/* @flow */
import React, { useState, useEffect, useRef } from 'react'
import type { Node } from 'react'
import styled from 'styled-components'
import Menu from '../../icons/menu.svg'
import Close from '../../icons/close.svg'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import { Box } from '../../utils/Box'
import Home from '../../icons/home.svg'
import Up from '../../icons/link-up.svg'
import Down from '../../icons/link-down.svg'
import Trade from '../../icons/trade.svg'
import Document from '../../icons/document.svg'
import GraphUp from '../../icons/graph-up.svg'
import Weighbriidge from '../../icons/weighbridge.svg'
import Partner from '../../icons/partner.svg'
import Feedback from '../../icons/mail-outline.svg'
import Work from '../../icons/work-outline.svg'
import Scheduler from '../../icons/scheduler.svg'
import Production from '../../icons/production.svg'
import Records from '../../icons/records.svg'
import Quality from '../../icons/quality.svg'
import Trust from '../../icons/trust.svg'
import AdminIcon from '../../icons/admin.svg'
import TripIcon from '../../icons/delivery-truck.svg'
import Receipt from '../../icons/receipt.svg'
import RightArrow from '../../icons/right-arrow.svg'
import Products from '../../icons/products.svg'
import MarketPlace from '../../icons/storefront.svg'
import MarketPlaceSell from '../../icons/shopping_cart.svg'
import MarketPlaceBuy from '../../icons/sell.svg'

import { LoaderWrapper, Spinner } from '../Spinner'
import { Spacer } from '../../utils/Spacer'

import { addEvent } from '../../utils/helpers'

type Props = {
	topPosition: number,
	open?: boolean,
	isLoading?: boolean,
	MenuItems?: Array<Node>,
	customMenuHeadingComponent: Node,
	isMobile: boolean,
	version: number,
	active?: boolean,
}

const Icons = {
	home: Home,
	outgoing: Up,
	incoming: Down,
	trade: Trade,
	documents: Document,
	trust: Trust,
	graphUp: GraphUp,
	weighbridge: Weighbriidge,
	partner: Partner,
	feedback: Feedback,
	production: Production,
	quality: Quality,
	records: Records,
	admin: AdminIcon,
	trips: TripIcon,
	receipt: Receipt,
	work: Work,
	scheduler: Scheduler,
	products: Products,
	marketPlace: MarketPlace,
	marketPlaceSell: MarketPlaceSell,
	marketPlaceBuy: MarketPlaceBuy,
}

const MenuWrapper = styled.div(p => ({
	position: 'fixed',
	top: p.top || 0,
	backgroundColor: '#120A50',
	minWidth: 272,
	color: p.theme.color.grey3,
	height: '100vh',
	zIndex: 50,
	overflowY: 'auto',
	willChange: 'transform',
	transition: 'transform 225ms cubic-bezier(0,0,.2,1) 0s',
	transform: 'translateX(-100%)',
	...(p.open && {
		transform: 'none',
	}),
}))

const ListWrapper = styled.ul({
	listStyle: 'none',
	cursor: 'pointer',
})

const Line = styled.div({
	borderBottom: '1px solid #645D95',
	marginBottom: '28px',
})

const ListItem = styled.li(p => ({
	fontSize: p.theme.fontSize.m,
	...p.theme.fonts.regular,
	width: '100%',
	padding: '20px 30px 20px 0',
	paddingLeft: ((p.index || 0) + 1) * 10,
	':hover': {
		color: p.theme.color.white,
		background: '#645D95',
	},
	borderBottom: '3px solid #120A50',
	borderTop: '3px solid #120A50',
	...(p.active && {
		color: p.theme.color.white,
		background: '#645D95',
		borderBottom: '3px solid #120A50',
		borderTop: '3px solid #120A50',
	}),
}))

const Title = styled.div(p => ({
	paddingLeft: ((p.index || 0) + 1) * 10,
	paddingRight: ((p.index || 0) + 1) * 10,
	transition: 'color 0.1s ease 0.1s',
	color: p.theme.color.white,
	maxWidth: '200px',
	lineHeight: 1.3,
}))

const MenuWrap = styled.a({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
})

const FooterWrapper = styled.p`
	text-align: center;
	font-size: 12px;
	z-index: 0;
	bottom: 0;
	left: 0;
	right: 0;
	line-height: 1em;
	position: absolute;
	margin-bottom: 24px;
`
const MenuBlock = ({
	id,
	icon = 'default',
	title,
	path,
	hasChildren,
	active,
}) => (
	<Box row justifyContent="space-between" id={id.replace('.', '')}>
		<MenuWrap
			href={path}
			onClick={e => {
				e.preventDefault()
			}}
			title={title}
		>
			<IconWrapper size={16} color="#fff">
				{icon !== 'default' && <Icon glyph={Icons[icon]} />}
			</IconWrapper>
			<Title>{title}</Title>
		</MenuWrap>
		{hasChildren && (
			<IconWrapper size={14} color="#fff">
				{icon !== 'default' && (
					<Icon
						style={{
							transform: active ? 'rotate(90deg)' : 'none',
						}}
						glyph={RightArrow}
					/>
				)}
			</IconWrapper>
		)}
	</Box>
)

type MenuProps = {
	id: string,
	index: number,
	icon: string,
	title: string,
	code: string,
	onClick: ({}) => {},
	isActive?: boolean,
	path: string,
	toggleMenu: boolean => {},
	hasChildren?: boolean,
}

export const MenuItem = ({
	id,
	index = 0,
	icon,
	title,
	code,
	onClick,
	isActive,
	path,
	toggleMenu,
	hasChildren,
}: MenuProps) => {
	return (
		<>
			<ListItem
				id={index}
				index={index}
				active={isActive}
				onClick={e => {
					e.preventDefault()
					if (typeof onClick === 'function')
						onClick({ path, code, toggleMenu, hasChildren })
				}}
			>
				<MenuBlock
					id={id}
					icon={icon}
					title={title}
					code={code}
					path={path}
					active={isActive}
					toggleMenu={toggleMenu}
					onClick={!isActive && onClick}
					hasChildren={!!hasChildren}
				/>
			</ListItem>
		</>
	)
}

const CoreMenu = ({
	open = false,
	isLoading = false,
	MenuItems,
	topPosition = 65,
	isMobile,
	customMenuHeadingComponent,
	version,
}: Props) => {
	const [isOpen, toggleMenu] = useState(open)
	const menuWrapperDom = useRef(null)
	const menuIconDom = useRef(null)
	useEffect(() => {
		// added to enable sidebar expand/collapse only on non mobile screen
		/* Removed as this do not handle case if menu is not open in mobile screen causes disappearance of menu sidebar. 
		toggleMenu(open) handles the sidebar collapse/expand
		 */
		// if (!isMobile) {
		// 	return
		// }

		const handleOutSideClick = e => {
			// added to enable sidebar expand/collapse only on non mobile screen
			if (!isMobile) {
				return
			}

			let isInsideDrop = false
			if (typeof document !== 'undefined') {
				const target = document.querySelector(
					'[aria-hidden="false"]:not(empty)'
				)

				isInsideDrop = target && target.contains(e.target) // $FlowFixMe
			}

			if (
				menuWrapperDom.current &&
				// $FlowFixMe
				!menuWrapperDom.current.contains(e.target) &&
				// $FlowFixMe
				!menuIconDom.current.contains(e.target) &&
				!isInsideDrop
			) {
				toggleMenu(false)
			}
		}

		toggleMenu(open)

		// add a event listener to track the click outside
		const detachEvent = addEvent(document, 'mousedown', handleOutSideClick)

		return () => detachEvent()
	}, [isMobile, open, toggleMenu])

	const MenuItemsMapped = React.Children.map(MenuItems, child => {
		return React.cloneElement(child, {
			toggleMenu,
		})
	})

	return (
		<>
			{isMobile && (
				<Box
					style={{
						position: 'absolute',
						padding: '24px 12px',
					}}
					ref={menuIconDom}
				>
					<IconWrapper
						height={25}
						width={25}
						style={{ cursor: 'pointer' }}
						onClick={() => toggleMenu(!isOpen)}
					>
						<Icon glyph={isOpen ? Close : Menu} />
					</IconWrapper>
				</Box>
			)}
			<MenuWrapper ref={menuWrapperDom} open={isOpen} top={topPosition}>
				{customMenuHeadingComponent}
				<Spacer size={20} />
				<Line />
				{isLoading && (
					<LoaderWrapper backGroundColor="transparent">
						<Spinner size={30} />
					</LoaderWrapper>
				)}
				<Box style={{ position: 'relative' }}>
					{!isLoading && <ListWrapper>{MenuItemsMapped}</ListWrapper>}
					<Spacer size={120} />
					<FooterWrapper>
						DIBIZ © {new Date().getFullYear()}, {version} (Beta)
					</FooterWrapper>
				</Box>
			</MenuWrapper>
		</>
	)
}

export { CoreMenu }
