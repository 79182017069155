import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { RFQListing } from 'ui-marketplace-app/modules/MarketPlace/containers/Enquiries/RFQListing'
import { ProductsListing } from 'ui-marketplace-app/modules/MarketPlace/containers/Products/ProductsListing'
import { BuyerRequirementListing } from 'ui-marketplace-app/modules/MarketPlace/containers/GlobalEnquiries/BuyerRequirementListing'
import MarketPlace from 'ui-marketplace-app/modules/MarketPlace/containers/MarketPlace'
import Quotes from 'ui-marketplace-app/modules/MarketPlace/containers/Quotes'
import { TabsWithFullWidth, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'

const TabsConfig = {
	mp_purchase_officer: [
		{
			code: 'home',
			title: 'marketPlace.home',
		},
		{
			code: 'enquiries',
			title: 'marketPlace.enquiries',
		},
		{
			code: 'orders',
			title: 'marketPlace.order',
		},
		{
			code: 'myRequirement',
			title: 'marketPlace.generalRequirement',
		},
	],
	mp_sales_marketing_officer: [
		{
			code: 'home',
			title: 'marketPlace.home',
		},
		{
			code: 'enquiries',
			title: 'marketPlace.enquiries',
		},
		{
			code: 'orders',
			title: 'marketPlace.order',
		},
		{
			code: 'products',
			title: 'marketPlace.products',
		},
	],
	palmoil_tdm: [
		{
			code: 'home',
			title: 'marketPlace.home',
		},
	],
}

const componentMap = {
	home: MarketPlace,
	enquiries: RFQListing,
	orders: Quotes,
	products: ProductsListing,
	myRequirement: BuyerRequirementListing,
}

const getTabComponents = () => ({ name }) => () => {
	const Component = componentMap[name]

	return <Component />
}

const MainDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const actor = useSelector(MarketPlaceDuc.selectors.getMarketPlaceActor)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const orgType = useSelector(AuthDuc.selectors.getActor)[0] || []

	const buyerActiveCounts = useSelector(
		MarketPlaceDuc.selectors.getBuyerMainDashboardActiveCounts
	)
	const sellerActiveCounts = useSelector(
		MarketPlaceDuc.selectors.getSellerMainDashboardActiveCounts
	)

	const activeCounts =
		actor === 'mp_purchase_officer' ? buyerActiveCounts : sellerActiveCounts

	const { buyer, seller } = useSelector(
		MarketPlaceDuc.selectors.getActiveTabsForBuyerAndSeller
	)

	const getTabs = useCallback(() => {
		if (orgType !== 'palmoil_tdm') {
			return TabsConfig[actor].map(({ code, title }) => ({
				name: code,
				title: t(title),
				IndicatorComponent:
					(code === 'enquiries' || code === 'orders') &&
					(({ isActive }) => (
						<DefaultTabIndicator
							active={isActive}
							extendStyles={{
								background: '#242845',
								color: theme.color.white,
							}}
						>
							{parseInt(activeCounts[code], 10)}
						</DefaultTabIndicator>
					)),
				showToolTip: code === 'myRequirement',
				toolTipText: t('marketPlace.postRequirementTooltip'),
			}))
		}

		return TabsConfig[orgType].map(({ code, title }) => ({
			name: code,
			title: t(title),
		}))
	}, [activeCounts, actor, orgType, t])

	return (
		<Box width="100%" height="100%">
			{orgType.length > 0 && (
				<>
					{orgType === 'palmoil_tdm' ? (
						<TabsWithFullWidth
							tabs={getTabs()}
							tabComponentsMap={getTabComponents()}
							bodyShadow
							activeTabIndex={0}
							onChange={({ name }) => {
								dispatch(
									MarketPlaceDuc.creators.setActiveTabs(
										'buyer',
										name
									)
								)
							}}
							isMobile={isMobile}
						/>
					) : (
						<>
							{actor === 'mp_purchase_officer' && (
								<TabsWithFullWidth
									tabs={getTabs()}
									tabComponentsMap={getTabComponents()}
									bodyShadow
									activeTabIndex={
										TabsConfig[actor].findIndex(
											type => type.code === buyer
										) === -1
											? 0
											: TabsConfig[actor].findIndex(
													type => type.code === buyer
											  )
									}
									onChange={({ name }) => {
										dispatch(
											MarketPlaceDuc.creators.setActiveTabs(
												'buyer',
												name
											)
										)
									}}
									isMobile={isMobile}
								/>
							)}
							{actor === 'mp_sales_marketing_officer' && (
								<TabsWithFullWidth
									tabs={getTabs()}
									tabComponentsMap={getTabComponents()}
									bodyShadow
									activeTabIndex={
										TabsConfig[actor].findIndex(
											type => type.code === seller
										) === -1
											? 0
											: TabsConfig[actor].findIndex(
													type => type.code === seller
											  )
									}
									onChange={({ name }) => {
										dispatch(
											MarketPlaceDuc.creators.setActiveTabs(
												'seller',
												name
											)
										)
									}}
									isMobile={isMobile}
								/>
							)}
						</>
					)}
				</>
			)}
		</Box>
	)
}

export { MainDashboard }
