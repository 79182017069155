import React from 'react'
import { Text } from 'ui-lib/components/Typography'

const EmailLabel = ({ email, removeEmail, index }) => {
	return (
		<div data-tag key={index}>
			{email}
			<Text data-tag-handle onClick={() => removeEmail(index)}>
				×
			</Text>
		</div>
	)
}

export { EmailLabel }
