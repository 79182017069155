import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import moment from 'moment'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	MONTHS,
	SHIPMENT_PERIOD,
	h3Styles,
	headingStyles,
	contractTableLabelStyles,
	contractTableTDStyles,
	s1Styles,
	paragraphStyles,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { Box } from 'ui-lib/utils/Box'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { Modal } from 'ui-lib/components/Modal'
import { SmallText, P } from 'ui-lib/components/Typography'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import { DigitalContractMessage } from '../../../../components/DigitalContractMessage'
import '../styles/contract1.css'

const PreviewFosfa81 = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { contractAction } = query
	const { rootModule, documentReference, documentType } = payload
	const [showModal, setShowModal] = useState(false)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const contractDetails = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	if (!contract.quantity && contractAction === 'generate') {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('contracts.editContractText')}
					</P>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.ok')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					dispatch(
						MarketPlaceDuc.creators.editContract(
							contractDetails,
							documentType
						)
					)
				}}
			/>
			<Title
				title={
					contractAction === 'edit'
						? t('contracts.editContractPreview')
						: t('contracts.createContractPreview')
				}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'participants-success',
								documentReference,
								documentType,
							},
							{
								contractAction,
							}
						)
					)
				}}
				small
				isMobile={isMobile}
				hasNoMaxWidth
			/>
			<Spacer size={60} />
			<Box center style={{ background: '#EAEAEB' }}>
				<Box
					width={804}
					style={{ background: theme.color.white }}
					padding={60}
				>
					<Box center>
						<h2 style={headingStyles}>
							FEDERATION OF OILS, SEEDS AND FATS ASSOCIATIONS
							LIMITED
						</h2>
						<h2 style={headingStyles}>FOSFA INTERNATIONAL</h2>
						<h2 style={{ ...headingStyles, paddingTop: 15 }}>
							ISSUED AND APPROVED JOINTLY WITH
						</h2>
						<h2 style={headingStyles}>
							THE PALM OIL REFINERS ASSOCIATION OF MALAYSIA
							(PORAM)
						</h2>
						<h2 style={headingStyles}>AND</h2>
						<h2 style={headingStyles}>
							THE MALAYAN EDIBLE OIL MANUFACTURERS’ ASSOCIATION
							(MEOMA)
						</h2>

						<h2 style={{ ...headingStyles, paddingTop: 15 }}>
							CONTRACT FOR PALM AND PALM KERNEL OIL PRODUCTS IN
							BULK
						</h2>
						<h2 style={headingStyles}>CIF TERMS</h2>
					</Box>
					<Spacer size={40} />
					<Box row={!isMobile} justifyContent="space-between">
						<Box>
							<p
								style={{
									...s1Styles,
									lineHeight: '16px',
									fontWeight: 'normal',
								}}
							>
								Revised and Effective
							</p>
							<br />
							<p
								style={{
									...s1Styles,
									lineHeight: '17px',
									fontWeight: 'normal',
								}}
							>
								from 1st. September 2011
							</p>
							<Spacer size={32} />
							<p style={{ ...s1Styles }}>
								SELLERS :{' '}
								<span style={{ fontWeight: 'normal' }}>
									{getIn(contractDetails, [
										'contract',
										'seller',
										'name',
									])}
								</span>
							</p>
							<p style={{ ...s1Styles, paddingTop: 13 }}>
								BUYERS :{' '}
								<span style={{ fontWeight: 'normal' }}>
									{getIn(contractDetails, [
										'contract',
										'buyer',
										'name',
									])}
								</span>
							</p>
							<p style={{ ...s1Styles, paddingTop: 13 }}>
								BROKERS :{' '}
								<span style={{ fontWeight: 'normal' }}>
									{getIn(contractDetails, [
										'contract',
										'broker',
										'name',
									])}
								</span>
							</p>
							<br />
							<br />
						</Box>
						<Box>
							<div
								style={{
									border: '1px solid black',
									padding: '10px',
									height: '148px',
									width: '148px',
									textAlign: 'center',
									wordBreak: 'break-all',
								}}
							>
								<p style={{ fontSize: '15px' }}>
									<u>Reference Nos</u>
								</p>
								<Spacer size={20} />
								<div
									style={{
										overflow: isMobile
											? 'scroll'
											: 'hidden',
									}}
								>
									<table style={{ width: '100%' }}>
										<tbody>
											{getIn(contractDetails, [
												'contract',
												'referenceNo',
											]) ||
												[].map(ref => {
													return (
														<tr key={ref}>
															<td
																style={{
																	paddingTop:
																		'10px',
																}}
															>
																{ref}
															</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</div>
							</div>
						</Box>
					</Box>
					<p>
						<br />
					</p>
					<p
						style={{
							...s1Styles,
							margin: '0px 150px 12px 0',
							padding: 0,
							textAlign: 'right',
						}}
					>
						Date:{' '}
						{`${moment(
							getIn(contractDetails, ['contract', 'date'])
						).format('DD MM YYYY')}; ${moment(
							getIn(contractDetails, ['contract', 'date'])
						).format('hh:mm A')}`}
					</p>
					<div>
						<p
							style={{
								color: 'black',
								fontFamily: '"Palatino Linotype", serif',
								fontStyle: 'normal',
								fontSize: 13,
								textAlign: 'center',
							}}
						>
							* An asterisk denotes alternative wording, and
							should be matter of agreement between the parties.
						</p>
					</div>
					<Spacer size={20} />
					<div>
						<p style={{ ...paragraphStyles, paddingTop: 5 }}>
							Sellers have agreed to sell and Buyers have agreed
							to buy:
						</p>
						<p style={{ textIndent: '0pt', textAlign: 'left' }}>
							<br />
						</p>
					</div>
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								width: 680,
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '36pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Contract No :{' '}
											{getIn(contractDetails, [
												'contract',
												'contractNo',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Date :{' '}
											{`${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('DD MM YYYY')}; ${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('hh:mm A')}`}
										</p>
									</td>
								</tr>
								<tr style={{ height: '35pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Product :{' '}
											{getIn(contractDetails, [
												'contract',
												'product',
												'name',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '24pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Origin :{' '}
											{getIn(contractDetails, [
												'contract',
												'productOrigin',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Quantity in
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											metric tonnes
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Shipment Period
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Discharge Port
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Contract
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Price CIF
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'quantity',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{`${t(
												MONTHS[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'month',
													])
												]
											)}, ${t(
												SHIPMENT_PERIOD[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'period',
													])
												]
											)}`}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'port',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'unitRate',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '13pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
										</p>
									</td>
								</tr>

								<tr style={{ height: '24pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Special Conditions
										</p>
									</td>
								</tr>
								<tr style={{ height: '24pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											{getIn(contractDetails, [
												'contract',
												'specialConditions',
											])}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						Payment in
					</p>
					<ul
						style={{
							color: 'black',
							fontFamily: '"Times New Roman", serif',
							fontStyle: 'normal',
							fontWeight: 'normal',
							textDecoration: 'none',
							fontSize: '10pt',
							listStyleType: 'none',
						}}
					>
						<li data-list-text="*">
							<p
								style={{
									lineHeight: '11pt',
									textAlign: 'left',
								}}
							>
								* (i) in accordance with the provisions of
								paragraph (a) of the Payment Clause;
							</p>
						</li>
						<Spacer size={20} />
						<li data-list-text="*">
							<p
								style={{
									marginLeft: 8,
									textAlign: 'left',
								}}
							>
								(ii) in accordance with the provisions of
								paragraph (b) of the Payment Clause;
							</p>
						</li>
					</ul>
					<Spacer size={16} />
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						Discharge in accordance with the provisions of the
						Discharge Clause and at an average rate of minimum
						…………………………………………………………………….....….. metric tons per hour,
						Sundays and holidays included.
					</p>
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						Domicile and Arbitration in ………………………………………………… as per
						Domicile and Arbitration Clauses
					</p>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							1.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								TOLERANCE:{' '}
							</span>
							Sellers have the option of shipping 5% more or less
							of the mean contract quantity. In the event of more
							than one shipment being made each shipment is to be
							considered as a separate contract but the tolerance
							on the mean contract quantity is not to be affected
							thereby.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							2.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								QUALITY AND SPECIFICATIONS:{' '}
							</span>
							Minimum flash point of 250ºF (121ºC).
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							Free Fatty Acid content shall be expressed as
							follows:
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
							}}
						>
							For Palm Oil Products - as Palmitic acid calculated
							on a molecular weight of 256
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
							}}
						>
							For Palm Kernel Oil Products - as Lauric acid
							calculated on a molecular weight of 200
						</p>
						<Spacer size={24} />
						<p
							style={{
								...paragraphStyles,
							}}
						>
							At time and place of shipment, the oil shall be of
							good merchantable quality of the agreed description
							and specifications.
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							It shall not contain substances of non oil-palm
							origin or materials used in its processing and not
							customarily found in oil of the contract
							description.
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							If the oil is loaded in more than one tank of the
							same ship, the analysis details of the oil in each
							separate tank shall conform with the above.
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							The specifications shall be those established by the
							Palm Oil Refiners Association of Malaysia (PORAM)
							for Palm Oil Products and by the Malayan Edible Oil
							Manufacturers’ Association (MEOMA) for Palm Kernel
							Oil Products as standard for the export of these
							products and in force at the date of contract and
							always provided they are not in contradiction with
							the above
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							Where the specification refers to Malaysian
							Standards, Sellers warrant that the oil will be
							produced from crude palm oil complying with the
							relative Standards and Industrial Research Institute
							of Malaysia (SIRIM) Standards and will in all
							respects conform to the requirements and
							restrictions of the Palm Oil Industry (Quality
							Control) Regulations 1983 and any amendment or
							extension thereto and/or other relevant Malaysian
							Government standards/regulations in force at the
							date of the contract
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							However, should the analysis relating to the oil in
							any tank/s fall outside the contract specifications
							-
						</p>
						<Spacer size={20} />
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									marginRight: 24,
								}}
							>
								(a)
							</p>
							<p
								style={{
									...paragraphStyles,
								}}
							>
								as to palm oil/olein/stearin and/or palm kernel
								oil/olein/stearin by no more than one unit in
								respect of iodine value;
							</p>
						</Box>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									marginRight: 24,
								}}
							>
								(b)
							</p>
							<p
								style={{
									...paragraphStyles,
								}}
							>
								as to palm acid oil/fatty acid distillate and/or
								palm kernel acid oil/fatty acid distillate by no
								more than 2% in respect of total fatty matter
								and/or free fatty acids;
							</p>
						</Box>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							The oil is not to be rejected provided always that
							the analysis of the composite sample representing
							the full quantity loaded complies with the contract
							specifications and provided that the oil so loaded
							and analysed emanates from the same Seller and is
							tendered to the same Buyer.
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							Where the contract refers to bleached deodorised
							palm oil/olein/stearin and/or bleached deodorised
							Palm kernel Oil/Olein/Stearin, the quality and
							specifications as ascertained on samples taken from
							ship’s tanks after loading shall be final and the
							provisions of the Arrival Quality-Adjustment Clause
							and the Sampling and Analysis Clause paragraph (d)
							shall not apply.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							3.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								ARRIVAL QUALITY-ADJUSTMENT:{' '}
							</span>
							Where this contract refers to neutralised palm oil
							or to neutralised palm kernel oil or to crude palm
							liquid fraction/palm solid fraction or to palm acid
							oil/fatty acid distillate and/or palm kernel acid
							oil/fatty acid distillate the price shall be subject
							to adjustment based on the arrival analysis
							ascertained in accordance with the provisions of the
							Sampling and Analysis Clause paragraph (d) and
							specifically as follows:
						</p>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									marginRight: 24,
								}}
							>
								(i)
							</p>
							<p
								style={{
									...paragraphStyles,
								}}
							>
								Unless otherwise stipulated, in the case of
								neutralised palm oil the basis shall be 0.25%
								FFA (as palmitic); and in the case of
								neutralised palm kernel oil the basis shall be
								0.25% (as lauric). Should the FFA on arrival be
								less than 0.25% Buyers shall pay to Sellers a
								premium of 0.5% of the contract price for each
								0.05% below 0.25%, fractions in proportion;
								should the FFA on arrival be greater than 0.25%
								Sellers shall pay to Buyers an allowance of 1%
								of the contract price for each 0.05% above 0.25%
								up to and including 0.5% and 2% of the contract
								price for each 0.05% above 0.5%, all fractions
								in proportion.
							</p>
						</Box>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									marginRight: 18,
								}}
							>
								(ii)
							</p>
							<p
								style={{
									...paragraphStyles,
								}}
							>
								In the case of crude palm liquid fraction/palm
								solid fraction the basis shall be 5% FFA (as
								palmitic); should the FFA on arrival be less
								than 5% Buyers shall pay to Sellers a premium of
								1% of the contract price for each 1% below 5%
								and should the FFA on arrival be greater than 5%
								Sellers shall pay to Buyers an allowance of 1%
								of the contract price for each 1% above 5%, all
								fractions in proportion.
							</p>
						</Box>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									marginRight: 14,
								}}
							>
								(iii)
							</p>
							<p
								style={{
									...paragraphStyles,
								}}
							>
								In the case of palm acid oil/fatty acid
								distillate and/or palm kernel acid oil/fatty
								acid distillate the total fatty matter/total
								saponifiable matter shall be 95% minimum at time
								of shipment and the basis shall be 97% at time
								of arrival; Buyers shall pay to Sellers a
								premium of 1% of the contract price for each 1%
								above 97% and Sellers shall pay to Buyers an
								allowance of 1% of the contract price for each
								1% below 97%, all fractions in proportion.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							4.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								DECLARATION OF DESTINATION:{' '}
							</span>
							The goods are sold for shipment to
							…..................................… but Buyers have
							the option to declare
							……………………....................................……………………….………...
							..............................................................................................................................................................................
							as the port/s of destination with a minimum of
							…….................................metric tons to
							any one port.
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							To exercise this option Buyers shall declare the
							port/s of destination to Sellers by any means of
							rapid written communication not later than 16.00
							hours on …….........................……………………………
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							5.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								SHIPMENT AND CLASSIFICATION:
							</span>
							Shipment in good condition in ship/s which comply
							with the FOSFA Qualifications and Operational
							Procedures for Ships Engaged in the Carriage of Oils
							and Fats in Bulk for Edible and Oleo-Chemical Use in
							force at the date of the Bill of Lading. The oil is
							to be shipped on a ship which, after loading in one
							or more origin ports, will proceed directly or
							indirectly, on a geographically normal route from
							the port/s of shipment to the port/s of destination.
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							For the purposes of this contract the words ‘ship’
							or ‘ships’ shall mean any full-powered engine-driven
							ship. Transhipment shall only be allowed under a
							through Bill of Lading and shall be restricted to
							the area of origin and/or customary transhipment
							ports in the area of destination for the goods
							specified in the contract, provided that
							transhipment at origin is completed within the
							original contract shipment period and/or agreed
							extension period. In case of transhipment at origin,
							shipping documents shall include FOSFA Certificate
							of Compliance, Cleanliness and Suitability of Ship’s
							Tank and a FOSFA Combined Master’s Certificate in
							respect of the ocean carrier. In the event of such
							transhipment at origin the Declaration of Shipment
							shall include the name of the ocean carrier and
							transhipment location. Nothing in this clause shall
							affect the Master’s rights or Ship’s obligations
							under Maritime Law.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							6.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								INSURANCE:{' '}
							</span>
							Insurance in accordance with the Institute/FOSFA
							Trades Clauses (A) and the Institute War and Strikes
							Clauses (FOSFA Trades) - including risk of
							contamination irrespective of percentage on each or
							on the whole - to be effected at Sellers’ option
							with first class underwriters or companies but for
							whose solvency the Sellers shall not be responsible.
							Claims to be payable in the currency of the
							contract. Policies and/or certificates and/or
							Letters of Insurance required under this contract
							shall be for not less than 5% over the invoice
							amount including freight.
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							Buyers shall accept insurance including Exclusion
							Clauses on the FOSFA Insurance Exclusion Clause
							List. In the event that Buyers receive an allowance
							under the Quality and Specifications Clause, Buyers
							to return the insurance policy to Sellers in order
							that they may make any recovery thereunder. Any
							benefit under the insurance in respect of loss in
							weight shall be for Sellers’ account.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							7.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								WAR RISKS INSURANCE:{' '}
							</span>
							War risks insurance shall be effected on the terms
							and conditions in force and approved at the time of
							shipment by the Institute of London Underwriters
							(Institute War Clauses [FOSFA Trades]). Any expense
							for covering war risks insurance in excess of ½%
							shall be for account of Buyers. The rate of such
							insurance shall not exceed the rate ruling in London
							at the time of shipment or date of ship’s sailing
							whichever may be adopted by underwriters. Notice of
							extra expense to be borne by Buyers shall be given
							by Sellers at the time of declaration under this
							contract or not later than 3 business days after the
							rate has been agreed with the underwriters whichever
							is the later. Failure to give such notice shall
							invalidate the Sellers’ claim unless in the opinion
							of the arbitrators the delay is justifiable.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							8.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								DECLARATION OF SHIPMENT:{' '}
							</span>
							Notice stating ship’s name, date of Bill/s of Lading
							and approximate quantity shipped shall be despatched
							by first Sellers to their Buyers not later than 10
							days after the date of the Bill/s of Lading. Notices
							by intermediate Sellers shall be accepted by their
							Buyers although received by them after such time, if
							from the 10th day after the date of the Bill/s of
							Lading such notices have been passed on with due
							despatch. The date of the “on board” Bill/s of
							Lading shall be considered proof of the date of
							shipment in the absence of conclusive evidence to
							the contrary.
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							Notices shall be deemed to be under reserve for
							errors and/or delays in transmission. Any slight
							variation in the ship’s name shall not invalidate
							the declaration. A valid declaration cannot be
							withdrawn except with the Buyers’ consent. Should
							the ship arrive before receipt of declaration of
							shipment and extra expenses be incurred, such
							expenses are to be paid by Sellers. Should extra
							lighterage expenses be incurred owing to Sellers
							tendering less than 50 tons for a contract of a
							greater quantity, the extra costs to be borne by
							Buyers and Sellers equally. The provisions of this
							clause to be inoperative if the goods have been sold
							afloat. Presentation of documents does not
							constitute a notice under the terms of this clause.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							9.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								SUPERINTENDENTS:{' '}
							</span>
							Reference in the contract to superintendents,
							surveyors or representatives shall mean member
							superintendents of FOSFA International.
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							The use of member superintendents shall be mandatory
							except where:-
						</p>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									marginRight: 24,
								}}
							>
								(i)
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								the contract or national laws or regulations
								require the use of Governmental or other
								agencies not recognised by FOSFA International/
								PORAM;
							</p>
						</Box>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									marginRight: 20,
								}}
							>
								(ii)
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								no member superintendent/s is/are available or
								proximate to the port/s concerned.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							10.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								ANALYSTS:{' '}
							</span>
							Reference in the contract to analysts shall mean
							analysts who are members of FOSFA International and
							represented in the Oils and Fats Section. The use of
							member analysts shall be mandatory except where the
							contract or national laws or regulations require the
							use of Governmental or other analysts.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							12.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								INTEREST:{' '}
							</span>
							If any payment is not paid on or before the due date
							for payment, interest shall be payable. If there is
							no due date for payment, interest shall be payable
							if there has been unreasonable delay in payment.
							Interest payable shall be appropriate to the
							currency involved. If the amount of interest is not
							mutually agreed, a dispute shall be deemed to exist
							which shall be settled by arbitration in accordance
							with the Arbitration Clause. Nothing in this clause
							shall affect a party’s rights to invoke the
							provisions of the Default Clause in a case where a
							failure to effect timely payment could give rise to
							a claim under that clause.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							13.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								CHARTER PARTY:{' '}
							</span>
							If the Bill/s of Lading refer/s to a Charter Party,
							then, if required by Buyers, Sellers shall provide a
							copy of the Charter Party.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							14.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								WAR DEVIATION:{' '}
							</span>
							Buyers agree to accept Bill of Lading containing the
							Chamber of Shipping War Deviation Clause and/or any
							other recognised official War Deviation Clause.
						</p>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							15.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								UNASCERTAINED GOODS:{' '}
							</span>
							In every instance where a parcel of goods paid for
							under this contract forms an unidentified part of a
							larger identified quantity of goods of the same
							description, whether in packages or in bulk, no
							separation or distinction shall be necessary and,
							until separation and identification of the parcel
							paid for hereby from the larger quantity has taken
							place the Buyer of the parcel is a pro rata owner of
							the whole of the larger quantity in common with
							Seller/s and Buyer/s of other parts of the larger
							quantity.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							16.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								DISCHARGE:{' '}
							</span>
							The oil shall be discharged at the port of
							destination at a berth suitable for the discharge of
							oils or, if practicable and mutually agreed, and
							provided the ship is willing to and can safely
							berth, at Buyers’ own or appointed premises within
							harbour limits. Buyers shall take delivery with
							customary quick despatch after notice of readiness
							has been given by the shipowner or representative/s
							in accordance with the Bill/s of Lading, Charter
							Party or Contract of Affreightment
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							Where FOSFA International/PORAM Standard Fixture
							Terms apply, Buyers shall take delivery at the rate
							per hour stipulated in the Preamble or where no such
							rate is stated at an average rate of 150 metric tons
							per hour Sundays and Holidays included (except
							gazetted port holidays) or the rate stipulated in
							the Charter Party or Contract of Affreightment
							whichever is the lower. Otherwise Buyers to be
							liable to pay demurrage at the rate stipulated in
							the Charter party or Contract of Affreightment.
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							Sellers are responsible for all expenses for pumping
							out and for connecting to ship’s outlet/s and for
							sweepings and/or puddling but discharging expenses
							arising after the oil has passed the ship’s rail
							shall be for Buyers’ account. Any loose collected
							remaining in the ship’s tank/s to be discharged by
							the Sellers and delivered to the Buyers at the
							discharging berth in packages to be provided by the
							Buyers.
						</p>
						<p
							style={{
								...paragraphStyles,
							}}
						>
							If the packages are supplied by the Buyers but the
							residue is not delivered, such residue or unpumpable
							oil shall not be included in the delivered weight
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							17.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								WEIGHTS:{' '}
							</span>
							<strong>At loading:</strong> The shipping weight
							shall be ascertained by a superintendent appointed
							by Sellers at their expense by gauging either in
							officially calibrated land tank/s or tank barge/s
							from which the oil is delivered or by delivery via
							certified weigh scales or from tank cars which, if
							not calibrated, shall be weighed before and after
							loading by single weighing only (front and back axle
							weighing not allowed). If none of these is possible
							then the shipping weight to be ascertained by the
							most practicable alternative means.
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
							}}
						>
							For goods sold for shipment to and/or discharged at
							USA/Canadian port/s: In the event that the oil
							shipped under this contract or this and other
							contracts with the same Buyer is loaded in separate
							tank/s not commingled with other parcels, Sellers
							shall declare Bill of Lading weights final and shall
							ensure that the insurance policy/certificate
							included with the documents covers Buyers against
							any difference between certified shipped and
							certified landed weights in excess of ½% and the
							provisions of the Adjustment of Outturn Clause as to
							price adjustment shall be based on the Bill of
							Lading weights.
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							<strong>At discharge:</strong> Buyers’
							superintendents shall ascertain the weight at
							Buyers’ expense conjointly with Sellers’
							superintendents, if in attendance, by gauging either
							in officially calibrated tank/s or tank barge/s in
							which the oil is received or by overside delivery to
							certified weigh scales, or in rail/road tank cars
							which, if not calibrated, shall be weighed before
							and after loading by single weighing only (front and
							back axle weighing not allowed). If none of these
							methods is possible then the discharged weight to be
							ascertained by the most practicable alternative
							means. In the event of disagreement between Buyers’
							and Sellers’ superintendents on the question of mass
							per volume (litre weight in air), sealed samples
							shall be submitted to an analyst whose decision
							shall be final. If establishment of weights is not
							completed within 5 working days after discharge
							Sellers may submit an interim invoice, which shall
							become due and payable, based on Bill of Lading
							weights. Weights shall then be established as soon
							as it is deemed practicable by the superintendents
							representing the parties and a final invoice shall
							be issued accordingly.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							18.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								ADJUSTMENT OF OUTTURN:{' '}
							</span>
							Any excess over or deficit under the mean contract
							quantity arrived at without taking into
							consideration the margin of 5% more or less, and the
							quantity delivered, is to be settled up to and
							including the first 2% variation from contract
							quantity at contract price and the variation above
							2% of the contract quantity at the price fixed by
							the appropriate Price Settlement Committee appointed
							by FOSFA International/PORAM/MEOMA, and published by
							the Federation or, if no price is fixed by the Price
							Settlement Committee then at the market price to be
							mutually agreed or fixed by arbitration for the day
							of arrival of the last ship to arrive at the
							berth/place where the contracted goods are to be
							discharged at the port of destination
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							19.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								COMMINGLING:{' '}
							</span>
							Sellers may load the oil commingled with other
							parcel/s provided that:
						</p>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginRight: 36,
								}}
							>
								(a)
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								the oil is of the same description, origin,
								grade and contractual quality specifications;
								and
							</p>
						</Box>
						<Spacer size={20} />
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginRight: 36,
								}}
							>
								(b)
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								the oil emanates from the same shipper (which in
								this contract shall be deemed to mean the party
								responsible for freight).
							</p>
						</Box>
						<Spacer size={20} />
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							The provisions of paragraphs (a) and (b) shall not
							apply:-
						</p>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginRight: 36,
								}}
							>
								(i)
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								where this contract refers to palm acid
								oil/fatty acid distillate and/or palm kernel
								acid oil/fatty acid distillate;
							</p>
						</Box>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginRight: 32,
								}}
							>
								(ii)
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								where the oil is sold for shipment to optional
								ports and the final destination/s is/are not
								declared to Sellers within one month prior to
								the commencement of the shipment period.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							20.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								SAMPLING AND ANALYSIS:{' '}
							</span>
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							21.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								DUTIES, TAXES, ETC:{' '}
							</span>
							All export duties, taxes, levies, etc., present or
							future in country of origin/port of shipment shall
							be for Sellers’ account. All import duties, taxes,
							levies, etc., present or future in port of
							discharge/country of destination shall be for
							Buyers’ account. Where the goods are entitled to
							free entry into or preferential duty in the port of
							destination named in this contract, Sellers shall
							furnish together with the shipping documents a
							Certificate of Origin and/or necessary documents in
							the form valid at the time of shipment, otherwise
							Sellers shall be responsible for any extra duty
							incurred by Buyers through the non-production of
							such Certificate and/or document/s.
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							At Buyers’ request and cost, Sellers shall endeavour
							to supply any alternative or additional certificate
							of origin and/or other documents but payment shall
							not be withheld for any delay incurred in complying
							with such request.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							22.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								NOTICES:{' '}
							</span>
							Notices to be despatched by any means of rapid
							written communication. All notices shall be under
							reserve for errors in transmission. Notices shall be
							passed on with due despatch by intermediate Buyers
							and Sellers. Any notices received after 16.00 hours
							on a business day shall be deemed to have been
							received on the following business day. Notice from
							a broker shall be a valid notice under this
							contract.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							23.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								NON-BUSINESS DAYS:{' '}
							</span>
							Should the time limit for doing any act or giving
							any notice expire on a Saturday, Sunday or any
							public holiday in the country where the party
							required to do the act or give the notice resides or
							carries on business or in the country where the act
							has to be done or the notice has to be received or
							on any day which the Federation of Oils, Seeds and
							Fats Associations Ltd and the Palm Oil Refiners
							Association of Malaysia and the Malayan Edible Oil
							Manufacturers Association shall declare to be a
							non-business day the time so limited shall be
							extended until the first business day thereafter.
							All business days shall be deemed to end at 16.00
							hours Mondays to Fridays inclusive. The contract
							shipment period not to be affected by this clause.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							24.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								ODD DAYS:{' '}
							</span>
							In any month containing an odd number of days the
							middle day shall be reckoned as belonging to both
							halves of the month
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							25.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								FORCE MAJEURE:{' '}
							</span>
							Should shipment of the goods or any part thereof be
							prevented at any time during the last 30 days of the
							contract shipment period by reason of Act of God,
							strikes, lockouts, riots, civil commotions, fires or
							any other cause comprehended by the term Force
							Majeure at port/s of loading or elsewhere preventing
							transport of the goods to such port/s, the time
							allowed for shipment shall be extended to 30 days
							beyond the termination of such cause, but should the
							contract shipment period be less than 30 days such
							extension shall be limited to the number of days
							allowed for shipment under the contract shipment
							period.
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							Should such cause exist for a period of 60 days
							beyond the contract shipment period the contract or
							any unfulfilled part thereof so affected shall be
							cancelled.
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							Sellers invoking this clause shall notify Buyers
							with due despatch
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							When goods of a specific origin are sold with the
							option of shipment from alternative ports and
							shipment from all alternative ports is not
							prevented, Sellers may only invoke this clause with
							regard to the specific port/s provided that the
							port/s has/have been notified to Buyers as the
							intended port/s of loading prior to or within 7 days
							of the occurrence but if the occurrence commences
							within the last 7 days of the contract shipment
							period the port/s of loading to be notified not
							later than the first business day following the
							contract shipment period. Shipment after the
							contract shipment period shall be limited to the
							port/s so nominated. Buyers have no claim against
							Sellers for delay in shipment or cancellation under
							this clause provided that Sellers shall have
							supplied to their Buyers, if required, satisfactory
							evidence justifying delay or non-fulfilment to
							establish any claim for extension or cancellation
							under this clause. In case of default after
							extension the default date shall be similarly
							deferred.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							26.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								PROHIBITION:{' '}
							</span>
							In the event, during the contract shipment period,
							of prohibition of export or any other executive or
							legislative act by or on behalf of the Government of
							the country of origin or of the territory where the
							port/s of shipment named herein is/are situate, or
							of blockade or hostilities, restricting export,
							whether partially or otherwise, any such restriction
							shall be deemed by both parties to apply to this
							contract and to the extent of such total or partial
							restriction to prevent fulfilment whether by
							shipment or by any other means whatsoever and to
							that extent this contract or any unfulfilled portion
							thereof shall be extended by 30 days. In the event
							of shipment during the extended period still proving
							impossible by reason, of any of the causes in this
							clause the contract or any unfulfilled part thereof
							shall be cancelled. Sellers invoking this clause
							shall advise Buyers with due despatch. If required,
							Sellers must produce proof to justify their claim
							for extension or cancellation under this clause.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							27.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								BANKRUPTCY/INSOLVENCY:{' '}
							</span>
							If before the fulfilment of this contract, either
							party shall suspend payment, notify any of this
							creditors that he is unable to meet his debts or
							that he has suspended payment or that he is about to
							suspend payment of his debts, convene, call or hold
							a meeting of his creditors, propose a voluntary
							arrangement, apply for an official moratorium, have
							an administration order made, have a winding up
							order made, have a receiver or manager appointed,
							convene, call or hold a meeting to go into
							liquidation (other than for reconstruction or
							amalgamation), become subject to an Interim Order
							under Section 252 of the Insolvency Act 1986 or have
							a Bankruptcy Petition presented against him, the
							contract shall forthwith be closed, either at the
							actual or estimated market price then current for
							similar goods or, at the option of the other party,
							at a price to be ascertained by re-purchase or
							re-sale and the difference between the contract
							price and such closing-out price shall be the amount
							which the other party shall be entitled to claim or
							shall be liable to account for under this contract.
							Should either party be dissatisfied with the price
							ascertained by re-purchase or re-sale then the
							matter shall be referred to arbitration. If no
							re-purchase or re-sale takes place, and if the
							parties cannot agree to a closing-out price, then on
							application of either party, the closing-out price
							shall be fixed by a sole arbitrator appointed by the
							Federation subject to the right of appeal under the
							Federation’s Rules of Arbitration and Appeal.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							28.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								CIRCLE:{' '}
							</span>
							Where a Seller repurchases from his Buyer, or from
							any subsequent Buyer, the same goods or part
							thereof, a circle shall be considered to exist as
							regards the particular goods so repurchased, and the
							provisions of the Default Clause shall not apply.
							(For the purpose of this clause, the same goods
							shall mean goods of the same description, of the
							same country of origin, of the same quality and
							where applicable, of the same analysis warranty, for
							shipment to the same port/s of destination during
							the same period of shipment.) Different currencies
							shall not invalidate the circle. If the goods are
							not declared or, having been declared, documents are
							not presented as a result of a circle having been
							established, invoices based on the mean contract
							quantity shall be settled between each Buyer and his
							Seller in the circle by payment by each Buyer to his
							Seller of the excess of the Seller’s invoice amount
							over the lowest invoice amount in the circle. Where
							the circle includes contract/s expressed in
							different currencies, the lowest invoice amount
							shall be replaced by the market price on the first
							business day for contractual shipment and invoices
							shall be settled between each Buyer and his Seller
							in the circle by payment of the difference between
							the market price and the relevant contract price in
							the currency of the contract. Failing amicable
							agreement the market price shall be that declared by
							a Price Settlement Committee of the Federation
							appointed for that purpose on application of either
							party.
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							Such settlement shall be due for payment not later
							than 15 consecutive days after the last day for
							declaration or, should the circle not be established
							before the expiry of this time, then settlement
							shall be due for payment not later than 7 days after
							the circle is established. No circle shall be
							considered to exist if its existence is not
							established within 45 days after the last day of
							shipment.
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							All Sellers and Buyers shall give every assistance
							to the establishment of the circle and where a
							circle shall have been established same shall be
							binding on all parties to the circle. Should any
							party in the circle commit prior to the due date for
							payment any act comprehended in the
							Bankruptcy/Insolvency Clause, the invoice amount for
							the goods calculated at the closing-out price as
							provided for in the Bankruptcy/Insolvency Clause,
							shall be taken as the basis for settlement instead
							of the lowest invoice amount in the circle, and in
							this event each Buyer shall make payment to his
							Seller or each Seller shall make payment to his
							Buyer of the difference between the closing-out
							price and the contract price, as the case may be.
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							In the event of a claim under the Prohibition Clause
							or the Force Majeure Clause, the date for settlement
							shall be deferred until the expiry of the extended
							shipment period. Thereafter, if the contract is
							cancelled under the terms of the Prohibition Clause
							or the Force Majeure Clause, this clause is not
							applicable.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							29.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								DEFAULT:{' '}
							</span>
							In default of fulfilment of this contract by either
							party the other party at his discretion shall, after
							giving notice, have the right either to cancel the
							contract, or the right to sell or purchase, as the
							case may be, against the defaulter who shall on
							demand make good the loss, if any, on such sale or
							purchase. If the party liable to pay shall be
							dissatisfied with the price of such sale or
							purchase, or if neither of the above rights is
							exercised, the damages, if any, shall, failing
							amicable settlement, be determined by arbitration.
							The damages awarded against the defaulter shall be
							limited to the difference between the contract price
							and the actual or estimated market price on the day
							of default. Damages to be computed on the mean
							contract quantity. If the arbitrators consider the
							circumstances of the default justify it they may, at
							their absolute discretion, award damages on a
							different quantity and/or award additional damages.
							Prior to the last day for making a declaration of
							shipment a Seller may notify his Buyer of his
							inability to ship but the date of such notice shall
							not become the default date without the agreement of
							the Buyer. If, for any other reason, either party
							fails to fulfil his contract and is declared to be
							in default by the other party and default is either
							agreed between the parties or subsequently found by
							arbitrators to have occurred, then the day of the
							default shall, failing amicable settlement, be
							decided by arbitration
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							30.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								INTERNATIONAL CONVENTIONS:{' '}
							</span>
							The following shall not apply to this contract:-
						</p>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginRight: 36,
								}}
							>
								(a)
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								the Uniform Law on Sales and the Uniform Law on
								Formation to which effect is given by the
								Uniform Laws on International Sales Act 1967;
							</p>
						</Box>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginRight: 36,
								}}
							>
								(b)
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								the United Nations Convention on Contracts for
								the International Sale of Goods of 1980;
							</p>
						</Box>
						<Box row={!isMobile}>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginRight: 36,
								}}
							>
								(c)
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								the United Nations Convention on the Limitation
								Period in the International Sale of Goods of
								1974 and the amending Protocol of 1980.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							31.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								DOMICILE:{' '}
							</span>
							Where both parties at or subsequent to the date of
							the contract agree, the contract shall be deemed to
							have been made in Malaysia and the construction,
							validity and performance thereof shall be governed
							in all respects by Malaysian law.
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							Otherwise this contract shall be deemed to have been
							made in England and the construction, validity and
							performance thereof shall be governed in all
							respects by English law.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							32.{' '}
							<span
								style={{ ...h3Styles, margin: '0 12px 0 30px' }}
							>
								ARBITRATION:{' '}
							</span>
							Where both parties at or subsequent to the date of
							the contract agree, any dispute arising out of or in
							connection with this contract shall be submitted to
							arbitration in Malaysia in accordance with the
							Arbitration Act of Malaysia 1952 (as revised in
							1972) and in accordance with the PORAM Rules of
							Arbitration and Appeal in force at the date of the
							contract.
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							In all other cases, any dispute arising out of the
							contract, including any question of law arising in
							connection therewith, shall be referred to
							arbitration in London (or elsewhere if so agreed) in
							accordance with the Rules of Arbitration and Appeal
							of the Federation of Oils, Seeds and Fats
							Associations Limited, in force at the date of this
							contract and of which both parties hereto shall be
							deemed to be cognizant.
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							Neither party hereto, nor any persons claiming under
							either of them, shall bring any action or other
							legal proceedings against the other of them in
							respect of any such dispute until such dispute shall
							first have been heard and determined by the
							arbitrators, umpire or Board of Appeal (as the case
							may be), in accordance with the Rules of Arbitration
							and Appeal governing the dispute, and it is hereby
							expressly agreed and declared that the obtaining of
							an Award from the arbitrators, umpire or Board of
							Appeal (as the case may be), shall be a condition
							precedent to the right of either party hereto or of
							any person claiming under either of them to bring
							any action or other legal proceedings against the
							other of them in respect of any such dispute.
						</p>
					</Box>
					<Spacer size={20} />
					<Box>
						<h3 style={h3Styles}>
							SPECIAL CONDITIONS FOR PALM AND PALM KERNEL OIL
							PRODUCTS OF MALAYSIAN OR INDONESIAN ORIGIN:
						</h3>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							EXTENSION OF SHIPMENT/CONTINUOUS LOADING: Where the
							contract shipment period does not exceed 31 days the
							period of shipment shall, at the request of Sellers,
							be extended by an additional period not exceeding 8
							days provided notice is given to Buyers of their
							intention to invoke the continuous loading
							provisions or claim such extension not later than
							the first business day following the last day of the
							original contract shipment period. Successive
							Sellers must pass on this notification with due
							despatch. Sellers shall at the same time nominate
							the ship they intend to load and shall provide,
							together with shipping documents, satisfactory
							evidence that the ship was originally booked with
							lay days/cancelling within the original contract
							shipment period.
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							No allowance shall be payable provided the ship
							commenced loading at the loadport from which the
							goods appropriated under this contract are shipped
							during the contract shipment period and provided
							loading of the goods appropriated under this
							contract is completed within 5 days of the end of
							the original contract shipment period. If loading
							did not so commence or, having commenced, did not
							complete within the said 5 days, Sellers shall make
							an allowance to their Buyers, on the quantity not
							loaded, to be deducted in the invoice from the
							contract price, as follows:
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							1/2% for 1, 2, 3 or 4 days
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							1% for 5 or 6 days
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							1 1/2% for 7 or 8 days.
						</p>
						<Spacer size={12} />
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							If Sellers invoke the continuous loading provisions
							or claim an extension and fail to ship within the 8
							days, the original contract shipment period shall be
							considered to have been extended by 8 days and the
							contract price reduced by 1½%.
						</p>
						<p
							style={{
								...paragraphStyles,
								textAlign: 'justify',
								width: isMobile ? 238 : 640,
							}}
						>
							Should Sellers not claim the above extension and
							fail to ship within the contract period, any penalty
							whether arrived at by amicable settlement or
							arbitration shall not be related to the allowances
							of this clause. Where the conditions of this clause
							are invoked Sellers undertake not to load on the
							same ship similar goods sold for the then current
							shipment period, without the prior consent of
							Buyers.
						</p>
					</Box>
					<Spacer size={28} />
					<p
						style={{
							...paragraphStyles,
							fontWeight: 28,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						FOSFA Copyright 2006.
					</p>
					<Spacer size={28} />
					<Box
						width={600}
						row={!isMobile}
						justifyContent="space-between"
					>
						<h3 style={h3Styles}>
							FOSFA/PORAM/MEOMA CONTRACT No. 81{' '}
						</h3>
						<h3 style={h3Styles}>AS38</h3>
					</Box>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						The following clause becomes effective on and from 1st
						March 2001 and is for use when agreed between Buyers and
						Sellers.
					</p>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						The FOSFA Qualification and Operational Procedures for
						Ships Engaged in the Carriage of Oils and Fats in Bulk
						for Edible and Oleo-Chemical use, the FOSFA Certificate
						of Compliance, Cleanliness and Suitability of Ship’s
						Tank, the FOSFA Combined Masters Certificate and the
						FOSFA List of Banned Immediate Previous Cargoes shall be
						those in force at the date of the contract
					</p>
					<Spacer size={28} />
					<Box
						width={600}
						row={!isMobile}
						justifyContent="space-between"
					>
						<h3 style={h3Styles}>FOSFA International </h3>
						<h3 style={h3Styles}>AS9</h3>
					</Box>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						The following clause becomes effective on and from 1st
						October 2005 and is for use when agreed between Buyers
						and Sellers.
					</p>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						The immediate previous cargo in the tank/s, lines and
						pump systems receiving the oils or fats shall have been
						a product on the FOSFA List of Acceptable Previous
						Cargoes in force at the date of the Bill/s of Lading.
						The Restrictions beyond the Immediate Previous Cargo on
						the FOSFA List of Acceptable Previous Cargoes shall
						apply. The statements referring to previous cargoes
						called for under the Payment and Shipping Documents
						Clause shall certify to this effect. The reference
						therein relating to the Banned List shall not apply.
					</p>
					<Spacer size={28} />
					<Box
						width={600}
						row={!isMobile}
						justifyContent="space-between"
					>
						<h3 style={h3Styles}>FOSFA International </h3>
						<h3 style={h3Styles}>EU1</h3>
					</Box>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						The following clause becomes effective on and from the
						1st. June 2004 and is for use in respect of oils and
						fats intended for human consumption sold for shipment to
						ports in the European Union.
					</p>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						GOODS WHICH ARE TO BE PROCESSED
					</p>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						In addition to the provision of FOSFA sticker AS9,
						Commission Directive 96/3/EC and 2004/4/EC or Successive
						Directives shall apply in respect of previous cargoes.
					</p>
					<Spacer size={28} />
					<Box
						width={600}
						row={!isMobile}
						justifyContent="space-between"
					>
						<h3 style={h3Styles}>FOSFA INTERNATIONAL </h3>
						<h3 style={h3Styles}>EU2</h3>
					</Box>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						The following clause becomes effective on and from 1st.
						June 2004 and is for use in respect of oils and fats
						intended for human consumption sold for shipments to
						ports in the European Union.
					</p>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						GOODS WHICH ARE NOT TO BE PROCESSED
					</p>
					<p
						style={{
							...paragraphStyles,
							textAlign: 'justify',
							width: isMobile ? 238 : 640,
						}}
					>
						In addition to the provision of FOSFA sticker AS9,
						Commission Directive 96/3/EC and 2004/4/EC or Successive
						Directives shall apply in respect of previous cargoes.
					</p>
					<Spacer size={20} />
					<Box
						row={!isMobile}
						justifyContent="space-between"
						margin="28px 0 0"
					>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Seller:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Broker:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Buyer:
						</p>
					</Box>
					<Spacer size={20} />
				</Box>
				<Box
					style={{
						width: isMobile ? 350 : 800,
						padding: 15,
						margin: '40px 0 ',
						alignItems: 'center',
						background: theme.color.white,
						justifyContent: 'space-between',
					}}
					row={!isMobile}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '60%',
							alignItems: 'end',
							marginRight: '5%',
							padding: '15px 10px',
							background: theme.color.white,
						}}
					>
						<SmallText
							style={{
								fontSize: 14,
								color: '#8D93A0',
								lineHeight: '19px',
								textAlign: 'justify',
							}}
						>
							{t('contracts.proceedToESignText')}
						</SmallText>
					</Box>

					<Button
						rounded
						label={t('contracts.proceedToESign')}
						reverse
						onClick={() => {
							if (contractAction === 'edit') {
								setShowModal(true)
							} else {
								dispatch(
									MarketPlaceDuc.creators.postContractDetails(
										contractDetails,
										documentType
									)
								)
							}
						}}
						extendStyles={{
							width: 266,
							background: '#3F56C4',
							color: theme.color.white,
						}}
					/>
				</Box>
				<DigitalContractMessage
					width={isMobile ? 350 : 800}
					margin="0 0 40px 0"
				/>
			</Box>
		</>
	)
}

export { PreviewFosfa81 }
