import React from 'react'
import { Grommet, Tip, Text, Box as GrommetBox } from 'grommet'
import styled from 'styled-components'
import { Box } from '../../utils/Box'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import ReportProblemIcon from '../../icons/report_problem.svg'
import baseTheme from '../../utils/base-theme'

const OriginalRequirementWrapper = styled.div`
	color: #c97b12;
	font-size: 12px;
	position: ${props => (props.fullIcon ? 'absolute' : 'relative')};
	top: ${props => props.top};
	left: ${props => props.left};
`

const OriginalRequirementInner = styled(Box)`
	margin-bottom: 6px;
	border-radius: 6px;
	margin-top: ${props => (props.marginTop ? props.marginTop : 0)};
	padding: ${props => (props.halfIcon || props.fullIcon ? 0 : '5px')};
	background: ${props =>
		props.halfIcon || props.fullIcon
			? 'transparent'
			: '#FFF0DD 0% 0% no-repeat padding-box'};
`

const OriginalRequirementLabel = styled.div`
	height: 24px;
	display: flex;
	align-items: center;
`

const IconOuterWrapper = styled.div`
	top: ${props => (props.halfIcon ? props.top : 0)};
	left: ${props => (props.halfIcon ? props.left : 0)};
	position: ${props => (props.halfIcon ? 'absolute' : 'relative')};
`

const TipContent = ({ message }) => (
	<GrommetBox direction="row" align="center">
		<svg viewBox="0 0 21 21" version="1.1" width="22px" height="22px">
			<polygon
				fill="#141C2C"
				points="6 2 18 12 6 22"
				transform="matrix(-1 0 0 1 30 0)"
			/>
		</svg>
		<GrommetBox
			style={{ fontSize: 12, maxWidth: 400, marginLeft: -1 }}
			background="#141C2C"
			pad="small"
			gap="small"
			round="xsmall"
		>
			<Text style={{ color: '#F0911E' }}>ORIGINAL REQUIREMENT:</Text>
			<Text style={{ marginTop: '-10px' }} size="small">
				{message}
			</Text>
		</GrommetBox>
	</GrommetBox>
)

const OriginalRequirement = ({
	newValue,
	marginTop,
	top = '0px',
	left = '0px',
	originalValue,
	halfIcon = false,
	fullIcon = false,
	halfIconTop = '0px',
	originalDisplayValue,
	halfIconLeft = '120px',
}) => {
	return (
		<OriginalRequirementWrapper
			halfIcon={halfIcon}
			fullIcon={fullIcon}
			top={top}
			left={left}
		>
			{((newValue && typeof newValue === 'string'
				? newValue.trim()
				: '') !==
				(originalValue && typeof originalValue === 'string'
					? originalValue.trim()
					: '') ||
				(typeof newValue === 'number' &&
					newValue !== originalValue)) && (
				<OriginalRequirementInner
					marginTop={marginTop}
					halfIcon={halfIcon}
					fullIcon={fullIcon}
				>
					{!fullIcon && (
						<OriginalRequirementLabel>
							Original Requirement:
						</OriginalRequirementLabel>
					)}
					{(halfIcon || fullIcon) && (
						<IconOuterWrapper
							halfIcon={halfIcon}
							top={halfIconTop}
							left={halfIconLeft}
						>
							<Grommet
								theme={{
									...baseTheme,
									global: {
										drop: {
											extend: {
												border: '1px solid trasparent',
											},
										},
									},
								}}
							>
								<Tip
									plain
									dropProps={{ align: { left: 'right' } }}
									content={
										<TipContent
											message={originalDisplayValue}
										/>
									}
									border={{ color: 'transparent' }}
								>
									<IconWrapper
										size={24}
										style={{
											width: 20,
											height: 20,
											padding: 3,
											display: 'flex',
											color: '#FFFFFF',
											borderRadius: 10,
											alignItems: 'center',
											background: '#F0911E',
											justifyContent: 'center',
											border: '1px solid #F0911E',
										}}
									>
										<Icon glyph={ReportProblemIcon} />
									</IconWrapper>
								</Tip>
							</Grommet>
						</IconOuterWrapper>
					)}
					{!halfIcon && !fullIcon && (
						<div>{originalDisplayValue}</div>
					)}
				</OriginalRequirementInner>
			)}
		</OriginalRequirementWrapper>
	)
}

export { OriginalRequirement }
