import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { Box } from 'ui-lib/utils/Box'

const ESignContract = () => {
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule, documentReference } = payload

	const eSignDetails = useSelector(MarketPlaceDuc.selectors.getESignDetails)

	const receiveMessage = event => {
		const origin = event.origin || event.originalEvent.origin
		const { data } = event
		switch (data) {
			case 'ESL:MESSAGE:REGISTER':
				event.source.postMessage('ESL:MESSAGE:ACTIVATE_EVENTS', origin)
				break
			case 'ESL:MESSAGE:STARTED:SIGNER_COMPLETE_REVIEWED':
				event.source.postMessage(data, origin)
				window.location.href = `/marketplace/${rootModule}/contract/e-sign-success/${documentReference}`
				break
			default:
				break
		}
	}

	window.addEventListener('message', receiveMessage, false)

	return (
		<>
			<Box>
				<Title title={t('contracts.generateESignature')} />
			</Box>
			{eSignDetails?.url && (
				<div>
					<iframe
						title="onespan"
						height="660px"
						width="100%"
						src={eSignDetails.url}
					/>
				</div>
			)}
		</>
	)
}

export { ESignContract }
