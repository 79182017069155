export const tranformGlobalRfqQueries = obj => {
	const { query } = obj
	let qry = 'limit=20'
	const {
		nextIndex,
		country,
		orgCategory,
		orgCertificate,
		product,
		state,
	} = query

	if (nextIndex && nextIndex !== '') {
		qry += `&startID=${nextIndex}`
	}
	if (product) {
		qry += `&product=eq(id->${product})`
	}
	if (orgCategory) {
		qry += `&meta=eq(orgCategory->${orgCategory})`
	}
	if (orgCertificate) {
		qry += `&meta=eq(certType->${orgCertificate})`
	}
	if (country) {
		qry += `&meta=eq(orgCountry->${country})`
	}
	if (state) {
		qry += `&meta=eq(orgState->${state})`
	}

	return qry
}
