import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as GrommBox, CheckBox } from 'grommet'
import { Close } from 'grommet-icons'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'
import { H2, P, H3 } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { useFormik } from 'formik'
import { EmailLabel } from './EmailLabel'

const GetLabel = (email, index, removeEmail) => (
	<EmailLabel email={email} removeEmail={removeEmail} index={index} />
)

const PostRequirementModal = ({ onClose, onConfirm }) => {
	const { t } = useTranslation()
	const [emails, setEmails] = useState([])
	const [allSellers, setAllSellers] = useState(false)

	const checkEmailLength = emails.length > 10

	const { submitForm } = useFormik({
		initialValues: emails,
		onSubmit: () => {
			onConfirm(emails, allSellers)
		},
	})

	const disableCTA = (!emails.length || checkEmailLength) && !allSellers

	return (
		<GrommBox width="720px" overflow>
			<Box row justifyContent="flex-end">
				<Button plain customIcon={<Close />} onClick={onClose} />
			</Box>
			<Box margin="0 40px">
				<H2
					style={{
						margin: '0 0 12px',
						fontSize: '30px',
						color: '#2D2D2D',
					}}
				>
					{t('postRequirementModal.heading')}
				</H2>
				<P style={{ fontSize: 18, color: '#8D93A0' }}>
					{t('postRequirementModal.subHeading')}
				</P>
				<H3 style={{ margin: '40px 0 8px', color: '#555454' }}>
					{t('postRequirementModal.label')}
				</H3>
				<ReactMultiEmail
					placeholder={t('postRequirementModal.placeholder')}
					emails={emails}
					onChange={value => {
						setEmails(value)
					}}
					validateEmail={email => {
						return isEmail(email)
					}}
					getLabel={GetLabel}
				/>
				{checkEmailLength && (
					<P
						style={{
							color: theme.color.error,
							margin: '12px 0 8px',
						}}
					>
						{t('postRequirementModal.validationMessage')}
					</P>
				)}
				<Box margin="20px 0 0">
					<CheckBox
						label={t('postRequirementModal.checkBoxLabel')}
						onChange={() => {
							setAllSellers(!allSellers)
						}}
					/>
				</Box>
				<Box margin="40px 0 0">
					<Button
						primary
						onClick={() => submitForm()}
						disabled={disableCTA}
						label={t('postRequirementModal.submit')}
					/>
				</Box>
			</Box>
		</GrommBox>
	)
}

export { PostRequirementModal }
