import { all, takeLatest, put, call } from 'redux-saga/effects'
import LogHelper from 'ui-marketplace-app/utils/logger'
import { CommunicationIdentityClient } from '@azure/communication-identity'
import {
	getIAMEndPoint,
	getCoreEndPoint,
	getMarketplaceEndPoint,
	getChatEndPoint,
	getChatAccessKey,
} from 'ui-marketplace-app/config'

import { CallWithRefreshCheck } from 'ui-marketplace-app/modules/Auth/AuthSaga'
import { getIn, setIn } from 'timm'
import request from 'ui-marketplace-app/utils/request'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MessagesDuc } from './duc'
import { chatAccessBasedOnActor } from '../MarketPlace/config'

const logger = LogHelper('client:MessagesSaga')

export default function* MessagesSaga() {
	try {
		yield all([
			takeLatest(
				MessagesDuc.creators.organisationListing().type,
				organisationListing
			),
			takeLatest(
				MessagesDuc.creators.organisationPaginatedListing().type,
				organisationPaginatedListing
			),
			takeLatest(MessagesDuc.creators.getOrgInfo().type, getOrgInfo),
			takeLatest(
				MessagesDuc.creators.transactionListing().type,
				transactionListing
			),
			takeLatest(
				MessagesDuc.creators.updateUnReadCount().type,
				updateUnReadCount
			),
			takeLatest(
				MessagesDuc.creators.sendMessageAction().type,
				sendMessageAction
			),
			takeLatest(MessagesDuc.creators.eventListing().type, eventListing),
			takeLatest(
				MessagesDuc.creators.searchHandling().type,
				searchHandling
			),
			takeLatest(
				MessagesDuc.creators.viewDocumentFromMSG().type,
				viewDocumentFromMSG
			),
			takeLatest(MessagesDuc.creators.getChatToken().type, getChatToken),
			takeLatest(
				MessagesDuc.creators.getChatThreadId().type,
				getChatThreadId
			),
			takeLatest(
				MessagesDuc.creators.chatCountCallOnly().type,
				chatCountCallOnly
			),
		])
	} catch (e) {
		logger.error(e)
	}
}

function* organisationListing() {
	try {
		const requestUrl = `${getCoreEndPoint()}communication/chat/partners`
		const origList = yield CallWithRefreshCheck(requestUrl)
		const finalListObj = {}
		const finalListArray = []
		finalListObj.totalCount = getIn(origList, ['data'])
			? getIn(origList, ['data']).length || 0
			: 0
		if (getIn(origList, ['data']).length > 0) {
			const readArrayList = []
			getIn(origList, ['data']).forEach(item => {
				if (item.unReadCount && item.unReadCount > 0) {
					finalListArray.push(item)
				} else {
					readArrayList.push(item)
				}
			})

			finalListObj.data = finalListArray.concat(readArrayList)
		}
		yield put(MessagesDuc.creators.setOrgList(finalListObj))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-org-listing'))
	}
}

function* getOrgInfo(action) {
	const { orgId } = action

	try {
		const requestUrlForNames = `${getIAMEndPoint()}clients/organizations/${orgId}`
		const orgData = yield CallWithRefreshCheck(requestUrlForNames)

		yield put(MessagesDuc.creators.setOrgInfo(getIn(orgData, ['data'])))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-getOrgInfo-listing'))
	}
}

function* organisationPaginatedListing(action) {
	const { partnersList, partnersListString } = action

	try {
		const requestUrlForNames = `${getIAMEndPoint()}clients/organizations?id=${partnersListString}&limit=99`
		const currentList = yield CallWithRefreshCheck(requestUrlForNames)
		const finalList = {}
		const list = []
		if (
			currentList &&
			getIn(currentList, ['data', 'list']) &&
			getIn(currentList, ['data', 'list']).length > 0
		) {
			Object.keys(partnersList).forEach(item => {
				getIn(currentList, ['data', 'list']).forEach(element => {
					if (
						getIn(element, ['id']) === getIn(partnersList, [item])
					) {
						list.push(element)
					}
				})
			})
			finalList.list = list
			finalList.totalCount = getIn(currentList, ['data', 'total'])
		}
		yield put(MessagesDuc.creators.setOrgPaginatedList(finalList))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('fetch-orgPaginated-listing')
		)
	}
}

function* sendMessageAction(action) {
	const { id, actionMsg } = action
	try {
		const sendMsgUrl = `${getCoreEndPoint()}chat/event?chatThread=${id}&event=${actionMsg}`
		yield CallWithRefreshCheck(sendMsgUrl, {
			method: 'POST',
		})
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('fetch-sendMessageAction-listing')
		)
	}
}

function* updateUnReadCount(action) {
	const { data, organisationListFull, transactionListFull } = action
	try {
		const currentOrgIndex = getIn(organisationListFull, ['data']).findIndex(
			item => getIn(item, ['id']) === getIn(data, ['partnerId'])
		)
		const currentRFQIndex = getIn(transactionListFull, ['list']).findIndex(
			item => getIn(item, ['masterTopic']) === getIn(data, ['id'])
		)

		const currentEventIndex = getIn(transactionListFull, [
			'list',
			[currentRFQIndex],
			'subDocsChatMeta',
		]).findIndex(
			item => getIn(item, ['docType']) === getIn(data, ['docType'])
		)
		const currentOrgUnRead = getIn(organisationListFull, [
			'data',
			[currentOrgIndex],
			'unReadCount',
		])
		const currentRFQunRead = getIn(transactionListFull, [
			'list',
			[currentRFQIndex],
			'unReadCount',
		])
		const currentEventUnRead = getIn(transactionListFull, [
			'list',
			[currentRFQIndex],
			'subDocsChatMeta',
			[currentEventIndex],
			'unReadCount',
		])
		const newTransactionList = setIn(
			transactionListFull,
			[
				'list',
				[currentRFQIndex],
				'subDocsChatMeta',
				[currentEventIndex],
				'unReadCount',
			],
			currentEventUnRead - getIn(data, ['count']) < 0
				? 0
				: currentEventUnRead - getIn(data, ['count'])
		)
		const currentNewTransactionList = setIn(
			newTransactionList,
			['list', [currentRFQIndex], 'unReadCount'],
			currentRFQunRead - getIn(data, ['count']) < 0
				? 0
				: currentRFQunRead - getIn(data, ['count'])
		)
		const newOrgList = setIn(
			organisationListFull,
			['data', [currentOrgIndex], 'unReadCount'],
			currentOrgUnRead - getIn(data, ['count']) < 0
				? 0
				: currentOrgUnRead - getIn(data, ['count'])
		)
		yield put(MessagesDuc.creators.setOrgList(newOrgList))
		yield put(MessagesDuc.creators.setTransList(currentNewTransactionList))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('fetch-update-unread-listing')
		)
	}
}

function* transactionListing(action) {
	const { id, orgList, startId = '', currentList = [] } = action
	try {
		const finalList = {}
		const rfqListUrl = `${getCoreEndPoint()}communication/chat/meta-paginated-list/partner-org/${id}?threadType=master&startID=${startId}`
		const rfqList = yield CallWithRefreshCheck(rfqListUrl)
		finalList.list =
			currentList && currentList.length > 0
				? currentList.concat(getIn(rfqList, ['data', 'list']))
				: getIn(rfqList, ['data', 'list'])
		finalList.totalCount = getIn(rfqList, ['data', 'total'])
		finalList.nextStartID = getIn(rfqList, ['data', 'nextStartID'])
		let countData = 0
		let indexNumber = 0
		if (getIn(orgList, ['data']) && getIn(orgList, ['data']).length > 0) {
			getIn(orgList, ['data']).forEach((item, index) => {
				if (
					getIn(item, ['id']) === id &&
					getIn(finalList, ['list']) &&
					getIn(finalList, ['list']).length > 0
				) {
					indexNumber = index
					getIn(finalList, ['list']).forEach(ele => {
						if (
							getIn(ele, ['unReadCount']) &&
							getIn(ele, ['unReadCount']) > 0
						) {
							countData += getIn(ele, ['unReadCount'])
						}
					})
				}
			})
		}

		if (
			countData > 0 &&
			getIn(orgList, ['data', [indexNumber]]) &&
			getIn(orgList, ['data', [indexNumber], 'unReadCount']) !== countData
		) {
			const newOrgList = setIn(
				orgList,
				['data', [indexNumber], 'unReadCount'],
				countData
			)
			yield put(MessagesDuc.creators.setOrgList(newOrgList))
		}
		yield put(MessagesDuc.creators.setTransList(finalList))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-transaction-listing'))
	}
}

function* eventListing(action) {
	const { id } = action
	try {
		const eventListUrl = `${getIAMEndPoint()}communication/chat/chatThread/master-topic/${id}`
		const eventList = yield CallWithRefreshCheck(eventListUrl)
		yield put(MessagesDuc.creators.setEventList(eventList))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-event-listing'))
	}
}

function* searchHandling(action) {
	const { id } = action
	try {
		const searchListUrl = `${getIAMEndPoint()}communication/chat/chatThread/master-topic/${id}`
		const searchList = yield CallWithRefreshCheck(searchListUrl)
		yield put(MessagesDuc.creators.setSearch(searchList))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-search-listing'))
	}
}

function* viewDocumentFromMSG(action) {
	try {
		const { id, event } = action
		let requestUrl = ''
		if (event === 'rfq') {
			requestUrl = `${getMarketplaceEndPoint()}rfq/${id}`
		} else {
			requestUrl = `${getMarketplaceEndPoint()}organizations/-/quote/${id}`
		}
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)
		const finalData =
			event === 'rfq' ? getIn(data, ['buyer']) : getIn(data, ['buyerOrg'])
		yield put(MessagesDuc.creators.setMSGDocumentDetails(finalData))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-rfqMSG-details'))
	}
}

function* getChatToken({ orgRole }) {
	try {
		if (!chatAccessBasedOnActor[orgRole]) {
			return
		}
		const requestUrl = `${getIAMEndPoint()}communications/chat/clientid/${
			chatAccessBasedOnActor[orgRole]
		}`
		const options = {
			method: 'GET',
		}
		const { data = [] } = yield call(request, requestUrl, options)

		const { acsIdentity } = data

		const token = yield call(fetchTokenFromACS, acsIdentity)
		yield put(MessagesDuc.creators.setChatToken(token))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

async function fetchTokenFromACS(acsIdentity) {
	try {
		const connectionString = `endpoint=${getChatEndPoint()};accessKey=${getChatAccessKey()}`

		const client = new CommunicationIdentityClient(connectionString)

		const { token } = await client.getToken(
			{ communicationUserId: acsIdentity },
			['chat']
		)

		return token
	} catch (err) {
		logger.log(err)
		throw err
	}
}

function* getChatThreadId(action) {
	const { tradeId, docType, documentId } = action
	try {
		if (!tradeId) {
			return
		}
		yield put(MessagesDuc.creators.setChatUnreadCount(0))
		const requestUrl = `${getIAMEndPoint()}communication/chat/chatThread/master-topic/${tradeId}`
		const options = {
			method: 'GET',
		}
		const { data = [] } = yield call(request, requestUrl, options)
		const documentNumber = {}
		const threadTypes = {}
		const docTypeList = []
		let contractChatIdentifier = []
		data.forEach(obj => {
			threadTypes[`${obj.meta.documentID}~${obj.docType}`] = obj.acsThread
			documentNumber[`${obj.meta.documentID}~${obj.docType}`] =
				getIn(obj, ['meta', 'documentNumber']) || ''
			docTypeList.push(`${obj.meta.documentID}~${obj.docType}`)
			if (
				obj.docType === 'contract-broker-seller' ||
				obj.docType === 'contract-buyer-seller'
			) {
				contractChatIdentifier = `${obj.meta.documentID}~${obj.docType}`
			}
		})
		yield put(
			MessagesDuc.creators.setContractChatIdentifier(
				contractChatIdentifier || null
			)
		)
		yield put(MessagesDuc.creators.setDocTypeList(docTypeList))
		yield put(MessagesDuc.creators.setChatThreads(threadTypes))
		yield put(MessagesDuc.creators.setDocumentNumber(documentNumber))
		yield put(MessagesDuc.creators.setDocumentChatList(data))

		const currentDocumentThreadID = threadTypes[`${documentId}~${docType}`]
		if (!currentDocumentThreadID) {
			return
		}
		const chatCountUrl = `${getCoreEndPoint()}communication/chat/meta/orgid/-/chatthread/${currentDocumentThreadID}`
		const chatCountOptions = {
			method: 'GET',
		}
		const { data: chatCountData = [] } = yield call(
			request,
			chatCountUrl,
			chatCountOptions
		)
		const orgChatUnredaCount = getIn(chatCountData, ['unReadCount']) || 0
		yield put(MessagesDuc.creators.setChatUnreadCount(orgChatUnredaCount))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* chatCountCallOnly(action) {
	const { chatThread } = action
	try {
		const chatCountUrl = `${getCoreEndPoint()}communication/chat/meta/orgid/-/chatthread/${chatThread}`
		const chatCountOptions = {
			method: 'GET',
		}
		const { data: chatCountData = [] } = yield call(
			request,
			chatCountUrl,
			chatCountOptions
		)
		const orgChatUnredaCount = getIn(chatCountData, ['unReadCount']) || 0
		yield put(MessagesDuc.creators.setChatUnreadCount(orgChatUnredaCount))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}
