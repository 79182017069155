import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'ui-marketplace-app/modules/App/components/Error'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { ContractNo1 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo1'
import { ContractNo2 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo2'
import { ContractNo3 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo3'
import { ContractNo4 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo4'
import { ContractNo5 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo5'
import { ContractNo6 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo6'
import { ContractNo7 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo7'
import { ContractNo8 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo8'
import { ContractNo9 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo9'
import { Fosfa81 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/Fosfa81'
import { AttachOwnContract } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/AttachOwnContract'

const modulesMap = {
	'contract-no-1': ContractNo1,
	'contract-no-2': ContractNo2,
	'contract-no-3': ContractNo3,
	'contract-no-4': ContractNo4,
	'contract-no-5': ContractNo5,
	'contract-no-6': ContractNo6,
	'contract-no-7': ContractNo7,
	'contract-no-8': ContractNo8,
	'contract-no-9': ContractNo9,
	fosfa81: Fosfa81,
	custom: AttachOwnContract,
}

const DibizContractGenerator = () => {
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { documentType } = payload
	const Component = modulesMap[documentType] || NotFoundBlock

	return <Component />
}

export { DibizContractGenerator }
