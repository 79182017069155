/* @flow */
import React from 'react'
import styled from 'styled-components'

const CLIENT_PUBLIC_PATH_FOR_ICONS = '/client'

type Props = {
	color?: string,
	shadow?: boolean,
	rotate180?: boolean,
	glyph: any,
	children?: any,
}

const IconBase = styled.svg(props => ({
	color: props.color ? props.color : 'inherit',
	fill: 'currentColor',
	width: 'inherit',
	height: 'inherit',
	...(props.shadow && {
		filter: 'drop-shadow(2px 1px 4px #000)',
	}),
	...(props.rotate180 && {
		transform: 'rotate(180deg)',
	}),
}))

/** Dirty hack since svg-sprite webpack plugin does not work with isomor
 *  rendering. Be sure to sync the above path in case your icons aren't showing up
 *
 */
export const wrapPublicPath = (url: string) => {
	// $FlowFixMe
	if (__SERVER__ && url && url.indexOf(CLIENT_PUBLIC_PATH_FOR_ICONS) < 0) {
		return `${CLIENT_PUBLIC_PATH_FOR_ICONS}${url}`
	}

	return url
}

let index = 0
const getUniqueIndex = () => {
	const id = `Icon-${(index += 1)}`

	return id
}

const Icon = (
	{ glyph = { viewBox: '0 0 0 0', id: '' }, ...props }: Props // $FlowFixMe
) => (
	<>
		{/* $FlowFixMe */}
		<IconBase viewBox={`${glyph.viewBox}`} {...props}>
			<use
				// xlinkHref={__PROD__ ? wrapPublicPath(glyph.url) : `#${glyph.id}`}
				xlinkHref={`#${glyph.id || getUniqueIndex()}`}
			/>
		</IconBase>
	</>
)

const IconWrapper = styled.div(p => ({
	display: 'flex',
	width: p.size || p.width || 15,
	height: p.size || p.height || 15,
	color: p.color || p.theme.color.primary,
	...(p.margin && { margin: p.margin }),
	...(p.disabled && { color: p.theme.color.grey2, cursor: 'disabled' }),
	...(p.sortUp && { marginTop: -2 }),
	...(p.sortDown && { marginTop: 6 }),
	...(p.hoverEffect && {
		willChange: 'transform',
		transition: 'transform 0.3s ease-out',
		transform: 'scale(1)',
		'&:hover': { transform: 'scale(1.1)' },
	}),
}))

export { Icon, IconWrapper }
