import React, { useState, useRef, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'timm'
import { Drop } from 'grommet'
import moment from 'moment'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MessagesDuc } from 'ui-marketplace-app/modules/Messages/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	RFQ_STATUS,
	STATUS_TEXT_COLOR,
	STATUS_BACKGROUND,
	MONTHS,
	SHIPMENT_PERIOD,
	CONTRACT_TYPE,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { KeyValueSegment } from 'ui-marketplace-app/shared/components/KeyValueSegment'
import { ChatModalComponent } from 'ui-marketplace-app/modules/Messages/components/ChatModalComponent'
import { SummaryBlock } from 'ui-marketplace-app/shared/components/DocumentLead'
import { getDateByFormat } from 'ui-marketplace-app/utils/date'
import { getIAMEndPoint } from 'ui-marketplace-app/config'
import request from 'ui-marketplace-app/utils/request'
import {
	Button,
	ButtonIconWrapper,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { Label } from 'ui-lib/components/Label'
import { H2, H4, Text, P } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import VerifiedIcon from 'ui-lib/icons/arrow_forward_down.svg'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import CheckCircleIcon from 'ui-lib/icons/check_circle_black.svg'
import { TabButtons } from 'ui-lib/components/TabButtons'
import { FileUpload } from 'ui-lib/components/FileUpload'
import HighlightOffIcon from 'ui-lib/icons/highlight_off_black.svg'
import ClockIcon from 'ui-lib/icons/watch_later_black.svg'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import styled from 'styled-components'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import InfoIcon from 'ui-lib/icons/info_black.svg'
import ChatIcon from 'ui-lib/icons/chat_icon.svg'
import Documents from 'ui-lib/icons/documents.svg'
import EditEllipsis from 'ui-lib/icons/editEllipsis.svg'
import DeleteIcon from 'ui-lib/icons/delete_outline.svg'
import ConvertToContract from 'ui-lib/icons/trade-manager.svg'
import EditIcon from 'ui-lib/icons/edit.svg'
import PlusIcon from 'ui-lib/icons/plus.svg'
import UploadIcon from 'ui-lib/icons/upload.svg'
import ConvertToPO from 'ui-lib/icons/convertToPO.svg'
import { Input } from 'ui-lib/components/Input'
import { Modal } from 'ui-lib/components/Modal'
import { OriginalRequirement } from 'ui-lib/components/OriginalRequirement/index'
import { supplyChainModels } from 'ui-marketplace-app/modules/Admin/components/StorageDetails'
import DownloadIcon from 'ui-lib/icons/file_download_black_24dp.svg'

const tableHeaderStyles = {
	background: '#C9EEF5',
	color: '#458490',
	padding: 16,
	position: 'relative',
}

const tableRowStyles = {
	border: '1px solid #CAD2DD',
	padding: 16,
}

const RFQ_STATUS$WICONS = [
	{
		icon: CheckCircleIcon,
		_status: 'accepted',
	},
	{
		icon: ClockIcon,
		_status: 'review',
	},
	{
		icon: HighlightOffIcon,
		_status: 'rejected',
	},
]

const Wrapper = styled.div`
	margin-top: 12px;
	margin-bottom: 20px;

	width: 740px;
	height: auto;
	background: #fff1dd 0% 0% no-repeat padding-box;
	border: 1px solid #f09112;
	border-left: 10px solid #f09112;
	border-radius: 6px;
	opacity: 1;
	color: #c97b12;
	letter-spacing: 0px;
	font-size: 16px;
`

const AttachmentMenu = ({
	t,
	data,
	getCurrentOrgID,
	setSelectedFile,
	setRenameModal,
	deleteFileAttachment,
}) => {
	const attachmentMenuRef = useRef()
	const [showAttachmentMenu, setShowAttachmentMenu] = useState(false)

	return (
		<>
			<Box
				overflow
				key={data?.id}
				alignItems="center"
				margin="30px 30px"
				width="100px"
			>
				<Box
					onClick={() => {
						window.open(getIn(data, ['meta', 'fullURL']), '_blank')
					}}
					style={{
						backgroundColor: '#ffeed4',
					}}
					alignItems="center"
					padding="30px 30px"
				>
					<Icon
						color="#9e797a"
						glyph={Documents}
						style={{
							height: 50,
							width: 40,
						}}
					/>
				</Box>
				{getCurrentOrgID === getIn(data, ['organizationID']) && (
					<Button
						rounded
						customIcon={
							<ButtonIconWrapper
								size={30}
								ref={attachmentMenuRef}
								lightBG
							>
								<Icon
									color={theme.color.grey15}
									glyph={EditEllipsis}
								/>
							</ButtonIconWrapper>
						}
						onClick={() => {
							setShowAttachmentMenu(true)
							setSelectedFile(data)
						}}
					/>
				)}
				<P color={theme.color.grey15}>{data?.meta?.name}</P>
			</Box>
			{showAttachmentMenu && (
				<Box
					style={{
						width: '44px',
					}}
				>
					<Drop
						stretch={false}
						onEsc={() => setShowAttachmentMenu(false)}
						onClickOutside={() => setShowAttachmentMenu(false)}
						target={attachmentMenuRef.current}
						align={{
							top: 'bottom',
							right: 'right',
						}}
					>
						<Box
							alignItems="flex-start"
							padding="10px 10px"
							style={{
								width: '265px',
								borderBottom: `1px solid ${theme.color.grey4}`,
							}}
							row
							onClick={() => {
								setShowAttachmentMenu(false)
								setRenameModal(true)
							}}
						>
							<Box
								padding="10px 10px"
								style={{
									background: theme.color.blue0,
								}}
							>
								<IconWrapper size={25}>
									<Icon
										color={theme.color.primary}
										glyph={EditIcon}
									/>
								</IconWrapper>
							</Box>
							<Box flex overflow padding="0px 20px">
								<H4 color={theme.color.primary}>
									{t('quote.renameFile')}
								</H4>
								<P color={theme.color.grey15}>
									{t('quote.renameFileline2')}
								</P>
							</Box>
						</Box>

						<Box
							alignItems="flex-start"
							padding="10px 10px"
							style={{
								width: '265px',
								borderBottom: `1px solid ${theme.color.grey4}`,
							}}
							row
							onClick={() => {
								setShowAttachmentMenu(false)
								deleteFileAttachment()
							}}
						>
							<Box
								padding="10px 10px"
								style={{
									background: '#e9bdbb',
								}}
							>
								<IconWrapper size={25}>
									<Icon
										color={theme.color.error}
										glyph={DeleteIcon}
									/>
								</IconWrapper>
							</Box>
							<Box flex overflow padding="0px 20px">
								<H4 color={theme.color.error}>
									{t('quote.deleteFile')}
								</H4>
								<P color={theme.color.grey15}>
									{t('quote.deleteFileline2')}
								</P>
							</Box>
						</Box>
					</Drop>
				</Box>
			)}
		</>
	)
}

const ViewQuote = () => {
	const actor = useSelector(MarketPlaceDuc.selectors.getMarketPlaceActor)
	const document = useSelector(MarketPlaceDuc.selectors.getQuoteDocument)
	const quoteDownloadUrl = useSelector(
		MarketPlaceDuc.selectors.getQuoteDownloadUrl
	)
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule } = payload
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [tooltipToggle, setTooltipToggle] = useState(false)
	const ref = useRef()
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const editUploadBtnRef = useRef()
	const [renameModal, setRenameModal] = useState(false)

	const [showUploadModal, setShowUploadModal] = useState(false)
	const [showEditUploadMenu, setShowEditUploadMenu] = useState(false)
	const [selectedFile, setSelectedFile] = useState({})
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [newFileName, setNewFileName] = useState('')
	const [showChatBox, setShowChatBox] = useState(false)

	const files = getIn(document, ['meta', 'files']) || []

	const uom = getIn(document, ['rfq', 'product', 'uom'])
	const currency = getIn(document, ['currency', 'code'])
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const VIEW_TAB_CONFIG = [
		{
			name: 'viewQuote',
			label: t('quote.viewQuote'),
		},
		{
			name: 'attachments',
			label: t('quote.attachments'),
		},
	]

	useEffect(() => {
		dispatch(MessagesDuc.creators.setDocTypeList([]))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [viewQuoteTabKey, attachmentTabKey] = VIEW_TAB_CONFIG.map(k => k.name)

	const activeTabs = useMemo(() => {
		return [...VIEW_TAB_CONFIG]
	}, [VIEW_TAB_CONFIG])

	const [activeTab, setActiveTab] = useState('viewQuote')

	const isViewQuoteTabActive = activeTab === viewQuoteTabKey
	const isAttachmentTabActive = activeTab === attachmentTabKey

	const addAttachmentToRFQ = () => {
		const addpayload = {
			meta: {
				files: [...files, ...uploadedFiles],
			},
		}
		setUploadedFiles([])
		dispatch(
			MarketPlaceDuc.creators.addAttachmentToQuote(
				addpayload,
				t('common.successUpdate'),
				getIn(document, ['id']),
				rootModule
			)
		)
	}

	const chatBoxData = data => {
		setShowChatBox(data)
	}

	const editFileName = () => {
		const filteredFile = files.filter(el => {
			return el.id !== selectedFile?.id
		})
		const newFiles = [
			...filteredFile,
			{
				...selectedFile,
				meta: {
					...selectedFile?.meta,
					name: newFileName,
				},
			},
		]

		const editNamepayload = {
			meta: {
				files: newFiles,
			},
		}

		dispatch(
			MarketPlaceDuc.creators.addAttachmentToQuote(
				editNamepayload,
				t('common.successUpdate'),
				getIn(document, ['id']),
				rootModule
			)
		)
	}

	const deleteFileAttachment = () => {
		const filteredFile = files.filter(el => {
			return el.id !== selectedFile?.id
		})
		const deleteFilepayload = {
			meta: {
				files: filteredFile,
			},
		}

		dispatch(
			MarketPlaceDuc.creators.addAttachmentToQuote(
				deleteFilepayload,
				t('common.successUpdate'),
				getIn(document, ['id']),
				rootModule
			)
		)
	}

	const sharePrivateFile = async docs => {
		if (!docs || docs.length === 0) {
			return
		}
		const fileIDs = (docs || []).map(d => d.id)
		const buyerId = getIn(document, ['buyerOrg', 'id']) || ''
		const sellerId = getIn(document, ['organization', 'id']) || ''
		const json = {
			fileIDs,
			shareWith: [buyerId, sellerId],
		}
		const options = {
			method: 'PUT',
			body: JSON.stringify(json),
		}
		const requestUrl = `${getIAMEndPoint()}files/share`
		const { data = {} } = await request(requestUrl, options)

		return data
	}

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('quote.marketPlace'),
			name: 'marketplace',
			isActive: true,
		},
		{
			label: t('quote.viewQuote'),
			name: 'view-quote',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'marketplace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule,
					}
				)
			)
		}
	}

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box>
				<Title
					title={t('quote.viewQuotationDetails')}
					icon={LeftArrowIcon}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE,
								{ rootModule }
							)
						)
					}
				/>

				<Modal
					forceCloseviaButton
					size="large"
					show={showUploadModal}
					heading={t('common.import')}
					body={
						<>
							<FileUpload
								type="private"
								name="file"
								size="5242880"
								label={t('quote.uploadAttachment')}
								required
								supportedFileType=".jpg,.png,.pdf"
								outline
								modalTriggerBtnLabel={t('common.upload')}
								currentUploads={uploadedFiles}
								onDocumentChange={async uploadedDoc => {
									await sharePrivateFile(uploadedDoc)
									setUploadedFiles(uploadedDoc)
								}}
								onUploadFailure={({ message }) => {
									dispatch(
										AppDuc.creators.showToast({
											messageType: 'error',
											message,
										})
									)
								}}
							/>
						</>
					}
					confirmlabel={t('common.proceed')}
					isDisabled={uploadedFiles.length === 0}
					closelabel={t('common.close')}
					onClose={() => {
						setUploadedFiles([])
						setShowUploadModal(false)
					}}
					onConfirm={() => {
						setShowUploadModal(false)
						addAttachmentToRFQ()
					}}
				/>
				<Modal
					forceCloseviaButton
					show={renameModal}
					heading={t('quote.renameFile')}
					body={
						<Box>
							<Input
								name="uploadedFileName"
								value={
									selectedFile?.meta?.name
										? selectedFile?.meta?.name
										: ''
								}
								onChange={event =>
									setNewFileName(event.target.value)
								}
								required
							/>
						</Box>
					}
					closelabel={t('common.back')}
					confirmlabel={t('quote.rename')}
					onClose={() => {
						setRenameModal(false)
					}}
					onConfirm={() => {
						setRenameModal(false)
						editFileName()
					}}
				/>

				{getIn(document, ['status']) === 'complete' && (
					<Wrapper>
						<Box row padding={16} alignItems="center">
							<IconWrapper size={24} style={{ marginRight: 16 }}>
								<Icon glyph={InfoIcon} color="#F09112" />
							</IconWrapper>
							{actor === 'mp_sales_marketing_officer'
								? t('quote.alreadyResponded')
								: t('quote.linkToViewPO')}
						</Box>
						{actor === 'mp_purchase_officer' ? (
							<ButtonWithNoBorder
								label={t('quote.viewPO')}
								extendStyles={{
									color: '#C97B12',
									marginLeft: 54,
								}}
								onClick={() => {
									dispatch(
										getIn(document, [
											'meta',
											'entity',
											'status',
											'state',
										]) === 'draft'
											? MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE,
													{
														rootModule:
															'purchase-order',
														action: 'attachDetails',
														documentReference: getIn(
															document,
															[
																'meta',
																'entity',
																'id',
															]
														),
														documentStatus: 'draft',
													},
													{},
													{},
													true
											  )
											: MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
													{
														rootModule:
															'purchase-order',
														documentReference: getIn(
															document,
															[
																'meta',
																'entity',
																'id',
															]
														),
													},
													{},
													{},
													true
											  )
									)
								}}
							/>
						) : (
							getIn(document, [
								'meta',
								'entity',
								'status',
								'state',
							]) === 'submitted' && (
								<ButtonWithNoBorder
									label={t('quote.viewPO')}
									extendStyles={{
										color: '#C97B12',
										marginLeft: 54,
									}}
									onClick={() => {
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
												{
													rootModule:
														'purchase-order',
													documentReference: getIn(
														document,
														['meta', 'entity', 'id']
													),
												},
												{},
												{},
												true
											)
										)
									}}
								/>
							)
						)}
					</Wrapper>
				)}
				<Box row center>
					<Box center padding="20px 0px">
						<TabButtons
							fontSize={theme.fontSize.xl}
							list={activeTabs}
							key={activeTab}
							id="QuoteBtnGrp"
							activeIndex={VIEW_TAB_CONFIG.findIndex(
								q => q.name === activeTab
							)}
							onChange={({ name }) => {
								setActiveTab(name)
							}}
							isMobile={isMobile}
						/>
					</Box>

					<Box
						style={{
							right: isMobile ? '0px' : '30px',
							position: 'absolute',
						}}
						row
						justifyContent="flex-end"
					>
						{quoteDownloadUrl && (
							<a
								download
								target="_blank"
								title="Download Quote"
								href={quoteDownloadUrl}
								rel="noopener noreferrer"
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<IconWrapper
									size={32}
									color="#000000"
									style={{
										cursor: 'pointer',
									}}
								>
									<Icon glyph={DownloadIcon} />
								</IconWrapper>
							</a>
						)}
						<Box
							row={!isMobile}
							alignItems={isMobile ? 'center' : 'normal'}
						>
							{(getIn(document, ['status']) !== 'completed' ||
								getIn(document, ['status']) === 'rejected') && (
								<Box
									onClick={() =>
										setShowEditUploadMenu(
											!showEditUploadMenu
										)
									}
									style={{
										cursor: 'pointer',
										background: '#3F56C4',
										width: '48px',
										height: '48px',
										boxShadow: '2px 8px 16px #2428453D',
										borderRadius: '6px',
										color: '#3F56C4',
										margin: '0px 20px',
									}}
									ref={editUploadBtnRef}
									center
								>
									<IconWrapper
										size={25}
										ref={ref}
										style={{ color: theme.color.white }}
									>
										<Icon glyph={PlusIcon} />
									</IconWrapper>
								</Box>
							)}
							{(getIn(document, ['status']) !== 'completed' ||
								getIn(document, ['status']) === 'rejected') &&
								actor === 'mp_purchase_officer' && (
									<>
										<Box
											ref={ref}
											style={{
												cursor: 'pointer',
												background: '#3F56C4',
												width: '48px',
												height: '48px',
												boxShadow:
													'2px 8px 16px #2428453D',
												borderRadius: '6px',
												marginTop: isMobile
													? '10px'
													: '0px',
											}}
											onClick={() => {
												setTooltipToggle(!tooltipToggle)
											}}
											center
										>
											<IconWrapper
												color="white"
												size={25}
												ref={ref}
											>
												<Icon
													glyph={VerifiedIcon}
													style={{
														transform:
															'rotate(90deg)',
													}}
												/>
											</IconWrapper>
											{tooltipToggle && (
												<Drop
													target={ref.current}
													overflow="unset"
													align={{
														top: 'bottom',
														right: 'right',
													}}
													onClickOutside={() =>
														setTooltipToggle(false)
													}
												>
													{RFQ_STATUS$WICONS.map(
														({ _status, icon }) => (
															<Box
																key={_status}
																row
																alignItems="center"
																padding={4}
																style={{
																	borderBottom:
																		'1px solid #D9DEE5',
																}}
															>
																<IconWrapper
																	color={
																		STATUS_TEXT_COLOR[
																			_status
																		]
																	}
																	size={25}
																>
																	<Icon
																		glyph={
																			icon
																		}
																	/>
																</IconWrapper>
																<Label
																	color="#fff"
																	small
																	key=",s"
																	style={{
																		padding: 8,
																		cursor:
																			'pointer',
																		color:
																			STATUS_TEXT_COLOR[
																				_status
																			],
																		marginLeft:
																			'12px',
																	}}
																	onClick={() => {
																		setTooltipToggle(
																			!tooltipToggle
																		)

																		dispatch(
																			MarketPlaceDuc.creators.changeQuoteStatus(
																				getIn(
																					document,
																					[
																						'id',
																					]
																				),
																				_status,
																				t(
																					'common.successUpdate'
																				)
																			)
																		)
																	}}
																>
																	{t(
																		RFQ_STATUS[
																			_status
																		]
																	)}
																</Label>
															</Box>
														)
													)}
												</Drop>
											)}
										</Box>
									</>
								)}
						</Box>
						{showEditUploadMenu && (
							<Box>
								<Drop
									target={editUploadBtnRef.current}
									align={{
										top: 'bottom',
										right: 'right',
									}}
									onEsc={() => setShowEditUploadMenu(false)}
									onClickOutside={() =>
										setShowEditUploadMenu(false)
									}
								>
									{actor === 'mp_purchase_officer' ? (
										<>
											{getIn(document, [
												'meta',
												'contractGenerated',
											]) !== true &&
												!(
													document.offerValidity &&
													moment(
														document.offerValidity
													).isBefore(moment())
												) && (
													<Box
														overflow
														alignItems="flex-start"
														padding="10px 10px"
														style={{
															width: '265px',
															borderBottom: `1px solid ${theme.color.grey4}`,
															cursor: 'pointer',
														}}
														row
														onClick={() => {
															dispatch(
																MainRouteDuc.creators.switchPage(
																	MainRouteDuc
																		.types
																		.MARKETPLACE$CONTRACTS,
																	{
																		rootModule,
																		action:
																			'create',
																		documentReference: getIn(
																			document,
																			[
																				'id',
																			]
																		),
																	}
																)
															)
														}}
													>
														<Box
															padding="10px 10px"
															style={{
																background:
																	theme.color
																		.blue0,
															}}
														>
															<IconWrapper
																size={25}
															>
																<Icon
																	color={
																		theme
																			.color
																			.primary
																	}
																	glyph={
																		ConvertToContract
																	}
																	style={{
																		transform:
																			'scaleX(-1)',
																	}}
																/>
															</IconWrapper>
														</Box>
														<Box
															flex
															overflow
															padding="0px 20px"
														>
															<H4
																color={
																	theme.color
																		.primary
																}
															>
																{t(
																	'quote.convertToContract'
																)}
															</H4>
															<P
																color={
																	theme.color
																		.grey15
																}
															>
																{t(
																	'quote.convertToContractHelpText'
																)}
															</P>
														</Box>
													</Box>
												)}
											{!(
												document.offerValidity &&
												moment(
													document.offerValidity
												).isBefore(moment())
											) && (
												<Box
													overflow
													alignItems="flex-start"
													padding="10px 10px"
													style={{
														width: '265px',
														borderBottom: `1px solid ${theme.color.grey4}`,
														cursor: 'pointer',
													}}
													row
													onClick={() => {
														dispatch(
															MarketPlaceDuc.creators.convertToPurchaseOrder(
																getIn(
																	document,
																	['id']
																)
															)
														)
													}}
												>
													<Box
														padding="10px 10px"
														style={{
															background:
																'#C9EEF5',
														}}
													>
														<IconWrapper size={25}>
															<Icon
																color="#458490"
																glyph={
																	ConvertToPO
																}
															/>
														</IconWrapper>
													</Box>
													<Box
														flex
														overflow
														padding="0px 20px"
													>
														<H4 color="#458490">
															{t(
																'quote.convertToPO'
															)}
														</H4>
														<P
															color={
																theme.color
																	.grey15
															}
														>
															{t(
																'quote.convertToPOHelpText'
															)}
														</P>
													</Box>
												</Box>
											)}
										</>
									) : (
										<>
											<Box
												overflow
												alignItems="flex-start"
												padding="10px 10px"
												style={{
													width: '265px',
													borderBottom: `1px solid ${theme.color.grey4}`,
													cursor: 'pointer',
												}}
												row
												onClick={() => {
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.MARKETPLACE$ACTION,
															{
																rootModule,
																action:
																	'edit-quote',
																documentReference: getIn(
																	document,
																	['id']
																),
															}
														)
													)
												}}
											>
												<Box
													padding="10px 10px"
													style={{
														background:
															theme.color.blue0,
													}}
												>
													<IconWrapper size={25}>
														<Icon
															color={
																theme.color
																	.primary
															}
															glyph={UploadIcon}
														/>
													</IconWrapper>
												</Box>
												<Box
													flex
													overflow
													padding="0px 20px"
												>
													<H4
														color={
															theme.color.primary
														}
													>
														{t('quote.editQuote')}
													</H4>
													<P
														color={
															theme.color.grey15
														}
													>
														{t(
															'quote.editQuoteHelpText'
														)}
													</P>
												</Box>
											</Box>
										</>
									)}
									<Box
										alignItems="flex-start"
										padding="10px 10px"
										row
										onClick={() => {
											setShowEditUploadMenu(false)
											setShowUploadModal(true)
										}}
										style={{
											width: '265px',
											cursor: 'pointer',
										}}
									>
										<Box
											padding="10px 10px"
											style={{
												background: '#fff3e5',
											}}
										>
											<IconWrapper size={25}>
												<Icon
													color="#e99f52"
													glyph={ChatIcon}
												/>
											</IconWrapper>
										</Box>
										<Box flex overflow padding="0px 20px">
											<H4 color="#e99f52">
												{t('quote.attachAnotherFile')}
											</H4>
											<P color={theme.color.grey15}>
												{t(
													'quote.attachAnotherFileHelpText'
												)}
											</P>
										</Box>
									</Box>
								</Drop>
							</Box>
						)}
					</Box>
				</Box>
				{isViewQuoteTabActive && (
					<Box row>
						<Box
							style={{
								flex: 1,
								background: '#fff',
								padding: '28px 40px',
							}}
						>
							<Box
								row={!isMobile}
								justifyContent="space-between"
								alignItems="baseline"
							>
								<H2 color="#158853">
									{getIn(document, ['number'])}
								</H2>
								<Box row justifyContent="flex-end">
									<Box
										style={{
											backgroundColor:
												STATUS_BACKGROUND[
													getIn(document, ['status'])
												],
											padding: '8px 16px',
											margin: '0 0 8px',
											borderRadius: '5px',
											opacity: 1,
											textAlign: 'center',
										}}
									>
										<Label
											style={{
												color:
													STATUS_TEXT_COLOR[
														getIn(document, [
															'status',
														])
													],
											}}
										>
											{t(
												RFQ_STATUS[
													getIn(document, ['status'])
												]
											).toUpperCase()}
										</Label>
									</Box>
								</Box>
							</Box>
							<Box
								row={!isMobile}
								margin="16px 0"
								justifyContent="space-between"
							>
								<Box width={isMobile ? '100%' : '50%'}>
									<Label
										style={{ color: theme.color.grey15 }}
									>
										{t('quote.issued')}{' '}
										{actor === 'mp_sales_marketing_officer'
											? t('quote.to')
											: t('quote.by')}
									</Label>
									<Spacer size={8} />

									<Title
										title={
											actor ===
											'mp_sales_marketing_officer'
												? getIn(document, [
														'buyerOrg',
														'name',
												  ])
												: getIn(document, [
														'organization',
														'name',
												  ])
										}
										note={
											actor ===
											'mp_sales_marketing_officer'
												? getIn(document, [
														'buyerOrg',
														'categories',
														0,
														'name',
												  ])
												: getIn(document, [
														'organization',
														'categories',
														0,
														'name',
												  ])
										}
										small
									/>
									<Spacer size={16} />
									<Text
										style={{
											lineHeight: '1.5',
											width: '100%',
											overflow: 'hidden',
											color: '#8D93A0',
											maxWidth: '200px',
										}}
									>
										{actor === 'mp_sales_marketing_officer'
											? `${getIn(document, [
													'buyerOrg',
													'primaryAddress',
													'line1',
											  ]) || ''} ,
								${getIn(document, ['buyerOrg', 'primaryAddress', 'city']) || ''},
								${getIn(document, ['buyerOrg', 'primaryAddress', 'state']) || ''},
								${getIn(document, ['buyerOrg', 'primaryAddress', 'country']) || ''}`
											: `${getIn(document, [
													'organization',
													'primaryAddress',
													'line1',
											  ]) || ''} ,
									${getIn(document, ['organization', 'primaryAddress', 'city']) || ''},
									${getIn(document, ['organization', 'primaryAddress', 'state']) || ''},
									${getIn(document, ['organization', 'primaryAddress', 'country']) || ''}`}
									</Text>
								</Box>
								{isMobile && (
									<Box
										height="14px"
										width="auto"
										margin="20px 0"
										style={{ background: '#F2F3F7' }}
									/>
								)}
								<Box>
									<Label
										style={{ color: theme.color.grey15 }}
									>
										{t('quote.shipmentRequest')}
									</Label>
									<Spacer size={8} />
									<Box
										style={{
											border: isMobile
												? 'none'
												: '1px solid #D9DEE5',
											padding: isMobile
												? '28px 0'
												: '28px 0 28px 16px',
											borderRadius: 6,
											position: 'relative',
										}}
									>
										<KeyValueSegment
											name={t('quote.desintationPort')}
											description={getIn(document, [
												'destinationPort',
											])}
										/>
										<OriginalRequirement
											newValue={getIn(document, [
												'destinationPort',
											])}
											originalValue={getIn(document, [
												'rfq',
												'destinationPort',
											])}
											originalDisplayValue={getIn(
												document,
												['rfq', 'destinationPort']
											)}
											fullIcon
											top="29px"
											left="130px"
										/>
										<KeyValueSegment
											name={t('quote.portOfLading')}
											description={getIn(document, [
												'ladingPort',
											])}
										/>
										<OriginalRequirement
											newValue={getIn(document, [
												'ladingPort',
											])}
											originalValue={getIn(document, [
												'rfq',
												'ladingPort',
											])}
											originalDisplayValue={getIn(
												document,
												['rfq', 'ladingPort']
											)}
											fullIcon
											top="58px"
											left="130px"
										/>
									</Box>
									<Spacer size={12} />
									<Box
										style={{
											backgroundColor: '#E2FADF',
											padding: '8px 16px',
											margin: '0 0 8px',
											borderRadius: '24px',
											opacity: 1,
											textAlign: 'center',
											width: 'fit-content',
										}}
									>
										<Label
											style={{
												color: '#299F74',
												fontWeight: 'bold',
											}}
										>
											{`${t(
												'quote.offerValidity'
											)}: ${getDateByFormat(
												getIn(document, [
													'offerValidity',
												])
											)}`}
										</Label>
									</Box>
								</Box>
							</Box>
							<Spacer size={20} />
							<Box
								height="14px"
								width="auto"
								style={{ background: '#F2F3F7' }}
							/>
							<Label
								style={{
									color: theme.color.grey15,
									margin: '20px 0 0 4px',
								}}
							>
								{t('quote.productDetails')}
							</Label>
							<table
								style={{
									width: '100%',
									borderSpacing: 4,
									borderCollapse: 'separate',
								}}
							>
								<tr>
									<th align="left" style={tableHeaderStyles}>
										{t('quote.product')}
									</th>
									<th align="left" style={tableHeaderStyles}>
										{`${t('quote.quantity')} (${uom &&
											uom.toUpperCase()})`}
										<OriginalRequirement
											newValue={getIn(document, [
												'quantity',
											])}
											originalValue={getIn(document, [
												'rfq',
												'quantity',
											])}
											originalDisplayValue={getIn(
												document,
												['rfq', 'quantity']
											)}
											fullIcon
											top="16px"
											left="118px"
										/>
									</th>
									{!isMobile && (
										<th
											align="left"
											style={tableHeaderStyles}
										>
											{`${t(
												'quote.unitRate'
											)} (${currency &&
												currency.toUpperCase()})`}
										</th>
									)}
									<th align="left" style={tableHeaderStyles}>
										{`${t(
											'quote.totalAmount'
										)} (${currency &&
											currency.toUpperCase()})`}
									</th>
								</tr>
								<tr>
									<td style={tableRowStyles}>
										{`${getIn(document, [
											'rfq',
											'product',
											'name',
										])} (${getIn(document, [
											'rfq',
											'product',
											'code',
										])})`}
									</td>
									<td style={tableRowStyles}>
										{getIn(document, ['quantity'])}
									</td>
									{!isMobile && (
										<td style={tableRowStyles}>
											{getIn(document, ['unitRate'])}
										</td>
									)}
									<td style={tableRowStyles}>
										{getIn(document, ['totalAmount'])}
									</td>
								</tr>
							</table>
							<Spacer size={20} />
							<Label
								style={{
									color: '#8D93A0',
									margin: '10px 0',
									position: 'relative',
								}}
							>
								{t('quote.scModel')}
								<OriginalRequirement
									newValue={getIn(document, [
										'supplychainModel',
										'name',
									])}
									originalValue={getIn(document, [
										'rfq',
										'supplyChainModel',
										'id',
									])}
									originalDisplayValue={t(
										supplyChainModels[
											getIn(document, [
												'rfq',
												'supplyChainModel',
												'id',
											])
										]
									)}
									fullIcon
									left="146px"
								/>
							</Label>
							<P>
								{getIn(document, ['supplychainModel', 'name'])}
							</P>

							<Spacer size={20} />
							<Box
								height="14px"
								width="auto"
								style={{ background: '#F2F3F7' }}
							/>
							<Label
								style={{
									color: theme.color.grey15,
									margin: '20px 0',
								}}
							>
								{t('quote.additionalDetails')}
							</Label>
							<Box
								row={!isMobile}
								justifyContent="space-between"
								style={{ position: 'relative' }}
							>
								<SummaryBlock
									name={t('quote.shipmentPeriod')}
									detail={`${t(
										MONTHS[
											getIn(document, [
												'shipmentPeriod',
												'month',
											])
										]
									) || ''} , ${t(
										SHIPMENT_PERIOD[
											getIn(document, [
												'shipmentPeriod',
												'period',
											])
										]
									) || ''}`}
									width="100%"
								/>
								<OriginalRequirement
									newValue={`${getIn(document, [
										'shipmentPeriod',
										'month',
									])}, ${getIn(document, [
										'shipmentPeriod',
										'period',
									])}`}
									originalValue={`${getIn(document, [
										'rfq',
										'shipment',
										'month',
									])}, ${getIn(document, [
										'rfq',
										'shipment',
										'period',
									])}`}
									originalDisplayValue={`${t(
										MONTHS[
											getIn(document, [
												'rfq',
												'shipment',
												'month',
											])
										]
									)}, ${t(
										SHIPMENT_PERIOD[
											getIn(document, [
												'rfq',
												'shipment',
												'period',
											])
										]
									)}`}
									fullIcon
									left="108px"
								/>
								<Box style={{ position: 'relative' }}>
									<SummaryBlock
										name={t('quote.thirdPartyInspection')}
										detail={`${(
											getIn(document, [
												'thirdPartyInspection',
											]) || []
										).join(', ')}`}
										width="120px"
									/>
									<OriginalRequirement
										newValue={
											getIn(document, [
												'thirdPartyInspection',
											]) &&
											getIn(document, [
												'thirdPartyInspection',
											]).length > 0
												? [
														...getIn(document, [
															'thirdPartyInspection',
														]),
												  ]
														.sort()
														.join(',')
												: ''
										}
										originalValue={
											getIn(document, [
												'rfq',
												'inspection',
											]) &&
											getIn(document, [
												'rfq',
												'inspection',
											]).length > 0
												? getIn(document, [
														'rfq',
														'inspection',
												  ])
														.map(item => item.type)
														.sort()
														.join(',')
												: ''
										}
										originalDisplayValue={
											getIn(document, [
												'rfq',
												'inspection',
											]) &&
											getIn(document, [
												'rfq',
												'inspection',
											]).length > 0
												? getIn(document, [
														'rfq',
														'inspection',
												  ])
														.map(item => item.type)
														.sort()
														.join(', ')
												: ''
										}
										fullIcon
										left="140px"
									/>
								</Box>

								<Box style={{ position: 'relative' }}>
									<SummaryBlock
										name={t(
											'quote.preferredQuantityInspector'
										)}
										detail={getIn(document, [
											'preferredQuantityInspector',
										])}
									/>
									<OriginalRequirement
										newValue={getIn(document, [
											'preferredQuantityInspector',
										])}
										originalValue={
											getIn(document, [
												'rfq',
												'inspection',
											]) &&
											getIn(document, [
												'rfq',
												'inspection',
											]).length > 0 &&
											getIn(document, [
												'rfq',
												'inspection',
											]).filter(
												item => item.type === 'quantity'
											).length > 0
												? getIn(document, [
														'rfq',
														'inspection',
												  ]).filter(
														item =>
															item.type ===
															'quantity'
												  )[0].officer
												: ''
										}
										originalDisplayValue={
											getIn(document, [
												'rfq',
												'inspection',
											]) &&
											getIn(document, [
												'rfq',
												'inspection',
											]).length > 0 &&
											getIn(document, [
												'rfq',
												'inspection',
											]).filter(
												item => item.type === 'quantity'
											).length > 0
												? getIn(document, [
														'rfq',
														'inspection',
												  ]).filter(
														item =>
															item.type ===
															'quantity'
												  )[0].officer
												: ''
										}
										fullIcon
										left="178px"
									/>
								</Box>
							</Box>
							{!isMobile && <Spacer size={20} />}
							<Box
								row={!isMobile}
								width={isMobile ? '100%' : '56%'}
								justifyContent="space-between"
								style={{ position: 'relative' }}
							>
								<SummaryBlock
									name={t('quote.typeOfContract')}
									detail={t(
										CONTRACT_TYPE[
											getIn(document, ['contractType'])
										]
									)}
									width="100%"
								/>
								<OriginalRequirement
									newValue={getIn(document, ['contractType'])}
									originalValue={getIn(document, [
										'rfq',
										'contractType',
									])}
									originalDisplayValue={t(
										CONTRACT_TYPE[
											getIn(document, [
												'rfq',
												'contractType',
											])
										]
									)}
									fullIcon
									left="178px"
								/>
								<Box style={{ position: 'relative' }}>
									<SummaryBlock
										name={t(
											'quote.preferredQualityInspector'
										)}
										detail={getIn(document, [
											'preferredQualityInspector',
										])}
									/>
									<OriginalRequirement
										newValue={getIn(document, [
											'preferredQualityInspector',
										])}
										originalValue={
											getIn(document, [
												'rfq',
												'inspection',
											]) &&
											getIn(document, [
												'rfq',
												'inspection',
											]).length > 0 &&
											getIn(document, [
												'rfq',
												'inspection',
											]).filter(
												item => item.type === 'quality'
											).length > 0
												? getIn(document, [
														'rfq',
														'inspection',
												  ]).filter(
														item =>
															item.type ===
															'quality'
												  )[0].officer
												: ''
										}
										originalDisplayValue={
											getIn(document, [
												'rfq',
												'inspection',
											]) &&
											getIn(document, [
												'rfq',
												'inspection',
											]).length > 0 &&
											getIn(document, [
												'rfq',
												'inspection',
											]).filter(
												item => item.type === 'quality'
											).length > 0
												? getIn(document, [
														'rfq',
														'inspection',
												  ]).filter(
														item =>
															item.type ===
															'quality'
												  )[0].officer
												: ''
										}
										fullIcon
										left="169px"
									/>
								</Box>
							</Box>
							<Spacer size={20} />

							<Box
								height="14px"
								width="auto"
								style={{ background: '#F2F3F7' }}
							/>
							<Label
								style={{
									color: '#8D93A0',
									margin: '10px 0',
									position: 'relative',
									display: 'flex',
								}}
							>
								{t('quote.termsAndConditions')}
								<Box
									style={{
										backgroundColor: '#E2FADF',
										padding: '0 8px',
										marginLeft: '8px',
										borderRadius: '24px',
										opacity: 1,
										textAlign: 'center',
										width: 'fit-content',
										fontSize: '10px',
									}}
								>
									<Label
										style={{
											color: '#299F74',
											fontWeight: 'bold',
										}}
									>
										{t('quote.quote')}
									</Label>
								</Box>
							</Label>
							<P>
								{getIn(document, [
									'meta',
									'termsAndConditions',
								])}
							</P>

							<Spacer size={20} />
							<Label
								style={{
									color: '#8D93A0',
									margin: '10px 0',
									position: 'relative',
									display: 'flex',
								}}
							>
								{t('quote.termsAndConditions')}
								<Box
									style={{
										backgroundColor: '#E2FADF',
										padding: '0 8px',
										marginLeft: '8px',
										borderRadius: '24px',
										opacity: 1,
										textAlign: 'center',
										width: 'fit-content',
										fontSize: '10px',
									}}
								>
									<Label
										style={{
											color: '#299F74',
											fontWeight: 'bold',
										}}
									>
										{t('quote.rfq')}
									</Label>
								</Box>
							</Label>
							<P>
								{getIn(document, [
									'rfq',
									'meta',
									'termsAndConditions',
								])}
							</P>

							<Spacer size={20} />
							<Label
								style={{
									color: '#8D93A0',
									margin: '10px 0',
									position: 'relative',
								}}
							>
								{t('quote.paymentTerms')}
								<OriginalRequirement
									newValue={getIn(document, ['paymentTerms'])}
									originalValue={getIn(document, [
										'rfq',
										'payment',
									])}
									originalDisplayValue={getIn(document, [
										'rfq',
										'payment',
									])}
									fullIcon
									left="116px"
								/>
							</Label>
							<P>{getIn(document, ['paymentTerms'])}</P>

							<Spacer size={20} />
							<Label
								style={{
									color: '#8D93A0',
									margin: '10px 0',
									position: 'relative',
								}}
							>
								{t('quote.additionalDetails')}
								<OriginalRequirement
									newValue={getIn(document, [
										'additionalDetails',
									])}
									originalValue={getIn(document, [
										'rfq',
										'requirementDetails',
									])}
									originalDisplayValue={getIn(document, [
										'rfq',
										'requirementDetails',
									])}
									fullIcon
									left="130px"
								/>
							</Label>
							<P>{getIn(document, ['additionalDetails'])}</P>

							<Spacer size={20} />
						</Box>
						<Box
							style={{
								position: 'fixed',
								display: 'grid',
								zIndex: 21,
								bottom: 0,
								right: showChatBox ? 8 : 75,
							}}
						>
							<ChatModalComponent
								documentId={getIn(document, ['id'])}
								sellerName={getIn(document, [
									'organization',
									'name',
								])}
								buyerName={getIn(document, [
									'buyerOrg',
									'name',
								])}
								buyerId={getIn(document, ['buyerOrg', 'id'])}
								sellerId={getIn(document, [
									'organization',
									'id',
								])}
								tradeId={getIn(document, ['tradeID'])}
								docType="quote"
								chatBoxData={data => chatBoxData(data)}
								isMobile={isMobile}
							/>
						</Box>
					</Box>
				)}
				{isAttachmentTabActive && (
					<Box style={{ background: '#fff' }}>
						<Box
							style={{
								padding: '15px',
								borderBottom: `1px solid ${theme.color.grey4}`,
							}}
						>
							<H4 color={theme.color.primary}>
								{t('quote.viewAllAttachment')}
							</H4>
						</Box>
						<Box
							row
							style={{
								padding: '15px',
								borderBottom: `1px solid ${theme.color.grey4}`,
							}}
						>
							{(files || []).map(d => (
								<AttachmentMenu
									key={d?.id}
									t={t}
									data={d}
									getCurrentOrgID={getCurrentOrgID}
									setSelectedFile={setSelectedFile}
									setRenameModal={setRenameModal}
									deleteFileAttachment={deleteFileAttachment}
								/>
							))}
						</Box>
					</Box>
				)}
			</Box>
		</>
	)
}

export { ViewQuote }
