import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { SmallText } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'

const PreviewContractParticipants = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query } = location
	const { contractAction } = query
	const { rootModule, documentType, documentReference } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	const initialValuesForEdit = useSelector(
		MarketPlaceDuc.selectors.getEditContractInitialValues
	)

	if (!contract.quantity) {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<Box
			style={{
				width: isMobile ? '100%' : '100vw',
				overflowY: 'scroll',
			}}
			center
			padding={8}
		>
			<Box>
				<Spacer size={20} />
				<Box
					style={{
						width: isMobile ? '100%' : 770,
						borderRadius: 10,
						height: 'fit-content',
						background: theme.color.white,
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={isMobile ? '45px 10px' : '45px 125px'}
				>
					<SmallText
						style={{
							fontSize: 32,
							color: '#242845',
							lineHeight: '32px',
							textAlign: 'left',
							fontWeight: 'bold',
						}}
					>
						{t('contracts.participants')}
					</SmallText>
					<Spacer size={10} />
					<SmallText
						style={{
							fontSize: 14,
							color: '#8D93A0',
							lineHeight: '19px',
							textAlign: 'justify',
						}}
					>
						{t('contracts.previewParticipantsText')}
					</SmallText>
					<Spacer size={20} />
					{contract.buyer && contract.buyer.name && (
						<Box
							style={{
								padding: '0 60px',
								height: 'fit-content',
							}}
						>
							<SmallText
								style={{
									fontSize: 16,
									color: '#242845',
									textAlign: 'left',
									fontWeight: 'bold',
									lineHeight: '21px',
								}}
							>
								{t('contracts.buyer')}
							</SmallText>
							<Box
								style={{
									height: 48,
									opacity: 1,
									marginTop: 8,
									borderRadius: 10,
									paddingLeft: 23.5,
									justifyContent: 'center',
									background:
										'#F2F3F7 0% 0% no-repeat padding-box',
								}}
							>
								{contract.buyer.name}
							</Box>
							<Spacer size={20} />
							<SmallText
								style={{
									fontSize: 16,
									color: '#242845',
									textAlign: 'left',
									fontWeight: 'bold',
									lineHeight: '21px',
								}}
							>
								{t('contracts.seller')}
							</SmallText>
							<Box
								style={{
									height: 48,
									opacity: 1,
									marginTop: 8,
									borderRadius: 10,
									paddingLeft: 23.5,
									justifyContent: 'center',
									background:
										'#F2F3F7 0% 0% no-repeat padding-box',
								}}
							>
								{contract.seller.name ||
									initialValuesForEdit.seller.name}
							</Box>
							<Spacer size={20} />
							{(contract.broker.name ||
								contract.broker.primaryContact.email) && [
								<SmallText
									key={
										contract.broker.name ||
										contract.broker.primaryContact.email
									}
									style={{
										fontSize: 16,
										color: '#242845',
										textAlign: 'left',
										fontWeight: 'bold',
										lineHeight: '21px',
									}}
								>
									{t('contracts.broker')}
								</SmallText>,
								<Box
									key={
										contract.broker.name ||
										contract.broker.primaryContact.email
									}
									style={{
										height: 72,
										opacity: 1,
										marginTop: 8,
										borderRadius: 10,
										paddingLeft: 23.5,
										lineHeight: '22px',
										justifyContent: 'center',
										background:
											'#F2F3F7 0% 0% no-repeat padding-box',
									}}
								>
									{contract.broker.name && (
										<div>{contract.broker.name}</div>
									)}
									{contract.broker.primaryContact.email && (
										<div>
											{
												contract.broker.primaryContact
													.email
											}
										</div>
									)}
								</Box>,
							]}
						</Box>
					)}

					<Spacer size={60} />

					<Box style={{ display: 'block' }}>
						<Button
							rounded
							label={t('common.submit')}
							reverse
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.MARKETPLACE$CONTRACTSWDOCTYPE,
										{
											rootModule,
											action: 'participants-success',
											documentReference,
											documentType,
										},
										{
											contractAction,
										}
									)
								)
							}}
							extendStyles={{
								width: 146,
								float: 'right',
								marginLeft: isMobile ? 0 : 20,
								background: '#3F56C4',
								color: theme.color.white,
							}}
						/>
						<Button
							rounded
							label={t('common.back')}
							reverse
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.MARKETPLACE$CONTRACTSWDOCTYPE,
										{
											rootModule,
											action:
												contractAction === 'edit'
													? 'edit'
													: 'generate',
											documentReference,
											documentType,
										},
										{
											preview: true,
										}
									)
								)
							}}
							extendStyles={{
								width: 146,
								float: isMobile ? 'initial' : 'right',
								color: '#3F56C4',
								background: theme.color.white,
								border: '1px solid #3F56C4',
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export { PreviewContractParticipants }
