import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { SmallText } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import SuccessIcon from 'ui-lib/icons/success-round.svg'

const ContractParticipantsSuccess = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { contractAction } = query
	const { rootModule, documentReference, documentType } = payload

	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	if (!contract.quantity) {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<>
			<Title
				title={t('contractsParticipantsSuccess.setParticipantsLabel')}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'preview-participants',
								documentReference,
								documentType,
							},
							{
								contractAction,
							}
						)
					)
				}}
				small
			/>
			<Spacer size={60} />

			<Box
				style={{
					width: '100vw',
					overflowY: 'scroll',
				}}
				center
				padding={8}
			>
				<Box center>
					<Spacer size={20} />
					<Box
						style={{
							width: '770px',
							height: 'fit-content',
							background: theme.color.white,
							borderRadius: '10px',
							boxShadow: '0px 3px 6px #00000029',
						}}
						padding={25}
						alignItems="center"
					>
						<IconWrapper color="#CE924B" size={70}>
							<Icon glyph={SuccessIcon} />
						</IconWrapper>
						<Spacer size={20} />
						<SmallText
							style={{
								color: '#2D2D2D',
								fontSize: '32px',
								lineHeight: '32px',
								width: '500px',
								fontWeight: 'bold',
								textAlign: 'center',
							}}
						>
							{t('contractsParticipantsSuccess.allSet')}
						</SmallText>
						<Spacer size={30} />
						<SmallText
							style={{
								color: '#555454',
								fontSize: '18px',
								lineHeight: '32px',
								textAlign: 'center',
							}}
						>
							{t(
								'contractsParticipantsSuccess.setParticipantsText'
							)}
						</SmallText>
						<Spacer size={50} />
						<Box
							style={{
								width: '410px',
							}}
						>
							<Button
								rounded
								label={t(
									'contractsParticipantsSuccess.proceed'
								)}
								reverse
								onClick={() => {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types
												.MARKETPLACE$CONTRACTSWDOCTYPE,
											{
												rootModule,
												action: 'preview',
												documentReference,
												documentType,
											},
											{
												contractAction,
											}
										)
									)
								}}
								extendStyles={{
									background: '#3F56C4',
									color: theme.color.white,
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export { ContractParticipantsSuccess }
