import React, { useCallback } from 'react'
import { getIn } from 'timm'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { BoxShadowTable } from 'ui-marketplace-app/shared/components/BoxShadowTable'
import { ShowMore } from 'ui-marketplace-app/modules/MarketPlace/components/ShowMore'
import {
	TABLE_ALIAS,
	RFQ_STATUS,
	STATUS_TEXT_COLOR,
	STATUS_BACKGROUND,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { getDateByFormat } from 'ui-marketplace-app/utils/date'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'

export const columnConfig = ({ t, onChange, actor, isMobile = false }) => {
	return [
		...(!isMobile
			? [
					{
						property: 'dateSent',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
							>
								<TableTitle>
									{actor === 'mp_purchase_officer'
										? t(TABLE_ALIAS.dateReceived)
										: t(TABLE_ALIAS.dateSent)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const dateSent = getIn(datum, ['createdAt'])

							return <Label>{getDateByFormat(dateSent)}</Label>
						},
					},
			  ]
			: []),
		{
			property: 'seller-buyer',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{actor === 'mp_purchase_officer'
							? t(TABLE_ALIAS.seller)
							: t(TABLE_ALIAS.buyer)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const buyer = getIn(datum, ['buyer', 'name'])
				const seller = getIn(datum, ['seller', 'name'])

				const org = actor === 'mp_purchase_officer' ? seller : buyer

				return <Label>{org}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const productName = getIn(datum, ['product', 'name'])
				const quantity = getIn(datum, ['quantity'])
				const uom = getIn(datum, ['product', 'uom']) || ''

				return (
					<>
						<Label>{productName}</Label>
						{isMobile && (
							<Label>
								{quantity} {uom.toUpperCase()}
							</Label>
						)}
					</>
				)
			},
		},
		...(!isMobile
			? [
					{
						property: 'quantity',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
							>
								<TableTitle>
									{t(TABLE_ALIAS.quantity)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const quantity = getIn(datum, ['quantity'])
							const uom = getIn(datum, ['product', 'uom']) || ''

							return (
								<Label>
									{quantity} {uom.toUpperCase()}
								</Label>
							)
						},
					},
			  ]
			: []),
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status'])

				return (
					<Box
						style={{
							backgroundColor: STATUS_BACKGROUND[status],
							padding: '8px 16px',
							margin: '0 0 8px',
							borderRadius: '5px',
							opacity: 1,
							textAlign: 'center',
						}}
					>
						<Label
							style={{
								color: STATUS_TEXT_COLOR[status],
							}}
						>
							{t(RFQ_STATUS[status]).toUpperCase()}
						</Label>
					</Box>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('view_document', {
								row: datum,
							})
						}}
					/>
				)
			},
		},
	]
}

const RFQListing = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const buyerRFQList = useSelector(MarketPlaceDuc.selectors.getBuyerRFQList)
	const sellerRFQList = useSelector(MarketPlaceDuc.selectors.getSellerRFQList)

	const buyerRFQPagination = useSelector(
		MarketPlaceDuc.selectors.getBuyerRFQPaginationQueries
	)
	const sellerRFQPagination = useSelector(
		MarketPlaceDuc.selectors.getSellerRFQPaginationQueries
	)

	const organizations = useSelector(
		MarketPlaceDuc.selectors.getMarketPlaceOrgList
	)

	const actor = useSelector(MarketPlaceDuc.selectors.getMarketPlaceActor)

	const rfqList =
		actor === 'mp_purchase_officer' ? buyerRFQList : sellerRFQList

	const pagination =
		actor === 'mp_purchase_officer'
			? buyerRFQPagination
			: sellerRFQPagination

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'view_document': {
					if (meta.row.id) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$ACTION,
								{
									rootModule,
									action: 'view-rfq',
									documentReference: meta.row.id,
								}
							)
						)
					}
					break
				}

				case 'rfq_pagination': {
					const locationState = {
						nextStartID: pagination.nextStartID,
					}

					dispatch(
						MarketPlaceDuc.creators.fetchRFQListing(
							rootModule,
							locationState
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, pagination.nextStartID, rootModule]
	)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('rfq.enquiries'),
			name: 'enquiries',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<BoxShadowTable
				size="medium"
				columnConfig={columnConfig({
					t,
					organizations,
					actor,
					onChange: handleChange,
					isMobile,
				})}
				rowData={rfqList}
				isMobile={isMobile}
			/>
			<ShowMore
				documents={rfqList}
				total={pagination.total}
				handleChange={handleChange}
				type="rfq_pagination"
			/>
		</>
	)
}

export { RFQListing }
