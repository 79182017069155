import React, { useState } from 'react'
import { getIn } from 'timm'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Input } from 'ui-lib/components/Input'
import { Button } from 'ui-lib/components/Button'
import { Select } from 'ui-lib/components/Select'
import { SmallText, H3, P, Text } from 'ui-lib/components/Typography'
import { TextArea } from 'ui-lib/components/TextArea'
import { Card } from 'ui-lib/components/Card'
import { Label } from 'ui-lib/components/Label'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import theme from 'ui-lib/utils/base-theme'
import * as yup from 'yup'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import { ContractsUpload } from 'ui-marketplace-app/modules/MarketPlace/components/ContractsUpload'

export const deliveryPeriodConfig = t => {
	return [
		{
			label: t('marketPlace.first-half'),

			name: 'first-half',
		},

		{
			label: t('marketPlace.second-half'),

			name: 'second-half',
		},

		{
			label: t('marketPlace.entire-month'),

			name: 'entire-month',
		},
	]
}

export const monthConfig = t => {
	return [
		{
			label: t('marketPlace.jan'),
			name: 'january',
		},
		{
			label: t('marketPlace.feb'),
			name: 'february',
		},
		{
			label: t('marketPlace.mar'),
			name: 'march',
		},
		{
			label: t('marketPlace.apr'),
			name: 'april',
		},
		{
			label: t('marketPlace.may'),
			name: 'may',
		},
		{
			label: t('marketPlace.jun'),
			name: 'june',
		},
		{
			label: t('marketPlace.jul'),
			name: 'july',
		},
		{
			label: t('marketPlace.aug'),
			name: 'august',
		},
		{
			label: t('marketPlace.sep'),
			name: 'september',
		},
		{
			label: t('marketPlace.oct'),
			name: 'october',
		},
		{
			label: t('marketPlace.nov'),
			name: 'november',
		},
		{
			label: t('marketPlace.dec'),
			name: 'december',
		},
	]
}

const AttachOwnContract = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const document = useSelector(MarketPlaceDuc.selectors.getQuoteDocument)
	const [fileCount, setFileCount] = useState(0)
	const [pdfFileData, setPdfFileData] = useState('')
	const [pdfFileName, setPdfFileName] = useState('')
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const {
		quantity,
		rfq = {},
		unitRate,
		additionalDetails,
		paymentTerms,
		organization,
		id: _quoteID,
		tradeID,
	} = document
	const { product = {}, id: _rfqID } = rfq

	const initialValuesForEdit =
		useSelector(MarketPlaceDuc.selectors.getEditContractInitialValues) || {}

	const initialValuesForCreate =
		{
			contractNo: '',
			quantity,
			product: getIn(product, ['name']),
			currency: getIn(document, ['currency', 'code']),
			brokerName: '',
			brokerEmail: '',
			date: '',
			basicDeliveryMonth: '',
			basicDeliveryPeriod: '',
			deliveryPeriodMonth: '',
			deliveryPeriodTimeFrame: '',
			unitRate,
			paymentTerms,
			specialConditions: '',
			productSpecification: additionalDetails,
		} || {}

	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { rootModule, documentReference, documentType, action } = payload
	const { preview } = query

	const currentOrgDetails = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)

	const previewDetails = useSelector(
		MarketPlaceDuc.selectors.getContractPreviewDetails
	)

	const validationSchema = yup.object().shape({
		quantity: yup
			.number()
			.positive()
			.required(),
		brokerName: yup.string(),
		brokerEmail: yup.string(),
		currency: yup.string().required(),
		unitRate: yup
			.number()
			.positive()
			.required(),
	})

	let initialValues = {}

	if (preview === true) {
		initialValues = previewDetails
	} else if (action === 'edit') {
		initialValues = initialValuesForEdit
	} else if (action === 'generate') {
		initialValues = initialValuesForCreate
	}

	const {
		handleSubmit,
		values,
		handleChange,
		submitForm,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const requestPayload = {
				tradeID,
				contractType: 'poram',
				contract: {
					contractNo: _values.contractNo,
					date: _values.date === '' ? new Date() : _values.date,
					seller: {
						id: getIn(organization, ['id']),
						name: getIn(organization, ['name']),
						primaryContact: getIn(organization, ['primaryContact']),
						primaryAddress: getIn(organization, ['primaryAddress']),
					},
					buyer: {
						id: getIn(currentOrgDetails, ['id']),
						name: getIn(currentOrgDetails, ['name']),
						primaryContact: getIn(currentOrgDetails, [
							'primaryContact',
						]),
						primaryAddress: getIn(currentOrgDetails, [
							'primaryAddress',
						]),
					},
					broker: {
						name: _values.brokerName,
						primaryContact: {
							email: _values.brokerEmail,
						},
					},
					referenceNo: [_quoteID, _rfqID],
					product: {
						...product,
						product: {
							name: product.name,
						},
						description: _values.description,
						meta: {
							specifications: _values.productSpecification,
						},
					},
					quantity: _values.quantity,
					unitRate: parseFloat(_values.unitRate),
					deliveryPeriod: {
						month: _values.deliveryPeriodMonth,
						period: _values.deliveryPeriodTimeFrame,
					},
					basicDelivery: {
						month: _values.basicDeliveryMonth,
						period: _values.basicDeliveryPeriod,
					},
					currency: { code: _values.currency },
					specialConditions: _values.specialConditions,
					paymentTerms: _values.paymentTerms,
				},
				meta: {
					contractModel: documentType,
					quoteID: _quoteID,
				},
			}

			if (action === 'edit') {
				requestPayload.id = documentReference
				requestPayload.contract.seller = initialValuesForEdit.seller
				requestPayload.tradeID = initialValuesForEdit.tradeID
			}

			dispatch(MarketPlaceDuc.creators.setPreviewValues(_values))
			dispatch(MarketPlaceDuc.creators.setContractDetails(requestPayload))
			dispatch(
				dispatch(
					MarketPlaceDuc.creators.postAttachedContractDetails(
						requestPayload,
						documentType,
						pdfFileData,
						pdfFileName,
						fileCount
					)
				)
			)
		},
	})

	return (
		<>
			<Box>
				<Title
					title={
						action === 'edit'
							? t('contracts.editContract')
							: t('contracts.createContractDetails')
					}
					icon={LeftArrowIcon}
					onClick={() => {
						if (action === 'edit') {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.MARKETPLACE$CONTRACTS,
									{
										rootModule,
										action: 'view',
										documentReference,
									}
								)
							)
						} else {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.MARKETPLACE$CONTRACTS,
									{
										rootModule,
										action: 'create',
										documentReference,
									}
								)
							)
						}
					}}
					isMobile={isMobile}
					hasNoMaxWidth
				/>
			</Box>
			<Box row={!isMobile} justifyContent="space-between">
				<Box
					style={{
						width: isMobile ? '100%' : '65%',
						background: '#fff',
						padding: '22px',
						marginRight: '20px',
					}}
				>
					<Box
						row={!isMobile}
						justifyContent="flex-start"
						alignItems="baseline"
					>
						<Box
							style={{
								overflow: 'hidden',
							}}
						>
							<H3
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
									width: '100%',
									color: '#8D93A0',
								}}
								title="DOMESTIC CONTRACT FOR MALAYSIAN CRUDE UNBLEACHED
								PALM OIL IN BULK"
							>
								DOMESTIC CONTRACT FOR MALAYSIAN CRUDE UNBLEACHED
								PALM OIL IN BULK
							</H3>
							<P
								style={{
									color: '#8D93A0',
									margin: '14px 0 0',
								}}
							>
								Issued jointly by The Palm Oil Refiners
								Association of Malaysia (PORAM) and Malaysian
								Palm Oil Association (MPOA)
							</P>
						</Box>
					</Box>
					<Spacer size={40} />
					<form onSubmit={handleSubmit}>
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: 18,
									color: '#8D93A0',
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('contracts.mainContractDetails')}
							</SmallText>
							<Box
								row={!isMobile}
								style={{ marginTop: '28px' }}
								justifyContent="space-between"
							>
								<Box style={{ width: '304px' }}>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.brokerName')}
									</Label>

									<Input
										value={values.brokerName}
										name="brokerName"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										disabled={action === 'edit'}
										error={
											touched.brokerName &&
											errors.brokerName
										}
									/>
								</Box>

								<Box style={{ width: '304px' }}>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
										bold
									>
										{t('contracts.brokerEmailAddress')}
									</Label>
									<Input
										value={values.brokerEmail}
										name="brokerEmail"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										disabled={action === 'edit'}
										error={
											touched.brokerEmail &&
											errors.brokerEmail
										}
									/>
								</Box>
							</Box>
							<Box row={!isMobile} justifyContent="space-between">
								<Box style={{ width: '304px' }}>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.contractNumber')}
									</Label>

									<Input
										value={values.contractNo}
										name="contractNo"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.contractNo &&
											errors.contractNo
										}
									/>
								</Box>
								<Box style={{ width: '304px' }}>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.date')}
									</Label>

									<DatePicker
										name="date"
										minDate={
											action === 'edit'
												? null
												: new Date()
										}
										value={values.date || new Date()}
										onChange={value => {
											setFieldValue(
												'date',
												new Date(value)
											)
										}}
										required
									/>
								</Box>
							</Box>
							<Box row={!isMobile} justifyContent="space-between">
								<Box style={{ width: '304px' }}>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.product')}
									</Label>

									<Input
										value={values.product}
										name="product"
										disabled
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.product && errors.product
										}
									/>
								</Box>

								<Box style={{ width: '304px' }}>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.quantityMT')}
									</Label>
									<Input
										value={values.quantity}
										name="quantity"
										type="number"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.quantity && errors.quantity
										}
									/>
								</Box>
							</Box>

							<Box row={!isMobile}>
								<Box>
									<Box>
										<Label
											style={{
												color: '#242845',
												fontSize: 14,
												width: 304,
												fontWeight: 'bold',
												margin: '0 0 8px 0px',
											}}
										>
											{t(
												'contracts.deliveryCollectionPeriod'
											)}
										</Label>
									</Box>
									<Box row={!isMobile}>
										<Box
											width={isMobile ? '100%' : 140}
											margin="0px"
										>
											<Select
												value={
													values.deliveryPeriodMonth
												}
												options={monthConfig(t)}
												key={values.deliveryPeriodMonth}
												labelKey="label"
												valueKey="name"
												onChange={value =>
													setFieldValue(
														'deliveryPeriodMonth',
														value.name
													)
												}
												returnOnlyValue
											/>
										</Box>
										<Box
											width={isMobile ? '100%' : 148}
											margin={
												isMobile ? '0px' : '0 0 0 16px'
											}
										>
											<Select
												value={
													values.deliveryPeriodTimeFrame
												}
												options={deliveryPeriodConfig(
													t
												)}
												key={
													values.deliveryPeriodTimeFrame
												}
												labelKey="label"
												valueKey="name"
												onChange={value =>
													setFieldValue(
														'deliveryPeriodTimeFrame',
														value.name
													)
												}
												returnOnlyValue
											/>
										</Box>
									</Box>
								</Box>
								<Box>
									<Box>
										<Label
											style={{
												color: '#242845',
												fontSize: 14,
												width: 304,
												margin: isMobile
													? '0 0 8px 0px'
													: '0 0 8px 22px',
												fontWeight: 'bold',
											}}
										>
											{t(
												'contracts.basicDeliveryCollection'
											)}
										</Label>
									</Box>
									<Box row={!isMobile}>
										<Box
											width={isMobile ? '100%' : 140}
											margin={
												isMobile ? '0px' : '0 0 0 22px'
											}
										>
											<Select
												value={
													values.basicDeliveryMonth
												}
												options={monthConfig(t)}
												key={values.basicDeliveryMonth}
												labelKey="label"
												valueKey="name"
												onChange={value =>
													setFieldValue(
														'basicDeliveryMonth',
														value.name
													)
												}
												returnOnlyValue
											/>
										</Box>
										<Box
											width={isMobile ? '100%' : 148}
											margin={
												isMobile ? '0px' : '0 0 0 16px'
											}
										>
											<Select
												value={
													values.basicDeliveryPeriod
												}
												options={deliveryPeriodConfig(
													t
												)}
												key={values.basicDeliveryPeriod}
												labelKey="label"
												valueKey="name"
												onChange={value =>
													setFieldValue(
														'basicDeliveryPeriod',
														value.name
													)
												}
												returnOnlyValue
											/>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box row={!isMobile}>
								<Box width={isMobile ? '100%' : 140}>
									<Label
										style={{
											color: '#242845',
											fontSize: 14,
											margin: '0 0 8px 0px',
											fontWeight: 'bold',
										}}
									>
										{t('contracts.currency')}
									</Label>
									<Select
										value={values.currency}
										name="currency"
										options={[
											{
												label: 'USD',
												name: 'USD',
											},
											{
												label: 'MYR',
												name: 'MYR',
											},
											{
												label: 'INR',
												name: 'INR',
											},
										]}
										onChange={value =>
											setFieldValue(
												'currency',
												value.currency.label
											)
										}
										key={values.currency}
										labelKey="label"
										returnKeyValue
										valueKey="name"
										onBlur={handleBlur}
										error={
											touched.currency && errors.currency
										}
									/>
								</Box>

								<Box
									width={isMobile ? '100%' : 148}
									margin={isMobile ? '0px' : '0 0 0 16px'}
								>
									<Label
										style={{
											color: '#242845',
											fontSize: 14,
											margin: '0 0 8px 0px',
											fontWeight: 'bold',
										}}
									>
										{t('contracts.pricePerMT')}
									</Label>
									<Input
										value={values.unitRate}
										name="unitRate"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.unitRate && errors.unitRate
										}
									/>
								</Box>
							</Box>
						</Card>

						<Spacer size={32} />
						<Card
							style={{
								padding: '0px 20px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: 18,
									color: '#8D93A0',
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('contracts.specsAndConditions')}
							</SmallText>
							<Spacer size={32} />

							<Box>
								<Label
									style={{
										fontSize: '16px',
										margin: '0 0 8px',
										color: theme.color.black3,
									}}
								>
									{t('contracts.productSpecs')}
								</Label>
								<TextArea
									name="productSpecification"
									value={values.productSpecification}
									onBlur={handleBlur}
									onChange={e => {
										setFieldValue(
											'productSpecification',
											e.target.value
										)
									}}
									autoFocus={false}
								/>
							</Box>
							<Box>
								<Label
									style={{
										fontSize: '16px',
										margin: '0 0 8px',
										color: theme.color.black3,
									}}
								>
									{t('contracts.specialConditions')}
								</Label>
								<TextArea
									name="specialConditions"
									value={values.specialConditions}
									onBlur={handleBlur}
									onChange={e => {
										setFieldValue(
											'specialConditions',
											e.target.value
										)
									}}
									autoFocus={false}
								/>
							</Box>
							<Box>
								<Label
									style={{
										fontSize: '16px',
										margin: '0 0 8px',
										color: theme.color.black3,
									}}
								>
									{t('contracts.descriptionOfOil')}
								</Label>
								<TextArea
									name="description"
									value={values.description}
									onBlur={handleBlur}
									onChange={e => {
										setFieldValue(
											'description',
											e.target.value
										)
									}}
									autoFocus={false}
								/>
							</Box>
							<P style={{ color: '#8D93A0' }}>
								{t('contracts.contract1Descriptionline1')}
								<br />
								<br />
								{t('contracts.contract1Descriptionline2')} :-
								<br />
								{t('contracts.contract1Descriptionline3')};
								<br />
								{t('contracts.contract1Descriptionline4')}.
							</P>
						</Card>
						<Box margin="20px 0 0">
							<Label
								style={{
									fontSize: '16px',
									margin: '0 0 8px',
									color: theme.color.black3,
								}}
							>
								{t('contracts.paymentMethodAndTerms')}
							</Label>
							<TextArea
								name="paymentTerms"
								value={values.paymentTerms}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'paymentTerms',
										e.target.value
									)
								}}
								autoFocus={false}
							/>
						</Box>
						<ContractsUpload
							onChange={(count, file, name) => {
								if (file.size / 1048576 > 5) {
									setFileCount(0)
									dispatch(
										AppDuc.creators.showToast({
											messageType: 'error',
											message: t(
												'contracts.fileUploadError'
											),
										})
									)
								} else {
									setFileCount(count)
									setPdfFileData(file)
									setPdfFileName(name)
								}
							}}
						/>
					</form>
				</Box>
				<Box
					style={{
						width: isMobile ? '100%' : '33%',
						marginTop: isMobile ? '32px' : '0px',
					}}
				>
					<Box
						style={{
							background: '#fff',
							padding: '28px',
						}}
					>
						<Label style={{ color: theme.color.blue5 }}>
							{t('contracts.seller')}
						</Label>
						<Spacer size={8} />

						<Title
							title={
								getIn(organization, ['name']) ||
								getIn(initialValuesForEdit, ['seller', 'name'])
							}
							note={getIn(organization, [
								'categories',
								0,
								'name',
							])}
						/>
						<Spacer size={8} />
						<Text
							style={{
								fontSize: 14,
								color: '#8D93A0',
								marginBottom: 8,
							}}
						>
							{t('contracts.address')}
						</Text>
						<P>{`${getIn(organization, [
							'primaryAddress',
							'line1',
						]) ||
							getIn(initialValuesForEdit, [
								'seller',
								'primaryAddress',
								'line1',
							]) ||
							''}, ${getIn(organization, [
							'primaryAddress',
							'city',
						]) ||
							getIn(initialValuesForEdit, [
								'seller',
								'primaryAddress',
								'city',
							]) ||
							''},
 ${getIn(organization, ['primaryAddress', 'state']) ||
		getIn(initialValuesForEdit, ['seller', 'primaryAddress', 'state']) ||
		''}, ${getIn(organization, ['primaryAddress', 'country']) ||
							getIn(initialValuesForEdit, [
								'seller',
								'primaryAddress',
								'country',
							]) ||
							''}.`}</P>

						<Spacer size={20} />
						<Text
							style={{
								fontSize: 14,
								color: '#8D93A0',
								marginBottom: 8,
							}}
						>
							{t('contracts.stateCountry')}
						</Text>
						<P>{`
							${getIn(organization, ['primaryAddress', 'state']) ||
								getIn(initialValuesForEdit, [
									'seller',
									'primaryAddress',
									'state',
								]) ||
								''}, ${getIn(organization, [
							'primaryAddress',
							'country',
						]) ||
							getIn(initialValuesForEdit, [
								'seller',
								'primaryAddress',
								'country',
							]) ||
							''}.`}</P>

						<Spacer size={12} />
					</Box>
					<Box
						style={{
							background: '#fff',
							padding: '28px',
							marginTop: 32,
						}}
					>
						<Label style={{ color: theme.color.blue5 }}>
							{t('contracts.buyer')}
						</Label>
						<Spacer size={8} />

						<Title
							title={getIn(currentOrgDetails, ['name'])}
							note={getIn(currentOrgDetails, [
								'categories',
								0,
								'name',
							])}
						/>
						<Spacer size={8} />
						<Text
							style={{
								fontSize: 14,
								color: '#8D93A0',
								marginBottom: 8,
							}}
						>
							{t('contracts.address')}
						</Text>
						<P>{`${getIn(currentOrgDetails, [
							'primaryAddress',
							'line1',
						]) || ''} ${getIn(currentOrgDetails, [
							'primaryAddress',
							'city',
						]) || ''},
 ${getIn(currentOrgDetails, ['primaryAddress', 'state']) ||
		''}, ${getIn(currentOrgDetails, ['primaryAddress', 'country']) ||
							''}.`}</P>

						<Spacer size={20} />
						<Text
							style={{
								fontSize: 14,
								color: '#8D93A0',
								marginBottom: 8,
							}}
						>
							{t('contracts.stateCountry')}
						</Text>
						<P>{`
							${getIn(currentOrgDetails, ['primaryAddress', 'state']) ||
								''} , ${getIn(currentOrgDetails, [
							'primaryAddress',
							'country',
						]) || ''}`}</P>
					</Box>
				</Box>
			</Box>
			<Box
				width={isMobile ? '100%' : '65%'}
				row
				justifyContent={isMobile ? 'center' : 'flex-end'}
				margin="36px 0 0"
			>
				<Button
					label={t('contracts.proceedToESign')}
					primary
					disabled={fileCount === 0}
					onClick={() => submitForm()}
					extendStyles={{ width: 266 }}
				/>
			</Box>
		</>
	)
}

export { AttachOwnContract }
