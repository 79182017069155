import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as GrommBox } from 'grommet'
import { Close } from 'grommet-icons'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'
import { H2, P, H3 } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { useFormik } from 'formik'
import { EmailLabel } from './EmailLabel'

const GetLabel = (email, index, removeEmail) => (
	<EmailLabel email={email} removeEmail={removeEmail} index={index} />
)

const ShareRFQModal = ({ onClose, onConfirm }) => {
	const { t } = useTranslation()
	const [emails, setEmails] = useState([])
	const checkEmailLength = emails.length > 10

	const { submitForm } = useFormik({
		initialValues: emails,
		onSubmit: () => {
			onConfirm(emails)
		},
	})

	const disableCTA = !emails.length || checkEmailLength

	return (
		<GrommBox width="720px" overflow>
			<Box row justifyContent="flex-end">
				<Button plain customIcon={<Close />} onClick={onClose} />
			</Box>
			<Box margin="0 40px">
				<H2
					style={{
						margin: '0 0 12px',
						fontSize: '30px',
						color: '#2D2D2D',
					}}
				>
					{t('shareRFQModal.heading')}
				</H2>
				<P style={{ fontSize: 18, color: '#8D93A0' }}>
					{t('shareRFQModal.subHeading')}
				</P>
				<H3 style={{ margin: '48px 0 8px', color: '#555454' }}>
					{t('shareRFQModal.label')}
				</H3>
				<ReactMultiEmail
					placeholder="placeholder"
					emails={emails}
					onChange={value => {
						setEmails(value)
					}}
					validateEmail={email => {
						return isEmail(email)
					}}
					getLabel={GetLabel}
				/>
				{checkEmailLength && (
					<P
						style={{
							color: theme.color.error,
							margin: '12px 0 8px',
						}}
					>
						{t('shareRFQModal.emailValidation')}
					</P>
				)}
				<P
					style={{
						color: '#8D93A0',
						fontSize: 14,
						margin: '12px 0 8px',
					}}
				>
					{t('shareRFQModal.helpText')}
				</P>
				<P style={{ color: '#8D93A0', fontSize: 14 }}>
					{t('shareRFQModal.emailExample')}
				</P>
				<Box margin="40px 0 60px">
					<Button
						primary
						onClick={() => submitForm()}
						disabled={disableCTA}
						label={t('shareRFQModal.sendRFQ')}
					/>
				</Box>
				<P style={{ fontSize: 14, color: '#ACB7CD' }}>
					{t('shareRFQModal.note')}
				</P>
			</Box>
		</GrommBox>
	)
}

export { ShareRFQModal }
