import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { getDateByFormat } from 'ui-marketplace-app/utils/date'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Icon } from 'ui-lib/icons/components/Icon'
import leftArrow from 'ui-lib/icons/left-arrow.svg'
import rightArrow from 'ui-lib/icons/right-arrow.svg'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'

const tableDataStyles = {
	border: '1px solid black',
	borderCollapse: 'collapse',
	textAlign: 'left',
	height: 56,
	paddingLeft: 16,
}

const tableHeaderStyles = {
	textAlign: 'left',
	color: '#172B4D',
	paddingBottom: 12,
}

const tableHeaderStylesForMobile = {
	width: '183px',
}

const tableStyles = {
	margin: '0 8px',
	width: 300,
}

const ViewSupplyAbility = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const {
		prevStartID = '',
		nextStartID = '',
		firstTable = [],
		secondTable = [],
	} = useSelector(MarketPlaceDuc.selectors.getSupplyAbility)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const productID = useSelector(MarketPlaceDuc.selectors.getProductID)

	const { payload, query = {} } = location
	const { organizationID } = query
	const { rootModule, documentReference } = payload

	return (
		<Box padding={8} width="100%" height="100%">
			<Title
				title={t('marketPlaceProducts.expectedSADetails')}
				icon={LeftArrowIcon}
				onClick={() => {
					if (organizationID) {
						dispatch(
							MarketPlaceDuc.creators.setDocumentViewStatus(true)
						)
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE,
								{
									rootModule: 'buyer',
								}
							)
						)
					} else {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$ACTION,
								{
									rootModule,
									action: 'view-product',
									documentReference: productID,
								}
							)
						)
					}
				}}
			/>

			<Box
				style={{
					background: '#FFFFFF 0% 0% no-repeat padding-box',
					borderRadius: '10px',
					opacity: 1,
					width: 'auto',
					height: 'auto',
				}}
				padding={24}
			>
				{firstTable.length > 0 && (
					<Box row justifyContent="flex-end">
						<Button
							label={t('marketPlaceProducts.prev30')}
							disabled={!prevStartID}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={leftArrow} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.MARKETPLACE$ACTION,
										{
											rootModule,
											action: 'view-supplyability',
											documentReference,
										},
										{
											startID: prevStartID,
											organizationID,
										}
									)
								)
							}
						/>
						<Button
							label={t('marketPlaceProducts.next30')}
							disabled={!nextStartID}
							rounded
							reverse
							extendStyles={{ marginLeft: '24px' }}
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={rightArrow} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.MARKETPLACE$ACTION,
										{
											rootModule,
											action: 'view-supplyability',
											documentReference,
										},
										{
											startID: nextStartID,
											organizationID,
										}
									)
								)
							}
						/>
					</Box>
				)}
				{firstTable.length === 0 && (
					<Box style={{ minHeight: 300 }}>
						<ErrorBlock
							hideButton
							status="empty"
							message={t('common.errorBlockMessage')}
						/>
					</Box>
				)}
				<Box
					row={!isMobile}
					justifyContent="space-between"
					margin="40px 0 0"
				>
					{firstTable.length > 0 && (
						<table style={tableStyles}>
							<tr>
								<th style={tableHeaderStyles}>
									{t('marketPlaceProducts.date')}
								</th>
								<th style={tableHeaderStyles}>
									{t('marketPlaceProducts.expectedSA')}
								</th>
							</tr>
							{firstTable.map(({ supplyAt, quantity, uom }) => {
								return (
									<tr key={supplyAt}>
										<td style={tableDataStyles}>
											{getDateByFormat(supplyAt)}
										</td>
										<td style={tableDataStyles}>
											{`${quantity} ${uom.toUpperCase()}`}
										</td>
									</tr>
								)
							})}
						</table>
					)}
					{secondTable.length > 0 && (
						<table style={tableStyles}>
							{
								<tr>
									{isMobile ? (
										<th />
									) : (
										<th style={tableHeaderStyles}>
											{t('marketPlaceProducts.date')}
										</th>
									)}
									{isMobile ? (
										<th
											style={tableHeaderStylesForMobile}
										/>
									) : (
										<th style={tableHeaderStyles}>
											{t(
												'marketPlaceProducts.expectedSA'
											)}
										</th>
									)}
								</tr>
							}
							{secondTable.map(({ supplyAt, quantity, uom }) => {
								return (
									<tr key={supplyAt}>
										<td style={tableDataStyles}>
											{getDateByFormat(supplyAt)}
										</td>
										<td style={tableDataStyles}>
											{`${quantity} ${uom.toUpperCase()}`}
										</td>
									</tr>
								)
							})}
						</table>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export { ViewSupplyAbility }
