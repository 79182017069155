import React, { useCallback } from 'react'
import { getIn } from 'timm'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { BoxShadowTable } from 'ui-marketplace-app/shared/components/BoxShadowTable'
import { ShowMore } from 'ui-marketplace-app/modules/MarketPlace/components/ShowMore'
import { TABLE_ALIAS } from 'ui-marketplace-app/modules/MarketPlace/config'
import { getDateByFormat } from 'ui-marketplace-app/utils/date'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'

export const columnConfig = ({ t, onChange, isMobile = false }) => {
	return [
		...(!isMobile
			? [
					{
						property: 'dateSent',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
							>
								<TableTitle>
									{t(TABLE_ALIAS.dateSent)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const dateSent = getIn(datum, ['createdAt'])

							return <Label>{getDateByFormat(dateSent)}</Label>
						},
					},
			  ]
			: []),
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const productName = getIn(datum, ['product', 'name'])
				const quantity = getIn(datum, ['quantity'])
				const uom = getIn(datum, ['product', 'uom']) || ''

				return (
					<>
						<Label>{productName}</Label>
						{isMobile && (
							<Label>
								{quantity} {uom.toUpperCase()}
							</Label>
						)}
					</>
				)
			},
		},
		...(!isMobile
			? [
					{
						property: 'quantity',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
							>
								<TableTitle>
									{t(TABLE_ALIAS.quantity)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const quantity = getIn(datum, ['quantity'])
							const uom = getIn(datum, ['product', 'uom']) || ''

							return (
								<Label>
									{quantity} {uom.toUpperCase()}
								</Label>
							)
						},
					},
			  ]
			: []),
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('view_document', {
								row: datum,
							})
						}}
					/>
				)
			},
		},
	]
}

const BuyerRequirementListing = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const myRequirementlist = useSelector(
		MarketPlaceDuc.selectors.getGeneralRequirementList
	)

	const pagination = useSelector(
		MarketPlaceDuc.selectors.getGeneralRequirementPaginationQueries
	)

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'view_document': {
					if (meta.row.id) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$ACTION,
								{
									rootModule: 'buyer',
									action: 'view-post-requirement',
									documentReference: meta.row.id,
								}
							)
						)
					}
					break
				}

				case 'myRequirements_pagination': {
					const locationState = {
						nextStartID: pagination.nextStartID,
					}

					dispatch(
						MarketPlaceDuc.creators.fetchGeneralRequirementListing(
							locationState
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, pagination.nextStartID]
	)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('postRequirement.generalRequirement'),
			name: 'generalRequirement',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<BoxShadowTable
				size="medium"
				columnConfig={columnConfig({
					t,
					onChange: handleChange,
					isMobile,
				})}
				rowData={myRequirementlist}
				isMobile={isMobile}
			/>
			<ShowMore
				documents={myRequirementlist}
				total={pagination.total}
				handleChange={handleChange}
				type="myRequirements_pagination"
			/>
		</>
	)
}

export { BuyerRequirementListing }
