import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { Button } from 'ui-lib/components/Button'
import { Text } from 'ui-lib/components/Typography'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import SuccessIcon from 'ui-lib/icons/success-round.svg'

const OrgDetails = ({ details, onDelete }) => {
	return (
		<Box row>
			<Box
				style={{
					background: '#FFFFFF 0% 0% no-repeat padding-box',
					border: '1px solid #F0911E',
					borderRadius: '6px',
					opacity: 1,
					width: '502px',
					height: '72px',
					marginBottom: 20,
				}}
			>
				<Text
					style={{
						padding: '12px 20px 18px',
						color: '#F0911E',
						fontWeight: 'bold',
					}}
				>
					{details.email}
				</Text>
				<Box row style={{ padding: '0 20px' }} alignItems="baseline">
					<Text
						style={{
							font: 'normal normal medium 16px/18px Roboto',
							letterSpacing: 0,
							color: '#242845',
							textTransform: 'capitalize',
							opacity: 1,
							width: 200,
							fontWeight: 500,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
						title={details.orgName}
					>
						{details.orgName}
					</Text>
					<Text
						style={{
							marginLeft: 12,
							font: 'normal normal normal 16px/21px Roboto',
							letterSpacing: 0,
							color: '#8D93A0',
							opacity: 1,
						}}
					>
						{details.place}
					</Text>
				</Box>
			</Box>
			<IconWrapper
				size={28}
				style={{ margin: '16px 0 12px 12px', cursor: 'pointer' }}
				onClick={() => onDelete(details.orgID)}
			>
				<Icon glyph={DeleteIcon} />
			</IconWrapper>
		</Box>
	)
}

const ExistingAccountsModal = ({ onConfirm }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const existingOrgList = useSelector(MarketPlaceDuc.selectors.getOrgList)
	const rfqPayloadForExistingOrgs = useSelector(
		MarketPlaceDuc.selectors.getRFQPayloadForExistingOrgs
	)

	const orgStatus = useSelector(
		MarketPlaceDuc.selectors.getOrgStatusForShareViaEmail
	)

	const { unregisteredStatus } = orgStatus

	const [organisations, setOrganisations] = useState(existingOrgList)

	useEffect(() => {
		setOrganisations(existingOrgList)
	}, [existingOrgList])

	function deleteOrg(orgID) {
		const orgListAfterDelete = organisations.filter(
			org => org.orgID !== orgID
		)

		setOrganisations(orgListAfterDelete)
	}

	function handleSubmit() {
		const payload = organisations.map(seller => {
			return {
				buyer: {
					id: rfqPayloadForExistingOrgs.buyer.id,
					name: rfqPayloadForExistingOrgs.buyer.name,
				},
				contractType: rfqPayloadForExistingOrgs.contractType || ' ',
				destinationPort:
					rfqPayloadForExistingOrgs.destinationPort || ' ',
				inspection: rfqPayloadForExistingOrgs.inspection || [],
				ladingPort: rfqPayloadForExistingOrgs.ladingPort || ' ',
				payment: rfqPayloadForExistingOrgs.payment || ' ',
				product: rfqPayloadForExistingOrgs.product || {},
				quantity: rfqPayloadForExistingOrgs.quantity,
				requirementDetails:
					rfqPayloadForExistingOrgs.requirementDetails || ' ',
				seller: {
					id: seller.orgID,
					name: seller.orgName,
				},
				shipment: rfqPayloadForExistingOrgs.shipment,
				supplychainModel: rfqPayloadForExistingOrgs.supplychainModel,
				meta: {
					inviteEmail: false,
					defaultRFQ: true,
					postRequirement: false,
					termsAndConditions:
						rfqPayloadForExistingOrgs.meta.termsAndConditions ||
						' ',
				},
			}
		})

		onConfirm(payload)
	}

	return (
		<>
			{unregisteredStatus === true ? (
				<>
					<Box alignItems="center">
						<IconWrapper size={60}>
							<Icon glyph={SuccessIcon} />
						</IconWrapper>
						<Text
							style={{
								fontSize: 32,
								color: '#2D2D2D',
								margin: '12px 0',
								fontWeight: 900,
							}}
						>
							{t('existingAccountsModal.rfqSent')}
						</Text>
						<Text
							style={{
								fontSize: 18,
								color: '#2D2D2D',
								paddingBottom: 4,
							}}
						>
							{t('existingAccountsModal.line1')}
						</Text>
					</Box>
					<Spacer size={32} />
					<Box style={{ marginLeft: 66, maxWidth: 576 }}>
						<Text
							style={{
								fontSize: 18,
								color: '#555454',
								fontWeight: 900,
								font: 'normal normal 900 18px/24px Avenir',
								letterSpacing: 0,
								opacity: 1,
							}}
						>
							{t('existingAccountsModal.line2')}
						</Text>
					</Box>
				</>
			) : (
				<Box alignItems="center">
					<Text
						style={{
							fontSize: 32,
							color: '#2D2D2D',
							margin: '12px 0',
							fontWeight: 900,
						}}
					>
						{t('existingAccountsModal.alert')}
					</Text>
					<Spacer size={24} />
					<Box style={{ maxWidth: 540 }}>
						<Text
							style={{
								fontSize: 18,
								color: '#555454',
								fontWeight: 900,
								font: 'normal normal 900 18px/24px Avenir',
								letterSpacing: 0,
								opacity: 1,
							}}
						>
							{t('existingAccountsModal.line3')}
						</Text>
					</Box>
				</Box>
			)}
			<Spacer size={32} />
			<Box alignItems="center">
				{organisations.map(orgDetails => (
					<OrgDetails
						key={orgDetails.orgID}
						details={orgDetails}
						onDelete={id => {
							deleteOrg(id)
						}}
					/>
				))}
			</Box>
			<Text
				style={{
					fontSize: 18,
					color: '#555454',
					fontWeight: 900,
					font: 'normal normal 900 18px/24px Avenir',
					letterSpacing: 0,
					opacity: 1,
					marginLeft: 65,
				}}
			>
				{t('existingAccountsModal.confirmationMessage')}
			</Text>

			<Box alignItems="center" margin="40px 0 0">
				<Box width={410}>
					<Button
						type="submit"
						primary
						label={
							organisations.length === 0
								? t('existingAccountsModal.proceedToListing')
								: t('existingAccountsModal.sendRFQ')
						}
						onClick={() => {
							if (organisations.length === 0) {
								dispatch(
									MarketPlaceDuc.creators.toggleExistingAccountsModal(
										false
									)
								)
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.MARKETPLACE,
										{
											rootModule: 'buyer',
										}
									)
								)
							} else handleSubmit()
						}}
					/>
				</Box>
			</Box>
		</>
	)
}

export { ExistingAccountsModal }
