import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { addLast, getIn } from 'timm'
import { Close } from 'grommet-icons'
import { Box } from 'ui-lib/utils/Box'
import { Modal } from 'ui-lib/components/Modal'
import { CoreMenu, MenuItem } from 'ui-lib/components/CoreMenu'
import theme from 'ui-lib/utils/base-theme'
import { P, H3, H1 } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import { RadioInput } from 'ui-lib/components/Radio'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DibizIcon from 'ui-lib/icons/dibiz-full-logo-transparent.svg'
import DibizIconMobile from 'ui-lib/icons/dibiz-logo.svg'
import SettingsIcon from 'ui-lib/icons/settings.svg'
import forwardArrowIcon from 'ui-lib/icons/arrow_forward_black_24dp.svg'
import HelpIcon from 'ui-lib/icons/help.svg'
import doneArrowIcon from 'ui-lib/icons/done_black_24dp.svg'
import LeftArrowIcon from 'ui-lib/icons/arrow_forward.svg'
import {
	Button,
	ButtonIconWrapper,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import {
	NotificationBadge,
	NotificationItem,
	ListItem,
	UnOrderedList,
	UserProfile,
	OfflineToast,
	HeaderRightBlock,
	LogoSearch,
	HeaderProfileText,
	SwitchProfile,
	isAtleastOneRuleActive,
	getAllowed,
} from 'ui-lib/utils/headerComponents'
import { getAppVersion } from 'ui-lib/config'
import { isIframeForTeamsApp } from '../../utils/helpers'

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
type Props = {
	virtualAccess?: Boolean,
	showSwitchProfile?: Boolean,
	showSwitchActor?: Boolean,
	showreminderForDOUploadModal?: Boolean,
	showPopUpModal?: Boolean,
	showModal?: Boolean,
	showCPModal?: Boolean,
	isMobile?: Boolean,
	isDesktop?: Boolean,
	isTablet?: Boolean,
	isLoading?: Boolean,
	profileMenuHeight?: Boolean,
	isOnline?: Boolean,
	lastSeenDate?: String,
	selectedCPOrg?: String,
	previousWeekFirstDate?: String,
	previousWeekLastDate?: String,
	pathname?: String,
	fullName?: String,
	orgRole?: String,
	orgName?: String,
	tourModule?: String,
	selectedCPID?: String,
	notificationsHeight?: String,
	background?: String,
	actor?: String,
	currentOrg?: Object,
	switchProfileList?: Array,
	signInComponents?: Array,
	switchActorList?: Array,
	tourRoleInfo?: Array,
	tourVisitStatus?: Array,
	navigationBaseReference?: Array,
	allowed?: Array,
	denied?: Array,
	featureAccessBasedOnType?: Object,
	notifications?: Object,
	lastSelectedNode?: Object,
	getWebtour?: () => void,
	useFormik?: () => void,
	setShowModal?: String => void,
	setShowSwitchProfile?: String => void,
	setShowSwitchActor?: String => void,
	setProfileMenuHeight?: String => void,
	setShowPopUpModal?: String => void,
	switchProfileLogin?: String => void,
	setSelectedCPOrg?: String => void,
	setShowCPModal?: String => void,
	setNotificationsHeight?: String => void,
	setShowreminderForDOUploadModal?: String => void,
	setLastSelectedNode?: Object => void,
	setbackground?: String => void,
	ref?: () => void,
	notifiyRef?: () => void,
	handleDispatch?: (
		type: String,
		route: Object,
		status: Boolean,
		selectedOrg: String
	) => void,
	handleTourComponentUpdate?: (name: String, iconName: String) => void,
	handleNotificationClick?: (notification: Object) => void,
	getDateByFormat?: () => void,
	handleUseDateByFormat?: () => void,
	getPathBasedOnAction?: (
		type: String,
		payload?: Object,
		query?: Object
	) => void,
}
const HEADER_HEIGHT = isIframeForTeamsApp() ? 0 : 64

const Wrapper = styled.div(p => ({
	height: HEADER_HEIGHT,
	zIndex: p.theme.zIndex.level5,
	backgroundColor: p.theme.color.white1,
	borderBottom: `1px solid ${p.theme.color.grey4}`,
	position: 'fixed',
	top: 0,
	width: '100vw',
}))

const Illustration = styled.img`
	width: 40%;
	align-self: center;
`
const ListTourBox = styled(Box)({
	cursor: 'pointer',
	color: theme.color.black3,
	flexDirection: 'row',
	justifyContent: 'space-between',
	':hover': {
		color: theme.color.blue5,
	},
})

const ListTourItem = styled.span({
	border: 'none',
	textDecoration: 'none',
	textAlign: 'left',
	letterSpacing: '0px',
	opacity: 1,
})

const HeaderComponent = ({
	ref = () => {},
	notifiyRef = () => {},
	useFormik = () => {},
	getWebtour = () => {},
	setbackground = () => {},
	setShowSwitchProfile = () => {},
	setShowSwitchActor = () => {},
	switchProfileLogin = () => {},
	setShowreminderForDOUploadModal = () => {},
	setShowPopUpModal = () => {},
	setShowModal = () => {},
	setSelectedCPOrg = () => {},
	setShowCPModal = () => {},
	setProfileMenuHeight = () => {},
	setNotificationsHeight = () => {},
	setLastSelectedNode = () => {},
	handleTourComponentUpdate = () => {},
	handleNotificationClick = () => {},
	getPathBasedOnAction = () => {},
	selectedCPOrg = '',
	pathname = '',
	fullName = '',
	orgRole = '',
	orgName = '',
	selectedCPID = '',
	tourModule = '',
	actor = '',
	notificationsHeight = '',
	background = '',
	lastSeenDate = '',
	previousWeekFirstDate = '',
	previousWeekLastDate = '',
	currentOrg = {},
	tourRoleInfo = [],
	tourVisitStatus = [],
	navigationBaseReference = [],
	switchProfileList = [],
	switchActorList = [],
	allowed = [],
	denied = [],
	signInComponents = [],
	notifications = {},
	lastSelectedNode = {},
	featureAccessBasedOnType = {},
	getDateByFormat = () => {},
	handleUseDateByFormat = () => {},
	handleDispatch = () => {},
	isOnline = false,
	virtualAccess = false,
	showSwitchProfile = false,
	showSwitchActor = false,
	showreminderForDOUploadModal = false,
	showPopUpModal = false,
	showModal = false,
	showCPModal = false,
	isMobile = false,
	isDesktop = false,
	isTablet = false,
	isLoading = false,
	profileMenuHeight = false,
}: Props) => {
	const { t } = useTranslation()
	const isDealer = actor.includes('palmoil_ffbdealer')
	const isSubDealer = actor.includes('palmoil_ffbsubdealer')
	const currentStatus = getIn(currentOrg, ['status', 'state']) || ''
	const orgImage = getIn(currentOrg, ['meta', 'logo'])
	const childOrg = getIn(currentOrg, ['meta', 'childOrganization']) || []
	const selectedChildOrg = childOrg.filter(d => d.id === selectedCPID)
	const childOrgName = getIn(selectedChildOrg, [0, 'name']) || ''
	const totalNotifications = notifications.total || 0
	const notificationList = notifications.list || []

	const getInitials = (name, delimeter) => {
		if (name) {
			const arr = name.split(delimeter)

			if (arr.length === 1) {
				return `${arr[0].charAt(0).toUpperCase()}${arr[0]
					.charAt(1)
					.toUpperCase()} `
			}

			return (
				arr[0].charAt(0).toUpperCase() +
				arr[arr.length - 1].charAt(0).toUpperCase()
			)
		}

		return ''
	}

	const notificationComponent = (
		<>
			<Box
				style={{
					position: 'relative',
					marginRight: '16px',
					display: 'inline-block',
					verticalAlign: 'middle',
					paddingTop: 10,
					background,
					borderRadius: '4px',
					cursor: 'pointer',
				}}
				width={50}
				onClick={() => {
					if (notificationsHeight === '0px') {
						if (totalNotifications <= 6) {
							setNotificationsHeight(
								(totalNotifications + 1) * 66
							)
							setbackground('#F0F0F1')
						} else {
							setNotificationsHeight('390px')
							setbackground('#F0F0F1')
						}
					} else {
						setNotificationsHeight('0px')
						setbackground('')
					}
				}}
				ref={notifiyRef}
				title={
					totalNotifications === 0 ? t('topBar.noNotifications') : ''
				}
			>
				<NotificationBadge size={30} count={totalNotifications} />
			</Box>

			<UnOrderedList
				height={notificationsHeight}
				top="64px"
				right="13px"
				width={isMobile ? '95vw' : '558px'}
				style={{
					overflowY: 'auto',
					position: 'absolute',
					bottom: 0,
					zIndex: 1,
				}}
			>
				{notificationList.map(data => {
					return (
						<NotificationItem
							padding="16px 32px 16px 16px"
							key={data.id}
							onClick={handleNotificationClick}
							data={data}
							handleUseDateByFormat={handleUseDateByFormat}
						/>
					)
				})}
				<ListItem
					key="notifications-showall"
					style={{
						fontSize: theme.fontSize.s,
						lineHeight: '8px',
						color: '#172B4D',
						textAlign: 'center',
						background: '#fff',
						textTransform: 'uppercase',
						position: 'sticky',
						bottom: 0,
						zIndex: 2,
						borderTop: '1px solid #E5E5E5',
						padding: '16px 0',
					}}
					onClick={() => {
						handleDispatch('notificationSettings')
						setNotificationsHeight('0px')
					}}
				>
					{totalNotifications === 0
						? t('topBar.noNotifications')
						: t('topBar.showAll')}
				</ListItem>
			</UnOrderedList>
		</>
	)

	const getMenuList = menuListItems => {
		const {
			navigationReference,
			pathName,
			_handleRouteChange,
			accessCheck,
			isMobile,
			t,
			virtualAccess = false,
			index = 0,
			parent = '---',
		} = menuListItems

		const { allowed, denied } = accessCheck

		// if you have some rules to filter out the pass the rules
		return navigationReference.reduce((agg, conf, _index) => {
			let aggregator = agg
			// check if the conf can make it to the list
			const features =
				conf.featureAccess || featureAccessBasedOnType[conf.action]

			const isModuleAllowed = isAtleastOneRuleActive(
				getAllowed(features, allowed, denied)
			)

			if (isModuleAllowed) {
				const path = getPathBasedOnAction(
					conf.action,
					conf.payload,
					conf.query
				)
				const arr = path.split('/')
				const _arr = pathName.split('/')

				const __path = `/${getIn(arr, [1])}`
				const ___path = `/${getIn(_arr, [1])}`

				const isActive = pathName === path
				const isChildOpen = __path === ___path

				const getAggList = aggr => {
					if (virtualAccess) {
						if (
							conf.title === 'sideBar.home' ||
							conf.title === 'sideBar.marketPlace' ||
							conf.title === 'sideBar.tdm' ||
							conf.title === 'sideBar.partnetMgnt'
						) {
							aggr.push(
								<MenuItem
									key={`${conf.title}-${index}-${parent}`}
									isFirst={_index === 0}
									index={index}
									icon={conf.icon}
									id={`${conf.title}`}
									title={t(conf.title)}
									onClick={({ toggleMenu }) => {
										if (isMobile) {
											toggleMenu(false)
										}
										if (conf.subRoutes) {
											setLastSelectedNode({
												path,
												status: !lastSelectedNode.status,
											})
										}

										_handleRouteChange(
											conf.action,
											conf.payload
										)
									}}
									isActive={
										isActive && lastSelectedNode.status
									}
									path={path}
									hasChildren={conf.subRoutes}
								/>
							)
						}
					} else {
						aggr.push(
							<MenuItem
								key={`${conf.title}-${index}-${parent}`}
								isFirst={_index === 0}
								index={index}
								icon={conf.icon}
								id={`${conf.title}`}
								title={t(conf.title)}
								onClick={({ toggleMenu }) => {
									if (isMobile) {
										toggleMenu(false)
									}
									if (conf.subRoutes) {
										setLastSelectedNode({
											path,
											status: !lastSelectedNode.status,
										})
									}
									_handleRouteChange(
										conf.action,
										conf.payload
									)
								}}
								isActive={isActive && lastSelectedNode.status}
								path={path}
								hasChildren={conf.subRoutes}
							/>
						)
					}

					return aggr
				}

				aggregator = getAggList(aggregator)

				const menuItems = {
					navigationReference: conf.subRoutes,
					pathName,
					_handleRouteChange,
					accessCheck: { allowed, denied },
					isMobile,
					t,
					virtualAccess,
					index: index + 1,
					parent: conf.title,
				}

				// if there are children, recursively get them too
				if (conf.subRoutes && isChildOpen && lastSelectedNode.status) {
					aggregator = addLast(aggregator, getMenuList(menuItems))
				}
			}

			return aggregator
		}, [])
	}

	const handleRouteChange = (type, payload) => {
		handleDispatch('handleRouteChange', type, false, false, payload)
	}

	const menuListItems = {
		navigationReference: navigationBaseReference,
		pathName: pathname,
		_handleRouteChange: handleRouteChange,
		accessCheck: { allowed, denied },
		isMobile,
		t,
		virtualAccess,
		index: 0,
		parent: undefined,
	}
	const MenuItems = getMenuList(menuListItems)

	return (
		<div>
			{typeof virtualAccess !== 'undefined' && (
				<div>
					<SwitchProfile
						list={switchProfileList}
						t={t}
						useFormik={useFormik}
						onClose={() => setShowSwitchProfile(false)}
						onSubmit={data => switchProfileLogin(data)}
						isVisible={showSwitchProfile}
					/>
					<SwitchProfile
						list={switchActorList}
						t={t}
						useFormik={useFormik}
						onClose={() => setShowSwitchActor(false)}
						onSubmit={data => switchProfileLogin(data)}
						isVisible={showSwitchActor}
					/>
					<Modal
						forceCloseviaButton
						size="large"
						show={showreminderForDOUploadModal}
						heading={t('common.reminder')}
						body={
							<Box
								style={{
									fontWeight: 500,
								}}
							>
								<Box row>
									<span>
										{t(
											'tdmDocumentListing.supplyChainReminderText'
										)}
									</span>
									&nbsp;
									<span
										style={{ color: theme.color.primary }}
									>
										{getDateByFormat(previousWeekFirstDate)}
									</span>
									&nbsp;
									<span>
										{t(
											'tdmDocumentListing.to'
										).toLowerCase()}
									</span>
									&nbsp;
									<span
										style={{ color: theme.color.primary }}
									>
										{getDateByFormat(previousWeekLastDate)}
									</span>
									.
								</Box>
								<Box>
									{t(
										'tdmDocumentListing.supplyChainConfirmationText'
									)}
								</Box>
							</Box>
						}
						closelabel={t('common.no')}
						confirmlabel={t('common.yes')}
						onClose={() => setShowreminderForDOUploadModal(false)}
						onConfirm={() => {
							setShowreminderForDOUploadModal(false)
							handleDispatch('DOUploadReminder')
						}}
					/>
					<Modal
						noPadding={false}
						hideButtons
						size="100%"
						height="40%"
						bgColor="#FFFFFF 0% 0% no-repeat padding-box"
						extendStyles={{
							boxShadow: '0px 6px 16px #0000004D',
							borderRadius: '4px',
							opacity: 1,
							padding: '0px 50px',
						}}
						closeable
						show={showPopUpModal}
						overflow="auto"
						body={
							<>
								<H1
									style={{
										textAlign: 'center',
										font: 'normal normal bold 24px Roboto',
										letterSpacing: '0px',
										color: theme.color.black3,
										opacity: 1,
									}}
								>
									{t('tourCommon.heading')}
								</H1>
								<Spacer size={40} />
								<Box width="70%" alignSelf="center">
									<Illustration
										src="/images/tour.svg"
										alt="dibizTour"
									/>
									<Spacer size={20} />
									<H3
										style={{
											color: theme.color.blue5,
											textAlign: 'center',
											font:
												'normal normal medium 20px Roboto',
											letterSpacing: '0px',
											opacity: 1,
											':hover': {
												color: theme.color.white,
												background: '#645D95',
											},
										}}
									>
										{t('tourCommon.subHeading')}
									</H3>
								</Box>
								<Spacer size={60} />
								<Box>
									{tourRoleInfo.map(
										({ index, name, iconName, route }) => {
											return (
												<Box key={`${index}-${route}`}>
													<ListTourBox
														style={{
															...(tourVisitStatus[
																index
															] === true && {
																color: 'green',
															}),
														}}
														onClick={() => {
															handleTourComponentUpdate(
																name,
																iconName
															)
															handleDispatch(
																'routing',
																route
															)
														}}
													>
														<Box
															row
															style={{
																color:
																	'inherit',
															}}
														>
															{iconName ===
															'none' ? (
																<p>
																	&ensp;&ensp;&ensp;-
																</p>
															) : (
																<IconWrapper
																	size={20}
																	padding="0% 0% 0% 1%"
																	color="inherit"
																>
																	<Icon
																		glyph={
																			iconName
																		}
																		color="inherit"
																	/>
																</IconWrapper>
															)}
															<Spacer
																size={10}
																horizontal
															/>
															<ListTourItem
																style={{
																	color:
																		'inherit',
																	font:
																		iconName ===
																		'none'
																			? 'normal normal normal 14px Roboto'
																			: 'normal normal normal 16px Roboto',
																}}
															>
																{t(
																	`tourCommon.${name}`
																)}
															</ListTourItem>
														</Box>
														<Box
															style={{
																color:
																	'inherit',
															}}
														>
															<IconWrapper
																size={20}
																style={{
																	padding:
																		'1%',
																	color:
																		'inherit',
																}}
															>
																<Icon
																	glyph={
																		tourVisitStatus[
																			index
																		]
																			? doneArrowIcon
																			: forwardArrowIcon
																	}
																	color="inherit"
																/>
															</IconWrapper>
														</Box>
													</ListTourBox>

													<Spacer size={24} />
												</Box>
											)
										}
									)}
									<Button
										onClick={() => {
											setShowPopUpModal(false)
											handleDispatch(
												'GUIDED_TOUR_SKIPPED'
											)
											handleDispatch(
												'setTourStatus',
												{},
												false
											)
											handleDispatch(
												'setTourOpenStatus',
												{},
												false
											)
										}}
										label={t('common.skip')}
										plain
										extendStyles={{
											textAlign: 'right',
											font:
												'normal normal medium 20px Roboto',
											letterSpacing: '0px',
											color: theme.color.blue5,
											opacity: '1',
										}}
									/>
								</Box>
							</>
						}
						onClose={() => {
							setShowPopUpModal(false)
							handleDispatch('setTourOpenStatus', {}, false)
							handleDispatch('setTourStatus', {}, false)
							handleDispatch('setTourPopupOpen', {}, false)

							if (signInComponents.length) {
								handleDispatch(
									'setFirstTimeSignInModalStatus',
									{},
									true
								)
								handleDispatch(
									'setFirstTimeSignInModalStatusLogin',
									{},
									true
								)
							}
						}}
						isMobile={isMobile}
					/>
					<Modal
						closeable
						show={showModal}
						heading={t('topBar.logout')}
						body={
							<P large bold>
								{t('topBar.logoutMsg')}
							</P>
						}
						closelabel={t('common.no')}
						confirmlabel={t('common.yes')}
						onClose={() => {
							setShowModal(false)
						}}
						onConfirm={() => {
							handleDispatch('signOutUser')
						}}
						isMobile={isMobile}
					/>
					<Modal
						hideButtons
						show={showCPModal}
						heading={t('login.chooseProfile')}
						body={
							<>
								<Box>
									<RadioInput
										hideError
										selected={selectedCPOrg === undefined}
										label={`${currentOrg.name} (Own)`}
										name={currentOrg.id}
										link
										returnKeyValue
										onChange={() => {
											setSelectedCPOrg('')
										}}
									/>
								</Box>
								{childOrg.map(datum => {
									return (
										<Box
											padding="15px 0px 0px 0px"
											key={datum.id}
										>
											<RadioInput
												hideError
												selected={
													selectedCPOrg === datum.id
												}
												label={datum.name}
												name={datum.id}
												link
												returnKeyValue
												onChange={() => {
													setSelectedCPOrg(datum.id)
												}}
											/>
										</Box>
									)
								})}
								<Box justifyContent="flex-end" row>
									<Button
										extendStyles={{
											borderRadius: '4px',
											fontSize: '16px',
											padding: '6px',
											textAlign: 'center',
											textDecoration: 'solid',
											width: '145px',
										}}
										onClick={() => setShowCPModal(false)}
										label={t('common.back')}
									/>
									<Button
										extendStyles={{
											borderRadius: '4px',
											fontSize: '16px',
											padding: '6px',
											textAlign: 'center',
											textDecoration: 'solid',
											marginLeft: '20px',
										}}
										onClick={() => {
											if (selectedCPOrg) {
												handleDispatch(
													'SELECTED_CP_ID',
													{},
													false,
													selectedCPOrg.toString()
												)
											} else {
												handleDispatch(
													'SELECTED_CP_ID_DELETE'
												)
											}
											handleDispatch(
												'switchPageDashboard'
											)

											setShowCPModal(false)
										}}
										label="Choose Organisation"
										primary
										plain
									/>
								</Box>
							</>
						}
						closelabel={t('common.no')}
						confirmlabel={t('common.yes')}
						onClose={() => {
							setShowCPModal(false)
						}}
						isMobile={isMobile}
					/>

					<Spacer size={HEADER_HEIGHT} />

					{!isIframeForTeamsApp() && (
						<Wrapper>
							<CoreMenu
								open={!isMobile}
								isMobile={isMobile}
								MenuItems={MenuItems}
								isLoading={isLoading}
								customMenuHeadingComponent={
									<Box
										style={{
											display: 'inline-block',
											width: '266px',
										}}
										padding={16}
										onClick={() => {
											if (profileMenuHeight === '0px') {
												setProfileMenuHeight('174px')
											} else {
												setProfileMenuHeight('0px')
											}
										}}
										ref={ref}
									>
										<Box
											row
											alignItems="flex-end"
											style={{
												padding: '0 8px',
											}}
										>
											<UserProfile bgUrl={orgImage}>
												{!orgImage &&
													getInitials(fullName)}
											</UserProfile>
											<div
												style={{
													display: 'inline-block',
													verticalAlign: 'middle',
												}}
											>
												<HeaderProfileText
													fontSize={theme.fontSize.s}
													title={fullName}
												>
													{fullName}
												</HeaderProfileText>
												<Spacer size={4} />

												<HeaderProfileText
													title={orgRole}
													fontSize={theme.fontSize.s}
												>
													{orgRole}
												</HeaderProfileText>
											</div>
										</Box>
										<Box
											style={{
												padding: '0 8px ',
											}}
										>
											<Spacer size={20} />
											<HeaderProfileText
												title={orgName}
												fontSize={theme.fontSize.s}
												fullMaxWidth
											>
												{orgName}
											</HeaderProfileText>
										</Box>

										<Spacer size={20} />
										<Box
											style={{
												border: '1px solid #9398B1',
												borderRadius: '4px',
											}}
										>
											<Button
												extendStyles={{
													width: '100%',
													borderRadius: '4px',
													fontSize: '16px',
													background: '#2A2364',
													padding: '6px',
													textAlign: 'center',
													textDecoration: 'solid',
												}}
												onClick={() =>
													setShowModal(true)
												}
												label={t('topBar.logout')}
												primary
												plain
											/>
										</Box>
										{switchProfileList.length > 0 && (
											<Box
												style={{
													border: '1px solid #9398B1',
													borderRadius: '4px',
													margin: '10px 0px 0px 0px',
												}}
											>
												<Button
													extendStyles={{
														width: '100%',
														borderRadius: '4px',
														fontSize: '16px',
														background: '#2A2364',
														padding: '6px',
														textAlign: 'center',
														textDecoration: 'solid',
													}}
													onClick={() =>
														setShowSwitchProfile(
															true
														)
													}
													label={t(
														'login.switchProfile'
													)}
													primary
													plain
												/>
											</Box>
										)}
										{switchActorList.length > 0 && (
											<Box
												style={{
													border: '1px solid #9398B1',
													borderRadius: '4px',
													margin: '10px 0px 0px 0px',
												}}
											>
												<Button
													extendStyles={{
														width: '100%',
														borderRadius: '4px',
														fontSize: '16px',
														background: '#2A2364',
														padding: '6px',
														textAlign: 'center',
														textDecoration: 'solid',
													}}
													onClick={() =>
														setShowSwitchActor(true)
													}
													label={t(
														'login.switchActor'
													)}
													primary
													plain
												/>
											</Box>
										)}
										{selectedCPID && (
											<Box padding="20px 0px 0px 0px">
												{t('login.cpReadOnly')}
											</Box>
										)}
									</Box>
								}
								topPosition={0}
								version={getAppVersion()}
							/>

							{tourModule === 'dashboard' &&
								!isLoading &&
								getWebtour()}
							<Box
								row
								center
								style={{
									height: 'inherit',
									paddingLeft: isMobile ? 50 : 309,
								}}
								justifyContent="space-between"
							>
								{(isDesktop || isTablet) && (
									<LogoSearch>
										<IconWrapper size={100}>
											<Icon glyph={DibizIcon} />
										</IconWrapper>
									</LogoSearch>
								)}
								{isMobile && (
									<LogoSearch>
										<IconWrapper
											size={50}
											onClick={() =>
												handleDispatch(
													'switchPageDashboard'
												)
											}
										>
											<Icon glyph={DibizIconMobile} />
										</IconWrapper>
									</LogoSearch>
								)}
								{virtualAccess ? (
									<>
										<div
											style={{
												margin: 0,
												width: '400px',
												height: 'auto',
												background:
													'#fff1dd 0% 0% no-repeat padding-box',
												border: '1px solid #f09112',
												borderLeft:
													'10px solid #f09112',
												borderRadius: '6px',
												opacity: 1,
												color: '#c97b12',
												letterSpacing: 0,
												fontSize: '16px',
											}}
											title={t(
												'setup.completeRegistration'
											)}
										>
											<Box
												row
												padding={16}
												alignContent="center"
												style={{
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												<Box
													style={{
														fontWeight: 500,
														fontSize: '16px',
														color:
															theme.color.blue5,
													}}
												>
													{t(
														'setup.youAreInVirtualView'
													)}
												</Box>
											</Box>
										</div>
									</>
								) : (
									''
								)}
								<HeaderRightBlock>
									{(isDealer || isSubDealer) &&
										childOrg.length > 0 && (
											<Button
												action
												rounded
												customIcon={
													<ButtonIconWrapper
														color={
															theme.color.blue5
														}
														lightBG
													>
														<Icon
															glyph={
																LeftArrowIcon
															}
														/>
													</ButtonIconWrapper>
												}
												label={
													selectedCPID
														? childOrgName
														: 'Own Organisation'
												}
												reverse
												onClick={() => {
													setShowCPModal(true)
												}}
												extendStyles={{
													color: '#2D2D2D',
													marginRight: '48px',
													borderColor: '#ACB7CD',
												}}
											/>
										)}
									{currentStatus === 'verified' && (
										<Button
											plain
											customIcon={
												<ButtonIconWrapper
													color="#172B4D"
													size={22}
												>
													<Icon glyph={HelpIcon} />
												</ButtonIconWrapper>
											}
											onClick={() => {
												handleDispatch(
													'setTourStatus',
													{},
													true
												)
												handleDispatch(
													'setTourOpenStatus',
													{},
													true
												)
											}}
										/>
									)}
									{virtualAccess && (
										<>
											<Spacer size={36} horizontal />
											<span
												style={{
													border: '1px solid red',
													padding: 10,
													color: 'red',
													borderRadius: 4,
												}}
											>
												{t('setup.unverified')}
											</span>
										</>
									)}
									<Spacer size={36} horizontal />
									<div id="SettingsHeader">
										<IconWrapper
											size={30}
											onClick={() =>
												handleDispatch('settings')
											}
											title={t('topBar.settings')}
										>
											<Icon
												glyph={SettingsIcon}
												color="#172B4D"
											/>
										</IconWrapper>
									</div>
									<Spacer size={45} horizontal />

									<div id="NotificationsHeader">
										{notificationComponent}
									</div>
								</HeaderRightBlock>
							</Box>
							{isOnline && showreminderForDOUploadModal && (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										marginLeft: '272px',
										height: '36px',
										fontSize: theme.fontSize.s,
										lineHeight: '16px',
										background: '#ccc',
										position: 'relative',
										backgroundColor: '#fffdd0',
										overflow: 'hidden',
										paddingLeft: 38,
									}}
								>
									<div>
										{t(
											'tdmDocumentListing.supplyChainReminderText'
										)}
										&nbsp;
										<span
											style={{
												color: theme.color.primary,
											}}
										>
											{getDateByFormat(
												previousWeekFirstDate
											)}
										</span>
										&nbsp;
										<span>
											{t(
												'tdmDocumentListing.to'
											).toLowerCase()}
										</span>
										&nbsp;
										<span
											style={{
												color: theme.color.primary,
											}}
										>
											{getDateByFormat(
												previousWeekLastDate
											)}
										</span>
										.{' '}
										<span>
											Click
											<ButtonWithNoBorder
												label="here"
												style={{
													color: theme.color.primary,
												}}
												onClick={() => {
													setShowreminderForDOUploadModal(
														false
													)
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.TRADE_DOCUMENT_MANAGER$WACTION,
															{
																rootModule:
																	'upload-delivery-order',
																action:
																	'attachDetails',
															},
															{},
															{},
															true
														)
													)
												}}
											/>
											to upload your data
										</span>
									</div>
									<div style={{ marginRight: 60 }}>
										<Button
											plain
											customIcon={
												<Close
													style={{
														height: 15,
														width: 15,
														cursor: 'pointer',
													}}
												/>
											}
											onClick={() =>
												setShowreminderForDOUploadModal(
													false
												)
											}
										/>
									</div>
								</div>
							)}
							{!isOnline && (
								<OfflineToast>
									<div>{t('offline.notification')}</div>
									<div>
										{t('offline.lastOnline')}:{' '}
										{lastSeenDate}
									</div>
								</OfflineToast>
							)}
						</Wrapper>
					)}
				</div>
			)}
		</div>
	)
}

export { HeaderComponent }
