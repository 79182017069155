import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { merge } from 'timm'
import { getStates, getCountries } from 'country-state-picker'
import { CheckBox } from 'grommet'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { CustomSelectSearch, Select } from 'ui-lib/components/Select'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Input } from 'ui-lib/components/Input'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'

const Label = styled.h4({
	fontSize: '14px',
	color: '#8D93A0',
})

const AdvanceFilter = ({
	filterValue,
	onChange,
	onDateChange,
	supplyAtStart,
	supplyAtEnd,
	isMobile = false,
}) => {
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload = {} } = location
	const { rootModule } = payload
	const { t } = useTranslation()
	const countries = getCountries()

	const countryList = []
	// eslint-disable-next-line array-callback-return
	countries.map(value => {
		if (value.code !== 'gg') {
			countryList.push({
				id: value.code,
				name: value.name,
			})
		}
	})

	const [stateList, setStateList] = useState([])

	const getStateCode = code => {
		const _stateList = []
		code.forEach(state => _stateList.push(getStates(state.id)))
		// eslint-disable-next-line prefer-spread
		const newStateList = [].concat.apply([], _stateList)
		const stateDataList = newStateList.map(state => {
			return {
				id: state,
				name: state,
			}
		})

		setStateList(stateDataList)
	}

	const handleChange = useCallback(
		keyValue => {
			onChange(merge(filterValue, keyValue))
		},

		[onChange, filterValue]
	)

	return (
		<>
			<Box row={!isMobile} justifyContent="space-between" margin="0 32px">
				<Box width={300}>
					<Label
						required
						style={{
							fontSize: '14px',
							color: theme.color.accent2,
						}}
					>
						{t('marketPlace.organisationType')}
					</Label>
					<Spacer size={12} />
					<CustomSelectSearch
						value={filterValue.organisationType}
						placeholder={t('marketPlace.organisationType')}
						options={[
							{
								name: 'Palmoil FFB Dealer',
								id: 'palmoil-ffbDealer',
							},
							{
								name: 'Palmoil SubDealer',
								id: 'palmoil-ffbSubDealer',
							},
							{
								name: 'Palmoil Plantation',
								id: 'palmoil-plantation',
							},
							{
								name: 'Palmoil Mill',
								id: 'palmoil-mill',
							},
							{
								name: 'Palm Kernel Mill',
								id: 'palmoil-kernelMill',
							},
							{
								name: 'Palmoil Refinery',
								id: 'palmoil-refinery',
							},
							{
								name: 'Palmoil Trader',
								id: 'palmoil-trader',
							},
							{
								name: 'Palmoil Storage',
								id: 'palmoil-storage',
							},
							{
								name: 'Palmoil End Manufacturer',
								id: 'palmoil-endManufacturer',
							},
							{
								name: 'Palmoil Oleo Chemicals',
								id: 'palmoil-oleochemicals',
							},
						]}
						onChange={value =>
							handleChange({ organisationType: value })
						}
					/>
				</Box>
				{isMobile && <Spacer size={12} />}
				<Box width={300}>
					<Label
						required
						style={{
							fontSize: '14px',
							color: theme.color.accent2,
						}}
					>
						{t('marketPlace.certificateType')}
					</Label>
					<Spacer size={12} />
					<CustomSelectSearch
						value={filterValue.certificateType}
						placeholder={t('marketPlace.certificateType')}
						options={[
							{
								name: 'RSPO',
								id: 'rspo',
							},
							{
								name: 'MSPO',
								id: 'mspo',
							},
							{
								name: 'MPOB',
								id: 'mpob',
							},
							{
								name: 'ISCC',
								id: 'iscc',
							},
							{
								name: 'ISPO',
								id: 'ispo',
							},
						]}
						onChange={value =>
							handleChange({ certificateType: value })
						}
					/>
				</Box>
			</Box>

			<Spacer size={36} />
			<Box row={!isMobile} justifyContent="space-between" margin="0 32px">
				<Box width={300}>
					<Label
						required
						style={{
							fontSize: '14px',
							color: theme.color.accent2,
						}}
					>
						{t('onboarding.countryPlaceHolder')}
					</Label>
					<Spacer size={12} />
					<CustomSelectSearch
						value={filterValue.country}
						placeholder={t('onboarding.countryPlaceHolder')}
						options={countryList}
						onChange={value => {
							handleChange({ country: value })
							getStateCode(value)
						}}
					/>
				</Box>
				{isMobile && <Spacer size={12} />}
				<Box width={300}>
					<Label
						required
						style={{
							fontSize: '14px',
							color: theme.color.accent2,
						}}
					>
						{t('onboarding.statePlaceHolder')}
					</Label>
					<Spacer size={12} />
					<CustomSelectSearch
						value={filterValue.state}
						placeholder={t('onboarding.statePlaceHolder')}
						options={stateList}
						onChange={value => handleChange({ state: value })}
					/>
				</Box>
			</Box>
			{rootModule !== 'seller' && (
				<>
					<Box margin="32px">
						<CheckBox
							label={t('advancedFilter.checkBoxLabel')}
							onChange={event =>
								handleChange({
									searchWithPartner: event.target.checked,
								})
							}
						/>
					</Box>

					<Spacer size={36} />
					<Box row={!isMobile} center margin="0 32px 0 4px">
						<span
							style={{
								width: isMobile ? 180 : 200,
								color: '#8D93A0',
								fontSize: theme.fontSize.m,
							}}
						>
							{t('advancedFilter.quantityRequirements')}
						</span>
						<div
							style={{
								borderBottom: `1px solid ${theme.color.grey4}`,
								width: '100%',
							}}
						/>
					</Box>
					<Spacer size={36} />
					<Box
						row={!isMobile}
						justifyContent="space-between"
						margin="0 32px"
					>
						<Box>
							<Label
								required
								style={{
									fontSize: '14px',
									color: theme.color.accent2,
								}}
							>
								{t('advancedFilter.addInventory')}
							</Label>

							<Box row={!isMobile} margin="8px 0 0">
								<Box width={200}>
									<Input
										value={filterValue.supplyAbilityQty}
										name="supplyAbilityQty"
										type="number"
										onChange={e => {
											handleChange({
												supplyAbilityQty:
													e.target.value,
											})
										}}
									/>
								</Box>
								<Box
									width={isMobile ? 200 : 84}
									margin={isMobile ? '0px' : '0 0 0 16px'}
								>
									<Select
										value={filterValue.supplyAbilityUOM}
										options={[
											{
												label: t('marketPlace.mt'),
												name: 'mt',
											},
											{
												label: t('marketPlace.kg'),
												name: 'kg',
											},
										]}
										key={filterValue.supplyAbilityUOM}
										labelKey="label"
										valueKey="name"
										onChange={value => {
											handleChange({
												supplyAbilityUOM: value.name,
											})
										}}
										returnOnlyValue
									/>
								</Box>
							</Box>
						</Box>
						<Box row={!isMobile}>
							<Box width={144}>
								<Label
									required
									style={{
										fontSize: '14px',
										color: theme.color.accent2,
										margin: '0 0 8px',
									}}
								>
									{t('advancedFilter.from')}
								</Label>
								<DatePicker
									required
									name="supplyAtStart"
									placeholder={t('advancedFilter.startDate')}
									minDate={new Date().setHours(0, 0, 0, 0)}
									maxDate={supplyAtEnd}
									value={supplyAtStart}
									onChange={value => {
										onDateChange('supplyAtStart', value)
									}}
									error={
										supplyAtEnd !== '' &&
										supplyAtStart === ''
											? t(
													'advancedFilter.startDateRequired'
											  )
											: ''
									}
								/>
							</Box>
							<Box
								width={144}
								margin={isMobile ? '0px' : '0 0 0 12px'}
							>
								<Label
									required
									style={{
										fontSize: '14px',
										color: theme.color.accent2,
										margin: '0 0 8px',
									}}
								>
									{t('advancedFilter.to')}
								</Label>
								<DatePicker
									required
									name="supplyAtEnd"
									placeholder={t('advancedFilter.endDate')}
									minDate={new Date().setHours(0, 0, 0, 0)}
									value={supplyAtEnd}
									onChange={value => {
										onDateChange('supplyAtEnd', value)
									}}
									error={
										supplyAtStart !== '' &&
										supplyAtEnd === ''
											? t(
													'advancedFilter.endDateRequired'
											  )
											: ''
									}
									toDate
								/>
							</Box>
						</Box>
					</Box>
				</>
			)}
		</>
	)
}

export default AdvanceFilter
