import React from 'react'
import LogHelper from 'ui-marketplace-app/utils/logger'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'

const logger = LogHelper('ui-marketplace:AppError')

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		logger.log(error, errorInfo)
	}

	render() {
		const { hasError } = this.state
		const { children } = this.props

		if (hasError) {
			// You can render any custom fallback UI
			return <ErrorBlock type={500} />
		}

		return children
	}
}

export default ErrorBoundary
