import React from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { supplyChainModels } from 'ui-marketplace-app/modules/Admin/components/StorageDetails'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	RFQ_STATUS,
	STATUS_TEXT_COLOR,
	STATUS_BACKGROUND,
	MONTHS,
	SHIPMENT_PERIOD,
	CONTRACT_TYPE,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { KeyValueSegment } from 'ui-marketplace-app/shared/components/KeyValueSegment'
import { SummaryBlock } from 'ui-marketplace-app/shared/components/DocumentLead'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'

import { Label } from 'ui-lib/components/Label'
import { Text, P } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'

const tableHeaderStyles = {
	background: '#C9F5E5',
	color: '#299F74',
	padding: 16,
}

const tableRowStyles = {
	border: '1px solid #CAD2DD',
	padding: 16,
}

export const Wrapper = styled.div(p => ({
	marginTop: p.margin || 0,
	width: '740px',
	height: 'auto',
	background: '#fff1dd 0% 0% no-repeat padding-box',
	border: '1px solid #f09112',
	borderLeft: '10px solid #f09112',
	borderRadius: '6px',
	opacity: 1,
	color: '#c97b12',
	letterSpacing: 0,
	fontSize: '16px',
}))

const RFQViewFromQuote = () => {
	const { t } = useTranslation()

	const rfqDetails = useSelector(MarketPlaceDuc.selectors.getRFQDetails)
	const actor = useSelector(MarketPlaceDuc.selectors.getMarketPlaceActor)

	const {
		status,
		seller,
		buyerAddress,
		sellerAddress,
		payment,
		destinationPort,
		ladingPort,
		supplyChainModel,
		productCode,
		product,
		month,
		sellerCategory,
		period,
		inspectionType = [],
		contractType,
		requirementDetails,
		termsAndConditions,
		quantity,
		uom,
		certificates = [],
		buyer,
		buyerCategory,
		buyerState,
		buyerCountry,
	} = rfqDetails
	const quantityInsp = inspectionType.filter(insp => insp.type === 'quantity')
	const qualityInsp = inspectionType.filter(insp => insp.type === 'quality')

	const quantityInspector = getIn(quantityInsp, [0, 'officer'])
	const qualityInspector = getIn(qualityInsp, [0, 'officer'])

	const inspection = inspectionType.map(insp => insp.type) || []

	return (
		<>
			<Box
				style={{
					height: '400px',
					overflow: 'scroll',
				}}
			>
				<Box row justifyContent="flex-end">
					<Box
						style={{
							backgroundColor: STATUS_BACKGROUND[status],
							padding: '8px 16px',
							margin: '0 0 8px',
							borderRadius: '5px',
							opacity: 1,
							textAlign: 'center',
						}}
					>
						<Label
							style={{
								color: STATUS_TEXT_COLOR[status],
							}}
						>
							{t(RFQ_STATUS[status]).toUpperCase()}
						</Label>
					</Box>
				</Box>
				<Box row margin="16px 0">
					<Box width="50%">
						<Label style={{ color: theme.color.grey15 }}>
							Issued
							{actor === 'mp_sales_marketing_officer'
								? t('quote.by')
								: t('quote.to')}
						</Label>
						<Spacer size={8} />
						<Box style={{ maxWidth: 250 }}>
							<Title
								title={
									actor === 'mp_sales_marketing_officer'
										? buyer
										: seller
								}
								small
								note={
									actor === 'mp_sales_marketing_officer'
										? buyerCategory
										: sellerCategory
								}
							/>
						</Box>
						<Spacer size={16} />
						<Text
							style={{
								lineHeight: '1.5',
								width: '100%',
								overflow: 'hidden',
								color: '#8D93A0',
								maxWidth: '200px',
							}}
						>
							{actor === 'mp_sales_marketing_officer'
								? buyerAddress
								: sellerAddress}
						</Text>
						<Spacer size={12} />
						<Text>{certificates.join(', ')}</Text>
						<Spacer size={12} />
						<Box row>
							<SummaryBlock
								name={t('quote.termsAndConditions')}
								detail={termsAndConditions}
								width="100%"
							/>
						</Box>
						<Spacer size={12} />
						<Box row>
							<SummaryBlock
								name={t('quote.paymentTerms')}
								detail={payment}
								width="100%"
							/>
						</Box>
					</Box>
					<Box>
						<Label style={{ color: theme.color.grey15 }}>
							{t('quote.destinationDetails')}
						</Label>
						<Spacer size={8} />
						<Box
							style={{
								border: '1px solid #D9DEE5',
								padding: '28px 0 28px 16px',
								borderRadius: 6,
							}}
						>
							<KeyValueSegment
								name={t('quote.address')}
								description={buyerAddress}
							/>
							<KeyValueSegment
								name={t('quote.stateCountry')}
								description={`${buyerState}, ${buyerCountry}`}
							/>
							<KeyValueSegment
								name={t('quote.port')}
								description={destinationPort}
							/>
						</Box>
						<Spacer size={12} />
						<Box row>
							<SummaryBlock
								name={t('quote.portOfLading')}
								detail={ladingPort}
								width="100%"
							/>
							<SummaryBlock
								name={t('quote.scModel')}
								detail={t(supplyChainModels[supplyChainModel])}
								width="100%"
							/>
						</Box>
					</Box>
				</Box>
				<Spacer size={20} />
				<Box
					height="14px"
					width="auto"
					style={{ background: '#F2F3F7' }}
				/>
				<Label
					style={{
						color: theme.color.grey15,
						margin: '20px 0 0 4px',
					}}
				>
					{t('quote.productDetails')}
				</Label>
				<table
					style={{
						width: '70%',
						borderSpacing: 4,
						borderCollapse: 'separate',
					}}
				>
					<tr>
						<th align="left" style={tableHeaderStyles}>
							{t('quote.product')}
						</th>
						<th align="left" style={tableHeaderStyles}>
							{`Quantity (${uom && uom.toUpperCase()})`}
						</th>
					</tr>
					<tr>
						<td style={tableRowStyles}>
							{`${product}  ${productCode || ' '}`}
						</td>
						<td style={tableRowStyles}>{quantity}</td>
					</tr>
				</table>
				<Spacer size={20} />
				<Box
					height="14px"
					width="auto"
					style={{ background: '#F2F3F7' }}
				/>
				<Label style={{ color: theme.color.grey15, margin: '20px 0' }}>
					{t('quote.additionalDetails')}
				</Label>
				<Box row justifyContent="space-between">
					<SummaryBlock
						name={t('quote.shipmentPeriod')}
						detail={`${t(MONTHS[month])}, ${t(
							SHIPMENT_PERIOD[period]
						)}`}
						width="100%"
					/>

					<SummaryBlock
						name={t('quote.thirdPartyInspection')}
						detail={inspection && inspection.join(', ')}
						width="100%"
					/>

					<SummaryBlock
						name={t('quote.preferredQuantityInspector')}
						detail={quantityInspector || ' '}
						width="100%"
					/>
				</Box>
				<Spacer size={20} />
				<Box row width="56%" justifyContent="space-between">
					<SummaryBlock
						name={t('quote.typeOfContract')}
						detail={t(CONTRACT_TYPE[contractType]) || ' '}
						width="100%"
					/>
					<Box>
						<SummaryBlock
							name={t('quote.preferredQualityInspector')}
							detail={qualityInspector || ' '}
							width="100%"
						/>
					</Box>
				</Box>
				<Spacer size={20} />
				<Box
					height="14px"
					width="auto"
					style={{ background: '#F2F3F7' }}
				/>
				<Label style={{ color: theme.color.grey15, margin: '20px 0' }}>
					{t('quote.requirementDetails')}
				</Label>
				<P>{requirementDetails}</P>
			</Box>
		</>
	)
}

export { RFQViewFromQuote }
