import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import saga from 'ui-marketplace-app/modules/MarketPlace/MarketPlaceSaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action

	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload, query = {} } = locationState

	const startID = getIn(query, ['startID']) || ''
	const preview = getIn(query, ['preview']) || ''

	extra.getSagaInjectors().injectSaga('MARKETPLACE', { saga })

	let activeModule = ''

	dispatch(AuthDuc.creators.fetchActor())

	// Inject the saga here. If it wasn't already
	// extra.getSagaInjectors().injectSaga('listing', { saga })
	const isMainDashboard = MainRouteDuc.types.MARKETPLACE === type
	const isDocumentView = MainRouteDuc.types.MARKETPLACE$ACTION === type
	const isDocumentStatusView =
		MainRouteDuc.types.MARKETPLACE$ACTIONSTATUS === type
	const isContract = MainRouteDuc.types.MARKETPLACE$CONTRACTS === type
	const isContractWDocType =
		MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE === type

	dispatch(MarketPlaceDuc.creators.setAPIBasedOnActor(payload))

	if (isMainDashboard) {
		// load the dashboard of the ui-marketplace module
		activeModule = 'dashboard'
		dispatch(MarketPlaceDuc.creators.fetchAPIBasedOnActor(payload))
	}
	if (isDocumentView) {
		activeModule = payload.action

		if (payload.action === 'create-rfq') {
			dispatch(
				MarketPlaceDuc.creators.fetchOrgProductDetails(
					payload.documentReference
				)
			)
		}
		if (payload.action === 'post-requirement') {
			dispatch(MarketPlaceDuc.creators.fetchGlobalProductsNoPagination())
		}
		if (payload.action === 'edit-rfq') {
			dispatch(
				MarketPlaceDuc.creators.viewRFQ(payload.documentReference, true)
			)
		}
		if (
			payload.action === 'view-rfq' ||
			payload.action === 'view-post-requirement'
		) {
			dispatch(MarketPlaceDuc.creators.viewRFQ(payload.documentReference))
			dispatch(
				MarketPlaceDuc.creators.viewRFQAttachments(
					payload.documentReference
				)
			)
		}
		if (payload.action === 'view-global-rfq') {
			dispatch(
				MarketPlaceDuc.creators.viewGlobalRFQ(payload.documentReference)
			)
		}
		if (payload.rootModule === 'product' && payload.action === 'view') {
			dispatch(MarketPlaceDuc.creators.fetchDocument())
		}
		if (payload.action === 'create-quote') {
			dispatch(MarketPlaceDuc.creators.viewRFQ(payload.documentReference))
		}
		if (
			payload.action === 'edit-quote' ||
			payload.action === 'view-quote'
		) {
			dispatch(
				MarketPlaceDuc.creators.viewQuote(
					payload.documentReference,
					payload,
					false
				)
			)
			dispatch(
				MarketPlaceDuc.creators.viewQuoteAttachments(
					payload.documentReference
				)
			)
		}
		if (
			payload.action === 'view-product' ||
			payload.action === 'edit-product'
		) {
			dispatch(
				MarketPlaceDuc.creators.viewProduct(payload.documentReference)
			)
		}
		if (payload.action === 'view-supplyability') {
			dispatch(MarketPlaceDuc.creators.setSupplyAbility({}))
			dispatch(
				MarketPlaceDuc.creators.fetchSupplyAbility(
					payload.documentReference,
					query.organizationID,
					startID
				)
			)
		}
	}
	if (isDocumentStatusView) {
		activeModule = payload.documentStatus
	}
	if (isContract || isContractWDocType) {
		activeModule = payload.action

		if (payload.action === 'generate' && preview !== true) {
			dispatch(
				MarketPlaceDuc.creators.viewQuote(
					payload.documentReference,
					payload,
					false
				)
			)
		}
		if (payload.action === 'edit' && preview !== true) {
			dispatch(
				MarketPlaceDuc.creators.initiateEditContractDetails(
					payload.documentReference
				)
			)
		}
		if (activeModule === 'e-sign') {
			dispatch(
				MarketPlaceDuc.creators.fetchESignDetails(
					payload.documentReference
				)
			)
		}
		if (activeModule === 'view') {
			dispatch(
				MarketPlaceDuc.creators.fetchESignDetails(
					payload.documentReference
				)
			)
			dispatch(
				MarketPlaceDuc.creators.fetchSavedContractDetails(
					payload.documentReference
				)
			)
		}
		if (activeModule === 'processing') {
			dispatch(
				MarketPlaceDuc.creators.fetchProcessingContractDetails(
					payload.rootModule,
					payload.documentReference,
					payload.documentType
				)
			)
		}
	}

	dispatch(MarketPlaceDuc.creators.setActiveModule(activeModule))
}
