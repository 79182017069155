import { getIn } from 'timm'
import {
	getseparatedTDMHost,
	getOriginHost,
	getSeparatedMarketPlaceHost,
	getSeparatedLoginHost,
	getCurrentProject,
} from 'ui-lib/config'
import { getPathInitial } from 'ui-lib/utils/helpers'

const currentProject = getCurrentProject()

// TDM regex
const tdmRootModulesRegex = 'incoming|outgoing|all'
const marketPlaceRootModulesRegex = 'buyer|seller'
const tdmSubModuleRegex =
	'purchase-order|invoice|delivery-order|incoming-delivery-order|upload-delivery-order|upload-weighbridge|weighbridge-slip|excel-uploads'
const tdmSubActionRegex =
	'partner|details|confirm|attachDetails|attach-details-confirm'
const documentStatus = 'draft|clone|edit'

// Auth regex
const authActionRegex =
	'forgot-password|sign-in|verify-email|otp-login|reset-password|verify-social|two-step-verification|signup|signup-detail|verify-email|set-password'

// Plantation regex
const PlantationModulesRegex = 'ticket|records|reports|bin|document'
const documentType = 'bin|ticket|harvester|trips'

// Mill and Refinery regex
const PalmAndRefineryModulesRegex =
	'quality|production|quality-cpo|quality-ffb|production-plot-input|production-plot-output|produced-batch|sourced-batch|input-storage|inventory|production-v2|production-v1'
const millSubModuleRegex = 'sourced-batch|produced-batch'

// Admin regex
const AdminModuleRegex =
	'assets|employee-management|company-information|collection-point|other-settings|webhook-api-settings'
const AdminSubModuleRegex =
	'vehicles|storageunits|production-line|add|weighbridges'
const AdminActionRegex =
	'add-employee|view-employee|edit-employee|create-asset|view-asset|edit-asset|preview-employee|add-cp|upload-assets'

// Settings regex
const settingsModuleRegex =
	'change-password|change-language|notifications|edit-profile'

// Onboarding regex
const signupRegex =
	'signup|signup-detail|verify-email|set-password|poc2022|qrredirect'
const onboardRegex =
	'select-language|congrats|invite|welcome|thankyou|organization|business|addcerts|successinvite|skip|surveySuccess|survey|upload-certificate|update-address|update-certDetails|update-prodDetails|all-set|add-cp-menu|add-collection-point|success-with-cp|success-with-no-cp|carbon-number'

const policyRegex = 'privacy-policy|terms-conditions'

// Storage regex
const storageModuleRegex = 'map-output|assign-tank'
const storageSubModuleRegex = 'map-incoming|map-outgoing'

// Dealer regex
const dealerRegex = 'work'

// common regex
const documentAction =
	'create|view|edit|add-partner|invite-partner|upload-partners'
// reports regex
const reportsModuleRegex = 'audit-report|general-report'

// Tour regex
const tourRegex = 'tour'

// Products regex
const productsRegex =
	'add-products|view-product|edit-product|create-sub-product'

// Marketplace regex
const marketplaceRegex =
	'view-rfq|view-global-rfq|create-quote|edit-quote|view-product|create-rfq|edit-rfq|edit-product|view-quote|view-supplyability|post-requirement|view-post-requirement|create|view|e-sign|generate|preview-participants|participants-success|preview|e-sign-success|processing|edit'

const docTypeRegex =
	'contract-no-1|contract-no-2|contract-no-3|contract-no-4|contract-no-5|contract-no-6|contract-no-7|contract-no-8|contract-no-9|fosfa81|custom'
// Marketplace status
const marketplaceStatus = 'success'

export const routePath = {
	AUTH: `/auth/:action(${authActionRegex})`,
	POLICY: `/:action(${policyRegex})`,
	ONBOARD: `/:action(${signupRegex})`,
	ONBOARD$ACTION: `/onboarding/:action(${onboardRegex})`,
	DASHBOARD: '/',
	PRODUCT_TRACE: '/product-trace',
	MESSAGES: '/messages',
	PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_V1:
		'/generate-trace/:traceID/:orgID/v1',
	PRODUCT_TRACE$GENERATE_PRODUCT_TRACE: '/generate-trace/:traceID/:orgID',
	PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP:
		'/generate-trace/group/:traceGroupID/:orgID',
	PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP_V2:
		'/generate-trace/group/:traceGroupID/:orgID/v2',
	PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_MAP:
		'/generate-trace/map/:geoID/:orgID',
	TRADE_DOCUMENT_MANAGER: `/trade-document-manager/:rootModule(${tdmRootModulesRegex})`,
	TRADE_DOCUMENT_MANAGER$WACTION: `/trade-document-manager/:rootModule(${tdmSubModuleRegex})/:action(${tdmSubActionRegex})`,
	TRADE_DOCUMENT_MANAGER$SUBROOT: `/trade-document-manager/:rootModule(${tdmRootModulesRegex})`,
	TRADE_DOCUMENT_MANAGER$LISTING: `/trade-document-manager/:rootModule(${tdmRootModulesRegex})/:submodule(${tdmSubModuleRegex})`,
	TRADE_DOCUMENT_MANAGER$AUDIT_REPORT: `/trade-document-manager/:rootModule(${reportsModuleRegex})`,
	TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE: `/trade-document-manager/:rootModule(${tdmSubModuleRegex})/:documentReference`,
	TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE: `/trade-document-manager/:rootModule(${tdmSubModuleRegex})/:action(${tdmSubActionRegex})/:documentReference/:documentStatus(${documentStatus})`,
	TRADE_DOCUMENT_MANAGER$COMPLETETRADEREPORT: `/trade-document-manager/:rootModule(${tdmSubModuleRegex})/:documentReference/:traceReportStatus`,

	WEIGHBRIDGE: '/weighbridge-slip',
	WEIGHBRIDGE$WACTION: `/weighbridge-slip/:action(${documentAction})`,
	WEIGHBRIDGE$DOCUMENTREFERENCE: `/weighbridge-slip/:action(${documentAction})/:attachmentID`,
	PLANTATION: '/plantation',
	PLANTATION$SUBROOT: `/plantation/:rootModule(${PlantationModulesRegex})`,
	PLANTATION$WACTION: `/plantation/:rootModule(${PlantationModulesRegex})/:action(${documentAction})`,
	PLANTATION$DOCUMENTREFERENCE: `/plantation/:rootModule(${PlantationModulesRegex})/:action(${documentAction})/:documentReference`,
	PLANTATION$DOCUMENTREFERENCETYPE: `/plantation/:rootModule(${PlantationModulesRegex})/:action(${documentAction})/:documentReference/:documentType(${documentType})`,
	MILL: '/mill',
	MILL$SUBROOT: `/mill/:rootModule(${PalmAndRefineryModulesRegex})`,
	MILL$WACTION: `/mill/:rootModule(${PalmAndRefineryModulesRegex})/:action(${documentAction})`,
	MILL$WDOCREFERENCE: `/mill/:rootModule(${PalmAndRefineryModulesRegex})/:action(${documentAction})/:documentReference`,
	MILL$LISTING: `/mill/:rootModule(${PalmAndRefineryModulesRegex})/:submodule(${millSubModuleRegex})`,

	REFINERY: '/refinery',
	REFINERY$SUBROOT: `/refinery/:rootModule(${PalmAndRefineryModulesRegex})`,
	REFINERY$WACTION: `/refinery/:rootModule(${PalmAndRefineryModulesRegex})/:action(${documentAction})`,
	REFINERY$WDOCREFERENCE: `/refinery/:rootModule(${PalmAndRefineryModulesRegex})/:action(${documentAction})/:documentReference`,
	REFINERY$LISTING: `/refinery/:rootModule(${PalmAndRefineryModulesRegex})/:submodule(${millSubModuleRegex})`,
	ADMIN: '/admin',
	ADMIN$SUBROOT: `/admin/:rootModule(${AdminModuleRegex})`,
	ADMIN$WACTION: `/admin/:rootModule(${AdminModuleRegex})/:action(${AdminActionRegex})`,
	ADMIN$SUBMODULEWACTION: `/admin/:rootModule(${AdminModuleRegex})/:subModule(${AdminSubModuleRegex})/:action(${AdminActionRegex})`,
	ADMIN$WDOCREFERENCE: `/admin/:rootModule(${AdminModuleRegex})/:action(${AdminActionRegex})/:documentReference`,
	ADMIN$SUBMODULEWDOCREFERENCE: `/admin/:rootModule(${AdminModuleRegex})/:subModule(${AdminSubModuleRegex})/:action(${AdminActionRegex})/:documentReference`,
	PARTNER: '/partner',
	MARKETPLACE: `/marketplace/:rootModule(${marketPlaceRootModulesRegex})`,
	MARKETPLACE$ACTION: `/marketplace/:rootModule(${marketPlaceRootModulesRegex})/:action(${marketplaceRegex})/:documentReference`,
	MARKETPLACE$CONTRACTS: `/marketplace/:rootModule(${marketPlaceRootModulesRegex})/contract/:action(${marketplaceRegex})/:documentReference`,
	MARKETPLACE$CONTRACTSWDOCTYPE: `/marketplace/:rootModule(${marketPlaceRootModulesRegex})/contract/:action(${marketplaceRegex})/:documentReference/:documentType(${docTypeRegex})`,
	MARKETPLACE$ACTIONSTATUS: `/marketplace/:rootModule(${marketPlaceRootModulesRegex})/:action(${marketplaceRegex})/:documentReference/:documentStatus(${marketplaceStatus})`,
	PARTNER$ACTION: `/partner/:action(${documentAction})`,
	PARTNER$WREQREFERENCE: `/partner/:action(${documentAction})/:partnerRef`,
	SETTINGS: '/settings',
	SETTINGS$SUBROOT: `/settings/:rootModule(${settingsModuleRegex})`,
	FEEDBACK: '/feedback',
	STORAGE_COMPANY: '/storage-company',
	STORAGE_COMPANY$LISTING: '/storage-assignment',
	STORAGE_COMPANY$SUBMODULELISTING: `/storage-assigment/:submodule(${storageSubModuleRegex})`,
	STORAGE_COMPANY$SUBROOT: `/storage-assigment/:rootModule(${storageModuleRegex})/:action(${documentAction})`,
	TRADER: '/trader',
	END_MANUFACTURER: '/end-manufacturer',
	DEALER: '/dealer',
	DEALER$SUBROOT: `/dealer/:rootModule(${dealerRegex})`,
	DEALER$WACTION: `/dealer/:rootModule(${dealerRegex})/:action(${documentAction})`,
	DEALER$WDOCREFERENCE: `/dealer/:rootModule(${dealerRegex})/:action(${documentAction})/:documentReference`,
	TRIPS: '/trips',
	TRIPS$WDOCREFERENCE: '/trips/:documentReference',
	WBTICKET: '/wb-tickets',
	WBTICKET$WACTION: '/wb-tickets/upload',
	WBTICKET$WDOCREFERENCE: '/wb-tickets/:documentReference',
	GENERAL_SCHEDULER: '/general-scheduler',
	GENERAL_SCHEDULER$WDOCREFERENCE: `/general-scheduler/:action(${documentAction})/:documentReference`,
	WEBTOUR: '/webTour',
	WEBTOUR$SUBROOT: `/WebTour/:rootModule(${tourRegex})`,
	PRODUCTS: '/products',
	PRODUCTS$WACTION: `/products/:rootModule(${productsRegex})`,
	PRODUCTS$WDOCREFERENCE: `/products/:rootModule(${productsRegex})/:documentReference`,
}

export const isDomainChange = (
	actionUrl,
	rootModule = '',
	isLoggedIn = false
) => {
	let domainChange = false
	if (
		(currentProject === 'auth' &&
			!(actionUrl.includes('AUTH') || actionUrl.includes('POLICY'))) ||
		(actionUrl.includes('DASHBOARD') && isLoggedIn)
	) {
		domainChange = true
	} else if (
		currentProject === 'tdm' &&
		!(
			actionUrl.includes('TRADE_DOCUMENT_MANAGER') ||
			actionUrl.includes('WEIGHBRIDGE') ||
			(actionUrl.includes('MILL$WACTION') &&
				(rootModule === 'quality-ffb' || rootModule === 'quality-cpo'))
		)
	) {
		domainChange = true
	} else if (
		currentProject === 'marketplace' &&
		!actionUrl.includes('MARKETPLACE')
	) {
		domainChange = true
	} else if (
		currentProject === 'core' &&
		(actionUrl.includes('TRADE_DOCUMENT_MANAGER') ||
			actionUrl.includes('WEIGHBRIDGE') ||
			actionUrl.includes('MARKETPLACE') ||
			actionUrl.includes('AUTH') ||
			actionUrl.includes('POLICY'))
	) {
		domainChange = true
	}

	return domainChange
}

export const getOnBeforeChangeUrl = (action = {}) => {
	let url = ''
	const actionType = getIn(action, ['type'])
	if (currentProject === 'tdm' || currentProject === 'marketplace') {
		url = `${getOriginHost()}${
			getIn(action, ['payload', 'action']) === 'sign-in' &&
			getIn(action, ['meta', 'query', 'redirect_to'])
				? `/auth/sign-in?redirect_to=${getIn(action, [
						'meta',
						'query',
						'redirect_to',
				  ])}`
				: ''
		}`
	} else if (currentProject === 'core') {
		if (actionType.includes('MARKETPLACE')) {
			url = `${getSeparatedMarketPlaceHost()}${getIn(action, [
				'meta',
				'location',
				'current',
				'pathname',
			])}`
		} else if (
			actionType.includes('AUTH') ||
			actionType.includes('POLICY')
		) {
			url = `${getSeparatedLoginHost()}${getIn(action, [
				'meta',
				'location',
				'current',
				'pathname',
			])}`
		} else {
			url = `${getseparatedTDMHost()}${getIn(action, [
				'meta',
				'location',
				'current',
				'pathname',
			])}`
		}
	}

	window.location.assign(url)
}

export const switchRoute = (
	actionType,
	payload = {},
	query = {},
	customMeta = {}
) => {
	const getBaseUrl = () => {
		let baseUrl = ''
		if (
			currentProject === 'tdm' &&
			!(
				actionType.includes('TRADE_DOCUMENT_MANAGER') ||
				actionType.includes('WEIGHBRIDGE') ||
				(actionType.includes('MILL$WACTION') &&
					(payload?.rootModule === 'quality-ffb' ||
						payload?.rootModule === 'quality-cpo'))
			)
		) {
			const currentType = actionType.split('/')[2]
			const path = getPathInitial(currentType)
			if (actionType.includes('MARKETPLACE')) {
				baseUrl = `${getSeparatedMarketPlaceHost()}${path}`
			} else {
				baseUrl = `${getOriginHost()}${path}`
			}
		} else if (
			currentProject === 'marketplace' &&
			!actionType.includes('MARKETPLACE')
		) {
			const currentType = actionType.split('/')[2]
			const path = getPathInitial(currentType)

			if (
				actionType.includes('TRADE_DOCUMENT_MANAGER') ||
				actionType.includes('WEIGHBRIDGE') ||
				(actionType.includes('MILL$WACTION') &&
					(payload?.rootModule === 'quality-ffb' ||
						payload?.rootModule === 'quality-cpo'))
			) {
				baseUrl = `${getseparatedTDMHost()}${path}`
			} else {
				baseUrl = `${getOriginHost()}${path}`
			}
		} else if (
			currentProject === 'core' &&
			(actionType.includes('TRADE_DOCUMENT_MANAGER') ||
				actionType.includes('MARKETPLACE'))
		) {
			const currentType = actionType.split('/')[2]
			const path = getPathInitial(currentType)
			if (actionType.includes('MARKETPLACE')) {
				baseUrl = `${getSeparatedMarketPlaceHost()}${path}`
			} else {
				baseUrl = `${getseparatedTDMHost()}${path}`
			}
		} else if (
			currentProject === 'auth' &&
			!(actionType.includes('POLICY') || actionType.includes('AUTH'))
		) {
			const currentType = actionType.split('/')[2]
			const path = getPathInitial(currentType)
			baseUrl = `${getOriginHost()}${path}`
		}

		return baseUrl
	}

	let url = getBaseUrl()

	if (
		currentProject === 'tdm' &&
		(actionType === '@route/global/MARKETPLACE$CONTRACTS' ||
			actionType === '@route/global/MARKETPLACE$CONTRACTSWDOCTYPE')
	) {
		url += '/contract'
	}

	if (payload?.documentType) {
		url += `/${payload.documentType}`
	}
	if (payload?.traceGroupID) {
		url += `/${payload.traceGroupID}`
	}
	if (payload?.orgID) {
		url += `/${payload.orgID}`
	}
	if (payload.rootModule) {
		url += `/${payload.rootModule}`
	}
	if (payload.action) {
		url += `/${payload.action}`
	}
	if (payload.documentReference) {
		url += `/${payload.documentReference}`
	}
	if (payload.documentStatus) {
		url += `/${payload.documentStatus}`
	}
	if (
		actionType ===
		'@route/global/PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP_V2'
	) {
		url += '/v2'
	}

	if (Object.keys(query)?.length > 0) {
		url += `?${new URLSearchParams(query).toString()}`
	}

	window.location.assign(url)
}
