/* eslint-disable import/extensions */
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { CheckBox } from 'grommet'
import { getIn, omit, setIn, merge } from 'timm'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import AdvanceFilter from 'ui-marketplace-app/modules/MarketPlace/containers/AdvanceFilter'
import { DocumentView } from 'ui-marketplace-app/modules/MarketPlace/containers/DocumentView'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { getTargetFilterQueries } from 'ui-marketplace-app/shared/helpers'
import { useDebouncedCallback } from 'ui-marketplace-app/utils/helpers'
import IsccLogo from 'ui-marketplace/src/assets/iscc.png'
import MspoLogo from 'ui-marketplace/src/assets/mspo.png'
import RspoLogo from 'ui-marketplace/src/assets/rspo.png'
import IspoLogo from 'ui-marketplace/src/assets/ispo.png'
import MpobLogo from 'ui-marketplace/src/assets/mpob.png'
import { SuggestionsInput } from 'ui-lib/components/Input'
import {
	Button,
	ButtonWithNoBorder,
	ButtonIconWrapper,
} from 'ui-lib/components/Button'
import { ShowMore } from 'ui-marketplace-app/modules/MarketPlace/components/ShowMore'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Modal } from 'ui-lib/components/Modal'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import VerifiedIcon from 'ui-lib/icons/verified.svg'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import FilterIcon from 'ui-lib/icons/filter.svg'
import { BoxShadowTable } from 'ui-marketplace-app/shared/components/BoxShadowTable'
import { columnConfig } from './Enquiries/RFQListing'
import { CTAWrapper } from '../components/SubComponents'

const CertificateLogo = {
	ISCC: IsccLogo,
	MSPO: MspoLogo,
	RSPO: RspoLogo,
	ISPO: IspoLogo,
	MPOB: MpobLogo,
}

const MarketPlaceHeading = styled.h1({
	fontSize: '40px',
	color: '#242845',
	fontWeight: '500',
})

const Illustration = styled.img`
	width: 40px;
`

const MarketPlaceNote = styled.h4({
	fontSize: '14px',
	color: '#8D93A0',
	fontWeight: '400',
	lineHeight: '1.8',
	maxWidth: '600px',
})

const Label = styled.h4({
	fontSize: '14px',
	color: '#8D93A0',
})

const Pills = styled.h4({
	borderRadius: '20px',
	backgroundColor: '#FFECDB',
	padding: '10px',
	color: '#AC672A',
	fontSize: '12px',
})

const CertificateBlock = ({ name }) => {
	return (
		<>
			<Box center>
				<Illustration src={CertificateLogo[name]} />
				<Spacer size={4} />

				{name}
			</Box>
			<Spacer size={14} horizontal />
		</>
	)
}

const MarketPlace = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload = {} } = location
	const { rootModule } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []

	const documentViewStatus = useSelector(
		MarketPlaceDuc.selectors.getDocumentViewStatus
	)

	const loading = useSelector(
		MarketPlaceDuc.selectors.getMPOrgListLoadingStatus
	)

	const organizations = useSelector(
		MarketPlaceDuc.selectors.getMarketPlaceOrgList
	)

	const sellerGloablRFQ = useSelector(
		MarketPlaceDuc.selectors.getMarketPlaceGlobalRFQList
	)
	const sellerGloablRFQPagination = useSelector(
		MarketPlaceDuc.selectors.getMarketPlaceGlobalRFQPagination
	)

	const pagination = useSelector(
		MarketPlaceDuc.selectors.getMarketPlacePagination
	)

	const activeFilters = useSelector(
		MarketPlaceDuc.selectors.getActiveFiltersForMarketPlaceSearch
	)

	const virtualAccess = useSelector(AuthDuc.selectors.getVirtualAccess)
	const { results = [], message } = useSelector(
		AuthDuc.selectors.getProductSearchResults
	)

	const [globalRfqQuery, setglobalRfqQuery] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [isDocumentView, setDocumentView] = useState(documentViewStatus)
	const [activeDocument, setActiveDocument] = useState({})
	const [bulkRFQdocument, setBulkRFQdocument] = useState([])
	const [performedSearch, setPerformedSearch] = useState(false)
	const [activeProduct, setActiveProduct] = useState('')
	const [filterValues, setFilterValue] = useState({
		organisationType: [],
		certificateType: [],
		country: [],
		state: [],
		supplyAbilityQty: '',
		supplyAbilityUOM: 'mt',
		searchWithPartner: '',
	})

	const [supplyAtStart, setSupplyAtStart] = useState('')
	const [supplyAtEnd, setSupplyAtEnd] = useState('')

	const checkForQty = getIn(filterValues, ['supplyAbilityQty']) > 0

	const [applyFilter, setApplyFilter] = useState(false)

	const transformedData = Object.values(results).filter(
		activeDoc => activeDoc
	)

	const productList = (transformedData || []).map(({ name, id }) => {
		return { label: name, value: id }
	})

	const handleSelect = selected => {
		const { value } = selected
		setActiveProduct(value)
	}

	const handleSearch = useDebouncedCallback(value => {
		dispatch(AuthDuc.creators.searchProducts(value))
	}, 800)

	const totalDocument = getIn(pagination, ['total']) || ''

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'apply_filter_seller': {
					const {
						filterType, // the root filter key e.g status
						filterValue,
						appliedFilter,
					} = meta
					const _currentQuery = getIn(location, ['query']) || {}
					const currentQuery = {
						..._currentQuery,
						activeIndex: 0,
						[filterType]: filterValue,
					}
					const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention
					const targetQueries =
						getIn(filterQueries, [filterType]) || []

					const orgCategoryName = (
						getIn(appliedFilter, ['organisationType']) || []
					).map(org => org.id)

					const orgCertificate = (
						getIn(appliedFilter, ['certificateType']) || []
					).map(org => org.name)

					const country = (
						getIn(appliedFilter, ['country']) || []
					).map(org => org.name)

					const state = (getIn(appliedFilter, ['state']) || []).map(
						org => org.name
					)

					const targetFilters = merge(
						_currentQuery,
						currentQuery,
						merge(
							filterQueries,
							{ [filterType]: targetQueries },
							orgCategoryName.length && {
								orgCategory: orgCategoryName.join(','),
							},
							orgCertificate.length && {
								orgCertificate: orgCertificate.join(','),
							},
							country.length && {
								country: country.join(','),
							},
							state.length && {
								state: state.join(','),
							}
						)
					)
					setglobalRfqQuery(targetFilters)
					dispatch(
						MarketPlaceDuc.creators.getMarketPlaceGlobalRFQList(
							setIn(location, ['query'], targetFilters),
							rootModule,
							virtualAccess
						)
					)

					break
				}
				case 'apply_filter': {
					const {
						filterType, // the root filter key e.g status
						filterValue,
						filterSegment,
						appliedFilter,
					} = meta
					const _currentQuery = getIn(location, ['query']) || {}
					const currentQuery = {
						..._currentQuery,
						activeIndex: 0,
					}
					const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention
					let targetQueries = getIn(filterQueries, [filterType]) || []
					targetQueries = getTargetFilterQueries(
						targetQueries,
						filterSegment,
						filterValue
					)

					const orgCategoryName = (
						getIn(appliedFilter, ['organisationType']) || []
					).map(org => org.name)

					const orgCertificate = (
						getIn(appliedFilter, ['certificateType']) || []
					).map(org => org.name)

					const country = (
						getIn(appliedFilter, ['country']) || []
					).map(org => org.name)

					const state = (getIn(appliedFilter, ['state']) || []).map(
						org => org.name
					)

					const supplyAbilityQty = getIn(appliedFilter, [
						'supplyAbilityQty',
					])

					const supplyAbilityUOM = getIn(appliedFilter, [
						'supplyAbilityUOM',
					])

					const searchWithPartner = getIn(appliedFilter, [
						'searchWithPartner',
					])

					const targetFilters = merge(
						_currentQuery,
						currentQuery,
						merge(
							filterQueries,
							{ [filterType]: targetQueries },

							orgCategoryName.length && {
								orgCategoryName: orgCategoryName.join('|'),
							},
							orgCertificate.length && {
								orgCertificate: orgCertificate.join(','),
							},
							{ virtualOrg: virtualAccess },
							country.length && {
								country: country.join(','),
							},
							state.length && {
								state: state.join(','),
							},
							searchWithPartner && {
								searchWithPartner: `eq|${searchWithPartner}`,
							},
							supplyAbilityQty && {
								supplyAbilityQty: `gte|${supplyAbilityQty}`,
							},
							checkForQty && {
								supplyAbilityUOM,
							},
							supplyAtStart && {
								supplyAtStart: `gte|${supplyAtStart}`,
							},
							supplyAtEnd && {
								supplyAtEnd: `lte|${supplyAtEnd}`,
							}
						)
					)

					dispatch(
						MarketPlaceDuc.creators.getMarketPlaceOrganisationList(
							setIn(location, ['query'], targetFilters),
							rootModule
						)
					)

					break
				}

				case 'paginate': {
					const { filterParams } = meta
					const { nextIndex } = filterParams

					if (rootModule === 'seller') {
						const locationState = {
							query: {
								...globalRfqQuery,
								nextIndex,
								limit: 20,
							},
						}
						dispatch(
							MarketPlaceDuc.creators.getMarketPlaceGlobalRFQList(
								locationState,
								rootModule,
								virtualAccess
							)
						)
					} else {
						const locationState = {
							query: {
								...location.query,
								nextIndex,
								limit: 100,
								productID: activeFilters.productID,
							},
						}
						dispatch(
							MarketPlaceDuc.creators.getMarketPlaceOrganisationList(
								locationState,
								rootModule
							)
						)
					}
					break
				}
				case 'view_document': {
					if (meta.row.id) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$ACTION,
								{
									rootModule,
									action: 'view-global-rfq',
									documentReference: meta.row.id,
								}
							)
						)
					}
					break
				}

				default:
					break
			}
		},
		[
			activeFilters.productID,
			checkForQty,
			dispatch,
			globalRfqQuery,
			location,
			rootModule,
			supplyAtEnd,
			supplyAtStart,
			virtualAccess,
		]
	)

	const disableSearch =
		(supplyAtStart === '' && supplyAtEnd !== '') ||
		(supplyAtEnd === '' && supplyAtStart !== '')

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: 'Marketplace',
			name: 'marketplace',
			isActive: true,
		},
		{
			label: 'Search Results',
			name: 'search-results',
			isActive: true,
		},
		{
			label: 'Seller Information',
			name: 'create-rfq',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'marketplace') {
			dispatch(MarketPlaceDuc.creators.flushMarketPlaceSearchResults())
			setPerformedSearch(false)
		}
		if (target === 'search-results') {
			setDocumentView(false)
		}
	}

	const handleClick = () => {
		dispatch(MarketPlaceDuc.creators.flushMarketPlaceSearchResults())
		setPerformedSearch(false)
	}

	const handleDateChange = (key, value) => {
		if (key === 'supplyAtStart') {
			setSupplyAtStart(value)
		} else setSupplyAtEnd(value)
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('marketPlace.modifySearch')}
				body={<AdvanceFilter />}
				confirmlabel={t('marketPlace.modifySearch')}
				onConfirm={() => setShowModal(false)}
			/>
			{actor !== 'palmoil_tdm' && rootModule !== 'seller' && (
				<div style={{ display: isMobile ? 'block' : 'flex' }}>
					<Box
						row
						justifyContent="space-between"
						alignItems="baseline"
						margin="24px 0"
						style={{
							width: isMobile ? '99%' : '49%',
							display: 'block',
							background: '#fff',
							marginRight: '20px',
							padding: '20px 50px',
						}}
					>
						<div
							style={{
								display: 'flex',
								marginBottom: '10px',
								justifyContent: 'center',
								textAlign: isMobile ? 'center' : 'left',
							}}
						>
							{t('marketPlace.postRequirementText')}
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Button
								primary
								label={t('marketPlace.postRequirement')}
								onClick={() => {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types
												.MARKETPLACE$ACTION,
											{
												rootModule: 'buyer',
												action: 'post-requirement',
												documentReference: 'create',
											}
										)
									)
								}}
							/>
						</div>
					</Box>
					<Box
						row
						justifyContent="space-between"
						alignItems="baseline"
						margin="24px 0"
						style={{
							width: isMobile ? '99%' : '49%',
							display: 'block',
							background: '#fff',
							marginRight: '20px',
							padding: '20px 50px',
						}}
					>
						<div
							style={{
								display: 'flex',
								marginBottom: '10px',
								justifyContent: 'center',
								textAlign: isMobile ? 'center' : 'left',
							}}
						>
							{t('marketPlace.postInventoryText')}
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Button
								primary
								label={t('marketPlace.postInventoryButtonText')}
								onClick={() => {
									dispatch(
										MarketPlaceDuc.creators.setActiveTabs(
											'seller',
											'products'
										)
									)
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.MARKETPLACE,
											{
												rootModule: 'seller',
											}
										)
									)
								}}
							/>
						</div>
					</Box>
				</div>
			)}

			{rootModule === 'seller' && (
				<>
					{sellerGloablRFQ.length === 0 && !performedSearch ? (
						<Box
							padding={8}
							width="100%"
							height="100%"
							style={{
								padding: '16px 70px',
								background: '#fff',
							}}
						>
							<Box>
								<MarketPlaceHeading>
									{t('marketPlace.marketPlaceHeadingSeller')}
								</MarketPlaceHeading>
								<MarketPlaceNote>
									{t(
										'marketPlace.marketPlaceHeadingNoteSeller'
									)}
								</MarketPlaceNote>
							</Box>
							<Spacer size={60} />
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={isMobile ? '100%' : '40%'}>
									<Label
										required
										style={{
											fontSize: '14px',
											color: theme.color.accent2,
										}}
									>
										{t('marketPlace.searchProduct')}
									</Label>
									<Spacer size={12} />
									<SuggestionsInput
										value=""
										placeholder={t(
											'marketPlace.searchProduct'
										)}
										onChange={e =>
											handleSearch(e.target.value)
										}
										suggestions={productList}
										onSelect={selected =>
											handleSelect(selected)
										}
										extendStyles={{
											borderRadius: '4px',
										}}
										error={message}
									/>
								</Box>
								<Box
									style={{
										display: applyFilter && 'none',
									}}
								>
									<Label
										required
										style={{
											fontSize: '14px',
											color: theme.color.accent2,
										}}
									>
										{t('marketPlace.moreFilters')}
									</Label>
									<Spacer size={12} />
									<Button
										label={t('marketPlace.advancedFilters')}
										outline
										customIcon={
											<ButtonIconWrapper lightBG>
												<Icon glyph={FilterIcon} />
											</ButtonIconWrapper>
										}
										onClick={() => setApplyFilter(true)}
									/>
								</Box>
							</Box>

							{applyFilter && (
								<>
									<Spacer size={20} />
									<CollapsableSegment
										expand
										title={t(
											'marketPlace.applyAdditionalFilters'
										)}
										open
										noBorderColor
									>
										<Spacer size={12} />

										<AdvanceFilter
											filterValue={filterValues}
											onChange={value => {
												setFilterValue(value)
											}}
											supplyAtStart={supplyAtStart}
											supplyAtEnd={supplyAtEnd}
											onDateChange={(key, value) => {
												handleDateChange(key, value)
											}}
											isMobile={isMobile}
										/>
									</CollapsableSegment>
								</>
							)}

							<Spacer size={20} />
							<Box alignItems={isMobile ? 'center' : 'flex-end'}>
								<Box width={200}>
									<Button
										label={t('marketPlace.findResults')}
										primary
										disabled={
											!activeProduct || disableSearch
										}
										onClick={() => {
											handleChange(
												'apply_filter_seller',

												{
													filterType: 'product',
													filterValue: activeProduct,
													appliedFilter: filterValues,
												}
											)
											setApplyFilter(false)
											setFilterValue({
												organisationType: [],
												certificateType: [],
												country: [],
												state: [],
												supplyAbilityQty: '',
												supplyAbilityUOM: 'mt',
											})
											setPerformedSearch(true)
											setSupplyAtStart('')
											setSupplyAtEnd('')
										}}
									/>
								</Box>
							</Box>
						</Box>
					) : (
						<Box>
							<Box row>
								<IconWrapper
									size={28}
									style={{
										cursor: 'pointer',
										marginRight: 12,
									}}
									onClick={() => {
										dispatch(
											MarketPlaceDuc.creators.flushMarketPlaceGlobalRFQResults()
										)
										setPerformedSearch(false)
									}}
								>
									<Icon glyph={LeftArrowIcon} />
								</IconWrapper>
							</Box>

							<BoxShadowTable
								size="medium"
								columnConfig={columnConfig({
									t,
									organizations,
									actor,
									onChange: handleChange,
									isMobile,
								})}
								rowData={sellerGloablRFQ}
								isMobile={isMobile}
							/>
							<ShowMore
								documents={sellerGloablRFQ}
								total={sellerGloablRFQPagination.total}
								handleChange={() =>
									handleChange('paginate', {
										filterParams: sellerGloablRFQPagination,
									})
								}
								type="quote_pagination"
							/>
						</Box>
					)}
				</>
			)}
			{rootModule === 'buyer' && (
				<Box
					padding={8}
					width="100%"
					height="100%"
					style={{
						padding: '16px 70px',
						background:
							organizations.length > 0 ? 'transparent' : '#fff',
					}}
				>
					{organizations.length > 0 && (
						<>
							{isDocumentView && (
								<>
									<Box style={{ padding: '10px 5px' }}>
										<Breadcrumb
											links={breadCrumbs}
											onClick={target =>
												handleBreadCrumbClick(target)
											}
										/>
									</Box>
									<Box row justifyContent="space-between">
										<Box>
											<Title
												title="Details"
												icon={LeftArrowIcon}
												small
												onClick={() =>
													setDocumentView(false)
												}
											/>
										</Box>
									</Box>
								</>
							)}
							{!isDocumentView && (
								<Box>
									<Box
										row={!isMobile}
										justifyContent="space-between"
									>
										<Box row>
											<IconWrapper
												size={28}
												style={{
													cursor: 'pointer',
													marginRight: 12,
												}}
												onClick={() => {
													handleClick()
												}}
											>
												<Icon glyph={LeftArrowIcon} />
											</IconWrapper>
											<Title
												title={t(
													'marketPlace.searchResults'
												)}
												note={`${getIn(pagination, [
													'total',
												])} results`}
												small
											/>
										</Box>
										<Box
											row={!isMobile}
											style={{
												width: isMobile
													? '100%'
													: '40%',
											}}
											justifyContent="flex-end"
										>
											<ButtonWithNoBorder
												label={t(
													'marketPlace.clearSearch'
												)}
												primary
												extendStyles={{
													boxShadow: 'none',
												}}
												onClick={() => {
													handleClick()
												}}
											/>
											<Spacer size={10} />

											<Button
												label={t(
													'marketPlace.sendMultiRFQ'
												)}
												primary
												disabled={
													bulkRFQdocument.length === 0
												}
												onClick={() => {
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.MARKETPLACE$ACTION,
															{
																rootModule:
																	'buyer',

																action:
																	'create-rfq',
																documentReference: bulkRFQdocument.toString(),
															},
															{
																id: bulkRFQdocument.toString(),
																productID: getIn(
																	organizations,
																	[
																		0,
																		'product',
																		'id',
																	]
																),
															}
														)
													)
													dispatch(
														MarketPlaceDuc.creators.flushRFQ()
													)
												}}
											/>
										</Box>
									</Box>
									<Spacer size={4} />
								</Box>
							)}

							<Spacer size={16} />

							{isDocumentView && (
								<DocumentView document={activeDocument} />
							)}
							{!isDocumentView && (
								<>
									{loading && <TransparentBgSpinner />}
									{!loading && (
										<>
											<Box
												style={{
													maxHeight: '80vh',
													overflowY: 'scroll',
												}}
											>
												{organizations.map(org => (
													<>
														<Box row>
															<CheckBox
																onChange={() => {
																	if (
																		bulkRFQdocument.includes(
																			org.id
																		)
																	) {
																		setBulkRFQdocument(
																			bulkRFQdocument.filter(
																				item =>
																					item !==
																					org.id
																			)
																		)
																	} else {
																		setBulkRFQdocument(
																			[
																				...bulkRFQdocument,
																				org.id,
																			]
																		)
																	}
																}}
															/>
															<Spacer size={16} />

															<Box
																style={{
																	width:
																		'95%',
																	backgroundColor:
																		'white',
																	borderRadius:
																		'10px',
																	padding:
																		'23px 38px',
																	cursor:
																		'pointer',
																	boxShadow:
																		'rgba(0, 0, 0, 0.1) 0 0 7px',
																}}
																onClick={() => {
																	setDocumentView(
																		true
																	)
																	setActiveDocument(
																		org
																	)
																}}
															>
																<Box
																	row={
																		!isMobile
																	}
																	justifyContent="space-between"
																>
																	<Box
																		row
																		alignItems="baseline"
																	>
																		<Title
																			title={getIn(
																				org,
																				[
																					'meta',
																					'organization',
																					'name',
																				]
																			)}
																			note={`${getIn(
																				org,
																				[
																					'meta',
																					'organization',
																					'primaryAddress',
																					'state',
																				]
																			) ||
																				''}  ${getIn(
																				org,
																				[
																					'meta',
																					'organization',
																					'primaryAddress',
																					'country',
																				]
																			) ||
																				''}`}
																			small
																			isMobile={
																				isMobile
																			}
																		/>
																		{getIn(
																			org,
																			[
																				'meta',
																				'organization',
																				'status',
																				'state',
																			]
																		) ===
																			'verified' && (
																			<>
																				<Spacer
																					size={
																						10
																					}
																					horizontal
																				/>
																				<IconWrapper
																					size={
																						25
																					}
																					color="#13835A"
																				>
																					<Icon
																						glyph={
																							VerifiedIcon
																						}
																					/>
																				</IconWrapper>
																			</>
																		)}
																	</Box>
																	<Box
																		row
																		alignItems="flex-end"
																	>
																		{(
																			getIn(
																				org,
																				[
																					'meta',
																					'organization',
																					'certificationStatus',
																					'certificates',
																				]
																			) ||
																			[]
																		).map(
																			certificate => (
																				<CertificateBlock
																					key={getIn(
																						certificate,
																						[
																							'meta',
																							'certificate',
																							'issuingBody',
																						]
																					)}
																					name={getIn(
																						certificate,
																						[
																							'meta',
																							'certificate',
																							'issuingBody',
																						]
																					)}
																				/>
																			)
																		)}
																	</Box>
																</Box>

																<Label>
																	{getIn(
																		org,
																		[
																			'meta',
																			'organization',
																			'categories',
																			0,
																			'name',
																		]
																	)}
																</Label>
																<Spacer
																	size={12}
																/>

																<Box
																	row={
																		!isMobile
																	}
																	alignItems={
																		isMobile
																			? 'start'
																			: 'center'
																	}
																	justifyContent="space-between"
																>
																	<Box
																		row
																		alignItems="baseline"
																	>
																		<Pills>
																			{getIn(
																				org,
																				[
																					'product',
																					'name',
																				]
																			)}
																		</Pills>
																	</Box>
																	{isMobile && (
																		<Spacer
																			size={
																				16
																			}
																		/>
																	)}
																	<Box
																		row
																		alignItems="center"
																	>
																		<Button
																			label={t(
																				'marketPlace.sendRFQ'
																			)}
																			disabled={
																				bulkRFQdocument.length >
																				1
																			}
																			rounded
																			primary
																			onClick={e => {
																				e.stopPropagation()
																				dispatch(
																					MainRouteDuc.creators.switchPage(
																						MainRouteDuc
																							.types
																							.MARKETPLACE$ACTION,
																						{
																							rootModule:
																								'buyer',

																							action:
																								'create-rfq',
																							documentReference:
																								org.id,
																						}
																					)
																				)
																				dispatch(
																					MarketPlaceDuc.creators.flushRFQ()
																				)
																			}}
																		/>
																	</Box>
																</Box>
															</Box>
														</Box>
														<Spacer size={16} />
													</>
												))}
											</Box>

											<CTAWrapper>
												<Label
													color="inherit"
													bold
													small
													style={{
														padding: '10px 4px',
													}}
												>
													{(organizations || [])
														.length > 0
														? `${t(
																'common.showingListFor'
														  )}
							  ${(organizations || []).length}
							  ${t('common.of')}
							  ${totalDocument}`
														: t(
																'common.errorBlockMessage'
														  )}
												</Label>
												<Box
													style={{
														width: '75%',
														alignItems: 'center',
														justifyContent:
															'center',
													}}
												>
													<Button
														plain
														disabled={
															(
																organizations ||
																[]
															).length === 0 ||
															(
																organizations ||
																[]
															).length ===
																totalDocument
														}
														extendStyles={{
															background:
																'#F2F4FF',
															padding: '8px 24px',
															borderRadius:
																'24px',
															width: '170px',
															textAlign: 'center',
														}}
														label={t(
															'common.showMore'
														)}
														onClick={() =>
															handleChange(
																'paginate',
																{
																	filterParams: pagination,
																}
															)
														}
													/>
												</Box>
											</CTAWrapper>
										</>
									)}
								</>
							)}
						</>
					)}
					{organizations.length === 0 && (
						<>
							{loading && <TransparentBgSpinner />}
							{!loading && (
								<>
									{performedSearch && (
										<Box center style={{ minHeight: 300 }}>
											<ErrorBlock
												hideButton
												status="empty"
												message={
													<>
														<h3>
															{t(
																'marketPlace.noMatch'
															)}
														</h3>
														<p
															style={{
																marginTop: 8,
															}}
														>
															{t(
																'marketPlace.searchErrorMessage'
															)}
														</p>
													</>
												}
											/>
											<Button
												primary
												label={t(
													'marketPlace.searchAgain'
												)}
												onClick={() => {
													dispatch(
														MarketPlaceDuc.creators.flushMarketPlaceSearchResults()
													)
													setPerformedSearch(false)
												}}
												extendStyles={{
													width: 200,
													marginTop: 20,
												}}
											/>
										</Box>
									)}
									{!performedSearch && (
										<>
											<Box>
												<MarketPlaceHeading>
													{actor === 'palmoil_tdm'
														? t(
																'marketPlace.raiseRFQ'
														  )
														: t(
																'marketPlace.marketPlaceHeading'
														  )}
												</MarketPlaceHeading>
												<MarketPlaceNote>
													{actor !==
														'palmoil_tdm' && (
														<MarketPlaceNote>
															{t(
																'marketPlace.marketPlaceHeadingNote'
															)}
														</MarketPlaceNote>
													)}
												</MarketPlaceNote>
											</Box>
											<Spacer size={60} />
											<Box
												row={!isMobile}
												justifyContent="space-between"
											>
												<Box
													width={
														isMobile
															? '100%'
															: '40%'
													}
												>
													<Label
														required
														style={{
															fontSize: '14px',
															color:
																theme.color
																	.accent2,
														}}
													>
														{t(
															'marketPlace.searchProduct'
														)}
													</Label>
													<Spacer size={12} />
													<SuggestionsInput
														value=""
														placeholder={t(
															'marketPlace.searchProduct'
														)}
														onChange={e =>
															handleSearch(
																e.target.value
															)
														}
														suggestions={
															productList
														}
														onSelect={selected =>
															handleSelect(
																selected
															)
														}
														extendStyles={{
															borderRadius: '4px',
														}}
														error={message}
													/>
												</Box>
												<Box
													style={{
														display:
															applyFilter &&
															'none',
													}}
												>
													<Label
														required
														style={{
															fontSize: '14px',
															color:
																theme.color
																	.accent2,
														}}
													>
														{t(
															'marketPlace.moreFilters'
														)}
													</Label>
													<Spacer size={12} />
													<Button
														label={t(
															'marketPlace.advancedFilters'
														)}
														outline
														customIcon={
															<ButtonIconWrapper
																lightBG
															>
																<Icon
																	glyph={
																		FilterIcon
																	}
																/>
															</ButtonIconWrapper>
														}
														onClick={() =>
															setApplyFilter(true)
														}
													/>
												</Box>
											</Box>
											{applyFilter && (
												<>
													<Spacer size={20} />
													<CollapsableSegment
														expand
														title={t(
															'marketPlace.applyAdditionalFilters'
														)}
														open
														noBorderColor
													>
														<Spacer size={12} />

														<AdvanceFilter
															filterValue={
																filterValues
															}
															onChange={value => {
																setFilterValue(
																	value
																)
															}}
															supplyAtStart={
																supplyAtStart
															}
															supplyAtEnd={
																supplyAtEnd
															}
															onDateChange={(
																key,
																value
															) => {
																handleDateChange(
																	key,
																	value
																)
															}}
															isMobile={isMobile}
														/>
													</CollapsableSegment>
												</>
											)}
											<Spacer size={20} />
											<Box
												alignItems={
													isMobile
														? 'center'
														: 'flex-end'
												}
											>
												<Box width={200}>
													<Button
														label={t(
															'marketPlace.findResults'
														)}
														primary
														disabled={
															!activeProduct ||
															disableSearch
														}
														onClick={() => {
															handleChange(
																'apply_filter',
																{
																	filterType:
																		'productID',
																	filterValue: activeProduct,
																	appliedFilter: filterValues,
																}
															)
															setApplyFilter(
																false
															)
															setFilterValue({
																organisationType: [],
																certificateType: [],
																country: [],
																state: [],
																supplyAbilityQty:
																	'',
																supplyAbilityUOM:
																	'mt',
															})
															setPerformedSearch(
																true
															)
															setSupplyAtStart('')
															setSupplyAtEnd('')
														}}
													/>
												</Box>
											</Box>
											<Spacer size={20} />
										</>
									)}
								</>
							)}
						</>
					)}
				</Box>
			)}
		</>
	)
}

export default MarketPlace
