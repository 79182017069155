import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import moment from 'moment'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	MONTHS,
	SHIPMENT_PERIOD,
	headingStyles,
	liStyles,
	h3Styles,
	contractTableLabelStyles,
	contractTableTDStyles,
	actorStyles,
	s1Styles,
	paragraphStyles,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { Box } from 'ui-lib/utils/Box'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { Modal } from 'ui-lib/components/Modal'
import { SmallText, P } from 'ui-lib/components/Typography'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import { DigitalContractMessage } from '../../../../components/DigitalContractMessage'

const PreviewContractNo4 = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { contractAction } = query
	const { rootModule, documentReference, documentType } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [showModal, setShowModal] = useState(false)

	const contractDetails = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	if (!contract.quantity && contractAction === 'generate') {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('contracts.editContractText')}
					</P>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.ok')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					dispatch(
						MarketPlaceDuc.creators.editContract(
							contractDetails,
							documentType
						)
					)
				}}
			/>
			<Title
				title={
					contractAction === 'edit'
						? t('contracts.editContractPreview')
						: t('contracts.createContractPreview')
				}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'participants-success',
								documentReference,
								documentType,
							},
							{
								contractAction,
							}
						)
					)
				}}
				small
				isMobile={isMobile}
				hasNoMaxWidth
			/>
			<Spacer size={60} />
			<Box center style={{ background: '#EAEAEB' }}>
				<Box
					width={804}
					style={{ background: theme.color.white }}
					padding={60}
				>
					<Box center>
						<h2 style={headingStyles}>
							THE PALM OIL REFINERS ASSOCIATION OF MALAYSIA
						</h2>
						<h2 style={headingStyles}>
							801C/802A, BLOCK B, EXECUTIVE SUITES, KELANA
							BUSINESS CENTRE, 97, JALAN SS7/2,
						</h2>
						<h2 style={headingStyles}>
							47301 KELANA JAYA, SELANGOR, MALAYSIA.
						</h2>
						<h2 style={headingStyles}>
							FOB CONTRACT FOR PROCESSED PALM OIL PRODUCTS IN
							DRUMS
						</h2>
						<h2 style={{ ...headingStyles, marginTop: 20 }}>
							ORIGIN:
						</h2>
					</Box>
					<Spacer size={40} />
					<Box row={!isMobile} justifyContent="space-between">
						<Box>
							<p
								style={{
									...s1Styles,
									fontWeight: 'normal',
									lineHeight: '16px',
								}}
							>
								Revised and Effective
							</p>
							<br />
							<p
								style={{
									...s1Styles,
									fontWeight: 'normal',
									lineHeight: '17px',
								}}
							>
								1 February 2012
							</p>
						</Box>
						<br />
						<h1
							style={{
								margin: '0px 68px 12px 0',
								padding: 0,
								textAlign: isMobile ? 'center' : 'right',
							}}
						>
							4
						</h1>
					</Box>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={440}>
							<div>
								<p style={{ ...actorStyles }}>
									Seller :{' '}
									{getIn(contractDetails, [
										'contract',
										'seller',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Buyer :{' '}
									{getIn(contractDetails, [
										'contract',
										'buyer',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Broker :{' '}
									{getIn(contractDetails, [
										'contract',
										'broker',
										'name',
									])}
								</p>
								<br />
								<br />
								<p
									style={{
										color: 'black',
										fontFamily:
											'"Palatino Linotype", serif',
										fontStyle: 'normal',
										fontSize: 13,
									}}
								>
									* An asterisk denotes alternative wording
									and that not applicable should be deleted.
								</p>
							</div>
						</Box>
						<Box>
							<div
								style={{
									border: '1px solid black',
									padding: '10px',
									height: '148px',
									width: '148px',
									textAlign: 'center',
								}}
							>
								<p style={{ fontSize: '15px' }}>
									<u>Reference No:</u>
								</p>
								<div
									style={{
										overflow: isMobile
											? 'scroll'
											: 'hidden',
									}}
								>
									<table style={{ width: '100%' }}>
										<tbody>
											{getIn(contractDetails, [
												'contract',
												'referenceNo',
											]) ||
												[].map(ref => {
													return (
														<tr key={ref}>
															<td
																style={{
																	paddingTop:
																		'10px',
																}}
															>
																{ref}
															</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</div>
							</div>
						</Box>
					</Box>
					<p>
						<br />
					</p>
					<div>
						<p style={{ ...paragraphStyles, paddingTop: 5 }}>
							The Seller has agreed to sell and the Buyer has
							agreed to buy, the following product(s) on terms and
							conditions as stipulated hereunder:-
						</p>
						<p style={{ textIndent: '0pt', textAlign: 'left' }}>
							<br />
						</p>
					</div>
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								width: 680,
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '36pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Contract No :{' '}
											{getIn(contractDetails, [
												'contract',
												'contractNo',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Date :{' '}
											{`${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('DD MM YYYY')}; ${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('hh:mm A')}`}
										</p>
									</td>
								</tr>
								<tr style={{ height: '35pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Product :{' '}
											{getIn(contractDetails, [
												'contract',
												'product',
												'name',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Quantity in
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											MT
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Shipment Period
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Load Port
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Price (FOB)
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											PMT
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'quantity',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{`${t(
												MONTHS[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'month',
													])
												]
											)}, ${t(
												SHIPMENT_PERIOD[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'period',
													])
												]
											)}`}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'port',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'unitRate',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '36pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Packaging :{' '}
											{getIn(contractDetails, [
												'contract',
												'packaging',
												'type',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Type of Drum :{' '}
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'packagingType',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '17pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Product Specifications
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Special Conditions
										</p>
									</td>
								</tr>
								<tr style={{ height: '26pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												padding: '0 6px',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'specifications',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
										rowSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												padding: '0 6px',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'specialConditions',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '91pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												padding: '0 6px',
												textIndent: '0pt',
												textAlign: 'justify',
											}}
										>
											The specifications shall be those
											established by The Palm Oil Refiners
											Association of Malaysia (PORAM) as
											standard for the export of these
											products and in force at the date of
											contract and always provided they
											are not in contradiction with
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												padding: '0 6px',
												lineHeight: '12pt',
												textAlign: 'justify',
											}}
										>
											the above.
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							paddingTop: '3pt',
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						The FFA content shall be expressed as follows:
					</p>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						* If as palmitic acid, calculated on a molecular weight
						of 256;
					</p>
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						* If as oleic acid, calculated on a molecular weight of
						282.
					</p>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						Payment at Seller’s bank in Malaysia
					</p>
					<Spacer size={20} />
					<ul
						style={{
							color: 'black',
							fontFamily: '"Times New Roman", serif',
							fontStyle: 'normal',
							fontWeight: 'normal',
							textDecoration: 'none',
							fontSize: '10pt',
							listStyleType: 'none',
						}}
					>
						<li data-list-text="*">
							<p
								style={{
									textAlign: 'left',
									marginLeft: 24,
								}}
							>
								(i) in accordance with the provisions of
								paragraph (a) of the Payment Clause;
							</p>
						</li>
						<li data-list-text="*">
							<p
								style={{
									textAlign: 'left',
									marginLeft: 24,
								}}
							>
								(ii) in accordance with the provisions of
								paragraph (b) of the Payment Clause;
							</p>
						</li>
					</ul>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>2.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUANTITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Seller has the option of shipping 1% more or
								less of the contract quantity. Such variance to
								be settled at the contract price.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>3.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUALITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								At time and place of sampling, the oil shall be
								of good merchantable quality within the meaning
								of the description and specifications stated in
								the preamble. Unless so stated, or otherwise
								agreed between the parties and confirmed in
								writing, the oil is not warranted to be suitable
								for any specific end-use or to conform to any
								particular analytical criteria not mentioned
								above.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>4.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SHIPMENT</h3>
							<Spacer size={20} />
							<Box width={isMobile ? 238 : 640}>
								<h3 style={h3Styles}>
									<span
										style={{
											marginRight: 20,
											fontWeight: 'normal',
										}}
									>
										(i)
									</span>
									Suitable Freight Space
								</h3>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
										marginLeft: 30,
									}}
								>
									In the event shipment is on break-bulk,
									suitable freight space to be provided
									alongside berth by Buyer. In the event that
									the vessel is unable to receive all the
									drums under deck, Seller shall load the
									remaining drums on deck at Buyer’s risk.
									Buyer shall then accept the remaining drums
									on deck and a claused Bill of Lading stating
									the number of drums loaded on deck. Buyer
									shall permit partial shipment. In this
									event, Buyer to hold Seller free and
									harmless of all consequences.
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
										marginLeft: 30,
									}}
								>
									In the event shipment is by containers, fit
									and suitable containers and freight space
									shall be provided at the port of loading.
								</p>
								<Spacer size={20} />
								<Box width={isMobile ? 238 : 640}>
									<h3 style={h3Styles}>
										<span
											style={{
												marginRight: 16,
												fontWeight: 'normal',
											}}
										>
											(ii)
										</span>
										Nomination of Vessel
									</h3>
									<Spacer size={12} />

									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										Buyer to nominate to Seller the name of
										the vessel (along with the name of
										vessel's agent at load port) and
										expected time of vessel's arrival not
										less than 14 calendar days prior to
										estimated time of arrival (ETA) of
										vessel.
									</p>
								</Box>
								<Spacer size={20} />
								<Box width={180}>
									<h3 style={h3Styles}>
										<span
											style={{
												marginRight: 14,
												fontWeight: 'normal',
											}}
										>
											(iii)
										</span>
										Substitution of Vessel
									</h3>
									<Spacer size={12} />
								</Box>
								<Box width={isMobile ? 238 : 640}>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										Buyer is allowed to substitute the
										originally nominated vessel provided
										that the vessel's ETA at loading berth
										remains unchanged and shall provide
										Seller with notice of substitution at
										least 7 calendar days prior to the ETA.
										If the originally nominated vessel or
										the substituted vessel is delayed (but
										always within the original shipment
										period) beyond the given ETA at the
										loading berth by more than 3 calendar
										days, Buyer shall bear all the
										additional costs including extra
										storage, overtime, export duties and
										incidental expenses, etc as incurred.
									</p>
								</Box>
								<Spacer size={20} />
								<Box width={180}>
									<h3 style={h3Styles}>
										<span
											style={{
												marginRight: 14,
												fontWeight: 'normal',
											}}
										>
											(iv)
										</span>
										Vessel's Dues
									</h3>
									<Spacer size={12} />
								</Box>
								<Box width={isMobile ? 238 : 640}>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										All expenses relating to vessel at load
										port including wharfage, dockage, quay
										charges, pilotage, port dues, tugs, any
										freight or transportation levy/tax
										imposed by the Malaysian Government and
										expenses of like nature incurred at
										loading port(s) shall be for the account
										of Buyer.
									</p>
								</Box>
								<Spacer size={20} />
								<Box width={180}>
									<h3 style={h3Styles}>
										<span
											style={{
												marginRight: 14,
												fontWeight: 'normal',
											}}
										>
											(v)
										</span>
										Vessel
									</h3>
									<Spacer size={12} />
								</Box>
								<Box width={isMobile ? 238 : 640}>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										For the purpose of this contract, the
										words “vessel” or “vessels” shall mean
										any full-powered engine-driven vessel.
									</p>
								</Box>
								<Spacer size={20} />
							</Box>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>5.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>RELEASE OF BILLS OF LADING</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Before the date of loading, Buyer shall telex
								ship’s Agents/Owners and Seller authorizing
								ship’s Agents/Owners to release Bills of Lading
								(marked freight to collect) direct to Seller
								immediately on completion of loading. All Bills
								of Lading shall be released to Seller within 48
								hours or on the first working day after
								completion of loading whichever is later
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event that the Bills of Lading are not
								released to Seller as above, Seller, without
								prejudice to his other rights under the
								contract, may also claim interest from Buyer at
								the rate of 1 1/2% per month on the contract
								price.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								If Buyer fails to pay freight or any other
								charges incurred, Seller reserves the right to
								pay freight or such other charges to secure the
								freight prepaid Bills of Lading and recover the
								cargo. Buyer to indemnify Seller for all
								resulting losses/expenses.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>6.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>
								EXTENSION OF SHIPMENT CLAUSE
							</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the contract period does not exceed 31
								calendar days the period of shipment shall, at
								the request of Buyer, be extended by an
								additional period not exceeding 8 calendar days
								provided notice is given to Seller of his
								intention to claim such extension on or before
								the last shipment day of the contract period.
								Buyer shall provide satisfactory evidence that
								such delayed vessel was originally booked with
								layday/cancelling within the original contract
								period. For any contract period exceeding 31
								calendar days, this clause shall not apply.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								For such late shipment, Buyer shall pay a
								penalty to Seller for late presentation of
								vessel as follows :
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								1/2% for 1,2,3, or 4 days
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								1% for 5 or 6 days
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								1 1/2% for 7 or 8 days
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should Buyer claim the Extension of Shipment
								Clause and the vessel fails to complete loading
								within such 8 calendar days, the original
								contract period shall be deemed to have been
								extended by 8 calendar days and the contract
								price increased by 1 1/2%. On the determination
								of penalty, the extended day shall be on the
								basis of completion of loading
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should Buyer not claim the above extension and
								fails to present vessel within the contract
								period, any penalty whether arrived at by
								amicable settlement or arbitration shall not be
								related to the penalty of this clause.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Upon the expiry of extended period without
								vessel being alongside the berth, Buyer is
								deemed to be in default of the contract.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>7.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SHIPPING INSTRUCTIONS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Buyer shall provide Seller with the following
								shipping instructions within 7 calendar days of
								the date of the contract:-
							</p>
							<Spacer size={20} />
							<ul
								style={{
									color: 'black',
									fontFamily: '"Times New Roman", serif',
									fontStyle: 'normal',
									fontWeight: 'normal',
									textDecoration: 'none',
									fontSize: '10pt',
									listStyleType: 'none',
								}}
							>
								<li>
									<p
										style={{
											textAlign: 'left',
											marginLeft: 24,
										}}
									>
										(i) &nbsp;&nbsp;&nbsp;&nbsp;Destination
										of cargo.
									</p>
								</li>
								<li>
									<p
										style={{
											textAlign: 'left',
											marginLeft: 24,
										}}
									>
										(ii) &nbsp;&nbsp;&nbsp;Notifying party.
									</p>
								</li>
								<li>
									<p
										style={{
											textAlign: 'left',
											marginLeft: 24,
										}}
									>
										(iii) &nbsp;&nbsp;Shipping marks and
										numbers.
									</p>
								</li>
								<li>
									<p
										style={{
											textAlign: 'left',
											marginLeft: 24,
										}}
									>
										(iv) &nbsp;&nbsp;Buyer’s banker’s name
										and address in event of payment being
										cash on presentation of documents.
									</p>
								</li>
							</ul>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>8.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>EXPORT DUTY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Seller shall be responsible for Malaysian export
								duty as incurred.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>9.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>WEIGHTS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Gross tare and nett weight as ascertained from
								weighing scales at Seller’s premises on 10% of
								the total drums selected at random and duly
								certified by independent surveyors appointed by
								Seller shall be final.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Buyer has the right to be represented, at his
								own costs, at time and place of weighing in
								which case weights shall be established
								conjointly.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>10.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SAMPLING AND ANALYSIS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Representative samples of the oil shall be drawn
								from 10% of the total quantity of the packed
								drums selected at random by independent
								surveyors at Seller’s premises
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								The sample so drawn shall be in quadruplicate (1
								for contractual analysis, 1 for the shipper and
								2 to be retained by the Surveyors) of the oil
								packed, at time of weighing.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Buyer has the right to be represented at his own
								cost at time of sampling at Seller’s premises to
								draw samples conjointly with Seller’s
								representatives. If Buyer is so represented,
								only samples drawn and sealed conjointly shall
								be accepted as official contract samples for
								analysis purposes.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event of any dispute on quality analysis,
								the samples retained by the surveyors shall be
								referred to the Malaysian Palm Oil Board (MPOB)
								laboratory, whose analysis shall be final.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>11.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DESTINATIONS EXCLUDED</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								It is mutually agreed that the final destination
								of the cargo shipped from Malaysian ports shall
								not be any destinations so declared prohibitive
								by the Government of Malaysia from time to time.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>12.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>INSURANCE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Buyer is deemed to have covered, before arrival
								of vessel alongside berth at loading port,
								insurance on all risks terms - Institute Cargo
								Clauses (All Risks) - including risk of
								contamination and leakage irrespective of
								percentage of each package or on the whole,
								including the risk of war, strikes, riots, civil
								commotion - effected with first class
								underwriters for 100% of the value to be
								shipped.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>13.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>PAYMENT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Payment shall be made as stipulated in the
								preamble for 100% of the invoice value against a
								complete set of shipping documents.
							</p>
							<Spacer size={20} />
							<Box row={!isMobile} width={isMobile ? 238 : 640}>
								<p style={{ ...paragraphStyles }}>*(a)</p>
								<Box>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										by irrevocable and confirmed letter of
										credit unrestricted for negotiation
										established in Seller’s favour through a
										recognised bank for 101% of the mean
										contract quantity. Unless otherwise
										agreed between the parties, such credit
										shall be advised and available to Seller
										not later than 10 calendar days from
										date of contract. Should the credit be
										opened on terms inconsistent with the
										contract, Seller may demand amendments
										which shall be arranged by Buyer and
										notified to Seller through the credit
										opening bank within 7 calendar days of
										the demand being received. The Letter of
										Credit shall provide for the following:-
									</p>
									<Spacer size={20} />
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(i) Telegraphic Transfer reimbursement
										allowed
									</p>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(ii) Partial shipment allowed
									</p>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(iii) Shipment on deck allowed
									</p>
								</Box>
							</Box>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								*(b)
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by
								cash on presentation of documents.
							</p>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								Seller shall present documents to Buyer through
								a Bank nominated by Buyer. All bank charges at
								Seller’s bank are for Seller’s account and all
								bank charges at Buyer's bank are for Buyer’s
								account. Buyer shall arrange payment by
								telegraphic transfer (TT) at Buyer’s cost.
							</p>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								Unless otherwise agreed, shipping documents
								shall consist of the following in triplicate:-
							</p>
							<Spacer size={20} />
							<Box width={isMobile ? 238 : 640}>
								<p
									style={{
										...paragraphStyles,
									}}
								>
									1. Commercial invoice;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									2. Full set of `on board' Bill(s) of Lading
									(marked freight to collect);
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									3. Certificate of quantity of drums loaded
									on board ascertained at port of loading and
									issued by a recognized independent surveyor;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									4. Survey report issued by a recognized
									independent surveyor, certifying the weight
									and particulars as to time and place of
									weighing and sampling;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									5. Certificate of analysis, issued by a
									recognized independent laboratory;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									6. Certificate of Origin.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Buyer is to accept certified photostatic
									copy(ies) of (3), (4) and (5) relating to
									the shipment.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Should documents be presented with
									incomplete set(s) of Bills of Lading,
									payment shall be made provided that delivery
									of such Bill(s) of Lading be guaranteed by
									Seller. Such guarantee to be endorsed, if
									required by Buyer by a recognized bank.
									Acceptance of this guarantee shall not
									prejudice Buyer’s rights under this
									contract.
								</p>
							</Box>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>14.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>NOTICE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the terms of the contract require notice
								to be given, such notice shall be transmitted or
								sent by cable, telegram, telex, fascmile, email,
								post or courier service or delivered by hand
								within the time limit specified in the contract.
								All notices shall be under reserve for errors
								and omissions in transmission or delivery. Any
								notice received after 1600 hours Malaysian time
								on a business day shall be deemed to have been
								received on the following business day.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>15.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>NON-BUSINESS DAYS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the time limit for doing any act or
								giving any notice expire on a Saturday, Sunday
								or any National Gazetted Public Holiday in
								Malaysia, the time so limited shall be extended
								until the first business day thereafter. All
								business days shall be deemed to end 1600 hours
								Malaysian time Monday to Friday inclusive. This
								clause shall however not be applicable to the
								contract period.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>16.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DOMICILE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								The contract shall be deemed to have been made
								in Malaysia and the construction, validity and
								performance thereof shall be governed in all
								respect by Malaysian Law, which shall have
								exclusive jurisdiction wherever the domicile,
								residence or place of business of the parties to
								the contract may be or become.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>17.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>FORCE MAJEURE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the performance of the contract be
								prevented by reason of fire, strikes, lockouts,
								riots, civil commotion, floods and/or any cause
								comprehended in the term force majeure, the
								contract period shall be extended for a period
								equal to the duration of the disabling event but
								not exceeding a period of 60 calendar days. If
								the force majeure event ends within 21 calendar
								days preceeding the end of the extended period,
								then a further 21 calendar days shall be allowed
								after the termination of the force majeure
								event.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the fulfilment of the contract not be
								possible within the extended period, the
								contract or any unfulfilled part thereof shall
								be deemed to be null and void at the end of such
								extended period.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>18.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DEFAULT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In default of fulfilment of the contract by
								either party, the other party at his discretion
								shall, after giving notice, have the right
								either to cancel the contract or the right to
								sell or purchase, as the case may be, against
								the defaulter who shall on demand make good the
								loss, if any, on such sale or purchase. If the
								party liable to pay shall be dissatisfied with
								the price of such sale or purchase, or if
								neither of the above right is exercised, the
								damage if any, shall, failing amicable
								settlement, be determined by arbitration. The
								damages awarded against the defaulter shall be
								limited to the difference between the contract
								price and the market price on the day of the
								default but if the Arbitrator(s) consider the
								circumstances of the default justify it, they
								may at their absolute discretion, award
								additional damages.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>19.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>BANKRUPTCY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								If before the fulfilment of the contract either
								party shall suspend payment, commit an act of
								bankruptcy, notify any of his creditors that he
								is unable to meet his debts or that he has
								suspended payments or that he is about to
								suspend payment of his debts, convene, call or
								hold a meeting either of his creditors or to
								pass a resolution to go into liquidation (except
								for voluntary winding up of a solvent company
								for the purpose of reconstruction or
								amalgamation) or shall apply for an official
								moratorium, have a petition presented for
								winding up or shall have a Receiver appointed,
								he shall be deemed to be and shall be treated as
								being at default and the contract shall
								forthwith be closed, either at the market price
								then current for similar goods or, at the option
								of the other party, at the price to be
								ascertained by repurchase or resale and the
								difference between the contract price and such
								closing-out price shall be the amount which the
								other party shall be entitled to claim or shall
								be liable to account for under this contract.
								Should either party be dissatisfied with the
								price, the matter shall be referred to
								arbitration. Where no such resale or repurchase
								takes place, the closing-out price shall be
								determined by a Price Settlement Committee of
								The Palm Oil Refiners Association of Malaysia.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>20.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>PROHIBITION</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event, during the contract period, of
								prohibition of export or any other executive or
								legislative act by or on behalf of the
								Government of the country of origin or of the
								territory where the port(s) of shipment name
								herein is/are situated, or of blockade or
								hostilities, restricting export whether
								partially or otherwise, any such restriction
								shall be deemed by both parties to apply to the
								contract and to the extent of such total or
								partial restriction to prevent fulfilment
								whether by shipment or by any other means
								whatsoever and to that extent the contract or
								any unfulfilled portion thereof shall be
								extended by 30 calendar days.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event of shipment during the extended
								period still proved impossible by reason of any
								of the causes in this clause, the contract or
								any unfulfilled part thereof shall be cancelled.
								Seller invoking this clause shall advise Buyer
								with due despatch. If required, Seller must
								produce proof to justify his claim for extension
								or cancellation under this clause.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>21.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>ODD DAYS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In any month containing an odd number of days,
								the middle day shall be reckoned as belonging to
								both halves of the month.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>22.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SHIPPING INSTRUCTIONS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Any dispute arising out of this contract,
								including any question of law arising in
								connection therewith, shall be referred to
								arbitration in Malaysia (at the PORAM
								Secretariat or elsewhere if so agreed), in
								accordance with the PORAM Rules of Arbitration
								and Appeal in force at the date of the
								initiation of the arbitration with the parties
								hereby agreeing that the arbitration shall be
								deemed an International Arbitration. The seat of
								the arbitration shall be Malaysia and the
								Malaysian Arbitration Act 2005 (“the Act”) or
								any reenactment thereof shall apply to any
								arbitration under this contract. The parties
								hereto further agree that Part III of the Act
								shall not apply to any arbitration under this
								contract.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Neither party hereto, nor any persons claiming
								under either of them shall bring any action or
								other legal proceedings against the other of
								them in respect of any such dispute until such
								dispute shall first have been heard and
								determined by the Sole Arbitrator/Panel of
								Arbitrators/Appeal Board (as the case may be),
								in accordance with the PORAM Rules of
								Arbitration and Appeal and it is hereby
								expressly agreed and declared that the obtaining
								of an award from the Sole Arbitrator/Panel of
								Arbitrators/Appeal Board (as the case may be),
								shall be a condition precedent to the right of
								either party hereto or of any person claiming
								under either of them to bring any action or
								other legal proceedings against the other of
								them in respect of such dispute
							</p>
						</Box>
					</Box>
					<Box
						row={!isMobile}
						justifyContent="space-between"
						margin="28px 0 0"
					>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Seller:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Broker:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Buyer:
						</p>
					</Box>
				</Box>
				<Box
					style={{
						width: isMobile ? 350 : 800,
						padding: 15,
						margin: '40px 0',
						alignItems: 'center',
						background: theme.color.white,
						justifyContent: 'space-between',
					}}
					row={!isMobile}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '60%',
							alignItems: 'end',
							marginRight: '5%',
							padding: '15px 10px',
							background: theme.color.white,
						}}
					>
						<SmallText
							style={{
								fontSize: 14,
								color: '#8D93A0',
								lineHeight: '19px',
								textAlign: 'justify',
							}}
						>
							{t('contracts.proceedToESignText')}
						</SmallText>
					</Box>

					<Button
						rounded
						label={t('contracts.proceedToESign')}
						reverse
						onClick={() => {
							if (contractAction === 'edit') {
								setShowModal(true)
							} else {
								dispatch(
									MarketPlaceDuc.creators.postContractDetails(
										contractDetails,
										documentType
									)
								)
							}
						}}
						extendStyles={{
							width: 266,
							background: '#3F56C4',
							color: theme.color.white,
						}}
					/>
				</Box>
				<DigitalContractMessage
					width={isMobile ? 350 : 800}
					margin="0 0 40px 0"
				/>
			</Box>
		</>
	)
}

export { PreviewContractNo4 }
