/* @flow */
import React from 'react'
// $FLowFixMe
import styled from 'styled-components'
import { Box } from 'ui-lib/utils/Box'
import { Icon } from 'ui-lib/icons/components/Icon'
import { H2, H1, SmallText } from 'ui-lib/components/Typography'

const TitleWrapper = styled.div(p => ({
	marginBottom: p.small ? 'auto' : 18,
}))

const DirectionIconWrapper = styled.div`
	height: 24px;
	width: 24px;
	margin-right: 10px;
	cursor: pointer;
`

const Note = styled.span(p => ({
	...p.theme.fonts.regular,
	fontSize: p.isMobile ? p.theme.fontSize.xxs : p.theme.fontSize.m,
	color: '#8D93A0',
}))

type Props = {
	id?: string,
	title: string,
	note?: string,
	icon?: {},
	small?: boolean,
	link?: boolean,
	noteColor?: string,
	noteFontSize?: string,
	titleFontSize?: string,

	onClick: () => void,
}

const Title = ({
	title,
	note,
	icon,
	small,
	onClick,
	noteColor,
	noteFontSize,
	isMobile = false,
	hasNoMaxWidth = false,
}: Props) => {
	const getSmallText = isMobile ? SmallText : H2
	const Component = small ? getSmallText : H1

	return (
		<TitleWrapper small={small}>
			<Box row alignItems="center">
				{icon && (
					<DirectionIconWrapper onClick={onClick}>
						<Icon glyph={icon} />
					</DirectionIconWrapper>
				)}
				<Component
					style={{
						lineHeight: 1.25,
						whiteSpace: isMobile ? 'unset' : 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						...(!hasNoMaxWidth
							? { maxWidth: isMobile ? '125px' : '460px' }
							: {}),
					}}
					title={title}
				>
					{title}
				</Component>
			</Box>
			{note && (
				<Note
					noteColor={noteColor}
					fontSize={noteFontSize}
					isMobile={isMobile}
				>
					{note}
				</Note>
			)}
		</TitleWrapper>
	)
}

Title.defaultProps = {
	onClick: () => {},
}

export { Title, Note }
