import React, { useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	MONTHS,
	SHIPMENT_PERIOD,
	headingStyles,
	contractTableLabelStyles,
	contractTableTDStyles,
	actorStyles,
	liStyles,
	s1Styles,
	h3Styles,
	paragraphStyles,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { Modal } from 'ui-lib/components/Modal'
import { Box } from 'ui-lib/utils/Box'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { SmallText, P } from 'ui-lib/components/Typography'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import { DigitalContractMessage } from '../../../../components/DigitalContractMessage'

const PreviewContractNo2 = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { contractAction } = query
	const { rootModule, documentReference, documentType } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [showModal, setShowModal] = useState(false)

	const contractDetails = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	if (!contract.quantity && contractAction === 'generate') {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('contracts.editContractText')}
					</P>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.ok')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					dispatch(
						MarketPlaceDuc.creators.editContract(
							contractDetails,
							documentType
						)
					)
				}}
			/>
			<Title
				title={
					contractAction === 'edit'
						? t('contracts.editContractPreview')
						: t('contracts.createContractPreview')
				}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'participants-success',
								documentReference,
								documentType,
							},
							{
								contractAction,
							}
						)
					)
				}}
				isMobile={isMobile}
				hasNoMaxWidth
			/>
			<Spacer size={60} />
			<Box
				center
				style={{
					background: '#EAEAEB',
				}}
			>
				<Box
					width={800}
					style={{ background: theme.color.white }}
					padding={60}
				>
					<Box center>
						<h2 style={headingStyles}>
							THE PALM OIL REFINERS ASSOCIATION OF MALAYSIA
						</h2>
						<h2 style={headingStyles}>
							801C/802A, BLOCK B, EXECUTIVE SUITES,
						</h2>
						<h2 style={headingStyles}>
							KELANA BUSINESS CENTRE, 97, JALAN SS7/2,
						</h2>
						<h2 style={headingStyles}>
							47301 KELANA JAYA, SELANGOR, MALAYSIA.
						</h2>
						<h2 style={headingStyles}>
							ISSUED JOINTLY WITH THE MALAYAN EDIBLE OIL
						</h2>
						<h2 style={headingStyles}>
							MANUFACTURERS&#39; ASSOCIATION
						</h2>
						<h2 style={headingStyles}>
							FOB CONTRACT FOR PROCESSED PALM OIL AND
						</h2>
						<h2 style={headingStyles}>PALM KERNEL OIL PRODUCTS</h2>
						<h2 style={headingStyles}>IN BULK</h2>
						<h2 style={headingStyles}>ORIGIN:</h2>
					</Box>
					<h1
						style={{
							margin: '0px 68px 12px 0',
							padding: 0,
							textAlign: isMobile ? 'center' : 'right',
						}}
					>
						2
					</h1>
					<Box row justifyContent="space-between">
						<Box width={440}>
							<p style={{ ...s1Styles, lineHeight: '16px' }}>
								Revised
							</p>
							<br />
							<p style={{ ...s1Styles, lineHeight: '17px' }}>
								and effective : 1 February 2012
							</p>
							<br />
							<div>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Seller :{' '}
									{getIn(contractDetails, [
										'contract',
										'seller',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Buyer :{' '}
									{getIn(contractDetails, [
										'contract',
										'buyer',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Broker :{' '}
									{getIn(contractDetails, [
										'contract',
										'broker',
										'name',
									])}
								</p>
								<br />
								<p
									style={{
										color: 'black',
										fontFamily:
											'"Palatino Linotype", serif',
										fontStyle: 'normal',
										fontSize: 13,
									}}
								>
									* An asterisk denotes alternative wording
									and that not applicable should be deleted.
								</p>
							</div>
						</Box>
						<Box>
							<div
								style={{
									border: '1px solid black',
									padding: '10px',
									height: '148px',
									width: '160px',
									textAlign: 'center',
								}}
							>
								<p style={{ fontSize: '15px' }}>
									<u>Reference No:</u>
								</p>
								<div
									style={{
										overflow: isMobile
											? 'scroll'
											: 'hidden',
									}}
								>
									<table style={{ width: '100%' }}>
										<tbody>
											{getIn(contractDetails, [
												'contract',
												'referenceNo',
											]) ||
												[].map(ref => {
													return (
														<tr key={ref}>
															<td
																style={{
																	paddingTop:
																		'10px',
																}}
															>
																{ref}
															</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</div>
							</div>
						</Box>
					</Box>
					<p>
						<br />
					</p>
					<div>
						<p style={{ ...paragraphStyles, paddingTop: 5 }}>
							The Seller has agreed to sell and the Buyer has
							agreed to buy, the following product(s) on terms and
							conditions as stipulated hereunder:-
						</p>
						<p style={{ textIndent: '0pt', textAlign: 'left' }}>
							<br />
						</p>
					</div>
					<div>
						<div
							style={{ overflow: isMobile ? 'scroll' : 'hidden' }}
						>
							<table
								style={{
									borderCollapse: 'collapse',
									width: 680,
								}}
								cellSpacing={0}
							>
								<tbody>
									<tr style={{ height: '36pt' }}>
										<td
											style={contractTableTDStyles}
											colSpan={2}
										>
											<p
												style={{
													...contractTableLabelStyles,
													textAlign: 'left',
													paddingLeft: '6px',
												}}
											>
												Contract No :{' '}
												{getIn(contractDetails, [
													'contract',
													'contractNo',
												])}
											</p>
										</td>
										<td
											style={contractTableTDStyles}
											colSpan={2}
										>
											<p
												style={{
													...contractTableLabelStyles,
													textAlign: 'left',
													paddingLeft: '6px',
												}}
											>
												Date :{' '}
												{`${moment(
													getIn(contractDetails, [
														'contract',
														'date',
													])
												).format(
													'DD MM YYYY'
												)}; ${moment(
													getIn(contractDetails, [
														'contract',
														'date',
													])
												).format('hh:mm A')}`}
											</p>
										</td>
									</tr>
									<tr style={{ height: '35pt' }}>
										<td
											style={contractTableTDStyles}
											colSpan={4}
										>
											<p
												style={{
													...contractTableLabelStyles,
													textAlign: 'left',
													paddingLeft: '6px',
												}}
											>
												Product :{' '}
												{getIn(contractDetails, [
													'contract',
													'product',
													'name',
												])}
											</p>
										</td>
									</tr>
									<tr style={{ height: '23pt' }}>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													lineHeight: '11pt',
													textAlign: 'center',
												}}
											>
												Quantity in
											</p>
											<p
												style={{
													...contractTableLabelStyles,
													lineHeight: '11pt',
													textAlign: 'center',
												}}
											>
												MT
											</p>
										</td>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													textAlign: 'center',
												}}
											>
												Shipment Period
											</p>
										</td>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													textAlign: 'center',
												}}
											>
												Load Port
											</p>
										</td>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													lineHeight: '11pt',
													textAlign: 'center',
												}}
											>
												Price (FOB)
											</p>
											<p
												style={{
													...contractTableLabelStyles,
													lineHeight: '11pt',
													textAlign: 'center',
												}}
											>
												PMT
											</p>
										</td>
									</tr>
									<tr style={{ height: '23pt' }}>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													textAlign: 'center',
												}}
											>
												<br />
												{getIn(contractDetails, [
													'contract',
													'quantity',
												])}
											</p>
										</td>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													textAlign: 'center',
												}}
											>
												<br />
												{`${t(
													MONTHS[
														getIn(contractDetails, [
															'contract',
															'shipmentPeriod',
															'month',
														])
													]
												)}, ${t(
													SHIPMENT_PERIOD[
														getIn(contractDetails, [
															'contract',
															'shipmentPeriod',
															'period',
														])
													]
												)}`}
											</p>
										</td>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													textAlign: 'center',
												}}
											>
												<br />
												{getIn(contractDetails, [
													'contract',
													'product',
													'meta',
													'port',
												])}
											</p>
										</td>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													textAlign: 'center',
												}}
											>
												<br />
												{getIn(contractDetails, [
													'contract',
													'unitRate',
												])}
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<Spacer size={12} />
						<div
							style={{ overflow: isMobile ? 'scroll' : 'hidden' }}
						>
							<table
								style={{
									borderCollapse: 'collapse',
									width: 680,
								}}
								cellSpacing={0}
							>
								<tbody>
									<tr style={{ height: '12pt' }}>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													paddingLeft: '5pt',
													textIndent: '0pt',
													lineHeight: '11pt',
													textAlign: 'left',
												}}
											>
												Product Specifications
											</p>
										</td>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													paddingLeft: '5pt',
													textIndent: '0pt',
													lineHeight: '11pt',
													textAlign: 'left',
												}}
											>
												Special Conditions
											</p>
										</td>
									</tr>
									<tr style={{ height: '23pt' }}>
										<td
											style={contractTableTDStyles}
											colSpan={2}
										>
											<p
												style={{
													...contractTableLabelStyles,
													padding: '5pt',
													textIndent: '0pt',
													textAlign: 'left',
												}}
											>
												<br />
												{getIn(contractDetails, [
													'contract',
													'product',
													'meta',
													'specifications',
												])}
												<br />
												{getIn(contractDetails, [
													'contract',
													'specialConditions',
												])}
											</p>
										</td>
									</tr>
									<tr style={{ height: '92pt' }}>
										<td style={contractTableTDStyles}>
											<p
												style={{
													...contractTableLabelStyles,
													padding: '5pt',
													textIndent: '0pt',
													textAlign: 'justify',
												}}
											>
												The specifications shall be
												those established by The Palm
												Oil Refiners Association of
												Malaysia (PORAM)/The Malayan
												Edible Oil Manufacturers’
												Association (MEOMA) as the case
												may be, as standard for the
												export of these products and in
												force at the date of contract
												and always provided they are not
												in contradiction with the above.
											</p>
										</td>
										<td style={contractTableTDStyles}>
											<p
												style={{
													textIndent: '0pt',
													textAlign: 'left',
												}}
											>
												<br />
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<p
						style={{
							...paragraphStyles,
							paddingTop: '3pt',
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						The FFA content shall be expressed as follows:
					</p>
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						If as palmitic acid, calculated on a molecular weight of
						256.
					</p>
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						If as oleic acid, calculated on a molecular weight of
						282.
					</p>
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						If as lauric acid, calculated on a molecular weight of
						200.
					</p>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						Payment at Seller’s bank in Malaysia
					</p>
					<Spacer size={20} />
					<ul
						style={{
							color: 'black',
							fontFamily: '"Times New Roman", serif',
							fontStyle: 'normal',
							fontWeight: 'normal',
							textDecoration: 'none',
							fontSize: '10pt',
							listStyleType: 'none',
						}}
					>
						<li data-list-text="*">
							<p
								style={{
									lineHeight: '11pt',
									textAlign: 'left',
								}}
							>
								* (i) in accordance with the provisions of
								paragraph (a) of the Payment.
							</p>
							<p
								style={{
									textIndent: '0pt',
									lineHeight: '11pt',
									textAlign: 'left',
								}}
							>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Clause;
							</p>
							<p style={{ textIndent: '0pt' }}>
								<br />
							</p>
						</li>
						<li data-list-text="*">
							<p
								style={{
									textAlign: 'left',
								}}
							>
								* (ii) in accordance with the provisions of
								paragraph (b) of the Payment
							</p>
							<p
								style={{
									textIndent: '0pt',
									lineHeight: '11pt',
									textAlign: 'left',
								}}
							>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Clause;
							</p>
						</li>
					</ul>
					<Spacer size={20} />
					<ol>
						<li style={liStyles} data-list-text={1}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>QUANTITY</h3>
								</Box>
								<Box width={500}>
									<span style={{ ...paragraphStyles }}>
										Seller shall deliver at loading port the
										contract quantity. However, under the
										following circumstances the actual
										quantity delivered may be varied from
										the contract quantity :
									</span>
									<Spacer size={12} />
									<ol>
										<li data-list-text="i)">
											<p style={{ ...paragraphStyles }}>
												<span>
													i)&nbsp;&nbsp;&nbsp;
												</span>
												2% or 25mt. (whichever is lower)
												more or less at buyer’s option
												to be declared at
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;least
												seventy two (72) hours prior to
												vessel's arrival at load port.
											</p>
										</li>
										<Spacer size={12} />
										<li data-list-text="ii)">
											<p style={{ ...paragraphStyles }}>
												<span>
													ii)&nbsp;&nbsp;&nbsp;
												</span>
												a tolerance of 1% or 10 mt
												(whichever is lower) more or
												less from the quantity
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;nominated
												for loading as per (i) above.
											</p>
											<Spacer size={12} />
											<p style={{ ...paragraphStyles }}>
												Such variance shall be settled
												at the contract price.
											</p>
										</li>
									</ol>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={2}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>QUALITY</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										At time and place of shipment, the oil
										shall be of good merchantable quality
										within the meaning of the description
										and specifications stated in the
										preamble. Unless so stated in the
										preamble or otherwise agreed between the
										parties and confirmed in writing, the
										oil is not warranted to be suitable for
										any specific end-use or to conform to
										any particular analytical criteria not
										mentioned above.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={3}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>SHIPMENT</h3>
								</Box>
								<Box width={500}>
									<h3 style={h3Styles}>
										<span
											style={{
												marginRight: 20,
												fontWeight: 'normal',
											}}
										>
											i)
										</span>
										Suitable Freight Space
									</h3>
									<Spacer size={12} />
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										Clean and fit freight space to be
										provided by Buyer. In the event that the
										vessel is found unsuitable by an
										independent surveyor licensed by the
										Malaysian Palm Oil Board (MPOB) and
										appointed by Seller for the carriage of
										the cargo, Seller shall load cargo upon
										receipt of appropriate instruction to
										load from Buyer. Under such
										circumstances, Buyer to hold Seller free
										and harmless of all consequences.
									</p>
									<Spacer size={20} />
									<Box width={500}>
										<h3 style={h3Styles}>
											<span
												style={{
													marginRight: 16,
													fontWeight: 'normal',
												}}
											>
												ii)
											</span>
											Nomination of Vessel
										</h3>
										<Spacer size={12} />

										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												a)
											</p>
											<p>
												Buyer shall nominate to Seller
												name of vessel not less than
												fourteen (14) calendar days
												prior to vessel's expected time
												of arrival at load port. Such
												expected time of arrival shall
												not exceed a spread of seven (7)
												calendar days which shall be
												within the contract period
											</p>
										</Box>
										<Spacer size={12} />
										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												b)
											</p>
											<p>
												If Buyer fails to give fourteen
												(14) calendar days advance
												notice of shipment, Seller is
												not entitled to reject but is
												obliged to load the oil only
												fourteen (14) calendar days from
												the date of receipt of such
												notice on condition that such
												fourteen (14) calendar days is
												within the contract period.
											</p>
										</Box>
										<Spacer size={12} />
										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												c)
											</p>
											<p>
												If the nomination is received
												less than fourteen (14) calendar
												days prior to the last day of
												the contract period, Buyer is
												deemed to be in default and the
												provisions of the Default Clause
												shall apply.
											</p>
										</Box>
									</Box>
									<Spacer size={20} />
									<Box width={180}>
										<h3 style={h3Styles}>
											<span
												style={{
													marginRight: 14,
													fontWeight: 'normal',
												}}
											>
												iii)
											</span>
											Substitution of Vessel
										</h3>
										<Spacer size={12} />
									</Box>
									<Box width={500}>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											Buyer is allowed to substitute the
											nominated vessel provided that the
											vessel's expected time of arrival at
											load port remains unchanged.
											However, Buyer shall notify Seller
											such substitution at least forty
											eight (48) hours before the arrival
											of the substituted vessel. If the
											nominated vessel or the substitute
											vessel is delayed (but within the
											contract period) beyond the expected
											time of arrival to load by more than
											seventy two (72) hours, Buyer shall
											bear all the additional costs
											including extra storage, heating
											charges, overtime, interests and
											other incidentals as incurred. Once
											the nominated vessel is alongside
											the loading berth, no substitution
											of vessel shall be allowed without
											the consent of the Seller, unless
											the substitution is necessitated due
											to the rejection of the vessel or
											vessel’s tanks by the Buyer’s
											nominated surveyor or due to other
											factors beyond the Buyer’s control.
											In the event of such a substitution,
											which shall comply with other
											provisions of this contract relating
											to substitution, the Seller shall be
											entitled to claim additional costs,
											as incurred, from the Buyer.
										</p>
									</Box>
									<Spacer size={20} />
									<Box width={180}>
										<h3 style={h3Styles}>
											<span
												style={{
													marginRight: 14,
													fontWeight: 'normal',
												}}
											>
												iv)
											</span>
											Optional Load Port(s)
										</h3>
										<Spacer size={12} />
									</Box>
									<Box width={500}>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											Where the contract is subject to
											optional load ports, Seller to
											declare load port to his Buyer at
											least thirty (30) calendar days
											prior to the commencement of the
											shipment period. Should the last day
											of declaration falls on a
											non-business day, Seller shall
											declare not later than on the day
											prior to the non-business day.
											Should Buyer not receive load port
											declaration in time he cannot hold
											his Seller in default but shall have
											the option to declare the load port
											of his choice which Seller must
											accept.
										</p>
									</Box>
									<Spacer size={20} />
									<Box width={180}>
										<h3 style={h3Styles}>
											<span
												style={{
													marginRight: 14,
													fontWeight: 'normal',
												}}
											>
												v)
											</span>
											Cargo Readiness
										</h3>
										<Spacer size={12} />
									</Box>
									<Box width={500}>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											The Seller shall, at least two (2)
											calendar days before vessel’s
											expected time of arrival at
											loadport, confirm to the Buyer or to
											the Buyer’s vessel’s agent if so
											advised by the Buyer, that the cargo
											shall be ready to load in all
											respects on the day the vessel is
											expected to arrive at loadport.
										</p>
									</Box>
									<Spacer size={20} />
									<Box>
										<h3 style={h3Styles}>
											<span
												style={{
													marginRight: 14,
													fontWeight: 'normal',
												}}
											>
												vi)
											</span>
											Loading, Laytime and Demurrage
										</h3>
										<Spacer size={12} />
									</Box>
									<Box width={500}>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
												marginBottom: 12,
											}}
										>
											Seller undertakes to load the vessel
											and Buyer undertakes to receive the
											oil on board the vessel at a minimum
											average rate of 150 tonnes per hour.
										</p>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
												marginBottom: 12,
											}}
										>
											Laytime to commence when vessel is
											alongside the loading berth and is
											in all respects ready to receive the
											oil.
										</p>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
												marginBottom: 12,
											}}
										>
											All waiting time (i.e. from the time
											vessel is alongside the loading
											berth until the commencement of
											loading of the first parcel of oil
											and from the time of completion of
											loading upto hose-off) shall be
											pro-rated amongst all shippers/
											Sellers who are to load oil on the
											vessel.
										</p>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
												marginBottom: 12,
											}}
										>
											Demurrage for individual shipper/
											Seller shall not apply until the
											total number of hours allowed for
											total cargo loaded shall have
											lapsed. Demurrage shall not be
											charged twice in the event that more
											than one shipper/Seller exceeds the
											number of hours allowed to the total
											shipment.
										</p>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
												marginBottom: 12,
											}}
										>
											Demurrage incurred at any loadport
											shall be based on time allowed to
											total quantity loaded at that
											loadport irrespective of the number
											of shippers/Sellers and cargo types.
										</p>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
												marginBottom: 12,
											}}
										>
											In the event that loading is stopped
											due to non-availability of the oil
											of some shippers/Sellers, all time
											from the time of the commencement of
											such stoppage shall be deemed to be
											laytime used by such
											shippers/Sellers only.
											Notwithstanding other provisions
											herein, only such shippers/Sellers
											shall be responsible for all laytime
											used from the time of the said
											stoppage onwards and be liable to
											demurrage if the total laytime used
											by them shall exceed the total
											laytime allowed to their parcels. As
											a consequence of such stoppage, if
											the port authorities should require
											the vessel to vacate berth, the
											relevant Sellers shall be
											responsible for all additional
											laytime used and other costs as
											incurred by the Buyers or the vessel
											for shifting of the vessel out of
											berth and re-berthing.
										</p>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
												marginBottom: 12,
											}}
										>
											Demurrage if any shall be payable by
											the shippers/Sellers responsible for
											the delay.
										</p>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											Demurrage rate shall be as
											stipulated in the Fixture for the
											related voyage but shall not exceed
											the rate prevailing in the market on
											the date of the said Fixture for
											similar vessels.
										</p>
									</Box>
									<Spacer size={20} />
									<Box width={180}>
										<h3 style={h3Styles}>
											<span
												style={{
													marginRight: 14,
													fontWeight: 'normal',
												}}
											>
												vii)
											</span>
											Vessel's Dues
										</h3>
										<Spacer size={12} />
									</Box>
									<Box width={500}>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
												marginBottom: 12,
											}}
										>
											All expenses relating to vessel at
											load port including wharfage,
											dockage, quay charges, pilotage,
											port dues, tugs, any freight or
											transportation levy/tax imposed by
											the Malaysian Government and
											expenses of like nature incurred at
											loading port(s), shall be for
											account of Buyer.
										</p>
										<p
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											For the purpose of this contract,
											the words “vessel” or “vessels”
											shall mean any full-powered
											engine-driven vessel classified not
											lower than 100 A1 in Lloyds Register
											or its equivalent notation by
											similar Classification Society.
										</p>
									</Box>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={4}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>RELEASE OF BILLS </h3>
									<h3 style={h3Styles}>OF LADING </h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Before the date of loading, Buyer shall
										telex ship’s agents/ owners and Seller
										authorising ship's agents / owners to
										release Bill(s) of Lading direct to
										Seller. All Bill(s) of Lading shall be
										released to Seller within forty eight
										(48) hours or on the first business day
										after completion of loading whichever is
										later.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										In the event that the Bill(s) of Lading
										are not released to Seller as above,
										Seller, without prejudice to his other
										rights under the contract, may also
										claim interest from Buyer at the rate of
										1½% per month on the contract price
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										If Buyer fails to pay freight or any
										other charges incurred, Seller reserves
										the right to pay freight or such other
										charges, secure the freight prepaid
										Bill(s) of Lading and recover the cargo.
										Buyer to indemnify the Seller for all
										resulting losses/expenses
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={5}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>EXTENSION OF </h3>
									<h3 style={h3Styles}>LADING </h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										At the request of Buyer, the period of
										shipment shall be extended by an
										additional period not exceeding eight
										(8) calendar days provided notice is
										given to Seller of his intention to
										claim such extension on or before the
										last shipment day of the the contract
										period. Buyer shall provide satisfactory
										evidence that such delayed vessel was
										originally booked with layday/cancelling
										within the original contract period.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										For such late shipment, Buyer shall pay
										a penalty to Seller for late
										presentation of vessel as follows:-
									</p>
									<Spacer size={12} />
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										½% for 1, 2, 3, or 4 days
									</p>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										1% for 5 or 6 days
									</p>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										1½% for 7 or 8 days
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Should Buyer claim the Extension of
										Shipment Clause and the vessel fails to
										complete loading within such eight (8)
										calendar days, the original contract
										period shall be deemed to have been
										extended by eight (8) calendar days and
										the contract price increased by 1½%. On
										the determination of penalty, the
										extended day shall be on the basis of
										completion of loading.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Should Buyer not claim the above
										extension and fail to present vessel
										within the contract period, any penalty
										whether arrived at by amicable
										settlement or arbitration shall not be
										related to the penalty of this clause.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Should the vessel be at berth but
										loading has not completed within the
										extended period of eight (8) calendar
										days and loading has not been
										interrupted for any fault of the Buyer,
										then the maximum late shipment penalty
										that shall apply shall be 1½% as
										applicable for a delay of eight (8)
										calendar days even if the loading
										completes after such eight (8) calendar
										days. Should the vessel have arrived at
										the port of loading and tendered notice
										of readiness within the eight (8)
										calendar days of the extension period
										but is not able to berth due to port
										congestion or other reasons for which
										the Buyer is not at fault, and
										consequently loading has not completed
										within the extended period of eight (8)
										calendar days, then the maximum late
										shipment penalty that shall apply shall
										be 1½% as applicable for a delay of
										eight (8) calendar days even if the
										berthing and/or loading completes after
										such eight (8) calendar days.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={6}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>EXPORT DUTY </h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Buyer shall be responsible for any
										increase in export duty in the port of
										loading, incurred under the following
										circumstances:-
									</p>
									<Spacer size={12} />
									<ol>
										<li data-list-text="i)">
											<p style={{ ...paragraphStyles }}>
												<span>
													i)&nbsp;&nbsp;&nbsp;
												</span>
												late presentation of vessel
												outside shipment period; and
											</p>
										</li>
										<Spacer size={12} />
										<li data-list-text="ii)">
											<p style={{ ...paragraphStyles }}>
												<span>
													ii)&nbsp;&nbsp;&nbsp;
												</span>
												if vessel should complete
												loading outside the shipment
												period
											</p>
										</li>
									</ol>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={7}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>SHIPPING</h3>
									<h3 style={h3Styles}>INSTRUCTIONS</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Buyer shall provide Seller with the
										following shipping instructions at least
										four (4) calendar days before the
										estimated time of arrival of the
										vessel:-
									</p>
									<Spacer size={12} />
									<ol>
										<li data-list-text="i)">
											<p style={{ ...paragraphStyles }}>
												<span>
													i)&nbsp;&nbsp;&nbsp;
												</span>
												Name of vessel's agent
											</p>
										</li>
										<li data-list-text="ii)">
											<p style={{ ...paragraphStyles }}>
												<span>ii)&nbsp;&nbsp;</span>
												Destination of cargo
											</p>
										</li>
										<li data-list-text="iii)">
											<p style={{ ...paragraphStyles }}>
												<span>iii)&nbsp;</span>
												Whether or not commingling is
												permitted
											</p>
										</li>
										<li data-list-text="iv)">
											<p style={{ ...paragraphStyles }}>
												<span>iv)&nbsp;&nbsp;</span>
												Notifying party
											</p>
										</li>
										<li data-list-text="v)">
											<p style={{ ...paragraphStyles }}>
												<span>
													v)&nbsp;&nbsp;&nbsp;
												</span>
												Demurrage rate as per Charter
												Party
											</p>
										</li>
										<li data-list-text="vi)">
											<p style={{ ...paragraphStyles }}>
												<span>vi)&nbsp;&nbsp;</span>
												Buyer’s banker’s name and
												address in event of payment
												being cash on presentation of
												documents.
											</p>
										</li>
										<Spacer size={12} />
										<p style={{ ...paragraphStyles }}>
											If instructions are not given as to
											whether commingling is permitted,
											Seller to load oil into tanks as
											allocated by vessel. In such event,
											Buyer shall be responsible for all
											consequences.
										</p>
									</ol>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={8}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>DESTINATIONS</h3>
									<h3 style={h3Styles}>EXCLUDED</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										It is mutually agreed that the final
										destination of the cargo shipped from
										Malaysian ports shall not be any
										destinations so declared prohibitive by
										the Government of Malaysia from time to
										time.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={9}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>INSURANCE</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Buyer is deemed to have covered adequate
										Marine Insurance on All Risks before
										arrival of the vessel at loading port.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={10}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>WEIGHTS</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Weights as ascertained from calibrated
										shore tanks at time of shipment and duly
										certified by an independent surveyor
										appointed by Seller shall be final.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Buyer however has the right to be
										represented at his own costs at time of
										shipment, in which case, weights shall
										be established conjointly.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={11}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>SAMPLING</h3>
									<h3 style={h3Styles}>ANALYSIS</h3>
								</Box>
								<Box width={500}>
									<ol>
										<li data-list-text="i)">
											<Box row>
												<p
													style={{
														...paragraphStyles,
														marginRight: 16,
													}}
												>
													i)
												</p>

												<p
													style={{
														...paragraphStyles,
													}}
												>
													Representative composite
													samples in quadruplicate (1
													for contractual analysis,1
													for the Shipper and 2 to be
													retained by the surveyor) of
													the oil shall be drawn from
													ship’s tank(s) at time of
													shipment by an independent
													surveyor appointed by
													Seller, in accordance with
													the MPOB/FOSFA
													International/ PORAM
													Processed Palm Oil Storage,
													Transportation, Sampling,
													and Survey Guide.
												</p>
											</Box>
										</li>
										<Spacer size={12} />
										<li data-list-text="ii)">
											<Box row>
												<Box>
													<p
														style={{
															...paragraphStyles,
															marginRight: 13,
														}}
													>
														ii)
													</p>
												</Box>
												<Box>
													<p
														style={{
															...paragraphStyles,
														}}
													>
														Buyer has the right to
														be represented at his
														own cost at time of
														shipment, to draw and
														seal samples conjointly
														with Seller’s
														representatives. If
														Buyer is so represented,
														only samples drawn and
														sealed conjointly shall
														be accepted as official
														contract samples for
														analysis.
													</p>
													<Spacer size={12} />
													<p
														style={{
															...paragraphStyles,
														}}
													>
														The samples so drawn
														shall be analysed by an
														independent laboratory
														licensed by MPOB and
														appointed by the
														surveyor(s), whose
														analysis shall be final
														and who shall issue the
														appropriate certificate.
														The Seller’s surveyor
														shall hold the sealed
														samples for a minimum
														period of ninety (90)
														calendar days
													</p>
												</Box>
											</Box>
										</li>
										<Spacer size={12} />
										<li data-list-text="iii)">
											<Box row>
												<p
													style={{
														...paragraphStyles,
														marginRight: 9,
													}}
												>
													iii)
												</p>

												<p
													style={{
														...paragraphStyles,
													}}
												>
													In the event that the
													conditions of tanks/
													pipelines/ valves or other
													equipment on board the
													vessel which come into
													direct contact with the
													cargo are not found suitable
													by the surveyor(s) for the
													carriage of the cargo, the
													samples as drawn from shore
													tanks at time of shipment
													shall be final and binding
													for the purposes of this
													contract.
												</p>
											</Box>
										</li>
										<Spacer size={12} />
										<li data-list-text="iv)">
											<Box row>
												<p
													style={{
														...paragraphStyles,
														marginRight: 10,
													}}
												>
													iv)
												</p>
												<p
													style={{
														...paragraphStyles,
													}}
												>
													In the event the cargo is to
													be commingled on board the
													vessel, the provision for
													sampling and analysis in
													(iii) above shall apply.
												</p>
											</Box>
										</li>
									</ol>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={12}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>PAYMENT</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Payment shall be made as stipulated in
										the preamble for 100% of the invoice
										value against a complete set of shipping
										documents.
									</p>
									<Spacer size={12} />
									<ol>
										<li>
											<Box row={!isMobile}>
												<p
													style={{
														...paragraphStyles,
														marginRight: 16,
													}}
												>
													*a)
												</p>

												<p
													style={{
														...paragraphStyles,
													}}
												>
													by irrevocable and confirmed
													Letter of Credit,
													unrestricted for negotiation
													established in Seller’s
													favour through a recognised
													bank for 103% of the mean
													contract quantity. Unless
													otherwise agreed between the
													parties, such credit shall
													be advised and made
													available to Seller not
													later than ten (10) calendar
													days from date of contract.
													Should the Letter of Credit
													be opened on terms
													inconsistent with the
													contract, Seller may demand
													amendments which shall be
													arranged by Buyer and
													notified to Seller through
													the credit opening bank
													within seven (7) calendar
													days of the demand being
													received but in no case
													later than the day prior to
													commencement of loading. The
													Letter of Credit shall
													provide for the negotiating
													bank to claim reimbursement
													by telex/cable from the
													credit opening bank upon
													confirmation that all
													documents conform to the
													credit requirements and the
													credit opening bank to make
													payment value dated on date
													of such claim being made.
													The expiration of the credit
													shall be at least fifteen
													(15) calendar days beyond
													the latest shipping date
												</p>
											</Box>
										</li>
										<Spacer size={12} />
										<li>
											<Box row>
												<p
													style={{
														...paragraphStyles,
														marginRight: 16,
													}}
												>
													*b)
												</p>

												<p
													style={{
														...paragraphStyles,
													}}
												>
													by cash on presentation of
													documents
												</p>
											</Box>
										</li>
									</ol>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Seller shall present documents to Buyer
										through a bank nominated by Buyer. All
										bank charges at Seller's bank are for
										Seller's account and all bank charges at
										Buyer's bank are for Buyer's account.
										Buyer shall arrange payment by
										telegraphic transfer (TT) at Buyer’s
										cost
									</p>
									<Spacer size={12} />
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={13}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>INTEREST</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										If payment is not made by due date,
										interest shall be payable at the rate of
										1½% per month
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Nothing in this clause shall affect a
										party's right to invoke the provisions
										of the Default Clause in a case where a
										failure to effect timely payment could
										give rise to a claim under that clause.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={14}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>UNASCERTAINED</h3>
									<h3 style={h3Styles}>GOODS</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										In every instance where a parcel of
										goods paid for under this contract forms
										an unidentified part of a larger
										identified quantity of goods of the same
										description, whether in packages or in
										bulk, no separation or distinction shall
										be necessary and, until separation and
										identification of the parcel paid for
										hereby from the larger quantity has
										taken place, the Buyer of the parcel is
										a pro rata owner of the whole of the
										larger quantity in common with Seller/s
										and Buyer/s of other parts of the larger
										quantity
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={15}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>SHIPPING</h3>
									<h3 style={h3Styles}>DOCUMENTS</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Unless otherwise agreed, shipping
										documents shall consist of the following
										in triplicate :-
									</p>
									<Spacer size={12} />
									<ol>
										<li data-list-text="1">
											<p style={{ ...paragraphStyles }}>
												<span>
													1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												</span>
												Commercial invoice;
											</p>
										</li>
										<Spacer size={12} />
										<li data-list-text="ii)">
											<p style={{ ...paragraphStyles }}>
												<span>
													2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												</span>
												Full set of clean "On Board"
												Bill(s) of Lading identifying
												vessels' tanks into which
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												the goods have been loaded;
											</p>
										</li>
										<Spacer size={12} />
										<li data-list-text="iii)">
											<p style={{ ...paragraphStyles }}>
												<span>
													3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												</span>
												Certificate of shipped weights
												ascertained at port of loading
												and issued by an
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												independent surveyor;
											</p>
										</li>
										<Spacer size={12} />
										<li data-list-text="iv)">
											<p style={{ ...paragraphStyles }}>
												<span>
													4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												</span>
												Survey report issued by an
												independent surveyor, certifying
												fitness and cleanliness
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												of the ship's tanks and
												pipelines and particulars as to
												time and place of loading,
												&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sampling
												and establishment of the shipped
												weight;
											</p>
										</li>
										<Spacer size={12} />
										<li data-list-text="v)">
											<p style={{ ...paragraphStyles }}>
												<span>
													5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												</span>
												Certificate of Analysis, issued
												by an independent laboratory;
											</p>
										</li>
										<Spacer size={12} />
										<li data-list-text="vi)">
											<p style={{ ...paragraphStyles }}>
												<span>
													6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												</span>
												Certificate of Origin.
											</p>
										</li>
										<Spacer size={20} />
										<p style={{ ...paragraphStyles }}>
											Buyer is to accept certified
											photostatic copy(ies) on items (3),
											(4) and (5) relating to the whole
											parcel(s).
										</p>
										<Spacer size={20} />
										<p style={{ ...paragraphStyles }}>
											In the event the surveyor is unable
											to certify the fitness and
											cleanliness of the ship's tanks and
											pipelines due to some cargo being
											present in the ship's tanks before
											commencement of loading, the
											surveyor's certificate certifying
											fitness and cleanliness of the
											ship's tanks and pipelines shall not
											form part of the documents as
											required in (4) above.
										</p>
										<Spacer size={20} />
										<p style={{ ...paragraphStyles }}>
											Should documents be presented with
											incomplete set(s) of Bill(s) of
											Lading, payment shall be made
											provided that delivery of such
											Bill(s) of Lading be guaranteed by
											Seller. Such guarantee to be
											endorsed, if required by Buyer, by a
											recognised bank. Acceptance of this
											guarantee shall not prejudice
											Buyer’s rights under the contract.
										</p>
									</ol>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={16}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>STRINGS</h3>
								</Box>

								<Box width={500}>
									<h3 style={h3Styles}>
										<span
											style={{
												marginRight: 16,
												fontWeight: 'normal',
											}}
										>
											i)
										</span>
										<span
											style={{
												textDecoration: 'underline',
											}}
										>
											Establishment of String
										</span>
									</h3>
									<Spacer size={12} />

									<Box
										row
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										<p
											style={{
												marginRight: 28,
												fontWeight: 'normal',
											}}
										>
											a.
										</p>
										<p>
											Where the goods forming the basis of
											this contract are purchased by
											subsequent Buyer(s) such that
											end-shipper and end-receiver and
											each intermediate party are separate
											entities and once vessel nomination
											has been passed on within the
											provisions of the Shipment Clause to
											all parties in the string, a string
											shall be deemed to have been
											established provided all parties in
											the string have contracted with each
											other on the basis of PORAM/MEOMA
											FOB in Bulk Contract (Contract No.
											2). The establishment of the string
											shall for all intents and purposes
											be to facilitate the performance of
											the contracts and is without
											prejudice to the rights and
											obligations of the respective Buyers
											and Sellers to one another. For the
											purpose of this clause, the same
											goods shall mean goods of the same
											quantity, of the same description,
											of the same country of origin, of
											the same contractual quality for
											shipment from the same port of
											loading during the same period of
											shipment.
										</p>
									</Box>
									<Spacer size={12} />
									<Box
										row
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										<p
											style={{
												marginRight: 28,
												fontWeight: 'normal',
											}}
										>
											b.
										</p>
										<p>
											Unless otherwise agreed to by the
											end-receiver in the string, which
											agreement shall not be unreasonably
											withheld, the formation of a string
											shall be completed at least four (4)
											calendar days before the vessel’s
											nominated expected time of arrival
											(ETA) at loadport. This clause does
											not in any way supercede the
											provisions of clause 3(ii) herein
											for Nomination of Vessel. The
											end-shipper as appearing in such
											string shall be deemed to be the
											party responsible for loading the
											goods.
										</p>
									</Box>
									<Spacer size={12} />
									<Box
										row
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										<p
											style={{
												marginRight: 28,
												fontWeight: 'normal',
											}}
										>
											c.
										</p>
										<p>
											The end-Buyer and the end-Seller
											shall then give all notices directly
											to each other with copies also being
											extended to their immediate
											contracting party who shall then
											pass the notice down or up the
											string accordingly. Notices so
											received by the end-Buyer or the
											end-Seller shall be deemed to have
											been received from their respective
											Buyer or Seller, as the case maybe.
											This procedure for passing notices
											is only to facilitate and expedite
											the giving and receipt of notices
											and does not absolve any party in
											the string from their other
											obligations to their respective
											Buyer of Seller, as the case may be.
										</p>
									</Box>
									<Spacer size={12} />
									<Box>
										<h3 style={h3Styles}>
											<span
												style={{
													marginRight: 16,
													fontWeight: 'normal',
												}}
											>
												ii)
											</span>
											<span
												style={{
													textDecoration: 'underline',
												}}
											>
												String-Defaulter
											</span>
										</h3>
										<Spacer size={12} />

										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												a.
											</p>
											<p>
												The Buyer and the Seller hereby
												acknowledge and agree that where
												a string has been established
												pursuant to this clause the
												Buyer and Seller shall have the
												rights and obligations as stated
												in the following paragraphs (ii)
												(b) and (ii) (c) of this clause,
												vis-à-vis the immediate Seller
												or Buyer of the String-Defaulter
												as hereafter defined, which
												shall be enforceable by or
												against the Buyer or the Seller
												as though the immediate Seller
												or Buyer of the String-Defaulter
												was a contracting party with the
												Buyer or Seller as the case may
												be.
											</p>
										</Box>
										<Spacer size={12} />
										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												b.
											</p>
											<p>
												After the cargo is loaded,
												should any party in the string
												commit, prior to the receipt of
												the Shipping Documents by the
												end-receiver in the string, any
												act comprehended in the
												Bankruptcy Clause, or is
												declared to be in default by its
												immediate Buyer or Seller, a
												closing-out price as provided
												for in the Bankruptcy Clause
												shall be applied to the
												contracts of such party
												(“String-Defaulter”) with its
												immediate Seller and Buyer. The
												immediate Seller to the
												String-Defaulter shall then
												present documents to the
												immediate Buyer from the
												String-Defaulter at such
												closing-out price. If a document
												by-pass had been agreed to
												earlier, such document by-pass
												shall continue to be effective
												and such closing-out price shall
												be used for price difference
												settlement only between the
												immediate Seller to and Buyer
												from the String-Defaulter
												instead of their actual contract
												prices with the
												String-Defaulter.
											</p>
										</Box>
										<Spacer size={12} />
										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												c.
											</p>
											<p>
												The immediate Seller to and
												Buyer from the String-Defaulter
												shall then claim from or pay to
												the String-Defaulter based upon
												the difference of their
												respective contract prices and
												such closing-out price. If
												either of the immediate Seller
												or immediate Buyer is required
												to claim from the
												String-Defaulter and the other
												is required to pay to the
												String-Defaulter then the
												immediate Seller or Buyer who is
												required to pay shall first pay
												to the other the amount payable
												by the String-Defaulter as
												herein above. If there is any
												surplus left after such payment,
												it shall then be paid to the
												String-Defaulter. In the event
												of there being more than one
												StringDefaulter in any string,
												all the provisions contained
												herein for settlement between
												the String Defaulter and its
												immediate Buyer and Seller shall
												apply to contracts of each such
												String-Defaulter
											</p>
										</Box>
									</Box>
									<Spacer size={12} />
									<Box>
										<h3 style={h3Styles}>
											<span
												style={{
													marginRight: 16,
													fontWeight: 'normal',
												}}
											>
												iii)
											</span>
											<span
												style={{
													textDecoration: 'underline',
												}}
											>
												Document By-Pass
											</span>
										</h3>
										<Spacer size={12} />

										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												a.
											</p>
											<p>
												When a string has been
												established as herein provided,
												a document bypass may take place
												whereby the end-shipper shall
												present documents to
												end-receiver. If any party in
												the string does not agree to a
												document bypass, then the
												documents shall pass down the
												string. Without prejudice to the
												rights and obligations of
												respective Buyers and Sellers to
												each other, the first Seller
												shall invoice the last Buyer on
												the basis of the selling price
												of the first Seller and last
												Buyer to pay accordingly. If the
												end-Buyer does not agree to
												accept documents at the first
												Seller’s selling price, then the
												documents shall pass down the
												string.
											</p>
										</Box>
										<Spacer size={12} />
										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												b.
											</p>
											<p>
												Upon receipt of documents, the
												end-receiver shall effect
												immediate payment and confirm to
												its immediate Seller that the
												documents are in order,
												whereupon each party in the
												string shall immediately notify
												its Seller of the same.
											</p>
										</Box>
										<Spacer size={12} />
										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												c.
											</p>
											<p>
												Each Buyer shall make payment to
												its Seller or each Seller shall
												make payment to its Buyer for
												the difference between its
												buying and selling prices
												immediately upon receipt of
												confirmation that the documents
												are in order. Where Malaysian
												parties are involved, the
												settlement is to be in Malaysian
												Ringgit, the conversion to be
												based on the mean exchange rate
												established by PORAM on the date
												of the Bill(s) of Lading.
											</p>
										</Box>
										<Spacer size={12} />
										<h3 style={h3Styles}>
											<span
												style={{
													marginRight: 16,
													fontWeight: 'normal',
												}}
											>
												iv)
											</span>
											<span
												style={{
													textDecoration: 'underline',
												}}
											>
												General
											</span>
										</h3>
										<Spacer size={12} />

										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												a.
											</p>
											<p>
												If any contract in the string is
												on the basis of any quality
												adjustment, payment for such
												quality adjustment shall also be
												made along with payment and
												settlement as above
											</p>
										</Box>
										<Spacer size={12} />
										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												b.
											</p>
											<p>
												Where contracts in the string
												are on the basis of different
												currencies, the exchange rate
												applicable for the conversion
												into a common currency
												(conversion into Malaysian
												Ringgit where one of the
												contract is in Malaysian
												Ringgit) shall be the mean
												exchange rate established by
												PORAM on the date of Bill(s) of
												Lading.
											</p>
										</Box>
										<Spacer size={12} />
										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												c.
											</p>
											<p>
												For the purpose of abundant
												clarification, it is further
												stipulated that in the event of
												a breakdown of the string prior
												to the cargo being loaded,
												respective parties in the string
												shall have full recourse to and
												be fully responsible to their
												immediate Buyer or Seller as if
												the string had never been
												established.
											</p>
										</Box>
										<Spacer size={12} />
										<Box
											row
											style={{
												...paragraphStyles,
												marginLeft: 30,
											}}
										>
											<p
												style={{
													marginRight: 28,
													fontWeight: 'normal',
												}}
											>
												d.
											</p>
											<p>
												In the event of a dispute of a
												common nature arising between
												the parties in a String, such
												disputes (“String Dispute/s”)
												shall be referred to arbitration
												(“String Arbitration”) as per
												the provisions of the
												Arbitration Clause herein.
												Parties hereto agree to submit
												to such String Arbitration
											</p>
										</Box>
									</Box>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={17}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>CIRCLES</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Where a Seller repurchases from his
										Buyer or from any subsequent Buyer(s)
										the same goods, a circle shall be deemed
										to have been established.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										For the purpose of this contract, a
										circle shall be deemed to have been
										established prior to vessel's nomination
										when all parties to the circle are
										identified and aware of the circle's
										existence or when the vessel's
										nomination has been passed by a Buyer to
										a subsequent party in a string and the
										same nomination is received back by the
										Buyer, always provided that the
										nomination is received back by the Buyer
										fourteen (14) calendar days prior to the
										vessel's arrival at load port.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Upon the establishment of such a circle,
										the provisions of the Default Clause
										shall not apply. For the purpose of this
										clause, the same goods shall mean goods
										of the same quantity, of the same
										description, of the same country of
										origin, of the same contractual quality
										for shipment from the same port of
										loading during the same period of
										shipment.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										If the goods are not tendered, or having
										tendered, documents are not presented as
										a result of a circle having been
										established, invoices based on the
										contract quantity shall be settled
										between each Buyer and his Seller in the
										circle by payment of each Buyer to his
										Seller of the excess of the Seller’s
										invoice amount over the lowest amount in
										the circle.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Without prejudice to the rights and
										obligations of respective Buyer and
										Seller to each other, such settlement
										shall be due for payment on the 15th.
										day from the date of the establishment
										of the circle irrespective of the
										shipment month.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Should any party in the circle commit,
										prior to the due date for payment any
										act comprehended in the Bankruptcy
										Clause, the invoice amount for the goods
										calculated at the closing-out price as
										provided for in the Bankruptcy Clause
										shall be taken as the basis of
										settlement instead of the lowest amount
										in the circle, and in this event, each
										Buyer shall make payment to his Seller
										or each Seller shall make payment to his
										Buyer of the difference between the
										closing-out price and the contract
										price, as the case may be.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Unless otherwise agreed by the parties
										concerned, where the Sale and Purchase
										contracts involving the same party(ies)
										in the circle are on the basis of
										different currencies, the exchange rate
										applicable for the conversion into a
										common currency (conversion into
										Malaysian Ringgit where one of the
										contract is in Malaysian Ringgit) shall
										be the mean exchange rate established by
										PORAM on the date of the establishment
										of the circle.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={18}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>NOTICE</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Where the terms of the contract require
										notice to be given, such notice shall be
										transmitted or sent by cable, telegram,
										telex, facsimile, email, post or courier
										service or delivered by hand within the
										time limit specified in the contract.
										All notices shall be under reserve for
										errors in transmission or delivery. Any
										notice received after 1600 hours
										Malaysian time on a business day shall
										be deemed to have been received on the
										following business day.
									</p>
									<Spacer size={12} />
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={19}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>NON-BUSINESS</h3>
									<h3 style={h3Styles}>DAY</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Should the time limit for doing any act
										or giving any notice expire on a
										Saturday Sunday or any National Gazetted
										Public Holiday in Malaysia, the time so
										limited shall be extended until the
										first business day thereafter or as
										provided for in the Payment Clause.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										All business days shall be deemed to end
										1600 hours Malaysian time Monday to
										Friday inclusive. This clause shall not
										be applicable to the contract period.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={20}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>ODD DAYS</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										In any month containing an odd number of
										days, the middle day shall be reckoned
										as belonging to both halves of the
										month.
									</p>
									<Spacer size={12} />
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={21}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>PROHIBITION</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										In the event, during the contract
										period, of prohibition of export or any
										other executive or legislative act by or
										on behalf of the Government of the
										country of origin or of the territory
										where the port(s) of shipment named
										herein is/are situated , or of blockade
										or hostilities, restricting export
										whether partially or otherwise, any such
										restriction shall be deemed by both
										parties to apply to this contract and to
										the extent of such total or partial
										restriction to prevent fulfilment
										whether by shipment or by any other
										means whatsoever and to the extent this
										contract or any unfulfilled portion
										thereof shall be extended by thirty (30)
										calendar days. Seller shall advise Buyer
										with due despatch.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										In the event of shipment during the
										extended period still proving impossible
										by reason of any of the causes in this
										clause, the contract or any unfulfilled
										part thereof shall be cancelled. Seller
										invoking this clause shall advise Buyer
										with due despatch. If required Seller
										must produce proof to justify his claim
										for extension or cancellation under this
										clause.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={22}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>FORCE MAJEURE </h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Should the performance of the contract
										be prevented by reason of fire, strikes,
										lock-outs, riots, civil commotion and/or
										any cause comprehended in the term force
										majeure at the ports of loading, the
										contract period shall be extended for a
										period equal to the duration of the
										disabling circumstances but not
										exceeding a period of sixty (60)
										calendar days. If the force majeure
										event ends within twenty one (21)
										calendar days preceding the end of the
										extended period, then a further twenty
										one (21) calendar days shall be allowed
										after the termination of the force
										majeure event.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Should the fulfilment of the contract
										not be possible within the extended
										period, the contract or any unfulfilled
										part thereof so affected shall be deemed
										to be null and void at the end of such
										extended period.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={23}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>BANKRUPTCY</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										If before the fulfilment of this
										contract either party shall suspend
										payment, commit an act of bankruptcy,
										notify any of his creditors that he is
										unable to meet his debts or that he has
										suspended payments or that he is about
										to suspend payment of his debts,
										convene, call or hold a meeting either
										of his creditors or to pass a resolution
										to go into liquidation (except for a
										voluntary winding up of a solvent
										company for the purpose of
										reconstruction or amalgamation) or shall
										apply for an official moratorium, have a
										petition presented for winding up or
										shall have a Receiver appointed, he
										shall be deemed to be and shall be
										treated as being at default and the
										contract shall forthwith be closed,
										either at the market price then current
										for similar goods or, at the option of
										the other party, at a price to be
										ascertained by repurchase or resale and
										the difference between the contract
										price and such closing-out price shall
										be the amount which the other party
										shall be entitled to claim or shall be
										liable to account for under this
										contract. Where no such resale or
										repurchase takes place, the closing-out
										price shall be determined by a Price
										Settlement Committee of The Palm Oil
										Refiners Association of Malaysia/The
										Malayan Edible Oil Manufacturers'
										Association as the case may be. Should
										either party be dissatisfied with the
										price, the matter should be referred to
										arbitration
									</p>
									<Spacer size={12} />
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={24}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>DEFAULT</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										In default of fulfilment of the contract
										by either party, the other party at his
										discretion shall after giving notice,
										have the right either to cancel the
										contract or the right to sell or
										purchase, as the case may be, against
										the defaulter who shall on demand make
										good the loss, if any, on such sale or
										purchase. If the party liable to pay,
										shall be dissatisfied with the price of
										such sale or purchase, or if neither of
										the above right is exercised, the
										damages if any, shall, failing amicable
										settlement be determined by arbitration.
										The damages awarded against the
										defaulter shall be limited to the
										differences between the contract price
										and the market price on the day of the
										default but if the Arbitrator(s)
										consider the circumstances of the
										default justify it, they may at their
										absolute discretion, award additional
										damages.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Prior to the last day of making a
										nomination of shipment, Seller/Buyer may
										notify his Buyer/Seller of his inability
										to ship/receive but the date of such
										notice shall not become the default date
										without the agreement of the other
										party. If, for any other reason, either
										party fails to fulfil his contract and
										is declared to be in default by the
										other party and default is either agreed
										between the parties or subsequently
										found by Arbitrator(s) to have occurred,
										then the day of the default shall,
										failing amicable settlement, be decided
										by arbitration
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={25}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>DOMICILE</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										The contract shall be deemed to have
										been made in Malaysia and the
										construction, validity and performance
										thereof shall be governed in all
										respects by Malaysian law, which shall
										have exclusive jurisdiction wherever the
										domicile, residence or place of business
										of the parties to this contract may be
										or become.
									</p>
									<Spacer size={12} />
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<li style={liStyles} data-list-text={26}>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={180}>
									<h3 style={h3Styles}>ARBITRATION</h3>
								</Box>
								<Box width={500}>
									<p style={{ ...paragraphStyles }}>
										Any dispute or String dispute arising
										out of this contract, including any
										question of law arising in connection
										therewith, shall be referred to
										arbitration in Malaysia (at the PORAM
										Secretariat or elsewhere if so agreed),
										in accordance with the PORAM Rules of
										Arbitration and Appeal in force at the
										date of the initiation of the
										arbitration with the parties hereby
										agreeing that the arbitration shall be
										deemed an International Arbitration. The
										seat of the arbitration shall be
										Malaysia and the Malaysian Arbitration
										Act 2005 (“the Act”) or any reenactment
										thereof shall apply to any arbitration
										under this contract. The parties hereto
										further agree that Part III of the Act
										shall not apply to any arbitration under
										this contract.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										The parties hereto agree to be bound by
										any Award, String Award or String Appeal
										Award made in the case of a String
										Arbitration or String Appeal held in
										accordance with the PORAM Rules of
										Arbitration and Appeal in so far as
										disputes of a common nature are
										concerned, whether or not the parties
										hereto have participated or otherwise
										made submissions in the String
										Arbitration as String Respondents.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										If any dispute arising out of this
										contract raises issues which are
										substantially the same as or connected
										with issues raised within the
										arbitration agreement in any of the
										contracts in a string (“the related
										dispute”) and if the related dispute has
										already been referred to arbitration in
										accordance with PORAM’s Rules of
										Arbitration and Appeal, then all
										contracting parties in the string agree
										that the dispute between them shall be
										referred to the same arbitral tribunal
										appointed by PORAM to hear the related
										dispute.
									</p>
									<Spacer size={12} />
									<p style={{ ...paragraphStyles }}>
										Neither party hereto, nor any persons
										claiming under either of them shall
										bring any action or other legal
										proceedings against the other of them in
										respect of any such dispute until such
										dispute shall first have been heard and
										determined by the Sole Arbitrator/Panel
										of Arbitrators/ Appeal Board (as the
										case may be), in accordance with the
										PORAM Rules of Arbitration and Appeal
										and it is hereby expressly agreed and
										declared that the obtaining of an award
										from the Sole Arbitrator/Panel of
										Arbitrators/Appeal Board (as the case
										may be), shall be a condition precedent
										to the right of either party hereto or
										of any person claiming under either of
										them to bring any action or other legal
										proceedings against the other of them in
										respect of such dispute.
									</p>
								</Box>
							</Box>
						</li>
						<Spacer size={20} />
						<Box row={!isMobile} justifyContent="space-between">
							<Box width={180}>
								<h3 style={h3Styles}>PORAM/MEOMA</h3>
							</Box>
							<Box width={500}>
								<p style={{ ...paragraphStyles }}>
									Wherever "PORAM/MEOMA as the case may be" is
									indicated in the contract, it is to be
									construed as reference to the Palm Oil
									Refiners Association of Malaysia only in
									respect of Palm Oil Products and to the
									Malayan Edible Oil Manufacturers'
									Association only in respect of Palm Kernel
									Oil Products.
								</p>
								<Spacer size={12} />
							</Box>
						</Box>
					</ol>
					<Box
						row={!isMobile}
						justifyContent="space-between"
						margin="28px 0 0"
					>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Seller:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Broker:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Buyer:
						</p>
					</Box>
				</Box>
				<Box
					style={{
						width: isMobile ? 350 : 800,
						marginTop: 40,
						padding: 15,
						marginBottom: 40,
						alignItems: 'center',
						background: theme.color.white,
						justifyContent: 'space-between',
					}}
					row={!isMobile}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '60%',
							alignItems: 'end',
							marginRight: '5%',
							padding: '15px 10px',
							background: theme.color.white,
						}}
					>
						<SmallText
							style={{
								fontSize: 14,
								color: '#8D93A0',
								lineHeight: '19px',
								textAlign: 'justify',
							}}
						>
							{t('contracts.proceedToESignText')}
						</SmallText>
					</Box>

					<Button
						rounded
						label={t('contracts.proceedToESign')}
						reverse
						onClick={() => {
							if (contractAction === 'edit') {
								setShowModal(true)
							} else {
								dispatch(
									MarketPlaceDuc.creators.postContractDetails(
										contractDetails,
										documentType
									)
								)
							}
						}}
						extendStyles={{
							width: 266,
							background: '#3F56C4',
							color: theme.color.white,
						}}
					/>
				</Box>
				<DigitalContractMessage
					width={isMobile ? 350 : 800}
					margin="0 0 40px 0"
				/>
			</Box>
		</>
	)
}

export { PreviewContractNo2 }
