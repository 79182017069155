import { all, takeLatest, put } from 'redux-saga/effects'
import LogHelper from 'ui-marketplace-app/utils/logger'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { WebTourDuc } from './duc'

const logger = LogHelper('client:WebTourSaga')

function* UpdateTourStatus(action) {
	try {
		const { details, skipGlobalLoader } = action
		const { tourStatus } = details
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('WebTour'))
		yield put(WebTourDuc.creators.setTourStatus(true))
		if (tourStatus) {
			yield put(WebTourDuc.creators.setActiveTourModule('tdm'))
		} else {
			yield put(WebTourDuc.creators.setActiveTourModule(''))
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('WebTour'))
	}
}

export default function* WebTourSaga() {
	try {
		yield all([
			takeLatest(
				WebTourDuc.creators.UpdateTourStatus().type,
				UpdateTourStatus
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
