import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'ui-marketplace-app/modules/App/components/Error'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { PreviewContractNo1 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractNo1'
import { PreviewContractNo2 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractNo2'
import { PreviewContractNo3 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractNo3'
import { PreviewContractNo4 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractNo4'
import { PreviewContractNo5 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractNo5'
import { PreviewContractNo6 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractNo6'
import { PreviewContractNo7 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractNo7'
import { PreviewContractNo8 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractNo8'
import { PreviewContractNo9 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractNo9'
import { PreviewFosfa81 } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewFosfa81'

const modulesMap = {
	'contract-no-1': PreviewContractNo1,
	'contract-no-2': PreviewContractNo2,
	'contract-no-3': PreviewContractNo3,
	'contract-no-4': PreviewContractNo4,
	'contract-no-5': PreviewContractNo5,
	'contract-no-6': PreviewContractNo6,
	'contract-no-7': PreviewContractNo7,
	'contract-no-8': PreviewContractNo8,
	'contract-no-9': PreviewContractNo9,
	fosfa81: PreviewFosfa81,
}

const PreviewContractGenerator = () => {
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { documentType } = payload
	const Component = modulesMap[documentType] || NotFoundBlock

	return <Component />
}

export { PreviewContractGenerator }
