import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { SmallText } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import SuccessIcon from 'ui-lib/icons/success-round.svg'
import { useTranslation } from 'react-i18next'

const ESignContractSuccess = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule, documentReference } = payload

	return (
		<Box
			style={{
				width: '100vw',
				overflowY: 'scroll',
			}}
			center
			padding={8}
		>
			<Box center>
				<Spacer size={20} />
				<Box
					style={{
						width: '770px',
						height: 'fit-content',
						background: theme.color.white,
						borderRadius: '10px',
						boxShadow: '0px 3px 6px #00000029',
					}}
					padding={25}
					alignItems="center"
				>
					<IconWrapper color="#CE924B" size={70}>
						<Icon glyph={SuccessIcon} />
					</IconWrapper>
					<Spacer size={20} />
					<SmallText
						style={{
							color: '#2D2D2D',
							fontSize: '32px',
							lineHeight: '32px',
							width: '500px',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						All Set!
					</SmallText>
					<Spacer size={30} />
					<SmallText
						style={{
							color: '#555454',
							fontSize: '18px',
							lineHeight: '32px',
							textAlign: 'center',
						}}
					>
						{t('contracts.participantNotified')}
					</SmallText>
					<SmallText
						style={{
							color: '#555454',
							fontSize: '18px',
							lineHeight: '32px',
							textAlign: 'center',
						}}
					>
						{t('contracts.reviewSignedCopyText')}
					</SmallText>
					<Spacer size={20} />

					<Spacer size={30} />
					<Box
						style={{
							width: '410px',
						}}
					>
						<Button
							rounded
							label={t('common.proceed')}
							reverse
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.MARKETPLACE$CONTRACTS,
										{
											rootModule,
											action: 'view',
											documentReference,
										}
									)
								)
							}}
							extendStyles={{
								background: '#3F56C4',
								color: theme.color.white,
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export { ESignContractSuccess }
