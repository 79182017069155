import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import Loader from 'ui-marketplace/src/assets/loader.gif'
import { useTranslation } from 'react-i18next'

const ProcessingContract = () => {
	const { t } = useTranslation()

	return (
		<Box>
			<Box
				style={{
					marginTop: 200,
					justifyContent: 'center',
				}}
				row
			>
				<div>
					<img src={Loader} alt="Loader" />
				</div>
			</Box>
			<Box
				style={{
					textAlign: 'center',
				}}
			>
				{t('contracts.esignProcessing')} ...
			</Box>
		</Box>
	)
}

export { ProcessingContract }
