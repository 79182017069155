import React, { useState, useRef, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Drop } from 'grommet'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MessagesDuc } from 'ui-marketplace-app/modules/Messages/duc'
import { supplyChainModels } from 'ui-marketplace-app/modules/Admin/components/StorageDetails'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	RFQ_STATUS,
	STATUS_TEXT_COLOR,
	STATUS_BACKGROUND,
	MONTHS,
	SHIPMENT_PERIOD,
	CONTRACT_TYPE,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { KeyValueSegment } from 'ui-marketplace-app/shared/components/KeyValueSegment'
import { SummaryBlock } from 'ui-marketplace-app/shared/components/DocumentLead'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { ChatModalComponent } from 'ui-marketplace-app/modules/Messages/components/ChatModalComponent'
import { ShareRFQModal } from 'ui-marketplace-app/modules/MarketPlace/components/ShareRFQModal'
import { ExistingAccountsModal } from 'ui-marketplace-app/modules/MarketPlace/components/ExistingAccountsModal'
import { getIAMEndPoint } from 'ui-marketplace-app/config'
import request from 'ui-marketplace-app/utils/request'
import { Modal } from 'ui-lib/components/Modal'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { TabButtons } from 'ui-lib/components/TabButtons'
import {
	Button,
	ButtonWithNoBorder,
	ButtonIconWrapper,
} from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Label } from 'ui-lib/components/Label'
import { H2, H4, Text, P } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Box } from 'ui-lib/utils/Box'
import { FileUpload } from 'ui-lib/components/FileUpload'
import theme from 'ui-lib/utils/base-theme'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import InfoIcon from 'ui-lib/icons/info_black.svg'
import CheckCircleIcon from 'ui-lib/icons/check_circle_black.svg'
import HighlightOffIcon from 'ui-lib/icons/highlight_off_black.svg'
import ArrowForwardIcon from 'ui-lib/icons/arrow_forward_down.svg'
import ChatIcon from 'ui-lib/icons/chat_icon.svg'
import ClockIcon from 'ui-lib/icons/watch_later_black.svg'
import ShareIcon from 'ui-lib/icons/share.svg'
import Documents from 'ui-lib/icons/documents.svg'
import EditEllipsis from 'ui-lib/icons/editEllipsis.svg'
import DeleteIcon from 'ui-lib/icons/delete_outline.svg'
import EditIcon from 'ui-lib/icons/edit.svg'
import PlusIcon from 'ui-lib/icons/plus.svg'
import UploadIcon from 'ui-lib/icons/upload.svg'
import DownloadIcon from 'ui-lib/icons/file_download_black_24dp.svg'

const tableHeaderStyles = {
	background: '#C9F5E5',
	color: '#299F74',
	padding: 16,
}

const tableRowStyles = {
	border: '1px solid #CAD2DD',
	padding: 16,
}

const RFQ_STATUS$WICONS = [
	{
		icon: CheckCircleIcon,
		_status: 'accepted',
	},
	{
		icon: ClockIcon,
		_status: 'review',
	},
	{
		icon: HighlightOffIcon,
		_status: 'rejected',
	},
]

export const Wrapper = styled.div(p => ({
	margin: p.margin || 0,
	width: p.width || '740px',
	height: 'auto',
	background: '#fff1dd 0% 0% no-repeat padding-box',
	border: '1px solid #f09112',
	borderLeft: '10px solid #f09112',
	borderRadius: '6px',
	opacity: 1,
	color: '#c97b12',
	letterSpacing: 0,
	fontSize: '16px',
}))

const AttachmentMenu = ({
	t,
	data,
	getCurrentOrgID,
	setSelectedFile,
	setRenameModal,
	deleteFileAttachment,
}) => {
	const attachmentMenuRef = useRef()
	const [showAttachmentMenu, setShowAttachmentMenu] = useState(false)

	return (
		<>
			<Box
				overflow
				key={data?.id}
				alignItems="center"
				margin="30px 30px"
				width="100px"
			>
				<Box
					onClick={() => {
						window.open(getIn(data, ['meta', 'fullURL']), '_blank')
					}}
					style={{
						backgroundColor: '#ffeed4',
					}}
					alignItems="center"
					padding="30px 30px"
				>
					<Icon
						color="#9e797a"
						glyph={Documents}
						style={{
							height: 50,
							width: 40,
						}}
					/>
				</Box>
				{getCurrentOrgID === getIn(data, ['organizationID']) && (
					<Button
						rounded
						customIcon={
							<ButtonIconWrapper
								size={30}
								ref={attachmentMenuRef}
								lightBG
							>
								<Icon
									color={theme.color.grey15}
									glyph={EditEllipsis}
								/>
							</ButtonIconWrapper>
						}
						onClick={() => {
							setShowAttachmentMenu(true)
							setSelectedFile(data)
						}}
					/>
				)}
				<P color={theme.color.grey15}>{data?.meta?.name}</P>
			</Box>
			{showAttachmentMenu && (
				<Box
					style={{
						width: '44px',
					}}
				>
					<Drop
						stretch={false}
						onEsc={() => setShowAttachmentMenu(false)}
						onClickOutside={() => setShowAttachmentMenu(false)}
						target={attachmentMenuRef.current}
						align={{
							top: 'bottom',
							right: 'right',
						}}
					>
						<Box
							alignItems="flex-start"
							padding="10px 10px"
							style={{
								width: '265px',
								borderBottom: `1px solid ${theme.color.grey4}`,
							}}
							row
							onClick={() => {
								setShowAttachmentMenu(false)
								setRenameModal(true)
							}}
						>
							<Box
								padding="10px 10px"
								style={{
									background: theme.color.blue0,
								}}
							>
								<IconWrapper size={25}>
									<Icon
										color={theme.color.primary}
										glyph={EditIcon}
									/>
								</IconWrapper>
							</Box>
							<Box flex overflow padding="0px 20px">
								<H4 color={theme.color.primary}>
									{t('rfq.renameFile')}
								</H4>
								<P color={theme.color.grey15}>
									{t('rfq.renameFileline2')}
								</P>
							</Box>
						</Box>

						<Box
							alignItems="flex-start"
							padding="10px 10px"
							style={{
								width: '265px',
								borderBottom: `1px solid ${theme.color.grey4}`,
							}}
							row
							onClick={() => {
								setShowAttachmentMenu(false)
								deleteFileAttachment()
							}}
						>
							<Box
								padding="10px 10px"
								style={{
									background: '#e9bdbb',
								}}
							>
								<IconWrapper size={25}>
									<Icon
										color={theme.color.error}
										glyph={DeleteIcon}
									/>
								</IconWrapper>
							</Box>
							<Box flex overflow padding="0px 20px">
								<H4 color={theme.color.error}>
									{t('rfq.deleteFile')}
								</H4>
								<P color={theme.color.grey15}>
									{t('rfq.deleteFileline2')}
								</P>
							</Box>
						</Box>
					</Drop>
				</Box>
			)}
		</>
	)
}

const ViewRFQ = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const ref = useRef()
	const editUploadBtnRef = useRef()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule, action } = payload
	const rfqDetails = useSelector(MarketPlaceDuc.selectors.getRFQDetails)
	const rfqDownloadUrl = useSelector(
		MarketPlaceDuc.selectors.getRFQDownloadUrl
	)
	const actor = useSelector(MarketPlaceDuc.selectors.getMarketPlaceActor)
	const respondButtonLoadingStatus = useSelector(
		MarketPlaceDuc.selectors.getRespondToRequirementLoader
	)
	const existingAccountsModalStatus = useSelector(
		MarketPlaceDuc.selectors.getExistingAccountsModalStatus
	)
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const [confModal, setConfModal] = useState(false)
	const [renameModal, setRenameModal] = useState(false)
	const [dropDownToggle, setDropDownToggle] = useState(false)
	const [rfqModal, setRFQModal] = useState(false)
	const [showUploadModal, setShowUploadModal] = useState(false)
	const [showEditUploadMenu, setShowEditUploadMenu] = useState(false)
	const [selectedFile, setSelectedFile] = useState({})
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [newFileName, setNewFileName] = useState('')
	const [showChatBox, setShowChatBox] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(respondButtonLoadingStatus)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [existingAccountModal, setExistingAccountModal] = useState(
		existingAccountsModalStatus
	)

	useEffect(() => {
		dispatch(MessagesDuc.creators.setDocTypeList([]))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setExistingAccountModal(existingAccountsModalStatus)
	}, [existingAccountsModalStatus])

	const {
		tradeID = '',
		files = [],
		status,
		rfqNumber,
		seller,
		sellerID,
		buyerAddress,
		sellerAddress,
		rfqAccess,
		payment,
		destinationPort,
		ladingPort,
		supplyChainModel,
		productCode,
		productID,
		product,
		month,
		sellerCategory,
		period,
		inspectionType = [],
		contractType,
		requirementDetails,
		termsAndConditions,
		quantity,
		defaultRFQ,
		uom,
		rfqID,
		certificates = [],
		buyer,
		buyerID,
		buyerCategory,
		buyerState,
		buyerCountry,
		quotesID,
		inviteEmail,
	} = rfqDetails
	const quantityInsp = inspectionType.filter(insp => insp.type === 'quantity')
	const qualityInsp = inspectionType.filter(insp => insp.type === 'quality')

	const quantityInspector = getIn(quantityInsp, [0, 'officer'])
	const qualityInspector = getIn(qualityInsp, [0, 'officer'])

	const hideButtons =
		status === 'completed' || status === 'rejected' || inviteEmail === true

	const VIEW_TAB_CONFIG = [
		{
			name: 'viewRFQ',
			label: t('rfq.viewRFQ'),
		},
		{
			name: 'attachments',
			label: t('rfq.attachments'),
		},
	]

	const [viewRFQTabKey, attachmentTabKey] = VIEW_TAB_CONFIG.map(k => k.name)

	const activeTabs = useMemo(() => {
		const tabs = [...VIEW_TAB_CONFIG]

		return tabs
	}, [VIEW_TAB_CONFIG])

	const [activeTab, setActiveTab] = useState('viewRFQ')

	const isViewRFQTabActive = activeTab === viewRFQTabKey
	const isAttachmentTabActive = activeTab === attachmentTabKey

	const addAttachmentToRFQ = () => {
		const addpayload = {
			...rfqDetails,
			rfqID,
			productID,
			productName: product,
			buyer: {
				id: buyerID,
				name: buyer,
			},
			sellers: [
				{
					id: sellerID,
					name: seller,
				},
			],
			files: [...files, ...uploadedFiles],
		}
		setUploadedFiles([])
		dispatch(
			MarketPlaceDuc.creators.editRfq(
				addpayload,
				t('common.successUpdate'),
				{
					setSubmitting: () => {},
					shouldRedirect: false,
				}
			)
		)
	}

	const chatBoxData = data => {
		setShowChatBox(data)
	}

	const editFileName = () => {
		const filteredFile = files.filter(el => {
			return el.id !== selectedFile?.id
		})

		const newFiles = [
			...filteredFile,
			{
				...selectedFile,
				meta: {
					...selectedFile?.meta,
					name: newFileName,
				},
			},
		]

		const editNamepayload = {
			...rfqDetails,
			rfqID,
			productID,
			productName: product,
			buyer: {
				id: buyerID,
				name: buyer,
			},
			sellers: [
				{
					id: sellerID,
					name: seller,
				},
			],

			files: newFiles,
		}

		dispatch(
			MarketPlaceDuc.creators.editRfq(
				editNamepayload,
				t('common.successUpdate'),
				{
					setSubmitting: () => {},
					shouldRedirect: false,
				}
			)
		)
	}

	const deleteFileAttachment = () => {
		const filteredFile = files.filter(el => {
			return el.id !== selectedFile?.id
		})
		const deleteFilepayload = {
			...rfqDetails,
			rfqID,
			productID,
			productName: product,
			buyer: {
				id: buyerID,
				name: buyer,
			},
			sellers: [
				{
					id: sellerID,
					name: seller,
				},
			],

			files: filteredFile,
		}

		dispatch(
			MarketPlaceDuc.creators.editRfq(
				deleteFilepayload,
				t('common.successUpdate'),
				{
					setSubmitting: () => {},
					shouldRedirect: false,
				}
			)
		)
	}

	const sharePrivateFile = async docs => {
		if (!docs || docs.length === 0) {
			return
		}
		const fileIDs = (docs || []).map(d => d.id)
		const json = {
			fileIDs,
			shareWith: [buyerID, sellerID],
		}
		const options = {
			method: 'PUT',
			body: JSON.stringify(json),
		}
		const requestUrl = `${getIAMEndPoint()}files/share`
		const { data = {} } = await request(requestUrl, options)

		return data
	}

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('rfq.marketPlace'),
			name: 'marketplace',
			isActive: true,
		},
		{
			label: t('rfq.viewRFQ'),
			name: 'view-rfq',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'marketplace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule,
					}
				)
			)
		}
	}

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Modal
				forceCloseviaButton
				show={confModal}
				heading={t('rfq.confirmation')}
				body={
					<P large bold>
						{t('rfq.confirmationMessage')}
					</P>
				}
				closelabel={t('common.back')}
				confirmlabel={t('common.proceed')}
				onClose={() => {
					setConfModal(false)
				}}
				onConfirm={() => {
					setConfModal(false)
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$ACTION,
							{
								rootModule,
								action: 'create-quote',
								documentReference: rfqID,
							}
						)
					)
				}}
			/>
			<Modal
				forceCloseviaButton
				show={renameModal}
				heading={t('rfq.renameFile')}
				body={
					<Box>
						<Input
							name="uploadedFileName"
							value={
								selectedFile?.meta?.name
									? selectedFile?.meta?.name
									: ''
							}
							onChange={event =>
								setNewFileName(event.target.value)
							}
							required
						/>
					</Box>
				}
				closelabel={t('common.back')}
				confirmlabel={t('rfq.rename')}
				onClose={() => {
					setRenameModal(false)
				}}
				onConfirm={() => {
					setRenameModal(false)
					editFileName()
				}}
			/>

			<Modal
				size="auto"
				show={rfqModal}
				body={
					<ShareRFQModal
						onClose={() => setRFQModal(false)}
						onConfirm={emails => {
							dispatch(
								MarketPlaceDuc.creators.shareRFQWithExistingTradePartners(
									emails,
									rfqID
								)
							)
							setRFQModal(false)
						}}
					/>
				}
				hideButtons
			/>
			<Modal
				size="770px"
				show={existingAccountModal}
				body={
					<ExistingAccountsModal
						onConfirm={rfqPayload => {
							dispatch(
								MarketPlaceDuc.creators.createRFQForExistingOrgs(
									rfqPayload,
									t('production.plotInputSuccessToast')
								)
							)
							dispatch(
								MarketPlaceDuc.creators.toggleExistingAccountsModal(
									false
								)
							)
						}}
					/>
				}
				hideButtons
			/>
			<Title
				title={t('rfq.viewRFQDetails')}
				icon={LeftArrowIcon}
				small
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE,
							{
								rootModule,
							}
						)
					)
				}}
			/>
			{actor === 'mp_sales_marketing_officer' &&
				rfqAccess === 'rfq-access' && (
					<Box
						row
						justifyContent="center"
						alignItems="baseline"
						margin="24px 0"
						style={{
							background: '#fff',
							padding: '16px 70px',
							fontSize: 24,
						}}
					>
						{t('rfq.respondHeader')}
						<Button
							isLoading={isSubmitting}
							extendStyles={{ marginLeft: 50 }}
							primary
							label={t('rfq.yes')}
							onClick={() => {
								setIsSubmitting(true)
								if (action === 'view-global-rfq') {
									const json = {
										...rfqDetails,
										sellerID: getCurrentOrgID,
									}
									dispatch(
										MarketPlaceDuc.creators.createRFQFromSeller(
											json,
											t(
												'production.plotInputSuccessToast'
											)
										)
									)
								} else {
									dispatch(
										MarketPlaceDuc.creators.updatePostRequirementStatus(
											'',
											rfqID
										)
									)
								}
							}}
						/>
					</Box>
				)}
			{status === 'completed' && (
				<Wrapper margin="52px 0 0" width={isMobile ? '390px' : 'auto'}>
					<Box row padding={16} alignItems="center">
						<IconWrapper size={24} style={{ marginRight: 16 }}>
							<Icon glyph={InfoIcon} color="#F09112" />
						</IconWrapper>
						{actor === 'mp_sales_marketing_officer'
							? t('rfq.alreadyResponded')
							: t('rfq.linkToViewQuote')}
					</Box>
					<ButtonWithNoBorder
						label={t('rfq.viewQuote')}
						extendStyles={{ color: '#C97B12', marginLeft: 54 }}
						onClick={() => {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.MARKETPLACE$ACTION,
									{
										rootModule,
										action: 'view-quote',
										documentReference: quotesID,
									}
								)
							)
						}}
					/>
				</Wrapper>
			)}
			<Box
				row
				justifyContent={isMobile ? 'start' : 'flex-end'}
				margin={isMobile ? '20px 0' : '0 0 20px'}
			>
				{rootModule === 'buyer' && defaultRFQ === true && (
					<Button
						primary
						label={t('rfq.shareViaEmail')}
						onClick={() => setRFQModal(true)}
						extendStyles={{ marginLeft: 24 }}
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={ShareIcon} />
							</ButtonIconWrapper>
						}
					/>
				)}
			</Box>

			{rootModule === 'buyer' && defaultRFQ === true && (
				<Wrapper margin="52px 0" width={isMobile ? '390px' : 'auto'}>
					<Box row padding={16} alignItems="center">
						<IconWrapper size={24} style={{ marginRight: 16 }}>
							<Icon glyph={ShareIcon} color="#F09112" />
						</IconWrapper>
						{t('rfq.shareViaEmailHelpText')}
					</Box>
				</Wrapper>
			)}

			<Box row center>
				<Box center padding="20px 0px">
					<TabButtons
						fontSize={theme.fontSize.xl}
						list={activeTabs}
						key={activeTab}
						id="DOAttachments"
						activeIndex={VIEW_TAB_CONFIG.findIndex(
							q => q.name === activeTab
						)}
						onChange={({ name }) => {
							setActiveTab(name)
						}}
						isMobile={isMobile}
					/>
				</Box>
				<Box
					style={{
						right: isMobile ? '0px' : '30px',
						position: 'absolute',
					}}
					row
					justifyContent="flex-end"
				>
					{rfqDownloadUrl && (
						<a
							download
							target="_blank"
							title="Download RFQ"
							href={rfqDownloadUrl}
							rel="noopener noreferrer"
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<IconWrapper
								size={32}
								color="#000000"
								style={{
									cursor: 'pointer',
								}}
							>
								<Icon glyph={DownloadIcon} />
							</IconWrapper>
						</a>
					)}
					<Box
						row={!isMobile}
						alignItems={isMobile ? 'center' : 'normal'}
					>
						{!hideButtons && rfqAccess === '' && (
							<Box
								onClick={() =>
									setShowEditUploadMenu(!showEditUploadMenu)
								}
								style={{
									cursor: 'pointer',
									background: '#3F56C4',
									width: '48px',
									height: '48px',
									boxShadow: '2px 8px 16px #2428453D',
									borderRadius: '6px',
									color: '#3F56C4',
									margin: '0px 20px',
								}}
								ref={editUploadBtnRef}
								center
							>
								<IconWrapper
									size={25}
									ref={ref}
									style={{ color: theme.color.white }}
								>
									<Icon glyph={PlusIcon} />
								</IconWrapper>
							</Box>
						)}
						{!hideButtons &&
							actor === 'mp_sales_marketing_officer' &&
							rfqAccess === '' && (
								<>
									<Box
										ref={ref}
										style={{
											cursor: 'pointer',
											background: '#3F56C4',
											width: '48px',
											height: '48px',
											boxShadow: '2px 8px 16px #2428453D',
											borderRadius: '6px',
											color: '#3F56C4',
											marginTop: isMobile
												? '10px'
												: '0px',
										}}
										onClick={() => {
											setDropDownToggle(!dropDownToggle)
										}}
										center
									>
										<IconWrapper
											size={25}
											ref={ref}
											style={{
												color: theme.color.white,
											}}
										>
											<Icon
												glyph={ArrowForwardIcon}
												style={{
													transform: 'rotate(90deg)',
												}}
											/>
										</IconWrapper>
										{dropDownToggle && (
											<Drop
												target={ref.current}
												overflow="unset"
												align={{
													top: 'bottom',
													right: 'right',
												}}
												onClickOutside={() =>
													setDropDownToggle(false)
												}
											>
												{RFQ_STATUS$WICONS.map(
													({ _status, icon }) => (
														<Box
															key={_status}
															row
															alignItems="center"
															padding={4}
															style={{
																borderBottom:
																	'1px solid #D9DEE5',
															}}
														>
															<IconWrapper
																color={
																	STATUS_TEXT_COLOR[
																		_status
																	]
																}
																size={20}
															>
																<Icon
																	glyph={icon}
																	size={10}
																/>
															</IconWrapper>
															<Label
																color="#fff"
																small
																key=",s"
																style={{
																	padding: 8,
																	cursor:
																		'pointer',
																	color:
																		STATUS_TEXT_COLOR[
																			_status
																		],
																	marginLeft:
																		'12px',
																}}
																onClick={() => {
																	setDropDownToggle(
																		false
																	)
																	dispatch(
																		MarketPlaceDuc.creators.changeRFQStatus(
																			rfqID,
																			_status,
																			t(
																				'common.successUpdate'
																			)
																		)
																	)
																}}
															>
																{t(
																	RFQ_STATUS[
																		_status
																	]
																)}
															</Label>
														</Box>
													)
												)}
											</Drop>
										)}
									</Box>
								</>
							)}
					</Box>
					{showEditUploadMenu && (
						<Box>
							<Drop
								target={editUploadBtnRef.current}
								align={{
									top: 'bottom',
									right: 'right',
								}}
								onEsc={() => setShowEditUploadMenu(false)}
								onClickOutside={() =>
									setShowEditUploadMenu(false)
								}
							>
								{actor === 'mp_sales_marketing_officer' && (
									<Box
										overflow
										alignItems="flex-start"
										padding="10px 10px"
										style={{
											width: '265px',
											borderBottom: `1px solid ${theme.color.grey4}`,
											cursor: 'pointer',
										}}
										row
										onClick={() => {
											setShowEditUploadMenu(false)
											setConfModal(true)
										}}
									>
										<Box
											padding="10px 10px"
											style={{
												background: theme.color.blue0,
											}}
										>
											<IconWrapper size={25}>
												<Icon
													color={theme.color.primary}
													glyph={UploadIcon}
												/>
											</IconWrapper>
										</Box>
										<Box flex overflow padding="0px 20px">
											<H4 color={theme.color.primary}>
												{t('rfq.convertToQuote')}
											</H4>
											<P color={theme.color.grey15}>
												{t(
													'rfq.convertToQuoteHelpText'
												)}
											</P>
										</Box>
									</Box>
								)}
								{actor === 'mp_purchase_officer' && (
									<>
										<Box
											alignItems="flex-start"
											padding="10px 10px"
											style={{
												width: '265px',
												borderBottom: `1px solid ${theme.color.grey4}`,
												cursor: 'pointer',
											}}
											row
											onClick={() => {
												setShowEditUploadMenu(false)
												dispatch(
													MainRouteDuc.creators.switchPage(
														MainRouteDuc.types
															.MARKETPLACE$ACTION,
														{
															rootModule,
															action: 'edit-rfq',
															documentReference: rfqID,
														}
													)
												)
											}}
										>
											<Box
												padding="10px 10px"
												style={{
													background:
														theme.color.blue0,
												}}
											>
												<IconWrapper size={25}>
													<Icon
														color={
															theme.color.primary
														}
														glyph={EditIcon}
													/>
												</IconWrapper>
											</Box>
											<Box
												flex
												overflow
												padding="0px 20px"
											>
												<H4 color={theme.color.primary}>
													{t('rfq.editRFQ')}
												</H4>
												<P color={theme.color.grey15}>
													{t('rfq.editRFQHelpText')}
												</P>
											</Box>
										</Box>
									</>
								)}

								<Box
									alignItems="flex-start"
									padding="10px 10px"
									row
									onClick={() => {
										setShowEditUploadMenu(false)
										setShowUploadModal(true)
									}}
									style={{
										width: '265px',
										cursor: 'pointer',
									}}
								>
									<Box
										padding="10px 10px"
										style={{
											background: '#fff3e5',
										}}
									>
										<IconWrapper size={25}>
											<Icon
												color="#e99f52"
												glyph={ChatIcon}
											/>
										</IconWrapper>
									</Box>
									<Box flex overflow padding="0px 20px">
										<H4 color="#e99f52">
											{t('rfq.attachAnotherFile')}
										</H4>
										<P color={theme.color.grey15}>
											{t('rfq.attachAnotherFileHelpText')}
										</P>
									</Box>
								</Box>
							</Drop>
						</Box>
					)}
				</Box>
			</Box>

			{isViewRFQTabActive && (
				<Box row>
					<Box
						style={{
							flex: 1,
							background: '#fff',
							padding: '28px ',
						}}
					>
						<Box
							row={!isMobile}
							justifyContent="space-between"
							alignItems="baseline"
						>
							<H2 color={theme.color.green}>{rfqNumber}</H2>
							<Box row justifyContent="flex-end">
								<Box
									style={{
										backgroundColor:
											STATUS_BACKGROUND[status],
										padding: '8px 16px',
										margin: '0 0 8px',
										borderRadius: '5px',
										opacity: 1,
										textAlign: 'center',
									}}
								>
									<Label
										style={{
											color: STATUS_TEXT_COLOR[status],
										}}
									>
										{t(RFQ_STATUS[status]).toUpperCase()}
									</Label>
								</Box>
							</Box>
						</Box>
						<Box row={!isMobile} margin="16px 0">
							<Box width="50%">
								<Label style={{ color: theme.color.grey15 }}>
									{t('postRequirement.issued')}{' '}
									{actor === 'mp_sales_marketing_officer'
										? t('rfq.by')
										: t('rfq.to')}
								</Label>
								<Spacer size={8} />
								<Box style={{ maxWidth: 250 }}>
									<Title
										title={
											actor ===
											'mp_sales_marketing_officer'
												? buyer
												: seller
										}
										small
										note={
											actor ===
											'mp_sales_marketing_officer'
												? buyerCategory
												: sellerCategory
										}
									/>
								</Box>
								<Spacer size={16} />
								<Text
									style={{
										lineHeight: '1.5',
										width: '100%',
										overflow: 'hidden',
										color: '#8D93A0',
										maxWidth: '200px',
									}}
								>
									{actor === 'mp_sales_marketing_officer'
										? buyerAddress
										: sellerAddress}
								</Text>
								<Spacer size={12} />
								<Text>{certificates.join(', ')}</Text>
								<Spacer size={12} />
								<Box row>
									<SummaryBlock
										name={t('rfq.paymentTerms')}
										detail={payment}
										width="100%"
									/>
								</Box>
							</Box>
							{isMobile && (
								<Box
									height="14px"
									width="auto"
									margin="20px 0"
									style={{ background: '#F2F3F7' }}
								/>
							)}
							<Box>
								<Label style={{ color: theme.color.grey15 }}>
									{t('rfq.destinationDetails')}
								</Label>
								<Spacer size={8} />
								<Box
									width={330}
									style={{
										border: isMobile
											? 'none'
											: '1px solid #D9DEE5',
										padding: isMobile
											? '28px 0'
											: '28px 4px 28px 16px',
										borderRadius: 6,
									}}
								>
									<KeyValueSegment
										name={t('rfq.address')}
										description={buyerAddress}
									/>
									<KeyValueSegment
										name={t('rfq.stateCountry')}
										description={`${buyerState}, ${buyerCountry}`}
									/>
									<KeyValueSegment
										name={t('rfq.port')}
										description={destinationPort}
									/>
								</Box>
								<Spacer size={12} />
								<Box row>
									<SummaryBlock
										name={t('rfq.portOfLading')}
										detail={ladingPort}
										width="100%"
									/>
								</Box>
							</Box>
						</Box>
						<Spacer size={20} />
						{isMobile && (
							<Box
								height="14px"
								width="auto"
								style={{ background: '#F2F3F7' }}
							/>
						)}
						<Label
							style={{
								color: theme.color.grey15,
								margin: '20px 0 0 4px',
							}}
						>
							{t('rfq.productDetails')}
						</Label>
						<table
							style={{
								tableLayout: 'fixed',
								width: isMobile ? '100%' : '550px',
								borderSpacing: 4,
								borderCollapse: 'separate',
							}}
						>
							<tr>
								<th align="left" style={tableHeaderStyles}>
									{t('rfq.product')}
								</th>
								<th align="left" style={tableHeaderStyles}>
									{`${t('rfq.quantity')} (${uom &&
										uom.toUpperCase()})`}
								</th>
								{!isMobile && (
									<th align="left" style={tableHeaderStyles}>
										{t('rfq.scModel')}
									</th>
								)}
							</tr>
							<tr>
								<td style={tableRowStyles}>
									{`${product}  ${productCode || ' '}`}
								</td>
								<td style={tableRowStyles}>{quantity}</td>
								{!isMobile && (
									<td style={tableRowStyles}>
										{t(supplyChainModels[supplyChainModel])}
									</td>
								)}
							</tr>
						</table>
						<Spacer size={20} />
						<Box
							height="14px"
							width="auto"
							style={{ background: '#F2F3F7' }}
						/>
						<Label
							style={{
								color: theme.color.grey15,
								margin: '20px 0',
							}}
						>
							{t('rfq.additionalDetails')}
						</Label>
						<Box row={!isMobile} justifyContent="space-between">
							<SummaryBlock
								name={t('rfq.shipmentPeriod')}
								detail={`${t(MONTHS[month])}, ${t(
									SHIPMENT_PERIOD[period]
								)}`}
								width="175px"
							/>
							<Box style={{ marginRight: 8 }}>
								<SummaryBlock
									name={t('rfq.preferredQuantityInspector')}
									detail={quantityInspector || ' '}
									width="175px"
								/>
							</Box>
						</Box>
						<Spacer size={20} />
						<Box row={!isMobile} justifyContent="space-between">
							<SummaryBlock
								name={t('rfq.typeOfContract')}
								detail={t(CONTRACT_TYPE[contractType]) || ' '}
								width="175px"
							/>
							<Box style={{ marginRight: 16 }}>
								<SummaryBlock
									name={t('rfq.preferredQualityInspector')}
									detail={qualityInspector || ' '}
									width="175px"
								/>
							</Box>
						</Box>
						<Spacer size={20} />
						<Box
							height="14px"
							width="auto"
							style={{ background: '#F2F3F7' }}
						/>
						<Label
							style={{
								color: theme.color.grey15,
								margin: '20px 0 8px 0',
							}}
						>
							{t('rfq.termsAndConditions')}
						</Label>
						<P>{termsAndConditions}</P>
						<Spacer size={12} />
						<Label
							style={{
								color: theme.color.grey15,
								margin: '20px 0 8px 0',
							}}
						>
							{t('rfq.requirementDetails')}
						</Label>
						<P>{requirementDetails}</P>
					</Box>
					<Box
						style={{
							position: 'fixed',
							display: 'grid',
							zIndex: 1,
							bottom: 0,
							right: showChatBox ? 8 : 75,
						}}
					>
						<ChatModalComponent
							documentId={rfqID}
							sellerName={seller}
							buyerName={buyer}
							buyerId={buyerID}
							sellerId={sellerID}
							tradeId={tradeID}
							docType="rfq"
							chatBoxData={data => chatBoxData(data)}
							isMobile={isMobile}
						/>
					</Box>
				</Box>
			)}
			{isAttachmentTabActive && (
				<Box style={{ background: '#fff' }}>
					<Box
						style={{
							padding: '15px',
							borderBottom: `1px solid ${theme.color.grey4}`,
						}}
					>
						<H4 color={theme.color.primary}>
							{t('rfq.viewAllAttachment')}
						</H4>
					</Box>
					<Box
						row
						style={{
							padding: '15px',
							borderBottom: `1px solid ${theme.color.grey4}`,
						}}
					>
						{(files || []).map(d => (
							<AttachmentMenu
								key={d?.id}
								t={t}
								data={d}
								getCurrentOrgID={getCurrentOrgID}
								setSelectedFile={setSelectedFile}
								setRenameModal={setRenameModal}
								deleteFileAttachment={deleteFileAttachment}
							/>
						))}
					</Box>
				</Box>
			)}

			<Modal
				forceCloseviaButton
				size="large"
				show={showUploadModal}
				heading={t('common.import')}
				body={
					<>
						<FileUpload
							type="private"
							name="file"
							size="5242880"
							label={t('rfq.uploadAttachment')}
							required
							supportedFileType=".jpg,.png,.pdf"
							outline
							modalTriggerBtnLabel={t('common.upload')}
							currentUploads={uploadedFiles}
							onDocumentChange={async uploadedDoc => {
								await sharePrivateFile(uploadedDoc)
								setUploadedFiles(uploadedDoc)
							}}
							onUploadFailure={({ message }) => {
								dispatch(
									AppDuc.creators.showToast({
										messageType: 'error',
										message,
									})
								)
							}}
						/>
					</>
				}
				confirmlabel={t('common.proceed')}
				isDisabled={uploadedFiles.length === 0}
				closelabel={t('common.close')}
				onClose={() => {
					setUploadedFiles([])
					setShowUploadModal(false)
				}}
				onConfirm={() => {
					setShowUploadModal(false)
					addAttachmentToRFQ()
				}}
			/>
		</>
	)
}

export { ViewRFQ }
