import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'

const initialState = {
	activeModule: '',
	loading: false,
	error: false,
	modules: {
		chat: {
			chatThreads: {},
		},
		products: [],
		organizations: {
			organizationList: [],
			pagination: {},
		},
		document: {},
		initialRFQDetails: {
			product: '',
			quantity: '',
			uom: '',
			quantityInspector: '',
			qualityInspector: '',
			destinationPort: '',
			ladingPort: '',
			supplyChainModel: '',
			payment: '',
			requirementDetails: '',
			termsAndConditions: '',
			contractType: '',
			sellerAddress: '',
			buyerAddress: '',
			month: '',
			period: '',
			thirdPartyInspectionType: [],
		},
		rfqDetails: {},
		rfqPayloadForExistingOrgs: {},
		orgStatusShareViaEmail: {},
		orgProductDetails: {},
		orgList: [],
		quotes: {
			viewQuote: {},
		},
		productDetails: {},
		marketPlaceActor: 'mp_purchase_officer',
		supplyAbilityDetails: {},
		productID: '',
		documentViewStatus: false,
		existingAccountsModal: false,
		mpOrgListLoadingStatus: false,
		buyer: {
			rfqList: [],
			rfqPagination: {},
			quotesListing: [],
			quotesPagination: {},
			generalRequirementListing: [],
			generalRequirementsPagination: {},
		},
		seller: {
			rfqList: [],
			rfqPagination: {},
			quotesListing: [],
			quotesPagination: {},
			productsListing: [],
			productsPagination: {},
		},
		activeTabs: {
			buyer: 'home',
			seller: 'home',
		},
		marketPlaceSearch: {
			activeFilters: {},
		},
		globalProductsList: [],
		contractDetails: {},
		editContractInitialValues: {},
		contractPreviewDetails: {},
		respondToRequirementLoader: false,
	},
}

export const MarketPlaceDuc = new Duck({
	namespace: 'marketPlace',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'GET_GLOBAL_PRODUCT',
		'SET_GLOBAL_PRODUCT_SUCCESS',
		'GET_MARKET_PLACE_ORGANISATION_LIST',
		'GET_MARKET_PLACE_GLOBAL_RFQ_LIST',
		'GET_MARKET_PLACE_GLOBAL_RFQ_PAGINATION_QUERIES',
		'SET_MARKET_PLACE_GLOBAL_RFQ_PAGINATION_QUERIES',
		'GET_MARKET_PLACE_GLOBAL_RFQ_LIST_SUCESS',
		'GET_MARKET_PLACE_ORGANISATION_LIST_SUCCESS',
		'SAVE_MARKET_PLACE_PAGINATION_QUERIES',
		'FETCH_RFQ_LISTING',
		'FETCH_QUOTES_LISTING',
		'VIEW_RFQ',
		'VIEW_RFQ_ATTACHMENTS',
		'VIEW_GLOBAL_RFQ',
		'CREATE_RFQ',
		'CREATE_RFQ_SELLER',
		'SET_RFQ_LIST',
		'SET_RFQ_DETAILS',
		'SET_RFQ_DOWNLOAD_URL',
		'FLUSH_RFQ_DOWNLOAD_URL',
		'FETCH_ORG_PRODUCT_DETAILS',
		'SET_ORG_PRODUCT_DETAILS',
		'SET_RFQ_TEMPLATE_SEARCH',
		'SET_RFQ_TEMPLATE_LIST',
		'RFQ_TEMPLATE_LIST',
		'SET_INITIAL_RFQ_DETAILS',
		'EDIT_RFQ',
		'FLUSH_RFQ_DETAILS',
		'SET_QUOTE_LIST',
		'CREATE_QUOTE',
		'VIEW_QUOTE',
		'VIEW_QUOTE_ATTACHMENTS',
		'SET_QUOTES_SUCCESS',
		'SET_QUOTE_DOWNLOAD_URL',
		'FLUSH_QUOTE_DOWNLOAD_URL',
		'CHANGE_RFQ_STATUS',
		'CHANGE_QUOTE_STATUS',
		'CHANGE_RFQ_STATUS',
		'ADD_ATTACHMENT_TO_QUOTE',
		'CONVERT_TO_PURCHASE_ORDER',
		'FETCH_PRODUCTS_LISTING',
		'SET_PRODUCTS_LISTING',
		'FETCH_API_BASED_ON_ACTOR',
		'SET_MARKETPLACE_ACTOR',
		'VIEW_PRODUCT',
		'SET_PRODUCT_DETAILS',
		'ADD_PRODUCT',
		'EDIT_PRODUCT',
		'SET_API_BASED_ON_ACTOR',
		// 'GET_CHAT_THREAD_BY_TRADE_ID',
		// 'SET_CHAT_THREADS',
		// 'SET_CHAT_UNREAD_COUNT',
		// 'GET_CHAT_TOKEN',
		// 'SET_CHAT_READ_COUNT',
		// 'SET_CHAT_TOKEN',
		'PUBLISH_SUPPLY_ABILITY',
		'FETCH_SUPPLY_ABILITY',
		'SET_SUPPLY_ABILITY',
		'SET_PRODUCT_ID',
		'SHARE_RFQ_WITH_EXISTING_TRADE_PARTNERS',
		'TOGGLE_EXISTING_ACCOUNTS_MODAL',
		'SET_RFQ_PAGINATION_QUERIES',
		'SET_QUOTES_PAGINATION_QUERIES',
		'SET_PRODUCTS_PAGINATION_QUERIES',
		'SET_MP_ORGLIST_LOADING_STATUS',
		'FLUSH_RFQ_LIST',
		'FLUSH_QUOTE_LIST',
		'FETCH_ORGS_DETAILS',
		'SET_ORG_LIST',
		'SET_RFQ_PAYLOAD_FOR_EXISTING_ORGS',
		'CREATE_RFQ_FOR_EXISTING_ORGS',
		'SET_ORG_STATUS_FOR_SHARE_VIA_EMAIL',
		'FLUSH_PRODUCT_LIST',
		'FLUSH_GENERAL_REQUIREMENT',
		'FETCH_GENERAL_REQUIREMENT_LISTING',
		'SET_GENERAL_REQUIREMENT_LISTING',
		'SET_GENERAL_REQUIREMENT_PAGINATION_QUERIES',
		'SET_ACTIVE_TABS',
		'FETCH_GLOBAL_PRODUCTS_NO_PAGINATION',
		'SET_GLOBAL_PRODUCTS_NO_PAGINATION',
		'POST_GENERAL_REQUIREMENT',
		'SET_ACTIVE_FILTERS_MARKETPLACE_SEARCH',
		'FLUSH_MARKETPLACE_SEARCH_RESULTS',
		'FLUSH_MARKETPLACE_GLOBAL_RFQ_RESULTS',
		'UPDATE_POST_REQUIREMENT_STATUS',
		'FETCH_MARKETPLACE_SAVED_CONTRACT_DETAILS',
		'FETCH_MARKETPLACE_PROCESSING_CONTRACT_DETAILS',
		'SET_MARKETPLACE_SAVED_CONTRACT_DETAILS',
		'SET_MARKETPLACE_CONTRACT_DETAILS',
		'GET_MARKETPLACE_CONTRACT_DETAILS',
		'FETCH_MARKETPLACE_CONTRACT_E_SIGNAL_DETAILS',
		'SET_MARKETPLACE_CONTRACT_E_SIGNAL_DETAILS',
		'POST_MARKETPLACE_CONTRACT_DETAILS',
		'POST_ATTACHED_MARKETPLACE_CONTRACT_DETAILS',
		'DECLINE_MARKETPLACE_CONTRACT_DETAILS',
		'ACCEPTED_MARKETPLACE_CONTRACT_DETAILS',
		'RETRY_SIGNING_DOCUMENT',
		'INITIATE_EDIT_CONTRACT_DETAILS',
		'SET_EDIT_CONTRACT_INITIAL_VALUES',
		'EDIT_CONTRACT',
		'SET_PREVIEW_VALUES',
		'SET_DOCUMENT_VIEW_STATUS',
		'RFQ_TEMPLATE_SEARCH',
		'SET_RESPOND_TO_REQUIREMENT_LOADER',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.SET_GLOBAL_PRODUCT_SUCCESS: {
				const { data } = action

				return setIn(state, ['modules', 'products'], data)
			}

			case duck.types.GET_MARKET_PLACE_ORGANISATION_LIST_SUCCESS: {
				const { data } = action

				const currentOrgList = getIn(state, [
					'modules',
					'organizations',
					'organizationList',
				])

				const finalList = currentOrgList.concat(data || [])

				return setIn(
					state,
					['modules', 'organizations', 'organizationList'],
					finalList
				)
			}
			case duck.types.GET_MARKET_PLACE_GLOBAL_RFQ_LIST_SUCESS: {
				const { data } = action
				const currentGlobalRFQList =
					getIn(state, ['modules', 'seller', 'globalRFQ']) || []

				const finalList = currentGlobalRFQList.concat(data || [])

				return setIn(
					state,
					['modules', 'seller', 'globalRFQ'],
					finalList
				)
			}
			case duck.types.SET_MARKET_PLACE_GLOBAL_RFQ_PAGINATION_QUERIES: {
				const { query } = action

				return setIn(
					state,
					['modules', 'seller', 'globalRfqPagination'],
					query
				)
			}
			case duck.types.SAVE_MARKET_PLACE_PAGINATION_QUERIES: {
				const { paginate } = action

				return setIn(
					state,
					['modules', 'organizations', 'pagination'],
					paginate
				)
			}

			case duck.types.SAVE_PAGINATION_QUERIES: {
				const { paginate } = action

				return setIn(state, ['pagination'], paginate)
			}

			case duck.types.SET_ORG_PRODUCT_DETAILS: {
				const { data } = action

				return setIn(state, ['modules', 'orgProductDetails'], data)
			}

			case duck.types.SET_RFQ_TEMPLATE_SEARCH: {
				const { data } = action

				return setIn(state, ['modules', 'rfqSearchResult'], data)
			}

			case duck.types.SET_RFQ_TEMPLATE_LIST: {
				const { data } = action

				return setIn(state, ['modules', 'rfqListResult'], data)
			}

			case duck.types.SET_RFQ_LIST: {
				const { list, actor } = action

				const currentRFQList = getIn(state, [
					'modules',
					actor,
					'rfqList',
				])

				const finalList = currentRFQList.concat(list || [])

				return setIn(state, ['modules', actor, 'rfqList'], finalList)
			}

			case duck.types.SET_QUOTE_LIST: {
				const { list, actor } = action

				const currentQuoteList = getIn(state, [
					'modules',
					actor,
					'quotesListing',
				])

				const finalList = currentQuoteList.concat(list || [])

				return setIn(
					state,
					['modules', actor, 'quotesListing'],
					finalList
				)
			}

			case duck.types.SET_RFQ_DETAILS: {
				const { details } = action

				return setIn(state, ['modules', 'rfqDetails'], details)
			}

			case duck.types.SET_RFQ_DOWNLOAD_URL: {
				const { details } = action

				return setIn(state, ['modules', 'rfqDownloadUrl'], details)
			}

			case duck.types.FLUSH_RFQ_DOWNLOAD_URL: {
				return setIn(state, ['modules', 'rfqDownloadUrl'], '')
			}

			case duck.types.SET_INITIAL_RFQ_DETAILS: {
				const { details } = action

				return setIn(state, ['modules', 'initialRFQDetails'], details)
			}

			case duck.types.FLUSH_RFQ_DETAILS: {
				return setIn(
					state,
					['modules', 'initialRFQDetails'],
					initialState.modules.initialRFQDetails
				)
			}

			case duck.types.FLUSH_RFQ_LIST: {
				const { actor } = action

				return setIn(state, ['modules', actor, 'rfqList'], [])
			}

			case duck.types.FLUSH_QUOTE_LIST: {
				const { actor } = action

				return setIn(state, ['modules', actor, 'quotesListing'], [])
			}

			case duck.types.FLUSH_PRODUCT_LIST: {
				return setIn(
					state,
					['modules', 'seller', 'productsListing'],
					[]
				)
			}

			case duck.types.SET_QUOTES_SUCCESS: {
				const { data } = action

				return setIn(state, ['modules', 'quotes', 'viewQuote'], data)
			}

			case duck.types.SET_QUOTE_DOWNLOAD_URL: {
				const { details } = action

				return setIn(
					state,
					['modules', 'quotes', 'quoteDownloadUrl'],
					details
				)
			}

			case duck.types.FLUSH_QUOTE_DOWNLOAD_URL: {
				return setIn(
					state,
					['modules', 'quotes', 'quoteDownloadUrl'],
					''
				)
			}

			case duck.types.SET_PRODUCTS_LISTING: {
				const { data } = action
				const currentProductsList = getIn(state, [
					'modules',
					'seller',
					'productsListing',
				])

				const finalList = currentProductsList.concat(data || [])

				return setIn(
					state,
					['modules', 'seller', 'productsListing'],
					finalList
				)
			}

			case duck.types.SET_MARKETPLACE_ACTOR: {
				const { actor } = action

				return setIn(state, ['modules', 'marketPlaceActor'], actor)
			}

			case duck.types.SET_PRODUCT_DETAILS: {
				const { details } = action

				return setIn(state, ['modules', 'productDetails'], details)
			}

			case duck.types.SET_SUPPLY_ABILITY: {
				const { details } = action

				return setIn(
					state,
					['modules', 'supplyAbilityDetails'],
					details
				)
			}

			case duck.types.SET_PRODUCT_ID: {
				const { id } = action

				return setIn(state, ['modules', 'productID'], id)
			}

			case duck.types.SET_RFQ_PAGINATION_QUERIES: {
				const { query, actor } = action

				return setIn(state, ['modules', actor, 'rfqPagination'], query)
			}

			case duck.types.SET_QUOTES_PAGINATION_QUERIES: {
				const { query, actor } = action

				return setIn(
					state,
					['modules', actor, 'quotesPagination'],
					query
				)
			}

			case duck.types.SET_PRODUCTS_PAGINATION_QUERIES: {
				return setIn(
					state,
					['modules', 'seller', 'productsPagination'],
					action.query
				)
			}

			case duck.types.TOGGLE_EXISTING_ACCOUNTS_MODAL: {
				const { status } = action

				return setIn(
					state,
					['modules', 'existingAccountsModal'],
					status
				)
			}

			case duck.types.SET_MP_ORGLIST_LOADING_STATUS: {
				const { status } = action

				return setIn(
					state,
					['modules', 'mpOrgListLoadingStatus'],
					status
				)
			}

			case duck.types.SET_GENERAL_REQUIREMENT_LISTING: {
				const { data } = action
				const currentList = getIn(state, [
					'modules',
					'buyer',
					'generalRequirementListing',
				])

				const finalList = currentList.concat(data || [])

				return setIn(
					state,
					['modules', 'buyer', 'generalRequirementListing'],
					finalList
				)
			}

			case duck.types.SET_GENERAL_REQUIREMENT_PAGINATION_QUERIES: {
				return setIn(
					state,
					['modules', 'buyer', 'generalRequirementsPagination'],
					action.query
				)
			}

			case duck.types.FLUSH_GENERAL_REQUIREMENT: {
				return setIn(
					state,
					['modules', 'buyer', 'generalRequirementListing'],
					[]
				)
			}

			case duck.types.SET_ACTIVE_TABS: {
				const { actor, activeTab } = action

				return setIn(state, ['modules', 'activeTabs', actor], activeTab)
			}

			case duck.types.SET_ORG_LIST: {
				const { list } = action

				return setIn(state, ['modules', 'orgList'], list)
			}

			case duck.types.SET_RFQ_PAYLOAD_FOR_EXISTING_ORGS: {
				const { payload } = action

				return setIn(
					state,
					['modules', 'rfqPayloadForExistingOrgs'],
					payload
				)
			}

			case duck.types.SET_ORG_STATUS_FOR_SHARE_VIA_EMAIL: {
				const { status } = action

				return setIn(
					state,
					['modules', 'orgStatusShareViaEmail'],
					status
				)
			}

			case duck.types.SET_GLOBAL_PRODUCTS_NO_PAGINATION: {
				const { data } = action

				return setIn(state, ['modules', 'globalProductsList'], data)
			}

			case duck.types.SET_ACTIVE_FILTERS_MARKETPLACE_SEARCH: {
				const { filters } = action

				return setIn(
					state,
					['modules', 'marketPlaceSearch', 'activeFilters'],
					filters
				)
			}

			case duck.types.FLUSH_MARKETPLACE_SEARCH_RESULTS: {
				return setIn(
					state,
					['modules', 'organizations', 'organizationList'],
					[]
				)
			}
			case duck.types.FLUSH_MARKETPLACE_GLOBAL_RFQ_RESULTS: {
				return setIn(state, ['modules', 'seller', 'globalRFQ'], [])
			}

			case duck.types.SET_MARKETPLACE_SAVED_CONTRACT_DETAILS: {
				const { savedContractDetails } = action

				return setIn(
					state,
					['modules', 'savedContractDetails'],
					savedContractDetails
				)
			}

			case duck.types.SET_MARKETPLACE_CONTRACT_DETAILS: {
				const { contractDetails } = action

				return setIn(
					state,
					['modules', 'contractDetails'],
					contractDetails
				)
			}

			case duck.types.SET_MARKETPLACE_CONTRACT_E_SIGNAL_DETAILS: {
				const { eSignDetails } = action

				return setIn(state, ['modules', 'eSignDetails'], eSignDetails)
			}

			case duck.types.SET_EDIT_CONTRACT_INITIAL_VALUES: {
				const { details } = action

				return setIn(
					state,
					['modules', 'editContractInitialValues'],
					details
				)
			}

			case duck.types.SET_PREVIEW_VALUES: {
				const { details } = action

				return setIn(
					state,
					['modules', 'contractPreviewDetails'],
					details
				)
			}

			case duck.types.SET_DOCUMENT_VIEW_STATUS: {
				const { status } = action

				return setIn(state, ['modules', 'documentViewStatus'], status)
			}

			case duck.types.SET_RESPOND_TO_REQUIREMENT_LOADER: {
				const { status } = action

				return setIn(
					state,
					['modules', 'respondToRequirementLoader'],
					status
				)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['marketPlace', 'activeModule']) || 'Error',
		getProductList: state =>
			getIn(state, ['marketPlace', 'modules', 'products']) || [],
		getMarketPlaceOrgList: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'organizations',
				'organizationList',
			]) || [],
		getMarketPlaceGlobalRFQList: state =>
			getIn(state, ['marketPlace', 'modules', 'seller', 'globalRFQ']) ||
			[],
		getMarketPlaceGlobalRFQPagination: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'seller',
				'globalRfqPagination',
			]) || [],

		getMarketPlacePagination: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'organizations',
				'pagination',
			]) || {},
		getMarketPlaceDocument: state =>
			getIn(state, ['marketPlace', 'modules', 'document']) || {},
		getInitialRFQFormValues: state =>
			getIn(state, ['marketPlace', 'modules', 'initialRFQDetails']) || {},
		getRFQDetails: state =>
			getIn(state, ['marketPlace', 'modules', 'rfqDetails']) || {},
		getRFQDownloadUrl: state =>
			getIn(state, ['marketPlace', 'modules', 'rfqDownloadUrl']) || null,
		getOrgProductDetails: state =>
			getIn(state, ['marketPlace', 'modules', 'orgProductDetails']),
		getQuoteList: state =>
			getIn(state, ['marketPlace', 'modules', 'quotes', 'documents']) ||
			[],
		getQuoteDocument: state =>
			getIn(state, ['marketPlace', 'modules', 'quotes', 'viewQuote']) ||
			{},
		getQuoteDownloadUrl: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'quotes',
				'quoteDownloadUrl',
			]) || null,
		getActiveTab: state =>
			getIn(state, ['marketPlace', 'modules', 'activeTab']) || '',
		getProductsListing: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'seller',
				'productsListing',
			]) || [],
		getMarketPlaceActor: state =>
			getIn(state, ['marketPlace', 'modules', 'marketPlaceActor']) || '',
		getProductDetails: state =>
			getIn(state, ['marketPlace', 'modules', 'productDetails']) || {},
		getSupplyAbility: state =>
			getIn(state, ['marketPlace', 'modules', 'supplyAbilityDetails']) ||
			{},
		getProductID: state =>
			getIn(state, ['marketPlace', 'modules', 'productID']) || '',
		getQuotesPaginationQueries: state =>
			getIn(state, ['marketPlace', 'modules', 'quotes', 'pagination']) ||
			{},
		getProductsPaginationQueries: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'seller',
				'productsPagination',
			]) || {},
		getMPOrgListLoadingStatus: state =>
			getIn(state, ['marketPlace', 'modules', 'mpOrgListLoadingStatus']),
		getBuyerRFQList: state =>
			getIn(state, ['marketPlace', 'modules', 'buyer', 'rfqList']),
		getSellerRFQList: state =>
			getIn(state, ['marketPlace', 'modules', 'seller', 'rfqList']),
		getBuyerRFQPaginationQueries: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'buyer',
				'rfqPagination',
			]) || {},
		getSellerRFQPaginationQueries: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'seller',
				'rfqPagination',
			]) || {},
		getBuyerQuotesList: state =>
			getIn(state, ['marketPlace', 'modules', 'buyer', 'quotesListing']),
		getSellerQuotesList: state =>
			getIn(state, ['marketPlace', 'modules', 'seller', 'quotesListing']),
		getBuyerQuotesPaginationQueries: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'buyer',
				'quotesPagination',
			]) || {},
		getSellerQuotesRFQPaginationQueries: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'seller',
				'quotesPagination',
			]) || {},
		getBuyerMainDashboardActiveCounts: new Duck.Selector(selectors =>
			createSelector(
				selectors.getBuyerRFQList,
				selectors.getBuyerQuotesList,
				(rfqList, quoteList) => {
					return {
						enquiries: rfqList.filter(
							rfq => rfq.status === 'pending'
						).length,
						orders: quoteList.filter(
							quote => quote.status === 'pending'
						).length,
					}
				}
			)
		),
		getSellerMainDashboardActiveCounts: new Duck.Selector(selectors =>
			createSelector(
				selectors.getSellerRFQList,
				selectors.getSellerQuotesList,
				(rfqList, quoteList) => {
					return {
						enquiries: rfqList.filter(
							rfq => rfq.status === 'pending'
						).length,
						orders: quoteList.filter(
							quote => quote.status === 'pending'
						).length,
					}
				}
			)
		),
		getGeneralRequirementList: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'buyer',
				'generalRequirementListing',
			]),
		getGeneralRequirementPaginationQueries: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'buyer',
				'generalRequirementsPagination',
			]) || {},
		getActiveTabsForBuyerAndSeller: state =>
			getIn(state, ['marketPlace', 'modules', 'activeTabs']),
		getExistingAccountsModalStatus: state =>
			getIn(state, ['marketPlace', 'modules', 'existingAccountsModal']),
		getOrgList: state =>
			getIn(state, ['marketPlace', 'modules', 'orgList']),
		getRFQPayloadForExistingOrgs: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'rfqPayloadForExistingOrgs',
			]),
		getOrgStatusForShareViaEmail: state =>
			getIn(state, ['marketPlace', 'modules', 'orgStatusShareViaEmail']),
		getGlobalProductsNoPagination: state =>
			getIn(state, ['marketPlace', 'modules', 'globalProductsList']),
		getActiveFiltersForMarketPlaceSearch: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'marketPlaceSearch',
				'activeFilters',
			]),
		getSavedContractDetails: state =>
			getIn(state, ['marketPlace', 'modules', 'savedContractDetails']),
		getContractDetails: state =>
			getIn(state, ['marketPlace', 'modules', 'contractDetails']),
		getESignDetails: state =>
			getIn(state, ['marketPlace', 'modules', 'eSignDetails']),
		getEditContractInitialValues: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'editContractInitialValues',
			]),
		getContractPreviewDetails: state =>
			getIn(state, ['marketPlace', 'modules', 'contractPreviewDetails']),
		getRfqTemplateSearchResult: state =>
			getIn(state, ['marketPlace', 'modules', 'rfqSearchResult']),
		getRfqTemplateListResult: state =>
			getIn(state, ['marketPlace', 'modules', 'rfqListResult']),
		getDocumentViewStatus: state =>
			getIn(state, ['marketPlace', 'modules', 'documentViewStatus']),
		getRespondToRequirementLoader: state =>
			getIn(state, [
				'marketPlace',
				'modules',
				'respondToRequirementLoader',
			]),
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		getGlobalProduct: () => ({
			type: duck.types.GET_GLOBAL_PRODUCT,
		}),
		setGlobalProductSuccess: data => ({
			type: duck.types.SET_GLOBAL_PRODUCT_SUCCESS,
			data,
		}),
		getMarketPlaceOrganisationList: (locationState, rootModule) => ({
			type: duck.types.GET_MARKET_PLACE_ORGANISATION_LIST,
			locationState,
			rootModule,
		}),
		getMarketPlaceGlobalRFQList: (
			locationState,
			rootModule,
			virtualAccess = false
		) => ({
			type: duck.types.GET_MARKET_PLACE_GLOBAL_RFQ_LIST,
			locationState,
			rootModule,
			virtualAccess,
		}),
		setGlobalRFQPaginationQueries: query => ({
			type: duck.types.SET_MARKET_PLACE_GLOBAL_RFQ_PAGINATION_QUERIES,
			query,
		}),
		getMarketPlaceOrganisationListSuccess: data => ({
			type: duck.types.GET_MARKET_PLACE_ORGANISATION_LIST_SUCCESS,
			data,
		}),
		getMarketPlaceGlobalRFQListSuccess: data => ({
			type: duck.types.GET_MARKET_PLACE_GLOBAL_RFQ_LIST_SUCESS,
			data,
		}),
		fetchOrgProductDetails: id => ({
			type: duck.types.FETCH_ORG_PRODUCT_DETAILS,
			id,
		}),
		setOrgProductDetails: data => ({
			type: duck.types.SET_ORG_PRODUCT_DETAILS,
			data,
		}),
		setRfqTemplateSearch: data => ({
			type: duck.types.SET_RFQ_TEMPLATE_SEARCH,
			data,
		}),
		setRfqTemplateList: data => ({
			type: duck.types.SET_RFQ_TEMPLATE_LIST,
			data,
		}),
		saveMarketPlacePaginationQueries: paginate => ({
			type: duck.types.SAVE_MARKET_PLACE_PAGINATION_QUERIES,
			paginate,
		}),
		fetchRFQListing: (rootModule, locationState = {}) => ({
			type: duck.types.FETCH_RFQ_LISTING,
			rootModule,
			locationState,
		}),
		viewRFQ: (rfqID, edit = false) => ({
			type: duck.types.VIEW_RFQ,
			rfqID,
			edit,
		}),
		viewRFQAttachments: rfqID => ({
			type: duck.types.VIEW_RFQ_ATTACHMENTS,
			rfqID,
		}),
		viewGlobalRFQ: (rfqID, edit = false) => ({
			type: duck.types.VIEW_GLOBAL_RFQ,
			rfqID,
			edit,
		}),
		createRFQ: (details, successToast, helpers) => ({
			type: duck.types.CREATE_RFQ,
			details,
			successToast,
			helpers,
		}),
		createRFQFromSeller: (details, successToast) => ({
			type: duck.types.CREATE_RFQ_SELLER,
			details,
			successToast,
		}),
		createQuote: (details, successToast, quoteID, helpers) => ({
			type: duck.types.CREATE_QUOTE,
			details,
			successToast,
			quoteID,
			helpers,
		}),
		addAttachmentToQuote: (details, successToast, quoteID, rootModule) => ({
			type: duck.types.ADD_ATTACHMENT_TO_QUOTE,
			details,
			successToast,
			quoteID,
			rootModule,
		}),
		setRFQList: (list, actor) => ({
			type: duck.types.SET_RFQ_LIST,
			list,
			actor,
		}),
		setRFQDetails: details => ({
			type: duck.types.SET_RFQ_DETAILS,
			details,
		}),
		setRFQDownloadUrl: details => ({
			type: duck.types.SET_RFQ_DOWNLOAD_URL,
			details,
		}),
		flushRFQDownloadUrl: () => ({
			type: duck.types.FLUSH_RFQ_DOWNLOAD_URL,
		}),
		setInitialRFQDetails: details => ({
			type: duck.types.SET_INITIAL_RFQ_DETAILS,
			details,
		}),
		editRfq: (details, successToast, helpers) => ({
			type: duck.types.EDIT_RFQ,
			details,
			successToast,
			helpers,
		}),
		flushRFQ: () => ({
			type: duck.types.FLUSH_RFQ_DETAILS,
		}),
		fetchQuotesListing: (payload, locationState = {}) => ({
			type: duck.types.FETCH_QUOTES_LISTING,
			payload,
			locationState,
		}),
		setQuoteListing: (list, actor) => ({
			type: duck.types.SET_QUOTE_LIST,
			list,
			actor,
		}),
		changeRFQStatus: (rfqID, status, successToast) => ({
			type: duck.types.CHANGE_RFQ_STATUS,
			rfqID,
			status,
			successToast,
		}),
		viewQuote: (quoteID, payload, status) => ({
			type: duck.types.VIEW_QUOTE,
			quoteID,
			payload,
			status,
		}),
		viewQuoteAttachments: quoteID => ({
			type: duck.types.VIEW_QUOTE_ATTACHMENTS,
			quoteID,
		}),
		setQuotesSuccess: data => ({
			type: duck.types.SET_QUOTES_SUCCESS,
			data,
		}),
		setQuoteDownloadUrl: details => ({
			type: duck.types.SET_QUOTE_DOWNLOAD_URL,
			details,
		}),
		flushQuoteDownloadUrl: () => ({
			type: duck.types.FLUSH_QUOTE_DOWNLOAD_URL,
		}),
		changeQuoteStatus: (quoteID, status, successToast) => ({
			type: duck.types.CHANGE_QUOTE_STATUS,
			quoteID,
			status,
			successToast,
		}),
		convertToPurchaseOrder: quoteID => ({
			type: duck.types.CONVERT_TO_PURCHASE_ORDER,
			quoteID,
		}),
		fetchProductsListing: (locationState = {}) => ({
			type: duck.types.FETCH_PRODUCTS_LISTING,
			locationState,
		}),
		setProductsListing: data => ({
			type: duck.types.SET_PRODUCTS_LISTING,
			data,
		}),
		fetchAPIBasedOnActor: payload => ({
			type: duck.types.FETCH_API_BASED_ON_ACTOR,
			payload,
		}),
		setMarketPlaceActor: actor => ({
			type: duck.types.SET_MARKETPLACE_ACTOR,
			actor,
		}),
		viewProduct: prodID => ({
			type: duck.types.VIEW_PRODUCT,
			prodID,
		}),
		setProductDetails: details => ({
			type: duck.types.SET_PRODUCT_DETAILS,
			details,
		}),
		addProduct: (productDetails, toastMessage) => ({
			type: duck.types.ADD_PRODUCT,
			productDetails,
			toastMessage,
		}),
		editProduct: (details, prodID, toastMessage) => ({
			type: duck.types.EDIT_PRODUCT,
			details,
			prodID,
			toastMessage,
		}),
		setAPIBasedOnActor: location => ({
			type: duck.types.SET_API_BASED_ON_ACTOR,
			location,
		}),
		// setChatThreads: chatThreads => ({
		// 	type: duck.types.SET_CHAT_THREADS,
		// 	chatThreads,
		// }),
		// setChatUnreadCount: count => ({
		// 	type: duck.types.SET_CHAT_UNREAD_COUNT,
		// 	count,
		// }),
		// getChatThreadByTradeId: (tradeId, docType) => ({
		// 	type: duck.types.GET_CHAT_THREAD_BY_TRADE_ID,
		// 	tradeId,
		// 	docType,
		// }),
		// getChatToken: orgRole => ({
		// 	type: duck.types.GET_CHAT_TOKEN,
		// 	orgRole,
		// }),

		// setMessageReadCount: (threadID, eventName) => ({
		// 	type: duck.types.SET_CHAT_READ_COUNT,
		// 	threadID,
		// 	eventName,
		// }),
		// setChatToken: chatToken => ({
		// 	type: duck.types.SET_CHAT_TOKEN,
		// 	chatToken,
		// }),
		publishSupplyAbility: details => ({
			type: duck.types.PUBLISH_SUPPLY_ABILITY,
			details,
		}),
		fetchSupplyAbility: (id, orgID, startID = '') => ({
			type: duck.types.FETCH_SUPPLY_ABILITY,
			id,
			orgID,
			startID,
		}),
		setSupplyAbility: details => ({
			type: duck.types.SET_SUPPLY_ABILITY,
			details,
		}),
		setProductID: id => ({
			type: duck.types.SET_PRODUCT_ID,
			id,
		}),
		shareRFQWithExistingTradePartners: (partners, rfqID = '') => ({
			type: duck.types.SHARE_RFQ_WITH_EXISTING_TRADE_PARTNERS,
			partners,
			rfqID,
		}),
		toggleExistingAccountsModal: status => ({
			type: duck.types.TOGGLE_EXISTING_ACCOUNTS_MODAL,
			status,
		}),
		setRFQPaginationQueries: (query, actor) => ({
			type: duck.types.SET_RFQ_PAGINATION_QUERIES,
			query,
			actor,
		}),
		setQuotesPaginationQueries: (query, actor) => ({
			type: duck.types.SET_QUOTES_PAGINATION_QUERIES,
			query,
			actor,
		}),
		setProductsPaginationQueries: query => ({
			type: duck.types.SET_PRODUCTS_PAGINATION_QUERIES,
			query,
		}),
		setMPOrgListLoadingStatus: status => ({
			type: duck.types.SET_MP_ORGLIST_LOADING_STATUS,
			status,
		}),
		flushRFQList: actor => ({
			type: duck.types.FLUSH_RFQ_LIST,
			actor,
		}),
		flushQuoteList: actor => ({
			type: duck.types.FLUSH_QUOTE_LIST,
			actor,
		}),
		fetchOrgsDetails: (orgIDs, emails) => ({
			type: duck.types.FETCH_ORGS_DETAILS,
			orgIDs,
			emails,
		}),
		setOrglist: list => ({
			type: duck.types.SET_ORG_LIST,
			list,
		}),
		setRFQPayloadForExistingOrgs: payload => ({
			type: duck.types.SET_RFQ_PAYLOAD_FOR_EXISTING_ORGS,
			payload,
		}),
		createRFQForExistingOrgs: (payload, toastMessage) => ({
			type: duck.types.CREATE_RFQ_FOR_EXISTING_ORGS,
			payload,
			toastMessage,
		}),
		setOrgStatusForShareViaEmail: (status = {}) => ({
			type: duck.types.SET_ORG_STATUS_FOR_SHARE_VIA_EMAIL,
			status,
		}),
		flushProductList: () => ({
			type: duck.types.FLUSH_PRODUCT_LIST,
		}),
		flushGeneralRequirement: () => ({
			type: duck.types.FLUSH_GENERAL_REQUIREMENT,
		}),
		fetchGeneralRequirementListing: (locationState = {}) => ({
			type: duck.types.FETCH_GENERAL_REQUIREMENT_LISTING,
			locationState,
		}),
		setGeneralRequirementListing: data => ({
			type: duck.types.SET_GENERAL_REQUIREMENT_LISTING,
			data,
		}),
		setGeneralRequirementPaginationQueries: query => ({
			type: duck.types.SET_GENERAL_REQUIREMENT_PAGINATION_QUERIES,
			query,
		}),
		setActiveTabs: (actor, activeTab) => ({
			type: duck.types.SET_ACTIVE_TABS,
			actor,
			activeTab,
		}),
		fetchGlobalProductsNoPagination: () => ({
			type: duck.types.FETCH_GLOBAL_PRODUCTS_NO_PAGINATION,
		}),
		setGlobalProductsNoPagination: data => ({
			type: duck.types.SET_GLOBAL_PRODUCTS_NO_PAGINATION,
			data,
		}),
		postGeneralRequirement: (emails, allSellers, payload) => ({
			type: duck.types.POST_GENERAL_REQUIREMENT,
			emails,
			allSellers,
			payload,
		}),
		setActiveFiltersMarketPlaceSearch: filters => ({
			type: duck.types.SET_ACTIVE_FILTERS_MARKETPLACE_SEARCH,
			filters,
		}),
		flushMarketPlaceSearchResults: () => ({
			type: duck.types.FLUSH_MARKETPLACE_SEARCH_RESULTS,
		}),
		flushMarketPlaceGlobalRFQResults: () => ({
			type: duck.types.FLUSH_MARKETPLACE_GLOBAL_RFQ_RESULTS,
		}),
		updatePostRequirementStatus: (status, rfqID) => ({
			type: duck.types.UPDATE_POST_REQUIREMENT_STATUS,
			status,
			rfqID,
		}),
		fetchSavedContractDetails: contractId => ({
			type: duck.types.FETCH_MARKETPLACE_SAVED_CONTRACT_DETAILS,
			contractId,
		}),
		fetchProcessingContractDetails: (
			userType,
			contractId,
			contractTemplateNo
		) => ({
			type: duck.types.FETCH_MARKETPLACE_PROCESSING_CONTRACT_DETAILS,
			userType,
			contractId,
			contractTemplateNo,
		}),
		setSavedContractDetails: savedContractDetails => ({
			type: duck.types.SET_MARKETPLACE_SAVED_CONTRACT_DETAILS,
			savedContractDetails,
		}),
		setContractDetails: contractDetails => ({
			type: duck.types.SET_MARKETPLACE_CONTRACT_DETAILS,
			contractDetails,
		}),
		fetchESignDetails: contractId => ({
			type: duck.types.FETCH_MARKETPLACE_CONTRACT_E_SIGNAL_DETAILS,
			contractId,
		}),
		setESignDetails: eSignDetails => ({
			type: duck.types.SET_MARKETPLACE_CONTRACT_E_SIGNAL_DETAILS,
			eSignDetails,
		}),
		postContractDetails: (contractDetails, contractTemplateNo) => ({
			type: duck.types.POST_MARKETPLACE_CONTRACT_DETAILS,
			contractDetails,
			contractTemplateNo,
		}),
		postAttachedContractDetails: (
			contractDetails,
			contractTemplateNo,
			pdfFileData,
			pdfFileName,
			fileCount
		) => ({
			type: duck.types.POST_ATTACHED_MARKETPLACE_CONTRACT_DETAILS,
			contractDetails,
			contractTemplateNo,
			pdfFileData,
			pdfFileName,
			fileCount,
		}),
		declineContractDetails: (contractId, payload) => ({
			type: duck.types.DECLINE_MARKETPLACE_CONTRACT_DETAILS,
			contractId,
			payload,
		}),
		acceptedContractDetails: contractId => ({
			type: duck.types.ACCEPTED_MARKETPLACE_CONTRACT_DETAILS,
			contractId,
		}),
		retrySigningDocument: contractId => ({
			type: duck.types.RETRY_SIGNING_DOCUMENT,
			contractId,
		}),
		initiateEditContractDetails: contractId => ({
			type: duck.types.INITIATE_EDIT_CONTRACT_DETAILS,
			contractId,
		}),
		setEditContractInitialValues: details => ({
			type: duck.types.SET_EDIT_CONTRACT_INITIAL_VALUES,
			details,
		}),
		editContract: (contractDetails, contractTemplateNo) => ({
			type: duck.types.EDIT_CONTRACT,
			contractDetails,
			contractTemplateNo,
		}),
		setPreviewValues: details => ({
			type: duck.types.SET_PREVIEW_VALUES,
			details,
		}),
		setDocumentViewStatus: status => ({
			type: duck.types.SET_DOCUMENT_VIEW_STATUS,
			status,
		}),
		rfqTemplateSearch: searchQuery => ({
			type: duck.types.RFQ_TEMPLATE_SEARCH,
			searchQuery,
		}),
		rfqTemplateList: () => ({
			type: duck.types.RFQ_TEMPLATE_LIST,
		}),
		setRespondToRequirementLoader: status => ({
			type: duck.types.SET_RESPOND_TO_REQUIREMENT_LOADER,
			status,
		}),
	}),
})
