import { getIn } from 'timm'
import { isMarketplaceHost } from '../utils/helpers'

const lookUpConfig = key =>
	getIn(window._env_, [key]) || getIn(process.env, [key]) || ''

// All the config env variables should starts with REACT_APP_
export const getAppVersion = () => lookUpConfig('REACT_APP_VERSION') || ''
export const getCurrentProject = () => lookUpConfig('REACT_APP_PROJECT') || ''

export const getIAMEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_IAM_URL'
			: 'REACT_APP_IAM_URL'
	) || ''}/`

export const getMapBoxAccessToken = () =>
	lookUpConfig('REACT_APP_MAPBOX_ACCESS_TOKEN') || ''

export const getseparatedTDMHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_TDM_HOST'
			: 'REACT_APP_TDM_HOST'
	) || ''}`

export const getOriginHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_ORIGIN_HOST'
			: 'REACT_APP_ORIGIN_HOST'
	) || ''}`

export const getSeparatedMarketPlaceHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_MP_HOST'
			: 'REACT_APP_MP_HOST'
	) || ''}`

export const getSeparatedLoginHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_LOGIN_HOST'
			: 'REACT_APP_LOGIN_HOST'
	) || ''}`
