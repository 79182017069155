import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { P } from 'ui-lib/components/Typography'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import RightArrow from 'ui-lib/icons/right-arrow.svg'
import theme from 'ui-lib/utils/base-theme'

const CollapsibleSegment = styled.section`
	max-height: ${props => (props.open ? '100%' : '0')};
	overflow: hidden;
	padding: ${props => (props.open ? '25px 0' : '0')};
	transition: all 0.3s ease-out;
`

const ContractBlock = ({
	heading,
	subheading,
	contractType,
	rootModule,
	documentReference,
	isMobile = false,
}) => {
	const dispatch = useDispatch()

	return (
		<Box
			style={{
				background: '#FFFFFF 0% 0% no-repeat padding-box',
				border: '1px solid #7680DE',
				borderRadius: 10,
				opacity: 1,
				width: 960,
				marginTop: 20,
			}}
			padding={isMobile ? '24px 55px 28px 32px' : '24px 54px 28px 32px'}
			row
		>
			<Box style={{ width: 580 }}>
				<P style={{ fontSize: 24, color: '#1D285D' }} bold>
					{heading}
				</P>
				<P style={{ color: '#6F7AAD', fontSize: theme.fontSize.l }}>
					{subheading}
				</P>
			</Box>
			<Button
				customIcon={
					<Box
						style={{
							height: isMobile ? 32 : 48,
							width: isMobile ? 32 : 48,
							borderRadius: '50%',
							background: '#F6F7F9',
							cursor: 'pointer',
						}}
						center
					>
						<IconWrapper>
							<Icon
								glyph={RightArrow}
								size={20}
								color="#CAD2DD"
							/>
						</IconWrapper>
					</Box>
				}
				extendStyles={{ marginLeft: isMobile ? 0 : 150 }}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'generate',
								documentReference,
								documentType: contractType,
							}
						)
					)
				}}
			/>
		</Box>
	)
}

const CreateContract = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [accordionState, setAccordionState] = useState(false)
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule, documentReference } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	return (
		<Box padding={8} width="100%" height="100%">
			<Title
				title={t('marketPlace.createContractHeading')}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$ACTION,
							{
								rootModule,
								action: 'view-quote',
								documentReference,
							}
						)
					)
				}}
				isMobile={isMobile}
				hasNoMaxWidth
			/>
			<Box
				style={{
					opacity: 1,
					width: 960,
					display: 'flex',
					borderRadius: 10,
					border: '1px solid #7680DE',
					justifyContent: 'space-between',
					background: '#FFFFFF 0% 0% no-repeat padding-box',
				}}
				padding="24px 54px 28px 32px"
				row
			>
				<Box>
					<P style={{ fontSize: 24, color: '#1D285D' }} bold>
						{t('marketPlace.attachContractHeading')}
					</P>
					<P style={{ color: '#6F7AAD', fontSize: theme.fontSize.l }}>
						{t('contracts.attachContractSubHeading')}
					</P>
				</Box>
				<Button
					customIcon={
						<Box
							style={{
								height: isMobile ? 32 : 48,
								width: isMobile ? 32 : 48,
								borderRadius: '50%',
								background: theme.color.primary,
								cursor: 'pointer',
							}}
							center
						>
							<IconWrapper>
								<Icon
									glyph={RightArrow}
									size={20}
									color={theme.color.white}
								/>
							</IconWrapper>
						</Box>
					}
					extendStyles={{ marginLeft: isMobile ? 0 : 150 }}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.MARKETPLACE$CONTRACTSWDOCTYPE,
								{
									rootModule,
									action: 'generate',
									documentReference,
									documentType: 'custom',
								}
							)
						)
					}}
				/>
			</Box>
			<Box
				style={{
					width: 960,
					opacity: 1,
					marginTop: 32,
					borderRadius: 10,
					border: '1px solid #7680DE',
					background: '#FFFFFF 0% 0% no-repeat padding-box',
				}}
				padding={
					isMobile ? '24px 0px 28px 32px' : '24px 54px 28px 32px'
				}
			>
				<Box
					row
					style={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Box width={isMobile ? '80%' : 'auto'}>
						<P style={{ fontSize: 24, color: '#2B7E60' }} bold>
							{t('contracts.dibizContractGeneratorHeading')}
						</P>
						<P
							style={{
								color: '#8BBAA9',
								fontSize: theme.fontSize.l,
							}}
						>
							{t('contracts.dibizContractGeneratorSubheading')}
						</P>
					</Box>
					<Button
						customIcon={
							<Box
								style={{
									height: isMobile ? 32 : 48,
									width: isMobile ? 32 : 48,
									borderRadius: '50%',
									background: '#8BBAA9',
									cursor: 'pointer',
								}}
								center
							>
								<IconWrapper>
									<Icon
										style={{
											transform: accordionState
												? 'rotate(-90deg)'
												: '',
										}}
										glyph={RightArrow}
										size={20}
										color={theme.color.white}
									/>
								</IconWrapper>
							</Box>
						}
						extendStyles={{ marginLeft: isMobile ? 0 : 245 }}
						onClick={() => setAccordionState(!accordionState)}
					/>
				</Box>
				<CollapsibleSegment open={accordionState}>
					<ContractBlock
						heading={t('contracts.contractNo1')}
						subheading="Domestic Contract for Malaysian Crude Unbleached Palm Oil in Bulk"
						contractType="contract-no-1"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
					<ContractBlock
						heading={t('contracts.contractNo2')}
						subheading="FOB Contract for Processed Palm Oil and Palm Kernel Oil Products in Bulk"
						contractType="contract-no-2"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
					<ContractBlock
						heading={t('contracts.contractNo3')}
						subheading="Domestic Contract for Malaysian Processed Palm Oil in Bulk (For Delivery/Collection within Malaysia including Singapore)"
						contractType="contract-no-3"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
					<ContractBlock
						heading={t('contracts.contractNo4')}
						subheading="FOB Contract for Processed Palm Oil Products in Drums"
						contractType="contract-no-4"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
					<ContractBlock
						heading={t('contracts.contractNo5')}
						subheading="CIF Contract for Processed Palm Oil Products in Drums"
						contractType="contract-no-5"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
					<ContractBlock
						heading={t('contracts.contractNo6')}
						subheading="Domestic Sales Contract for Malaysian Crude Unbleached Palm Oil In Bulk CIF Delivered Weights for Sales between East and Peninsular Malaysia"
						contractType="contract-no-6"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
					<ContractBlock
						heading={t('contracts.contractNo7')}
						subheading="Joint FOB Contract for Crude Palm Oil and Crude Palm Kernel Oil in Bulk issued jointly by The Palm Oil Refiners Association of Malaysia, Malayan Edible Oil Manufacturers’ Association and Malaysian Palm Oil Association."
						contractType="contract-no-7"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
					<ContractBlock
						heading={t('contracts.contractNo8')}
						subheading="FOB Contract for Processed Palm Oil Products in Packed Form"
						contractType="contract-no-8"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
					<ContractBlock
						heading={t('contracts.contractNo9')}
						subheading="CIF Contract for Processed Palm Oil Products in Packed Form"
						contractType="contract-no-9"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
					<ContractBlock
						heading="FOSFA 81"
						subheading="Contract for Palm and Palm Kernel Oil Products in Bulk (CIF) issued jointly by FOSFA International/PORAM/MEOMA"
						contractType="fosfa81"
						rootModule={rootModule}
						documentReference={documentReference}
						isMobile={isMobile}
					/>
				</CollapsibleSegment>
			</Box>
		</Box>
	)
}

export { CreateContract }
