import React from 'react'
import { getIn } from 'timm'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	deliveryPeriodConfig,
	monthConfig,
} from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Forms/ContractNo1'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Input } from 'ui-lib/components/Input'
import { Button } from 'ui-lib/components/Button'
import { Select } from 'ui-lib/components/Select'
import { SmallText, H3, P, Text } from 'ui-lib/components/Typography'
import { TextArea } from 'ui-lib/components/TextArea'
import { Card } from 'ui-lib/components/Card'
import { Label } from 'ui-lib/components/Label'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import theme from 'ui-lib/utils/base-theme'
import * as yup from 'yup'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'

const ContractNo4 = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const document = useSelector(MarketPlaceDuc.selectors.getQuoteDocument)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const {
		quantity,
		rfq = {},
		unitRate,
		additionalDetails,
		paymentTerms,
		organization,
		id: _quoteID,
		tradeID,
	} = document
	const { product = {}, id: _rfqID } = rfq

	const initialValuesForEdit =
		useSelector(MarketPlaceDuc.selectors.getEditContractInitialValues) || {}

	const initialValuesForCreate = {
		contractNo: '',
		quantity,
		product: getIn(product, ['name']),
		currency: getIn(document, ['currency', 'code']),
		brokerName: '',
		brokerEmail: '',
		date: '',
		unitRate,
		paymentTerms,
		month: getIn(document, ['shipmentPeriod', 'month']),
		period: getIn(document, ['shipmentPeriod', 'period']),
		specialConditions: '',
		productSpecification: additionalDetails,
		ladingPort: getIn(document, ['rfq', 'ladingPort']),
	}

	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { rootModule, documentReference, documentType, action } = payload
	const { preview } = query

	const currentOrgDetails = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)

	const previewDetails = useSelector(
		MarketPlaceDuc.selectors.getContractPreviewDetails
	)

	const validationSchema = yup.object().shape({
		quantity: yup
			.number()
			.positive()
			.required(),
		brokerName: yup.string(),
		brokerEmail: yup.string(),
		currency: yup.string().required(),
		unitRate: yup
			.number()
			.positive()
			.required(),
	})

	let initialValues = {}

	if (preview === true) {
		initialValues = previewDetails
	} else if (action === 'edit') {
		initialValues = initialValuesForEdit
	} else if (action === 'generate') {
		initialValues = initialValuesForCreate
	}
	const {
		handleSubmit,
		values,
		handleChange,
		submitForm,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const requestPayload = {
				tradeID,
				contractType: 'poram',
				contract: {
					contractNo: _values.contractNo,
					date: _values.date === '' ? new Date() : _values.date,
					shipmentPeriod: {
						month: _values.month,
						period: _values.period,
					},
					packaging: {
						type: _values.packaging,
					},
					seller: {
						id: getIn(organization, ['id']),
						name: getIn(organization, ['name']),
						primaryContact: getIn(organization, ['primaryContact']),
						primaryAddress: getIn(organization, ['primaryAddress']),
					},
					buyer: {
						id: getIn(currentOrgDetails, ['id']),
						name: getIn(currentOrgDetails, ['name']),
						primaryContact: getIn(currentOrgDetails, [
							'primaryContact',
						]),
						primaryAddress: getIn(currentOrgDetails, [
							'primaryAddress',
						]),
					},
					broker: {
						name: _values.brokerName,
						primaryContact: {
							email: _values.brokerEmail,
						},
					},
					referenceNo: [_quoteID, _rfqID],
					product: {
						...product,
						product: {
							name: product.name,
						},
						meta: {
							specifications: _values.productSpecification,
							packagingType: _values.drum,
							port: _values.ladingPort,
						},
					},
					quantity: _values.quantity,
					unitRate: parseFloat(_values.unitRate),
					currency: { code: _values.currency },
					specialConditions: _values.specialConditions,
					paymentTerms: _values.paymentTerms,
				},
				meta: {
					contractModel: documentType,
					quoteID: _quoteID,
				},
			}

			if (action === 'edit') {
				requestPayload.id = documentReference
				requestPayload.contract.seller = initialValuesForEdit.seller
				requestPayload.tradeID = initialValuesForEdit.tradeID
			}

			dispatch(MarketPlaceDuc.creators.setPreviewValues(_values))
			dispatch(MarketPlaceDuc.creators.setContractDetails(requestPayload))
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
					{
						rootModule,
						action: 'preview-participants',
						documentReference,
						documentType,
					},
					{
						contractAction: action === 'edit' ? 'edit' : 'generate',
					}
				)
			)
		},
	})

	return (
		<>
			<Box>
				<Title
					title={
						action === 'edit'
							? t('contracts.editContract')
							: t('contracts.createContractDetails')
					}
					icon={LeftArrowIcon}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$CONTRACTS,
								{
									rootModule,
									action: 'create',
									documentReference,
								}
							)
						)
					}}
					isMobile={isMobile}
					hasNoMaxWidth
				/>
			</Box>
			<Box row={!isMobile} justifyContent="space-between">
				<Box
					style={{
						width: isMobile ? '100%' : '65%',
						background: '#fff',
						padding: '22px',
						marginRight: '20px',
					}}
				>
					<Box
						row={!isMobile}
						justifyContent="flex-start"
						alignItems="baseline"
					>
						<Box
							style={{
								overflow: 'hidden',
							}}
						>
							<H3
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
									width: '100%',
									color: '#8D93A0',
								}}
								title="FOB Contract for Processed Palm Oil Products in Drums"
							>
								FOB Contract for Processed Palm Oil Products in
								Drums
							</H3>
						</Box>
					</Box>
					<Spacer size={40} />
					<form onSubmit={handleSubmit}>
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: 18,
									color: '#8D93A0',
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('contracts.mainContractDetails')}
							</SmallText>
							<Box
								row={!isMobile}
								style={{ marginTop: '28px' }}
								justifyContent="space-between"
							>
								<Box
									style={{
										width: isMobile ? '100%' : '304px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.brokerName')}
									</Label>

									<Input
										value={values.brokerName}
										name="brokerName"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.brokerName &&
											errors.brokerName
										}
									/>
								</Box>

								<Box
									style={{
										width: isMobile ? '100%' : '304px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
										bold
									>
										{t('contracts.brokerEmailAddress')}
									</Label>
									<Input
										value={values.brokerEmail}
										name="brokerEmail"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.brokerEmail &&
											errors.brokerEmail
										}
									/>
								</Box>
							</Box>
							<Box row={!isMobile} justifyContent="space-between">
								<Box
									style={{
										width: isMobile ? '100%' : '304px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.contractNumber')}
									</Label>

									<Input
										value={values.contractNo}
										name="contractNo"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.contractNo &&
											errors.contractNo
										}
									/>
								</Box>
								<Box
									style={{
										width: isMobile ? '100%' : '304px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.date')}
									</Label>
									<DatePicker
										name="date"
										minDate={
											action === 'edit'
												? null
												: new Date()
										}
										value={values.date || new Date()}
										onChange={value => {
											setFieldValue(
												'date',
												new Date(value)
											)
										}}
										required
									/>
								</Box>
							</Box>
							<Box row={!isMobile} justifyContent="space-between">
								<Box
									style={{
										width: isMobile ? '100%' : '304px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.product')}
									</Label>

									<Input
										value={values.product}
										name="product"
										disabled
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.product && errors.product
										}
									/>
								</Box>

								<Box
									style={{
										width: isMobile ? '100%' : '304px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.quantityMT')}
									</Label>
									<Input
										value={values.quantity}
										name="quantity"
										type="number"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.quantity && errors.quantity
										}
									/>
								</Box>
							</Box>
							<Box
								row={!isMobile}
								justifyContent="space-between"
								margin="8px 0 0"
							>
								<Box>
									<Label
										style={{
											fontSize: '14px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.shipmentPeriod')}
									</Label>
									<Box row={!isMobile} margin="8px 0 0">
										<Box width={isMobile ? '100%' : 140}>
											<Select
												value={values.month}
												options={monthConfig(t)}
												key={values.month}
												labelKey="label"
												valueKey="name"
												onChange={value =>
													setFieldValue(
														'month',
														value.name
													)
												}
												returnOnlyValue
											/>
										</Box>

										<Box
											width={isMobile ? '100%' : 144}
											margin={
												isMobile ? '0px' : '0 0 0 16px'
											}
										>
											<Select
												value={values.period}
												options={deliveryPeriodConfig(
													t
												)}
												key={values.period}
												labelKey="label"
												valueKey="name"
												onChange={value =>
													setFieldValue(
														'period',
														value.name
													)
												}
												returnOnlyValue
											/>
										</Box>
									</Box>
								</Box>
								<Box
									style={{
										width: isMobile ? '100%' : '304px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.loadPort')}
									</Label>
									<Input
										value={values.ladingPort}
										name="ladingPort"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.ladingPort &&
											errors.ladingPort
										}
									/>
								</Box>
							</Box>
							<Box row={!isMobile} justifyContent="space-between">
								<Box
									style={{
										width: isMobile ? '100%' : '304px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.packaging')}
									</Label>

									<Input
										value={values.packaging}
										name="packaging"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.packaging &&
											errors.packaging
										}
									/>
								</Box>

								<Box
									style={{
										width: isMobile ? '100%' : '304px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
											fontWeight: 'bold',
										}}
									>
										{t('contracts.typeOfDrum')}
									</Label>
									<Input
										value={values.drum}
										name="drum"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={touched.drum && errors.drum}
									/>
								</Box>
							</Box>
							<Box row={!isMobile}>
								<Box width={isMobile ? '100%' : 140}>
									<Label
										style={{
											color: '#242845',
											fontSize: 14,
											margin: '0 0 8px 0px',
											fontWeight: 'bold',
										}}
									>
										{t('contracts.currency')}
									</Label>
									<Select
										value={values.currency}
										name="currency"
										options={[
											{
												label: 'USD',
												name: 'USD',
											},
											{
												label: 'MYR',
												name: 'MYR',
											},
											{
												label: 'INR',
												name: 'INR',
											},
										]}
										onChange={value =>
											setFieldValue(
												'currency',
												value.currency.label
											)
										}
										key={values.currency}
										labelKey="label"
										returnKeyValue
										valueKey="name"
										onBlur={handleBlur}
										error={
											touched.currency && errors.currency
										}
									/>
								</Box>

								<Box
									width={isMobile ? '100%' : 148}
									margin={isMobile ? '0px' : '0 0 0 16px'}
								>
									<Label
										style={{
											color: '#242845',
											fontSize: 14,
											margin: '0 0 8px 0px',
											fontWeight: 'bold',
										}}
									>
										{t('contracts.pricePerMT')}
									</Label>
									<Input
										value={values.unitRate}
										name="unitRate"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.unitRate && errors.unitRate
										}
									/>
								</Box>
							</Box>
						</Card>

						<Spacer size={32} />
						<Card
							style={{
								padding: '0px 20px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: 18,
									color: '#8D93A0',
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('contracts.pricePerMT')}
							</SmallText>
							<Spacer size={32} />

							<Box>
								<Label
									style={{
										fontSize: '16px',
										margin: '0 0 8px',
										color: theme.color.black3,
									}}
								>
									{t('contracts.productSpecs')}
								</Label>
								<TextArea
									name="productSpecification"
									value={values.productSpecification}
									onBlur={handleBlur}
									onChange={e => {
										setFieldValue(
											'productSpecification',
											e.target.value
										)
									}}
									autoFocus={false}
								/>
							</Box>
							<Box>
								<Label
									style={{
										fontSize: '16px',
										margin: '0 0 8px',
										color: theme.color.black3,
									}}
								>
									{t('contracts.specialConditions')}
								</Label>
								<TextArea
									name="specialConditions"
									value={values.specialConditions}
									onBlur={handleBlur}
									onChange={e => {
										setFieldValue(
											'specialConditions',
											e.target.value
										)
									}}
									autoFocus={false}
								/>
							</Box>
						</Card>
						<Box margin="20px 0 0">
							<Label
								style={{
									fontSize: '16px',
									margin: '0 0 8px',
									color: theme.color.black3,
								}}
							>
								{t('contracts.paymentMethodAndTerms')}
							</Label>
							<TextArea
								name="paymentTerms"
								value={values.paymentTerms}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'paymentTerms',
										e.target.value
									)
								}}
								autoFocus={false}
							/>
						</Box>
					</form>
				</Box>
				<Box
					style={{
						width: isMobile ? '100%' : '33%',
					}}
				>
					<Box
						style={{
							background: '#fff',
							padding: '28px',
						}}
					>
						<Label style={{ color: theme.color.blue5 }}>
							{t('contracts.seller')}
						</Label>
						<Spacer size={8} />

						<Title
							title={
								getIn(organization, ['name']) ||
								getIn(initialValuesForEdit, ['seller', 'name'])
							}
							note={getIn(organization, [
								'categories',
								0,
								'name',
							])}
							isMobile={isMobile}
							hasNoMaxWidth
						/>
						<Spacer size={8} />
						<Text
							style={{
								fontSize: 14,
								color: '#8D93A0',
								marginBottom: 8,
							}}
						>
							{t('contracts.address')}
						</Text>
						<P>{`${getIn(organization, [
							'primaryAddress',
							'line1',
						]) ||
							getIn(initialValuesForEdit, [
								'seller',
								'primaryAddress',
								'line1',
							]) ||
							''}, ${getIn(organization, [
							'primaryAddress',
							'city',
						]) ||
							getIn(initialValuesForEdit, [
								'seller',
								'primaryAddress',
								'city',
							]) ||
							''},
 ${getIn(organization, ['primaryAddress', 'state']) ||
		getIn(initialValuesForEdit, ['seller', 'primaryAddress', 'state']) ||
		''}, ${getIn(organization, ['primaryAddress', 'country']) ||
							getIn(initialValuesForEdit, [
								'seller',
								'primaryAddress',
								'country',
							]) ||
							''}.`}</P>

						<Spacer size={20} />
						<Text
							style={{
								fontSize: 14,
								color: '#8D93A0',
								marginBottom: 8,
							}}
						>
							{t('contracts.stateCountry')}
						</Text>
						<P>{`
							${getIn(organization, ['primaryAddress', 'state']) ||
								getIn(initialValuesForEdit, [
									'seller',
									'primaryAddress',
									'state',
								]) ||
								''}, ${getIn(organization, [
							'primaryAddress',
							'country',
						]) ||
							getIn(initialValuesForEdit, [
								'seller',
								'primaryAddress',
								'country',
							]) ||
							''}.`}</P>

						<Spacer size={12} />
					</Box>
					<Box
						style={{
							background: '#fff',
							padding: '28px',
							marginTop: 32,
						}}
					>
						<Label style={{ color: theme.color.blue5 }}>
							{t('contracts.buyer')}
						</Label>
						<Spacer size={8} />

						<Title
							title={getIn(currentOrgDetails, ['name'])}
							note={getIn(currentOrgDetails, [
								'categories',
								0,
								'name',
							])}
							isMobile={isMobile}
							hasNoMaxWidth
						/>
						<Spacer size={8} />
						<Text
							style={{
								fontSize: 14,
								color: '#8D93A0',
								marginBottom: 8,
							}}
						>
							{t('contracts.address')}
						</Text>
						<P>{`${getIn(currentOrgDetails, [
							'primaryAddress',
							'line1',
						]) || ''} ${getIn(currentOrgDetails, [
							'primaryAddress',
							'city',
						]) || ''},
 ${getIn(currentOrgDetails, ['primaryAddress', 'state']) ||
		''}, ${getIn(currentOrgDetails, ['primaryAddress', 'country']) ||
							''}.`}</P>

						<Spacer size={20} />
						<Text
							style={{
								fontSize: 14,
								color: '#8D93A0',
								marginBottom: 8,
							}}
						>
							{t('contracts.stateCountry')}
						</Text>
						<P>{`
							${getIn(currentOrgDetails, ['primaryAddress', 'state']) ||
								''} , ${getIn(currentOrgDetails, [
							'primaryAddress',
							'country',
						]) || ''}`}</P>
					</Box>
				</Box>
			</Box>
			<Box
				width={isMobile ? '100%' : '65%'}
				row
				justifyContent={isMobile ? 'center' : 'flex-end'}
				margin="36px 0 0"
			>
				<Button
					label={t('contracts.generateDigitalContract')}
					primary
					onClick={() => submitForm()}
					extendStyles={{ width: 266 }}
				/>
			</Box>
		</>
	)
}

export { ContractNo4 }
