import React, { useCallback } from 'react'
import { getIn } from 'timm'
import { useTranslation } from 'react-i18next'
import { BoxShadowTable } from 'ui-marketplace-app/shared/components/BoxShadowTable'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import {
	TABLE_ALIAS,
	RFQ_STATUS,
	STATUS_TEXT_COLOR,
	STATUS_BACKGROUND,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { getDateByFormat } from 'ui-marketplace-app/utils/date'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { ShowMore } from 'ui-marketplace-app/modules/MarketPlace/components/ShowMore'
import { Spacer } from 'ui-lib/utils/Spacer'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { useSelector, useDispatch } from 'react-redux'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'

export const columnConfig = ({ t, onChange, actor, isMobile = false }) => {
	return [
		...(!isMobile
			? [
					{
						property: 'dateSent',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
							>
								<TableTitle>
									{t(TABLE_ALIAS.dateSent)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const dateSent = getIn(datum, ['createdAt'])

							return <Label>{getDateByFormat(dateSent)}</Label>
						},
					},
			  ]
			: []),
		{
			property: 'buyer',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.seller)}</TableTitle>
				</Box>
			),
			render: datum => {
				const buyer = getIn(datum, ['buyerOrg', 'name'])
				const seller = getIn(datum, ['organization', 'name'])

				const org = actor === 'mp_purchase_officer' ? seller : buyer

				return <Label>{org}</Label>
			},
		},
		...(!isMobile
			? [
					{
						property: 'country',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
							>
								<TableTitle>
									{t(TABLE_ALIAS.country)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const country =
								getIn(datum, [
									'buyerOrg',
									'primaryAddress',
									'country',
								]) || '---'

							return <Label>{country}</Label>
						},
					},
			  ]
			: []),
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const product = getIn(datum, ['rfq', 'product', 'name'])
				const quantity = getIn(datum, ['quantity'])
				const uom = getIn(datum, ['rfq', 'product', 'uom']) || ''

				return (
					<>
						<Label>{product}</Label>{' '}
						{isMobile && (
							<Label>
								{quantity} {uom.toUpperCase()}
							</Label>
						)}
					</>
				)
			},
		},
		...(!isMobile
			? [
					{
						property: 'quantity',
						header: (
							<Box
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
							>
								<TableTitle>
									{t(TABLE_ALIAS.quantity)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const quantity = getIn(datum, ['quantity'])
							const uom =
								getIn(datum, ['rfq', 'product', 'uom']) || ''

							return (
								<Label>
									{quantity} {uom.toUpperCase()}
								</Label>
							)
						},
					},
			  ]
			: []),
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status'])

				return (
					<Box
						style={{
							backgroundColor: STATUS_BACKGROUND[status],
							padding: '8px 16px',
							margin: '0 0 8px',
							borderRadius: '5px',
							opacity: 1,
							textAlign: 'center',
						}}
					>
						<Label
							style={{
								color: STATUS_TEXT_COLOR[status],
							}}
						>
							{t(RFQ_STATUS[status]).toUpperCase()}
						</Label>
					</Box>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('view_document', {
								row: datum,
							})
						}}
					/>
				)
			},
		},
	]
}

const QuoteListing = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const buyerQuoteList = useSelector(
		MarketPlaceDuc.selectors.getBuyerQuotesList
	)
	const sellerQuoteList = useSelector(
		MarketPlaceDuc.selectors.getSellerQuotesList
	)

	const buyerQuotesPagination = useSelector(
		MarketPlaceDuc.selectors.getBuyerQuotesPaginationQueries
	)
	const sellerQuotesPagination = useSelector(
		MarketPlaceDuc.selectors.getSellerQuotesRFQPaginationQueries
	)

	const actor = useSelector(MarketPlaceDuc.selectors.getMarketPlaceActor)

	const pagination =
		actor === 'mp_purchase_officer'
			? buyerQuotesPagination
			: sellerQuotesPagination

	const quoteList =
		actor === 'mp_purchase_officer' ? buyerQuoteList : sellerQuoteList

	const onChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'view_document': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$ACTION,
								{
									rootModule,
									action: 'view-quote',
									documentReference: getIn(meta, [
										'row',
										'id',
									]),
								}
							)
						)
					}
					break
				}

				case 'quote_pagination': {
					const locationState = {
						nextStartID: pagination.nextStartID,
					}

					dispatch(
						MarketPlaceDuc.creators.fetchQuotesListing(
							rootModule,
							locationState
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, pagination.nextStartID, rootModule]
	)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('quote.quotation'),
			name: 'quotation',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	return (
		<>
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Title
				title={
					rootModule === 'buyer'
						? t('quote.showingIncomingQuotations')
						: t('quote.showingOutgoingQuotations')
				}
			/>
			<Spacer size={12} />

			<BoxShadowTable
				size="medium"
				columnConfig={columnConfig({
					t,
					onChange,
					actor,
					isMobile,
				})}
				rowData={quoteList}
				isMobile={isMobile}
			/>
			<ShowMore
				documents={quoteList}
				total={pagination.total}
				handleChange={onChange}
				type="quote_pagination"
			/>
		</>
	)
}

export { QuoteListing }
