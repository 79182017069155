import React, { useState, useEffect } from 'react'
import { getIn } from 'timm'
import { Modal } from 'ui-lib/components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { MessagesDuc } from 'ui-marketplace-app/modules/Messages/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { ChatModalComponent } from 'ui-marketplace-app/modules/Messages/components/ChatModalComponent'
import {
	RFQ_STATUS,
	STATUS_TEXT_COLOR,
	STATUS_BACKGROUND,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import moment from 'moment'
import { Grommet } from 'grommet'
import { P, Text, SmallText } from 'ui-lib/components/Typography'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { Label } from 'ui-lib/components/Label'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import InfoIcon from 'ui-lib/icons/info_black.svg'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import RightArrowIcon from 'ui-lib/icons/arrow_forward_black_24dp.svg'
import DownloadIcon from 'ui-lib/icons/file_download_black_24dp.svg'
import { TextArea } from 'ui-lib/components/TextArea'
import { Document, Page, pdfjs } from 'react-pdf'
import { Wrapper } from '../Enquiries/ViewRFQ'
import { DigitalContractMessage } from '../../components/DigitalContractMessage'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const declineButtonTheme = {
	button: {
		border: {
			radius: '4px',
			color: theme.color.soft_red,
			width: '1px',
		},
	},
}

const ViewAttachedContract = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const [showModal, setShowModal] = useState(false)
	const [message, setMessage] = useState('')
	const [totalPages, setTotalPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [showChatBox, setShowChatBox] = useState(false)
	const [currentUserModule, setCurrentUserModule] = useState('buyer')
	const { payload } = location
	const { documentReference } = payload
	const { fullName } = useSelector(AuthDuc.selectors.getUserProfile)
	const userProf = useSelector(AuthDuc.selectors.getUserProfile)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const savedContractDetails = useSelector(
		MarketPlaceDuc.selectors.getSavedContractDetails
	)
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	useEffect(() => {
		dispatch(MessagesDuc.creators.setDocTypeList([]))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (
			getIn(userProf, ['organization', 'id']) ===
			getIn(savedContractDetails, ['contract', 'buyer', 'id'])
		) {
			setCurrentUserModule('buyer')
		} else if (
			getIn(userProf, ['organization', 'id']) ===
			getIn(savedContractDetails, ['contract', 'seller', 'id'])
		) {
			setCurrentUserModule('seller')
		} else {
			setCurrentUserModule('broker')
		}
	}, [savedContractDetails, userProf])

	const getData = data => {
		switch (data) {
			case 'seller': {
				return currentUserModule === 'seller'
					? getIn(savedContractDetails, [
							'contract',
							'seller',
							'name',
					  ])
					: getIn(savedContractDetails, [
							'contract',
							'broker',
							'name',
					  ])
			}
			case 'buyer': {
				return currentUserModule === 'seller'
					? getIn(savedContractDetails, [
							'contract',
							'broker',
							'name',
					  ])
					: getIn(savedContractDetails, ['contract', 'buyer', 'name'])
			}
			case 'buyerID': {
				return currentUserModule === 'seller'
					? getIn(savedContractDetails, ['contract', 'broker', 'id'])
					: getIn(savedContractDetails, ['contract', 'buyer', 'id'])
			}
			case 'sellerID': {
				return currentUserModule === 'seller'
					? getIn(savedContractDetails, ['contract', 'seller', 'id'])
					: getIn(savedContractDetails, ['contract', 'broker', 'id'])
			}

			case 'currentDocType': {
				return currentUserModule === 'seller'
					? 'contract-broker-seller'
					: 'contract-buyer-broker'
			}

			default:
				break
		}
	}

	const seller =
		getIn(savedContractDetails, ['contract', 'broker']) &&
		getIn(savedContractDetails, ['contract', 'broker', 'id'])
			? getData('seller')
			: getIn(savedContractDetails, ['contract', 'seller', 'name'])
	const buyer =
		getIn(savedContractDetails, ['contract', 'broker']) &&
		getIn(savedContractDetails, ['contract', 'broker', 'id'])
			? getData('buyer')
			: getIn(savedContractDetails, ['contract', 'buyer', 'name'])
	const buyerID =
		getIn(savedContractDetails, ['contract', 'broker']) &&
		getIn(savedContractDetails, ['contract', 'broker', 'id'])
			? getData('buyerID')
			: getIn(savedContractDetails, ['contract', 'buyer', 'id'])
	const sellerID =
		getIn(savedContractDetails, ['contract', 'broker']) &&
		getIn(savedContractDetails, ['contract', 'broker', 'id'])
			? getData('sellerID')
			: getIn(savedContractDetails, ['contract', 'seller', 'id'])
	const currentDocType =
		getIn(savedContractDetails, ['contract', 'broker']) &&
		getIn(savedContractDetails, ['contract', 'broker', 'id'])
			? getData('currentDocType')
			: 'contract-buyer-seller'
	const tradeID = getIn(savedContractDetails, ['tradeID'])

	function onDocumentLoadSuccess({ numPages }) {
		setTotalPages(numPages)
	}

	const getStatus = userType => {
		const orgId = savedContractDetails
			? savedContractDetails.contract[userType].id
			: ''
		if (orgId) {
			const orgDetail = savedContractDetails.status.find(
				element => element.orgID === orgId
			)
			if (orgDetail) {
				return orgDetail.status
			}
		}

		return 'pending'
	}

	useEffect(() => {
		if (actor === 'palmoil_broker') {
			dispatch(MessagesDuc.creators.eventListing(tradeID))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeID, actor])

	const eventList = useSelector(MessagesDuc.selectors.getEventList)

	const getDocID = docType => {
		let ID = null
		if (eventList && eventList.data && eventList.data.length > 0) {
			const currItem = eventList.data.find(item => {
				return item.docType === docType
			})
			ID = currItem && currItem.meta && currItem.meta.documentID
		}

		return ID
	}

	const chatBoxData = data => {
		setShowChatBox(data)
	}

	const getGenerateButton = type => {
		return (
			<Button
				rounded
				label={t('contracts.generateESign')}
				reverse
				onClick={() => {
					if (
						type === 'buyer' &&
						!savedContractDetails.meta.signaturePackageID
					) {
						dispatch(
							MarketPlaceDuc.creators.retrySigningDocument(
								getIn(savedContractDetails, ['id'])
							)
						)
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.MARKETPLACE$CONTRACTSWDOCTYPE,
								{
									rootModule:
										currentUserModule === 'broker'
											? 'seller'
											: currentUserModule,
									action: 'processing',
									documentReference: getIn(
										savedContractDetails,
										['id']
									),
									documentType: getIn(savedContractDetails, [
										'meta',
										'contractModel',
									]),
								}
							)
						)
					} else {
						if (getStatus(type) === 'pending') {
							dispatch(
								MarketPlaceDuc.creators.acceptedContractDetails(
									getIn(savedContractDetails, ['id'])
								)
							)
						}
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.MARKETPLACE$CONTRACTSWDOCTYPE,
								{
									rootModule:
										currentUserModule === 'broker'
											? 'seller'
											: currentUserModule,
									action: 'e-sign',
									documentReference: getIn(
										savedContractDetails,
										['id']
									),
									documentType: getIn(savedContractDetails, [
										'meta',
										'contractModel',
									]),
								}
							)
						)
					}
				}}
				extendStyles={{
					width: 200,
					background: '#3F56C4',
					color: theme.color.white,
				}}
			/>
		)
	}
	const contractNumber = getIn(savedContractDetails, [
		'contract',
		'contractNo',
	])

	const versionNumber = getIn(savedContractDetails, ['version'])

	const status = getIn(savedContractDetails, ['status']) || []
	const isSignedByBrokerOrSeller =
		(status || []).filter(log => log.status === 'accepted').length > 1

	return (
		<Box
			style={{
				padding: '0px 70px',
				position: 'relative',
			}}
		>
			{getIn(savedContractDetails, ['meta', 'signed']) && (
				<a
					download
					target="_blank"
					rel="noopener noreferrer"
					title="Download Contract"
					href={getIn(savedContractDetails, [
						'contractAttachment',
						'meta',
						'fullURL',
					])}
				>
					<IconWrapper
						size={32}
						color="#000000"
						style={{
							top: 20,
							right: 70,
							cursor: 'pointer',
							position: 'absolute',
						}}
					>
						<Icon glyph={DownloadIcon} />
					</IconWrapper>
				</a>
			)}
			<Title
				title={t('contracts.viewContract')}
				icon={LeftArrowIcon}
				onClick={() =>
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
							{
								rootModule:
									currentUserModule === 'buyer'
										? 'outgoing'
										: 'incoming',
							},
							{},
							{},
							true
						)
					)
				}
			/>
			{currentUserModule === 'buyer' &&
				getIn(savedContractDetails, ['meta', 'signed']) && (
					<Box row justifyContent="space-between">
						<Box>
							{!isSignedByBrokerOrSeller &&
								getIn(savedContractDetails, ['versionMeta']) ===
									undefined && (
									<Wrapper>
										<Box
											row
											padding={16}
											alignContent="center"
										>
											<IconWrapper
												size={24}
												style={{
													marginRight: 16,
												}}
											>
												<Icon
													glyph={InfoIcon}
													color="#F09112"
												/>
											</IconWrapper>
											{t(
												'contracts.contactbrokerSellerText'
											)}
										</Box>
									</Wrapper>
								)}
							{getIn(savedContractDetails, ['versionMeta']) !==
								undefined && (
								<Wrapper>
									<Box row padding={16}>
										<IconWrapper
											size={24}
											style={{ marginRight: 16 }}
										>
											<Icon
												glyph={InfoIcon}
												color="#F09112"
											/>
										</IconWrapper>

										<div>
											{t('contracts.contractRevisedText')}{' '}
											<ButtonWithNoBorder
												label={`${contractNumber}, Revised Ver.${versionNumber}`}
												extendStyles={{
													color: '#C97B12',
												}}
												onClick={() => {
													dispatch(
														MainRouteDuc.creators.switchPage(
															MainRouteDuc.types
																.MARKETPLACE$CONTRACTS,
															{
																rootModule:
																	currentUserModule ===
																	'broker'
																		? 'seller'
																		: currentUserModule,
																action: 'view',
																documentReference: getIn(
																	savedContractDetails,
																	[
																		'versionMeta',
																		'revisedID',
																	]
																),
															}
														)
													)
												}}
											/>
											{t(
												'contracts.contractRevisedTextLine2'
											)}
										</div>
									</Box>
								</Wrapper>
							)}
						</Box>
						{getIn(savedContractDetails, ['versionMeta']) ===
							undefined && (
							<Box margin="16px 0">
								<Button
									rounded
									label={t('contracts.editContract')}
									onClick={() => {
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.MARKETPLACE$CONTRACTSWDOCTYPE,
												{
													rootModule:
														currentUserModule ===
														'broker'
															? 'seller'
															: currentUserModule,
													action: 'edit',
													documentReference,
													documentType: getIn(
														savedContractDetails,
														[
															'meta',
															'contractModel',
														]
													),
												}
											)
										)
									}}
								/>
							</Box>
						)}
					</Box>
				)}
			{currentUserModule === 'buyer' &&
				!getIn(savedContractDetails, ['meta', 'signed']) && (
					<Box style={{ alignItems: 'end' }}>
						{getGenerateButton('buyer')}
					</Box>
				)}
			{(currentUserModule === 'seller' ||
				currentUserModule === 'broker') &&
				!getIn(savedContractDetails, ['meta', 'signed']) &&
				((currentUserModule === 'broker' &&
					getStatus('broker') !== 'rejected') ||
					(currentUserModule === 'seller' &&
						getStatus('seller') !== 'rejected')) && (
					<Box
						style={{
							padding: 15,
							marginBottom: 40,
							alignItems: 'center',
							background: theme.color.white,
							justifyContent: 'space-between',
						}}
						row
					>
						<Box
							style={{
								width: '52%',
								alignItems: 'end',
								marginRight: '5%',
								padding: '15px 10px',
								background: theme.color.white,
							}}
						>
							<SmallText
								style={{
									fontSize: 14,
									color: theme.color.grey15,
									lineHeight: '19px',
									textAlign: 'justify',
								}}
							>
								{t('contracts.e-signNudgeUser')}
							</SmallText>
						</Box>

						<Box style={{ display: 'inline-block' }}>
							<Grommet
								theme={declineButtonTheme}
								style={{ float: 'left' }}
							>
								<Button
									rounded
									label={t('common.decline')}
									onClick={() => setShowModal(true)}
									extendStyles={{
										width: 120,
										lineHeight: '32px',
										color: theme.color.soft_red,
										background:
											theme.color.light_greyish_red,
										border: `1px solid ${theme.color.soft_red}`,
										'&:hover': {
											boxShadow: `0px 0px 0px 2px ${theme.color.soft_red}`,
										},
									}}
								/>
							</Grommet>
							<Box style={{ paddingLeft: 15 }}>
								{getGenerateButton(currentUserModule)}
							</Box>
						</Box>
					</Box>
				)}
			{getIn(savedContractDetails, ['contractID']) && (
				<Box
					style={{
						opacity: 1,
						borderRadius: 10,
						padding: '0 40px',
						position: 'relative',
						border: '1px solid #CAD2DD',
						background: '#FFFFFF 0% 0% no-repeat padding-box',
					}}
					margin="28px 0 0"
				>
					<Box
						row={!isMobile}
						justifyContent="space-between"
						width="100%"
					>
						<Box
							style={{
								padding: '16px 0',
							}}
						>
							<Label style={{ color: '#7E8189' }}>
								{t('contracts.dateSent')}
							</Label>
							<P
								style={{
									color: '#242845',
									margin: '6px 0 0',
								}}
							>
								{moment(
									getIn(savedContractDetails, ['createdAt'])
								).format('DD MM YYYY')}
							</P>
							<P>
								{moment(
									getIn(savedContractDetails, ['createdAt'])
								).format('hh:mm A')}
							</P>
							{getIn(savedContractDetails, [
								'meta',
								'signed',
							]) && (
								<>
									<Label
										style={{
											color: '#7E8189',
											marginTop: 15,
										}}
									>
										{t('contracts.signedBy')}
									</Label>
									<P
										style={{
											color: '#242845',
											margin: '6px 0 6px',
										}}
									>
										{fullName}
									</P>
								</>
							)}
							<P>
								{`${moment(
									getIn(savedContractDetails, ['createdAt'])
								).format('DD MM YYYY')}; ${moment(
									getIn(savedContractDetails, ['createdAt'])
								).format('hh:mm A')}`}
							</P>
						</Box>
						{getIn(savedContractDetails, [
							'contract',
							'seller',
							'name',
						]) && (
							<Box
								width={200}
								style={{
									padding: '16px 0',
								}}
							>
								<Label style={{ color: '#7E8189' }}>
									{t('contracts.seller')}
								</Label>
								<P
									style={{
										color: '#242845',
										margin: '6px 0 0',
									}}
								>
									{getIn(savedContractDetails, [
										'contract',
										'seller',
										'name',
									])}
								</P>
								{getIn(savedContractDetails, [
									'contract',
									'seller',
									'primaryAddress',
								]) && (
									<P style={{ color: '#989EA9' }}>
										{`${getIn(savedContractDetails, [
											'contract',
											'seller',
											'primaryAddress',
											'line1',
										]) || ''}, ${getIn(
											savedContractDetails,
											[
												'contract',
												'seller',
												'primaryAddress',
												'city',
											]
										) || ''},
									${getIn(savedContractDetails, [
										'contract',
										'seller',
										'primaryAddress',
										'state',
									]) || ''}, ${getIn(savedContractDetails, [
											'contract',
											'seller',
											'primaryAddress',
											'country',
										]) || ''}.`}
									</P>
								)}
							</Box>
						)}
						{savedContractDetails.contract.broker.primaryContact
							.email && (
							<Box
								style={{
									padding: '16px 0',
								}}
							>
								<Label style={{ color: '#7E8189' }}>
									{t('contracts.broker')}
								</Label>
								<P
									style={{
										color: '#242845',
										margin: '6px 0 0',
									}}
								>
									{getIn(savedContractDetails, [
										'contract',
										'broker',
										'name',
									])}
								</P>
								<P style={{ color: '#989EA9' }}>
									{getIn(savedContractDetails, [
										'contract',
										'broker',
										'primaryContact',
										'email',
									])}
								</P>
								{getIn(savedContractDetails, ['version']) >
									0 && (
									<Box
										style={{
											backgroundColor: '#D4DAF8',
											padding: '8px 16px',
											margin: '48px 0 8px',
											borderRadius: '5px',
											opacity: 1,
											textAlign: 'center',
										}}
									>
										<Label
											style={{
												color: '#3F56C4',
											}}
										>
											{t(
												'contracts.revisedVer'
											).toUpperCase()}
											.{versionNumber}
										</Label>
									</Box>
								)}
							</Box>
						)}
						<Box
							style={{
								padding: 16,
								...(isMobile
									? { paddingLeft: '0px' }
									: { borderLeft: '1px solid #CAD2DD' }),
							}}
						>
							<Label style={{ color: '#7E8189' }}>
								{t('contracts.docStatus')}
							</Label>
							<Box style={{ margin: '6px 0 0 0' }}>
								{savedContractDetails.contract.broker
									.primaryContact.email && (
									<div
										style={{
											marginTop: 20,
											display: isMobile
												? 'block'
												: 'flex',
											aligItems: 'center',
										}}
									>
										<Label
											style={{
												width: 50,
												color: '#242845',
												fontWeight: isMobile
													? 400
													: 500,
											}}
										>
											{t('contracts.broker')}
										</Label>
										<Box
											style={{
												opacity: 1,
												marginLeft: isMobile ? 0 : 30,
												padding: '4px 16px',
												textAlign: 'center',
												borderRadius: '5px',
												display: 'inline-block',
												backgroundColor:
													STATUS_BACKGROUND[
														getStatus('broker')
													],
											}}
										>
											<Label
												style={{
													color:
														STATUS_TEXT_COLOR[
															getStatus('broker')
														],
												}}
											>
												{t(
													RFQ_STATUS[
														getStatus('broker')
													]
												).toUpperCase()}
											</Label>
										</Box>
									</div>
								)}
								<div
									style={{
										marginTop: 20,
										display: isMobile ? 'block' : 'flex',
										aligItems: 'center',
									}}
								>
									<Label
										style={{
											width: 50,
											color: '#242845',
											fontWeight: isMobile ? 400 : 500,
										}}
									>
										{t('contracts.seller')}
									</Label>
									<Box
										style={{
											opacity: 1,
											marginLeft: isMobile ? 0 : 30,
											padding: '4px 16px',
											textAlign: 'center',
											borderRadius: '5px',
											display: 'inline-block',
											backgroundColor:
												STATUS_BACKGROUND[
													getStatus('seller')
												],
										}}
									>
										<Label
											style={{
												color:
													STATUS_TEXT_COLOR[
														getStatus('seller')
													],
											}}
										>
											{t(
												RFQ_STATUS[getStatus('seller')]
											).toUpperCase()}
										</Label>
									</Box>
								</div>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
			<Spacer size={20} />
			{getIn(savedContractDetails, ['meta', 'signed']) && (
				<div>
					<div
						style={{
							padding: '40px 0',
							background: theme.color.grey19,
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										padding: '0 0 30px 0',
										justifyContent: 'space-between',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'end',
										}}
									>
										<div
											tabIndex={0}
											role="button"
											style={{
												width: 48,
												height: 48,
												borderRadius: 10,
												color:
													pageNumber > 1
														? theme.color.white
														: theme.color.grey15,
												background:
													pageNumber > 1
														? theme.color.grey15
														: theme.color.grey18,
												border: `1px solid ${theme.color.grey15}`,
												cursor:
													pageNumber > 1
														? 'pointer'
														: 'unset',
												pointerEvents:
													pageNumber > 1
														? 'auto'
														: 'none',
											}}
											onClick={() => {
												setPageNumber(pageNumber - 1)
											}}
											onKeyPress={() => {
												setPageNumber(pageNumber - 1)
											}}
										>
											<Icon glyph={LeftArrowIcon} />
										</div>
										<span
											style={{
												marginLeft: 10,
												color: theme.color.grey15,
											}}
										>
											{t('contracts.prevPage')}
										</span>
									</div>
									<div
										style={{
											fontSize: 24,
											fontWeight: 500,
											marginLeft: -15,
											color: theme.color.accent2,
										}}
									>
										{t('contracts.page')} {pageNumber}{' '}
										{t('contracts.of')} {totalPages}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'end',
										}}
									>
										<span
											style={{
												marginRight: 10,
												color: theme.color.grey15,
											}}
										>
											{t('contracts.nextpage')}
										</span>
										<div
											tabIndex={0}
											role="button"
											style={{
												width: 48,
												height: 48,
												borderRadius: 10,
												color:
													pageNumber < totalPages
														? theme.color.white
														: theme.color.grey15,
												background:
													pageNumber < totalPages
														? theme.color.grey15
														: theme.color.grey18,
												border: `1px solid ${theme.color.grey15}`,
												cursor:
													pageNumber < totalPages
														? 'pointer'
														: 'unset',
												pointerEvents:
													pageNumber < totalPages
														? 'auto'
														: 'none',
											}}
											onClick={() => {
												setPageNumber(pageNumber + 1)
											}}
											onKeyPress={() => {
												setPageNumber(pageNumber + 1)
											}}
										>
											<Icon glyph={RightArrowIcon} />
										</div>
									</div>
								</div>
								<Document
									file={{
										url: getIn(savedContractDetails, [
											'contractAttachment',
											'meta',
											'fullURL',
										]),
										withCredentials: true,
									}}
									onLoadSuccess={onDocumentLoadSuccess}
								>
									<Page pageNumber={pageNumber} />
								</Document>
							</div>
						</div>
					</div>
					<DigitalContractMessage margin="40px 0 0 0" />
				</div>
			)}
			<Box
				style={{
					position: 'fixed',
					display: 'grid',
					zIndex: 1,
					bottom: 0,
					right: showChatBox ? 8 : 75,
				}}
			>
				<ChatModalComponent
					documentId={
						actor === 'palmoil_broker'
							? getDocID(currentDocType)
							: getIn(savedContractDetails, ['id'])
					}
					sellerName={seller}
					buyerName={buyer}
					buyerId={buyerID}
					sellerId={sellerID}
					tradeId={tradeID}
					docType={currentDocType}
					chatBoxData={data => chatBoxData(data)}
					isMobile={isMobile}
				/>
			</Box>
			{actor === 'palmoil_broker' ? (
				<Box
					style={{
						position: 'fixed',
						display: 'grid',
						zIndex: 1,
						bottom: 0,
						right: showChatBox ? 575 : 432,
					}}
				>
					<ChatModalComponent
						documentId={getDocID('contract-broker-seller')}
						sellerName={getIn(savedContractDetails, [
							'contract',
							'seller',
							'name',
						])}
						buyerName={getIn(savedContractDetails, [
							'contract',
							'broker',
							'name',
						])}
						buyerId={getIn(savedContractDetails, [
							'contract',
							'broker',
							'id',
						])}
						sellerId={getIn(savedContractDetails, [
							'contract',
							'seller',
							'id',
						])}
						tradeId={tradeID}
						docType="contract-broker-seller"
						chatBoxData={data => console.log(data)}
						isMobile={isMobile}
					/>
				</Box>
			) : (
				''
			)}
			<Modal
				size="large"
				show={showModal}
				heading={t('marketPlace.contractDeclined')}
				onConfirmWarning
				body={
					<>
						<Text
							style={{
								marginTop: -10,
								lineHeight: '18px',
								position: 'absolute',
								color: theme.color.grey15,
							}}
						>
							{t('marketPlace.confirmDeclineMessage')}
						</Text>
						<SmallText
							style={{
								fontWeight: 600,
								margin: '55px 0 5px',
							}}
						>
							{t('marketPlace.reasonForDecline')}
						</SmallText>
						<TextArea
							onChange={e => {
								setMessage(e.target.value)
							}}
							placeholder={t('contracts.enterMessage')}
						/>
					</>
				}
				confirmlabel={t('marketPlace.confirmDecline')}
				secondaryButtonLabel={t('common.back')}
				secondaryButtonClick={() => {
					setShowModal(false)
					setMessage('')
				}}
				isDisabled={false}
				onConfirm={() => {
					setShowModal(false)
					const declinePayload = {
						message,
					}
					dispatch(
						MarketPlaceDuc.creators.declineContractDetails(
							getIn(savedContractDetails, ['id']),
							declinePayload
						)
					)
				}}
				extendStyles={{
					borderRadius: 10,
				}}
			/>
		</Box>
	)
}

export { ViewAttachedContract }
