import styled from 'styled-components'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'

export const CTAWrapper = styled(Box)(p => ({
	padding: 10,
	minHeight: 62,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	color: p.theme.color.primary,
	display: 'flex',
	flexDirection: 'row',
	border: `1px solid ${theme.color.grey4}`,
}))

export const customCheckBoxTheme = {
	checkBox: {
		color: '#13835A',
		check: {
			thickness: '2px',
		},
	},
}
