import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import moment from 'moment'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	MONTHS,
	SHIPMENT_PERIOD,
	headingStyles,
	liStyles,
	h3Styles,
	contractTableLabelStyles,
	contractTableTDStyles,
	actorStyles,
	s1Styles,
	paragraphStyles,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { Box } from 'ui-lib/utils/Box'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { Modal } from 'ui-lib/components/Modal'
import { SmallText, P } from 'ui-lib/components/Typography'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import { DigitalContractMessage } from '../../../../components/DigitalContractMessage'
import '../styles/contract1.css'

const PreviewContractNo5 = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { contractAction } = query
	const { rootModule, documentReference, documentType } = payload
	const [showModal, setShowModal] = useState(false)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const contractDetails = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	if (!contract.quantity && contractAction === 'generate') {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('contracts.editContractText')}
					</P>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.ok')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					dispatch(
						MarketPlaceDuc.creators.editContract(
							contractDetails,
							documentType
						)
					)
				}}
			/>
			<Title
				title={
					contractAction === 'edit'
						? t('contracts.editContractPreview')
						: t('contracts.createContractPreview')
				}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'participants-success',
								documentReference,
								documentType,
							},
							{
								contractAction,
							}
						)
					)
				}}
				small
				isMobile={isMobile}
				hasNoMaxWidth
			/>
			<Spacer size={60} />
			<Box center style={{ background: '#EAEAEB' }}>
				<Box
					width={804}
					style={{ background: theme.color.white }}
					padding={60}
				>
					<Box center>
						<h2 style={headingStyles}>
							THE PALM OIL REFINERS ASSOCIATION OF MALAYSIA
						</h2>
						<h2 style={headingStyles}>
							801C/802A, BLOCK B, EXECUTIVE SUITES,
						</h2>
						<h2 style={headingStyles}>
							KELANA BUSINESS CENTRE, 97, JALAN SS7/2,
						</h2>
						<h2 style={headingStyles}>
							47301 KELANA JAYA, SELANGOR, MALAYSIA.
						</h2>
						<h2 style={headingStyles}>
							CIF CONTRACT FOR PROCESSED PALM OIL PRODUCTS IN
							DRUMS
						</h2>
						<h2 style={{ ...headingStyles, marginTop: 20 }}>
							ORIGIN:
						</h2>
					</Box>
					<Spacer size={40} />
					<Box row={!isMobile} justifyContent="space-between">
						<Box>
							<p
								style={{
									...s1Styles,
									fontWeight: 'normal',
									lineHeight: '16px',
								}}
							>
								Revised and Effective
							</p>
							<br />
							<p
								style={{
									...s1Styles,
									fontWeight: 'normal',
									lineHeight: '17px',
								}}
							>
								1 February 2012
							</p>
						</Box>
						<br />
						<h1
							style={{
								margin: '0px 68px 12px 0',
								padding: 0,
								textAlign: isMobile ? 'center' : 'right',
							}}
						>
							5
						</h1>
					</Box>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={440}>
							<div>
								<p style={{ ...actorStyles }}>
									Seller :{' '}
									{getIn(contractDetails, [
										'contract',
										'seller',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Buyer :{' '}
									{getIn(contractDetails, [
										'contract',
										'buyer',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Broker :{' '}
									{getIn(contractDetails, [
										'contract',
										'broker',
										'name',
									])}
								</p>
								<br />
								<br />
								<p
									style={{
										color: 'black',
										fontFamily:
											'"Palatino Linotype", serif',
										fontStyle: 'normal',
										fontSize: 13,
									}}
								>
									* An asterisk denotes alternative wording
									and that not applicable should be deleted.
								</p>
							</div>
						</Box>
						<Box>
							<div
								style={{
									border: '1px solid black',
									padding: '10px',
									height: '148px',
									width: '148px',
									textAlign: 'center',
								}}
							>
								<p style={{ fontSize: '15px' }}>
									<u>Reference No:</u>
								</p>
								<div
									style={{
										overflow: isMobile
											? 'scroll'
											: 'hidden',
									}}
								>
									<table style={{ width: '100%' }}>
										<tbody>
											{getIn(contractDetails, [
												'contract',
												'referenceNo',
											]) ||
												[].map(ref => {
													return (
														<tr key={ref}>
															<td
																style={{
																	paddingTop:
																		'10px',
																}}
															>
																{ref}
															</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</div>
							</div>
						</Box>
					</Box>
					<p>
						<br />
					</p>
					<div>
						<p style={{ ...paragraphStyles, paddingTop: 5 }}>
							The Seller has agreed to sell and the Buyer has
							agreed to buy, the following product(s) on terms and
							conditions as stipulated hereunder:-
						</p>
						<p style={{ textIndent: '0pt', textAlign: 'left' }}>
							<br />
						</p>
					</div>
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								width: 680,
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '36pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Contract No :{' '}
											{getIn(contractDetails, [
												'contract',
												'contractNo',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Date :{' '}
											{`${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('DD MM YYYY')}; ${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('hh:mm A')}`}
										</p>
									</td>
								</tr>
								<tr style={{ height: '35pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Product :{' '}
											{getIn(contractDetails, [
												'contract',
												'product',
												'name',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Quantity in
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											MT
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Shipment Period
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Destination
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Price (CIF)
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											PMT
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'quantity',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{`${t(
												MONTHS[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'month',
													])
												]
											)}, ${t(
												SHIPMENT_PERIOD[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'period',
													])
												]
											)}`}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'port',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'unitRate',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '36pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Packaging :{' '}
											{getIn(contractDetails, [
												'contract',
												'packaging',
												'type',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Type of Drum :{' '}
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'packagingType',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '17pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Product Specifications
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Special Conditions
										</p>
									</td>
								</tr>
								<tr style={{ height: '26pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												padding: '0 6px',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'specifications',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
										rowSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												padding: '0 6px',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'specialConditions',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '91pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												padding: '0 6px',
												textIndent: '0pt',
												textAlign: 'justify',
											}}
										>
											The specifications shall be those
											established by The Palm Oil Refiners
											Association of Malaysia (PORAM) as
											standard for the export of these
											products and in force at the date of
											contract and always provided they
											are not in contradiction with the
											above.
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							paddingTop: '3pt',
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						The FFA content shall be expressed as follows:
					</p>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						* If as palmitic acid, calculated on a molecular weight
						of 256;
					</p>
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						* If as oleic acid, calculated on a molecular weight of
						282.
					</p>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						Payment at Seller’s bank in Malaysia
					</p>
					<Spacer size={20} />
					<ul
						style={{
							color: 'black',
							fontFamily: '"Times New Roman", serif',
							fontStyle: 'normal',
							fontWeight: 'normal',
							textDecoration: 'none',
							fontSize: '10pt',
							listStyleType: 'none',
						}}
					>
						<li data-list-text="*">
							<p
								style={{
									textAlign: 'left',
									marginLeft: 24,
								}}
							>
								(i) in accordance with the provisions of
								paragraph (a) of the Payment Clause;
							</p>
						</li>
						<li data-list-text="*">
							<p
								style={{
									textAlign: 'left',
									marginLeft: 24,
								}}
							>
								(ii) in accordance with the provisions of
								paragraph (b) of the Payment Clause;
							</p>
						</li>
					</ul>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>2.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUALITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Seller has the option of shipping 1% more or
								less of the contract quantity. Such variance
								shall be settled at the contract price.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>3.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUALITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								At time and place of sampling the oil shall be
								of good merchantable quality within the meaning
								of the description and specifications stated in
								the preamble. Unless so stated, or otherwise
								agreed between the parties and confirmed in
								writing, the oil is not warranted to be suitable
								for any specific end-use or to conform to any
								particular analytical criteria not mentioned
								above.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>4.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>
								SHIPMENT AND CLASSIFICATION
							</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Shipment in good condition, direct or indirect,
								with or without transhipment in ship/s (tankers
								excluded), classified not lower than Llyods
								100A1 or equivalent class in any other
								recognised register. In the event of more than
								one shipment being made, each shipment is to be
								considered as a separate contract but the margin
								on the mean contract quantity shall not be
								affected thereby. For the purposes of this
								contract, the word `ship' or `ships' shall mean
								any full-powered engine-driven ship. In the
								event shipment is by container, fit and suitable
								containers and freight space shall be provided
								at the port of loading
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>5.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DECLARATION OF SHIPMENT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Notice stating the ship's name, date of Bill(s)
								of Lading, and quantity shipped shall be
								despatched by Seller to Buyer not later than 10
								calendar days from the date of the Bill(s) of
								Lading. The date of the "on board" Bill(s) of
								Lading shall be considered proof of the date of
								shipment in the absence of conclusive evidence
								to the contrary. Notice shall be deemed to be
								under reserve for errors and/or delays in
								transmission. Any slight variation in the ship's
								name shall not invalidate the declaration. A
								valid declaration cannot be withdrawn except
								with Buyer’s consent. Should the ship arrive
								before receipt of declaration of shipment, any
								extra expenses as incurred shall be borne by
								Seller.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>6.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>
								EXTENSION OF SHIPMENT/CONTINUOUS LOADING
							</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the shipment period does not exceed 31
								calendar days the period of shipment shall, at
								the request of Seller, be extended by an
								additional period not exceeding 15 calendar days
								provided notice is given to Buyer of the
								intention not later than the first business day
								following the last day of the original shipment
								period. Seller shall at the same time nominate
								the ship he intends to load and shall provide,
								together with shipping documents satisfactory
								evidence that the ship was originally booked for
								shipment within the original shipment period.
								Should Seller not claim the above extension and
								fails to ship within the shipment period, Seller
								shall be deemed to be at default.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event that Seller claims for the
								extension and fails to ship within the extended
								period, Seller is deemed to be in default.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>7.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SHIPPING INSTRUCTIONS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Buyer shall provide Seller with the following
								instructions within 7 calendar days of the date
								of contract :-
							</p>
							<Spacer size={20} />
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										marginRight: 16,
									}}
								>
									(a)
								</p>
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Shipping marks and numbers
								</p>
							</Box>
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										marginRight: 16,
									}}
								>
									(b)
								</p>
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Notifying party
								</p>
							</Box>
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										marginRight: 16,
									}}
								>
									(c)
								</p>
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Buyer’s banker’s name and address in event
									of payment being cash on presentation of
									documents.{' '}
								</p>
							</Box>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>8.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>INSURANCE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Insurance on all risks terms per Institute Cargo
								Clauses (All Risks), including risks of
								contamination and leakage irrespective of
								percentage of each package or on the whole,
								including the risk of war, strikes, riots, civil
								commotion - to be effected with first class
								Underwriters for 101% of the value shipped.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>9.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>WAR RISKS INSURANCE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								War Risks Insurance shall be effected on the
								terms and conditions in force and approved at
								the time of shipment by the Institute of London
								Underwriters. Any expense for covering war risks
								insurance in excess of 1/2% shall be for account
								of Buyer. The rate of insurance shall not exceed
								the rate ruling in London at the time of
								shipment or date of ship's sailing whichever may
								be adopted by Underwriters. Notice of extra
								expenses to be borne by Buyer shall be given by
								Seller at the time of declaration under this
								contract or not later than 3 business days after
								the rate has been agreed with Underwriters
								whichever is the later. Failure to give such
								notice shall invalidate Seller’s claim unless in
								the opinion of arbitrators the delay is
								justifiable.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>10.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SAMPLING AND ANALYSIS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Representative samples of the oil shall be drawn
								from 10% of the total quantity of the packed
								drums selected at random by independent
								surveyors at Seller’s premises.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								The sample so drawn shall be in quadruplicate (1
								for contractual analysis, 1 for the shipper and
								2 to be retained by the surveyors) of the oil
								packed, at time of weighing.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Buyer has the right to be represented at his own
								cost at time of sampling at Seller’s premises to
								draw samples conjointly with Seller’s
								representatives. If Buyer is so represented,
								only samples drawn and sealed conjointly shall
								be accepted as official contract samples for
								analysis purposes.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event of any dispute on quality analysis,
								the samples retained by the surveyors shall be
								referred to the Malaysian Palm Oil Board (MPOB)
								laboratory, whose analysis shall be final.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>11.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>PAYMENT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Payment shall be made as stipulated in the
								preamble for 100% of the invoice value against a
								complete set of shipping documents.
							</p>
							<Spacer size={20} />
							<Box row={!isMobile} width={isMobile ? 238 : 640}>
								<p style={{ ...paragraphStyles }}>*(a)</p>
								<Box>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										by irrevocable and confirmed Letter of
										Credit unrestricted for negotiation
										established in Seller’s favour through a
										recognized bank for 101% of the mean
										contract quantity. Unless otherwise
										agreed between the parties, such credit
										shall be advised and available to Seller
										not later than 10 calendar days from
										date of contract. Should the credit be
										opened on terms inconsistent with the
										contract, Seller may demand amendments
										which shall be arranged by Buyer and
										notified to Seller through the credit
										opening bank within 7 calendar days of
										the demand being received. The Letter of
										Credit shall provide for the following:-
									</p>
									<Spacer size={20} />
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(i) Telegraphic Transfer reimbursement
										allowed
									</p>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(ii) Partial shipment allowed
									</p>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(iii) Shipment on deck allowed
									</p>
								</Box>
							</Box>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								*(b)
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by
								cash on presentation of documents.
							</p>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								Seller shall present documents to Buyer through
								a bank nominated by Buyer. All bank charges at
								Seller’s bank are for Seller’s account and all
								bank charges at Buyer’s bank are for Buyer’s
								account. Buyer shall arrange payment by
								telegraphic transfer (TT) at Buyer’s cost.
							</p>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								Unless otherwise agreed shipping documents shall
								consist of the following in triplicate:-
							</p>
							<Spacer size={20} />
							<Box width={isMobile ? 238 : 640}>
								<p
									style={{
										...paragraphStyles,
									}}
								>
									1. Commercial invoice;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									2. Full set of clean and prepaid ‘on board'
									Bill(s) of Lading;
								</p>

								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
										marginLeft: 12,
									}}
								>
									If Bill(s) of Lading is signed by parties
									other than the Master, the Bill(s) of Lading
									shall be accompanied by photostat copy of
									written authority from shipowner or Master
									authorising the signatory to the Bill(s) of
									Lading;
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									3. Policy of Insurance Certificate in the
									currency of contract;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									4. Certificate of quantity of drums loaded
									on board ascertained at port of loading and
									issued by a recognised independent surveyor;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									5. Survey report issued by a recognised
									independent surveyor, certifying the weight
									and particulars as to time and place of
									weighing and sampling;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									6. Certificate of analysis, issued by a
									recognised independent laboratory;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									7. Certificate of Origin.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Buyer is to accept certified photostatic
									copies of items (4), (5) and (6) relating to
									the shipment.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Should documents be presented with
									incomplete set(s) of Bill(s) of Lading,
									payment shall be made provided that delivery
									of such Bill(s) of Lading be guaranteed by
									Seller. Such guarantee to be endorsed, if
									required by Buyer by a recognised bank.
									Acceptance of this guarantee shall not
									prejudice Buyer’s rights under this
									contract.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Should Seller fails to present shipping
									documents on arrival of the ship at
									destination, Buyer shall take delivery under
									an indemnity provided by himself and shall
									pay for the documents when presented.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Any reasonable expenses as incurred
									including costs of such indemnity and extra
									handling charges incurred by reason of
									failure of the Seller to provide such
									documents shall be borne by Seller.
								</p>
							</Box>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>12.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DUTIES, TAXES ETC</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								All export duties, levies etc., present or
								future in country of origin/port of shipment
								shall be for Seller’s account.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								All import duties, taxes, levies, licenses etc.,
								present or future in port of discharge/country
								of destination shall be for Buyer’s
								responsibility and account.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the goods are entitled to free entry into
								or preferential duty in the port of destination
								named in this contract, Seller shall furnish
								together with the shipping documents a
								Certificate of Origin and/or necessary
								document(s) in the form valid at time of
								shipment, otherwise Seller shall be responsible
								for any extra duty incurred by Buyer through the
								non-production of such certificate and/or
								documents.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>13.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>NOTICES</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the terms of the contract require notice
								to be given, such notice shall be despatched by
								cable, telegram, telex, fascimile, email, post
								or courier service or delivered by hand within
								the time limit specified in the contract. All
								notices shall be under reserve for errors and
								omissions in transmission or delivery. Any
								notice received after 1600 hours Malaysian time
								on a business day shall be deemed to have been
								received on the following day.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>14.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>NON-BUSINESS DAYS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the time limit for doing any act or
								giving any notice expire on a Saturday, Sunday
								or any National Gazetted Public Holiday in
								Malaysia, the time so limited shall be extended
								until the first business day thereafter. All
								business days shall be deemed to end 1600 hours
								Malaysian time Monday to Friday inclusive. This
								clause shall however not be applicable to the
								contract period.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>15.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>ODD DAYS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In any month containing an odd number of days,
								the middle day shall be reckoned as belonging to
								both halves of the month.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>16.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DEFAULT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In default of fulfilment of the contract by
								either party, the other party at his discretion
								shall, after giving notice, have the right
								either to cancel the contract or the right to
								sell or purchase, as the case may be, against
								the defaulter who shall on demand make good the
								loss, if any, on such sale or purchase. If the
								party liable to pay shall be dissatisfied with
								the price of such sale or purchase, or if
								neither of the above right is exercised, the
								damage if any, shall, failing amicable
								settlement, be determined by arbitration. The
								damages awarded against the defaulter shall be
								limited to the differences between the contract
								price and the market price on the day of the
								default but if the Arbitrator(s) consider the
								circumstances of the default justify it, they
								may at their absolute discretion, award
								additional damages.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>17.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>BANKRUPTCY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								If before the fulfilment of this contract either
								party shall suspend payment, commit an act of
								bankruptcy, notify any of his creditors that he
								is unable to meet his debts or that he has
								suspended payments or that he is about to
								suspend payment of his debts, convene, call or
								hold a meeting either of his creditors or to
								pass a resolution to go into liquidation (except
								for voluntary winding up of a solvent company
								for the purpose of reconstruction or
								amalgamation) or shall apply for an official
								moratorium, have a petition presented for
								winding up or shall have a Receiver appointed,
								he shall be deemed to be and shall be treated as
								being at default and the contract shall
								forthwith be closed, either at the market price
								then current for similar goods or, at the option
								of the other party, at a price to be ascertained
								by repurchase or resale and the difference
								between the contract price and such closing-out
								price shall be the amount which the other party
								shall be entitled to claim or shall be liable to
								account for under this contract. Should either
								party be dissatisfied with the price, the matter
								shall be referred to arbitration. Where no such
								resale or repurchase takes place, the
								closing-out price shall be determined by a Price
								Settlement Committee of The Palm Oil Refiners
								Association of Malaysia.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>18.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>PROHIBITION</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event, during the contract period, of
								prohibition of export or any other executive or
								legislative act by or on behalf of the
								Government of the country of origin or of the
								territory where the port(s) of shipment name
								herein is/are situated, or of blockade or
								hostilities, restricting export whether
								partially or otherwise, any such restriction
								shall be deemed by both parties to apply to the
								contract and to the extent of such total or
								partial restriction to prevent fulfilment
								whether by shipment or by any other means
								whatsoever and to that extent the contract or
								any unfulfilled portion thereof shall be
								extended by 30 calendar days
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event of shipment during the extended
								period still proved impossible by reason of any
								of the causes in this clause the contract or any
								unfulfilled part thereof shall be cancelled.
								Seller invoking this clause shall advise Buyer
								with due despatch. If required, Seller must
								produce proof to justify his claim for extension
								or cancellation under this clause.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>19.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>FORCE MAJEURE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the performance of the contract be
								prevented by reason of fire, strikes, lockouts,
								riots, civil commotion, floods and/or any cause
								comprehended in the term force majeure, the
								contract period shall be extended for a period
								equal to the duration of the disabling event but
								not exceeding a period of 60 calendar days. If
								the force majeure event ends within 21 calendar
								days preceding the end of the extended period,
								then a further 21 calendar days shall be allowed
								after the termination of the force majeure
								event.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the fulfilment of the contract not be
								possible within the extended period, the
								contract or any unfulfilled part thereof shall
								be deemed to be null and void at the end of such
								extended period.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>20.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DOMICILE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								The contract shall be deemed to have been made
								in Malaysia and the construction, validity and
								performance thereof shall be governed in all
								respect by Malaysian Law, which shall have
								exclusive jurisdiction wherever the domicile,
								residence or place of business of the parties to
								the contract may be or become.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>21.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>ARBITRATION</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Any dispute arising out of this contract,
								including any question of law arising in
								connection therewith, shall be referred to
								arbitration in Malaysia (at the PORAM
								Secretariat or elsewhere if so agreed), in
								accordance with the PORAM Rules of Arbitration
								and Appeal in force at the date of the
								initiation of the arbitration with the parties
								hereby agreeing that the arbitration shall be
								deemed an International Arbitration. The seat of
								the arbitration shall be Malaysia and the
								Malaysian Arbitration Act 2005 (“the Act”) or
								any reenactment thereof shall apply to any
								arbitration under this contract. The parties
								hereto further agree that Part III of the Act
								shall not apply to any arbitration under this
								contract.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Neither party hereto, nor any persons claiming
								under either of them shall bring any action or
								other legal proceedings against the other of
								them in respect of any such dispute until such
								dispute shall first have been heard and
								determined by the Sole Arbitrator/Panel of
								Arbitrators/Appeal Board (as the case may be),
								in accordance with the PORAM Rules of
								Arbitration and Appeal and it is hereby
								expressly agreed and declared that the obtaining
								of an award from the Sole Arbitrator/Panel of
								Arbitrators/Appeal Board (as the case may be),
								shall be a condition precedent to the right of
								either party hereto or of any person claiming
								under either of them to bring any action or
								other legal proceedings against the other of
								them in respect of such dispute.
							</p>
						</Box>
					</Box>
					<Box
						row={!isMobile}
						justifyContent="space-between"
						margin="28px 0 0"
					>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Seller:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Broker:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Buyer:
						</p>
					</Box>
				</Box>
				<Box
					style={{
						width: isMobile ? 350 : 800,
						padding: 15,
						margin: '40px 0',
						alignItems: 'center',
						background: theme.color.white,
						justifyContent: 'space-between',
					}}
					row={!isMobile}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '60%',
							alignItems: 'end',
							marginRight: '5%',
							padding: '15px 10px',
							background: theme.color.white,
						}}
					>
						<SmallText
							style={{
								fontSize: 14,
								color: '#8D93A0',
								lineHeight: '19px',
								textAlign: 'justify',
							}}
						>
							{t('contracts.proceedToESignText')}
						</SmallText>
					</Box>

					<Button
						rounded
						label={t('contracts.proceedToESign')}
						reverse
						onClick={() => {
							if (contractAction === 'edit') {
								setShowModal(true)
							} else {
								dispatch(
									MarketPlaceDuc.creators.postContractDetails(
										contractDetails,
										documentType
									)
								)
							}
						}}
						extendStyles={{
							width: 266,
							background: '#3F56C4',
							color: theme.color.white,
						}}
					/>
				</Box>
				<DigitalContractMessage
					width={isMobile ? 350 : 800}
					margin="0 0 40px 0"
				/>
			</Box>
		</>
	)
}

export { PreviewContractNo5 }
