import React from 'react'
import { useSelector } from 'react-redux'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { getIn } from 'timm'
import { QuoteListing } from './QuoteListing'
import { ViewQuote } from './ViewQuote'

const Quotes = () => {
	const locationState = useSelector(MarketPlaceDuc.selectors.location)
	const quotesID = getIn(locationState, ['query', 'quotesID']) || ''

	return quotesID ? <ViewQuote /> : <QuoteListing />
}

export default Quotes
