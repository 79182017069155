// It is important for this to be the first import, so that it initializes first. It updates
// the prototype of fetch, XMLHttpRequest etc. to introduce instrumentation
// import 'ui-marketplace-app/utils/apm'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { Grommet } from 'grommet'
import App from 'ui-marketplace-app/modules/App'
import { apiConfigAvailable } from 'ui-marketplace-app/config'
import { APIConfigError } from 'ui-marketplace-app/utils/helpers'
import baseTheme from 'ui-lib/utils/base-theme'
import { languageChange } from 'i18next-redux-languagedetector'
import { I18nextProvider } from 'react-i18next'
import * as serviceWorker from './serviceWorker'
import configureStore from './store'
import configureI18n, { i18nextConfig } from './i18n'

// import the reset styles
import 'ui-lib/utils/reset-styles.css'

// Populate all the store necessities from the server rendered Page.
const initialState = {
	i18next: i18nextConfig,
}

const { store } = configureStore(initialState)

// enable the react perf devtools
if (process.env.NODE_ENV === 'development' && typeof window === 'object') {
	// hook whyDidYouRender to track the renders in development
	// Go here https://github.com/welldone-software/why-did-you-render#usage
	const whyDidYouRender = require('@welldone-software/why-did-you-render')
	whyDidYouRender(React)
}

const i18n = configureI18n({
	i18nextConfig,
	redux: {
		lookupRedux: () => {
			return store.getState().i18next
		},
		cacheUserLanguageRedux: language => {
			store.dispatch(languageChange(language))
		},
	},
})

ReactDOM.createRoot(document.getElementById('app')).render(
	<ReduxProvider store={store}>
		<Grommet theme={baseTheme} plain>
			<I18nextProvider i18n={i18n}>
				{apiConfigAvailable ? <App /> : <APIConfigError />}
			</I18nextProvider>
		</Grommet>
	</ReduxProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
