/* eslint-disable import/extensions */
import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { getDateByFormat } from 'ui-marketplace-app/utils/date'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import { H3, Text, P } from 'ui-lib/components/Typography'
import SupplyAbilityIcon from 'ui-lib/icons/supplyability.svg'
import IsccLogo from 'ui-marketplace/src/assets/iscc.png'
import MspoLogo from 'ui-marketplace/src/assets/mspo.png'
import RspoLogo from 'ui-marketplace/src/assets/rspo.png'
import Documents from 'ui-lib/icons/documents.svg'

const Label = styled.h4({
	fontSize: '14px',
	color: '#354DC5',
})

const CertificateLogo = {
	ISCC: IsccLogo,
	MSPO: MspoLogo,
	RSPO: RspoLogo,
}

const Illustration = styled.img`
	width: 10%;
`

const Description = styled.p({
	fontSize: '14px',
	color: '#242845',
	maxWidth: '350px',
	lineHeight: '20px',
})

export const KeyValueSegment = ({
	label,
	value,
	labelWidth,
	valueWidth = '340px',
	noMaxWidth = false,
}) => {
	return (
		<Box row justifyContent="flex-start" alignItems="baseline">
			<Box
				style={{
					width: labelWidth || '25%',
					overflow: 'hidden',
				}}
			>
				<H3
					style={{
						lineHeight: '1.5',
						fontSize: theme.fontSize.m,
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						color: '#8D93A0',
					}}
					title={label}
				>
					{label}
				</H3>
			</Box>

			<Box
				padding={4}
				style={{ maxWidth: noMaxWidth ? 'none' : valueWidth }}
			>
				<Text
					title={value}
					style={{
						lineHeight: '1.5',
						width: '100%',
						overflow: 'hidden',
						color: '#172B4D',
						maxWidth: '200px',
					}}
				>
					{value}
				</Text>
			</Box>
		</Box>
	)
}

export const CertificateBlock = ({ name, certificate }) => {
	return (
		<>
			<Box row alignItems="center" justifyContent="space-between">
				<Illustration src={CertificateLogo[name]} />

				<Spacer size={8} />
				<Label style={{ color: '#8D93A0', fontWeight: '400' }}>
					{name}
				</Label>
				<Spacer size={12} />
				<Label style={{ color: '#242845', fontWeight: '500' }}>
					{`Valid upto ${getDateByFormat(
						getIn(certificate, ['expiryEndDate'])
					)}`}
				</Label>
				<Spacer size={12} />
				{getIn(certificate, ['files', 0, 'meta', 'fullURL']) && (
					<Label
						style={{
							color: '#3F56C4',
							fontWeight: '500',
							cursor: 'pointer',
						}}
						link={getIn(certificate, [
							'files',
							0,
							'meta',
							'fullURL',
						])}
						onClick={() => {
							window.open(
								getIn(certificate, [
									'files',
									0,
									'meta',
									'fullURL',
								]),
								'_blank'
							)
						}}
					>
						VIEW
					</Label>
				)}
			</Box>
			<Spacer size={8} />
		</>
	)
}

const DocumentView = ({ document }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	return (
		<>
			<Box row justifyContent="space-between">
				<Box
					style={{
						width: '55%',
						background: '#fff',
						padding: '22px 40px',
						marginRight: '20px',
					}}
				>
					<>
						<Title
							title={getIn(document, ['product', 'name'])}
							note={getIn(document, ['product', 'code'])}
							small
						/>
						<Spacer size={36} />
						<Box>
							<Label>
								{t('documentView.productDescription')}
							</Label>
							<Spacer size={8} />
							<Description>
								{getIn(document, ['product', 'description'])}
							</Description>
						</Box>
						<Spacer size={48} />
						<Box>
							<Label>{t('documentView.packagingDelivery')}</Label>
							<Spacer size={8} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('documentView.packagingType')}
								value="Bulk"
							/>
							<Spacer size={4} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('documentView.colour')}
								value="Yellow"
							/>
							<Spacer size={4} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('documentView.approxLeadTime')}
								value={getIn(document, [
									'meta',
									'approxLeadTime',
								])}
							/>
							<Spacer size={4} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('documentView.portOfLading')}
								value={getIn(document, ['meta', 'port'])}
							/>
						</Box>
						<Box
							row
							alignItems="center"
							style={{
								background:
									'#CDD5FA 0% 0% no-repeat padding-box',
								boxShadow: '2px 8px 16px #172B4D3D',
								border: '1px solid #3F56C4',
								borderRadius: '4px',
								opacity: 1,
								margin: '12px 0 44px 0',
								width: '412px',
								cursor: 'pointer',
							}}
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.MARKETPLACE$ACTION,
										{
											rootModule: 'seller',
											action: 'view-supplyability',
											documentReference: document.id,
										},
										{
											organizationID:
												document.organizationID,
										}
									)
								)
							}}
						>
							<IconWrapper
								size={24}
								style={{
									background:
										'#CDD5FA 0% 0% no-repeat padding-box',
									opacity: 1,
									margin: '16px',
								}}
							>
								<Icon glyph={SupplyAbilityIcon} />
							</IconWrapper>
							<P style={{ color: '#3F56C4' }}>
								{t('documentView.viewSupplyAbility')}
							</P>
						</Box>

						<Box>
							<Label>{t('documentView.specifications')}</Label>
							<Spacer size={8} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('documentView.grade')}
								value={
									getIn(document, ['meta', 'grade']) || '---'
								}
							/>
							<Spacer size={4} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('documentView.hsn')}
								value={getIn(document, ['product', 'hsnsac'])}
							/>
							<Spacer size={4} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('documentView.qualitySpecs')}
								value={getIn(document, [
									'meta',
									'specifications',
								])}
							/>
							<Spacer size={4} />
							<Box padding="4px 0px" row>
								<H3
									style={{
										lineHeight: '1.5',
										fontSize: theme.fontSize.m,
										width: '50%',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										color: '#8D93A0',
									}}
								>
									{t('documentView.otherAttachments')}
								</H3>
								<Box row>
									{(
										getIn(document, [
											'meta',
											'otherCertifications',
										]) || []
									).map(data => (
										<Box
											key={getIn(document, ['id'])}
											style={{
												width: 60,
												margin: '0px 10px 0px 0px',
											}}
										>
											<Box
												style={{
													height: 60,
													backgroundColor: '#ffeed4',
													width: 60,
												}}
												onClick={() => {
													window.open(
														getIn(data, [
															'meta',
															'fullURL',
														]),
														'_blank'
													)
												}}
												alignItems="center"
												justifyContent="center"
											>
												<Icon
													color="#9e797a"
													glyph={Documents}
													style={{
														height: 30,
														width: 20,
													}}
												/>
											</Box>
											<P
												style={{
													margin: 0,
													'text-overflow': 'ellipsis',
													'white-space': 'nowrap',
													overflow: 'hidden',
												}}
											>
												{getIn(data, ['meta', 'name'])}
											</P>
										</Box>
									))}
								</Box>
							</Box>
						</Box>
					</>
				</Box>
				<Box
					style={{
						width: '42%',
					}}
				>
					<Box
						style={{
							background: '#fff',
							padding: '27px 37px',
						}}
					>
						<Label>{t('documentView.sellerDetails')}</Label>
						<Spacer size={8} />

						<Title
							title={getIn(document, [
								'meta',
								'organization',
								'name',
							])}
							note={getIn(document, [
								'meta',
								'organization',
								'categories',
								0,
								'name',
							])}
							small
						/>
						<Spacer size={8} />
						<KeyValueSegment
							labelWidth="40%"
							label={t('documentView.address')}
							value={`${getIn(document, [
								'meta',
								'organization',
								'primaryAddress',
								'line1',
							]) || ''} ${getIn(document, [
								'primaryAddress',
								'city',
							]) || ''}
 ${getIn(document, ['meta', 'organization', 'primaryAddress', 'state']) ||
		''} ${getIn(document, [
								'meta',
								'organization',
								'primaryAddress',
								'country',
							]) || ''}`}
						/>
						<Spacer size={8} />
						<KeyValueSegment
							labelWidth="40%"
							label={t('documentView.stateCountry')}
							value={`
							${getIn(document, ['meta', 'organization', 'primaryAddress', 'state']) ||
								''} ${getIn(document, [
								'meta',
								'organization',
								'primaryAddress',
								'country',
							]) || ''}`}
						/>
						<Spacer size={12} />

						{(
							getIn(document, [
								'meta',
								'organization',
								'certificationStatus',
								'certificates',
							]) || []
						).map(certificate => (
							<CertificateBlock
								key={getIn(certificate, [
									'meta',
									'certificate',
									'issuingBody',
								])}
								name={getIn(certificate, [
									'meta',
									'certificate',
									'issuingBody',
								])}
								certificate={certificate}
							/>
						))}
					</Box>
					<Spacer size={32} />

					<Button
						label={t('documentView.sendRFQ')}
						rounded
						primary
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.MARKETPLACE$ACTION,
									{
										rootModule: 'buyer',
										action: 'create-rfq',
										documentReference: getIn(document, [
											'id',
										]),
									}
								)
							)
						}
					/>
				</Box>
			</Box>
		</>
	)
}

export { DocumentView }
