import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { addLast, getIn, removeAt } from 'timm'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { SupplyAbilityModal } from 'ui-marketplace-app/modules/MarketPlace/components/SupplyAbilityModal'
import { isEmptyObject } from 'ui-marketplace-app/utils/helpers'
import { CheckBoxGroup } from 'ui-lib/components/Checkbox'
import { Label, Text, H3, SmallText, P } from 'ui-lib/components/Typography'
import { Select } from 'ui-lib/components/Select'
import { Modal } from 'ui-lib/components/Modal'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Input } from 'ui-lib/components/Input'
import { TextArea } from 'ui-lib/components/TextArea'
import { FileUploadWithPreview } from 'ui-lib/components/FileUpload'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { Card } from 'ui-lib/components/Card'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import PlusIcon from 'ui-lib/icons/plus.svg'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import Documents from 'ui-lib/icons/documents.svg'
import Trash from 'ui-lib/icons/deleteTrash.svg'
import { FileDropWithInput } from 'ui-lib/components/DropzoneWithInput'

const labelStyles = {
	fontSize: '16px',
	margin: '0 0 8px',
	color: theme.color.black3,
}

const EditProduct = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule } = payload
	const productDetails = useSelector(
		MarketPlaceDuc.selectors.getProductDetails
	)
	const [showUpload, setShowUpload] = useState(false)
	const [saModal, setSAModal] = useState(false)
	const product = getIn(productDetails, ['product']) || {}
	const productMeta = getIn(product, ['meta'])
	const meta = getIn(productDetails, ['meta']) || {}
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const {
		grade,
		specifications,
		port,
		approxLeadTime,
		colour,
		packagingType,
		productImage,
		tradeAccess = [],
		description,
		otherCertifications,
		lcv,
	} = meta

	const { name, code, id } = product

	const initialValues = {
		port,
		grade,
		specifications,
		approxLeadTime,
		colour,
		packagingType,
		description,
		productImage,
		otherCertifications,
		lcv,
	}

	if (tradeAccess === 'trade-buy-sell-access') {
		initialValues.tradeAccess = ['trade-buy-access', 'trade-sell-access']
	}
	if (tradeAccess === 'trade-buy-access') {
		initialValues.tradeAccess = ['trade-buy-access']
	}
	if (tradeAccess === 'trade-sell-access') {
		initialValues.tradeAccess = ['trade-sell-access']
	}

	const validationSchema = yup.object().shape({
		tradeAccess: yup.array().required(),
		lcv: yup.number().positive(),
	})

	const {
		values,
		handleBlur,
		handleSubmit,
		handleChange,
		errors,
		touched,
		submitForm,
		setFieldValue,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const _payload = {
				meta: {
					otherCertifications: _values.otherCertifications,
					port: _values.port,
					grade: _values.grade,
					specifications: _values.specifications,
					approxLeadTime: _values.approxLeadTime,
					colour: _values.colour,
					packagingType: _values.packagingType,
					description: _values.description,
					productImage: { ..._values.productImage },
					...productMeta,
					lcv: _values.lcv,
				},
			}

			if (_values.tradeAccess.length > 1) {
				_payload.meta.tradeAccess = 'trade-buy-sell-access'
			} else {
				_payload.meta.tradeAccess = getIn(_values, ['tradeAccess', 0])
			}

			dispatch(
				MarketPlaceDuc.creators.editProduct(
					_payload,
					id,
					t('common.updateSuccess')
				)
			)
		},
	})

	const disableCTA =
		!isEmptyObject(errors) ||
		values.tradeAccess === undefined ||
		values.tradeAccess.length === 0

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('marketPlaceProducts.marketPlace'),
			name: 'marketplace',
			isActive: true,
		},
		{
			label: t('marketPlaceProducts.editProduct'),
			name: 'edit-product',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'marketplace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule: 'seller',
					}
				)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Modal
				size="auto"
				overflow="auto"
				show={saModal}
				body={
					<SupplyAbilityModal
						show={saModal}
						details={productDetails}
						onClose={() => setSAModal(false)}
						onSubmit={details => {
							dispatch(
								MarketPlaceDuc.creators.publishSupplyAbility(
									details
								)
							)
							setSAModal(false)
						}}
						isMobile={isMobile}
					/>
				}
				hideButtons
			/>
			<Title
				title={t('products.editProductTitle')}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$ACTION,
							{
								rootModule,
								action: 'view-product',
								documentReference: id,
							}
						)
					)
				}}
			/>
			<Box
				style={{
					background: '#fff',
					padding: '22px',
					marginRight: '20px',
				}}
			>
				<Box row={!isMobile} alignItems={isMobile ? 'start' : 'center'}>
					<Box
						style={{
							width: isMobile ? '100%' : '20%',
						}}
					>
						<H3
							style={{
								lineHeight: '1.5',
								fontSize: theme.fontSize.xl,
								color: '#8D93A0',
								width: '100%',
							}}
							title={t('marketPlaceProducts.editProductDetails')}
						>
							{t('marketPlaceProducts.editProductDetails')}:
						</H3>
					</Box>
					<Box>
						<Text
							title="RBDPO"
							style={{
								lineHeight: '1.5',
								width: '100%',
								overflow: 'hidden',
								color: '#299F74',
								fontSize: theme.fontSize.xl,
								fontWeight: 'bold',
							}}
						>
							{`${name} (${code})`}
						</Text>
					</Box>
				</Box>
				<Spacer size={20} />
				<form onSubmit={handleSubmit}>
					<Card
						style={{
							marginTop: 40,
							padding: '0px 20px',
							position: 'relative',
							overflow: 'visible',
							background: '#FAFBFF',
							border: '1px solid #D9DEE5',
							borderRadius: '6px',
						}}
						textAlign="left"
					>
						<SmallText
							style={{
								position: 'absolute',
								fontSize: theme.fontSize.xl,
								color: theme.color.accent2,
								top: '-10px',
								left: '20px',
								display: 'inline-block',
								backgroundColor: '#FAFBFF',
								padding: '0 8px',
							}}
						>
							{t('marketPlaceProducts.productDetails')}
						</SmallText>
						<Box
							row={!isMobile}
							justifyContent="space-between"
							style={{
								margin: '24px 0 0 40px',
							}}
						>
							<Box width={412}>
								<Label style={labelStyles}>
									{t(
										'marketPlaceProducts.productDescription'
									)}
								</Label>
								<TextArea
									name="description"
									value={values.description}
									onBlur={handleBlur}
									onChange={e => {
										setFieldValue(
											'description',
											e.target.value
										)
									}}
									customStyles={{
										backgroundColor: '#FFFFFF',
									}}
								/>
							</Box>
							<Box width={200}>
								<FileUploadWithPreview
									type="public"
									name="license"
									size="5242880"
									onDocumentChange={document => {
										setFieldValue(
											'productImage',
											getIn(document, [0])
										)
									}}
									onUploadFailure={({ message }) => {
										dispatch(
											AppDuc.creators.showToast({
												messageType: 'error',
												message,
											})
										)
									}}
									imageSrc={
										getIn(values, [
											'productImage',
											'meta',
											'fullURL',
										]) ||
										getIn(productDetails, [
											'meta',
											'productImage',
											'meta',
											'fullURL',
										])
									}
								/>
							</Box>
						</Box>
						<Box
							style={{
								width: isMobile ? '250px' : '412px',
								margin: '24px 0 0 40px',
							}}
						>
							<Label style={labelStyles}>
								{t('marketPlaceProducts.lcaFullForm')}
							</Label>
							<Input
								value={values.lcv}
								name="lcv"
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.lcv && errors.lcv}
							/>
						</Box>
					</Card>
					<Box row={!isMobile}>
						<Box
							row
							alignItems="center"
							style={{
								background:
									'#CDD5FA 0% 0% no-repeat padding-box',
								boxShadow: '2px 8px 16px #172B4D3D',
								border: '1px solid #3F56C4',
								borderRadius: '4px',
								opacity: 1,
								margin: '44px 0 44px 40px',
								width: isMobile ? '250px' : '412px',
								cursor: 'pointer',
							}}
							onClick={() => setSAModal(true)}
						>
							<IconWrapper
								size={12}
								style={{
									background:
										'#CDD5FA 0% 0% no-repeat padding-box',
									opacity: 1,
									margin: '16px',
								}}
							>
								<Icon
									glyph={PlusIcon}
									style={{
										border: '1px solid #3F56C4',
									}}
								/>
							</IconWrapper>
							<P style={{ color: '#3F56C4' }}>
								{t('marketPlaceProducts.publishSupply3Months')}
							</P>
						</Box>
						<Box>
							<Box
								style={{
									backgroundColor: '#CE924B',
									padding: '4px 10px',
									borderRadius: '4px',
									opacity: 1,
									position: 'relative',
									top: '32px',
									marginLeft: 24,
									width: 65,
								}}
							>
								<Label
									style={{
										color: theme.color.white,
										fontSize: '12px',
									}}
									bold
								>
									{t('marketPlaceProducts.proTip')}
								</Label>
							</Box>
							<P
								style={{
									position: 'relative',
									top: '28px',
									margin: '8px 0 0 24px',
									color: '#8D93A0',
									width: isMobile ? 250 : 440,
								}}
							>
								{t('marketPlaceProducts.proTipHelpText')}
							</P>
						</Box>
					</Box>
					<Card
						style={{
							marginTop: 40,
							padding: '0px 20px',
							position: 'relative',
							overflow: 'visible',
							background: '#FAFBFF',
							border: '1px solid #D9DEE5',
							borderRadius: '6px',
						}}
						textAlign="left"
					>
						<SmallText
							style={{
								position: 'absolute',
								fontSize: theme.fontSize.xl,
								color: theme.color.accent2,
								top: '-10px',
								left: '20px',
								display: 'inline-block',
								backgroundColor: '#FAFBFF',
								padding: '0 8px',
							}}
						>
							{t('marketPlaceProducts.profileType')}
						</SmallText>
						<Box margin="28px 0 28px 8px">
							<CheckBoxGroup
								selected={values.tradeAccess || []}
								options={[
									{
										label: t(
											'marketPlaceProducts.buyProduct'
										),
										key: 'trade-buy-access',
									},
									{
										label: t(
											'marketPlaceProducts.sellProduct'
										),
										key: 'trade-sell-access',
									},
								]}
								onChange={value => {
									setFieldValue('tradeAccess', value || [])
								}}
							/>
						</Box>
					</Card>

					<Card
						style={{
							marginTop: 40,
							padding: '20px',
							position: 'relative',
							overflow: 'visible',
							background: '#FAFBFF',
							border: '1px solid #D9DEE5',
							borderRadius: '6px',
						}}
						textAlign="left"
					>
						<SmallText
							style={{
								position: 'absolute',
								fontSize: theme.fontSize.xl,
								color: theme.color.accent2,
								top: '-10px',
								left: '20px',
								display: 'inline-block',
								backgroundColor: '#FAFBFF',
								padding: '0 8px',
							}}
						>
							{t('marketPlaceProducts.packagingDeliveryDetails')}
						</SmallText>
						<Box
							style={{
								width: isMobile ? '250px' : '412px',
								margin: '24px 0 0 40px',
							}}
						>
							<Label style={labelStyles}>
								{t('marketPlaceProducts.portOfLading')}
							</Label>
							<Input
								value={values.port}
								name="port"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.port && errors.port}
							/>
						</Box>
						<Box
							style={{
								width: isMobile ? '250px' : '412px',
								marginLeft: 40,
							}}
						>
							<Label style={labelStyles}>
								{t('marketPlaceProducts.packagingType')}
							</Label>
							<Select
								value={values.packagingType}
								options={[
									{
										label: t('marketPlace.bulk'),
										name: 'bulk',
									},
									{
										label: t('marketPlace.barrel'),
										name: 'barrel',
									},
									{
										label: t('marketPlace.bottle'),
										name: 'bottle',
									},
									{
										label: t('marketPlace.box'),
										name: 'box',
									},
									{
										label: t('marketPlace.can'),
										name: 'can',
									},
									{
										label: t('marketPlace.bags'),
										name: 'bags',
									},
									{
										label: t('marketPlace.bucket'),
										name: 'bucket',
									},
									{
										label: t('marketPlace.carton'),
										name: 'carton',
									},
									{
										label: t(
											'marketPlace.intermediate-bulk-container'
										),
										name: 'intermediate-bulk-container',
									},
									{
										label: t('marketPlace.pallet'),
										name: 'pallet',
									},
									{
										label: t('marketPlace.pallet-box'),
										name: 'pallet-box',
									},
									{
										label: t('marketPlace.pouch'),
										name: 'pouch',
									},
									{
										label: t('marketPlace.tube'),
										name: 'tube',
									},
								]}
								key={values.packagingType}
								labelKey="label"
								valueKey="name"
								onChange={value =>
									setFieldValue('packagingType', value.name)
								}
								returnOnlyValue
							/>
						</Box>
						<Box
							style={{
								width: isMobile ? '250px' : '412px',
								marginLeft: 40,
							}}
						>
							<Label style={labelStyles}>
								{t('marketPlaceProducts.colour')}
							</Label>
							<Input
								value={values.colour}
								name="colour"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.colour && errors.colour}
							/>
						</Box>
						<Box
							style={{
								width: isMobile ? '250px' : '412px',
								marginLeft: 40,
							}}
						>
							<Label style={labelStyles}>
								{t('marketPlaceProducts.approxLeadTime')}
							</Label>
							<Input
								value={values.approxLeadTime}
								name="approxLeadTime"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.approxLeadTime &&
									errors.approxLeadTime
								}
							/>
						</Box>
					</Card>
					<Card
						style={{
							padding: '0px 20px',
							position: 'relative',
							overflow: 'visible',
							background: '#FAFBFF',
							border: '1px solid #D9DEE5',
							borderRadius: '6px',
							marginTop: 40,
						}}
						textAlign="left"
					>
						<SmallText
							style={{
								position: 'absolute',
								fontSize: theme.fontSize.xl,
								color: theme.color.accent2,
								top: '-10px',
								left: '20px',
								display: 'inline-block',
								backgroundColor: '#FAFBFF',
								padding: '0 8px',
							}}
						>
							{t('marketPlaceProducts.specifications')}
						</SmallText>
						<Box
							style={{
								width: isMobile ? '250px' : '412px',
								margin: '24px 0 0 40px',
							}}
						>
							<Label style={labelStyles}>
								{t('marketPlaceProducts.grade')}
							</Label>
							<Input
								value={values.grade}
								name="grade"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.grade && errors.grade}
							/>
						</Box>
						<Box
							style={{
								width: isMobile ? '250px' : '412px',
								marginLeft: 40,
							}}
						>
							<Label style={labelStyles}>
								{t('marketPlaceProducts.qualitySpecifications')}
							</Label>
							<TextArea
								name="specifications"
								value={values.specifications}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'specifications',
										e.target.value
									)
								}}
								customStyles={{
									backgroundColor: '#FFFFFF',
								}}
							/>
						</Box>
						<Box padding="0px 0px 0px 40px">
							<Label style={labelStyles}>
								{t('marketPlaceProducts.additionalDetails')}
							</Label>

							<Box row={!isMobile}>
								{(values.otherCertifications || []).map(
									(data, index) => (
										<Box
											key={data.id}
											style={{
												width: 60,
												margin: '10px 10px',
											}}
										>
											<Box
												onClick={() => {
													setFieldValue(
														'otherCertifications',
														removeAt(
															values.otherCertifications,
															index
														)
													)
												}}
												alignItems="center"
												justifyContent="center"
												style={{
													height: 30,
													backgroundColor: '#E9BDBC',
													width: 30,
													borderRadius: '15px',
													border: '1px solid #BF3333',
													position: 'absolute',
													'margin-top': '-13px',
													'margin-left': '38px',
												}}
											>
												<Icon
													color="#BF3333"
													glyph={Trash}
													style={{
														height: 30,
														width: 20,
													}}
												/>
											</Box>
											<Box
												style={{
													height: 60,
													backgroundColor: '#ffeed4',
													width: 60,
													margin: '0px 8px 0px 0px',
												}}
												onClick={() => {
													window.open(
														getIn(data, [
															'meta',
															'fullURL',
														]),
														'_blank'
													)
												}}
												alignItems="center"
												justifyContent="center"
											>
												<Icon
													color="#9e797a"
													glyph={Documents}
													style={{
														height: 30,
														width: 20,
													}}
												/>
											</Box>
											<P
												style={{
													margin: 0,
													'text-overflow': 'ellipsis',
													'white-space': 'nowrap',
													overflow: 'hidden',
												}}
											>
												{getIn(data, ['meta', 'name'])}
											</P>
										</Box>
									)
								)}
								<Button
									label={t(
										'marketPlaceProducts.addAttachments'
									)}
									onClick={() => setShowUpload(true)}
									extendStyles={{
										height: 42,
										margin: isMobile ? '0px' : '0px 52px',
									}}
								/>
							</Box>
						</Box>
						<Modal
							size="large"
							overflow="auto"
							show={showUpload}
							heading={t('marketPlaceProducts.addAttachment')}
							hideButtons
							closeable
							body={
								<>
									<FileDropWithInput
										type="public"
										size="5242880"
										onDocumentChange={documents => {
											const newFiles =
												values.otherCertifications
													?.length > 0
													? addLast(
															values.otherCertifications,
															documents
													  )
													: documents
											setFieldValue(
												'otherCertifications',
												newFiles
											)
											setShowUpload(false)
										}}
										onUploadFailure={({ message }) => {
											dispatch(
												AppDuc.creators.showToast({
													messageType: 'error',
													message,
												})
											)
										}}
									/>
								</>
							}
							onClose={() => setShowUpload(false)}
						/>
					</Card>
				</form>
			</Box>
			<Box row justifyContent="flex-end" margin="36px 0 0">
				<Button
					label={t('marketPlaceProducts.update')}
					disabled={disableCTA}
					primary
					onClick={() => submitForm()}
					extendStyles={{ width: 200 }}
				/>
			</Box>
		</Box>
	)
}

export { EditProduct }
