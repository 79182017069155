import Duck from 'extensible-duck'
import { redirect, NOT_FOUND } from 'redux-first-router'
import { getIn } from 'timm'
import { getSeparatedLoginHost } from 'ui-lib/config'
import { convertToTitleCase } from 'ui-marketplace-app/utils/helpers'
import { switchRoute, isDomainChange } from 'ui-lib/utils/routePathConfig'

export const initialState = 'Error'

export const namespace = '@route'

export const store = 'global'

export const routePageInterceptor = action => {
	// intercept and derive the names of the route you want to inject.
	if (action.type === 'NOT_FOUND') {
		return 'Error'
	}

	// using empty string to make same return type
	return ''
}

export const reducer = (state, action, duck) => {
	const interceptedRouteState = routePageInterceptor(action)

	// if routePageInterceptor could arrive at some derivation, use that
	if (interceptedRouteState !== '') return interceptedRouteState

	// default matching from type
	let matchFromType = ''
	// eslint-disable-next-line
	action &&
		action.type.indexOf('@route') > -1 &&
		duck.options.types.some(val => {
			const actionType = action.type.split('@route/global/')[1]
			if (actionType === val) {
				// see if there is a match from types we defined.
				// if $ is present, it means route is served to reused component.
				matchFromType = convertToTitleCase(val.split('$')[0])

				return true
			}

			return false
		})

	return matchFromType || state
}

export const selectors = {
	page: state => state.page,
}

export const creators = duck => ({
	switchPage: (
		actionType,
		payload,
		query = {},
		customMeta = {},
		changeDomain = false
	) => {
		if (
			(changeDomain && isDomainChange(actionType)) ||
			actionType === '@route/global/DASHBOARD' // for breadcrum home redirection
		) {
			switchRoute(actionType, payload, query, customMeta)
		} else {
			return {
				type: actionType,
				payload,
				customMeta,
				...(query ? { meta: { query } } : {}),
			}
		}
	},
	switch500: () => ({
		type: duck.types['500'],
	}),
	switch401: () => ({
		type: duck.types['401'],
	}),
	notFound: () => ({
		type: NOT_FOUND,
	}),
	redirect: (actionType, payload, query, customMeta) => {
		if (getIn(payload, ['action']) === 'sign-in') {
			window.location = `${getSeparatedLoginHost()}/auth/sign-in${
				getIn(query, ['redirect_to'])
					? `?redirect_to=${getIn(query, ['redirect_to'])}`
					: ''
			}`
		} else {
			redirect({
				type: actionType,
				payload,
				customMeta,
				...(query ? { meta: { query } } : {}),
			})
		}
	},
})

// Feeds the page state of the global redux state.
// This is used to conditionally render your components in router.
export const MainRouteDuc = new Duck({
	namespace,
	store,
	types: [
		'DASHBOARD',
		'404',
		'401',
		'500',
		'ONBOARD',
		'ONBOARD$ACTION',
		'AUTH',
		'TRADE_DOCUMENT_MANAGER',
		'TRADE_DOCUMENT_MANAGER$SUBROOT',
		'TRADE_DOCUMENT_MANAGER$LISTING',
		'TRADE_DOCUMENT_MANAGER$WACTION',
		'TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE',
		'TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE',
		'TRADE_DOCUMENT_MANAGER$COMPLETETRADEREPORT',
		'TRADE_DOCUMENT_MANAGER$PRODUCT_TRACE',
		'PLANTATION$DOCUMENTREFERENCETYPE',
		'TRADE_DOCUMENT_MANAGER$GENERATE_PRODUCT_TRACE',
		'TRADE_DOCUMENT_MANAGER$AUDIT_REPORT',
		'MILL',
		'MILL$SUBROOT',
		'MILL$WACTION',
		'MILL$WDOCREFERENCE',
		'MILL$LISTING',
		'PLANTATION',
		'PLANTATION$SUBROOT',
		'PLANTATION$WACTION',
		'PLANTATION$DOCUMENTREFERENCE',
		'REFINERY',
		'REFINERY$SUBROOT',
		'REFINERY$WACTION',
		'REFINERY$WDOCREFERENCE',
		'REFINERY$LISTING',
		'WEIGHBRIDGE',
		'WEIGHBRIDGE$WACTION',
		'WEIGHBRIDGE$DOCUMENTREFERENCE',
		'ADMIN',
		'ADMIN$SUBROOT',
		'ADMIN$WACTION',
		'ADMIN$SUBMODULEWACTION',
		'ADMIN$WDOCREFERENCE',
		'ADMIN$SUBMODULEWDOCREFERENCE',
		'PARTNER',
		'PARTNER$ACTION',
		'PARTNER$WREQREFERENCE',
		'SETTINGS',
		'SETTINGS$SUBROOT',
		'FEEDBACK',
		'PRODUCT_TRACE',
		'PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_V1',
		'PRODUCT_TRACE$GENERATE_PRODUCT_TRACE',
		'PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP',
		'PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_MAP',
		'MESSAGES',
		'STORAGE_COMPANY',
		'STORAGE_COMPANY$LISTING',
		'STORAGE_COMPANY$SUBMODULELISTING',
		'STORAGE_COMPANY$SUBROOT',
		'TRADER',
		'END_MANUFACTURER',
		'POLICY',
		'INVENTORY',
		'INVENTORY$ACTION',
		'INVENTORY$WDOCREFERENCE',
		'DEALER',
		'DEALER$SUBROOT',
		'DEALER$WACTION',
		'DEALER$WDOCREFERENCE',
		'TRIPS',
		'TRIPS$WDOCREFERENCE',
		'WBTICKET',
		'WBTICKET$WDOCREFERENCE',
		'WBTICKET$WACTION',
		'SCHEDULER',
		'SCHEDULER$WACTION',
		'SCHEDULER$WDOCREFERENCE',
		'GENERAL_SCHEDULER',
		'GENERAL_SCHEDULER$WDOCREFERENCE',
		'PRODUCTS',
		'PRODUCTS$WACTION',
		'PRODUCTS$WDOCREFERENCE',
		'MARKETPLACE',
		'MARKETPLACE$ACTION',
		'MARKETPLACE$CONTRACTS',
		'MARKETPLACE$CONTRACTSWDOCTYPE',
		'MARKETPLACE$ACTIONSTATUS',
	],
	initialState,
	reducer,
	selectors,
	creators,
})
