import React, { useState } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import PlusIcon from 'ui-lib/icons/plus.svg'
import theme from 'ui-lib/utils/base-theme'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'

const ContractsUpload = props => {
	const { onChange } = props
	const [uploadedFiles, setUploadedFiles] = useState([])

	const readFileAsync = file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.onload = () => {
				resolve(reader.result)
			}
			reader.onerror = reject
			reader.readAsArrayBuffer(file)
		})
	}

	const range = (start, end) => {
		const length = end - start + 1

		return Array.from({ length }, (_, i) => start + i - 1)
	}

	const extractPdfPage = async arrayBuff => {
		const pdfSrcDoc = await PDFDocument.load(arrayBuff)
		const pdfNewDoc = await PDFDocument.create()
		const pages = await pdfNewDoc.copyPages(
			pdfSrcDoc,
			range(1, pdfSrcDoc.getPageCount())
		)
		pages.forEach(page => pdfNewDoc.addPage(page))
		const lastPage = pdfNewDoc.addPage()

		const fontSize = 12
		const timesRomanFont = await pdfNewDoc.embedFont(
			StandardFonts.TimesRoman
		)
		const { width, height } = lastPage.getSize()
		lastPage.drawText('As Sellers:', {
			x: 50,
			y: height / 2 + 300,
			size: fontSize,
			font: timesRomanFont,
			color: rgb(0, 0, 0),
		})

		lastPage.drawText('As Brokers:', {
			x: width / 2 - 25,
			y: height / 2 + 300,
			size: fontSize,
			font: timesRomanFont,
			color: rgb(0, 0, 0),
		})

		lastPage.drawText('As Buyers:', {
			x: width - 100,
			y: height / 2 + 300,
			size: fontSize,
			font: timesRomanFont,
			color: rgb(0, 0, 0),
		})

		const newpdf = await pdfNewDoc.save()

		return { pdf: newpdf, count: pdfNewDoc.getPageCount() }
	}

	const getBlob = uint8array => {
		return new Blob([uint8array], {
			type: 'application/pdf',
		})
	}

	// Execute when user select a file
	const handleContractUpload = async event => {
		const { files } = event.target

		if (files?.length > 0) {
			const filesArray = Array.from(files)
			const pdfArrayBuffer = await readFileAsync(filesArray[0])
			const currentpdf = await extractPdfPage(pdfArrayBuffer)
			const blob = getBlob(currentpdf.pdf)
			onChange(currentpdf.count, blob, files[0].name)
		}
		setUploadedFiles([...files])
	}

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<input
					id="file-upload"
					style={{ display: 'none' }}
					type="file"
					onChange={handleContractUpload}
				/>
				<label htmlFor="file-upload">
					<Box
						style={{
							height: 88,
							width: 88,
							borderRadius: '50%',
							background: '#645DB7',
							cursor: 'pointer',
							boxShadow:
								'inset 0 0 0 10px #E4E2FA, inset 0 0 0 20px #B6B2E5',
						}}
						center
					>
						<IconWrapper size={24}>
							<Icon glyph={PlusIcon} color={theme.color.white} />
						</IconWrapper>
					</Box>
				</label>
				{uploadedFiles.length > 0 && <p>{uploadedFiles[0].name}</p>}
			</div>
		</>
	)
}

export { ContractsUpload }
