import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { BoxShadowTable } from 'ui-marketplace-app/shared/components/BoxShadowTable'
import { Title } from 'ui-marketplace-app/shared/components/Title'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { TABLE_ALIAS } from 'ui-marketplace-app/modules/MarketPlace/config'
import { Wrapper } from 'ui-marketplace-app/modules/MarketPlace/containers/Enquiries/ViewRFQ'
import { ShowMore } from 'ui-marketplace-app/modules/MarketPlace/components/ShowMore'
import { ProductsModal } from 'ui-marketplace-app/modules/MarketPlace/components/ProductsModal'
import { ProductProfile } from 'ui-marketplace-app/modules/MarketPlace/components/ProductProfile'
import { Label, TableTitle, P, H2 } from 'ui-lib/components/Typography'
import { Modal } from 'ui-lib/components/Modal'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import ReportProblemIcon from 'ui-lib/icons/report_problem.svg'
import InfoIcon from 'ui-lib/icons/info_black.svg'

const columnConfig = ({ t, onChange, isMobile = false }) => {
	return [
		{
			property: 'productName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.productName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const productName = getIn(datum, ['product', 'name'])
				const productCode = getIn(datum, ['product', 'code'])

				return (
					<Label as="span">
						{`${productName}     (${productCode})` || '---'}
					</Label>
				)
			},
			size: isMobile && '50%',
		},
		{
			property: 'checkForUpdate',
			render: datum => {
				const specifications =
					getIn(datum, ['meta', 'specifications']) || ''
				const tradeAccess = getIn(datum, ['meta', 'tradeAccess']) || ''
				const description = getIn(datum, ['meta', 'description']) || ''
				const lcv = getIn(datum, ['meta', 'lcv']) || ''

				const checkForUpdate =
					specifications === '' ||
					tradeAccess === '' ||
					description === '' ||
					lcv === ''

				return (
					checkForUpdate && (
						<Box
							style={{
								backgroundColor: '#FFEED4',
								padding: '2px 8px',
								margin: '0 0 8px',
								borderRadius: '5px',
								opacity: 1,
								textAlign: 'center',
							}}
						>
							<Label
								style={{
									color: '#F09112',
								}}
							>
								{t(
									'marketPlaceProducts.updateRequired'
								).toUpperCase()}
							</Label>
						</Box>
					)
				)
			},
			size: '16%',
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('view_document', {
								row: datum,
							})
						}}
					/>
				)
			},
		},
	]
}

const ProductsListing = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const documents = useSelector(MarketPlaceDuc.selectors.getProductsListing)
	const pagination = useSelector(
		MarketPlaceDuc.selectors.getProductsPaginationQueries
	)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [showModal, setShowModal] = useState(false)
	const [confModal, setConfModal] = useState(false)
	const [productProfileModal, setProductProfileModal] = useState(false)
	const [activeProducts, setActiveProducts] = useState([])
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule } = payload

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'view_document': {
					if (meta.row.product.id) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$ACTION,
								{
									rootModule,
									action: 'view-product',
									documentReference: meta.row.product.id,
								}
							)
						)
					}
					break
				}

				case 'products_pagination': {
					const locationState = {
						nextStartID: pagination.nextStartID,
					}

					dispatch(
						MarketPlaceDuc.creators.fetchProductsListing(
							locationState
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, pagination.nextStartID, rootModule]
	)

	function checkForUpdate(data) {
		const individualProductCheck = data.map(prod => {
			const specifications = getIn(prod, ['meta', 'specifications']) || ''
			const tradeAccess = getIn(prod, ['meta', 'tradeAccess']) || ''
			const description = getIn(prod, ['meta', 'description']) || ''
			const lcv = getIn(prod, ['meta', 'lcv']) || ''

			return (
				specifications === '' ||
				tradeAccess === '' ||
				description === '' ||
				lcv === ''
			)
		})

		return individualProductCheck.find(p => p === true)
	}

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('marketPlaceProducts.products'),
			name: 'products',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<ProductsModal
				show={showModal}
				onClose={() => {
					setShowModal(false)
				}}
				onProductConfirm={({ products }) => {
					setActiveProducts(products)
					setShowModal(false)
					setConfModal(true)
				}}
			/>
			<Modal
				forceCloseviaButton
				show={confModal}
				size="large"
				heading={
					<Box row>
						<IconWrapper
							size={40}
							style={{
								padding: 4,
								background: '#FFEFD8',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 6,
							}}
						>
							<Icon
								glyph={ReportProblemIcon}
								style={{ color: '#F09112' }}
							/>
						</IconWrapper>
						<H2
							style={{
								marginLeft: 12,
								fontWeight: 'bold',
								color: '#F09112',
							}}
						>
							{t('marketPlaceProducts.confirmation')}
						</H2>
					</Box>
				}
				body={
					<P large bold>
						{t('marketPlaceProducts.confirmationMessage')}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.proceed')}
				onClose={() => {
					setConfModal(false)
				}}
				onConfirm={() => {
					setConfModal(false)
					setProductProfileModal(true)
				}}
			/>
			<Modal
				size="auto"
				overflow="auto"
				show={productProfileModal}
				body={
					<ProductProfile
						documents={activeProducts}
						onConfirm={values => {
							dispatch(
								MarketPlaceDuc.creators.addProduct(
									values,
									t('common.successCreate')
								)
							)
							setActiveProducts(values)
							setProductProfileModal(false)
						}}
						isMobile={isMobile}
					/>
				}
				closeable
				onClose={() => setProductProfileModal(false)}
				hideButtons
			/>

			<Title title={t('products.products')} />
			<Wrapper width={isMobile ? '390px' : '740px'}>
				<Box row padding={16} alignItems="center">
					<IconWrapper size={24} style={{ marginRight: 16 }}>
						<Icon glyph={InfoIcon} color="#F09112" />
					</IconWrapper>
					{t('marketPlaceProducts.productsHelpTex')}
				</Box>
			</Wrapper>
			<Spacer size={40} />
			{checkForUpdate(documents) && (
				<Wrapper width={isMobile ? '390px' : '740px'}>
					<Box row padding={16} alignItems="center">
						{t('marketPlaceProducts.productProfileText')}
					</Box>
				</Wrapper>
			)}
			<Box row justifyContent="flex-end" style={{ marginTop: '10px' }}>
				<Button
					label={t('products.addProduct')}
					primary
					rounded
					onClick={() => setShowModal(true)}
				/>
			</Box>
			<Spacer size={40} />
			<BoxShadowTable
				size="large"
				columnConfig={columnConfig({
					onChange: handleChange,
					t,
					isMobile,
				})}
				rowData={documents}
				isMobile={isMobile}
			/>
			<ShowMore
				documents={documents}
				total={pagination.total}
				handleChange={handleChange}
				type="products_pagination"
			/>
		</Box>
	)
}

export { ProductsListing }
