import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { supplyChainModels } from 'ui-marketplace-app/modules/Admin/components/StorageDetails'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	RFQ_STATUS,
	STATUS_TEXT_COLOR,
	STATUS_BACKGROUND,
	MONTHS,
	SHIPMENT_PERIOD,
	CONTRACT_TYPE,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { KeyValueSegment } from 'ui-marketplace-app/shared/components/KeyValueSegment'
import { SummaryBlock } from 'ui-marketplace-app/shared/components/DocumentLead'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { ShareRFQModal } from 'ui-marketplace-app/modules/MarketPlace/components/ShareRFQModal'
import { ExistingAccountsModal } from 'ui-marketplace-app/modules/MarketPlace/components/ExistingAccountsModal'
import { Modal } from 'ui-lib/components/Modal'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Label } from 'ui-lib/components/Label'
import { H2, Text, P } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import ShareIcon from 'ui-lib/icons/share.svg'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'

const tableHeaderStyles = {
	background: '#C9F5E5',
	color: '#299F74',
	padding: 16,
}

const tableRowStyles = {
	border: '1px solid #CAD2DD',
	padding: 16,
}

export const Wrapper = styled.div(p => ({
	margin: p.margin || 0,
	width: p.width || '740px',
	height: 'auto',
	background: '#fff1dd 0% 0% no-repeat padding-box',
	border: '1px solid #f09112',
	borderLeft: '10px solid #f09112',
	borderRadius: '6px',
	opacity: 1,
	color: '#c97b12',
	letterSpacing: 0,
	fontSize: '16px',
}))

const ViewPostRequirement = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const rfqDetails = useSelector(MarketPlaceDuc.selectors.getRFQDetails)
	const actor = useSelector(MarketPlaceDuc.selectors.getMarketPlaceActor)
	const existingAccountsModalStatus = useSelector(
		MarketPlaceDuc.selectors.getExistingAccountsModalStatus
	)

	const {
		status,
		rfqID,
		rfqNumber,
		seller,
		buyerAddress,
		sellerAddress,
		payment,
		destinationPort,
		ladingPort,
		supplyChainModel,
		productCode,
		product,
		month,
		sellerCategory,
		period,
		inspectionType = [],
		contractType,
		requirementDetails,
		termsAndConditions,
		quantity,
		uom,
		certificates = [],
		buyer,
		buyerCategory,
		buyerState,
		buyerCountry,
	} = rfqDetails
	const quantityInsp = inspectionType.filter(insp => insp.type === 'quantity')
	const qualityInsp = inspectionType.filter(insp => insp.type === 'quality')

	const quantityInspector = getIn(quantityInsp, [0, 'officer'])
	const qualityInspector = getIn(qualityInsp, [0, 'officer'])

	const inspection = inspectionType.map(insp => insp.type) || []

	const [rfqModal, setRFQModal] = useState(false)

	const [existingAccountModal, setExistingAccountModal] = useState(
		existingAccountsModalStatus
	)

	useEffect(() => {
		setExistingAccountModal(existingAccountsModalStatus)
	}, [existingAccountsModalStatus])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('postRequirement.marketPlace'),
			name: 'marketplace',
			isActive: true,
		},
		{
			label: t('postRequirement.viewGeneralRequirement'),
			name: 'view-general-requirement',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'marketplace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule,
					}
				)
			)
		}
	}

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Title
				title={t('postRequirement.viewRequirementDetails')}
				icon={LeftArrowIcon}
				small
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE,
							{
								rootModule,
							}
						)
					)
				}}
			/>
			<Modal
				size="auto"
				show={rfqModal}
				body={
					<ShareRFQModal
						onClose={() => setRFQModal(false)}
						onConfirm={emails => {
							dispatch(
								MarketPlaceDuc.creators.shareRFQWithExistingTradePartners(
									emails,
									rfqID
								)
							)
							setRFQModal(false)
						}}
					/>
				}
				hideButtons
			/>
			<Modal
				size="770px"
				show={existingAccountModal}
				body={
					<ExistingAccountsModal
						onConfirm={rfqPayload => {
							dispatch(
								MarketPlaceDuc.creators.createRFQForExistingOrgs(
									rfqPayload,
									t('production.plotInputSuccessToast')
								)
							)
							dispatch(
								MarketPlaceDuc.creators.toggleExistingAccountsModal(
									false
								)
							)
						}}
					/>
				}
				hideButtons
			/>
			<Box
				row
				justifyContent={isMobile ? 'start' : 'flex-end'}
				margin={isMobile ? '20px 0' : '0 0 20px'}
			>
				{rootModule === 'buyer' && (
					<Button
						primary
						label={t('postRequirement.shareViaEmail')}
						onClick={() => setRFQModal(true)}
						extendStyles={{ marginLeft: 24 }}
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={ShareIcon} />
							</ButtonIconWrapper>
						}
					/>
				)}
			</Box>
			{rootModule === 'buyer' && (
				<Wrapper margin="52px 0" width={isMobile ? '390px' : 'auto'}>
					<Box row padding={16} alignItems="center">
						<IconWrapper size={24} style={{ marginRight: 16 }}>
							<Icon glyph={ShareIcon} color="#F09112" />
						</IconWrapper>
						{t('postRequirement.shareViaEmailHelpText')}
					</Box>
				</Wrapper>
			)}
			<Box
				style={{
					background: '#fff',
					padding: '28px 40px',
					marginTop: 40,
				}}
			>
				<Box
					row={!isMobile}
					justifyContent="space-between"
					alignItems="baseline"
				>
					<H2 color={theme.color.green}>{rfqNumber}</H2>
					<Box row justifyContent="flex-end">
						<Box
							style={{
								backgroundColor: STATUS_BACKGROUND[status],
								padding: '8px 16px',
								margin: '0 0 8px',
								borderRadius: '5px',
								opacity: 1,
								textAlign: 'center',
							}}
						>
							<Label
								style={{
									color: STATUS_TEXT_COLOR[status],
								}}
							>
								{t(RFQ_STATUS[status]).toUpperCase()}
							</Label>
						</Box>
					</Box>
				</Box>
				<Box row={!isMobile} margin="16px 0">
					<Box width="50%">
						<Label style={{ color: theme.color.grey15 }}>
							{`${t('postRequirement.issued')} ${
								actor === 'mp_sales_marketing_officer'
									? t('postRequirement.by')
									: t('postRequirement.to')
							}`}
						</Label>
						<Spacer size={8} />
						<Box style={{ maxWidth: 250 }}>
							<Title
								title={
									actor === 'mp_sales_marketing_officer'
										? buyer
										: seller
								}
								small
								note={
									actor === 'mp_sales_marketing_officer'
										? buyerCategory
										: sellerCategory
								}
							/>
						</Box>
						<Spacer size={16} />
						<Text
							style={{
								lineHeight: '1.5',
								width: '100%',
								overflow: 'hidden',
								color: '#8D93A0',
								maxWidth: '200px',
							}}
						>
							{actor === 'mp_sales_marketing_officer'
								? buyerAddress
								: sellerAddress}
						</Text>
						<Spacer size={12} />
						<Text>{certificates.join(', ')}</Text>
						<Spacer size={12} />
						<Box row>
							<SummaryBlock
								name={t('postRequirement.paymentTerms')}
								detail={payment}
								width="100%"
							/>
						</Box>
					</Box>
					{isMobile && (
						<Box
							height="14px"
							width="auto"
							margin="20px 0"
							style={{ background: '#F2F3F7' }}
						/>
					)}
					<Box>
						<Label style={{ color: theme.color.grey15 }}>
							{t('rfq.destinationDetails')}
						</Label>
						<Spacer size={8} />
						<Box
							style={{
								border: isMobile ? 'none' : '1px solid #D9DEE5',
								padding: isMobile
									? '28px 0'
									: '28px 0 28px 16px',
								borderRadius: 6,
							}}
						>
							<KeyValueSegment
								name={t('postRequirement.address')}
								description={buyerAddress}
							/>
							<KeyValueSegment
								name={t('postRequirement.stateCountry')}
								description={`${buyerState}, ${buyerCountry}`}
							/>
							<KeyValueSegment
								name={t('postRequirement.port')}
								description={destinationPort}
							/>
						</Box>
						<Spacer size={12} />
						<Box row>
							<SummaryBlock
								name={t('postRequirement.portOfLading')}
								detail={ladingPort}
								width="100%"
							/>
							<SummaryBlock
								name={t('postRequirement.scModel')}
								detail={t(supplyChainModels[supplyChainModel])}
								width="100%"
							/>
						</Box>
					</Box>
				</Box>
				<Spacer size={20} />
				<Box
					height="14px"
					width="auto"
					style={{ background: '#F2F3F7' }}
				/>
				<Label
					style={{
						color: theme.color.grey15,
						margin: '20px 0 0 4px',
					}}
				>
					{t('postRequirement.productDetails')}
				</Label>
				<table
					style={{
						width: isMobile ? '100%' : '70%',
						borderSpacing: 4,
						borderCollapse: 'separate',
					}}
				>
					<tr>
						<th align="left" style={tableHeaderStyles}>
							{t('postRequirement.product')}
						</th>
						<th align="left" style={tableHeaderStyles}>
							{`${t('postRequirement.quantity')} (${uom &&
								uom.toUpperCase()})`}
						</th>
					</tr>
					<tr>
						<td style={tableRowStyles}>
							{`${product}  ${productCode || ' '}`}
						</td>
						<td style={tableRowStyles}>{quantity}</td>
					</tr>
				</table>
				<Spacer size={20} />
				<Box
					height="14px"
					width="auto"
					style={{ background: '#F2F3F7' }}
				/>
				<Label style={{ color: theme.color.grey15, margin: '20px 0' }}>
					{t('postRequirement.additionalDetails')}
				</Label>
				<Box row={!isMobile} justifyContent="space-between">
					<SummaryBlock
						name={t('postRequirement.shipmentPeriod')}
						detail={`${t(MONTHS[month])}, ${t(
							SHIPMENT_PERIOD[period]
						)}`}
						width="100%"
					/>

					<SummaryBlock
						name={t('postRequirement.thirdPartyInspection')}
						detail={inspection && inspection.join(', ')}
						width="100%"
					/>

					<SummaryBlock
						name={t('postRequirement.preferredQuantityInspector')}
						detail={quantityInspector || ' '}
						width="100%"
					/>
				</Box>
				<Spacer size={20} />
				<Box row={!isMobile} width="56%" justifyContent="space-between">
					<SummaryBlock
						name={t('postRequirement.typeOfContract')}
						detail={t(CONTRACT_TYPE[contractType]) || ' '}
						width="100%"
					/>
					<Box>
						<SummaryBlock
							name={t(
								'postRequirement.preferredQualityInspector'
							)}
							detail={qualityInspector || ' '}
							width="100%"
						/>
					</Box>
				</Box>
				<Spacer size={20} />
				<Box
					height="14px"
					width="auto"
					style={{ background: '#F2F3F7' }}
				/>
				<Label
					style={{
						color: theme.color.grey15,
						margin: '20px 0 8px 0',
					}}
				>
					{t('postRequirement.termsAndConditions')}
				</Label>
				<P>{termsAndConditions}</P>
				<Spacer size={12} />
				<Label
					style={{
						color: theme.color.grey15,
						margin: '20px 0 8px 0',
					}}
				>
					{t('postRequirement.requirementDetails')}
				</Label>
				<P>{requirementDetails}</P>
			</Box>
		</>
	)
}

export { ViewPostRequirement }
