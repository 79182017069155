import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { addLast, getIn, removeAt, replaceAt } from 'timm'
import { Grommet, CheckBox } from 'grommet'
import { H2, P, Label } from 'ui-lib/components/Typography'
import { Table } from 'ui-lib/components/Table'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { customCheckBoxTheme } from './SubComponents'

const columnConfig = ({ onChange, profiles, t }) => {
	return [
		{
			property: 'product',
			render: datum => {
				const { code, name, id } = datum

				const profile = profiles.filter(prof => prof.id === id)

				const isChecked =
					getIn(profile, [0, 'isBuyer']) === true ||
					getIn(profile, [0, 'isSeller']) === true

				return (
					<Label
						as="span"
						style={{
							fontSize: 16,
							color: isChecked ? '#13835A' : '#4B4552',
						}}
					>{`${name} (${code})`}</Label>
				)
			},
			size: '60%',
		},
		{
			property: 'buyer',
			header: (
				<Box center style={{ cursor: 'pointer' }}>
					<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
						{t('productProfile.asABuyer')}
					</Label>
				</Box>
			),
			render: datum => {
				const { id } = datum
				const product = profiles.filter(prof => prof.id === id)

				return (
					<Box center>
						<Grommet theme={customCheckBoxTheme}>
							<CheckBox
								onChange={e => {
									onChange('toggle_trade_access', {
										id,
										isBuyer: e.target.checked,
										isSeller: getIn(product, [
											0,
											'isSeller',
										]),
									})
								}}
							/>
						</Grommet>
					</Box>
				)
			},
		},
		{
			property: 'seller',
			header: (
				<Box center style={{ cursor: 'pointer' }}>
					<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
						{t('productProfile.asASeller')}
					</Label>
				</Box>
			),
			render: datum => {
				const { id } = datum

				const product = profiles.filter(prof => prof.id === id)

				return (
					<Box center>
						<Grommet theme={customCheckBoxTheme}>
							<CheckBox
								onChange={e => {
									onChange('toggle_trade_access', {
										id,
										isSeller: e.target.checked,
										isBuyer: getIn(product, [0, 'isBuyer']),
									})
								}}
							/>
						</Grommet>
					</Box>
				)
			},
		},
	]
}

const ProductProfile = ({ documents, onConfirm, isMobile = false }) => {
	const { t } = useTranslation()
	const [profiles, setProfiles] = useState([])

	const disableCTA = documents.length === profiles.length

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'toggle_trade_access') {
				// check for existing profile type
				const existingProfile = profiles.filter(
					profile => profile.id === meta.id
				)

				// get current row index
				const index = profiles.findIndex(i => i.id === meta.id)

				let tradeAccess
				let productType
				if (existingProfile.length === 0) {
					if (meta.isBuyer) {
						tradeAccess = 'trade-buy-access'
						productType = ['incoming']
					}

					if (meta.isSeller) {
						tradeAccess = 'trade-sell-access'
						productType = ['outgoing']
					}

					const newRow = addLast(profiles, {
						id: meta.id,
						tradeAccess,
						productType,
						isBuyer: meta.isBuyer,
						isSeller: meta.isSeller,
					})

					setProfiles(newRow)
				} else {
					if (meta.isBuyer || meta.isSeller) {
						if (meta.isBuyer && meta.isSeller) {
							tradeAccess = 'trade-buy-sell-access'
							productType = ['incoming', 'outgoing']
						}

						if (meta.isBuyer && meta.isSeller === false) {
							tradeAccess = 'trade-buy-access'
							productType = ['incoming']
						}

						if (meta.isSeller && meta.isBuyer === false) {
							tradeAccess = 'trade-sell-access'
							productType = ['outgoing']
						}

						const doc = replaceAt(profiles, index, {
							id: meta.id,
							tradeAccess,
							productType,
							isBuyer: meta.isBuyer,
							isSeller: meta.isSeller,
						})

						setProfiles(doc)
					}

					if (
						meta.isBuyer === undefined ||
						meta.isSeller === undefined ||
						(meta.isBuyer === false && meta.isSeller === false)
					) {
						const updatedProfiles = removeAt(profiles, index)
						setProfiles(updatedProfiles)
					}
				}
			}
		},
		[profiles]
	)

	return (
		<Box
			style={{
				width: 'auto',
				background: theme.color.white,
				borderRadius: '10px',
				boxShadow: '0px 3px 6px #00000029',
			}}
		>
			<Box margin={isMobile ? '20px 0 0 0px' : '20px 0 0 60px'}>
				<H2 style={{ fontSize: 32 }}>{t('productProfile.heading')}</H2>
				<P style={{ fontSize: 14, color: '#f09112' }}>
					{t('productProfile.mandatoryMessage')}
				</P>
			</Box>
			<Box
				height="auto"
				width={800}
				style={{
					margin: isMobile ? '28px 55px 0 0px' : '28px 55px 0 60px',
					overflow: 'auto',
				}}
			>
				<Table
					columnConfig={columnConfig({
						onChange: handleOnChange,
						profiles,
						t,
					})}
					rowData={documents}
					noRoundedBorder
					mobileViewCSSOn={isMobile}
				/>

				{!disableCTA && (
					<P
						style={{
							fontSize: 14,
							color: '#f09112',
							marginTop: 24,
						}}
					>
						{t('productProfile.allProductsMandatoryMessage')}
					</P>
				)}
			</Box>

			<Box center margin="20px 0">
				<Button
					type="submit"
					disabled={!disableCTA}
					primary
					label={t('productProfile.addProfile')}
					extendStyles={{
						width: 410,
					}}
					onClick={() => onConfirm(profiles)}
				/>
			</Box>
		</Box>
	)
}

export { ProductProfile }
