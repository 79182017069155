import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	MONTHS,
	SHIPMENT_PERIOD,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { Modal } from 'ui-lib/components/Modal'
import { Box } from 'ui-lib/utils/Box'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { SmallText, P } from 'ui-lib/components/Typography'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import { DigitalContractMessage } from '../../../../components/DigitalContractMessage'
import '../styles/contract1.css'

const PreviewContractNo1 = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { contractAction } = query
	const { rootModule, documentReference, documentType } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [showModal, setShowModal] = useState(false)

	const contractDetails = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	if (!contract.quantity && contractAction === 'generate') {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('contracts.editContractText')}
					</P>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.ok')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					dispatch(
						MarketPlaceDuc.creators.editContract(
							contractDetails,
							documentType
						)
					)
				}}
			/>
			<Title
				title={
					contractAction === 'edit'
						? t('contracts.editContractPreview')
						: t('contracts.createContractPreview')
				}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'participants-success',
								documentReference,
								documentType,
							},
							{
								contractAction,
							}
						)
					)
				}}
				small
				isMobile={isMobile}
				hasNoMaxWidth
			/>
			<Spacer size={60} />
			<Box center style={{ background: '#EAEAEB' }}>
				<div
					id="preview-contract"
					style={{ width: isMobile ? '350px' : '670px' }}
				>
					<h2
						style={{
							paddingTop: '3pt',
							textIndent: '0pt',
							lineHeight: '12pt',
							textAlign: 'center',
						}}
					>
						DOMESTIC CONTRACT
					</h2>
					<h2
						style={{
							textIndent: '0pt',
							textAlign: 'center',
						}}
					>
						FOR MALAYSIAN CRUDE UNBLEACHED
					</h2>
					<h2
						style={{
							textIndent: '0pt',
							textAlign: 'center',
						}}
					>
						PALM OIL IN BULK
					</h2>
					<p style={{ textIndent: '0pt', textAlign: 'center' }}>
						<br />
					</p>
					<h2
						style={{
							textIndent: '0pt',
							textAlign: 'center',
						}}
					>
						Issued Jointly By The Palm Oil Refiners Association Of
						Malaysia (PORAM)
					</h2>
					<h2
						style={{
							textIndent: '0pt',
							textAlign: 'center',
						}}
					>
						And Malaysian Palm Oil Association (MPOA)
					</h2>
					<p style={{ textIndent: '0pt', textAlign: 'center' }}>
						<br />
					</p>
					<p style={{ textIndent: '0pt', textAlign: 'left' }}>
						<br />
					</p>
					<p
						className="s1"
						style={{
							textIndent: '0pt',
							textAlign: 'left',
							paddingLeft: '6pt',
						}}
					>
						Revised and Effective 1 February 2012
					</p>
					<p
						className="s2"
						style={{
							textIndent: '0pt',
							textAlign: 'right',
							marginRight: '12pt',
						}}
					>
						1
					</p>
					<p style={{ textIndent: '0pt', textAlign: 'left' }}>
						<br />
					</p>
					<p style={{ textIndent: '0pt', textAlign: 'left' }}>
						<br />
					</p>
					<p style={{ textIndent: '0pt', textAlign: 'left' }} />
					<div
						style={{
							display: isMobile ? 'block' : 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<div>
							<p
								className="s1"
								style={{
									paddingBottom: '2pt',
									paddingLeft: '6pt',
									textIndent: '0pt',
									textAlign: 'left',
								}}
							>
								Seller :{' '}
								{getIn(contractDetails, [
									'contract',
									'seller',
									'name',
								])}
							</p>
							<p
								style={{
									paddingLeft: '389pt',
									textIndent: '0pt',
									lineHeight: '1pt',
									textAlign: 'left',
								}}
							/>
							<p
								className="s1"
								style={{
									paddingTop: '8pt',
									paddingBottom: '1pt',
									paddingLeft: '6pt',
									textIndent: '0pt',
									textAlign: 'left',
								}}
							>
								Buyer :{' '}
								{getIn(contractDetails, [
									'contract',
									'buyer',
									'name',
								])}
							</p>
							<p
								style={{
									paddingLeft: '389pt',
									textIndent: '0pt',
									lineHeight: '1pt',
									textAlign: 'left',
								}}
							/>
							<p
								style={{ textIndent: '0pt', textAlign: 'left' }}
							/>
							<p
								className="s1"
								style={{
									paddingTop: '9pt',
									paddingLeft: '6pt',
									textIndent: '0pt',
									textAlign: 'left',
								}}
							>
								Broker :{' '}
								{getIn(contractDetails, [
									'contract',
									'broker',
									'name',
								])}
							</p>
						</div>
						<div
							style={{
								border: '1px solid black',
								padding: '10px 4px 10px',
							}}
						>
							<p style={{ fontSize: '15px' }}>
								<u>Reference No:</u>
								<div
									style={{
										width: 100,
										wordBreak: 'break-all',
									}}
								>
									{getIn(contractDetails, [
										'contract',
										'referenceNo',
									])}
								</div>
							</p>

							<table style={{ width: '100%' }}>
								<tbody>
									<tr>
										<td />
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<p
						style={{
							paddingTop: '10pt',
							paddingLeft: '6pt',
							textIndent: '0pt',
							textAlign: 'left',
							width: '600px',
						}}
					>
						The Sellers have agreed to sell and the Buyers have
						agreed to buy the following on terms and conditions as
						stipulated hereunder:-
					</p>
					<p style={{ textIndent: '0pt', textAlign: 'left' }}>
						<br />
					</p>
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								marginLeft: '6.015pt',
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '32pt' }}>
									<td
										style={{
											width: '252pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
										colSpan={2}
									>
										<p
											className="s5"
											style={{
												paddingTop: '8pt',
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											Contract No :{' '}
											{getIn(contractDetails, [
												'contract',
												'contractNo',
											])}
										</p>
									</td>
									<td
										style={{
											width: '243pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
										colSpan={2}
									>
										<p
											className="s5"
											style={{
												paddingTop: '8pt',
												paddingLeft: '10pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											Date :{' '}
											{`${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('DD MM YYYY')}; ${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('hh:mm A')}`}
										</p>
									</td>
								</tr>
								<tr style={{ height: '32pt' }}>
									<td
										style={{
											width: '495pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
										colSpan={4}
									>
										<p
											className="s5"
											style={{
												paddingTop: '8pt',
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											Description of Oil :{' '}
											{getIn(contractDetails, [
												'contract',
												'product',
												'description',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '44pt' }}>
									<td
										style={{
											width: '117pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											className="s5"
											style={{
												paddingTop: '8pt',
												paddingLeft: '50pt',
												paddingRight: '28pt',
												textIndent: '-12pt',
												textAlign: 'left',
											}}
										>
											Quantity in MT
										</p>
									</td>
									<td
										style={{
											width: '135pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											className="s5"
											style={{
												paddingTop: '9pt',
												paddingLeft: '41pt',
												paddingRight: '2pt',
												textIndent: '-22pt',
												textAlign: 'left',
											}}
										>
											Delivery/Collection Period
										</p>
									</td>
									<td
										style={{
											width: '117pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											className="s5"
											style={{
												paddingTop: '9pt',
												paddingLeft: '21pt',
												textIndent: '24pt',
												textAlign: 'left',
											}}
										>
											Basic Delivery/Collection
										</p>
									</td>
									<td
										style={{
											width: '126pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											className="s5"
											style={{
												paddingTop: '8pt',
												paddingLeft: '51pt',
												paddingRight: '49pt',
												textIndent: '1pt',
												textAlign: 'center',
											}}
										>
											Price PMT
										</p>
									</td>
								</tr>
								<tr style={{ height: '40pt' }}>
									<td
										style={{
											width: '117pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'quantity',
											])}
										</p>
									</td>
									<td
										style={{
											width: '135pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											<br />
											{`${t(
												MONTHS[
													getIn(contractDetails, [
														'contract',
														'deliveryPeriod',
														'month',
													])
												]
											)}, ${t(
												SHIPMENT_PERIOD[
													getIn(contractDetails, [
														'contract',
														'deliveryPeriod',
														'period',
													])
												]
											)}`}
										</p>
									</td>
									<td
										style={{
											width: '117pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											<br />
											{`${t(
												MONTHS[
													getIn(contractDetails, [
														'contract',
														'basicDelivery',
														'month',
													])
												]
											)}, ${t(
												SHIPMENT_PERIOD[
													getIn(contractDetails, [
														'contract',
														'basicDelivery',
														'period',
													])
												]
											)}`}
										</p>
									</td>
									<td
										style={{
											width: '126pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'unitRate',
											])}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p style={{ textIndent: '0pt', textAlign: 'left' }}>
						<br />
					</p>
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								marginLeft: '6.015pt',
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '29pt' }}>
									<td
										style={{
											width: '252pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											className="s5"
											style={{
												paddingTop: '8pt',
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											Product Specifications
										</p>
									</td>
									<td
										style={{
											width: '243pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											className="s5"
											style={{
												paddingTop: '7pt',
												paddingLeft: '79pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											Special Conditions
										</p>
									</td>
								</tr>
								<tr style={{ height: '38pt' }}>
									<td
										style={{
											width: '495pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
										colSpan={2}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'left',
												paddingLeft: '5pt',
												paddingRight: '4pt',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'specialConditions',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '102pt' }}>
									<td
										style={{
											width: '495pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
										colSpan={2}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
										</p>
										<p
											className="s5"
											style={{
												paddingLeft: '5pt',
												paddingRight: '4pt',
												textIndent: '0pt',
												textAlign: 'justify',
											}}
										>
											The specifications shall be those
											established by The Palm Oil Refiners
											Association of Malaysia (PORAM) as
											standard for the sale/purchase of
											oils, and by Malaysian Palm Oil
											Association (MPOA) as standard for
											the sale/purchase of crude palm oil,
											in force at the date of contract and
											always provided they are not in
											contradiction with the above.
										</p>
										<p
											className="s5"
											style={{
												paddingLeft: '5pt',
												textIndent: '0pt',
												lineHeight: '13pt',
												textAlign: 'justify',
											}}
										>
											The FFA content shall be expressed
											as follows :-
										</p>
										<p
											className="s5"
											style={{
												paddingLeft: '5pt',
												paddingRight: '224pt',
												textIndent: '0pt',
												lineHeight: '13pt',
												textAlign: 'justify',
											}}
										>
											If as palmitic acid, calculated on a
											molecular weight of 256; If as oleic
											acid, calculated on a molecular
											weight of 282.
										</p>
									</td>
								</tr>
								<tr style={{ height: '65pt' }}>
									<td
										style={{
											width: '252pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
										</p>
										<p
											className="s5"
											style={{
												paddingLeft: '81pt',
												paddingRight: '81pt',
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											Methods and Terms of
										</p>
										<p
											className="s5"
											style={{
												paddingLeft: '81pt',
												paddingRight: '81pt',
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											Payment
										</p>
									</td>
									<td
										style={{
											width: '243pt',
											borderTopStyle: 'solid',
											borderTopWidth: '1pt',
											borderLeftStyle: 'solid',
											borderLeftWidth: '1pt',
											borderBottomStyle: 'solid',
											borderBottomWidth: '1pt',
											borderRightStyle: 'solid',
											borderRightWidth: '1pt',
										}}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'paymentTerms',
											])}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<br />
					<ol id="l1">
						<li data-list-text={2}>
							<h2
								style={{
									paddingTop: '3pt',
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								QUANTITY
							</h2>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<ol id="l2">
								<li data-list-text="(a)">
									<p
										style={{
											paddingLeft: '50pt',
											textIndent: '-17pt',
											textAlign: 'left',
										}}
									>
										Delivery/Collection by Road Tanker
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
									<p
										style={{
											paddingLeft: isMobile
												? '0px'
												: '51pt',
											textIndent: '0pt',
											textAlign: 'justify',
										}}
									>
										Sellers/Buyers shall deliver/collect the
										contract quantity. In the event that
										there is a further immediate contract
										between Sellers and Buyers, any excess
										or deficiency in the quantity arising
										from the last road tanker load nearest
										to the contract quantity shall be used
										to offset against such subsequent
										contract meant for completion within the
										same contract period.
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
									<p
										style={{
											paddingLeft: isMobile
												? '0px'
												: '51pt',
											textIndent: '0pt',
											textAlign: 'justify',
										}}
									>
										The contract may be deemed to have been
										completed (at the option of the
										Sellers/Buyers in case of
										delivery/collection contracts as
										applicable) by delivery/collection of 5%
										of such last contract or 5 tonnes
										(whichever is lower) more or less from
										the contract quantity. The variation of
										the first 2% from the contract quantity
										shall be settled at the contract price.
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									/>
									<p
										style={{
											paddingLeft: isMobile
												? '0px'
												: '51pt',
											textIndent: '0pt',
											textAlign: 'justify',
										}}
									>
										The variation above such 2% upto and
										including the maximum allowed above,
										shall be settled at the market price.
										Failing amicable settlement by the
										parties, the market price shall be the
										MPOB Peninsular Malaysia price on the
										last date of delivery, and in the event
										such price is not available, the
										previous day’s price shall apply.
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
								</li>
								<li data-list-text="(b)">
									<p
										style={{
											paddingLeft: '51pt',
											textIndent: '-18pt',
											textAlign: 'left',
										}}
									>
										Delivery/Collection by Vessel/Barge
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
									<p
										style={{
											paddingLeft: isMobile
												? '0px'
												: '51pt',
											textIndent: '0pt',
											textAlign: 'justify',
										}}
									>
										Sellers/Buyers shall deliver/collect the
										contract quantity. In the event that
										there is more than 1 contract shipped in
										the same vessel/barge to the same buyer,
										any excess or deficiency shall be
										prorated between the contracts.
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
									<p
										style={{
											paddingLeft: isMobile
												? '0px'
												: '51pt',
											textIndent: '0pt',
											textAlign: 'justify',
										}}
									>
										The contract may be deemed to have been
										completed (at the option of the
										Sellers/Buyers in case of
										delivery/collection contracts as
										applicable) by delivery/collection of 2%
										more or less from the contract quantity.
										Such variation shall be accounted for at
										the contract price.
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
								</li>
							</ol>
						</li>
						<br />
						<li data-list-text={3}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								QUALITY
							</h2>
							<p
								style={{
									paddingTop: '8pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								The oil shall be of good merchantable quality of
								the agreed description at time of collection/
								delivery.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
						</li>
						<br />
						<li data-list-text={4}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								DELIVERY/COLLECTION
							</h2>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								If the basis of contract is delivered Sellers
								shall be responsible for providing suitable road
								tanker/vessel tank(s) for delivery of the oil
								during the contract period. Sellers shall give
								Buyers at least 3 working days notice (for
								delivery by road tankers) or 7 working days
								notice (for delivery by vessels) of the
								intention to effect delivery.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								If the basis of contract is collection, Buyers
								shall be responsible for providing suitable road
								tanker/vessel tank(s) for collection of the oil
								during the contract period. Buyers shall give
								Sellers at least 3 working days notice (for
								collection by road tankers) or 7 working days
								notice (for collection by vessels) of their
								intention to effect collection stating quantity
								to be collected per day and the particulars of
								the fleet of road tankers (intended to be
								employed)/vessels.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								When there is more than one contract for the
								similar oil between the parties for the same
								delivery/collection period, the
								delivery/collection shall follow the dates of
								the respective contracts on a "first in, first
								out" basis.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
						</li>
						<br />
						<li data-list-text={5}>
							<p
								className="s6"
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								EXTENSION OF COLLECTION/DELIVERY
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingTop: '4pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								Collection/delivery must be completed within the
								contract period, failing which it shall
								constitute a default. However, subject to mutual
								agreement in writing at least 5 working days
								prior to expiry of contract period, the time for
								collection/delivery could be extended to a
								period (hereinafter referred to as the extended
								period) not exceeding 10 days.
							</p>
							<p
								style={{
									paddingTop: '3pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'left',
								}}
							>
								The following charges for the quantity carried
								forward each day are to be borne by the party
								requesting the extended period :-
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'left',
								}}
							>
								First 5 days without any charges 8th. day at
								RM3.00 PMT 6th. day at RM1.00 PMT 9th. day at
								RM4.00 PMT
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'left',
								}}
							>
								7th. day at RM2.00 PMT 10th. day at RM5.00 PMT
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								Non-collection/delivery of the oil by the end of
								the extended period shall constitute a default.
								However, subject to mutual agreement in writing,
								the time for collection/delivery could be
								delayed for a further period and in which event,
								completion of the contract may be delayed to the
								extent of the period of delay requested. In this
								instance, non-collection/delivery in the
								contract month shall not constitute default.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{ textIndent: '0pt', textAlign: 'left' }}
							/>
						</li>
						<br />
						<li data-list-text={6}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								WEIGHTS
							</h2>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								If the basis of contract is delivery by road
								tankers, weights as established at Buyers'
								weighbridge shall be final. If delivery is by
								vessels, delivered weight based on ullage drawn
								from caliberated shore tanks into which oil is
								discharged shall be final.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								If basis of contract is collection by road
								tankers, weights as established at Sellers'
								weighbridge shall be final. If collection is by
								vessels, shipped weight based on ullage drawn
								from caliberated shore tanks from which oil is
								loaded shall be final.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								Sellers/Buyers may appoint a representative to
								superintend weighing on their behalf at their
								own cost.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
						</li>
						<br />
						<li data-list-text={7}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								SAMPLING
							</h2>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								Representative samples in quadruplicate shall be
								drawn and sealed by Sellers or Buyers as the
								case may be, in accordance with the procedures
								as specified hereunder. Sellers/Buyers may
								appoint a representative to be present at the
								time samples are being drawn. Two of the sealed
								samples shall be provided to Buyers'
								representative and the other two shall be
								retained by Sellers or vice versa.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								If the basis of contract is delivered,
								representative samples shall be drawn from each
								road tanker at the time and place of delivery.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								If the basis of contract is collection,
								representative samples shall be drawn from
								Seller's storage tank at the time of despatch.
								However, if the oil to be loaded is from more
								than one storage tank then samples should be
								drawn from each lorry tanker.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								In such an event, the Sellers may exercise
								either of the following option but once an
								option is exercised, it shall apply to all
								despatches under the contract.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<ol id="l3">
								<li data-list-text="(a)">
									<p
										style={{
											paddingLeft: isMobile
												? '0px'
												: '33pt',
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										All samples drawn from each despatch
										point shall be analysed separately and a
										weighted average of quality shall then
										be determined, or
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
								</li>
								<li data-list-text="(b)">
									<p
										style={{
											paddingLeft: isMobile
												? '0px'
												: '33pt',
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										All samples drawn from each days'
										quantity from each despatch point shall
										be thoroughly mixed and a composite
										sample shall be drawn and analysed.
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
									<p
										style={{
											paddingLeft: isMobile
												? '0px'
												: '33pt',
											textIndent: '0pt',
											textAlign: 'justify',
										}}
									>
										All inlet and outlet valves of the road
										tankers must be sealed.
									</p>
									<p
										style={{
											textIndent: '0pt',
											textAlign: 'left',
										}}
									>
										<br />
									</p>
									<p
										style={{
											paddingLeft: isMobile
												? '0px'
												: '33pt',
											textIndent: '0pt',
											textAlign: 'justify',
										}}
									>
										If the basis of contract is delivered,
										the analysis as certified by Buyers'
										laboratory based on samples drawn at
										time of delivery shall be final. If the
										basis of contract is collection, the
										analysis as certified by Seller's
										laboratory shall be final. In the event
										of a dispute an independence surveyor
										or/analyst to be appointed and the cost
										of such surveyor/analyst shall be for
										the account of the party at fault. In
										such an event the findings of the
										independent surveyor/analyst shall be
										final.
									</p>
								</li>
							</ol>
						</li>
						<br />
						<li data-list-text={8}>
							<h2
								style={{
									paddingTop: '3pt',
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								INSURANCE
							</h2>
							<p
								style={{
									paddingTop: '4pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								If the basis of contract is delivered by road
								tankers, the Sellers shall be responsible for
								the insurance up to the time the oil is
								discharge from the road tanker. If the basis of
								contract is collection by road tankers, the
								Buyers shall be responsible for the insurance
								from the time the oil is loaded onto the road
								tanker.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
						</li>
						<br />
						<li data-list-text={9}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								DEFAULT
							</h2>
							<p
								style={{ textIndent: '0pt', textAlign: 'left' }}
							/>
							<p
								style={{
									paddingTop: '6pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								In default of fulfilment of this contract by
								either party, the other party at his discretion
								shall, after giving notice in writing or by
								cable, telegram, facsimile or telex, if they do
								not reside in the same town, have the right
								either to cancel the contract or the right to
								sell or purchase, as the case may be, against
								the defaulter who shall on demand make good the
								loss, if any, on such sale or purchase. If the
								party liable to pay shall be dissatisfied with
								the price of such sale or purchase, or if
								neither of the above right is exercised, the
								damages if any, shall, failing amicable
								settlement, be determined by arbitration. The
								damages awarded against the defaulter shall be
								limited to the difference between the contract
								price and the market price on the day of the
								default but if the Arbitrator(s) consider the
								circumstances of the default justify it, they
								may at their absolute discretion, award
								additional damage.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
						</li>
						<br />
						<li data-list-text={10}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								BANKRUPTCY
							</h2>
							<p
								style={{
									paddingTop: '10pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								If before the fulfilment of this contract either
								party shall suspend payment, commit an act of
								bankruptcy, notify any of his creditors that he
								is unable to meet his debts or that he has
								suspended payment or that he is about to suspend
								payment of his debts, convene, call or hold a
								meeting either of his creditors or to pass a
								resolution to go into liquidation (except for a
								voluntary winding up of a solvent company for
								the purpose of reconstruction or amalgamation)
								or shall apply for an official moratorium, have
								a petition presented for winding up or shall
								have a Receiver appointed, he shall be deemed to
								be and shall be treated as being in default and
								the contract shall forthwith be closed, either
								at the market price then current for similar
								goods or, at the option of the other party,
								declarable by the end of the next business day,
								at a price to be ascertained by repurchase or
								resale on such next business day and the
								difference between the contract price and such
								closing-out price shall be the amount which the
								party shall be entitled to claim or shall be
								liable to account for under this contract.
								Should either party be dissatisfied with the
								price, the matter shall be referred to
								arbitration. Where no such resale or repurchase
								takes place, the closing-out price shall be
								determined by a Price Settlement Committee
								appointed jointly by The Palm Oil Refiners
								Association of Malaysia and the Malaysian Palm
								Oil Association.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
						</li>
						<br />
						<li data-list-text={11}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								DOMICILE
							</h2>
							<p
								style={{
									paddingTop: '6pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								This contract shall be deemed to have been made
								in Malaysia and the construction, validity and
								performance thereof shall be governed in all
								respects by Malaysian law.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
						</li>
						<br />
						<li data-list-text={12}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								FORCE MAJEURE
							</h2>
							<p
								style={{
									paddingTop: '6pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								Should delivery/collection by Sellers be
								prevented by reason of fires, strikes, lockouts,
								riots, civil commotion, floods and/or any cause
								comprehended in the term force majeure, the
								contract period shall be extended for a period
								equal to the duration of the disabling
								circumstances but not exceeding a period of 60
								calender days. If the force majeure event ends
								within 21 days preceding the end of the extended
								period, then a further 21 days shall be allowed
								after the termination of the force majeure
								event.
							</p>
							<p
								style={{
									paddingTop: '10pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								Should the fulfilment of the contract not be
								possible within the extended period, the
								contract or any unfulfilled part thereof shall
								be deemed to be null and void at the end of such
								extended period.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
						</li>
						<br />
						<li data-list-text={13}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								NOTICE
							</h2>
							<p
								style={{
									paddingTop: '6pt',
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								Where the terms of the contract require notice
								to be given, such notice shall be despatched by
								cable, telegram, facsimile, telex, email or when
								the parties reside in the same town or when such
								notice is likely to be received within any
								specified time limit the notice may be
								despatched by telex, first class mail or
								delivered by hand. All notices shall be under
								reserve for errors in transmission. Any notice
								received after 1600 hours Malaysian time on a
								business day shall be deemed to have been
								received on the following business day.
							</p>
						</li>
						<br />
						<li data-list-text={14}>
							<h2
								style={{
									paddingTop: '3pt',
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								NON-BUSINESS DAY
							</h2>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								Should the time limit for doing any act or
								giving any notice expire on a Saturday, Sunday
								or any National Gazetted Public Holiday in
								Malaysia, the time so limited shall be extended
								until the first business day thereafter. All
								business days shall be deemed to end 1600 hours
								Malaysian time Monday to Friday inclusive. The
								contract delivery/collection period shall not be
								affected by this clause.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
						</li>
						<br />
						<li data-list-text={15}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								ODD DAYS
							</h2>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{
									paddingLeft: isMobile ? '0px' : '33pt',
									textIndent: '0pt',
									textAlign: 'justify',
								}}
							>
								In any month containing an odd number of days
								the middle day shall be reckoned as belonging to
								both halves of the month.
							</p>
							<p style={{ textIndent: '0pt', textAlign: 'left' }}>
								<br />
							</p>
							<p
								style={{ textIndent: '0pt', textAlign: 'left' }}
							/>
						</li>
						<br />
						<li data-list-text={16}>
							<h2
								style={{
									paddingLeft: '33pt',
									textIndent: '-27pt',
									textAlign: 'left',
								}}
							>
								ARBITRATION
							</h2>
						</li>
					</ol>
					<p style={{ textIndent: '0pt', textAlign: 'left' }}>
						<br />
					</p>
					<p
						style={{
							paddingLeft: isMobile ? '0px' : '33pt',
							textIndent: '0pt',
							textAlign: 'justify',
						}}
					>
						Any dispute arising out of this contract, including any
						question of law arising in connection therewith, shall
						be referred to arbitration in Malaysia (at the PORAM
						Secretariat or elsewhere if so agreed), in accordance
						with the PORAM Rules of Arbitration and Appeal in force
						at the date of the initiation of the arbitration.
					</p>
					<p style={{ textIndent: '0pt', textAlign: 'left' }}>
						<br />
					</p>
					<p
						style={{
							paddingLeft: isMobile ? '0px' : '33pt',
							textIndent: '0pt',
							textAlign: 'justify',
						}}
					>
						Neither party hereto, nor any persons claiming under
						either of them shall bring any action or other legal
						proceedings against the other of them in respect of any
						such dispute until such dispute shall first have been
						heard and determined by the Sole Arbitrator/Panel of
						Arbitrators/Appeal Board (as the case maybe), in
						accordance with the PORAM Rules of Arbitration and
						Appeal and it is hereby expressly agreed and declared
						that the obtaining of an award from the Sole
						Arbitrator/Panel of Arbitrators/Appeal Board(as the case
						may be), shall be a condition precedent to the right of
						either party hereto or of any person claiming under
						either of them to bring any action or other legal
						proceedings against the other of them in respect of such
						dispute.
					</p>
					<p style={{ textIndent: '0pt', textAlign: 'left' }}>
						<br />
					</p>
					<p
						style={{
							paddingTop: '9pt',
							paddingLeft: '6pt',
							textIndent: '0pt',
							textAlign: 'left',
							paddingBottom: '28pt',
						}}
					>
						As
						Sellers:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As
						Brokers:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As
						Buyers:
					</p>
				</div>
				<Box
					style={{
						width: isMobile ? 350 : 670,
						padding: 15,
						marginBottom: 40,
						alignItems: 'center',
						background: theme.color.white,
						justifyContent: 'space-between',
					}}
					row={!isMobile}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '60%',
							alignItems: 'end',
							marginRight: '5%',
							padding: '15px 10px',
							background: theme.color.white,
						}}
					>
						<SmallText
							style={{
								fontSize: 14,
								color: '#8D93A0',
								lineHeight: '19px',
								textAlign: 'justify',
							}}
						>
							{t('contracts.proceedToESignText')}
						</SmallText>
					</Box>
					<Button
						rounded
						label={t('contracts.proceedToESign')}
						reverse
						onClick={() => {
							if (contractAction === 'edit') {
								setShowModal(true)
							} else {
								dispatch(
									MarketPlaceDuc.creators.postContractDetails(
										contractDetails,
										documentType
									)
								)
							}
						}}
						extendStyles={{
							width: 266,
							background: '#3F56C4',
							color: theme.color.white,
						}}
					/>
				</Box>
				<DigitalContractMessage
					width={isMobile ? 350 : 670}
					margin="0 0 40px 0"
				/>
			</Box>
		</>
	)
}

export { PreviewContractNo1 }
