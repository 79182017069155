import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ChatClient } from '@azure/communication-chat'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { getChatEndPoint } from 'ui-marketplace-app/config'
import { useDateByFormat } from 'ui-marketplace-app/utils/date'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MessagesDuc } from 'ui-marketplace-app/modules/Messages/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { ChatModal } from 'ui-lib/components/ChatModal'

const ChatModalComponent = ({
	documentId,
	tradeId,
	sellerName,
	buyerName,
	buyerId,
	sellerId,
	chatBoxData,
	isMobile,
	docType = 'rfq',
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const orgId = useSelector(AuthDuc.selectors.getUserOrgId)
	const threadIDs = useSelector(MessagesDuc.selectors.getChatThreadId)
	const firstDocTypeList = useSelector(MessagesDuc.selectors.getDocTypeList)
	const contractChatIdentifier = useSelector(
		MessagesDuc.selectors.getContractChatIdentifier
	)
	const documentNumberList = useSelector(
		MessagesDuc.selectors.getDocumentNumberList
	)
	const getDocumentChatList = useSelector(
		MessagesDuc.selectors.getDocumentChatList
	)
	const token = useSelector(MessagesDuc.selectors.getChatToken)
	const unReadCountFromApi = useSelector(
		MessagesDuc.selectors.getChatUnreadCount
	)

	const { firstName, orgRole, id: userId, organization } = useSelector(
		AuthDuc.selectors.getUserProfile
	)
	const { currentOrganization } = useSelector(AuthDuc.selectors.auth)

	const loadToken = useCallback(async () => {
		try {
			await dispatch(MessagesDuc.creators.getChatToken(orgRole))
		} catch (err) {
			const { message } = err
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgRole])

	useEffect(() => {
		async function fetchChat() {
			await loadToken()
		}
		fetchChat()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgRole])

	return (
		<>
			{token && (
				<ChatModal
					documentId={documentId}
					sellerName={sellerName}
					buyerName={buyerName}
					buyerId={buyerId}
					sellerId={sellerId}
					tradeId={tradeId}
					docType={docType}
					chatBoxData={data => chatBoxData(data)}
					isMobile={isMobile}
					dispatch={dispatch}
					handleTranslation={data => t(data)}
					orgId={orgId}
					threadIDs={threadIDs}
					firstDocTypeList={firstDocTypeList}
					contractChatIdentifier={contractChatIdentifier}
					documentNumberList={documentNumberList}
					getDocumentChatList={getDocumentChatList}
					token={token}
					unReadCountFromApi={unReadCountFromApi}
					firstName={firstName}
					userId={userId}
					organization={organization}
					currentOrganization={currentOrganization}
					MessagesDuc={MessagesDuc}
					AppDuc={AppDuc}
					ChatClient={ChatClient}
					AzureCommunicationTokenCredential={
						AzureCommunicationTokenCredential
					}
					getChatEndPoint={getChatEndPoint}
					useDateByFormat={useDateByFormat}
				/>
			)}
		</>
	)
}

export { ChatModalComponent }
