/* @flow */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import request from 'ui-lib/utils/request'
import CloseIcon from 'ui-lib/icons/circle-close.svg'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DocumentIcon from 'ui-lib/icons/document.svg'
import CancelIcon from 'ui-lib/icons/close.svg'

import { Input } from 'ui-lib/components/Input'
import { Card } from 'ui-lib/components/Card'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { FieldText } from 'ui-lib/components/FieldText'
import { H2, H3, Text, Label, P } from 'ui-lib/components/Typography'

import { addLast, getIn, removeAt } from 'timm'
import LogHelper from 'ui-lib/utils/logger'
import {
	Button,
	ButtonIconWrapper,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import UploadIcon from 'ui-lib/icons/upload.svg'
import FileUploadIcon from 'ui-lib/icons/uploadFile.svg'

import { getIAMEndPoint } from 'ui-lib/config'

const logger = LogHelper('file-upload')

type Props = {
	type?: string,
	name: string,
	size?: number,
	hideError?: boolean,
	error?: string,
	dropLabel?: string,
	onDocumentChange: (Array<{ url: string, name: string }>) => void,
	onUploadFailure: (Array<{ url: string, name: string }>) => void,
}

const DropHere = styled.div`
	position: absolute,
	width: inherit;
	box-sizing: border-box;
	min-height: 120px;
	border: 1px solid #979797;
	border-radius: 10px; 
`
const Item = styled.div`
	width: fit-content;
	position: static;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 1px 6px;
	color: #4a4a4a;
	background: #ebf8fe;
	border: 1px solid #5d7480;
	border-radius: 10px;
`

const Close = styled.button`
	margin-left: 10px;
	width: inherit;
	border: none;
	border-radius: 50%;
	background-color: rgb(50, 48, 47);
`

// const FILE_UPLOAD_URL = 'http://dibizglobal.org:8000/'

const FileDropWithInput = ({
	type,
	name,
	size,
	hideError,
	error,
	dropLabel,
	onDocumentChange,
	onUploadFailure,
}: Props) => {
	const defaultType = type || 'private'
	const uploadUrl = `${getIAMEndPoint()}files/${defaultType}`
	const maxSize = size
	const [uploadedDocs, setupLoadedDocs] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([])

	const [fileName, setFileName] = useState('')

	const [isLoading, setLoading] = useState()

	const onStatusChange = _isLoading => {
		setLoading(_isLoading)
	}

	useEffect(() => {
		const filename =
			uploadedDocs.length > 0 ? uploadedDocs[0].meta.name : ''
		setFileName(filename)
	}, [uploadedDocs])

	const onDrop = useCallback(
		acceptedFiles => {
			onStatusChange(true)
			const callsToUpload = acceptedFiles.map(async file => {
				const formData = new FormData()
				formData.append('file', file)
				formData.append('name', file.name)
				const options = {
					method: 'POST',
					body: formData,
					formData: true,
					headers: {
						'content-type': null,
					},
				}

				return await request(uploadUrl, options).then(success => {
					const response = getIn(success, ['data'])
					if (!response) throw new Error('Unable to upload the file')

					return response
				})
			})

			Promise.all(callsToUpload)
				.then(files => {
					setupLoadedDocs(files)
				})
				.catch(err => {
					logger.log(err)
					onUploadFailure({ message: err.message })
				})
				.finally(() => onStatusChange(false))
		},
		[onUploadFailure, uploadUrl]
	)

	const onSubmit = () => {
		onDocumentChange(uploadedDocs)
	}

	const removeFile = index => {
		setupLoadedDocs(removeAt(uploadedDocs, index))
	}

	const {
		isDragActive,
		getInputProps,
		getRootProps,
		isDragReject,
		rejectedFiles,
	} = useDropzone({
		onDrop,
		accept: '.jpeg,.png,.pdf,.jpg,.xlsx,.csv',
		minSize: 0,
		maxSize,
		maxFiles: 1,
	})

	const isFileTolarge =
		rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize

	return (
		<Box>
			<DropHere {...getRootProps()}>
				<ul>
					{!!uploadedDocs.length &&
						uploadedDocs.map((file, index) => (
							<Item>
								<IconWrapper
									size={10}
									style={{ marginRight: 10 }}
								>
									<Icon glyph={DocumentIcon} />
								</IconWrapper>
								<li
									href={file.meta.fullURL}
									target="_blank"
									download
								>
									{file.meta.name}
								</li>
								<Close onClick={e => removeFile(index, e)}>
									<IconWrapper
										size={40}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Icon glyph={CloseIcon} />
									</IconWrapper>
								</Close>
							</Item>
						))}
				</ul>
				<input {...getInputProps()} />
				<Box alignItems="center" justifyContent="center">
					<IconWrapper size={80} center>
						<Icon glyph={FileUploadIcon} />
					</IconWrapper>
				</Box>
				<Box alignItems="center" justifyContent="center">
					{!isDragActive && dropLabel}
					{isDragActive && !isDragReject && "Drop it like it's hot!"}
					{isDragReject && 'File type not accepted, sorry!'}
					{isFileTolarge && <div>File is too large.</div>}

					{!hideError && (
						<FieldText error show={!!error}>
							{error}
						</FieldText>
					)}
				</Box>
			</DropHere>
			<Box padding="16px 0px 8px 0px" alignItems="flex-start">
				<H3>Rename the File</H3>
			</Box>
			<Box>
				<Input
					disabled={uploadedDocs.length === 0}
					value={fileName}
					hideLabel
					extendStyles={{
						borderRadius: '10px',
						borderColor: '#3F57C6',
						borderWidth: '1px',
					}}
					onBlur={() => {}}
					onChange={e => {
						const items = Object.assign(uploadedDocs)
						items[0].meta.name = e.target.value
						setupLoadedDocs(items)
					}}
					name="filename"
					box
					disableChooseOne={false}
					placeholder="Type Here..."
				/>
			</Box>

			<Box row justifyContent="space-between">
				<P css={{ color: '#8D93A0' }}>
					Note: Upload .png, .jpg, .pdf, .xlsx, .csv files only
				</P>
				<Button
					disabled={uploadedDocs.length === 0}
					primary
					label="Attach"
					type="button"
					onClick={() => onSubmit()}
				/>
			</Box>
		</Box>
	)
}

FileDropWithInput.defaultProps = {
	dropLabel:
		'Drag and drop your file here or upload on clicking on the document icon.',
	onDocumentChange: () => {},
	onUploadFailure: () => {},
}
export { FileDropWithInput }
