import React, { useCallback } from 'react'
import debounce from 'lodash/debounce'
import baseTheme from './base-theme'

const supportsPassive = () => {
	let isPassive = false
	const opts = Object.defineProperty({}, 'passive', {
		// eslint-disable-next-line
		get: () => {
			isPassive = true
		},
	})
	window.addEventListener('test', null, opts)

	return isPassive
}

export const hrefHandler = href => param => {
	if (typeof href === 'function') href(param)
	else if (typeof href === 'string' && href.length > 0) {
		if (typeof window !== 'undefined') {
			// console.log('Going to redirect', href)
			window.location.href = href
		}
	} else return null
}

/**
 * util for adding event listerners with detach inbuilt.
 * @param {string} event
 * @param {function} cb
 */
export const addEvent = (dom, event, cb) => {
	// if there is no window available, then mock it up
	if (!dom || !dom.addEventListener) {
		return () => {}
	}

	dom.addEventListener(
		event,
		cb,
		supportsPassive() ? { passive: true, capture: true } : true
	)

	return () => dom.removeEventListener(event, cb)
}

/**
 * Simple delay creator
 */
export const sleep = delay =>
	new Promise(resolve => {
		setTimeout(resolve, delay)
	})

/**
 * truncation of the sentence based on the number given
 */

export const handleCustomEllipsis = (text, charLimit) => {
	if (text.length > charLimit) {
		const truncatedText = `${text.substring(0, charLimit)}...`

		return truncatedText
	}

	return text
}

const slugifyRegex = /[^a-z0-9A-Z\- ]/g
const slugifyWithAllowedUrl = /[^a-z0-9A-Z\-._~:/?#\[\]@!$&'()*+,;= ]/g
const spaceRegex = /[ ]/g
/**
	@function slugify
		@param {string} str String to convert
		@param {string} retainUrlFormat Whether slashes should be retained
		@returns {string}
	@description Returns a slug of the input string
*/
export function slugify(str = '', retainUrlFormat = false) {
	if (typeof str !== 'string') return str

	return str
		.trim()
		.replace(retainUrlFormat ? slugifyWithAllowedUrl : slugifyRegex, '')
		.replace(spaceRegex, '-')
		.toLowerCase()
}

/**
 * Check if element is visible in the dom
 *
 * @param {*} ele
 * @returns
 */
export function isVisible(ele) {
	if (!ele || !ele.clientHeight) return false

	return (
		ele.clientWidth !== 0 &&
		ele.clientHeight !== 0 &&
		ele.style.opacity !== 0
	)
}

/** breaks array in smaller groups */
export function chunkArrayInGroups(arr, size) {
	const newArr = []

	for (let i = 0; i < arr.length; i += size) {
		newArr.push(arr.slice(i, i + size))
	}

	return newArr
}

export const simpleHashCode = str => {
	let hash = 0
	let i
	let chr

	if (str.length === 0) return hash
	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i)
		hash = (hash << 5) - hash + chr // eslint-disable-line no-bitwise
		// Convert to 32bit integer
		hash |= 0 // eslint-disable-line no-bitwise
	}

	return hash
}

export function useDebouncedCallback(callback, delay = 0, options = {}) {
	return useCallback(debounce(callback, delay, options), [
		callback,
		delay,
		options,
	])
}
export const applyMediaQueries = (media = ['mobile'], styles = {}) =>
	media.reduce(
		(agg, _media) => ({
			...agg,
			[`@media ${baseTheme.mediaQueries[_media.trim()] ||
				'void'}`]: styles,
		}),
		{}
	)

export const applyMediaQueriesStr = (media = ['mobile'], styles = '') =>
	media.reduce(
		(agg, _media) => `${agg}
			@media ${baseTheme.mediaQueries[_media.trim()] || 'void'} {
				${styles}
			}`,
		''
	)

export const isDateValid = date => Number.isFinite(new Date(date).getTime())

export const setIn = (obj, keys, value) => {
	const lastIdx = keys.length - 1
	let o = obj
	for (let i = 0; i < keys.length; i++) {
		const k = keys[i]
		if (o[k] === undefined) {
			o[k] = {}
		}
		if (lastIdx === i) {
			o[k] = value
			break
		}
		o = o[k]
	}

	return obj
}

export const getDotColorStatus = detail => {
	const {
		certType,
		supplyBase,
		supplyChainType,
		certificatesSelected,
		supplyChainSelected,
	} = detail

	let certificateColorStatus = 'bad'
	let count = 0
	let supplyBaseSupplyChain = []

	const getSupplyBaseChain = certItem => {
		if (certItem.state && certItem.state.toLowerCase() === 'active') {
			count += 1
			const certItemSupplyChain = certItem.supplyChainModel
				? certItem.supplyChainModel
				: []
			if (supplyBase) {
				supplyBaseSupplyChain = [
					...supplyBaseSupplyChain,
					...certItemSupplyChain,
				]
			}
		}
	}

	let currentCertificatesSelected = 'ANY'
	if (certificatesSelected && certificatesSelected.length > 0) {
		currentCertificatesSelected = certificatesSelected
	}

	if (certType) {
		if (currentCertificatesSelected.includes('ANY')) {
			certType.forEach(certItem => {
				getSupplyBaseChain(certItem)
			})
		} else {
			currentCertificatesSelected.forEach(selected => {
				const certItem = certType.find(item => item.ctype === selected)
				if (certItem) {
					getSupplyBaseChain(certItem)
				}
			})
		}
	}
	if (count > 0 || currentCertificatesSelected.includes('ANY'))
		certificateColorStatus = 'good'

	let supplyChainColorStatus = 'bad'
	if (
		supplyChainSelected &&
		supplyChainSelected !== '' &&
		supplyChainSelected !== 'any'
	) {
		if (supplyBase) {
			if (
				supplyBaseSupplyChain.length > 0 &&
				supplyBaseSupplyChain.includes(supplyChainSelected)
			) {
				supplyChainColorStatus = 'good'
			}
		} else if (
			supplyChainType &&
			supplyChainType.length > 0 &&
			supplyChainType.includes(supplyChainSelected)
		) {
			supplyChainColorStatus = 'good'
		}
	} else {
		supplyChainColorStatus = 'good'
	}

	let dotColorStatus = 'bad'
	if (
		certificateColorStatus === 'good' &&
		supplyChainColorStatus === 'good'
	) {
		dotColorStatus = 'good'
	}

	return dotColorStatus
}

export const orgTypes = {
	'palmoil-plantation': 'Palmoil Plantation',
	'palmoil-smallholder': 'Palmoil Smallholder',
	'palmoil-ffbDealer': 'Palmoil Dealer',
	'palmoil-mill': 'Palmoil Mill',
	'palmoil-kernelMill': 'Palmoil Kernel Mill',
	'palmoil-refinery': 'Palmoil Refinery',
	'palmoil-oleochemicals': 'Palmoil Oleochemicals',
	'palmoil-trader': 'Palmoil Trader',
	'palmoil-endManufacturer': 'Palmoil End Manufacturer',
	'palmoil-cooperative-society': 'Palmoil Co-operative Society',
}

export const orgTypesMapped = t => ({
	'palmoil-smallholder': t('invitePartner.smallholder'),
	'palmoil-plantation': t('invitePartner.plantation'),
	'palmoil-ffbDealer': t('invitePartner.ffb-dealer'),
	'palmoil-ffbSubDealer': t('invitePartner.subDealer'),
	'palmoil-collectionPoint': t('dealerGeneralReports.collectionPt'),
	'palmoil-mill': t('invitePartner.palm-oil-mill'),
	'palmoil-kernelMill': t('invitePartner.palm-kernel-oil-mill'),
	'palmoil-refinery': t('invitePartner.palm-oil-refinery'),
	'palmoil-oleochemicals': t('invitePartner.oleochemicals'),
	'palmoil-storage': t('invitePartner.storage-company'),
	'palmoil-trader': t('invitePartner.trader'),
	'palmoil-endManufacturer': t('invitePartner.end-manufacturer'),
	'palmoil-cooperative-society': t('invitePartner.cooperative-society'),
	'': t('invitePartner.unregisteredOrganisations'),
})

const getSummaryData = (track, data) => {
	if (track.length > 0) {
		track.forEach(obj => {
			const index = data.findIndex(
				item => item.orgTypeID === obj.orgTypeID
			)
			const childrenIndex = data[index]?.children.findIndex(
				item => item.id === obj.id
			)
			if (childrenIndex === -1) {
				data[index].children.push({
					...obj,
					...{ children: [], childIndex: 1 },
				})
			}

			const { children } = obj

			const hasChildren = children && !!children.length
			if (hasChildren) {
				children.forEach(child => {
					getSummaryData([child], data)
				})
			}
		})
	}

	return data
}

export const getSummaryTree = (track, t) => {
	const data = []
	Object.keys(orgTypesMapped(t)).forEach(item => {
		data.push({
			shape: {},
			path: item,
			errorMsg: '',
			certType: [],
			children: [],
			childIndex: 0,
			status: 'good',
			transaction: [],
			transformed: [],
			orgTypeID: item,
			transforming: [],
			supplyBase: false,
			supplyChainType: [],
			certificateTypes: [],
			supplyChainTypes: [],
			title: orgTypesMapped(t)[item],
			deforestationData: [],
			noDotColorStatus: true,
			orgType: orgTypesMapped(t)[item],
			blockChainStatus: 'verified',
		})
	})

	const summaryData = getSummaryData(track, data)
	let filteredSummaryData = []
	if (summaryData.length > 0) {
		filteredSummaryData = summaryData.filter(
			item => item.children.length !== 0
		)
	}

	return filteredSummaryData
}

export const getRandomNumber = () => {
	const crypto = window.crypto || window.msCrypto
	const array = new Uint32Array(1)
	crypto.getRandomValues(array) // Compliant for security-sensitive use cases
	const number = array[0]

	return number
}

export const getTitle = (
	currentTitle,
	currentUnknownPercentage,
	virtualTraceData
) => {
	let titleText = currentTitle
	if (currentUnknownPercentage) {
		titleText += ` (${currentUnknownPercentage}%)`
	}

	return (
		<div>
			<div>{titleText}</div>
			{virtualTraceData && currentTitle !== 'Unknown Organization' && (
				<div>(Unregistered Organisation)</div>
			)}
		</div>
	)
}

export const isMarketplaceHost = () => {
	return window.location.host.includes('marketplace')
}

export const isMarketplaceOrg = orgdetails => {
	return orgdetails?.meta?.platform === 'marketplace'
}

export const getOrganisationTypes = () => {
	if (isMarketplaceHost()) {
		return [
			{
				name: 'Palmoil FFB Dealer',
				id: 'marketplace-palmoil-ffbDealer',
			},
			{
				name: 'Palmoil SubDealer',
				id: 'marketplace-palmoil-ffbSubDealer',
			},
			{
				name: 'Palmoil Plantation',
				id: 'marketplace-palmoil-plantation',
			},
			{
				name: 'Palmoil Mill',
				id: 'marketplace-palmoil-mill',
			},
			{
				name: 'Palm Kernel Mill',
				id: 'marketplace-palmoil-kernelMill',
			},
			{
				name: 'Palmoil Refinery',
				id: 'marketplace-palmoil-refinery',
			},
			{
				name: 'Palmoil Trader',
				id: 'marketplace-palmoil-trader',
			},
			{
				name: 'Palmoil Storage',
				id: 'marketplace-palmoil-storage',
			},
			{
				name: 'Palmoil End Manufacturer',
				id: 'marketplace-palmoil-endManufacturer',
			},
			{
				name: 'Palmoil Oleo Chemicals',
				id: 'marketplace-palmoil-oleochemicals',
			},
			{
				name: 'Palmoil Co-operative Society',
				id: 'marketplace-palmoil-cooperative-society',
			},
		]
	}

	return [
		{
			name: 'Palmoil FFB Dealer',
			id: 'palmoil-ffbDealer',
		},
		{
			name: 'Palmoil SubDealer',
			id: 'palmoil-ffbSubDealer',
		},
		{
			name: 'Palmoil Plantation',
			id: 'palmoil-plantation',
		},
		{
			name: 'Palmoil Mill',
			id: 'palmoil-mill',
		},
		{
			name: 'Palm Kernel Mill',
			id: 'palmoil-kernelMill',
		},
		{
			name: 'Palmoil Refinery',
			id: 'palmoil-refinery',
		},
		{
			name: 'Palmoil Trader',
			id: 'palmoil-trader',
		},
		{
			name: 'Palmoil Storage',
			id: 'palmoil-storage',
		},
		{
			name: 'Palmoil End Manufacturer',
			id: 'palmoil-endManufacturer',
		},
		{
			name: 'Palmoil Oleo Chemicals',
			id: 'palmoil-oleochemicals',
		},
		{
			name: 'Palmoil Co-operative Society',
			id: 'palmoil-cooperative-society',
		},
	]
}

export const getPathInitial = type => {
	if (
		type === 'PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP' ||
		type === 'PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP_V2'
	) {
		return '/generate-trace/group'
	}
	const currentPath = type.split('$')[0]
	switch (currentPath) {
		case 'AUTH': {
			return '/auth'
		}
		case 'ONBOARD': {
			return '/onboarding'
		}
		case 'DASHBOARD': {
			return '/'
		}
		case 'PRODUCT_TRACE': {
			return '/product-trace'
		}
		case 'MESSAGES': {
			return '/messages'
		}
		case 'WEIGHBRIDGE': {
			return '/weighbridge-slip'
		}
		case 'PLANTATION': {
			return '/plantation'
		}
		case 'MILL': {
			return '/mill'
		}
		case 'REFINERY': {
			return '/refinery'
		}
		case 'ADMIN': {
			return '/admin'
		}
		case 'PARTNER': {
			return '/partner'
		}
		case 'MARKETPLACE': {
			return '/marketplace'
		}
		case 'SETTINGS': {
			return '/settings'
		}
		case 'FEEDBACK': {
			return '/feedback'
		}
		case 'STORAGE_COMPANY': {
			return '/storage-company'
		}
		case 'TRADER': {
			return '/trader'
		}
		case 'END_MANUFACTURER': {
			return '/end-manufacturer'
		}
		case 'DEALER': {
			return '/dealer'
		}
		case 'TRIPS': {
			return '/trips'
		}
		case 'WBTICKET': {
			return '/wb-tickets'
		}
		case 'GENERAL_SCHEDULER': {
			return '/general-scheduler'
		}
		case 'WEBTOUR': {
			return '/webTour'
		}
		case 'PRODUCTS': {
			return '/products'
		}
		case 'TRADE_DOCUMENT_MANAGER': {
			return '/trade-document-manager'
		}

		default:
			return ''
	}
}

export const isIframeForTeamsApp = () => {
	return window.self !== window.top
}

export function isEmptyObject(obj) {
	for (const key in obj)
		if (Object.prototype.hasOwnProperty.call(obj, key)) return false

	return true
}
