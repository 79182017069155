/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { CookieDuc } from 'ui-marketplace-app/modules/App/cookieDuc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import {
	getSwitchProfileCreds,
	getSwitchProfileOrgsIds,
} from 'ui-marketplace-app/config'
import { DIbizTourActorBased } from 'ui-marketplace-app/modules/WebTour/config'
import { tourModuleActorBased } from 'ui-marketplace-app/modules/WebTour/TourSteps'
import { getPathBasedOnAction } from 'ui-marketplace-app/routes/options'
import { HeaderComponent } from 'ui-lib/components/HeaderComponent'
import {
	navigationBaseReference,
	featureAccessBasedOnType,
} from 'ui-marketplace-app/routes/base'
import { useDateByFormat, getDateByFormat } from 'ui-marketplace-app/utils/date'
import { WebTour } from 'ui-marketplace-app/modules/WebTour'
import { WebTourDuc } from 'ui-marketplace-app/modules/WebTour/duc'

const handleRouteChange = (dispatch, type, payload) => {
	dispatch(MainRouteDuc.creators.switchPage(type, payload, {}, {}, true))
}

const useOutsideClick = (ref, callback) => {
	const handleClick = e => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback()
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClick)

		return () => {
			document.removeEventListener('click', handleClick)
		}
	})
}

const Header = ({ isLoading, isOnline, lastOnline }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const ref = useRef()
	const notifiyRef = useRef()
	const { fullName, orgRole } = useSelector(AuthDuc.selectors.getUserProfile)
	const currentOrg = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const outgoingDOList = useSelector(AppDuc.selectors.getOutgoingDOList)
	const orgId = useSelector(AuthDuc.selectors.getUserOrgId)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)

	const [showSwitchProfile, setShowSwitchProfile] = useState(false)
	const [showSwitchActor, setShowSwitchActor] = useState(false)
	const [previousWeekFirstDate, setPreviousWeekFirstDate] = useState(null)
	const [previousWeekLastDate, setPreviousWeekLastDate] = useState(null)
	const [
		showreminderForDOUploadModal,
		setShowreminderForDOUploadModal,
	] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [showCPModal, setShowCPModal] = useState(false)
	const [selectedCPOrg, setSelectedCPOrg] = useState(selectedCPID)
	const isTourOpen = useSelector(WebTourDuc.selectors.isTourOpen)
	const tourPopupOpen = useSelector(WebTourDuc.selectors.tourPopupOpen)
	const tourStatus = useSelector(WebTourDuc.selectors.tourStatus)
	const [showPopUpModal, setShowPopUpModal] = useState(false)
	const [lastSelectedNode, setLastSelectedNode] = useState({
		path: '',
		status: true,
	})

	useEffect(() => {
		if (showPopUpModal === false) {
			setShowPopUpModal(tourPopupOpen)
		}

		if (showPopUpModal === true || tourStatus === true) {
			dispatch(WebTourDuc.creators.setTourPopupOpen(false))
		}
		if (isTourOpen === true && tourStatus === true) {
			setShowPopUpModal(true)
		}
	}, [isTourOpen, tourPopupOpen, tourStatus, dispatch, showPopUpModal])

	const actorArray = useSelector(AuthDuc.selectors.getActor)
	const actor = actorArray[0] || []

	const orgName = getIn(currentOrg, ['name'])

	const userRole = getIn(tourModuleActorBased(t), [actor]) || []
	const tourVisitStatus = userRole.map(obj => obj.visitStatus)

	const _tourRole = getIn(DIbizTourActorBased, [actor]) || []
	const tourRoleInfo = _tourRole.map(
		({ index, name, label, iconName, route }) => {
			return { index, name, label, iconName, route }
		}
	)

	const { allowed = [], denied = [] } = useSelector(
		AuthDuc.selectors.getCurrentUserRoles
	)
	const { isMobile, isDesktop, isTablet } = useSelector(
		AppDuc.selectors.detection
	)
	const { pathname } = useSelector(AuthDuc.selectors.location)

	const [notificationFetched, updateNotificationFetched] = useState(false)

	const notifications = useSelector(AuthDuc.selectors.getNotifications) || {}

	const totalNotifications = notifications.total || 0
	const virtualAccess = useSelector(AuthDuc.selectors.getVirtualAccess)

	const signInComponents = useSelector(AuthDuc.selectors.getModalStatusRoute)

	const lastSeenDate = useDateByFormat(lastOnline, 'd LLL yyyy hh:mm a')

	const [background, setbackground] = useState('')
	const [switchActorList, setSwitchActorList] = useState([])
	const [switchProfileList, setSwitchProfileList] = useState([])
	const [notificationsHeight, setNotificationsHeight] = useState('0px')

	const [profileMenuHeight, setProfileMenuHeight] = useState('0px')
	useOutsideClick(ref, () => {
		if (profileMenuHeight) setProfileMenuHeight('0px')
	})

	useOutsideClick(notifiyRef, () => {
		if (notificationsHeight) {
			setNotificationsHeight('0px')
			setbackground('')
		}
	})

	const getWebtour = () => {
		return <WebTour />
	}

	useEffect(() => {
		const switchOrgIDs = getSwitchProfileOrgsIds()
		const switchOrgCreds = getSwitchProfileCreds()

		if (switchOrgIDs && orgId) {
			const obj = Object.values(switchOrgIDs).filter(arr =>
				(arr || []).includes(orgId)
			)
			if (obj.length > 0) {
				const { userAccounts = [] } = switchOrgCreds[orgId] || {}
				// eslint-disable-next-line no-unused-expressions
				userAccounts.length > 0
					? setSwitchActorList(userAccounts)
					: setSwitchActorList([])

				const ownerAccounts = Object.keys(switchOrgCreds)
					.filter(key => obj[0].includes(key))
					.map(cur => {
						const {
							name,
							password,
							type,
							userName,
						} = switchOrgCreds[cur]

						return {
							name,
							password,
							type,
							userName,
						}
					}, {})
				// eslint-disable-next-line no-unused-expressions
				ownerAccounts.length > 0
					? setSwitchProfileList(ownerAccounts)
					: setSwitchProfileList([])
			}
		}
	}, [orgId])

	useEffect(() => {
		if (notificationFetched) {
			return
		}
		updateNotificationFetched(true)

		dispatch(AuthDuc.creators.fetchNotifications())
		window.setInterval(() => {
			dispatch(AuthDuc.creators.fetchNotifications())
		}, 180000)
		window.setInterval(() => {
			dispatch(AppDuc.creators.isUpdateAvailable())
		}, 1800000)
	}, [notificationFetched, dispatch])
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	useEffect(() => {
		if (tourModule === 'dashboard') {
			dispatch(WebTourDuc.creators.setDashboardVisit(true))
		}
	}, [tourModule, dispatch])

	const handleTourComponentUpdate = (tourModuleItem, iconName) => {
		if (iconName === 'none')
			dispatch(WebTourDuc.creators.setCreateTourOpenStatus(true))

		setShowPopUpModal(false)
		dispatch(WebTourDuc.creators.setActiveTourModule(tourModuleItem))
		if (isTourOpen === true) {
			dispatch(WebTourDuc.creators.setTourOpenStatus(false))
		}
		dispatch(WebTourDuc.creators.setTourStatus(true))
	}

	const handleNotificationClick = notification => {
		const { meta, id, originOrgID } = notification
		const { component, componentRefID } = meta
		const entity = component.split('/')[1]

		if (component.includes('rfq')) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE$ACTION,
					{
						rootModule:
							currentOrg.id === originOrgID ? 'buyer' : 'seller',
						action: 'view-rfq',
						documentReference: componentRefID,
					}
				)
			)
		} else if (component.includes('quote')) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE$ACTION,
					{
						rootModule:
							currentOrg.id === originOrgID ? 'seller' : 'buyer',
						action: 'view-quote',
						documentReference: componentRefID,
					}
				)
			)
		} else if (component.includes('organizations/partners')) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PARTNER$WREQREFERENCE,
					{
						action: 'view',
						partnerRef: componentRefID,
					},
					{
						docType: 'notifications',
					},
					{},
					true
				)
			)
		} else {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
					{
						rootModule: entity,
						documentReference: componentRefID,
					},
					{},
					{},
					true
				)
			)
		}
		setNotificationsHeight('0px')
		dispatch(AuthDuc.creators.singleNotificationUpdateStatus(id, 'seen'))
	}

	const switchProfileLogin = val => {
		const { userName, password } = val || {}
		const json = { email: userName, password }
		setShowSwitchProfile(false)
		setShowSwitchActor(false)
		dispatch(
			AuthDuc.creators.loginUser(json, {
				setSubmitting: () => {},
			})
		)
	}

	document.title =
		totalNotifications > 0
			? `${t('dibiz')} (${totalNotifications})`
			: t('dibiz')

	const UseDateByFormatComponent = (date, format) => {
		const activeLocale = useSelector(AppDuc.selectors.activeLocale)

		return useMemo(() => getDateByFormat(date, format, activeLocale), [
			date,
			format,
			activeLocale,
		])
	}

	const handleDispatch = (
		type,
		route = {},
		status = false,
		selectedOrg = '',
		payload = {}
	) => {
		switch (type) {
			case 'DOUploadReminder': {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$WACTION,
						{
							rootModule: 'upload-delivery-order',
							action: 'attachDetails',
						},
						{},
						{},
						true
					)
				)

				break
			}
			case 'routing': {
				dispatch(route)

				break
			}
			case 'GUIDED_TOUR_SKIPPED': {
				dispatch(
					CookieDuc.creators.setCookie({
						cookieName: 'GUIDED_TOUR_SKIPPED',
						cookieValue: true,
						storage: 'C',
					})
				)

				break
			}
			case 'SELECTED_CP_ID': {
				dispatch(
					CookieDuc.creators.setCookie({
						cookieName: 'SELECTED_CP_ID',
						cookieValue: selectedOrg,
						storage: 'C',
					})
				)

				break
			}
			case 'SELECTED_CP_ID_DELETE': {
				dispatch(
					CookieDuc.creators.deleteCookie({
						cookieName: 'SELECTED_CP_ID',
					})
				)

				break
			}
			case 'switchPageDashboard': {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.DASHBOARD
					)
				)
				break
			}
			case 'setTourStatus': {
				dispatch(WebTourDuc.creators.setTourStatus(status))
				break
			}
			case 'setTourOpenStatus': {
				dispatch(WebTourDuc.creators.setTourOpenStatus(status))
				break
			}
			case 'setTourPopupOpen': {
				dispatch(WebTourDuc.creators.setTourPopupOpen(status))
				break
			}
			case 'setFirstTimeSignInModalStatus': {
				dispatch(AuthDuc.creators.setFirstTimeSignInModalStatus(status))
				break
			}
			case 'setFirstTimeSignInModalStatusLogin': {
				dispatch(
					AuthDuc.creators.setFirstTimeSignInModalStatusLogin(status)
				)
				break
			}
			case 'signOutUser': {
				dispatch(AuthDuc.creators.signOutUser())
				break
			}
			case 'settings': {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.SETTINGS,
						{},
						{},
						{},
						true
					)
				)
				break
			}
			case 'handleRouteChange': {
				dispatch(
					MainRouteDuc.creators.switchPage(
						route,
						payload,
						{},
						{},
						true
					)
				)

				break
			}
			case 'notificationSettings': {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.SETTINGS$SUBROOT,
						{
							rootModule: 'notifications',
						},
						{},
						{},
						true
					)
				)

				break
			}

			default:
				break
		}
	}

	useEffect(() => {
		if (
			allowed.length > 0 &&
			allowed.includes('fe.sidebar.trade-document-manager.r')
		) {
			dispatch(AppDuc.creators.DOList())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowed])

	useEffect(() => {
		if (
			allowed.length > 0 &&
			allowed.includes('fe.sidebar.trade-document-manager.r')
		) {
			const showPopupCookie = 'showreminderForDOUploadPopup=true'
			const shouldShowPopup =
				document.cookie.indexOf(showPopupCookie) === -1

			const today = new Date()
			const lastSunday = new Date(
				today.setDate(today.getDate() - today.getDay())
			)
			const CurrPreviousWeekLastDate = new Date(lastSunday)
			const currPreviousWeekFirstDate = new Date(
				lastSunday.setDate(lastSunday.getDate() - 6)
			)

			setPreviousWeekFirstDate(currPreviousWeekFirstDate)
			setPreviousWeekLastDate(CurrPreviousWeekLastDate)

			const dateCheck =
				getIn(outgoingDOList, ['list'])?.length > 0 &&
				!getIn(outgoingDOList, ['list']).find(
					item =>
						new Date(item.createdAt) >= currPreviousWeekFirstDate
				)

			if (!tourStatus && shouldShowPopup && dateCheck) {
				setShowreminderForDOUploadModal(true)
				// Set cookie for max-age of 86400 seconds (24hrs)
				const expires = new Date(
					Date.now() + 86400 * 1000
				).toUTCString()
				document.cookie = `${showPopupCookie}; expires=${expires}; path=/;`
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tourStatus, allowed, outgoingDOList])

	return (
		<>
			<div>
				<HeaderComponent
					virtualAccess={virtualAccess}
					switchProfileList={switchProfileList}
					switchActorList={switchActorList}
					showSwitchProfile={showSwitchProfile}
					showSwitchActor={showSwitchActor}
					showPopUpModal={showPopUpModal}
					showModal={showModal}
					showCPModal={showCPModal}
					selectedCPOrg={selectedCPOrg}
					profileMenuHeight={profileMenuHeight}
					notificationsHeight={notificationsHeight}
					background={background}
					isLoading={isLoading}
					currentOrg={currentOrg}
					tourRoleInfo={tourRoleInfo}
					tourVisitStatus={tourVisitStatus}
					navigationBaseReference={navigationBaseReference}
					pathname={pathname}
					allowed={allowed}
					denied={denied}
					fullName={fullName}
					orgRole={orgRole}
					orgName={orgName}
					selectedCPID={selectedCPID}
					actor={actor}
					lastSelectedNode={lastSelectedNode}
					tourModule={tourModule}
					notifications={notifications}
					isOnline={isOnline}
					lastSeenDate={lastSeenDate}
					previousWeekFirstDate={previousWeekFirstDate}
					previousWeekLastDate={previousWeekLastDate}
					featureAccessBasedOnType={featureAccessBasedOnType}
					showreminderForDOUploadModal={showreminderForDOUploadModal}
					isMobile={isMobile}
					isDesktop={isDesktop}
					isTablet={isTablet}
					signInComponents={signInComponents}
					useFormik={useFormik}
					getWebtour={getWebtour}
					setNotificationsHeight={data =>
						setNotificationsHeight(data)
					}
					setLastSelectedNode={data => setLastSelectedNode(data)}
					getDateByFormat={data => getDateByFormat(data)}
					handleUseDateByFormat={(date, format) =>
						UseDateByFormatComponent(date, format)
					}
					setbackground={data => setbackground(data)}
					setProfileMenuHeight={data => setProfileMenuHeight(data)}
					setShowSwitchActor={data => setShowSwitchActor(data)}
					setShowSwitchProfile={data => setShowSwitchProfile(data)}
					switchProfileLogin={data => switchProfileLogin(data)}
					setShowPopUpModal={data => setShowPopUpModal(data)}
					setSelectedCPOrg={data => setSelectedCPOrg(data)}
					setShowModal={data => setShowModal(data)}
					setShowCPModal={data => setShowCPModal(data)}
					setShowreminderForDOUploadModal={data =>
						setShowreminderForDOUploadModal(data)
					}
					handleTourComponentUpdate={(name, iconName) =>
						handleTourComponentUpdate(name, iconName)
					}
					handleNotificationClick={notification =>
						handleNotificationClick(notification)
					}
					getPathBasedOnAction={(type, payload, query) =>
						getPathBasedOnAction(type, payload, query)
					}
					ref={ref}
					notifiyRef={notifiyRef}
					handleDispatch={(
						type,
						route,
						status,
						selectedOrg,
						payload
					) =>
						handleDispatch(
							type,
							route,
							status,
							selectedOrg,
							payload
						)
					}
				/>
			</div>
		</>
	)
}

export { Header }
