import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'timm'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { KeyValueSegment } from 'ui-marketplace-app/modules/Admin/components/KeyValueSegment'
import {
	PACKAGING_TYPE,
	PROFILE_TYPE,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { Card } from 'ui-lib/components/Card'
import { Button } from 'ui-lib/components/Button'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { H3, P } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Box } from 'ui-lib/utils/Box'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import SupplyAbilityIcon from 'ui-lib/icons/supplyability.svg'
import Documents from 'ui-lib/icons/documents.svg'
import theme from 'ui-lib/utils/base-theme'

const ProductDetails = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { rootModule } = payload
	const productDetails = useSelector(
		MarketPlaceDuc.selectors.getProductDetails
	)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const product = getIn(productDetails, ['product']) || {}
	const meta = getIn(productDetails, ['meta']) || {}

	const { name, hsnsac, id } = product

	const {
		grade,
		specifications,
		port,
		approxLeadTime,
		colour,
		packagingType,
		description,
		productImage,
		tradeAccess,
		otherCertifications,
		lcv,
	} = meta

	const na = t('marketPlaceProducts.notAvailable')

	const h3Styles = {
		padding: '4px 12px',
		fontSize: theme.fontSize.m,
		color: theme.color.accent2,
	}

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('marketPlaceProducts.marketPlace'),
			name: 'marketplace',
			isActive: true,
		},
		{
			label: t('marketPlaceProducts.viewProduct'),
			name: 'view-product',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'marketplace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule: 'seller',
					}
				)
			)
		}
	}
	const getLogo = productImg => {
		return (
			<Box margin={16}>
				<Card
					style={{
						padding: '8px',
						position: 'relative',
						overflow: 'visible',
						boxShadow: ' 0px 6px 16px #00000014',
						width: '200px',
						height: '188px',
					}}
					textAlign="left"
				>
					<Box
						style={{
							backgroundColor: '#E4E8F0',
							borderRadius: '4px',
							width: '180px',
							height: '170px',
						}}
						justifyContent="center"
						alignItems="center"
					>
						{getIn(productImg, ['meta', 'fullURL']) ? (
							<img
								src={
									getIn(productImg, ['meta', 'fullURL']) || ''
								}
								alt="logo"
								style={{
									width: '180px',
									height: '170px',
								}}
							/>
						) : (
							t('marketPlaceProducts.noLogo')
						)}
					</Box>
				</Card>
			</Box>
		)
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Title
				title={t('viewProduct.title')}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE,
							{ rootModule }
						)
					)
				}}
			/>
			<Box row justifyContent="flex-end">
				<Button
					label={t('common.edit')}
					rounded
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$ACTION,
								{
									rootModule,
									action: 'edit-product',
									documentReference: id,
								}
							)
						)
					}
					extendStyles={{ width: 150 }}
				/>
			</Box>
			<Card height="auto" textAlign="left" style={{ margin: '32px 0' }}>
				<Box
					padding={8}
					style={{
						backgroundColor: '#EBF4FDDE',
						borderBottom: '0.2px solid #ACB7CD',
					}}
				>
					<H3
						bold
						color="#233EC0"
						style={{
							position: 'relative',
							right: '-5px',
						}}
					>
						{t('viewProduct.cardHeading')}
					</H3>
				</Box>
				<Box row={!isMobile} justifyContent="space-between">
					{isMobile && getLogo(productImage)}
					<Box width={isMobile ? '98%' : '70%'}>
						<Box style={{ padding: '8px 22px 30px 2px' }}>
							<KeyValueSegment
								label={t('products.productName')}
								value={name || na}
								labelWidth={isMobile ? '50%' : '25%'}
							/>
							<KeyValueSegment
								label={t('products.hsn')}
								value={hsnsac || na}
								labelWidth={isMobile ? '50%' : '25%'}
							/>
							<KeyValueSegment
								label={t('marketPlaceProducts.carbonValue')}
								value={lcv || na}
								labelWidth={isMobile ? '50%' : '25%'}
							/>
							<H3 style={{ ...h3Styles, paddingTop: 12 }}>
								{t('products.productDescription')}
							</H3>
							<P style={{ padding: '4px 12px' }}>{description}</P>
						</Box>
						<Box
							row
							alignItems="center"
							style={{
								background:
									'#CDD5FA 0% 0% no-repeat padding-box',
								boxShadow: '2px 8px 16px #172B4D3D',
								border: '1px solid #3F56C4',
								borderRadius: '4px',
								opacity: 1,
								margin: '60px 0 44px 12px',
								width: isMobile ? '350px' : '412px',
								cursor: 'pointer',
							}}
							onClick={() => {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.MARKETPLACE$ACTION,
										{
											rootModule,
											action: 'view-supplyability',
											documentReference:
												productDetails.id,
										}
									)
								)
								dispatch(
									MarketPlaceDuc.creators.setProductID(id)
								)
							}}
						>
							<IconWrapper
								size={24}
								style={{
									background:
										'#CDD5FA 0% 0% no-repeat padding-box',
									opacity: 1,
									margin: '16px',
								}}
							>
								<Icon glyph={SupplyAbilityIcon} />
							</IconWrapper>
							<P style={{ color: '#3F56C4' }}>
								{t('marketPlaceProducts.viewSA')}
							</P>
						</Box>
						<Box style={{ padding: '0 22px 20px 2px' }}>
							<H3
								bold
								color="#354DC5"
								style={{ margin: '0 0 0 12px' }}
							>
								{t('marketPlaceProducts.profile')}
							</H3>
							<KeyValueSegment
								label="Type"
								value={t(PROFILE_TYPE[tradeAccess]) || na}
								labelWidth={isMobile ? '50%' : '25%'}
							/>
						</Box>
						<H3
							bold
							color="#354DC5"
							style={{ margin: '10px 0 24px 12px' }}
						>
							{t('marketPlaceProducts.packagingDelivery')}
						</H3>
						<KeyValueSegment
							label={t('documentView.packagingType')}
							value={t(PACKAGING_TYPE[packagingType]) || na}
							labelWidth={isMobile ? '50%' : '25%'}
						/>
						<KeyValueSegment
							label={t('marketPlaceProducts.colour')}
							value={colour || na}
							labelWidth={isMobile ? '50%' : '25%'}
						/>
						<KeyValueSegment
							label={t('marketPlaceProducts.approxLeadTime')}
							value={approxLeadTime || na}
							labelWidth={isMobile ? '50%' : '25%'}
						/>
						<KeyValueSegment
							label={t('marketPlaceProducts.portOfLading')}
							value={port || na}
							labelWidth={isMobile ? '50%' : '25%'}
						/>
						<H3
							bold
							color="#354DC5"
							style={{ margin: '24px 0 24px 12px' }}
						>
							{t('marketPlaceProducts.specifications')}
						</H3>
						<KeyValueSegment
							label={t('marketPlaceProducts.grade')}
							value={grade || na}
							labelWidth={isMobile ? '50%' : '25%'}
						/>
						<KeyValueSegment
							label={t('marketPlaceProducts.qualitySpecs')}
							value={specifications || na}
							labelWidth={isMobile ? '50%' : '25%'}
						/>
						<KeyValueSegment
							label={t('marketPlaceProducts.otherAttachments')}
							value={t('marketPlaceProducts.noRecords')}
							labelWidth={isMobile ? '50%' : '25%'}
						/>
						<Box padding="4px 12px" row>
							<H3
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
									width: isMobile ? '50%' : '25%',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									color: theme.color.accent2,
								}}
							>
								{t('marketPlaceProducts.otherAttachments')}
							</H3>
							<Box row={!isMobile}>
								:
								{(otherCertifications || []).map(data => (
									<Box
										key={data.id}
										style={{
											width: 60,
											margin: '0px 10px',
										}}
									>
										<Box
											style={{
												height: 60,
												backgroundColor: '#ffeed4',
												width: 60,
											}}
											onClick={() => {
												window.open(
													getIn(data, [
														'meta',
														'fullURL',
													]),
													'_blank'
												)
											}}
											alignItems="center"
											justifyContent="center"
										>
											<Icon
												color="#9e797a"
												glyph={Documents}
												style={{
													height: 30,
													width: 20,
												}}
											/>
										</Box>
										<P
											style={{
												margin: 0,
												'text-overflow': 'ellipsis',
												'white-space': 'nowrap',
												overflow: 'hidden',
											}}
										>
											{getIn(data, ['meta', 'name'])}
										</P>
									</Box>
								))}
							</Box>
						</Box>
						<Spacer size={48} />
					</Box>
					{!isMobile && getLogo(productImage)}
				</Box>
			</Card>
		</Box>
	)
}

export { ProductDetails }
