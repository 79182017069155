import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'ui-marketplace-app/modules/App/components/Error'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { MainDashboard } from 'ui-marketplace-app/modules/MarketPlace/containers/MainDashboard'
import { DocumentView } from 'ui-marketplace-app/modules/MarketPlace/containers/DocumentView'
import { ViewRFQ } from 'ui-marketplace-app/modules/MarketPlace/containers/Enquiries/ViewRFQ'
import { CreateRFQ } from 'ui-marketplace-app/modules/MarketPlace/containers/Enquiries/CreateRFQ'
import { CreateQuote } from 'ui-marketplace-app/modules/MarketPlace/containers/Quotes/CreateQuote'
import { ViewQuote } from 'ui-marketplace-app/modules/MarketPlace/containers/Quotes/ViewQuote'
import { ProductDetails } from 'ui-marketplace-app/modules/MarketPlace/containers/Products/ProductDetails'
import { EditProduct } from 'ui-marketplace-app/modules/MarketPlace/containers/Products/EditProduct'
import { ViewSupplyAbility } from 'ui-marketplace-app/modules/MarketPlace/containers/Products/ViewSupplyAbility'
import { PostRequirement } from 'ui-marketplace-app/modules/MarketPlace/containers/GlobalEnquiries/PostRequirement'
import { ViewPostRequirement } from 'ui-marketplace-app/modules/MarketPlace/containers/GlobalEnquiries/ViewPostRequirement'
import { CreateContract } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/CreateContract'
import { ViewAttachedContract } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/ViewAttachedContract'
import { ContractParticipantsSuccess } from 'ui-marketplace-app/modules/MarketPlace/components/ContractParticipantsSuccess'
import { ESignContract } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/ESignContract'
import { ESignContractSuccess } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/ESignContractSuccess'
import { DibizContractGenerator } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts'
import { PreviewContractGenerator } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews'
import { PreviewContractParticipants } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/GenerateContracts/Previews/PreviewContractParticipants'
import { ProcessingContract } from 'ui-marketplace-app/modules/MarketPlace/containers/PoramContracts/ProcessingContract'

const modulesMap = {
	dashboard: MainDashboard,
	product: DocumentView,
	'view-quote': ViewQuote,
	'create-quote': CreateQuote,
	'edit-quote': CreateQuote,
	'create-rfq': CreateRFQ,
	'edit-rfq': CreateRFQ,
	'view-rfq': ViewRFQ,
	'view-global-rfq': ViewRFQ,
	'view-product': ProductDetails,
	'edit-product': EditProduct,
	'view-supplyability': ViewSupplyAbility,
	'post-requirement': PostRequirement,
	'view-post-requirement': ViewPostRequirement,
	create: CreateContract,
	view: ViewAttachedContract,
	'e-sign': ESignContract,
	generate: DibizContractGenerator,
	edit: DibizContractGenerator,
	preview: PreviewContractGenerator,
	'e-sign-success': ESignContractSuccess,
	'preview-participants': PreviewContractParticipants,
	'participants-success': ContractParticipantsSuccess,
	processing: ProcessingContract,
}

const MarketPlace = () => {
	const activeModule = useSelector(MarketPlaceDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock

	return <Component />
}

export default MarketPlace
