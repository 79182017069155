import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { PostRequirementModal } from 'ui-marketplace-app/modules/MarketPlace/components/PostRequirementModal'
import {
	isEmptyObject,
	viewPRTemplateKeys,
} from 'ui-marketplace-app/utils/helpers'
import { Input } from 'ui-lib/components/Input'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { CheckBoxGroup, CheckBox } from 'ui-lib/components/Checkbox'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import { Button } from 'ui-lib/components/Button'
import { Modal } from 'ui-lib/components/Modal'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { SmallText, P } from 'ui-lib/components/Typography'
import { Select, SelectSearch } from 'ui-lib/components/Select'
import { TextArea } from 'ui-lib/components/TextArea'
import { Card } from 'ui-lib/components/Card'
import { Label } from 'ui-lib/components/Label'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import theme from 'ui-lib/utils/base-theme'
import * as yup from 'yup'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import InfoIcon from 'ui-lib/icons/info_black.svg'
import ReportProblemIcon from 'ui-lib/icons/report_problem.svg'
import styled from 'styled-components'
import { getSupplyChainModels } from 'ui-marketplace-app/modules/Admin/config'

const labelWrapper = styled.div``

const ProductNameWrapper = styled.div`
	font-size: 18px;
	font-weight: 500;
	overflow: hidden;
	padding-bottom: 3px;
	text-overflow: ellipsis;
	color: ${theme.color.black3};
`

const TemplateNameWrapper = styled.div`
	font-size: 16px;
	font-weight: 400;
	color: ${theme.color.grey14};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`
const SubContentWrapper = styled.div`
	// padding-right: 15px;
`
const ViewText = styled.div`
	cursor: pointer;
	color: ${theme.color.blue13};
`
const MainWrapper = styled.div`
	display: flex;
	padding: 17px 20px;
	border-radius: 5px;

	border: 1px solid
		${props => (props.selected ? theme.color.blue5 : theme.color.grey14)}};
	&:hover {
		background-color: ${theme.color.grey13};
	}
`
const ListMainWrapper = styled.div`
	overflow: scroll;
	max-height: 350px;
	padding-right: 10px;
`

const ViewMainWrapper = styled.div`
	overflow: scroll;
	max-height: 400px;
	min-height: 400px;
	border-radius: 5px;
	border: 1px solid ${theme.color.blue5};
`
const ViewContentwrapperOut = styled.div`
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: ${props =>
		props.index === props.length - 1 ? '20px' : '0px'};
	padding-top: ${props => (props.index === 0 ? '20px' : '0px')};
	background-color: ${props =>
		props.index === 0 || props.index === 1 ? theme.color.grey13 : 'px'};
`
const ViewContentwrapper = styled.div`
	display: ${props => (props.isMobile ? 'block' : 'flex')};
	padding-bottom: 5px;
`
const ViewHeaderWrap = styled.div`
	display: flex;
`
const ViewHeaderIcon = styled.div``
const ViewKeyText = styled.div`
	font-size: 16px;
	min-width: 225px;
	font-weight: 500;
	color: ${theme.color.black3};
`
const ViewValueText = styled.div`
	font-size: 16px;
	min-width: 250px;
	font-weight: 300;
	color: ${theme.color.black3};
`
const ViewHeaderMainText = styled.div`
	margin-left: 20px;
`
const ViewHeaderSubText = styled.div`
	padding-left: 60px;
	margin-top: -10px;
`

const PostRequirement = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [postRequirementModal, setPostRequirementModal] = useState(false)
	const [requirementPayload, setRequirementPayload] = useState({})
	const [selectedProduct, setSelectedProduct] = useState({})
	const [showTemplateListModal, setShowTemplateListModal] = useState(false)
	const [showTemplateNameInput, setShowTemplateNameInput] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [selectedTemplate, setSelectedTemplate] = useState({})
	const [initialValues, setInitialValues] = useState({})
	const [showViewModal, setShowViewModal] = useState(false)
	const [templateNameExists, setTemplateNameExists] = useState(false)

	useEffect(() => {
		dispatch(MarketPlaceDuc.creators.rfqTemplateList())
	}, [dispatch])

	const products =
		useSelector(MarketPlaceDuc.selectors.getGlobalProductsNoPagination) ||
		[]

	const currentOrganization = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)

	const rfqTemplateNameSearch = useSelector(
		MarketPlaceDuc.selectors.getRfqTemplateSearchResult
	)

	const rfqTemplateNameList = useSelector(
		MarketPlaceDuc.selectors.getRfqTemplateListResult
	)

	const productsList = products.map(prod => {
		const name = getIn(prod, ['name'])
		const id = getIn(prod, ['id'])
		const code = getIn(prod, ['code'])

		return {
			label: name,
			name: id,
			code,
		}
	})

	useEffect(() => {
		setTemplateNameExists(
			getIn(rfqTemplateNameSearch, ['data', 'TempNameMatch']) || false
		)
	}, [rfqTemplateNameSearch])

	const validationSchema = yup.object().shape({
		quantity: yup
			.number()
			.positive()
			.required(),
		uom: yup.string().required(),
		productID: yup.string().required(),
		templateName:
			showTemplateNameInput &&
			yup
				.string()
				.required(t('validation.templateNameRequired'))
				.min(1, t('validation.templateNameRequired'))
				.matches(/\S/, t('validation.templateNameRequired'))
				.strict(),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		submitForm,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues: initialValues || {},
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const inspection = []

			if (_values.thirdPartyInspectionType) {
				const quantityInsp = _values.thirdPartyInspectionType.filter(
					insp => insp === 'quantity'
				)

				const qualityInsp = _values.thirdPartyInspectionType.filter(
					insp => insp === 'quality'
				)

				if (quantityInsp.length) {
					inspection.push({
						type: 'quantity',
						officer: _values.quantityInspector,
					})
				}

				if (qualityInsp.length) {
					inspection.push({
						type: 'quality',
						officer: _values.qualityInspector,
					})
				}
			}

			const payload = {
				buyer: {
					id: currentOrganization.id,
					name: currentOrganization.name,
				},
				contractType: _values.contractType || ' ',
				destinationPort: _values.destinationPort || ' ',
				inspection,
				ladingPort: _values.ladingPort || ' ',
				payment: _values.payment || ' ',
				product:
					{
						code: selectedProduct.code,
						id: selectedProduct.name,
						name: selectedProduct.label,
						uom: _values.uom,
					} || {},
				templateName: _values.templateName
					? _values.templateName.trim()
					: '',
				saveTemplate: showTemplateNameInput,
				quantity: _values.quantity,
				requirementDetails: _values.requirementDetails || ' ',
				seller: {
					id: `${selectedProduct.code.toLowerCase()}-global-seller`,
					name: 'Global Seller',
				},
				shipment: {
					month: _values.month,
					period: _values.period,
				},
				supplychainModel: {
					id: _values.supplychainModel,
				},
				meta: {
					inviteEmail: false,
					sellerType: `${selectedProduct.code.toLowerCase()}-global-seller`,
					globalSeller: 'true',
					termsAndConditions: _values.termsAndConditions || ' ',
				},
			}

			setPostRequirementModal(true)
			setRequirementPayload(payload)
		},
	})

	function submitDetails(emails, allSellers) {
		dispatch(
			MarketPlaceDuc.creators.postGeneralRequirement(
				emails,
				allSellers,
				requirementPayload
			)
		)
	}

	const disableCTA =
		!isEmptyObject(errors) ||
		!values.productID ||
		!values.quantity ||
		(showTemplateNameInput &&
			(!values.templateName ||
				(values.templateName && !values.templateName.trim()))) ||
		(showTemplateNameInput && templateNameExists) ||
		!values.uom

	const getInspectorData = (
		item,
		type,
		getType = false,
		getTypeArray = false
	) => {
		let data = ''
		if (getType) {
			const typeList = []
			if (
				getIn(item, ['templateObj', 'inspection']) &&
				getIn(item, ['templateObj', 'inspection']).length > 0
			) {
				getIn(item, ['templateObj', 'inspection']).forEach(ele => {
					typeList.push(getIn(ele, ['type']))
				})
				data = getTypeArray ? typeList : typeList.toString()
			}
		} else {
			const tempData =
				getIn(item, ['templateObj', 'inspection']) &&
				getIn(item, ['templateObj', 'inspection']).length > 0
					? getIn(item, ['templateObj', 'inspection']).find(
							ele => getIn(ele, ['type']) === type
					  )
					: ''
			data = tempData ? getIn(tempData, ['officer']) : ''
		}

		return data
	}

	const viewValues = index => {
		let value = ''
		switch (index) {
			case 0: {
				value =
					getIn(selectedItem, ['templateObj', 'product', 'name']) ||
					''
				break
			}
			case 1: {
				const data = (
					<div>
						{getIn(selectedItem, ['templateObj', 'quantity']) || ''}
						&nbsp;
						{getIn(selectedItem, [
							'templateObj',
							'product',
							'uom',
						]) || ''}
					</div>
				)
				value = data || ''
				break
			}
			case 2: {
				const data = (
					<div>
						{getIn(selectedItem, [
							'templateObj',
							'shipment',
							'month',
						]) || ''}
						{getIn(selectedItem, [
							'templateObj',
							'shipment',
							'month',
						])
							? ','
							: ''}{' '}
						&nbsp;
						{getIn(selectedItem, [
							'templateObj',
							'shipment',
							'period',
						]) || ''}
					</div>
				)
				value = data || ''
				break
			}
			case 3: {
				value = getIn(selectedItem, ['templateObj', 'ladingPort']) || ''
				break
			}
			case 4: {
				value =
					getIn(selectedItem, ['templateObj', 'destinationPort']) ||
					''
				break
			}
			case 5: {
				value =
					getIn(selectedItem, [
						'templateObj',
						'supplyChainModel',
						'id',
					]) || ''
				break
			}
			case 6: {
				value = getInspectorData(selectedItem, '', true) || ''
				break
			}
			case 7: {
				value = getInspectorData(selectedItem, 'quality') || ''
				break
			}
			case 8: {
				value = getInspectorData(selectedItem, 'quantity') || ''
				break
			}
			case 9: {
				value =
					getIn(selectedItem, ['templateObj', 'contractType']) || ''
				break
			}
			case 10: {
				value = getIn(selectedItem, ['templateObj', 'payment']) || ''
				break
			}
			case 11: {
				value =
					getIn(selectedItem, [
						'templateObj',
						'requirementDetails',
					]) || ''
				break
			}
			case 12: {
				value =
					getIn(selectedItem, [
						'templateObj',
						'meta',
						'termsAndConditions',
					]) || ''
				break
			}

			default:
				break
		}

		return value
	}

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('postRequirement.marketPlace'),
			name: 'marketplace',
			isActive: true,
		},
		{
			label: t('postRequirement.postGeneralRequirement'),
			name: 'post-general-requirement',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'marketplace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule: 'buyer',
					}
				)
			)
		}
	}

	const viewSelectedItem = () => {
		const viewData = []
		viewPRTemplateKeys.forEach((ele, index) => {
			viewData.push(
				<ViewContentwrapperOut
					index={index}
					length={viewPRTemplateKeys.length}
				>
					<ViewContentwrapper isMobile={isMobile}>
						<ViewKeyText>{ele}</ViewKeyText>
						<ViewValueText>{viewValues(index)}</ViewValueText>
					</ViewContentwrapper>
				</ViewContentwrapperOut>
			)
		})

		return <ViewMainWrapper>{viewData}</ViewMainWrapper>
	}

	const viewSelectedItemHeader = () => {
		return (
			<ViewHeaderWrap>
				<ViewHeaderIcon>
					<IconWrapper
						size={38}
						style={{
							color: theme.color.blue5,
							cursor: 'pointer',
							paddingTop: '5px',
						}}
						onClick={() => {
							setShowTemplateListModal(true)
							setShowViewModal(false)
						}}
					>
						<Icon glyph={LeftArrowIcon} />
					</IconWrapper>
				</ViewHeaderIcon>
				<ViewHeaderMainText>
					{getIn(selectedItem, ['templateName'])}
				</ViewHeaderMainText>
			</ViewHeaderWrap>
		)
	}

	const radioGroupOptions = () => {
		const list = []
		const tempList =
			rfqTemplateNameList &&
			getIn(rfqTemplateNameList, ['data', 'list']) &&
			getIn(rfqTemplateNameList, ['data', 'list']).length > 0
				? getIn(rfqTemplateNameList, ['data', 'list'])
				: ''

		if (tempList && tempList.length > 0) {
			tempList.forEach(item => {
				const options = {}

				options.name = getIn(item, ['id'])
				options.label = (
					<MainWrapper
						selected={
							getIn(item, ['id']) ===
							getIn(selectedTemplate, ['id'])
						}
					>
						<SubContentWrapper
							style={{
								maxWidth: isMobile ? '100px' : '290px',
								minWidth: isMobile ? '100px' : '290px',
							}}
						>
							<ProductNameWrapper
								title={
									getIn(item, [
										'templateObj',
										'product',
										'name',
									]) || ''
								}
							>
								{getIn(item, [
									'templateObj',
									'product',
									'name',
								]) || ''}
							</ProductNameWrapper>
							<TemplateNameWrapper
								title={getIn(item, ['templateName']) || ''}
							>
								{getIn(item, ['templateName']) || ''}
							</TemplateNameWrapper>
						</SubContentWrapper>
						<SubContentWrapper
							style={{
								maxWidth: isMobile ? '85px' : '158px',
								minWidth: isMobile ? '85px' : '158px',
								paddingLeft: '20px',
							}}
						>
							<ProductNameWrapper
								title={`${getIn(item, [
									'templateObj',
									'quantity',
								]) || ''} ${getIn(item, [
									'templateObj',
									'product',
									'uom',
								]) || ''}`}
							>
								{getIn(item, ['templateObj', 'quantity']) || ''}
								&nbsp;
								{getIn(item, [
									'templateObj',
									'product',
									'uom',
								]) || ''}
							</ProductNameWrapper>
						</SubContentWrapper>
						<SubContentWrapper
							style={{
								maxWidth: '60px',
							}}
						>
							<ViewText
								onClick={() => {
									setSelectedItem(item)
									setShowViewModal(true)
									setShowTemplateListModal(false)
								}}
							>
								{t('common.view').toUpperCase()}
							</ViewText>
						</SubContentWrapper>
					</MainWrapper>
				)

				list.push(options)
			})
		}

		return list
	}

	const templateList = () => {
		return (
			<ListMainWrapper>
				<RadioInputGroup
					direction="column"
					selected={
						selectedTemplate && getIn(selectedTemplate, ['id'])
							? getIn(selectedTemplate, ['id'])
							: null
					}
					gap="medium"
					options={radioGroupOptions()}
					onChange={e => {
						setSelectedTemplate(
							getIn(rfqTemplateNameList, ['data', 'list']).find(
								ele => getIn(ele, ['id']) === e.target.value
							)
						)
					}}
				/>
			</ListMainWrapper>
		)
	}

	const templateHandleChange = e => {
		if (e.target.value.trim() !== '') {
			dispatch(
				MarketPlaceDuc.creators.rfqTemplateSearch(e.target.value.trim())
			)
			handleChange(e)
		}
	}

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box>
				<Title
					title={t('postRequirement.postYourRequirement')}
					icon={LeftArrowIcon}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE,
								{
									rootModule: 'buyer',
								}
							)
						)
					}}
				/>
			</Box>
			<Modal
				size="auto"
				show={postRequirementModal}
				body={
					<PostRequirementModal
						onClose={() => setPostRequirementModal(false)}
						onConfirm={(emails, allSellers) => {
							submitDetails(emails, allSellers)
							setPostRequirementModal(false)
						}}
					/>
				}
				hideButtons
			/>
			<Box row={!isMobile} justifyContent="space-between">
				{isMobile && (
					<Box
						style={{
							width: isMobile ? '100%' : '26%',
							alignItems: isMobile ? 'center' : 'normal',
						}}
					>
						{rfqTemplateNameList &&
						getIn(rfqTemplateNameList, ['data']) &&
						getIn(rfqTemplateNameList, ['data', 'list']) &&
						getIn(rfqTemplateNameList, ['data', 'list']).length >
							0 ? (
							<Box
								style={{
									padding: '1px',
									marginBottom: 32,
								}}
							>
								<Button
									label={t(
										'postRequirement.orderUsingTemplate'
									)}
									onClick={() => {
										setShowTemplateListModal(true)
										setSelectedTemplate({})
									}}
								/>
							</Box>
						) : (
							''
						)}
					</Box>
				)}
				<Box
					style={{
						background: '#fff',
						padding: '22px',
						width:
							rfqTemplateNameList &&
							getIn(rfqTemplateNameList, ['data']) &&
							getIn(rfqTemplateNameList, ['data', 'list']) &&
							getIn(rfqTemplateNameList, ['data', 'list'])
								.length > 0
								? '800px'
								: '824px',
					}}
				>
					<form onSubmit={handleSubmit}>
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('postRequirement.product')}
							</SmallText>
							<Box style={{ width: '100%', marginTop: '20px' }}>
								<Label
									style={{
										fontSize: '14px',
										color: '#242845',
										marginBottom: 8,
									}}
									required
								>
									{t('postRequirement.chooseProduct')}
								</Label>
								<Box width={isMobile ? 250 : 300}>
									<SelectSearch
										name="productID"
										value={values.productID}
										options={productsList}
										onChange={value => {
											setFieldValue(
												'productID',
												value.name
											)
											setSelectedProduct(value)
										}}
										returnOnlyValue
										labelKey="label"
										valueKey="name"
										onBlur={handleBlur}
										error={
											touched.productID &&
											errors.productID
										}
										style={{
											width: 300,
											border: '1px solid #0b20aa',
											borderRadius: 4,
										}}
									/>
								</Box>
							</Box>
						</Card>
						<Spacer size={40} />
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('postRequirement.quantityTimeline')}
							</SmallText>

							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box>
									<Label
										style={{
											fontSize: '14px',
											color: '#242845',
										}}
										required
									>
										{t('postRequirement.enterQuantity')}
									</Label>
									<Box row margin="8px 0 0">
										<Box width={isMobile ? 125 : 193}>
											<Input
												value={values.quantity}
												name="quantity"
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.quantity &&
													errors.quantity
												}
											/>
										</Box>
										<Box
											width={isMobile ? 100 : 149}
											margin="0  0 0 16px"
										>
											<Select
												value={values.uom}
												options={[
													{
														label: t(
															'marketPlace.mt'
														),
														name: 'mt',
													},
													{
														label: t(
															'marketPlace.kg'
														),
														name: 'kg',
													},
												]}
												key={values.uom}
												labelKey="label"
												valueKey="name"
												onChange={value =>
													setFieldValue(
														'uom',
														value.name
													)
												}
												returnOnlyValue
											/>
										</Box>
									</Box>
									<Box row margin="8px 0 0">
										<Box>
											<Label
												style={{
													fontSize: '14px',
													color: '#242845',
												}}
											>
												{t(
													'postRequirement.shipmentPeriod'
												)}
											</Label>
											<Box row margin="8px 0 0">
												<Box
													width={isMobile ? 125 : 193}
												>
													<Select
														value={values.month}
														options={[
															{
																label: t(
																	'marketPlace.jan'
																),
																name: 'january',
															},
															{
																label: t(
																	'marketPlace.feb'
																),
																name:
																	'february',
															},
															{
																label: t(
																	'marketPlace.mar'
																),
																name: 'march',
															},
															{
																label: t(
																	'marketPlace.apr'
																),
																name: 'april',
															},
															{
																label: t(
																	'marketPlace.may'
																),
																name: 'may',
															},
															{
																label: t(
																	'marketPlace.jun'
																),
																name: 'june',
															},
															{
																label: t(
																	'marketPlace.jul'
																),
																name: 'july',
															},
															{
																label: t(
																	'marketPlace.aug'
																),
																name: 'august',
															},
															{
																label: t(
																	'marketPlace.sep'
																),
																name:
																	'september',
															},
															{
																label: t(
																	'marketPlace.oct'
																),
																name: 'october',
															},
															{
																label: t(
																	'marketPlace.nov'
																),
																name:
																	'november',
															},
															{
																label: t(
																	'marketPlace.dec'
																),
																name:
																	'december',
															},
														]}
														key={values.month}
														labelKey="label"
														valueKey="name"
														onChange={value =>
															setFieldValue(
																'month',
																value.name
															)
														}
														returnOnlyValue
													/>
												</Box>

												<Box
													width={isMobile ? 100 : 149}
													margin="0 0 0 16px"
												>
													<Select
														value={values.period}
														options={[
															{
																label: t(
																	'marketPlace.first-half'
																),

																name:
																	'first-half',
															},

															{
																label: t(
																	'marketPlace.second-half'
																),

																name:
																	'second-half',
															},

															{
																label: t(
																	'marketPlace.entire-month'
																),

																name:
																	'entire-month',
															},
														]}
														key={values.period}
														labelKey="label"
														valueKey="name"
														onChange={value =>
															setFieldValue(
																'period',
																value.name
															)
														}
														returnOnlyValue
													/>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>

								<Card
									style={{
										background: '#CBD2EF',
										width: 252,
										padding: '12px 20px 0px',
										height: 160,
										margin: isMobile
											? '26px 0px 28px 0px'
											: '26px 0px 0px 28px',
										borderRadius: 6,
									}}
									textAlign="left"
								>
									<Box row>
										<IconWrapper
											size={24}
											style={{
												padding: 4,
												background: '#ECEEF7',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Icon glyph={ReportProblemIcon} />
										</IconWrapper>
										<SmallText
											style={{
												marginLeft: 12,
												fontWeight: 'bold',
											}}
										>
											{t('postRequirement.impStep')}
										</SmallText>
									</Box>
									<Box>
										<P
											style={{
												color: '#3F56C4',
												fontSize: 14,
												marginTop: 8,
											}}
										>
											{t(
												'postRequirement.impStepDescription'
											)}
										</P>
									</Box>
								</Card>
							</Box>
						</Card>

						<Spacer size={32} />
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('postRequirement.shipmentDetails')}
							</SmallText>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box
									style={{
										width: isMobile ? '250px' : '300px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
										}}
									>
										{t('postRequirement.portOfLading')}
									</Label>
									<Input
										value={values.ladingPort}
										name="ladingPort"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.ladingPort &&
											errors.ladingPort
										}
									/>
								</Box>

								<Box
									style={{
										width: isMobile ? '250px' : '300px',
									}}
								>
									<Label
										style={{
											fontSize: '14px',
											margin: '0 0 8px',
											color: theme.color.black3,
										}}
									>
										{t('postRequirement.destinationPort')}
									</Label>
									<Input
										value={values.destinationPort}
										name="destinationPort"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.destinationPort &&
											errors.destinationPort
										}
									/>
								</Box>
							</Box>
						</Card>
						<Spacer size={32} />
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('postRequirement.otherDetails')}
							</SmallText>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box style={{ width: '36%' }}>
									<Box style={{ width: '300%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												color: '#242845',
												margin: '0 0 8px',
											}}
										>
											{t(
												'postRequirement.thirdPartyInspection'
											)}
										</Label>
										<Box style={{ width: '300%' }}>
											<CheckBoxGroup
												selected={
													values.thirdPartyInspectionType
												}
												options={[
													{
														label: t(
															'marketPlace.quantity'
														),
														key: 'quantity',
													},
													{
														label: t(
															'marketPlace.quality'
														),
														key: 'quality',
													},
												]}
												onChange={value => {
													setFieldValue(
														'thirdPartyInspectionType',
														value
													)
												}}
											/>
										</Box>
									</Box>
								</Box>
								<Card
									style={{
										background: '#CBD2EF',
										width: isMobile ? 250 : 300,
										padding: isMobile
											? '12px 20px'
											: '12px 20px 0px',
										height: 'auto',
										marginTop: isMobile ? 20 : 0,
									}}
									textAlign="left"
								>
									<Box row>
										<IconWrapper
											style={{
												backgroundColor: '#ECEEF7',
												padding: 4,
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
											size={24}
										>
											<Icon glyph={InfoIcon} />
										</IconWrapper>
										<P
											style={{
												color: '#3F56C4',
												fontSize: 14,
												marginLeft: 16,
											}}
										>
											{t(
												'postRequirement.thirdPartyInspectionline2'
											)}
										</P>
									</Box>
								</Card>
							</Box>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box
									style={{
										width: isMobile ? '250px' : '300px',
									}}
								>
									<Label
										style={{
											fontSize: theme.fontSize.m,
											color: '#242845',
											margin: '0 0 8px',
										}}
									>
										{t(
											'postRequirement.preferredQuantityInspector'
										)}
									</Label>

									<Input
										value={values.quantityInspector}
										name="quantityInspector"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.quantityInspector &&
											errors.quantityInspector
										}
									/>
								</Box>

								<Box
									style={{
										width: isMobile ? '250px' : '300px',
									}}
								>
									<Label
										style={{
											fontSize: theme.fontSize.m,
											color: '#242845',
											margin: '0 0 8px',
										}}
									>
										{t(
											'postRequirement.preferredQualityInspector'
										)}
									</Label>
									<Input
										value={values.qualityInspector}
										name="qualityInspector"
										type="text"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.qualityInspector &&
											errors.qualityInspector
										}
									/>
								</Box>
							</Box>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box
									style={{
										width: isMobile ? '250px' : '300px',
									}}
								>
									<Label
										style={{
											fontSize: theme.fontSize.m,
											color: '#242845',
											margin: '0 0 8px',
										}}
									>
										{t('postRequirement.scModel')}
									</Label>

									<Select
										value={values.supplychainModel}
										options={getSupplyChainModels(t)}
										key={values.supplychainModel}
										labelKey="label"
										valueKey="name"
										onChange={value =>
											setFieldValue(
												'supplychainModel',
												value.name
											)
										}
										returnOnlyValue
									/>
								</Box>

								<Box
									style={{
										width: isMobile ? '250px' : '300px',
									}}
								>
									<Label
										style={{
											fontSize: theme.fontSize.m,
											color: '#242845',
											margin: '0 0 8px',
										}}
									>
										{t('postRequirement.typeOfContract')}
									</Label>
									<Select
										value={values.contractType}
										options={[
											{
												label: t('marketPlace.fob'),

												name: 'fob',
											},

											{
												label: t('marketPlace.cif'),

												name: 'cif',
											},

											{
												label: t(
													'marketPlace.local-delivery'
												),

												name: 'local-delivery',
											},
										]}
										key={values.contractType}
										labelKey="label"
										valueKey="name"
										onChange={e =>
											setFieldValue(
												'contractType',
												e.name
											)
										}
										returnOnlyValue
									/>
								</Box>
							</Box>
						</Card>
						<Spacer size={32} />
						<Box>
							<labelWrapper>
								<Label
									style={{
										fontSize: '16px',
										margin: '0 0 8px',
										color: theme.color.black3,
									}}
								>
									{t('postRequirement.termsAndConditions')}
								</Label>
								<Label
									style={{
										fontSize: '12px',
										margin: isMobile ? '8px 0' : '0 0 8px',
										color: theme.color.white,
										alignItems: 'center',
										float: 'right',
										paddingRight: '10px',
										paddingLeft: '10px',
										backgroundColor: '#CE924B',
										borderRadius: '4px',
										opacity: 1,
									}}
								>
									{t(
										'postRequirement.termsAndConditionsline2'
									)}
								</Label>
							</labelWrapper>
							<TextArea
								name="termsAndConditions"
								value={values.termsAndConditions}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'termsAndConditions',
										e.target.value
									)
								}}
							/>
						</Box>
						<Spacer size={16} />
						<Box>
							<Label
								style={{
									fontSize: '16px',
									margin: '0 0 8px',
									color: theme.color.black3,
								}}
							>
								{t('postRequirement.paymentTerms')}
							</Label>
							<TextArea
								name="payment"
								value={values.payment}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue('payment', e.target.value)
								}}
							/>
						</Box>
						<Spacer size={16} />
						<Box>
							<Label
								style={{
									fontSize: '16px',
									margin: '0 0 8px',
									color: theme.color.black3,
								}}
							>
								{t('postRequirement.additionalDetails')}
							</Label>
							<TextArea
								name="requirementDetails"
								value={values.requirementDetails}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'requirementDetails',
										e.target.value
									)
								}}
							/>
						</Box>
						{showTemplateNameInput ? (
							<Box>
								<Label
									style={{
										fontSize: '14px',
										color: '#242845',
										marginBottom: '8px',
									}}
									required
								>
									{t('postRequirement.templateName')}
								</Label>
								<Box>
									<Input
										helpText={
											templateNameExists ? (
												<div
													style={{
														color:
															theme.color.error,
														paddingTop: 8,
													}}
												>
													{t(
														'postRequirement.templateNameValidation'
													)}
												</div>
											) : (
												''
											)
										}
										value={values.templateName}
										name="templateName"
										type="text"
										onChange={e => templateHandleChange(e)}
										onBlur={handleBlur}
										error={errors.templateName}
									/>
								</Box>
							</Box>
						) : (
							''
						)}
					</form>
				</Box>
				{!isMobile && (
					<Box
						style={{
							width: '26%',
						}}
					>
						{rfqTemplateNameList &&
						getIn(rfqTemplateNameList, ['data']) &&
						getIn(rfqTemplateNameList, ['data', 'list']) &&
						getIn(rfqTemplateNameList, ['data', 'list']).length >
							0 ? (
							<Box
								style={{
									padding: '1px',
									marginBottom: 32,
								}}
							>
								<Button
									label={t(
										'postRequirement.orderUsingTemplate'
									)}
									onClick={() => {
										setShowTemplateListModal(true)
										setSelectedTemplate({})
									}}
								/>
							</Box>
						) : (
							''
						)}
					</Box>
				)}
			</Box>
			<Box
				width="824px"
				row={!isMobile}
				justifyContent="space-between"
				margin="36px 0 0"
			>
				<Box
					style={{
						height: '42px',
						paddingTop: '9px',
						paddingLeft: '23px',
						paddingRight: '23px',
						backgroundColor: theme.color.white,
					}}
				>
					<CheckBox
						label={t('postRequirement.checkBoxLabel')}
						name="saveTemplate"
						onChange={() =>
							setShowTemplateNameInput(!showTemplateNameInput)
						}
					/>
				</Box>
				{isMobile && (
					<Box
						style={{
							fontSize: 14,
							marginTop: 12,
							color: theme.color.grey14,
						}}
					>
						{t('postRequirement.tip')}
					</Box>
				)}
				<Box
					style={{
						alignItems: 'end',
						marginTop: isMobile ? '30px' : '0px',
						marginRight: isMobile ? '10px' : '0px',
					}}
				>
					<Button
						label={t('common.proceed')}
						primary
						disabled={disableCTA}
						onClick={() => submitForm()}
						extendStyles={{ width: 212 }}
					/>
				</Box>
			</Box>
			{!isMobile && (
				<Box
					style={{
						fontSize: 14,
						marginTop: 12,
						color: theme.color.grey14,
					}}
				>
					{t('postRequirement.tip')}
				</Box>
			)}
			<Modal
				size="680px"
				show={showTemplateListModal}
				closeable
				heading={t('postRequirement.selectTemplate')}
				subHeading={t('rfq.selectTemplateSubheading')}
				body={templateList()}
				confirmlabel={t('postRequirement.selectTemplate')}
				onClose={() => setShowTemplateListModal(false)}
				extendStyles={{ borderRadius: '10px' }}
				isDisabled={
					!(selectedTemplate && getIn(selectedTemplate, ['id']))
				}
				onConfirm={() => {
					if (selectedTemplate) {
						const rfqDetails = {
							productID:
								getIn(selectedTemplate, [
									'templateObj',
									'product',
									'id',
								]) || '',
							quantity:
								parseInt(
									getIn(selectedTemplate, [
										'templateObj',
										'quantity',
									]),
									10
								) || '',
							uom: getIn(selectedTemplate, [
								'templateObj',
								'product',
								'uom',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'product',
										'uom',
								  ]).toLowerCase() || ''
								: '',
							ladingPort:
								getIn(selectedTemplate, [
									'templateObj',
									'ladingPort',
								]) || '',
							month: getIn(selectedTemplate, [
								'templateObj',
								'shipment',
								'month',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'shipment',
										'month',
								  ]).toLowerCase() || ''
								: '',
							period: getIn(selectedTemplate, [
								'templateObj',
								'shipment',
								'period',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'shipment',
										'period',
								  ])
										.replace(/\s/g, '-')
										.toLowerCase() || ''
								: '',
							destinationPort:
								getIn(selectedTemplate, [
									'templateObj',
									'destinationPort',
								]) || '',
							quantityInspector:
								getInspectorData(
									selectedTemplate,
									'quantity'
								) || '',
							qualityInspector:
								getInspectorData(selectedTemplate, 'quality') ||
								'',
							thirdPartyInspectionType: getInspectorData(
								selectedTemplate,
								'',
								true,
								true
							),
							supplychainModel: getIn(selectedTemplate, [
								'templateObj',
								'supplyChainModel',
								'id',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'supplyChainModel',
										'id',
								  ])
										.replace(/\s/g, '-')
										.toLowerCase() || ''
								: '',
							contractType: getIn(selectedTemplate, [
								'templateObj',
								'contractType',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'contractType',
								  ])
										.replace(/\s/g, '-')
										.toLowerCase() || ''
								: '',
							payment:
								getIn(selectedTemplate, [
									'templateObj',
									'payment',
								]) || '',
							requirementDetails:
								getIn(selectedTemplate, [
									'templateObj',
									'requirementDetails',
								]) || '',
							termsAndConditions:
								getIn(selectedTemplate, [
									'templateObj',
									'meta',
									'termsAndConditions',
								]) || '',
						}
						setInitialValues(rfqDetails)
						if (getIn(selectedTemplate, ['productID'])) {
							productsList.forEach(value => {
								if (
									getIn(selectedTemplate, ['productID']) ===
									getIn(value, ['name'])
								) {
									setFieldValue('product', value.name)
									setSelectedProduct(value)
								}
							})
						}
						setShowTemplateListModal(false)
					}
				}}
			/>
			<Modal
				size="680px"
				show={showViewModal}
				closeable
				heading={viewSelectedItemHeader()}
				subHeading={
					<ViewHeaderSubText>
						{t('postRequirement.showing')}{' '}
						{getIn(selectedItem, ['templateName'])}{' '}
						{t('postRequirement.details')}
					</ViewHeaderSubText>
				}
				hideButtons
				body={viewSelectedItem()}
				onClose={() => {
					setShowViewModal(false)
					setSelectedTemplate({})
				}}
				extendStyles={{ borderRadius: '10px' }}
			/>
		</>
	)
}

export { PostRequirement }
