import React from 'react'
import { useTranslation } from 'react-i18next'
import { Close } from 'grommet-icons'
import { isEmptyObject } from 'ui-marketplace-app/utils/helpers'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Box as GrommBox } from 'grommet'
import { Box } from 'ui-lib/utils/Box'
import { H2, P } from 'ui-lib/components/Typography'
import { Label } from 'ui-lib/components/Label'
import { Select } from 'ui-lib/components/Select'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { useFormik } from 'formik'
import * as yup from 'yup'

const labelStyles = {
	fontSize: '16px',
	color: '#172B4D',
}

const SupplyAbilityModal = ({
	onClose,
	details,
	onSubmit,
	isMobile = false,
}) => {
	const { t } = useTranslation()

	const { id } = details

	const validationSchema = yup.object().shape({
		supplyAt: yup.date().required(),
		quantity: yup
			.number()
			.positive()
			.required(),
		uom: yup.string().required(),
	})

	const {
		values,
		handleBlur,
		errors,
		touched,
		handleChange,
		setFieldValue,
		submitForm,
	} = useFormik({
		initialValues: {
			supplyAt: '',
			quantity: '',
			uom: '',
			noOfDays: '1',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const payload = { ..._values, id }
			onSubmit(payload)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.supplyAt

	return (
		<GrommBox width="880px" overflow>
			<Box row justifyContent="flex-end">
				<Button plain customIcon={<Close />} onClick={onClose} />
			</Box>
			<Box>
				<H2 style={{ color: '#4B4552', fontSize: 32 }}>
					{t('supplyAbilityModal.publishSA')}
				</H2>
				<P
					style={{
						color: '#727F9A',
						fontSize: 16,
					}}
				>
					{t('supplyAbilityModal.publishSASubheading')}
				</P>
				<P
					style={{
						color: '#3F56C4',
						fontSize: 16,
						marginTop: 8,
					}}
				>
					{t('supplyAbilityModal.addDataForDay')}
				</P>
			</Box>
			<Label style={{ ...labelStyles, marginTop: 12 }} required>
				{t('supplyAbilityModal.selectDate')}
			</Label>
			<Box row={!isMobile}>
				<Box>
					<Box width={336}>
						<DatePicker
							value={values.supplyAt}
							minDate={new Date().setHours(0, 0, 0, 0)}
							placeholder={t('supplyAbilityModal.chooseADay')}
							onChange={value => {
								setFieldValue('supplyAt', new Date(value))
							}}
						/>
					</Box>
					<Box>
						<Label style={labelStyles} required>
							{t('supplyAbilityModal.addInventory')}
						</Label>
						<Box row margin="8px 0 0">
							<Box width={isMobile ? 150 : 180}>
								<Input
									value={values.quantity}
									name="quantity"
									type="number"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.quantity && errors.quantity}
									extendStyles={{ height: 41 }}
								/>
							</Box>
							<Box
								width={isMobile ? 120 : 144}
								margin="0 0 0 12px"
							>
								<Select
									value={values.uom}
									options={[
										{
											label: t('marketPlace.mt'),
											name: 'mt',
										},
										{
											label: t('marketPlace.kg'),
											name: 'kg',
										},
									]}
									key={values.uom}
									placeholder={t(
										'supplyAbilityModal.chooseUOM'
									)}
									labelKey="label"
									valueKey="name"
									onChange={value =>
										setFieldValue('uom', value.name)
									}
									returnOnlyValue
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box style={{ marginLeft: isMobile ? 0 : 100 }}>
					<RadioInputGroup
						gap="medium"
						options={[
							{
								label: t('supplyAbilityModal.sameInventory30'),
								name: '30',
							},
							{
								label: t('supplyAbilityModal.sameInventory60'),
								name: '60',
							},
							{
								label: t('supplyAbilityModal.sameInventory90'),
								name: '90',
							},
						]}
						onChange={e => {
							setFieldValue('noOfDays', e.target.value)
						}}
					/>
				</Box>
			</Box>

			<Box row justifyContent="flex-end">
				<Button
					primary
					disabled={disableCTA}
					onClick={() => submitForm()}
					label={t('common.proceed')}
				/>
			</Box>
		</GrommBox>
	)
}

export { SupplyAbilityModal }
