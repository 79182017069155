import { all, takeLatest, put, call, select, delay } from 'redux-saga/effects'
import { getIn } from 'timm'
import LogHelper from 'ui-marketplace-app/utils/logger'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import {
	getIAMEndPoint,
	getCoreEndPoint,
	getMarketplaceEndPoint,
} from 'ui-marketplace-app/config'
import {
	CallWithRefreshCheck,
	getOrgIDFromLoggedUser,
} from 'ui-marketplace-app/modules/Auth/AuthSaga'
import { DocumentListing } from 'ui-marketplace-app/modules/App/AppSaga'
import request from 'ui-marketplace-app/utils/request'
import { Toast } from 'ui-lib/components/Toast'
import { MarketPlaceDuc } from './duc'
import { tranformGlobalRfqQueries } from './helpers'

const logger = LogHelper('client:MarketPlaceSaga')

const PAGINATION_LIMIT = 100

function* getGlobalProduct() {
	try {
		const requestUrl = `${getCoreEndPoint()}products?limit=100`
		const { data } = yield CallWithRefreshCheck(requestUrl)
		const productList = getIn(data, ['list']) || []
		yield put(MarketPlaceDuc.creators.setGlobalProductSuccess(productList))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* getMarketPlaceOrganisationList(action) {
	try {
		const { locationState } = action
		const requestUrl = `${getMarketplaceEndPoint()}orgproducts`
		yield put(MarketPlaceDuc.creators.setMPOrgListLoadingStatus(true))
		const {
			data,
			serverPaginationQuery,
			frontendTargetQuery,
		} = yield DocumentListing(requestUrl, locationState, PAGINATION_LIMIT)
		yield put(
			MarketPlaceDuc.creators.getMarketPlaceOrganisationListSuccess(
				data.list
			)
		)

		yield put(
			MarketPlaceDuc.creators.saveMarketPlacePaginationQueries(
				serverPaginationQuery
			)
		)

		yield put(
			MarketPlaceDuc.creators.setActiveFiltersMarketPlaceSearch(
				frontendTargetQuery
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(MarketPlaceDuc.creators.setMPOrgListLoadingStatus(false))
	}
}

function* getMarketPlaceGlobalRFQList(action) {
	try {
		const { locationState, virtualAccess } = action
		const query = yield tranformGlobalRfqQueries(locationState)
		const requestUrl = `${getMarketplaceEndPoint()}rfq/trade/search?${query}&virtualOrg=${virtualAccess}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data', 'list']) || []

		const pagination = {
			total: getIn(origResponse, ['data', 'total']),
			nextIndex: getIn(origResponse, ['data', 'nextStartID']),
		}

		yield put(MarketPlaceDuc.creators.setMPOrgListLoadingStatus(true))

		yield put(
			MarketPlaceDuc.creators.getMarketPlaceGlobalRFQListSuccess(response)
		)
		yield put(
			MarketPlaceDuc.creators.setGlobalRFQPaginationQueries(pagination)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(MarketPlaceDuc.creators.setMPOrgListLoadingStatus(false))
	}
}

function* fetchOrgProductDetails(action) {
	try {
		const { id } = action
		const checkMultiple = id.replace(/%2C/g, ',').split(',')
		const urlParams = new URLSearchParams(window.location.search)
		const productID = urlParams.get('productID')
		let requestUrl = `${getMarketplaceEndPoint()}orgproducts`
		let data
		if (checkMultiple.length === 1) {
			requestUrl += `/${id}`
			const response = yield CallWithRefreshCheck(requestUrl)
			data = {
				product: getIn(response, ['data', 'product']) || {},
				defaultUOM: getIn(response, ['data', 'defaultUOM']) || '',
				id: getIn(response, ['data', 'id']) || '',
				organization: [
					getIn(response, ['data', 'meta', 'organization']) || [],
				],
				organizationID: [
					{ id: getIn(response, ['data', 'organizationID']) || '' },
				],
			}
		} else {
			requestUrl += `?productID=${productID}&id=${id}`
			const response = yield CallWithRefreshCheck(requestUrl)
			const organizations = getIn(response, ['data', 'list']).map(
				org => getIn(org, ['meta', 'organization']) || {}
			)
			const organizationIDs = getIn(response, ['data', 'list']).map(
				org => {
					return { id: getIn(org, ['organizationID']) || '' }
				}
			)
			data = {
				product: getIn(response, ['data', 'list', 0, 'product']) || {},
				defaultUOM:
					getIn(response, ['data', 'list', 0, 'defaultUOM']) || '',
				id: getIn(response, ['data', 'list', 0, 'id']) || '',
				organization: organizations,
				organizationID: organizationIDs,
			}
		}
		yield put(MarketPlaceDuc.creators.flushRFQ())

		yield put(AppDuc.creators.showGlobalLoader('fetch-org-prod-details'))

		yield put(MarketPlaceDuc.creators.setOrgProductDetails(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-org-prod-details'))
	}
}

function* fetchRFQListing(payload) {
	try {
		const { rootModule, locationState } = payload
		const { nextStartID } = locationState
		const orgID = yield getOrgIDFromLoggedUser()
		yield put(AppDuc.creators.showGlobalLoader('fetch-rfq-listing'))
		yield put(MarketPlaceDuc.creators.getGlobalProduct())
		let requestUrl
		if (rootModule === 'buyer') {
			requestUrl = `${getMarketplaceEndPoint()}rfqlist?meta=postRequirement-%3Efalse&limit=20&${rootModule}=id->${orgID}&startID=${nextStartID ||
				''}`
		}

		if (rootModule === 'seller') {
			requestUrl = `${getMarketplaceEndPoint()}rfqlist?globalSeller=true&limit=20&${rootModule}=id->${orgID}&startID=${nextStartID ||
				''}`
		}
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data', 'list']) || []

		const pagination = {
			total: getIn(origResponse, ['data', 'total']),
			nextStartID: getIn(origResponse, ['data', 'nextStartID']),
		}

		yield put(MarketPlaceDuc.creators.setRFQList(response, rootModule))
		yield put(
			MarketPlaceDuc.creators.setRFQPaginationQueries(
				pagination,
				rootModule
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-rfq-listing'))
	}
}

function* fetchQuotesListing(location) {
	try {
		const { payload, locationState } = location
		const { nextStartID } = locationState

		const actor = payload === 'seller' ? 'organizationID' : 'buyerID'
		const orgID = yield getOrgIDFromLoggedUser()

		yield put(AppDuc.creators.showGlobalLoader('fetch-rfq-listing'))
		yield put(MarketPlaceDuc.creators.getGlobalProduct())
		const requestUrl = `${getMarketplaceEndPoint()}organizations/-/quote?limit=20&${actor}=${orgID}&startID=${nextStartID ||
			''}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data', 'list']) || []

		const pagination = {
			total: getIn(origResponse, ['data', 'total']),
			nextStartID: getIn(origResponse, ['data', 'nextStartID']),
		}

		yield put(
			MarketPlaceDuc.creators.setQuotesPaginationQueries(
				pagination,
				payload
			)
		)
		yield put(MarketPlaceDuc.creators.setQuoteListing(response, payload))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-rfq-listing'))
	}
}

function* viewRFQ(action) {
	try {
		const { rfqID, edit } = action
		yield put(AppDuc.creators.showGlobalLoader('fetch-rfq-details'))
		yield put(MarketPlaceDuc.creators.flushRFQ())

		const requestUrl = `${getMarketplaceEndPoint()}rfq/${rfqID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		const certificateList =
			getIn(data, ['seller', 'certificationStatus', 'certificates']) || []

		const buyerCertificateList =
			getIn(data, ['buyer', 'certificationStatus', 'certificates']) || []
		const certificates =
			certificateList.map(cert => cert.meta.certificate.issuingBody) || []

		const sellerAddress = `${getIn(data, [
			'seller',
			'primaryAddress',
			'line1',
		]) || ''}, ${getIn(data, ['seller', 'primaryAddress', 'city']) ||
			''} , ${getIn(data, ['seller', 'primaryAddress', 'state']) ||
			''}, ${getIn(data, ['seller', 'primaryAddress', 'country']) || ''}`

		const buyerAddress = `${getIn(data, [
			'buyer',
			'primaryAddress',
			'line1',
		]) || ''}, ${getIn(data, ['buyer', 'primaryAddress', 'city']) ||
			''} , ${getIn(data, ['buyer', 'primaryAddress', 'state']) ||
			''}, ${getIn(data, ['buyer', 'primaryAddress', 'country']) || ''}`

		const organization = getIn(data, ['seller'])

		const quantityInspector = getIn(
			(getIn(data, ['inspection']) || []).filter(
				insp => insp.type === 'quantity'
			),
			[0, 'officer']
		)
		const qualityInspector = getIn(
			(getIn(data, ['inspection']) || []).filter(
				insp => insp.type === 'quality'
			),
			[0, 'officer']
		)

		const thirdPartyInspectionType = (
			getIn(data, ['inspection']) || []
		).map(insp => insp.type)

		const rfqDetails = {
			rfqID: data.id,
			tradeID: data.tradeID,
			rfqNumber: data.rfqNumber,
			seller: data.seller.name,
			sellerID: data.seller.id,
			status: data.status,
			sellerCategory: getIn(data, ['seller', 'categories', 0, 'name']),
			buyer: data.buyer.name,
			buyerID: data.buyer.id,
			buyerCategory: getIn(data, ['buyer', 'categories', 0, 'name']),
			sellerAddress,
			buyerAddress,
			buyerState: getIn(data, ['buyer', 'primaryAddress', 'state']),
			buyerCountry: getIn(data, ['buyer', 'primaryAddress', 'country']),
			product: data.product.name,
			productID: data.product.id,
			productCode: data.product.code,
			quantity: data.quantity,
			uom: data.product.uom,
			destinationPort: data.destinationPort,
			ladingPort: data.ladingPort,
			supplyChainModel: getIn(data, ['supplyChainModel', 'id']) || '',
			payment: data.payment,
			requirementDetails: data.requirementDetails,
			termsAndConditions: getIn(data, ['meta', 'termsAndConditions']),
			inspectionType: data.inspection,
			contractType: data.contractType,
			month: getIn(data, ['shipment', 'month']) || '',
			period: getIn(data, ['shipment', 'period']) || '',
			quantityInspector,
			qualityInspector,
			thirdPartyInspectionType,
			certificates,
			buyerCertificateList,
			organization: [organization],
			quotesID: getIn(data, ['meta', 'quoteID']) || '',
			inviteEmail: getIn(data, ['meta', 'inviteEmail']),
			files: getIn(data, ['meta', 'files']) || [],
			defaultRFQ: getIn(data, ['meta', 'defaultRFQ']),
			rfqAccess: getIn(data, ['meta', 'rfqAccess']) || '',
			postRequirement: getIn(data, ['meta', 'postRequirement']),
		}

		if (edit) {
			yield put(MarketPlaceDuc.creators.setInitialRFQDetails(rfqDetails))
		} else {
			const preferredQuantityInspector = (
				getIn(rfqDetails, ['inspectionType']) || []
			).filter(insp => insp.type === 'quantity')
			const preferredQualityInspector = (
				getIn(rfqDetails, ['inspectionType']) || []
			).filter(insp => insp.type === 'quality')

			const quoteDetails = {
				quantity: getIn(rfqDetails, ['quantity']) || '',
				ladingPort: getIn(rfqDetails, ['ladingPort']) || '',
				destinationPort: getIn(rfqDetails, ['destinationPort']) || '',
				contractType: getIn(rfqDetails, ['contractType']) || '',
				month: getIn(rfqDetails, ['month']) || '',
				period: getIn(rfqDetails, ['period']) || '',
				supplychainModel: {
					id: getIn(rfqDetails, ['supplyChainModel']) || '',
				},
				paymentTerms: getIn(rfqDetails, ['payment']) || '',
				termsAndConditions:
					getIn(rfqDetails, ['termsAndConditions']) || '',
				additionalDetails:
					getIn(rfqDetails, ['requirementDetails']) || '',
				thirdPartyInspection:
					getIn(rfqDetails, ['thirdPartyInspectionType']) || [],
				preferredQuantityInspector:
					getIn(preferredQuantityInspector, [0, 'officer']) || '',
				preferredQualityInspector:
					getIn(preferredQualityInspector, [0, 'officer']) || '',
			}
			yield put(MarketPlaceDuc.creators.setRFQDetails(rfqDetails))
			yield put(MarketPlaceDuc.creators.setQuotesSuccess(quoteDetails))
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-rfq-details'))
	}
}

function* viewRFQAttachments(action) {
	try {
		const { rfqID } = action
		yield put(MarketPlaceDuc.creators.flushRFQDownloadUrl())

		const requestUrl = `${getCoreEndPoint()}entities/${rfqID}/attachments`
		const options = {
			method: 'GET',
		}
		let attachmentsData = yield call(request, requestUrl, options)

		if (attachmentsData.data && attachmentsData.data.length === 0) {
			yield delay(5000)
			attachmentsData = yield call(request, requestUrl, options)
		}

		const downloadUrl =
			attachmentsData.data &&
			attachmentsData.data[0] &&
			attachmentsData.data[0].file.meta.fullURL
				? attachmentsData.data[0].file.meta.fullURL
				: null

		yield put(MarketPlaceDuc.creators.setRFQDownloadUrl(downloadUrl))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* viewGLobalRFQ(action) {
	try {
		const { rfqID, edit } = action
		yield put(AppDuc.creators.showGlobalLoader('fetch-rfq-details'))

		const requestUrl = `${getMarketplaceEndPoint()}rfq/trade/${rfqID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		const certificateList =
			getIn(data, ['seller', 'certificationStatus', 'certificates']) || []

		const buyerCertificateList =
			getIn(data, ['buyer', 'certificationStatus', 'certificates']) || []
		const certificates =
			certificateList.map(cert => cert.meta.certificate.issuingBody) || []

		const sellerAddress = `${getIn(data, [
			'seller',
			'primaryAddress',
			'line1',
		]) || ''}, ${getIn(data, ['seller', 'primaryAddress', 'city']) ||
			''} , ${getIn(data, ['seller', 'primaryAddress', 'state']) ||
			''}, ${getIn(data, ['seller', 'primaryAddress', 'country']) || ''}`

		const buyerAddress = `${getIn(data, [
			'buyer',
			'primaryAddress',
			'line1',
		]) || ''}, ${getIn(data, ['buyer', 'primaryAddress', 'city']) ||
			''} , ${getIn(data, ['buyer', 'primaryAddress', 'state']) ||
			''}, ${getIn(data, ['buyer', 'primaryAddress', 'country']) || ''}`

		const organization = getIn(data, ['seller'])

		const quantityInspector = getIn(
			(getIn(data, ['inspection']) || []).filter(
				insp => insp.type === 'quantity'
			),
			[0, 'officer']
		)
		const qualityInspector = getIn(
			(getIn(data, ['inspection']) || []).filter(
				insp => insp.type === 'quality'
			),
			[0, 'officer']
		)

		const thirdPartyInspectionType = (
			getIn(data, ['inspection']) || []
		).map(insp => insp.type)

		const rfqDetails = {
			rfqID: data.id,
			tradeID: data.tradeID,
			rfqNumber: data.rfqNumber,
			seller: data.seller.name,
			sellerID: data.seller.id,
			status: data.status,
			sellerCategory: getIn(data, ['seller', 'categories', 0, 'name']),
			buyer: data.buyer.name,
			buyerID: data.buyer.id,
			buyerCategory: getIn(data, ['buyer', 'categories', 0, 'name']),
			sellerAddress,
			buyerAddress,
			buyerState: getIn(data, ['buyer', 'primaryAddress', 'state']),
			buyerCountry: getIn(data, ['buyer', 'primaryAddress', 'country']),
			product: data.product.name,
			productID: data.product.id,
			productCode: data.product.code,
			quantity: data.quantity,
			uom: data.product.uom,
			destinationPort: data.destinationPort,
			ladingPort: data.ladingPort,
			supplyChainModel: getIn(data, ['supplyChainModel', 'id']) || '',
			payment: data.payment,
			requirementDetails: data.requirementDetails,
			termsAndConditions: getIn(data, ['meta', 'termsAndConditions']),
			inspectionType: data.inspection,
			contractType: data.contractType,
			month: getIn(data, ['shipment', 'month']) || '',
			period: getIn(data, ['shipment', 'period']) || '',
			quantityInspector,
			qualityInspector,
			thirdPartyInspectionType,
			certificates,
			buyerCertificateList,
			organization: [organization],
			quotesID: getIn(data, ['meta', 'quoteID']) || '',
			inviteEmail: getIn(data, ['meta', 'inviteEmail']),
			files: getIn(data, ['meta', 'files']) || [],
			defaultRFQ: getIn(data, ['meta', 'defaultRFQ']),
			rfqAccess: getIn(data, ['meta', 'rfqAccess']),
			postRequirement: getIn(data, ['meta', 'postRequirement']),
		}

		yield put(MarketPlaceDuc.creators.flushRFQ())

		if (edit) {
			yield put(MarketPlaceDuc.creators.setInitialRFQDetails(rfqDetails))
		} else {
			const preferredQuantityInspector = (
				getIn(rfqDetails, ['inspectionType']) || []
			).filter(insp => insp.type === 'quantity')
			const preferredQualityInspector = (
				getIn(rfqDetails, ['inspectionType']) || []
			).filter(insp => insp.type === 'quality')

			const quoteDetails = {
				quantity: getIn(rfqDetails, ['quantity']) || '',
				ladingPort: getIn(rfqDetails, ['ladingPort']) || '',
				destinationPort: getIn(rfqDetails, ['destinationPort']) || '',
				contractType: getIn(rfqDetails, ['contractType']) || '',
				month: getIn(rfqDetails, ['month']) || '',
				period: getIn(rfqDetails, ['period']) || '',
				supplychainModel: {
					id: getIn(rfqDetails, ['supplyChainModel']) || '',
				},
				paymentTerms: getIn(rfqDetails, ['payment']) || '',
				termsAndConditions:
					getIn(rfqDetails, ['termsAndConditions']) || '',
				additionalDetails:
					getIn(rfqDetails, ['requirementDetails']) || '',
				thirdPartyInspection:
					getIn(rfqDetails, ['thirdPartyInspectionType']) || [],
				preferredQuantityInspector:
					getIn(preferredQuantityInspector, [0, 'officer']) || '',
				preferredQualityInspector:
					getIn(preferredQualityInspector, [0, 'officer']) || '',
			}
			yield put(MarketPlaceDuc.creators.setRFQDetails(rfqDetails))
			yield put(MarketPlaceDuc.creators.setQuotesSuccess(quoteDetails))
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-rfq-details'))
	}
}

function* createRFQFromSeller(action) {
	const { details, successToast } = action
	try {
		const {
			contractType,
			destinationPort,
			quantityInspector,
			qualityInspector,
			payment,
			ladingPort,
			requirementDetails,
			termsAndConditions,
			supplyChainModel,
			quantity,
			uom,
			month,
			period,
			productID,
			product,
			productCode,
			buyerID,
			buyer,
			sellerID,
		} = details
		const requestUrl = `${getMarketplaceEndPoint()}rfq/global`

		const inspection = []

		if (quantityInspector) {
			inspection.push({
				type: 'quantity',
				officer: quantityInspector,
			})
		}

		if (qualityInspector) {
			inspection.push({
				type: 'quality',
				officer: qualityInspector,
			})
		}

		const rfqDetails = {
			contractType,
			destinationPort,
			inspection,
			ladingPort,
			payment,
			product: {
				id: productID,
				name: product,
				code: productCode,
				uom,
			},
			quantity,
			requirementDetails,
			seller: {
				id: sellerID,
			},
			buyer: {
				id: buyerID,
				name: buyer,
			},
			shipment: {
				month,
				period,
			},
			supplychainModel: {
				id: supplyChainModel,
			},
			meta: {
				inviteEmail: false,
				defaultRFQ: true,
				termsAndConditions,
			},
		}
		const options = {
			method: 'POST',
			body: JSON.stringify(rfqDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})

		yield put(MarketPlaceDuc.creators.flushRFQList('seller'))

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MARKETPLACE, {
				rootModule: 'seller',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(MarketPlaceDuc.creators.setRespondToRequirementLoader(false))
	}
}

function* createRFQ(action) {
	const {
		details,
		successToast,
		helpers: { setSubmitting, actor },
	} = action
	try {
		const {
			contractType,
			destinationPort,
			quantityInspector,
			qualityInspector,
			payment,
			ladingPort,
			requirementDetails,
			termsAndConditions,
			supplyChainModel,
			quantity,
			uom,
			month,
			period,
			productID,
			productName,
			productCode,
			templateName,
			saveTemplate,
			buyer = {},
			sellers = [],
		} = details
		const requestUrl = `${getMarketplaceEndPoint()}rfq`
		const templateRequestUrl = `${getMarketplaceEndPoint()}rfq/template`

		const inspection = []

		if (quantityInspector) {
			inspection.push({
				type: 'quantity',
				officer: quantityInspector,
			})
		}

		if (qualityInspector) {
			inspection.push({
				type: 'quality',
				officer: qualityInspector,
			})
		}

		const rfqDetails = sellers.map(seller => {
			return {
				buyer,
				contractType,
				destinationPort,
				inspection,
				ladingPort,
				payment,
				product: {
					id: productID,
					name: productName,
					code: productCode,
					uom,
				},
				quantity,
				requirementDetails,
				seller: {
					name: seller.name,
					id: seller.id,
				},
				shipment: {
					month,
					period,
				},
				supplychainModel: {
					id: supplyChainModel,
				},
				meta: {
					inviteEmail: false,
					defaultRFQ: true,
					termsAndConditions,
				},
			}
		})

		if (saveTemplate && templateName !== '') {
			const templatePayload = {
				productID,
				templateName: templateName.trim(),
				templateObj: rfqDetails[0],
			}

			const templateOptions = {
				method: 'POST',
				body: JSON.stringify(templatePayload),
			}

			yield call(request, templateRequestUrl, templateOptions)
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(rfqDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})

		yield put(MarketPlaceDuc.creators.flushRFQList('buyer'))
		if (actor !== 'palmoil_tdm') {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule: 'buyer',
					}
				)
			)
		} else {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule: 'outgoing',
					},
					{},
					{},
					true
				)
			)
		}
	} catch (err) {
		setSubmitting(false)
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* editRfq(action) {
	const {
		details,
		successToast,
		helpers: { setSubmitting, shouldRedirect = true },
	} = action
	try {
		const {
			contractType,
			destinationPort,
			quantityInspector,
			qualityInspector,
			payment,
			ladingPort,
			requirementDetails,
			termsAndConditions,
			supplyChainModel,
			quantity,
			uom,
			month,
			period,
			productID,
			productName,
			productCode,
			buyer = {},
			sellers = [],
			files = [],
			rfqID,
		} = details

		const inspection = []

		if (quantityInspector) {
			inspection.push({
				type: 'quantity',
				officer: quantityInspector,
			})
		}

		if (qualityInspector) {
			inspection.push({
				type: 'quality',
				officer: qualityInspector,
			})
		}

		const rfqDetails = {
			buyer,
			contractType,
			destinationPort,
			inspection,
			ladingPort,
			payment,
			product: {
				id: productID,
				name: productName,
				code: productCode,
				uom,
			},
			quantity,
			requirementDetails,
			seller: {
				name: sellers[0].name,
				id: sellers[0].id,
			},
			shipment: {
				month,
				period,
			},
			supplychainModel: {
				id: supplyChainModel,
			},
			meta: {
				inviteEmail: false,
				defaultRFQ: true,
				files,
				termsAndConditions,
			},
		}

		const requestUrl = `${getMarketplaceEndPoint()}rfq/${rfqID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(rfqDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		if (shouldRedirect) {
			yield put(
				MarketPlaceDuc.creators.setActiveTabs('buyer', 'enquiries')
			)

			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule: 'buyer',
					}
				)
			)
		} else {
			yield put(MarketPlaceDuc.creators.viewRFQ(rfqID))
		}
	} catch (err) {
		setSubmitting(false)
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* changeRFQStatus(action) {
	try {
		yield put(AppDuc.creators.showGlobalLoader('change-rfq-status'))
		const { rfqID, status } = action
		const requestUrl = `${getMarketplaceEndPoint()}rfq/${rfqID}`
		const statusDetails = {
			status,
		}
		const options = {
			method: 'PUT',
			body: JSON.stringify(statusDetails),
		}
		yield call(request, requestUrl, options)
		yield put(MarketPlaceDuc.creators.viewRFQ(rfqID))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('change-rfq-status'))
	}
}
function* addAttachmentToQuote(action) {
	const { details, successToast, quoteID, rootModule } = action
	try {
		let requestUrl = `${getMarketplaceEndPoint()}organizations/-/quote`
		if (quoteID) {
			requestUrl += `/${quoteID}`
		}
		const options = {
			method: quoteID ? 'PUT' : 'POST',
			body: JSON.stringify(details),
		}

		const { data } = yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		yield put(MarketPlaceDuc.creators.setActiveTabs(rootModule, 'orders'))
		yield put(MarketPlaceDuc.creators.flushQuoteList(rootModule))

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$ACTION,
				{
					rootModule,
					action: 'view-quote',
					documentReference: data.id,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* createQuote(action) {
	const {
		details,
		successToast,
		quoteID,
		helpers: { setSubmitting },
	} = action
	try {
		let requestUrl = `${getMarketplaceEndPoint()}organizations/-/quote`
		if (quoteID) {
			requestUrl += `/${quoteID}`
		}
		const options = {
			method: quoteID ? 'PUT' : 'POST',
			body: JSON.stringify(details),
		}

		const { data } = yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		yield put(MarketPlaceDuc.creators.setActiveTabs('seller', 'orders'))
		yield put(MarketPlaceDuc.creators.flushQuoteList('seller'))

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$ACTION,
				{
					rootModule: 'seller',
					action: 'view-quote',
					documentReference: data.id,
				}
			)
		)
	} catch (err) {
		setSubmitting(false)
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* viewQuote(action) {
	try {
		const { quoteID, payload, status } = action

		const requestUrl = `${getMarketplaceEndPoint()}organizations/-/quote/${quoteID}`

		const options = {
			method: 'GET',
		}

		const { data } = yield call(request, requestUrl, options)

		const attachmentsDataRequestUrl = `${getCoreEndPoint()}entities/${quoteID}/attachments`
		let attachmentsData = yield call(
			request,
			attachmentsDataRequestUrl,
			options
		)

		if (attachmentsData.data && attachmentsData.data.length === 0) {
			yield delay(5000)
			attachmentsData = yield call(
				request,
				attachmentsDataRequestUrl,
				options
			)
		}

		const downloadUrl =
			attachmentsData.data &&
			attachmentsData.data[0] &&
			attachmentsData.data[0].file.meta.fullURL
				? attachmentsData.data[0].file.meta.fullURL
				: null
		yield put(MarketPlaceDuc.creators.setQuoteDownloadUrl(downloadUrl))
		yield put(MarketPlaceDuc.creators.setQuotesSuccess(data))
		if (status) {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{ rootModule: payload },
					{
						quotesID: data.id,
					}
				)
			)
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* viewQuoteAttachments(action) {
	try {
		const { quoteID } = action

		yield put(MarketPlaceDuc.creators.flushQuoteDownloadUrl())

		const requestUrl = `${getCoreEndPoint()}entities/${quoteID}/attachments`
		const options = {
			method: 'GET',
		}

		let attachmentsData = yield call(request, requestUrl, options)

		if (attachmentsData.data && attachmentsData.data.length === 0) {
			yield delay(5000)
			attachmentsData = yield call(request, requestUrl, options)
		}

		const downloadUrl =
			attachmentsData.data &&
			attachmentsData.data[0] &&
			attachmentsData.data[0].file.meta.fullURL
				? attachmentsData.data[0].file.meta.fullURL
				: null
		yield put(MarketPlaceDuc.creators.setQuoteDownloadUrl(downloadUrl))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* changeQuoteStatus(action) {
	try {
		yield put(AppDuc.creators.showGlobalLoader('change-rfq-status'))

		const { quoteID, status } = action

		const requestUrl = `${getMarketplaceEndPoint()}organizations/-/quote/${quoteID}`

		const statusDetails = {
			status,
		}

		const options = {
			method: 'PUT',

			body: JSON.stringify(statusDetails),
		}

		yield call(request, requestUrl, options)
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$ACTION,
				{
					rootModule: 'buyer',
					action: 'view-quote',
					documentReference: quoteID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',

				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('change-rfq-status'))
	}
}

function* convertToPurchaseOrder(action) {
	try {
		const { quoteID } = action
		const requestUrl = `${getMarketplaceEndPoint()}organizations/-/quote/${quoteID}/convert-purchase-order`
		const options = {
			method: 'POST',
		}
		const { data = {} } = yield call(request, requestUrl, options)

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE,
				{
					rootModule: 'purchase-order',
					action: 'attachDetails',
					documentReference: data.id,
					documentStatus: 'draft',
				},
				{},
				{},
				true
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchProductsListing(action) {
	try {
		const { locationState } = action
		const { nextStartID } = locationState
		yield put(AppDuc.creators.showGlobalLoader('fetch-products-listing'))
		const orgID = yield getOrgIDFromLoggedUser()
		const requestUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products?limit=20&startID=${nextStartID ||
			''}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data', 'list']) || []

		const pagination = {
			total: getIn(origResponse, ['data', 'total']),
			nextStartID: getIn(origResponse, ['data', 'nextStartID']),
			tradeAccess: getIn(origResponse, ['data', 'tradeAccess']),
		}

		yield put(
			MarketPlaceDuc.creators.setProductsPaginationQueries(pagination)
		)
		yield put(MarketPlaceDuc.creators.setProductsListing(response))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-products-listing'))
	}
}

function* setAPIBasedOnActor(locationState) {
	try {
		const { location } = locationState
		const { rootModule } = location
		const actor =
			rootModule === 'seller'
				? 'mp_sales_marketing_officer'
				: 'mp_purchase_officer'

		yield put(MarketPlaceDuc.creators.setMarketPlaceActor(actor))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-api-based-on-actor'))
	}
}

function* fetchAPIBasedOnActor(location) {
	try {
		const { payload } = location
		const { rootModule } = payload
		const actor =
			rootModule === 'seller'
				? 'mp_sales_marketing_officer'
				: 'mp_purchase_officer'

		yield put(MarketPlaceDuc.creators.flushRFQList(rootModule))
		yield put(MarketPlaceDuc.creators.flushQuoteList(rootModule))
		yield put(MarketPlaceDuc.creators.flushProductList(rootModule))
		yield put(MarketPlaceDuc.creators.flushGeneralRequirement())

		yield put(MarketPlaceDuc.creators.setMarketPlaceActor(actor))

		if (actor === 'mp_sales_marketing_officer') {
			yield put(MarketPlaceDuc.creators.fetchProductsListing())
		}
		if (actor === 'mp_purchase_officer') {
			yield put(MarketPlaceDuc.creators.fetchGeneralRequirementListing())
		}
		yield put(MarketPlaceDuc.creators.fetchRFQListing(rootModule))
		yield put(MarketPlaceDuc.creators.fetchQuotesListing(rootModule))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-api-based-on-actor'))
	}
}

function* viewProduct(action) {
	try {
		const { prodID } = action
		yield put(AppDuc.creators.showGlobalLoader('view-product'))
		const orgID = yield getOrgIDFromLoggedUser()
		const requestUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products/${prodID}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		yield put(MarketPlaceDuc.creators.setProductDetails(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('view-product'))
	}
}

function* addProduct(action) {
	try {
		const { productDetails, toastMessage } = action

		const payload = (productDetails || []).map(prod => {
			return {
				product: {
					id: prod.id,
				},
				meta: {
					tradeAccess: prod.tradeAccess,
				},
			}
		})

		const orgID = yield getOrgIDFromLoggedUser()

		yield put(AppDuc.creators.showGlobalLoader('add-product'))

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products?limit=100`
		const options = {
			method: 'POST',
			body: JSON.stringify({ products: payload }),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MARKETPLACE, {
				rootModule: 'seller',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('add-product'))
	}
}

function* editProduct(action) {
	try {
		const { details, prodID, toastMessage } = action

		yield put(AppDuc.creators.showGlobalLoader('edit-product'))

		const orgID = yield getOrgIDFromLoggedUser()

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products/${prodID}`

		const options = {
			method: 'PUT',
			body: JSON.stringify(details),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MARKETPLACE, {
				rootModule: 'seller',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('edit-product'))
	}
}

function* publishSupplyAbility(action) {
	try {
		const { details } = action
		const { id, supplyAt, quantity, uom, noOfDays } = details
		const supplyDetails = {
			supplyAt: new Date(supplyAt.setHours(0, 0, 0)),
			quantity,
			uom,
			noOfDays,
		}

		yield put(AppDuc.creators.showGlobalLoader('publish-supply-ability'))

		const requestUrl = `${getMarketplaceEndPoint()}inventory/products/${id}/days/${noOfDays}`

		const options = {
			method: 'POST',
			body: JSON.stringify(supplyDetails),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
		})
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('publish-supply-ability'))
	}
}

function* fetchSupplyAbility(action) {
	try {
		const { id, orgID, startID } = action
		yield put(AppDuc.creators.showGlobalLoader('fetch-supply-ability'))

		let requestUrl = `${getMarketplaceEndPoint()}inventory/organization/-/products?orgProductID=${id}&limit=30&startID=${startID}`
		if (orgID) {
			requestUrl = `${getMarketplaceEndPoint()}inventory/organization/${orgID}/products?&orgProductID=${id}&limit=30&startID=${startID}`
		}
		const options = {
			method: 'GET',
		}

		const { data = {} } = yield call(request, requestUrl, options)

		const supplyAbility = getIn(data, ['list']) || []
		const middleIndex = Math.ceil(supplyAbility.length / 2)

		const firstTable = supplyAbility.splice(0, middleIndex)
		const secondTable = supplyAbility.splice(-middleIndex)

		const details = {
			nextStartID: getIn(data, ['nextStartID']) || '',
			prevStartID: getIn(data, ['prevStartID']) || '',
			firstTable,
			secondTable,
			pageCount: getIn(data, ['pageCount']),
			pageSize: getIn(data, ['pageSize']),
		}
		yield put(MarketPlaceDuc.creators.setSupplyAbility(details))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-supply-ability'))
	}
}

function* shareRFQWithExistingTradePartners(action) {
	try {
		const { partners, rfqID } = action
		const requestUrl = `${getMarketplaceEndPoint()}rfq/trade/sendemail`

		yield put(
			AppDuc.creators.showGlobalLoader(
				'share-rfq-with-existing-trade-partners'
			)
		)

		const payload = {
			recipients: partners,
			rfqId: [rfqID],
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}

		const { data = {} } = yield call(request, requestUrl, options)

		const {
			registeredOrgs = [],
			registeredStatus = false,
			unregisteredStatus = false,
			registeredEmailOrgs = {},
			rfqPayload = {},
		} = data

		const status = {
			registeredStatus,
			unregisteredStatus,
		}

		if (unregisteredStatus === true)
			yield Toast({
				type: 'success',
			})

		if (registeredStatus === true) {
			yield put(
				MarketPlaceDuc.creators.setOrgStatusForShareViaEmail(status)
			)
			yield put(
				MarketPlaceDuc.creators.setRFQPayloadForExistingOrgs(rfqPayload)
			)
			yield put(
				MarketPlaceDuc.creators.fetchOrgsDetails(
					registeredOrgs,
					registeredEmailOrgs
				)
			)
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader(
				'share-rfq-with-existing-trade-partners'
			)
		)
	}
}

function* fetchOrgsDetails(action) {
	const { orgIDs = [], emails = {} } = action
	try {
		const requestURL = `${getIAMEndPoint()}clients/organizations?limit=100&id=${orgIDs.join(
			','
		)}`
		const origResponse = yield CallWithRefreshCheck(requestURL)
		const list = getIn(origResponse, ['data', 'list']) || []
		const organisationList = list.map(org => {
			const state = getIn(org, ['primaryAddress', 'state']) || ''
			const country = getIn(org, ['primaryAddress', 'country']) || ' '
			const place =
				`${state} ${state ? '(State),' : ''} ${country}` || ' '
			const email = Object.keys(emails).find(
				key => emails[key] === org.id
			)

			return {
				email,
				place,
				orgName: getIn(org, ['name']),
				orgID: getIn(org, ['id']),
			}
		})

		yield put(MarketPlaceDuc.creators.setOrglist(organisationList))
		yield put(MarketPlaceDuc.creators.toggleExistingAccountsModal(true))
	} catch (err) {
		const { message } = err

		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* createRFQForExistingOrgs(action) {
	const { payload, toastMessage } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}rfq`
		yield put(
			AppDuc.creators.showGlobalLoader('create-rfq-for-existing-orgs')
		)

		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}

		yield call(request, requestUrl, options)

		Toast({
			type: 'success',
			message: toastMessage,
		})

		yield put(MarketPlaceDuc.creators.flushRFQList('buyer'))

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MARKETPLACE, {
				rootModule: 'buyer',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('create-rfq-for-existing-orgs')
		)
	}
}

function* fetchGeneralRequirementListing(payload) {
	try {
		const { locationState } = payload
		const { nextStartID } = locationState
		const orgID = yield getOrgIDFromLoggedUser()
		yield put(AppDuc.creators.showGlobalLoader('fetch-my-requirement'))
		const requestUrl = `${getMarketplaceEndPoint()}rfqlist?limit=20&meta=postRequirement-%3Etrue&seller=id-%3Eglobal-seller&buyer=id->${orgID}&startID=${nextStartID ||
			''}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data', 'list']) || []

		const pagination = {
			total: getIn(origResponse, ['data', 'total']),
			nextStartID: getIn(origResponse, ['data', 'nextStartID']),
		}

		yield put(
			MarketPlaceDuc.creators.setGeneralRequirementListing(response)
		)
		yield put(
			MarketPlaceDuc.creators.setGeneralRequirementPaginationQueries(
				pagination
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-my-requirement'))
	}
}

function* fetchGlobalProductsNoPagination() {
	try {
		const requestUrl = `${getCoreEndPoint()}products/all?masterCategory=palm-oil&subCategory=palm-oil`
		yield put(
			AppDuc.creators.showGlobalLoader(
				'fetch-global-products-no-pagination'
			)
		)
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data', 'list']) || []

		yield put(
			MarketPlaceDuc.creators.setGlobalProductsNoPagination(response)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader(
				'fetch-global-products-no-pagination'
			)
		)
	}
}

function* postGeneralRequirement(action) {
	try {
		const { emails, allSellers, payload } = action

		const emailRequestUrl = `${getMarketplaceEndPoint()}rfq/trade/sendemail`
		const rfqRequestUrl = `${getMarketplaceEndPoint()}rfq/trade/postrequirement-rfq`
		const templateRequestUrl = `${getMarketplaceEndPoint()}rfq/template`

		yield put(AppDuc.creators.showGlobalLoader('post-my-requirement'))

		const emailDetails = {}

		const emailOptions = {
			method: 'POST',
		}

		const rfqDetails = payload

		const rfqOptions = {
			method: 'POST',
		}

		if (payload.saveTemplate && payload.templateName !== '') {
			const templatePayload = {
				productID:
					payload.product && payload.product.id
						? payload.product.id
						: '',
				templateName: payload.templateName.trim(),
				templateObj: payload,
			}

			const templateOptions = {
				method: 'POST',
				body: JSON.stringify(templatePayload),
			}

			yield call(request, templateRequestUrl, templateOptions)
		}

		if (emails.length > 0) {
			emailDetails.recipients = emails
			emailDetails.rfq = payload
			emailDetails.postRequirement = true
			emailOptions.body = JSON.stringify(emailDetails)
			yield call(request, emailRequestUrl, emailOptions)
		}

		if (allSellers) {
			rfqDetails.meta.postRequirement = true
			rfqOptions.body = JSON.stringify(payload)
			yield call(request, rfqRequestUrl, rfqOptions)
		}

		Toast({
			type: 'success',
		})

		yield put(
			MarketPlaceDuc.creators.setActiveTabs('buyer', 'myRequirement')
		)
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MARKETPLACE, {
				rootModule: 'buyer',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('post-my-requirement'))
	}
}

function* updatePostRequirementStatus(action) {
	const { status, rfqID } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}rfq/${rfqID}`

		const payload = {
			meta: {
				rfqAccess: status,
				postRequirement: false,
			},
		}

		yield put(
			AppDuc.creators.showGlobalLoader('update-post-requirement-status')
		)

		const options = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}

		yield call(request, requestUrl, options)

		Toast({
			type: 'success',
		})

		yield put(MarketPlaceDuc.creators.setActiveTabs('seller', 'enquiries'))
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MARKETPLACE, {
				rootModule: 'seller',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('update-post-requirement-status')
		)
		yield put(MarketPlaceDuc.creators.setRespondToRequirementLoader(false))
	}
}

function* fetchSavedContractDetails(action) {
	const { contractId } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}trade/organization/-/contracts/${contractId}`
		yield put(AppDuc.creators.showGlobalLoader('fetch-contract-details'))
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(MarketPlaceDuc.creators.setSavedContractDetails(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-contract-details'))
	}
}

function* fetchProcessingContractDetails(action) {
	const { userType, contractId, contractTemplateNo } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}trade/organization/-/contracts/${contractId}`
		const { data } = yield CallWithRefreshCheck(requestUrl)
		if (!data.meta.signaturePackageID) {
			// eslint-disable-next-line no-param-reassign
			action.count =
				typeof action.count !== 'undefined' ? action.count + 1 : 0
			if (action.count > 10) {
				throw new Error('Something went wrong')
			}
			yield delay(2000)
			// eslint-disable-next-line no-plusplus, no-param-reassign
			yield* fetchProcessingContractDetails(action)
		} else {
			yield put(
				MarketPlaceDuc.creators.acceptedContractDetails(contractId)
			)
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
					{
						rootModule: userType,
						action: 'e-sign',
						documentReference: data.id,
						documentType: contractTemplateNo,
					}
				)
			)
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MARKETPLACE, {
				rootModule: userType,
			})
		)
	}
}

function* fetchESignDetails(action) {
	const { contractId } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}trade/contracts/signingURL/${contractId}`
		yield put(AppDuc.creators.showGlobalLoader('fetch-e-sign-details'))
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(MarketPlaceDuc.creators.setESignDetails(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-e-sign-details'))
	}
}

function* postContractDetails(action) {
	const { contractDetails, contractTemplateNo } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}trade/contract`
		yield put(AppDuc.creators.showGlobalLoader('post-contract-details'))
		const options = {
			method: 'POST',
			body: JSON.stringify(contractDetails),
		}
		const { data } = yield call(request, requestUrl, options)
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule: 'buyer',
					action: 'processing',
					documentReference: data.id,
					documentType: contractTemplateNo,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('post-contract-details'))
	}
}

function* postAttachedContractDetails(action) {
	const {
		contractDetails,
		contractTemplateNo,
		pdfFileData,
		pdfFileName,
		fileCount,
	} = action
	const formData = new FormData()
	formData.append('file', pdfFileData)
	formData.append('name', pdfFileName)
	try {
		let requestUrl = `${getIAMEndPoint()}files/private`
		yield put(
			AppDuc.creators.showGlobalLoader('post-attached-contract-details')
		)
		let options = {
			method: 'POST',
			headers: {
				'content-type': null,
			},
			body: formData,
			formData: true,
		}
		const { data } = yield call(request, requestUrl, options)
		requestUrl = `${getMarketplaceEndPoint()}trade/contract`
		const details = {
			...contractDetails,
			contractAttachment: data,
			meta: {
				...contractDetails.meta,
				contractModel: 'custom',
				customPageCount: fileCount,
			},
		}
		options = {
			method: 'POST',
			body: JSON.stringify(details),
		}
		const contractData = yield call(request, requestUrl, options)
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule: 'buyer',
					action: 'processing',
					documentReference: contractData.data.id,
					documentType: contractTemplateNo,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('post-attached-contract-details')
		)
	}
}

function* declineContractDetails(action) {
	const { contractId, payload } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}trade/contracts/${contractId}/status/rejected`
		yield put(AppDuc.creators.showGlobalLoader('decline-contract-details'))
		const options = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)
		yield put(MarketPlaceDuc.creators.fetchSavedContractDetails(contractId))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('decline-contract-details'))
	}
}

function* acceptedContractDetails(action) {
	const { contractId } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}trade/contracts/${contractId}/status/accepted`
		yield put(AppDuc.creators.showGlobalLoader('accepted-contract-details'))
		const options = {
			method: 'PUT',
			body: JSON.stringify({ message: '' }),
		}
		yield call(request, requestUrl, options)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('accepted-contract-details'))
	}
}

function* retrySigningDocument(action) {
	const { contractId } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}trade/contract/${contractId}/retry-signing-document`
		yield put(AppDuc.creators.showGlobalLoader('retry-signing-document'))
		const options = {
			method: 'POST',
		}
		yield call(request, requestUrl, options)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('retry-signing-document'))
	}
}

function* initiateEditContractDetails(action) {
	const { contractId } = action
	try {
		const requestUrl = `${getMarketplaceEndPoint()}trade/organization/-/contracts/${contractId}`
		yield put(
			AppDuc.creators.showGlobalLoader('initiate-edit-contract-details')
		)
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)
		const { contract = {} } = data
		const details = {
			basicDeliveryMonth: getIn(contract, ['basicDelivery', 'month']),
			basicDeliveryPeriod: getIn(contract, ['basicDelivery', 'period']),
			brokerEmail: getIn(contract, ['broker', 'primaryContact', 'email']),
			brokerName: getIn(contract, ['broker', 'name']),
			contractNo: getIn(contract, ['contractNo']),
			currency: getIn(contract, ['currency', 'code']),
			date: getIn(contract, ['date']),
			deliveryPeriodMonth: getIn(contract, ['deliveryPeriod', 'month']),
			deliveryPeriodTimeFrame: getIn(contract, [
				'deliveryPeriod',
				'period',
			]),
			description: getIn(contract, ['product', 'description']),
			destination: getIn(contract, ['destination']),
			drum: getIn(contract, ['product', 'meta', 'packagingType']),
			ladingPort: getIn(contract, ['product', 'meta', 'port']),
			month: getIn(contract, ['shipmentPeriod', 'month']),
			packaging: getIn(contract, ['packaging', 'type']),
			paymentTerms: getIn(contract, ['paymentTerms']),
			period: getIn(contract, ['shipmentPeriod', 'period']),
			port: getIn(contract, ['product', 'meta', 'port']),
			product: getIn(contract, ['product', 'product', 'name']),
			productSpecification: getIn(contract, [
				'product',
				'meta',
				'specifications',
			]),
			quantity: getIn(contract, ['quantity']),
			seller: getIn(contract, ['seller']),
			size: getIn(contract, ['packaging', 'size']),
			specialConditions: getIn(contract, ['specialConditions']),
			tradeID: getIn(data, ['tradeID']),
			unitRate: getIn(contract, ['unitRate']),
			unitsPerTwentyContainers: getIn(contract, [
				'packaging',
				'unitsPerTwentyContainers',
			]),
		}
		yield put(MarketPlaceDuc.creators.setEditContractInitialValues(details))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('initiate-edit-contract-details')
		)
	}
}

function* editContract(action) {
	const { contractDetails, contractTemplateNo } = action
	const { id } = contractDetails
	try {
		const requestUrl = `${getMarketplaceEndPoint()}trade/organization/-/contracts/${id}`
		yield put(AppDuc.creators.showGlobalLoader('edit-contract'))
		const details = { ...contractDetails }

		delete details.id

		const options = {
			method: 'PUT',
			body: JSON.stringify(details),
		}
		const { data } = yield call(request, requestUrl, options)
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule: 'buyer',
					action: 'processing',
					documentReference: data.id,
					documentType: contractTemplateNo,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('edit-contract'))
	}
}

function* rfqTemplateSearch(action) {
	const { searchQuery } = action

	try {
		const requestUrl = `${getMarketplaceEndPoint()}rfqtemplatenamevalidation?query=${searchQuery}`
		const options = {
			method: 'GET',
		}
		const response = yield call(request, requestUrl, options)
		yield put(MarketPlaceDuc.creators.setRfqTemplateSearch(response))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('search-rfq-template'))
	}
}

function* rfqTemplateList() {
	try {
		const requestUrl = `${getMarketplaceEndPoint()}rfqtemplatelist?limit=100`
		const options = {
			method: 'GET',
		}
		const response = yield call(request, requestUrl, options)
		yield put(MarketPlaceDuc.creators.setRfqTemplateList(response))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('list-rfq-template'))
	}
}

export default function* MarketPlaceSaga() {
	try {
		yield all([
			takeLatest(
				MarketPlaceDuc.creators.getGlobalProduct().type,
				getGlobalProduct
			),
			takeLatest(
				MarketPlaceDuc.creators.getMarketPlaceOrganisationList().type,
				getMarketPlaceOrganisationList
			),
			takeLatest(
				MarketPlaceDuc.creators.getMarketPlaceGlobalRFQList().type,
				getMarketPlaceGlobalRFQList
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchOrgProductDetails().type,
				fetchOrgProductDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchRFQListing().type,
				fetchRFQListing
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchQuotesListing().type,
				fetchQuotesListing
			),
			takeLatest(MarketPlaceDuc.creators.viewRFQ().type, viewRFQ),
			takeLatest(
				MarketPlaceDuc.creators.viewRFQAttachments().type,
				viewRFQAttachments
			),
			takeLatest(
				MarketPlaceDuc.creators.viewGlobalRFQ().type,
				viewGLobalRFQ
			),
			takeLatest(MarketPlaceDuc.creators.editRfq().type, editRfq),
			takeLatest(MarketPlaceDuc.creators.createRFQ().type, createRFQ),
			takeLatest(
				MarketPlaceDuc.creators.createRFQFromSeller().type,
				createRFQFromSeller
			),
			takeLatest(MarketPlaceDuc.creators.createQuote().type, createQuote),
			takeLatest(
				MarketPlaceDuc.creators.addAttachmentToQuote().type,
				addAttachmentToQuote
			),
			takeLatest(MarketPlaceDuc.creators.viewQuote().type, viewQuote),
			takeLatest(
				MarketPlaceDuc.creators.viewQuoteAttachments().type,
				viewQuoteAttachments
			),
			takeLatest(
				MarketPlaceDuc.creators.changeRFQStatus().type,
				changeRFQStatus
			),
			takeLatest(
				MarketPlaceDuc.creators.changeQuoteStatus().type,
				changeQuoteStatus
			),
			takeLatest(
				MarketPlaceDuc.creators.convertToPurchaseOrder().type,
				convertToPurchaseOrder
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchProductsListing().type,
				fetchProductsListing
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchAPIBasedOnActor().type,
				fetchAPIBasedOnActor
			),
			takeLatest(MarketPlaceDuc.creators.viewProduct().type, viewProduct),
			takeLatest(MarketPlaceDuc.creators.addProduct().type, addProduct),
			takeLatest(MarketPlaceDuc.creators.editProduct().type, editProduct),
			takeLatest(
				MarketPlaceDuc.creators.setAPIBasedOnActor().type,
				setAPIBasedOnActor
			),
			// takeLatest(
			// 	MarketPlaceDuc.creators.getChatThreadByTradeId().type,
			// 	getChatThreadId
			// ),
			// takeLatest(
			// 	MarketPlaceDuc.creators.getChatToken().type,
			// 	getChatToken
			// ),
			// takeLatest(
			// 	MarketPlaceDuc.creators.setMessageReadCount().type,
			// 	setMessageReadCount
			// ),
			takeLatest(
				MarketPlaceDuc.creators.publishSupplyAbility().type,
				publishSupplyAbility
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchSupplyAbility().type,
				fetchSupplyAbility
			),
			takeLatest(
				MarketPlaceDuc.creators.shareRFQWithExistingTradePartners()
					.type,
				shareRFQWithExistingTradePartners
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchOrgsDetails().type,
				fetchOrgsDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.createRFQForExistingOrgs().type,
				createRFQForExistingOrgs
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchGeneralRequirementListing().type,
				fetchGeneralRequirementListing
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchGlobalProductsNoPagination().type,
				fetchGlobalProductsNoPagination
			),
			takeLatest(
				MarketPlaceDuc.creators.postGeneralRequirement().type,
				postGeneralRequirement
			),
			takeLatest(
				MarketPlaceDuc.creators.updatePostRequirementStatus().type,
				updatePostRequirementStatus
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchSavedContractDetails().type,
				fetchSavedContractDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchProcessingContractDetails().type,
				fetchProcessingContractDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.fetchESignDetails().type,
				fetchESignDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.postContractDetails().type,
				postContractDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.postAttachedContractDetails().type,
				postAttachedContractDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.declineContractDetails().type,
				declineContractDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.acceptedContractDetails().type,
				acceptedContractDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.retrySigningDocument().type,
				retrySigningDocument
			),
			takeLatest(
				MarketPlaceDuc.creators.initiateEditContractDetails().type,
				initiateEditContractDetails
			),
			takeLatest(
				MarketPlaceDuc.creators.editContract().type,
				editContract
			),
			takeLatest(
				MarketPlaceDuc.creators.rfqTemplateSearch().type,
				rfqTemplateSearch
			),
			takeLatest(
				MarketPlaceDuc.creators.rfqTemplateList().type,
				rfqTemplateList
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
