export const TABLE_ALIAS = {
	dateSent: 'marketPlace.dateSent',
	dateReceived: 'marketPlace.dateReceived',
	seller: 'marketPlace.seller',
	product: 'marketPlace.product',
	quantity: 'marketPlace.quantity',
	status: 'marketPlace.status',
	buyer: 'marketPlace.buyer',
	country: 'marketPlace.country',
	productName: 'marketPlace.productName',
	qtyTBP: 'marketPlace.qtyTBP',
}

export const MONTHS = {
	january: 'marketPlace.jan',
	february: 'marketPlace.feb',
	march: 'marketPlace.mar',
	april: 'marketPlace.apr',
	may: 'marketPlace.may',
	june: 'marketPlace.jun',
	july: 'marketPlace.jul',
	august: 'marketPlace.aug',
	september: 'marketPlace.sep',
	october: 'marketPlace.oct',
	november: 'marketPlace.nov',
	december: 'marketPlace.dec',
}

export const SHIPMENT_PERIOD = {
	'first-half': 'marketPlace.first-half',
	'second-half': 'marketPlace.second-half',
	'entire-month': 'marketPlace.entire-month',
}

export const INSPECTION_TYPE = {
	quantity: 'marketPlace.quantity',
	quality: 'marketPlace.quality',
}

export const CONTRACT_TYPE = {
	fob: 'marketPlace.fob',
	cif: 'marketPlace.cif',
	'local-delivery': 'marketPlace.local-delivery',
}

export const RFQ_STATUS = {
	pending: 'marketPlace.pending',
	accepted: 'marketPlace.accepted',
	review: 'marketPlace.under-review',
	rejected: 'marketPlace.regret',
	completed: 'marketPlace.completed',
	complete: 'marketPlace.completed',
}

export const STATUS_TEXT_COLOR = {
	pending: '#F09112',
	accepted: '#228686',
	review: '#574D9B',
	rejected: '#BF3333',
	completed: '#158853',
	complete: '#158853',
}

export const STATUS_BACKGROUND = {
	pending: '#FFEED4',
	accepted: '#A1E6E6',
	review: '#D0CCFA',
	rejected: '#F8BFBF',
	completed: '#C9F7C3',
	complete: '#C9F7C3',
}

export const ORG_STATUS_TEXT_COLOR = {
	pending: '#F09112',
	verified: '#158853',
	active: '#228686',
	'in-review': '#574D9B',
	unverified: '#BF3333',
	rejected: '#BF3333',
}

export const ORG_STATUS_BACKGROUND = {
	pending: '#FFEED4',
	verified: '#C9F7C3',
	active: '#A1E6E6',
	'in-review': '#D0CCFA',
	unverified: '#F8BFBF',
	rejected: '#F8BFBF',
}

export const PACKAGING_TYPE = {
	bulk: 'marketPlace.bulk',
	barrel: 'marketPlace.barrel',
	bottle: 'marketPlace.bottle',
	box: 'marketPlace.box',
	can: 'marketPlace.can',
	bags: 'marketPlace.bags',
	bucket: 'marketPlace.bucket',
	carton: 'marketPlace.carton',
	'intermediate-bulk-container': 'marketPlace.intermediate-bulk-container',
	pallet: 'marketPlace.pallet',
	'pallet-box': 'marketPlace.pallet-box',
	pouch: 'marketPlace.pouch',
	tube: 'marketPlace.tube',
}

export const PROFILE_TYPE = {
	'trade-buy-access': 'Buyer',
	'trade-sell-access': 'Seller',
	'trade-buy-sell-access': ' Buyer & Seller',
}

export const headingStyles = {
	color: 'black',
	fontFamily: '"Palatino Linotype", serif',
	fontStyle: 'normal',
	fontWeight: 'bold',
	textDecoration: 'none',
	fontSize: '16px',
}

export const actorStyles = {
	color: 'black',
	fontFamily: '"Palatino Linotype", serif',
	fontStyle: 'normal',
	fontWeight: 'normal',
	textDecoration: 'none',
	fontSize: '13px',
}

export const s1Styles = {
	color: 'black',
	fontFamily: '"Palatino Linotype", serif',
	fontStyle: 'normal',
	fontWeight: 'bold',
	textDecoration: 'none',
	fontSize: '14px',
}

export const paragraphStyles = {
	color: 'black',
	fontFamily: '"Times New Roman", serif',
	fontStyle: 'justify',
	fontWeight: 'normal',
	textDecoration: 'none',
	fontSize: '14px',
	margin: 0,
}

export const contractTableTDStyles = {
	width: '312px',
	border: '1.3px solid',
}

export const contractTableLabelStyles = {
	color: 'black',
	fontFamily: '"Times New Roman", serif',
	fontStyle: 'normal',
	fontWeight: 'normal',
	textDecoration: 'none',
	fontSize: '14px',
	textIndent: '0pt',
}

export const h3Styles = {
	color: 'black',
	fontFamily: '"Times New Roman", serif',
	fontStyle: 'normal',
	fontWeight: 'bold',
	textDecoration: 'none',
	fontSize: '14px',
}

export const liStyles = {
	color: 'black',
	fontFamily: '"Times New Roman", serif',
	fontStyle: 'normal',
	fontWeight: 'normal',
	textDecoration: 'none',
	fontSize: '14px',
}

export const chatAccessBasedOnActor = {
	owner: 'master-chat',
	administrator: 'master-chat',
	'office-management': 'saas-tdm-chat',
	'sales-marketing-officer': 'marketplace-chat',
	'purchase-officer': 'marketplace-chat',
}
