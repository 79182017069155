import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import moment from 'moment'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	MONTHS,
	SHIPMENT_PERIOD,
	headingStyles,
	liStyles,
	h3Styles,
	contractTableLabelStyles,
	contractTableTDStyles,
	actorStyles,
	s1Styles,
	paragraphStyles,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { Box } from 'ui-lib/utils/Box'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { Modal } from 'ui-lib/components/Modal'
import { SmallText, P } from 'ui-lib/components/Typography'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import { DigitalContractMessage } from '../../../../components/DigitalContractMessage'
import '../styles/contract1.css'

const PreviewContractNo9 = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { contractAction } = query
	const { rootModule, documentReference, documentType } = payload
	const [showModal, setShowModal] = useState(false)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const contractDetails = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	if (!contract.quantity && contractAction === 'generate') {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('contracts.editContractText')}
					</P>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.ok')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					dispatch(
						MarketPlaceDuc.creators.editContract(
							contractDetails,
							documentType
						)
					)
				}}
			/>
			<Title
				title={
					contractAction === 'edit'
						? t('contracts.editContractPreview')
						: t('contracts.createContractPreview')
				}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'participants-success',
								documentReference,
								documentType,
							},
							{
								contractAction,
							}
						)
					)
				}}
				small
				isMobile={isMobile}
				hasNoMaxWidth
			/>
			<Spacer size={60} />
			<Box center style={{ background: '#EAEAEB' }}>
				<Box
					width={804}
					style={{ background: theme.color.white }}
					padding={60}
				>
					<Box center>
						<h2 style={headingStyles}>
							THE PALM OIL REFINERS ASSOCIATION OF MALAYSIA
						</h2>
						<h2 style={headingStyles}>
							801C/802A, BLOCK B, EXECUTIVE SUITES,
						</h2>
						<h2 style={headingStyles}>
							KELANA BUSINESS CENTRE, 97, JALAN SS7/2,
						</h2>
						<h2 style={headingStyles}>
							47301 KELANA JAYA, SELANGOR, MALAYSIA.
						</h2>
						<h2 style={headingStyles}>
							CIF CONTRACT FOR PROCESSED PALM OIL PRODUCTS IN
							PACKED FORM
						</h2>
						<h2 style={{ ...headingStyles, marginTop: 20 }}>
							ORIGIN:
						</h2>
					</Box>
					<Spacer size={40} />
					<Box>
						<p
							style={{
								...s1Styles,
								lineHeight: '16px',
								fontWeight: 'normal',
							}}
						>
							Effective
						</p>
						<br />
						<p
							style={{
								...s1Styles,
								lineHeight: '17px',
								fontWeight: 'normal',
							}}
						>
							1 July 2012
						</p>
					</Box>
					<br />
					<h1
						style={{
							margin: '0px 68px 12px 0',
							padding: 0,
							textAlign: isMobile ? 'center' : 'right',
						}}
					>
						9
					</h1>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={440}>
							<div>
								<p style={{ ...actorStyles }}>
									Seller :{' '}
									{getIn(contractDetails, [
										'contract',
										'seller',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Buyer :{' '}
									{getIn(contractDetails, [
										'contract',
										'buyer',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Broker :{' '}
									{getIn(contractDetails, [
										'contract',
										'broker',
										'name',
									])}
								</p>
								<br />
								<br />
								<p
									style={{
										color: 'black',
										fontFamily:
											'"Palatino Linotype", serif',
										fontStyle: 'italic',
										fontSize: 13,
									}}
								>
									* An asterisk denotes alternative wording
									and that not applicable should be deleted.
								</p>
							</div>
						</Box>
						<Box>
							<div
								style={{
									border: '1px solid black',
									padding: '10px',
									height: '148px',
									width: '148px',
									textAlign: 'center',
								}}
							>
								<p style={{ fontSize: '15px' }}>
									<u>Reference No:</u>
								</p>
								<div
									style={{
										overflow: isMobile
											? 'scroll'
											: 'hidden',
									}}
								>
									<table style={{ width: '100%' }}>
										<tbody>
											{getIn(contractDetails, [
												'contract',
												'referenceNo',
											]) ||
												[].map(ref => {
													return (
														<tr key={ref}>
															<td
																style={{
																	paddingTop:
																		'10px',
																}}
															>
																{ref}
															</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</div>
							</div>
						</Box>
					</Box>
					<p>
						<br />
					</p>
					<div>
						<p style={{ ...paragraphStyles, paddingTop: 5 }}>
							The Seller has agreed to sell and the Buyer has
							agreed to buy, the following product(s) on terms and
							conditions as stipulated hereunder:-
						</p>
						<p style={{ textIndent: '0pt', textAlign: 'left' }}>
							<br />
						</p>
					</div>
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								width: 680,
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '36pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Contract No :{' '}
											{getIn(contractDetails, [
												'contract',
												'contractNo',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Date :{' '}
											{`${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('DD MM YYYY')}; ${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('hh:mm A')}`}
										</p>
									</td>
								</tr>
								<tr style={{ height: '35pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Product :{' '}
											{getIn(contractDetails, [
												'contract',
												'product',
												'name',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Quantity in
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											metric tonnes
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Shipment Period
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Destination
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Price (CIF)
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Per metric tonne
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'quantity',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{`${t(
												MONTHS[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'month',
													])
												]
											)}, ${t(
												SHIPMENT_PERIOD[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'period',
													])
												]
											)}`}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'destination',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'unitRate',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '13pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
										</p>
									</td>
								</tr>
								<tr style={{ height: '39pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											Type of Packaging :{' '}
											{getIn(contractDetails, [
												'contract',
												'packaging',
												'type',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											Size :{' '}
											{getIn(contractDetails, [
												'contract',
												'packaging',
												'size',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											No. of units per 20’Container:{' '}
											{getIn(contractDetails, [
												'contract',
												'packaging',
												'unitsPerTwentyContainers',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '13pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												lineHeight: '12pt',
												textAlign: 'left',
											}}
										>
											Product Specifications:
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												lineHeight: '12pt',
												textAlign: 'left',
											}}
										>
											Special Conditions:
										</p>
									</td>
								</tr>
								<tr style={{ height: '52pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'specifications',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'specialConditions',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '87pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												paddingRight: '4pt',
												textIndent: '0pt',
												textAlign: 'justify',
											}}
										>
											The specifications shall be those
											established by The Palm Oil Refiners
											Association of Malaysia (PORAM) as
											standard for the export of these
											products and in force at the date of
											contract and always provided they
											are not in contradiction with the
											above.
										</p>
										<Spacer size={20} />
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												lineHeight: '12pt',
												textAlign: 'justify',
											}}
										>
											The FFA content shall be expressed
											as follows:
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												paddingRight: '210pt',
												textIndent: '0pt',
												lineHeight: '12pt',
												textAlign: 'left',
											}}
										>
											If as palmitic acid, calculated on a
											molecular weight of 256; If as oleic
											acid, calculated on a molecular
											weight of 282.
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						Payment at Seller’s bank in Malaysia
					</p>
					<Spacer size={20} />
					<ul
						style={{
							color: 'black',
							fontFamily: '"Times New Roman", serif',
							fontStyle: 'normal',
							fontWeight: 'normal',
							textDecoration: 'none',
							fontSize: '10pt',
							listStyleType: 'none',
						}}
					>
						<li data-list-text="*">
							<p
								style={{
									marginLeft: 12,
									lineHeight: '11pt',
									textAlign: 'left',
								}}
							>
								(i) in accordance with the provisions of
								paragraph (a) of the Payment Clause;
							</p>
						</li>
						<li data-list-text="*">
							<p
								style={{
									marginLeft: 12,
									textAlign: 'left',
								}}
							>
								(ii) in accordance with the provisions of
								paragraph (b) of the Payment Clause;
							</p>
						</li>
					</ul>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>1.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DEFINITIONS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								For the purposes of this contract:
							</p>
							<Spacer size={20} />
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 16,
									}}
								>
									a.
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 238 : 640,
									}}
								>
									“Product” shall refer to processed palm oil.
								</p>
							</Box>
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 16,
									}}
								>
									b.
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 238 : 640,
									}}
								>
									“Processed Palm Oil” shall include any palm
									oil fractions, and products made from palm
									oil such as cooking oil, cooking fats,
									shortening, margarine, soaps, cocoa butter
									substitute (CBS), cocoa butter equivalent
									(CBE), cocoa butter replacer (CBR).
								</p>
							</Box>
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 16,
									}}
								>
									c.
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 238 : 640,
									}}
								>
									“Packed Form” means either drums, tins,
									cartons, poly lined paper bags,
									polypropylene (PP) woven bags, polyethylene
									(PE) lined bags in cartons, polyethylene
									terephthalate (PET), jerrycans, flexibags,
									box, intermediate bulk container (IBC),
									isotanks, plastic tubs/pails, sachets, bags,
									bag in box (BIB).
								</p>
							</Box>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>2.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUANTITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Seller has the option of shipping 3% more or
								less of the contract quantity. Such variance
								shall be settled at the contract price.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>3.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUALITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								At time and place of sampling, the product shall
								be of good merchantable quality within the
								meaning of the description and specifications
								stated in the preamble. Unless so stated, or
								otherwise agreed between the parties and
								confirmed in writing, the product is not
								warranted to be suitable for any specific
								end-use or to conform to any particular
								analytical criteria not mentioned above.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>4.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>PRICE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								The trade term of the price in this contract is
								governed by INCOTERMS 2010.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>5.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>
								SHIPMENT AND CLASSIFICATION
							</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Shipment to be made in good condition, direct or
								indirect, with or without transshipment in
								ship(s) (tankers excluded), classified in
								Llyod’s Register or recognised conference lines
								of equivalent class. For the purposes of this
								contract, the word `ship' or `ships' shall mean
								any full-powered engine-driven ship. In the
								event shipment is by container, fit and suitable
								containers and freight space shall be provided
								at the port of loading.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>6.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DECLARATION OF SHIPMENT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Notice stating the ship's name, date of Bill(s)
								of Lading, and quantity shipped shall be
								despatched by Seller to Buyer not later than 10
								calendar days from the date of the Bill(s) of
								Lading. The date of the "on board" Bill(s) of
								Lading shall be considered proof of the date of
								shipment in the absence of conclusive evidence
								to the contrary. Notice shall be deemed to be
								under reserve for errors and/or delays in
								transmission. Any slight variation in the ship's
								name shall not invalidate the declaration.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>7.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>EXTENSION OF SHIPMENT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the shipment period does not exceed 31
								calendar days, if Seller is unable to secure
								suitable container space, an additional of 15
								calendar days, at the request of Seller, be
								extended provided notice is given to Buyer of
								the intention not later than the first business
								day after the end of the original shipment
								period. Seller shall at the same time nominate
								the ship he intends to load and shall provide,
								together with shipping documents satisfactory
								evidence that the ship was originally booked for
								shipment within the original shipment period.
								Should Seller not request the above extension
								and fails to ship within the shipment period,
								Seller shall be deemed to be in default
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								WIn the event that Seller requested for the
								extension and fails to ship within the extended
								period, Seller is deemed to be in default.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>8.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SHIPPING INSTRUCTIONS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Buyer shall provide Seller with the following
								instructions within 7 calendar days from the
								date of contract:-
							</p>
							<Spacer size={20} />
							<ul
								style={{
									color: 'black',
									fontFamily: '"Times New Roman", serif',
									fontStyle: 'normal',
									fontWeight: 'normal',
									textDecoration: 'none',
									fontSize: '10pt',
									listStyleType: 'none',
								}}
							>
								<li>
									<p
										style={{
											textAlign: 'left',
											marginLeft: 24,
										}}
									>
										(a) &nbsp;&nbsp;Shipping marks and
										numbers
									</p>
								</li>
								<li>
									<p
										style={{
											textAlign: 'left',
											marginLeft: 24,
										}}
									>
										(b) &nbsp;&nbsp;Notifying party
									</p>
								</li>
								<li>
									<p
										style={{
											textAlign: 'left',
											marginLeft: 24,
										}}
									>
										(c) &nbsp;&nbsp;Buyer’s banker’s name
										and address in event of payment being
										cash on presentation of documents.
									</p>
								</li>
								<li>
									<p
										style={{
											textAlign: 'left',
											marginLeft: 24,
										}}
									>
										(d) &nbsp;&nbsp;Any other documents
										required for goods entitled to free
										entry or preferential duty at port of
										destination
									</p>
								</li>
								<li>
									<p
										style={{
											textAlign: 'left',
											marginLeft: 24,
										}}
									>
										(e) &nbsp;&nbsp;Consignee – For
										non-Letter of Credit term, seller holds
										the ownership of the goods unless full
										payment has been received by seller.
										Seller is allowed to consign Bill of
										Lading to order if goods have not been
										fully paid-for in advance.
									</p>
								</li>
							</ul>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								If more than one delivery is called for, each
								delivery is to be considered a separate contract
								for the purposes of furnishing complete shipping
								instructions by buyer.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>9.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>INSURANCE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Seller shall insure the products as per
								Institute Cargo Clauses (All Risks), which shall
								include risks of contamination and leakage
								irrespective of percentage of each package or on
								the whole, the risk of war, strikes, riots,
								civil commotion and such insurance is to be
								effected with first class Underwriters for 110%
								of the value shipped.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>10.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>WAR RISKS INSURANCE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								War Risks Insurance shall be effected on the
								terms and conditions in force and approved at
								the time of shipment by the Institute of London
								Underwriters. Any expense for covering war risks
								insurance in excess of ½% shall be for account
								of Buyer. The rate of insurance shall not exceed
								the rate ruling in London at the time of
								shipment or date of ship's sailing whichever may
								be adopted by Underwriters. Notice of extra
								expenses to be borne by Buyer shall be given by
								Seller at the time of declaration under this
								contract or not later than 3 business days after
								the rate has been agreed with Underwriters
								whichever is the later. Failure to give such
								notice shall invalidate Seller’s claim unless in
								the opinion of arbitrators the delay is
								justifiable.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>11.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SAMPLING AND ANALYSIS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Unless otherwise agreed, representative samples
								of the product shall be drawn from 10% of the
								total quantity of the packed products selected
								at random by independent surveyors at Seller’s
								premises.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								The sample so drawn shall be in quadruplicate (1
								for contractual analysis, 1 for the shipper and
								2 to be retained by the surveyors) of the
								product, at time of weighing.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Buyer has the right to be represented at his own
								cost at time of sampling at Seller’s premises to
								draw samples jointly with Seller’s
								representatives. If Buyer is so represented only
								samples drawn and sealed jointly shall be
								accepted as official contract samples for
								analysis purposes.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event of any dispute on quality analysis,
								the samples retained by the surveyors shall be
								referred to the Malaysian Palm Oil Board (MPOB)
								laboratory, whose analysis shall be final. The
								samples shall be retained for a duration of 3
								months from the date of Bill of Lading unless
								otherwise agreed.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>12.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>PAYMENT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Payment shall be made as stipulated in the
								preamble for 100% of the invoice value against a
								complete set of shipping documents.
							</p>
							<Spacer size={20} />
							<Box row={!isMobile} width={isMobile ? 238 : 640}>
								<p style={{ ...paragraphStyles }}>*(a)</p>
								<Box>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										By irrevocable and confirmed Letter of
										Credit unrestricted for negotiation
										established in Seller’s favour through a
										recognized bank for 103% of the mean
										contract quantity. Unless otherwise
										agreed between the parties, such credit
										shall be established in a good
										operative/negotiable order and available
										to Seller not later than 10 calendar
										days from date of contract. Should the
										credit be opened on terms inconsistent
										with the contract, Seller may demand
										amendments which shall be arranged by
										Buyer and notified to Seller through the
										credit opening bank within 7 calendar
										days of the demand being received. The
										Letter of Credit shall provide for the
										following:-
									</p>
									<Spacer size={20} />
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(i) &nbsp;&nbsp;&nbsp;Negotiating bank
										is allowed to claim reimbursement by
										telegraphic transfer
									</p>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(ii) &nbsp;&nbsp;Partial shipment
										allowed
									</p>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(iii) &nbsp;Shipment on Deck allowed, or
										unless specifically stated otherwise
									</p>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										(iv) &nbsp;&nbsp;Name of reimbursing
										bank and Letter of Credit issuing bank’s
										account number maintains with the
										reimbursing bank
									</p>
								</Box>
							</Box>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								*(b)
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by
								cash on presentation of documents.
							</p>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								Seller shall present documents to Buyer through
								a bank nominated by Buyer. All bank charges at
								Seller’s bank are for Seller’s account and all
								bank charges at Buyer’s bank are for Buyer’s
								account. Buyer shall arrange payment by
								telegraphic transfer (TT) at Buyer’s cost.
							</p>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								Unless otherwise agreed shipping documents shall
								consist of the following in triplicate :-
							</p>
							<Spacer size={20} />
							<Box width={isMobile ? 238 : 640}>
								<p
									style={{
										...paragraphStyles,
									}}
								>
									1. Commercial invoice;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									2. Full set of clean and prepaid ‘on board'
									Bill(s) of Lading, to be certified by a
									recognized bank if required by the Buyer;
								</p>

								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									3. Policy of Insurance Certificate in the
									currency of contract, to be certified by a
									recognized bank if required by the Buyer;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									4. Certificate of analysis, issued by a
									recognised independent laboratory or
									Seller’s laboratory;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									5. Certificate of Origin.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Buyer is to accept certified photostate
									copies of items (3) and (4) relating to the
									shipment.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Should documents be presented with
									incomplete set(s) of Bill(s) of Lading,
									payment shall be made provided that delivery
									of such Bill(s) of Lading be guaranteed by
									Seller. Such guarantee is to be endorsed, if
									required by Buyer, by a recognized bank.
									Acceptance of this guarantee shall not
									prejudice Buyer’s rights under this
									contract.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									No clerical error in the documents shall
									entitle Buyer to reject or to delay payment
									provided Seller to furnish at the request of
									Buyer a guarantee, to be countersigned by a
									recognized bank, if required by Buyer,
									Seller shall be responsible for any loss or
									expense incurred by Buyer on account of such
									error.
								</p>
							</Box>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>13.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DUTIES, TAXES, ETC</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								All export duties, levies etc., present or
								future in country of origin/port of shipment
								shall be for Seller’s account.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								All import duties, taxes, levies, licenses etc.,
								present or future in port of discharge/country
								of destination shall be for Buyer’s
								responsibility and account.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Demurrages arising from Buyer’s inability to
								discharge the cargo on time or to take delivery
								of the cargo, other terminal charges at
								discharging port, or liner’s detention charges,
								if any shall be borne by the Buyer
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the goods are entitled to free or
								preferential duty at port of destination named
								in this contract, Seller shall furnish together
								with the shipping documents a Certificate of
								Origin and/or other necessary document(s) in the
								form valid at time of shipment as per Shipping
								Instruction under clause 7(d), failing which,
								Seller shall be responsible for any extra duty
								incurred by Buyer through the non-production of
								such certificate and/or documents.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>14.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>NOTICES</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the terms of the contract require notice
								to be given, such notice shall be despatched by
								cable, telegram, e-mail, fascimile, post or
								courier service or delivered by hand within the
								time limit specified in the contract. All
								notices shall be under reserve for errors and
								omissions in transmission or delivery. Any
								notice received after 1600 hours Malaysian time
								on a business day shall be deemed to have been
								received on the following business day.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>15.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>NON-BUSINESS DAYS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the time limit for doing any act or
								giving any notice expire on a Saturday, Sunday
								or any National Gazetted Public Holiday in
								Malaysia, the time so limited shall be extended
								until the first business day thereafter. All
								business days shall be deemed to end 1600 hours
								Malaysian time Monday to Friday inclusive. This
								clause shall however not be applicable to the
								contract period.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>16.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>ODD DAYS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In any month containing an odd number of days,
								the middle day shall be reckoned as belonging to
								both halves of the month.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>17.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>
								DEFAULT AND/OR NON-PERFORMANCE
							</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								If Buyer (i) fails to furnish shipping
								instructions within the time specified, (ii)
								refuses to accept any shipment properly tendered
								hereunder, (iii) fails to tender any payment
								hereunder when due, or (iv) fails to perform in
								any other respects according to its obligations
								set out in the terms herein (each of which shall
								be a material breach of contract), Seller may
								treat such default as (a) a total breach of the
								entire Contract, (b) partial breach of Contract
								(e.g. a breach only as to the individual
								shipment or instalment) and/or (c) shipment not
								made within stipulated period where all
								conditions have been met.
							</p>
							<Spacer size={20} />
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 8,
									}}
								>
									(a)
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 238 : 640,
									}}
								>
									Non-Fulfilment
								</p>
							</Box>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginLeft: 24,
									width: 600,
								}}
							>
								In the event of non-compliance by either Party,
								the other Party at his discretion shall, after
								giving notice, have the right to cancel the
								contract on basis of the market price at the
								date of default. In case of any dispute over the
								date of default or on the market price on that
								day, the matter shall be referred to
								Arbitration. The following procedure shall be
								adopted:
							</p>
							<Spacer size={20} />
							<Box row={!isMobile} style={{ marginLeft: 24 }}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 16,
									}}
								>
									i.
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 215 : 580,
									}}
								>
									If the Seller is in default and if at the
									date of default the market price of the
									contracted product to be shipped is higher
									than the contract price, the Seller shall be
									charged with the difference between the
									market price and contract price.
								</p>
							</Box>
							<Spacer size={20} />
							<Box row={!isMobile} style={{ marginLeft: 24 }}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 14,
									}}
								>
									ii.
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 215 : 580,
									}}
								>
									If the Buyer is in default and if at the
									date of default the market price of the
									contracted product to be shipped is lower
									than the contract price, the Buyer shall be
									charged with the difference between market
									price and contract price.
								</p>
							</Box>
							<Spacer size={20} />
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 8,
									}}
								>
									(b)
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 238 : 640,
									}}
								>
									Non-Payment
								</p>
							</Box>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginLeft: 24,
									width: 600,
								}}
							>
								Not withstanding all provisions set out in
								clause 13, the Seller has the right to put the
								Buyer in default for the delivery(ies) made but
								not paid for.
							</p>
							<Spacer size={20} />
							<Box row={!isMobile} style={{ marginLeft: 24 }}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 16,
									}}
								>
									i.
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 215 : 580,
									}}
								>
									In the event of non-payment at sight by the
									Buyer for documents complying with
									conditions of the contract, the Seller may
									give the Buyer formal notice to effect
									payment within three business days.
								</p>
							</Box>
							<Spacer size={20} />
							<Box row={!isMobile} style={{ marginLeft: 24 }}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 14,
									}}
								>
									ii.
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 215 : 580,
									}}
								>
									If payment is not made within that period,
									the Seller shall put Buyer on notice that
									Seller has the right either to cancel the
									contract or the right to sell the goods
									against the defaulter who shall on demand
									make good the loss, if any, on such sale.
								</p>
							</Box>
							<Spacer size={20} />
							<Box row={!isMobile} style={{ marginLeft: 24 }}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 14,
									}}
								>
									iii.
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 215 : 580,
									}}
								>
									If payment is not made within that period,
									Seller reserves the right to refuse further
									shipments to Buyer, or cancel unshipped
									balance without prejudice to all other
									rights that the Seller may have.
								</p>
							</Box>
							<Spacer size={20} />
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										marginRight: 8,
									}}
								>
									(c)
								</p>
								<p
									style={{
										...paragraphStyles,
										textAlign: 'justify',
										width: isMobile ? 215 : 580,
									}}
								>
									Date of Default
								</p>
							</Box>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									marginLeft: 24,
									width: 600,
								}}
							>
								Prior to the last day of the contract shipment
								period, either party may notify the other party
								of its inability to ship or take shipment but
								the date of such notice shall not become the
								default date without the agreement of the other
								party. If, for any other reason, either party
								fails to fulfil this contract and is declared to
								be in default by the other party and default is
								either agreed between the parties or
								subsequently found by arbitrators to have
								occurred, then the date of the default shall,
								failing amicable settlement, be decided by
								arbitration.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>18.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>INSOLVENCY/BANKRUPTCY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								If before the fulfilment of this contract either
								party shall suspend payment, commit an act of
								bankruptcy, notify any of his creditors that he
								is unable to meet his debts or that he has
								suspended payments or that he is about to
								suspend payment of his debts, convene, call or
								hold a meeting either of his creditors or to
								pass a resolution to go into liquidation (except
								for voluntary winding up of a solvent company
								for the purpose of reconstruction or
								amalgamation) or shall apply for an official
								moratorium, have a petition presented for
								winding up or shall have a Receiver appointed,
								he shall be deemed to be and shall be treated as
								being at default and the contract shall
								forthwith be closed, either at the market price
								then current for similar goods or, at the option
								of the other party, at a price to be ascertained
								by repurchase or resale and the difference
								between the contract price and such closing-out
								price shall be the amount which the other party
								shall be entitled to claim or shall be liable to
								account for under this contract. Should either
								party be dissatisfied with the price, the matter
								shall be referred to arbitration. Where no such
								resale or repurchase takes place, the
								closing-out price shall be determined by a Price
								Settlement Committee of The Palm Oil Refiners
								Association of Malaysia.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>19.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>PROHIBITION</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event, during the contract period, of
								prohibition of export or any other executive or
								legislative act by or on behalf of the
								Government of the country of origin or of the
								territory where the port(s) of shipment named
								herein is/are situated, or of blockade or
								hostilities, restricting export whether
								partially or otherwise, any such restriction
								shall be deemed by both parties to apply to the
								contract and to the extent of such total or
								partial restriction to prevent fulfilment
								whether by shipment or by any other means
								whatsoever and to that extent the contract or
								any unfulfilled portion thereof shall be
								extended by 30 calendar days.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event of shipment during the extended
								period still proved impossible by reason of any
								of the causes in this clause the contract or any
								unfulfilled part thereof shall be cancelled.
								Seller invoking this clause shall advise Buyer
								with due despatch. If required, Seller must
								produce proof to justify his claim for extension
								or cancellation under this clause.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>20.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>FORCE MAJEURE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the performance of the contract be
								prevented by reason of fire, strikes, lockouts,
								riots, civil commotion, floods and/or any cause
								comprehended in the term force majeure, the
								contract period shall be extended for a period
								equal to the duration of the disabling event but
								not exceeding a period of 60 calendar days. If
								the force majeure event ends within 21 calendar
								days preceding the end of the extended period,
								then a further 21 calendar days shall be allowed
								after the termination of the force majeure
								event.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the fulfilment of the contract not be
								possible within the extended period, the
								contract or any unfulfilled part thereof shall
								be deemed to be null and void at the end of such
								extended period.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>21.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DOMICILE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								The contract shall be deemed to have been made
								in Malaysia and the construction, validity and
								performance thereof shall be governed in all
								respect by Malaysian Law, which shall have
								exclusive jurisdiction wherever the domicile,
								residence or place of business of the parties to
								the contract may be or become.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>22.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>ARBITRATION</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Any dispute arising out of this contract,
								including any question of law arising in
								connection therewith, shall be referred to
								arbitration in Malaysia (at the PORAM
								Secretariat or elsewhere if so agreed), in
								accordance with the PORAM Rules of Arbitration
								and Appeal in force at the date of the
								initiation of the arbitration with the parties
								hereby agreeing that the arbitration shall be
								deemed an International Arbitration. The seat of
								the arbitration shall be Malaysia and the
								Malaysian Arbitration Act 2005 (“the Act”) or
								any reenactment thereof shall apply to any
								arbitration under this contract. The parties
								hereto further agree that Part III of the Act
								shall not apply to any arbitration under this
								contract.
							</p>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Neither party hereto, nor any persons claiming
								under either of them shall bring any action or
								other legal proceedings against the other of
								them in respect of any such dispute until such
								dispute shall first have been heard and
								determined by the Sole Arbitrator/Panel of
								Arbitrators/Appeal Board (as the case may be),
								in accordance with the PORAM Rules of
								Arbitration and Appeal and it is hereby
								expressly agreed and declared that the obtaining
								of an award from the Sole Arbitrator/Panel of
								Arbitrators/Appeal Board (as the case may be),
								shall be a condition precedent to the right of
								either party hereto or of any person claiming
								under either of them to bring any action or
								other legal proceedings against the other of
								them in respect of such dispute.
							</p>
						</Box>
					</Box>
					<Box
						row={!isMobile}
						justifyContent="space-around"
						margin="28px 0 0"
					>
						<Box>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								As Seller:
							</p>
							<Spacer size={60} />
							<p
								style={{
									...paragraphStyles,
									borderBottom: '1px solid black',
								}}
							/>
							<Spacer size={16} />
							<p
								style={{
									...paragraphStyles,
								}}
							>
								(Authorized Signatory)
							</p>
							<Spacer size={16} />
							<p
								style={{
									...paragraphStyles,
								}}
							>
								Name:
							</p>
							<Spacer size={16} />
							<p
								style={{
									...paragraphStyles,
								}}
							>
								Company Seal
							</p>
						</Box>
						<Box>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								As Broker:
							</p>
							<Spacer size={60} />
							<p
								style={{
									...paragraphStyles,
									borderBottom: '1px solid black',
								}}
							/>
							<Spacer size={16} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								(Authorized Signatory)
							</p>
							<Spacer size={16} />
							<p
								style={{
									...paragraphStyles,
								}}
							>
								Name:
							</p>
							<Spacer size={16} />
							<p
								style={{
									...paragraphStyles,
								}}
							>
								Company Seal
							</p>
						</Box>
						<Box>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								As Buyer:
							</p>
							<Spacer size={60} />
							<p
								style={{
									...paragraphStyles,
									borderBottom: '1px solid black',
								}}
							/>
							<Spacer size={16} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								(Authorized Signatory)
							</p>
							<Spacer size={16} />
							<p
								style={{
									...paragraphStyles,
								}}
							>
								Name:
							</p>
							<Spacer size={16} />
							<p
								style={{
									...paragraphStyles,
								}}
							>
								Company Seal
							</p>
						</Box>
					</Box>
				</Box>
				<Box
					style={{
						width: isMobile ? 350 : 800,
						padding: 15,
						margin: '40px 0',
						alignItems: 'center',
						background: theme.color.white,
						justifyContent: 'space-between',
					}}
					row={!isMobile}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '60%',
							alignItems: 'end',
							marginRight: '5%',
							padding: '15px 10px',
							background: theme.color.white,
						}}
					>
						<SmallText
							style={{
								fontSize: 14,
								color: '#8D93A0',
								lineHeight: '19px',
								textAlign: 'justify',
							}}
						>
							{t('contracts.proceedToESignText')}
						</SmallText>
					</Box>

					<Button
						rounded
						label={t('contracts.proceedToESign')}
						reverse
						onClick={() => {
							if (contractAction === 'edit') {
								setShowModal(true)
							} else {
								dispatch(
									MarketPlaceDuc.creators.postContractDetails(
										contractDetails,
										documentType
									)
								)
							}
						}}
						extendStyles={{
							width: 266,
							background: '#3F56C4',
							color: theme.color.white,
						}}
					/>
				</Box>
				<DigitalContractMessage
					width={isMobile ? 350 : 800}
					margin="0 0 40px 0"
				/>
			</Box>
		</>
	)
}

export { PreviewContractNo9 }
