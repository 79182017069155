import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	MONTHS,
	SHIPMENT_PERIOD,
	headingStyles,
	contractTableLabelStyles,
	contractTableTDStyles,
	liStyles,
	h3Styles,
	actorStyles,
	s1Styles,
	paragraphStyles,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { Modal } from 'ui-lib/components/Modal'
import { SmallText, P } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import { DigitalContractMessage } from '../../../../components/DigitalContractMessage'

const PreviewContractNo3 = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { contractAction } = query
	const { rootModule, documentReference, documentType } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [showModal, setShowModal] = useState(false)

	const contractDetails = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	if (!contract.quantity && contractAction === 'generate') {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('contracts.editContractText')}
					</P>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.ok')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					dispatch(
						MarketPlaceDuc.creators.editContract(
							contractDetails,
							documentType
						)
					)
				}}
			/>
			<Title
				title={
					contractAction === 'edit'
						? t('contracts.editContractPreview')
						: t('contracts.createContractPreview')
				}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'participants-success',
								documentReference,
								documentType,
							},
							{
								contractAction,
							}
						)
					)
				}}
				small
				isMobile={isMobile}
				hasNoMaxWidth
			/>
			<Spacer size={60} />
			<Box center style={{ background: '#EAEAEB' }}>
				<Box
					width={800}
					style={{ background: theme.color.white }}
					padding={60}
				>
					<Box center>
						<h2 style={headingStyles}>
							THE PALM OIL REFINERS ASSOCIATION OF MALAYSIA
						</h2>
						<h2 style={headingStyles}>
							801C/802A, BLOCK B, EXECUTIVE SUITES,
						</h2>
						<h2 style={headingStyles}>
							KELANA BUSINESS CENTRE, 97, JALAN SS7/2,
						</h2>
						<h2 style={headingStyles}>
							47301 KELANA JAYA, SELANGOR, MALAYSIA.
						</h2>
						<h2 style={headingStyles}>
							Domestic Contract for Malaysian Processed Palm Oil
							in Bulk
						</h2>
						<h2 style={headingStyles}>
							(For Delivery/Collection within Malaysia including
							Singapore)
						</h2>
					</Box>
					<Spacer size={40} />
					<p
						style={{
							...s1Styles,
							fontWeight: 'normal',
							lineHeight: '16px',
						}}
					>
						Revised and Effective
					</p>
					<br />
					<p
						style={{
							...s1Styles,
							fontWeight: 'normal',
							lineHeight: '17px',
						}}
					>
						1 February 2012
					</p>
					<br />
					<h1
						style={{
							margin: '0px 68px 12px 0',
							padding: 0,
							textAlign: isMobile ? 'center' : 'right',
						}}
					>
						3
					</h1>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={440}>
							<div>
								<p style={{ ...actorStyles }}>
									Seller :{' '}
									{getIn(contractDetails, [
										'contract',
										'seller',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Buyer :{' '}
									{getIn(contractDetails, [
										'contract',
										'buyer',
										'name',
									])}
								</p>
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Broker :
									{getIn(contractDetails, [
										'contract',
										'broker',
										'name',
									])}
								</p>
								<br />
								<br />
								<p
									style={{
										color: 'black',
										fontFamily:
											'"Palatino Linotype", serif',
										fontStyle: 'normal',
										fontSize: 13,
									}}
								>
									* An asterisk denotes alternative wording
									and that not applicable should be deleted.
								</p>
							</div>
						</Box>
						<Box>
							<div
								style={{
									border: '1px solid black',
									padding: '10px',
									height: '140px',
									width: '148px',
									textAlign: 'center',
								}}
							>
								<p style={{ fontSize: '15px' }}>
									<u>Reference No:</u>
								</p>
								<div
									style={{
										overflow: isMobile
											? 'scroll'
											: 'hidden',
									}}
								>
									<table style={{ width: '100%' }}>
										<tbody>
											{getIn(contractDetails, [
												'contract',
												'referenceNo',
											]) ||
												[].map(ref => {
													return (
														<tr key={ref}>
															<td
																style={{
																	paddingTop:
																		'10px',
																}}
															>
																{ref}
															</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</div>
							</div>
						</Box>
					</Box>
					<p>
						<br />
					</p>
					<div>
						<p style={{ ...paragraphStyles, paddingTop: 5 }}>
							The Seller has agreed to sell and the Buyer has
							agreed to buy, the following product(s) on terms and
							conditions as stipulated hereunder:-
						</p>
						<p style={{ textIndent: '0pt', textAlign: 'left' }}>
							<br />
						</p>
					</div>
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								width: 680,
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '36pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Contract No :{' '}
											{getIn(contractDetails, [
												'contract',
												'contractNo',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Date :{' '}
											{`${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('DD MM YYYY')}; ${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('hh:mm A')}`}
										</p>
									</td>
								</tr>
								<tr style={{ height: '35pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Description of Oil :{' '}
											{getIn(contractDetails, [
												'contract',
												'product',
												'description',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
												paddingTop: 4,
											}}
										>
											Quantity in
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
												paddingTop: 4,
											}}
										>
											MT
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
												paddingTop: 4,
											}}
										>
											Delivery/Collection
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Period
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
												paddingTop: 4,
											}}
										>
											Basic
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Delivery/Collection
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
												paddingTop: 4,
											}}
										>
											Price
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											PMT
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'quantity',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{`${t(
												MONTHS[
													getIn(contractDetails, [
														'contract',
														'deliveryPeriod',
														'month',
													])
												]
											)}, ${t(
												SHIPMENT_PERIOD[
													getIn(contractDetails, [
														'contract',
														'deliveryPeriod',
														'period',
													])
												]
											)}`}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{`${t(
												MONTHS[
													getIn(contractDetails, [
														'contract',
														'basicDelivery',
														'month',
													])
												]
											)}, ${t(
												SHIPMENT_PERIOD[
													getIn(contractDetails, [
														'contract',
														'basicDelivery',
														'period',
													])
												]
											)}`}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'unitRate',
											])}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={12} />
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								width: 680,
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '40pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Specifications
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Special Conditions
										</p>
									</td>
								</tr>
								<tr style={{ height: '52pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'specifications',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'specialConditions',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '51pt' }}>
									<td
										style={{
											...contractTableTDStyles,
											paddingBottom: 12,
										}}
										colSpan={2}
									>
										<p
											style={{
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											<br />
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											The specifications shall be those
											established by The Palm Oil Refiners
											Association of Malaysia (PORAM) as
											standard for the sale/purchase of
											oils in force at the date of
											contract and always provided they
											are not in
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												textIndent: '0pt',
												lineHeight: '12pt',
												textAlign: 'left',
											}}
										>
											contradiction with the above.
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={12} />
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table>
							<tbody>
								<tr style={{ height: '40pt' }}>
									<td
										style={{
											...contractTableTDStyles,
											width: '35%',
										}}
									>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												paddingRight: '4pt',
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											Methods and Terms of Payment
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												padding: '5pt',
												textIndent: '0pt',
												textAlign: 'left',
											}}
										>
											{getIn(contractDetails, [
												'contract',
												'paymentTerms',
											])}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							paddingTop: '3pt',
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						The FFA content shall be expressed as follows:
					</p>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						* If as palmitic acid, calculated on a molecular weight
						of 256;
					</p>
					<p
						style={{
							...paragraphStyles,
							textIndent: '0pt',
							textAlign: 'left',
						}}
					>
						* If as oleic acid, calculated on a molecular weight of
						282.
					</p>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>2.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUANTITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile
										? 238
										: isMobile
										? 238
										: 640,
								}}
							>
								Seller/Buyer shall deliver/collect the contract
								quantity. In the event that there is a further
								immediate contract between Seller and Buyer, any
								excess of deficiency in the quantity arising
								from the last road tanker/vessel load nearest to
								the contract quantity shall be used to off-set
								against such subsequent contract meant for
								completion within the same contract period.
							</p>
							<Spacer size={20} />
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										marginRight: 20,
									}}
								>
									(a)
								</p>
								<Box>
									<p style={{ ...paragraphStyles }}>
										Delivery / Collection by Road Tanker
									</p>
									<Spacer size={20} />
									<p
										style={{
											...paragraphStyles,
											width: isMobile ? 238 : 640,
										}}
									>
										The contract may be deemed to have been
										completed (at the option of the
										Seller/Buyer in case of
										delivery/collection contracts as
										applicable) by delivery/collection of 5%
										of such last contract or 5 tonnes
										(whichever is lower) more or less from
										the contract quantity. The variation of
										the first 2% from the contract quantity
										shall be settled at the contract price.
										The variation above such 2% upto and
										including the maximum allowed above,
										shall be settled at the market price
										ruling at time of delivery/collection.
										Failing amicable settlement by the
										parties, the market price shall be
										determined by the Price Settlement
										Committee appointed by PORAM.
									</p>
								</Box>
							</Box>
							<Spacer size={20} />
							<Box row={!isMobile}>
								<p
									style={{
										...paragraphStyles,
										marginRight: 20,
									}}
								>
									(b)
								</p>
								<Box>
									<p style={{ ...paragraphStyles }}>
										Delivery/Collection by Vessel
									</p>
									<Spacer size={20} />
									<p
										style={{
											...paragraphStyles,
											width: isMobile ? 238 : 640,
										}}
									>
										The contract may be deemed to have been
										completed at the option of Seller/Buyer
										in case of delivery/collection contracts
										(as applicable) by delivery/collection
										of 2% more or less from the contract
										quantity. Such variation shall be
										accounted for at the contract price.
									</p>
								</Box>
							</Box>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>3.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUALITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								At time and place of delivery/collection the oil
								shall be of good merchantable quality within the
								meaning of the description and specifications
								stated in the preamble. Unless so stated or
								otherwise agreed between the parties and
								confirmed in writing, the oil is not warranted
								to be suitable for any specific end-use or to
								conform to any particular analytical criteria
								not mentioned above.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>4.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DELIVERY/ COLLECTION</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is delivered, Seller
								shall be responsible for providing suitable road
								tankers/vessel tank(s) for delivery of the oil
								during the contract period. Seller shall give
								Buyer at least 3 business days notice (for
								delivery by road tankers) or 7 business days
								notice (for delivery by vessels) of the
								intention to effect delivery.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is collection, Buyer
								shall be responsible for providing suitable road
								tankers/vessel tank(s) for collection of the oil
								during the contract period. Buyer shall give
								Seller at least 3 business days notice (for
								collection by road tankers) or 7 business days
								notice (for collection by vessels) of the
								intention to effect collection, stating quantity
								to be collected per day and the particulars of
								the fleet of road tankers (intended to be
								employed)/vessels.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								When there is more than one contract for similar
								oil between the parties for the same
								delivery/collection period, the
								delivery/collection shall follow the dates of
								the respective contracts on a “first in, first
								out” basis.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>5.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>
								EXTENSION OF COLLECTION / DELIVERY
							</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Collection / Delivery shall be completed within
								the contract period. However, the time for
								collection/ delivery may be extended by Seller/
								Buyer to a period (hereinafter referred to as
								extended period) not exceeding 14 calendar days
								provided a written notice be given at least 3
								business days prior to expiry of contract
								period.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								The penalty for extension of collection /
								delivery shall be payable by the Seller or
								Buyer, as the case may be, as follows : -
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								First 7 calendar days – no penalty
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
								}}
							>
								8 th – 14th calendar days at 1.5% of the
								contract price.
							</p>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Non-collection/ non-delivery of the oil by the
								end of the extended period shall constitute a
								default.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								However, subject to mutual agreement in writing,
								the time for collection/delivery may be extended
								to a further period.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Upon expiry of the extended period of 14
								calendar days or the expiry of the extended
								further period as agreed upon, without
								collection or delivery of the oil or any part
								thereof, the Seller or Buyer as the case may be,
								shall be deemed to be in default of the contract
								or to the extent of any part thereof not
								collected or delivered.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								In the event of default of the contract, any
								penalty or damages whether arrived at by
								amicable settlement or arbitration shall not be
								related to the penalties of this clause.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>6.</p>
						</Box>

						<Box>
							<h3 style={h3Styles}>WEIGHTS</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is delivered by road
								tankers, weights as established at Buyer’s
								weighbridge shall be final. If delivery is by
								vessels, delivered weight based on ullage drawn
								from calibrated shore tanks into which oil is
								discharged shall be final.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is collection by road
								tankers, weights as established at Seller’s
								weighbridge shall be final. If collection is by
								vessels, shipped weight based on ullage drawn
								from calibrated shore tanks from which oil is
								loaded shall be final
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Seller/Buyer may appoint a representative to
								superintend weighing on his behalf at his own
								cost
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<p style={{ ...liStyles, marginRight: 24 }}>7.</p>
						<Box>
							<h3 style={h3Styles}>SAMPLING</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Representative samples in quadruplicate shall be
								drawn and sealed by Seller or Buyer as the case
								may be, in accordance with the procedures as
								specified hereunder. Seller/Buyer may appoint a
								representative to be present at the time samples
								are being drawn at his own expenses and to draw
								samples conjointly with Buyer’s/Seller’s
								representatives. Two of the sealed samples shall
								be provided to Buyer’s representative and the
								other two shall be retained by Seller or vice
								versa.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is delivered,
								representative samples shall be drawn from each
								road tanker at the time and place of delivery.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is collection,
								representative samples shall be drawn from the
								road tankers at the time and place of despatch.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								All inlet and outlet valves of the road tankers
								must be sealed.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is delivered by road
								tankers, the analysis as certified by Buyer’s
								laboratory based on samples drawn at time of
								delivery shall be final. If the basis of
								contract is collection by road tankers, the
								analysis as certified by Seller’s laboratory
								shall be final. In the event of a dispute, an
								independent surveyor/analyst to be appointed and
								the cost of such survey/analysis shall be for
								the account of the party at fault. In such an
								event, the findings of the independent
								surveyor/analyst shall be final.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is delivery/collection
								by vessel, representative composite samples in
								quadruplicate (1 for contractual analysis, 1 for
								the Shipper and 2 to be retained by the
								surveyors) of the oil shall be drawn from ship's
								tank(s) at time of shipment, in accordance with
								the MPOB/FOSFA/ PORAM Processed Palm Oil
								Storage, Transportation, Sampling and Survey
								Guide.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>8.</p>
						</Box>

						<Box>
							<h3 style={h3Styles}>INSURANCE</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is delivered, the
								Seller shall be responsible for insurance up to
								the time the oil is discharged from the road
								tanker. If the basis of contract is collection,
								Buyer shall be responsible for insurance from
								the time the oil is loaded onto road tankers.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If the basis of contract is delivery/collection
								by vessel, Seller/Buyer shall cover Marine
								Insurance with first class underwriters (but for
								whose solvency Seller/Buyer shall not be
								responsible) against all risks with the
								Institute Cargo Clauses enforced as at 31st
								December 1981 including the risk of
								contamination irrespective of percentage of each
								tank or on the whole, including risks of war,
								strike, riots and civil commotion for 102% of
								the contract value
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>9.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DEFAULT</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								In default of fulfilment of this contract by
								either party, the other party at his discretion
								shall, after giving notice, have the right
								either to cancel the contract or the right to
								sell or purchase, as the case may be, against
								the defaulter who shall on demand make good the
								loss, if any, on such sale or purchase. If the
								party liable to pay shall be dissatisfied with
								the price of such sale or purchase, or if
								neither of the above right is exercised, the
								damage if any, shall, failing amicable
								settlement, be determined by arbitration. The
								damages awarded against the defaulter shall be
								limited to the differences between the contract
								price and the market price on the day of the
								default but if the Arbitrator(s) consider the
								circumstances of the default justify it, they
								may at their absolute discretion, award
								additional damages.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<p style={{ ...liStyles, marginRight: 24 }}>10.</p>
						<Box>
							<h3 style={h3Styles}>BANKRUPTCY</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								If before the fulfilment of this contract either
								party shall suspend payment, commit an act of
								bankruptcy, notify any of his creditors that he
								is unable to meet his debts or that he has
								suspended payments or that he is about to
								suspend payment of his debts, convene, call or
								hold a meeting either of his creditors or to
								pass a resolution to go into liquidation (except
								for voluntary winding up of a solvent company
								for the purpose of reconstruction or
								amalgamation) or shall apply for an official
								moratorium, have a petition presented for
								winding up or shall have a Receiver appointed,
								he shall be deemed to be and shall be treated as
								being at default and the contract shall
								forthwith be closed, either at the market price
								then current for similar goods or, at the option
								of the other party, at a price to be ascertained
								by repurchase or resale and the difference
								between the contract price and such closing-out
								price shall be the amount which the other party
								shall be entitled to claim or shall be liable to
								account for under this contract. Should either
								party be dissatisfied with the price, the matter
								shall be referred to arbitration. Where no such
								resale or repurchase takes place, the
								closing-out price shall be determined by a Price
								Settlement Committee appointed by The Palm Oil
								Refiners Association of Malaysia.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>11.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DOMICILE</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								The contract shall be deemed to have been made
								in Malaysia and the construction, validity and
								performance thereof shall be governed in all
								respects by Malaysian Law, which shall have
								exclusive jurisdiction wherever the domicile,
								residence or place of business of the parties of
								this contract may be or become.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>12.</p>
						</Box>

						<Box>
							<h3 style={h3Styles}>FORCE MAJEURE</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Should the performance of the contract be
								prevented by reason of fire, strikes, lockouts,
								riots, civil commotion, floods and/or any cause
								comprehended in the term force majeure, the
								contract period shall be extended for a period
								equal to the duration of the disabling event but
								not exceeding a period of 60 calendar days. If
								the force majeure event ends within the period
								preceding 21 calendar days before the end of the
								extended period, then a further 21 calendar days
								shall be allowed after the termination of the
								force majeure event. Should the fulfilment of
								the contract not be possible within the extended
								period, the contract or any unfulfilled part
								thereof shall be deemed to be null and void at
								the end of such extended period
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>13.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>NOTICE</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Where the terms of the contract require notice
								to be given, such notice shall be transmitted or
								sent by cable, telegram, telex, fascimile,
								email, post or courier service or delivered by
								hand within the time limit specified in the
								contract. All notices shall be under reserve for
								errors and omissions in transmission or
								delivery. Any notice received after 1600 hours
								Malaysian time on a business day shall be deemed
								to have been received on the following business
								day.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>14.</p>
						</Box>

						<Box>
							<h3 style={h3Styles}>NON-BUSINESS DAY</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Should the time limit for doing any act or
								giving any notice expire on a Saturday, Sunday
								or any National Gazetted Public Holiday in
								Malaysia, the time so limited shall be extended
								until the first business day thereafter. All
								business days shall be deemed to end 1600 hours
								Malaysian time Monday to Friday inclusive. The
								contract delivery/collection period shall not be
								affected by this clause.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>15.</p>
						</Box>

						<Box>
							<h3 style={h3Styles}>ODD DAYS</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								In any month containing an odd number of days,
								the middle day shall be reckoned as belonging to
								both halves of the month.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>16.</p>
						</Box>

						<Box>
							<h3 style={h3Styles}>ARBITRATION</h3>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Any dispute arising out of this contract,
								including any question of law arising in
								connection therewith, shall be referred to
								arbitration in Malaysia (at the PORAM
								Secretariat or elsewhere if so agreed), in
								accordance with the PORAM Rules of Arbitration
								and Appeal in force at the date of the
								initiation of the arbitration with the parties
								hereby agreeing that the arbitration shall be
								deemed an International Arbitration. The seat of
								the arbitration shall be Malaysia and the
								Malaysian Arbitration Act 2005 (“the Act”) or
								any reenactment thereof shall apply to any
								arbitration under this contract. The parties
								hereto further agree that Part III of the Act
								shall not apply to any arbitration under this
								contract.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									width: isMobile ? 238 : 640,
								}}
							>
								Neither party hereto, nor any persons claiming
								under either of them shall bring any action or
								other legal proceedings against the other of
								them in respect of any such dispute until such
								dispute shall first have been heard and
								determined by the Sole Arbitrator/Panel of
								Arbitrators/Appeal Board (as the case may be),
								in accordance with the PORAM Rules of
								Arbitration and Appeal and it is hereby
								expressly agreed and declared that the obtaining
								of an award from the Sole Arbitrator/Panel of
								Arbitrators/Appeal Board (as the case may be),
								shall be a condition precedent to the right of
								either party hereto or of any person claiming
								under either of them to bring any action or
								other legal proceedings against the other of
								them in respect of such dispute.
							</p>
						</Box>
					</Box>
					<Box
						row={!isMobile}
						justifyContent="space-between"
						margin="28px 0 0"
					>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Seller:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Broker:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Buyer:
						</p>
					</Box>
				</Box>
				<Box
					style={{
						width: isMobile ? 350 : 800,
						padding: 15,
						margin: '40px 0',
						alignItems: 'center',
						background: theme.color.white,
						justifyContent: 'space-between',
					}}
					row={!isMobile}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '60%',
							alignItems: 'end',
							marginRight: '5%',
							padding: '15px 10px',
							background: theme.color.white,
						}}
					>
						<SmallText
							style={{
								fontSize: 14,
								color: '#8D93A0',
								lineHeight: '19px',
								textAlign: 'justify',
							}}
						>
							{t('contracts.proceedToESignText')}
						</SmallText>
					</Box>

					<Button
						rounded
						label={t('contracts.proceedToESign')}
						reverse
						onClick={() => {
							if (contractAction === 'edit') {
								setShowModal(true)
							} else {
								dispatch(
									MarketPlaceDuc.creators.postContractDetails(
										contractDetails,
										documentType
									)
								)
							}
						}}
						extendStyles={{
							width: 266,
							background: '#3F56C4',
							color: theme.color.white,
						}}
					/>
				</Box>
				<DigitalContractMessage
					width={isMobile ? 350 : 800}
					margin="0 0 40px 0"
				/>
			</Box>
		</>
	)
}

export { PreviewContractNo3 }
