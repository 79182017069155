import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { KeyValueSegment } from 'ui-marketplace-app/modules/MarketPlace/containers/DocumentView'
import { isEmptyObject } from 'ui-marketplace-app/utils/helpers'
import { Input } from 'ui-lib/components/Input'
import { Button } from 'ui-lib/components/Button'
import { TextArea } from 'ui-lib/components/TextArea'
import { Select } from 'ui-lib/components/Select'
import { Label } from 'ui-lib/components/Label'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import theme from 'ui-lib/utils/base-theme'
import * as yup from 'yup'
import ArrowBackIcon from 'ui-lib/icons/arrow_back.svg'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { SmallText, H3, Text, P } from 'ui-lib/components/Typography'
import { Card } from 'ui-lib/components/Card'
import { useSelector, useDispatch } from 'react-redux'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { getIn } from 'timm'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { DatePicker } from 'ui-lib/components/Datepicker'
import InfoIcon from 'ui-lib/icons/info_black.svg'
import { CheckBoxGroup } from 'ui-lib/components/Checkbox'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import { Modal } from 'ui-lib/components/Modal'
import { OriginalRequirement } from 'ui-lib/components/OriginalRequirement'
import { RFQViewFromQuote } from 'ui-marketplace-app/modules/MarketPlace/containers/Quotes/RFQViewFromQuote'

const CertificateBlock = ({ name, t }) => {
	return (
		<>
			<Box row alignItems="center" justifyContent="space-between">
				<Spacer size={8} />
				<Label style={{ color: '#8D93A0', fontWeight: '400' }}>
					{name}
				</Label>
				<Spacer size={12} />
				<Label style={{ color: '#242845', fontWeight: '500' }}>
					Valid upto 12 DEC 2022
				</Label>
				<Spacer size={12} />
				<Label style={{ color: '#3F56C4', fontWeight: '500' }}>
					{t('quote.view')}
				</Label>
			</Box>
			<Spacer size={8} />
		</>
	)
}

const CreateQuote = () => {
	const { t } = useTranslation()

	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false)
	const getQuoteDocument = useSelector(
		MarketPlaceDuc.selectors.getQuoteDocument
	)
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { action, rootModule } = payload
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const rfqDetails = useSelector(MarketPlaceDuc.selectors.getRFQDetails)
	const rfqOriginalDetails = useSelector(
		MarketPlaceDuc.selectors.getRFQDetails
	)

	const validationSchema = yup.object().shape({
		quantity: yup
			.number()
			.positive(t('validation.positiveCapacity'))
			.required(t('validation.capacityReq')),
		currency: yup.string().required(t('validation.capacityReq')),
		unitRate: yup
			.number()
			.positive(t('validation.positiveCapacity'))
			.required(t('validation.capacityReq')),
	})

	const currentOrgDetails = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)

	const {
		files = [],
		rfqNumber,
		buyerAddress,
		product,
		rfqID,
		buyer,
		buyerCategory,
		buyerState,
		buyerCountry,
		buyerCertificateList,
		buyerID,
	} = rfqDetails

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		isSubmitting,
		errors,
		submitForm,
	} = useFormik({
		initialValues: getQuoteDocument,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			let _payload = {}
			if (action === 'create-quote') {
				const {
					month: _month,
					period: _period,
					quantity: _quantity,
					totalAmount,
					unitRate,
					...payloadValues
				} = _values
				_payload = {
					...payloadValues,
					buyerOrg: {
						id: buyerID,
					},
					rfq: {
						id: rfqID,
					},
					quantity: parseFloat(_quantity, 10),
					unitRate: parseFloat(unitRate, 10),
					totalAmount: parseFloat(totalAmount, 10),

					shipmentPeriod: {
						month: _month,
						period: _period,
					},
					meta: {
						files,
						termsAndConditions:
							getIn(payloadValues, ['termsAndConditions']) || '',
					},
				}
			} else {
				const {
					month: _month,
					period: _period,
					quantity: _quantity,
					totalAmount,
					unitRate,
					...payloadValues
				} = _values
				_payload = {
					...payloadValues,
					buyerOrg: {
						id: buyerID,
					},
					rfq: {
						id: rfqID,
					},
					meta: {
						termsAndConditions:
							getIn(payloadValues, ['termsAndConditions']) || '',
					},
					quantity: parseFloat(_quantity, 10),
					unitRate: parseFloat(unitRate, 10),
					totalAmount: parseFloat(totalAmount, 10),
					shipmentPeriod: {
						month: _month,
						period: _period,
					},
				}

				delete _payload.status
			}

			const message =
				action === 'create-quote'
					? t('production.plotInputSuccessToast')
					: t('common.successUpdate')

			dispatch(
				MarketPlaceDuc.creators.createQuote(
					_payload,
					message,
					getIn(getQuoteDocument, ['id']),
					{
						setSubmitting,
					}
				)
			)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.quantity

	const supplyChainModelOptions = [
		{
			name: t('createAsset.identity-preserved'),
			id: 'identity-preserved',
		},
		{
			name: t('createAsset.segregated'),
			id: 'segregated',
		},
		{
			name: t('createAsset.mass-balance'),
			id: 'mass-balance',
		},
	]

	const thirdPartyInspectionOptions = [
		{
			label: t('quote.quantity'),
			key: 'quantity',
		},
		{
			label: t('quote.quality'),
			key: 'quality',
		},
	]

	const contractTypeOptions = [
		{
			label: t('marketPlace.fob'),
			name: 'fob',
		},
		{
			label: t('marketPlace.cif'),
			name: 'cif',
		},
		{
			label: t('marketPlace.local-delivery'),
			name: 'local-delivery',
		},
	]

	const monthOptions = [
		{
			label: t('marketPlace.jan'),
			name: 'january',
		},
		{
			label: t('marketPlace.feb'),
			name: 'february',
		},
		{
			label: t('marketPlace.mar'),
			name: 'march',
		},
		{
			label: t('marketPlace.apr'),
			name: 'april',
		},
		{
			label: t('marketPlace.may'),
			name: 'may',
		},
		{
			label: t('marketPlace.jun'),
			name: 'june',
		},
		{
			label: t('marketPlace.jul'),
			name: 'july',
		},
		{
			label: t('marketPlace.aug'),
			name: 'august',
		},
		{
			label: t('marketPlace.sep'),
			name: 'september',
		},
		{
			label: t('marketPlace.oct'),
			name: 'october',
		},
		{
			label: t('marketPlace.nov'),
			name: 'november',
		},
		{
			label: t('marketPlace.dec'),
			name: 'december',
		},
	]

	const periodOptions = [
		{
			label: t('marketPlace.first-half'),

			name: 'first-half',
		},

		{
			label: t('marketPlace.second-half'),

			name: 'second-half',
		},

		{
			label: t('marketPlace.entire-month'),

			name: 'entire-month',
		},
	]

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('quote.marketPlace'),
			name: 'marketplace',
			isActive: true,
		},
		{
			label:
				action === 'create-quote'
					? t('quote.createQuote')
					: t('quote.editQuote'),
			name: 'create-quote',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'marketplace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule: 'buyer',
					}
				)
			)
		}
	}

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Modal
				show={showModal}
				heading={rfqNumber}
				body={<RFQViewFromQuote />}
				confirmlabel={t('common.close')}
				onConfirm={() => {
					setShowModal(false)
				}}
			/>
			<Box row justifyContent="space-between">
				<Title
					title={
						action === 'create-quote'
							? t('quote.generateQuotation')
							: t('quote.editQuotation')
					}
					icon={ArrowBackIcon}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE,
								{
									rootModule,
								}
							)
						)
					}}
				/>

				<Box
					style={{
						cursor: 'pointer',
						background: '#3F56C4',
						width: '48px',
						height: '48px',
						boxShadow: '2px 8px 16px #2428453D',
						borderRadius: '6px',
						marginRight: isMobile ? '10px' : '0px',
					}}
					onClick={() => {
						setShowModal(true)
					}}
					center
				>
					<IconWrapper color="white" size={25}>
						<Icon glyph={InfoIcon} />
					</IconWrapper>
				</Box>
			</Box>
			<Box row justifyContent="space-between">
				<Box
					style={{
						width: isMobile ? '100%' : '60%',
						background: '#fff',
						padding: '22px',
						marginRight: '20px',
					}}
				>
					<Box
						row={!isMobile}
						justifyContent="flex-start"
						alignItems="baseline"
					>
						<Box
							style={{
								width: isMobile ? '100%' : '25%',
								overflow: 'hidden',
							}}
						>
							<H3
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
									width: '100%',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									color: '#8D93A0',
								}}
								title={t('quote.quoteForProduct')}
							>
								{t('quote.quoteForProduct')} :
							</H3>
						</Box>

						<Box
							padding={4}
							style={{
								width: '75%',
							}}
						>
							<Text
								title={product}
								style={{
									lineHeight: '1.5',
									width: '100%',
									overflow: 'hidden',
									color: '#299F74',
									fontSize: theme.fontSize.xl,
									fontWeight: 'bold',
								}}
							>
								{product}
							</Text>
						</Box>
					</Box>
					<Spacer size={40} />
					<form onSubmit={handleSubmit}>
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('quote.productDetails')}
							</SmallText>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box
									style={{ width: isMobile ? '100%' : '20%' }}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
											required
										>
											{t('quote.quantity')}
										</Label>
										<Box style={{ width: '100%' }}>
											<Input
												value={values.quantity}
												name="quantity"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.quantity &&
													errors.quantity
												}
											/>
										</Box>
										<OriginalRequirement
											newValue={parseInt(
												values.quantity,
												10
											)}
											originalValue={
												rfqOriginalDetails.quantity
											}
											originalDisplayValue={`${rfqOriginalDetails.quantity} ${rfqOriginalDetails.uom}`}
											marginTop="-30px"
										/>
									</Box>
								</Box>
								<Box
									style={{ width: isMobile ? '100%' : '20%' }}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
											required
										>
											{t('quote.currency')}
										</Label>
										<Select
											value={getIn(values, [
												'currency',
												'code',
											])}
											name="currency"
											type="text"
											options={[
												{
													label: 'USD',
													name: 'USD',
												},
												{
													label: 'MYR',
													name: 'MYR',
												},
												{
													label: 'INR',
													name: 'INR',
												},
											]}
											onChange={value =>
												setFieldValue('currency', {
													code: value.currency.label,
												})
											}
											key={values.currency}
											labelKey="label"
											returnKeyValue
											valueKey="name"
											onBlur={handleBlur}
											error={
												touched.currency &&
												errors.currency
											}
										/>
									</Box>
								</Box>
								<Box
									style={{ width: isMobile ? '100%' : '22%' }}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
											required
										>
											{t('quote.unitRate')}
										</Label>
										<Input
											value={values.unitRate}
											name="unitRate"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.unitRate &&
												errors.unitRate
											}
										/>
									</Box>
								</Box>
								<Box
									style={{ width: isMobile ? '100%' : '26%' }}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
										>
											{t('quote.totalAmount')}
										</Label>
										<Input
											value={
												values.quantity &&
												values.unitRate
													? values.quantity *
													  values.unitRate
													: 0
											}
											name="totalAmount"
											type="text"
											disabled
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.totalAmount &&
												errors.totalAmount
											}
										/>
									</Box>
								</Box>
							</Box>
						</Card>
						<Spacer size={32} />
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('quote.shipmentDetails')}
							</SmallText>
							<Box
								row
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
								alignItems="center"
							>
								<Box
									style={{ width: isMobile ? '40%' : '35%' }}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
										>
											{t('quote.portOfLading')}
										</Label>
										<Box style={{ width: '100%' }}>
											<Input
												value={values.ladingPort}
												name="ladingPort"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.ladingPort &&
													errors.ladingPort
												}
											/>
										</Box>
										<OriginalRequirement
											newValue={values.ladingPort}
											originalValue={
												rfqOriginalDetails.ladingPort
											}
											originalDisplayValue={
												rfqOriginalDetails.ladingPort
											}
											marginTop="-30px"
										/>
									</Box>
								</Box>

								<IconWrapper color="#8D93A0" size={25}>
									<Icon glyph={LeftArrowIcon} rotate180 />
								</IconWrapper>

								<Box
									style={{ width: isMobile ? '40%' : '32%' }}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
										>
											{t('quote.desintationPort')}
										</Label>
										<Input
											value={values.destinationPort}
											name="destinationPort"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.destinationPort &&
												errors.destinationPort
											}
										/>
									</Box>
									<OriginalRequirement
										newValue={values.destinationPort}
										originalValue={
											rfqOriginalDetails.destinationPort
										}
										originalDisplayValue={
											rfqOriginalDetails.destinationPort
										}
										marginTop="-30px"
									/>
								</Box>
							</Box>
						</Card>
						<Spacer size={32} />
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('quote.otherDetails')}
							</SmallText>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box
									style={{ width: isMobile ? '100%' : '35%' }}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
										>
											{t('quote.scModel')}
										</Label>
										<Box style={{ width: '100%' }}>
											<Select
												value={getIn(values, [
													'supplychainModel',
													'id',
												])}
												options={
													supplyChainModelOptions
												}
												key={values.supplychainModel}
												labelKey="name"
												valueKey="id"
												onChange={value =>
													setFieldValue(
														'supplychainModel',
														value
													)
												}
												returnOnlyValue
											/>
										</Box>
										<OriginalRequirement
											newValue={
												values.supplychainModel &&
												values.supplychainModel.id
													? values.supplychainModel.id
													: ''
											}
											originalValue={
												rfqOriginalDetails.supplyChainModel
											}
											originalDisplayValue={
												supplyChainModelOptions.find(
													option => {
														return (
															option.id ===
															(rfqOriginalDetails.supplyChainModel
																? rfqOriginalDetails.supplyChainModel
																: '')
														)
													}
												)?.name
											}
											marginTop="-22px"
										/>
									</Box>
								</Box>

								<Box
									style={{ width: isMobile ? '100%' : '40%' }}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
										>
											{t('quote.validityOfOffer')}
										</Label>
										<DatePicker
											required
											name="offerValidity"
											returnKeyValue
											placeholder={t('quote.chooseDate')}
											minDate={new Date().setHours(
												0,
												0,
												0,
												0
											)}
											value={values.offerValidity}
											onChange={keyValue => {
												const date = new Date(
													keyValue.offerValidity
												).setHours(23, 59, 59)

												const offerValidity = new Date(
													date
												).toISOString()

												setFieldValue(
													'offerValidity',
													offerValidity
												)
											}}
										/>
									</Box>
								</Box>
							</Box>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box
									style={{ width: isMobile ? '100%' : '36%' }}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												fontWeight: 'bold',
												color: '#242845',
												margin: '0 0 8px',
											}}
										>
											{t('quote.thirdPartyInspection')}
										</Label>
										<Box style={{ width: '100%' }}>
											<CheckBoxGroup
												selected={
													values.thirdPartyInspection
												}
												options={
													thirdPartyInspectionOptions
												}
												onChange={value => {
													setFieldValue(
														'thirdPartyInspection',
														value
													)
												}}
											/>
										</Box>
										<OriginalRequirement
											newValue={
												values.thirdPartyInspection &&
												values.thirdPartyInspection
													.length > 0
													? [
															...values.thirdPartyInspection,
													  ]
															.sort()
															.join(',')
													: ''
											}
											originalValue={
												rfqOriginalDetails.thirdPartyInspectionType &&
												rfqOriginalDetails
													.thirdPartyInspectionType
													.length > 0
													? [
															...rfqOriginalDetails.thirdPartyInspectionType,
													  ]
															.sort()
															.join(',')
													: ''
											}
											originalDisplayValue={
												rfqOriginalDetails.thirdPartyInspectionType &&
												rfqOriginalDetails
													.thirdPartyInspectionType
													.length > 0
													? rfqOriginalDetails.thirdPartyInspectionType
															.map(
																value =>
																	thirdPartyInspectionOptions.find(
																		option => {
																			return (
																				option.key ===
																				value
																			)
																		}
																	)?.label
															)
															.join(',')
													: ''
											}
											marginTop="2px !important"
										/>
									</Box>
								</Box>
								<Card
									style={{
										background: '#CBD2EF',
										maxWidth: isMobile ? 300 : 200,
										padding: '12px 4px 4px 12px',
										height: 'auto',
										marginTop: isMobile ? '20px' : '0px',
									}}
									textAlign="left"
								>
									<Box row>
										<IconWrapper
											style={{
												backgroundColor: '#ECEEF7',
												padding: 4,
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
											size={24}
										>
											<Icon glyph={InfoIcon} />
										</IconWrapper>
										<P
											style={{
												color: '#3F56C4',
												fontSize: 14,
												marginLeft: 8,
											}}
										>
											{t(
												'quote.thirdPartyInspectionline2'
											)}
										</P>
									</Box>
								</Card>
							</Box>

							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box
									style={{
										width: isMobile ? '100%' : '200px',
									}}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												color: '#242845',
												fontWeight: 'bold',
												margin: '0 0 8px',
											}}
										>
											{t(
												'quote.preferredQuantityInspector'
											)}
										</Label>
										<Box style={{ width: '100%' }}>
											<Input
												value={
													values.preferredQuantityInspector
												}
												name="preferredQuantityInspector"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.preferredQuantityInspector &&
													errors.preferredQuantityInspector
												}
											/>
										</Box>
										<OriginalRequirement
											newValue={
												values.preferredQuantityInspector
											}
											originalValue={
												rfqOriginalDetails.quantityInspector
													? rfqOriginalDetails.quantityInspector
													: ''
											}
											originalDisplayValue={
												rfqOriginalDetails.quantityInspector
											}
											marginTop="-30px"
										/>
									</Box>
								</Box>

								<Box
									style={{
										width: isMobile ? '100%' : '200px',
									}}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												color: '#242845',
												fontWeight: 'bold',
												margin: '0 0 8px',
											}}
										>
											{t(
												'quote.preferredQualityInspector'
											)}
										</Label>
										<Input
											value={
												values.preferredQualityInspector
											}
											name="preferredQualityInspector"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.preferredQualityInspector &&
												errors.preferredQualityInspector
											}
										/>
									</Box>
									<OriginalRequirement
										newValue={
											values.preferredQualityInspector
										}
										originalValue={
											rfqOriginalDetails.qualityInspector
												? rfqOriginalDetails.qualityInspector
												: ''
										}
										originalDisplayValue={
											rfqOriginalDetails.qualityInspector
										}
										marginTop="-30px"
									/>
								</Box>
							</Box>

							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box
									style={{
										width: isMobile ? '100%' : '200px',
									}}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												color: '#242845',
												fontWeight: 'bold',
												margin: '0 0 8px',
											}}
										>
											{t('quote.typeOfContract')}
										</Label>
										<Box style={{ width: '100%' }}>
											<RadioInputGroup
												options={contractTypeOptions}
												selected={values.contractType}
												onChange={e => {
													setFieldValue(
														'contractType',
														e.target.value
													)
												}}
											/>
										</Box>
										<OriginalRequirement
											newValue={values.contractType}
											originalValue={
												rfqOriginalDetails.contractType
													? rfqOriginalDetails.contractType
													: ''
											}
											originalDisplayValue={
												rfqOriginalDetails.contractType
													? contractTypeOptions.find(
															option => {
																return (
																	option.name ===
																	rfqOriginalDetails.contractType
																)
															}
													  )?.label
													: ''
											}
											marginTop="-22px"
										/>
									</Box>
								</Box>

								<Box
									style={{
										width: isMobile ? '100%' : '200px',
									}}
								>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												color: '#242845',
												fontWeight: 'bold',
												margin: '0 0 8px',
											}}
										>
											{t('quote.shipmentPeriod')}
										</Label>
										<Box>
											<Box
												width={isMobile ? '100%' : 200}
											>
												<Select
													value={values.month}
													options={monthOptions}
													key={values.month}
													labelKey="label"
													valueKey="name"
													onChange={value =>
														setFieldValue(
															'month',
															value.name
														)
													}
													returnOnlyValue
												/>
											</Box>

											<Box
												width={isMobile ? '100%' : 200}
											>
												<Select
													value={values.period}
													options={periodOptions}
													key={values.period}
													labelKey="label"
													valueKey="name"
													onChange={value =>
														setFieldValue(
															'period',
															value.name
														)
													}
													returnOnlyValue
												/>
											</Box>
											<OriginalRequirement
												newValue={`${values.month}, ${values.period}`}
												originalValue={`${rfqOriginalDetails.month}, ${rfqOriginalDetails.period}`}
												originalDisplayValue={
													rfqOriginalDetails.month &&
													rfqOriginalDetails.period
														? `${
																monthOptions.find(
																	option => {
																		return (
																			option.name ===
																			(rfqOriginalDetails.month
																				? rfqOriginalDetails.month
																				: '')
																		)
																	}
																)?.label
														  }, ${
																periodOptions.find(
																	option => {
																		return (
																			option.name ===
																			(rfqOriginalDetails.period
																				? rfqOriginalDetails.period
																				: '')
																		)
																	}
																)?.label
														  }`
														: ''
												}
												marginTop="-22px"
											/>
										</Box>
									</Box>
								</Box>
							</Box>
						</Card>
						<Spacer size={32} />
						<Box>
							<Label>
								<Label
									style={{
										fontSize: '16px',
										margin: '0 0 8px',
										color: theme.color.black3,
									}}
								>
									{t('quote.termsAndConditions')}
								</Label>
								<Label
									style={{
										fontSize: '12px',
										margin: isMobile ? '8px 0' : '0 0 8px',
										color: theme.color.white,
										alignItems: 'center',
										float: 'right',
										paddingRight: '10px',
										paddingLeft: '10px',
										backgroundColor: '#CE924B',
										borderRadius: '4px',
										opacity: 1,
									}}
								>
									{t('quote.termsAndConditionsline2')}
								</Label>
							</Label>
							<TextArea
								name="termsAndConditions"
								value={
									action === 'create-quote'
										? getIn(values, ['termsAndConditions'])
										: getIn(values, [
												'meta',
												'termsAndConditions',
										  ])
								}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'termsAndConditions',
										e.target.value
									)
								}}
							/>
						</Box>
						<Spacer size={16} />
						<Box>
							<Label
								style={{
									fontSize: '16px',
									margin: '0 0 8px',
									color: theme.color.black3,
								}}
							>
								{t('quote.paymentTerms')}
							</Label>
							<TextArea
								name="paymentTerms"
								value={values.paymentTerms}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'paymentTerms',
										e.target.value
									)
								}}
							/>
						</Box>
						<Spacer size={16} />
						<OriginalRequirement
							newValue={values.paymentTerms}
							originalValue={rfqOriginalDetails.payment}
							originalDisplayValue={rfqOriginalDetails.payment}
							halfIcon
							marginTop="-38px"
							halfIconTop="2px"
						/>

						<Box>
							<Label
								style={{
									fontSize: '16px',
									margin: '0 0 8px',
									color: theme.color.black3,
								}}
							>
								{t('quote.additionalDetails')}
							</Label>
							<TextArea
								name="additionalDetails"
								value={values.additionalDetails}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'additionalDetails',
										e.target.value
									)
								}}
							/>
						</Box>
						<OriginalRequirement
							newValue={values.additionalDetails}
							originalValue={
								rfqOriginalDetails.requirementDetails
							}
							originalDisplayValue={
								rfqOriginalDetails.requirementDetails
							}
							halfIcon
							marginTop="-22px"
							halfIconTop="2px"
						/>
					</form>
				</Box>

				{action === 'create-quote' && (
					<Box
						style={{
							width: isMobile ? '100%' : '37%',
						}}
					>
						<Box
							style={{
								background: '#fff',
								padding: '27px 37px',
							}}
						>
							<Label style={{ color: theme.color.blue5 }}>
								{t('quote.issueTo')}
							</Label>
							<Spacer size={8} />

							<Title title={buyer} small note={buyerCategory} />

							<Spacer size={8} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('quote.address')}
								value={buyerAddress}
							/>
							<Spacer size={8} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('quote.stateCountry')}
								value={`${buyerState}, ${buyerCountry}`}
							/>
							<Spacer size={8} />

							{(buyerCertificateList || []).map(certificate => (
								<CertificateBlock
									key={certificate.certificateType}
									name={certificate.certificateType}
									t
								/>
							))}
						</Box>
						<Spacer size={20} />
						<Box
							style={{
								background: '#fff',
								padding: '27px 37px',
							}}
						>
							<Label style={{ color: theme.color.blue5 }}>
								{t('quote.issuedBy')}
							</Label>
							<Spacer size={8} />

							<Title
								title={getIn(currentOrgDetails, ['name'])}
								note={getIn(currentOrgDetails, [
									'categories',
									0,
									'name',
								])}
								small
							/>
							<Spacer size={8} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('quote.address')}
								value={`${getIn(currentOrgDetails, [
									'primaryAddress',
									'line1',
								]) || ''} ${getIn(currentOrgDetails, [
									'primaryAddress',
									'city',
								]) || ''}
 ${getIn(currentOrgDetails, ['primaryAddress', 'state']) ||
		''} ${getIn(currentOrgDetails, ['primaryAddress', 'country']) || ''}`}
							/>
							<Spacer size={8} />
							<KeyValueSegment
								labelWidth="50%"
								label={t('quote.stateCountry')}
								value={`
							${getIn(currentOrgDetails, ['primaryAddress', 'state']) ||
								''} , ${getIn(currentOrgDetails, [
									'primaryAddress',
									'country',
								]) || ''}`}
							/>
							<Spacer size={8} />
						</Box>
					</Box>
				)}
			</Box>
			<Box
				width={isMobile ? '100%' : '60%'}
				row
				justifyContent={isMobile ? 'center' : 'flex-end'}
				margin="36px 0 0"
			>
				<Button
					isLoading={isSubmitting}
					label={
						action === 'create-quote'
							? t('quote.generateQuote')
							: t('quote.editQuote')
					}
					primary
					disabled={disableCTA}
					extendStyles={{ width: 212 }}
					onClick={() => submitForm()}
				/>
			</Box>
		</>
	)
}

export { CreateQuote }
