import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import moment from 'moment'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import {
	MONTHS,
	SHIPMENT_PERIOD,
	headingStyles,
	liStyles,
	h3Styles,
	contractTableLabelStyles,
	contractTableTDStyles,
	actorStyles,
	s1Styles,
	paragraphStyles,
} from 'ui-marketplace-app/modules/MarketPlace/config'
import { Box } from 'ui-lib/utils/Box'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { Modal } from 'ui-lib/components/Modal'
import { SmallText, P } from 'ui-lib/components/Typography'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import { DigitalContractMessage } from '../../../../components/DigitalContractMessage'
import '../styles/contract1.css'

const PreviewContractNo6 = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload, query = {} } = location
	const { contractAction } = query
	const { rootModule, documentReference, documentType } = payload
	const [showModal, setShowModal] = useState(false)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const contractDetails = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	const { contract = {} } = useSelector(
		MarketPlaceDuc.selectors.getContractDetails
	)

	if (!contract.quantity && contractAction === 'generate') {
		dispatch(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
				{
					rootModule,
					action: 'generate',
					documentReference,
					documentType,
				}
			)
		)
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewEmployee.confirm')}
				body={
					<P large bold>
						{t('contracts.editContractText')}
					</P>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.ok')}
				onClose={() => setShowModal(false)}
				onConfirm={() => {
					setShowModal(false)
					dispatch(
						MarketPlaceDuc.creators.editContract(
							contractDetails,
							documentType
						)
					)
				}}
			/>
			<Title
				title={
					contractAction === 'edit'
						? t('contracts.editContractPreview')
						: t('contracts.createContractPreview')
				}
				icon={LeftArrowIcon}
				onClick={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE,
							{
								rootModule,
								action: 'participants-success',
								documentReference,
								documentType,
							},
							{
								contractAction,
							}
						)
					)
				}}
				small
				isMobile={isMobile}
				hasNoMaxWidth
			/>
			<Spacer size={60} />
			<Box center style={{ background: '#EAEAEB' }}>
				<Box
					width={804}
					style={{ background: theme.color.white }}
					padding={60}
				>
					<Box center>
						<h2 style={headingStyles}>
							DOMESTIC SALES CONTRACT FOR MALAYSIAN CRUDE
							UNBLEACHED PALM OIL IN
						</h2>
						<h2 style={headingStyles}>
							BULK CIF DELIVERED WEIGHTS FOR SALES BETWEEN EAST
							AND
						</h2>
						<h2 style={headingStyles}>PENINSULAR MALAYSIA</h2>
					</Box>
					<Spacer size={20} />
					<p
						style={{
							...paragraphStyles,
							margin: '10px 0',
							fontSize: 14,
						}}
					>
						Issued jointly by Malaysian Palm Oil Association (MPOA)
						and The Palm Oil Refiners Association of Malaysia
						(PORAM)
					</p>
					<Box>
						<p style={{ ...s1Styles, fontWeight: 'normal' }}>
							Revised and Effective
						</p>
						<br />
						<p style={{ ...s1Styles, fontWeight: 'normal' }}>
							1 February 2012
						</p>
					</Box>
					<br />
					<h1
						style={{
							margin: '0px 68px 12px 0',
							padding: 0,
							textAlign: isMobile ? 'center' : 'right',
						}}
					>
						6
					</h1>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={440}>
							<div>
								<p style={{ ...actorStyles }}>
									Seller :{' '}
									{getIn(contractDetails, [
										'contract',
										'seller',
										'name',
									])}
								</p>
								<br />
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Buyer :{' '}
									{getIn(contractDetails, [
										'contract',
										'buyer',
										'name',
									])}
								</p>
								<br />
								<p style={{ ...actorStyles, paddingTop: 13 }}>
									Broker :{' '}
									{getIn(contractDetails, [
										'contract',
										'broker',
										'name',
									])}
								</p>
							</div>
						</Box>
						<Box>
							<div
								style={{
									border: '1px solid black',
									padding: '10px',
									height: '148px',
									width: '148px',
									textAlign: 'center',
								}}
							>
								<p style={{ fontSize: '15px' }}>
									<u>Reference No:</u>
								</p>
								<div
									style={{
										overflow: isMobile
											? 'scroll'
											: 'hidden',
									}}
								>
									<table style={{ width: '100%' }}>
										<tbody>
											{getIn(contractDetails, [
												'contract',
												'referenceNo',
											]) ||
												[].map(ref => {
													return (
														<tr key={ref}>
															<td
																style={{
																	paddingTop:
																		'10px',
																}}
															>
																{ref}
															</td>
														</tr>
													)
												})}
										</tbody>
									</table>
								</div>
							</div>
						</Box>
					</Box>
					<p>
						<br />
					</p>
					<div>
						<p style={{ ...paragraphStyles, paddingTop: 5 }}>
							The Seller has agreed to sell and the Buyer has
							agreed to buy, the following product(s) on terms and
							conditions as stipulated hereunder:-
						</p>
						<p style={{ textIndent: '0pt', textAlign: 'left' }}>
							<br />
						</p>
					</div>
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								width: 680,
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '36pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Contract No :{' '}
											{getIn(contractDetails, [
												'contract',
												'contractNo',
											])}
										</p>
									</td>
									<td
										style={contractTableTDStyles}
										colSpan={2}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Date :{' '}
											{`${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('DD MM YYYY')}; ${moment(
												getIn(contractDetails, [
													'contract',
													'date',
												])
											).format('hh:mm A')}`}
										</p>
									</td>
								</tr>
								<tr style={{ height: '35pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={4}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'left',
												paddingLeft: '6px',
											}}
										>
											Description of Oil:{' '}
											{getIn(contractDetails, [
												'contract',
												'product',
												'description',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Quantity in
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											MT
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											Shipment Period
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											Price (CIF)
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												lineHeight: '11pt',
												textAlign: 'center',
											}}
										>
											PMT
										</p>
									</td>
								</tr>
								<tr style={{ height: '23pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'quantity',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{`${t(
												MONTHS[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'month',
													])
												]
											)}, ${t(
												SHIPMENT_PERIOD[
													getIn(contractDetails, [
														'contract',
														'shipmentPeriod',
														'period',
													])
												]
											)}`}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'unitRate',
											])}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={12} />
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								width: 680,
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '28pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											Specifications
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											Destination
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											Special Conditions
										</p>
									</td>
								</tr>
								<tr style={{ height: '34pt' }}>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'specifications',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'product',
												'meta',
												'port',
											])}
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'specialConditions',
											])}
										</p>
									</td>
								</tr>
								<tr style={{ height: '75pt' }}>
									<td
										style={contractTableTDStyles}
										colSpan={3}
									>
										<p
											style={{
												...contractTableLabelStyles,
												paddingLeft: '5pt',
												paddingRight: '12pt',
												textIndent: '0pt',
												textAlign: 'justify',
											}}
										>
											The specifications shall be those
											established as standard by Malaysian
											Palm Oil Association (MPOA) and The
											Palm Oil Refiners Association of
											Malaysia (PORAM) as standard for the
											sale/purchase of Crude Palm Oil, in
											force at the date of contract and
											always provided they are not in
											contradiction with the above.
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												paddingTop: '9pt',
												paddingLeft: '5pt',
												textIndent: '0pt',
												lineHeight: '12pt',
												textAlign: 'justify',
											}}
										>
											FFA as palmitic acid calculated on a
											molecular weight of 256.
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={12} />
					<div style={{ overflow: isMobile ? 'scroll' : 'hidden' }}>
						<table
							style={{
								borderCollapse: 'collapse',
								width: 680,
							}}
							cellSpacing={0}
						>
							<tbody>
								<tr style={{ height: '56pt' }}>
									<td
										style={{
											...contractTableTDStyles,
											width: '35%',
										}}
									>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											Methods and Terms of
										</p>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'center',
											}}
										>
											Payment
										</p>
									</td>
									<td style={contractTableTDStyles}>
										<p
											style={{
												...contractTableLabelStyles,
												textIndent: '0pt',
												textAlign: 'left',
												paddingLeft: '8px',
											}}
										>
											<br />
											{getIn(contractDetails, [
												'contract',
												'paymentTerms',
											])}
										</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>1.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUANTITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Sellers shall load the contract quantity with 5%
								more or less. Variance upto the first 2% of the
								contract quantity shall be settled at the
								contract price. The variance beyond 2% of the
								contract quantity shall be settled at the market
								price at the date of completion of discharge and
								that the Malaysian Palm Oil Board (MPOB) average
								price for the relevant region shall be the
								market price.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>2.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>QUALITY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								The oil shall be of good merchantable quality of
								the agreed description at time of discharge
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>3.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SHIPMENT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Shipment to be made to the port of destination
								from any East Malaysian port. For the purposes
								of this contract, the words vessel or vessels
								shall include any full-powered-engine driven
								vessel. However, any tanks, tank heating coils,
								lines, pumps or fittings which contain copper or
								copper alloy shall not be used for loading,
								carrying or discharging the oil. The tank/s
								shall be clean and shall not contain any leaded
								petroleum or other leaded products as the three
								previous cargoes.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Partial shipments are permitted. If the Bill of
								Lading is issued at a place other than the
								loading port, the name of the loading port and
								the date of loading shall be stated on the Bill
								of Lading; such date shall be considered the
								date of shipment.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event of partial shipments, each shipment
								is to be considered a separate contract
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Sellers to provided Buyers name of vessel (along
								the name of vessel's agent at discharge port)
								not less than 7 days prior to vessel's expected
								time of arrival at load port.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>4.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>INSURANCE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Sellers shall cover the Marine Insurance with
								first class underwriters (but for whose solvency
								Sellers shall not be responsible) against all
								risks with the Institute/FOSFA Trade Clauses ( A
								) including the risk of contamination
								irrespective of percentage of each tank or on
								the whole; including risks of war, strike, riots
								and civil commotion for 102% of the CIF contract
								value.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>5.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>HEATING INSTRUCTIONS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Sellers shall endeavour to ensure that PORAM
								Heating Instructions are followed and shall
								instruct the carriers to record daily
								temperatures of the oil and to provide Buyers on
								request with a chart signed by the ship's Master
								or Chief Officer.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>6.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DECLARATION OF SHIPMENT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Notice stating the ship's name, date of Bill(s)
								of Lading and quantity shall be despatched by
								Sellers to Buyers within 2 business days from
								the date of the Bill(s) of Lading, communication
								permitted by facsimile/telex/cable. The date of
								the Bill(s) of Lading shall be considered proof
								of the date of shipment in the absence of
								conclusive evidence to the contrary. Any slight
								variation in the ship's name shall not
								invalidate the declaration
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>7.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>
								EXTENSION OF SHIPMENT / CONTINUOUS LOADING
							</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the contract shipment period does not
								exceed 31 days the period of shipment shall, at
								the request of Sellers, be extended by an
								additional period not exceeding 8 days provided
								notice is given to Buyers of their intention to
								invoke the continuous loading provisions or
								claim such extension not later than the first
								business day following the last day of the
								original contract shipment period. Successive
								Sellers must pass on this notification with due
								despatch. Sellers shall at the same time
								nominate the ship they intend to load and shall
								provide, together with shipping documents,
								satisfactory evidence that the ship was
								originally booked with laydays/cancelling within
								the original contract shipment period.
							</p>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								No reduction in price shall be made provide the
								ship commenced loading at :-
							</p>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
									width: isMobile ? 238 : 640,
								}}
							>
								the first Sabah/Sarawak port in the case of oil
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
									width: isMobile ? 238 : 640,
								}}
							>
								from the States of Sabah and Sarawak
							</p>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								during the contract period and provided loading
								is completed within 5 days of the end of the
								original contract period. If loading did not so
								commence or, having commenced, did not complete
								within the said 5 days, Sellers shall reduce the
								price to their Buyers, on the quantity not
								loaded, to be deducted in the invoice from the
								contract price, as follows :-
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
									width: isMobile ? 238 : 640,
								}}
							>
								1/2% for 1, 2, 3, or 4 days
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
									width: isMobile ? 238 : 640,
								}}
							>
								1% for 5 or 6 days
							</p>
							<p
								style={{
									...paragraphStyles,
									textAlign: 'center',
									width: isMobile ? 238 : 640,
								}}
							>
								1 1/2% for 7 or 8 days
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								If Sellers invoke the continuous loading
								provisions or claim an extension and fail to
								ship within the 8 days, the original contract
								shipment period shall be considered to have been
								extended by 8 days and the contract price
								reduced by 1 1/2%.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should Sellers not claim the above extension and
								fail to ship within the contract period any
								penalty whether arrived at by amicable
								settlement or arbitration shall not be related
								to the penalty of this clause.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>8.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>PAYMENT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Payment shall be made for 98% of the invoice
								value against a complete set of shipping
								documents not later than 2 days after
								presentation, failing which interest at the rate
								of 2% above Bank Negara's based lending rate per
								annum shall become payable.
							</p>
							<Spacer size={20} />
							<Box row={!isMobile} width={isMobile ? 238 : 640}>
								<p style={{ ...paragraphStyles }}>*(a)</p>
								<Box>
									<p
										style={{
											...paragraphStyles,
											marginLeft: 30,
										}}
									>
										by irrevocable and confirmed letter of
										credit for unrestricted negotiation
										established in Sellers' favour through a
										recognised bank for 102% of the contract
										quantity. Unless otherwise agreed
										between the parties, such letter of
										credit shall be advised and made
										available to the Sellers not less than
										15 business days prior to commencement
										of loading. Should the letter of credit
										be opened on terms of inconsistent with
										the contract, Sellers may demand
										amendments which shall be arranged by
										Buyers and notified to Sellers through
										the opening bank within 7 business days
										of the demand being received but in no
										case later than 3 business days prior to
										commencement of loading. The letter of
										credit shall provide for the negotiating
										bank to claim reimbursement by
										telex/cable from the opening bank upon
										confirmation that all documents conform
										to the credit requirements.
									</p>
								</Box>
							</Box>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								*(b)
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by
								cash on presentation of documents.
							</p>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								If documents are presented by Buyers through the
								intermediary of a bank(s) then the bank charges
								incurred shall be for Sellers' account. If
								Buyers demand presentation through a bank of
								their choice, those bank charges shall be for
								Buyers' account.
							</p>
							<Spacer size={20} />
							<p style={{ ...paragraphStyles }}>
								Unless otherwise agreed shipping documents shall
								consist of the following in triplicate :-
							</p>
							<Spacer size={20} />
							<Box width={isMobile ? 238 : 640}>
								<p
									style={{
										...paragraphStyles,
									}}
								>
									1. provisional/final invoice;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									2. full set of clean Bill(s) of Lading;
								</p>

								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									3. certificate of shipped weights
									ascertained at port of loading by a
									recognised independent surveyor;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									4. survey report issued by a recognised
									independent surveyor, certifying fitness and
									cleanliness of ship's tanks and pipeline and
									particulars as to time and place of loading,
									sampling and establishment of the shipped
									weight;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									5. certificate of analysis issued by an
									independent certified analyst based on
									samples drawn in accordance with the
									Sampling and Analysis Clause;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									6. certificate of origin issued by any
									Chamber of Commerce or Malaysian Customs
									Authorities, if applicable;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									7. Customs Movement Permit in the number of
									copies as required;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									8. Malaysian Common Tariff Form No. 3 (MCT3)
									in the number of copies as required (a
									signed copy of MCT3 duly certified by
									Customs Authorities at port of discharge,
									should be returned to Sellers within 5
									business days from the date of release of
									the same by Customs Authorities at port of
									discharge, failing which Buyers shall
									reimburse Sellers any costs incurred arising
									from the delay);
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									9. certificate of insurance;
								</p>
								<Spacer size={12} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									10. a copy of the Heating Instructions
									issued to the ship's Master in accordance
									with Clause 5.
								</p>
								<Spacer size={20} />
								<p
									style={{
										...paragraphStyles,
									}}
								>
									Buyers to accept photostatic and certified
									copies of items (3), (4), (5), (7) and (10)
									relating to the whole parcel(s). Should the
									documents be presented with incomplete
									set(s) of Bill of Lading, payment shall be
									made provided that delivery of such Bills of
									Lading be guaranteed by Sellers. Such
									guarantee to be endorsed, if required by
									Buyers, by a recognised bank. Acceptance of
									this guarantee shall not prejudice Buyers'
									rights under this contract.
								</p>
							</Box>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>9.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DISCHARGE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								The oil shall be delivered at the port of
								destination at a berth usual for discharge of
								edible oils or, if practicable and mutually
								agreed, at a special berth. Buyers shall take
								delivery with customary quick despatch
								immediately after proper notice has been given
								by the ship's Owners or his representatives that
								the ship is in all respects ready to deliver the
								oil. Otherwise Buyers are liable to pay
								demurrage.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Sellers are responsible for pumping out and all
								expenses to ship's rail, and for sweepings
								and/or puddling. Discharging expenses from
								ship's rail are for account of Buyers. Any
								residue remaining in the ship's tank(s) to be
								claimed by Buyers and to be delivered to them at
								Sellers' expense at the discharging berth in
								packages to be provided by Buyers.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								A bank guarantee will be provided by Buyers to
								vessel Owners for discharge of cargo without
								submission of original Bills of Lading, with a
								condition that the related Bills of Lading will
								be submitted to Owners as soon as they are
								received from Sellers. In lieu, a Letter of
								Indemnity could be provided, if mutually agreed
								between Sellers and Buyers.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>10.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>WEIGHTS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Weights as ascertained from ullage drawn from
								calibrated shore tanks and/or Buyers'
								weighbridge at the time of discharge and duly
								certified by independent surveyors appointed by
								Buyers shall be final. Sellers have the right to
								be represented, at their own costs, at the time
								of discharge in which case weights shall be
								established conjointly.
							</p>
						</Box>
					</Box>

					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>11.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>SAMPLING AND ANALYSIS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								<span
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
									}}
								>
									At loading
								</span>{' '}
								: Representative samples of the oil shall be
								drawn in quadruplicate from each shore tank in
								accordance with the MPOA Guide Book for
								Surveying of Crude Palm Oil exports from
								Malaysia and sealed by recognised
								superintendent(s) for analysis. Two each of
								these samples shall be handed to the Master with
								instruction to hand them over to receivers at
								port of discharge. Tests to be made from a set
								of samples by independent certified analyst at
								the port of shipment who shall issue the
								appropriate certificate. The remaining set of
								samples to be retained by Sellers'
								superintendent at port of loading. The costs of
								analysis at time of shipment shall be for
								Sellers' account.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Buyers have the right to be represented at time
								of shipment at their expense and to draw and
								seal samples conjointly with Sellers'
								representatives. If Buyers are so represented
								only samples sealed conjointly shall be accepted
								as official contract samples for analysis
								purposes only.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								<span
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
									}}
								>
									At discharge
								</span>{' '}
								: Representative samples of the oil shall be
								drawn from ship's tank in accordance with the
								MPOA Guide Book for Surveying of Crude Palm Oil
								exports from Malaysia. Buyers' and Sellers'
								superintendents shall drawn and seal conjointly
								5 sets of representative samples for analysis
								and/or arbitration purposes. Any unpumpable
								and/or off-quality oil discharged and stored
								separately shall be analysed separately. Buyers
								or their representatives shall retain 3 sets of
								sealed samples. The remaining 2 sets of sealed
								samples shall be retained by Sellers or their
								representatives.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								If Buyers fails to send a set of samples for
								analysis by independent certified analyst
								Sellers have a right to submit a sample and the
								results of this analysis shall stand. Buyers
								shall pass on the analysis certificate to their
								Sellers with due despatch. Final receiver shall
								also instruct the analyst to forward a copy of
								the analysis certificate direct to shippers'
								superintendent.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In the event of a dispute on quality, analysis
								of a sample conjointly drawn and sealed shall be
								referred to MPOB no later than 10 business days
								from the date of drawing sample. MPOB analysis
								shall be final.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should either party fail to appoint a
								superintendent then the samples drawn by the
								superintendent present shall be the valid
								samples for the purposes of analysis and/or
								arbitration.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>12.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>NOTICES</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Where the terms of the contract require notice
								to be given, such notice shall be despatched by
								cable, telegram, facsimile, telex, email or when
								the parties reside in the same town or when such
								notice is likely to be received within any
								specified time limit, the notice may be
								despatched by first class mail or delivered by
								hand. All notices shall be under reserve for
								errors in transmission. Any notice received
								after 1600 hours Malaysian time on a business
								day shall be deemed to have been received on the
								following business day.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>13.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>NON-BUSINESS DAYS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the time limit for doing any act or
								giving any notice expire on a Saturday, Sunday
								or any national gazetted public holiday in
								Malaysia, the time so limited shall be extended
								until the first business day thereafter. All
								business days shall be deemed to end 1600 hours
								Malaysian time, Monday to Friday inclusive. The
								shipment period shall not be affected by this
								clause.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>14.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>ODD DAYS</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In any month containing an odd number of days,
								the middle day shall be reckoned as belonging to
								both halves of the month.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>15.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>FORCE MAJEURE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should the performance of the contract be
								prevented by reason of fire, strikes, lockout,
								riots, civil commotion, and/or any cause
								comprehended in the term force majeure, the
								contract period shall be extended for a period
								equal to the duration of the disabling
								circumstances but not exceeding a period of 60
								calendar days. If the force majeure events ends
								within 21 calendar days preceeding the end of
								the extended period, then a further 21 calendar
								days shall be allowed after the termination of
								the force majeure event
							</p>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Should fulfilment of the contract not be
								possible within the extended period, the
								contract or any unfulfilled part thereof so
								affected shall be deemed to be null and avoid at
								the end of such extended period.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>16.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DEFAULT</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								In default of fulfilment of this contract by
								either party, the other party at his discretion
								shall, after giving notice in writing or by
								cable, telegram, facsimile or telex, if they do
								not reside in the same town, have the right
								either to cancel the contract or the right to
								sell or purchase as the case may be, against the
								defaulter who shall on demand make good the
								loss, if any, on such sale or purchase. If the
								party liable to pay shall be dissatisfied with
								the price of such sale or purchase, or if
								neither of the above right is exercised, the
								damages if any, shall, failing amicable
								settlement be determined by arbitration. The
								damages awarded against the defaulter shall be
								limited to the differences between the contract
								price and the market price on the day of the
								default but if the Arbitrators consider the
								circumstances of the default justify it, they
								may at their absolute discretion, award
								additional damages.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>17.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>DOMICILE</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								This contract shall be deemed to have been made
								in Malaysia and the construction, validity and
								performance thereof shall be governed in all
								respects by Malaysian law.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>18.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>BANKRUPTCY</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								If before the fulfilment of this contract either
								party shall suspend payment, commit an act of
								bankruptcy, notify any of his creditors that he
								is unable to meet his debts or that he has
								suspended payment or that he is about to suspend
								payment of his debts, convene, call or hold a
								meeting either of his creditors or to pass a
								resolution to go into liquidation (except for a
								voluntary winding up of a solvent company for
								the purpose of reconstruction or amalgamation)
								or shall apply for an official moratorium, have
								a petition presented for winding up or shall
								have a Receiver appointed, he shall be deemed to
								be and shall be treated as being in default and
								the contract shall forthwith be closed, either
								at the market price then current for similar
								goods, or at the option of the other party,
								declarable by the end of the next business day,
								at a price to be ascertained by repurchase or
								resale on such next business day and the
								difference between the contract price and such
								closing - out price shall be the amount which
								the other party shall be entitled to claim or
								shall be liable to account for under this
								contract. Should either party be dissatisfied
								with the price, the matter shall be referred to
								arbitration. Where no such resale or repurchase
								takes place, the closing-out price shall be
								determined by a Price Settlement Committee
								appointed jointly by The Palm Oil Refiners
								Association of Malaysia and Malaysian Palm Oil
								Association.
							</p>
						</Box>
					</Box>
					<Spacer size={20} />
					<Box row={!isMobile}>
						<Box>
							<p style={{ ...liStyles, marginRight: 24 }}>19.</p>
						</Box>
						<Box>
							<h3 style={h3Styles}>ARBITRATION</h3>
							<Spacer size={20} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Any dispute arising out of this contract,
								including any question of law arising in
								connection therewith, shall be referred to
								arbitration in Malaysia (at the PORAM
								Secretariat or elsewhere if so agreed), in
								accordance with the PORAM Rules for Arbitration
								and Appeal in force at the date of the
								initiation of the arbitration.
							</p>
							<Spacer size={12} />
							<p
								style={{
									...paragraphStyles,
									textAlign: 'justify',
									width: isMobile ? 238 : 640,
								}}
							>
								Neither party hereto, nor any persons claiming
								under either of them shall bring any action or
								other legal proceedings against the other of
								them in respect of any such dispute until such
								dispute shall first have been heard and
								determined by the Sole Arbitrator/Panel of
								Arbitrators/Appeal Board (as the case maybe), in
								accordance with the PORAM Rules for Arbitration
								and Appeal and it is hereby expressly agreed and
								declared that the obtaining of an award from the
								Sole Arbitrator/Panel of Arbitrators/Appeal
								Board (as the case may be), shall be a condition
								precedent to the right of either party hereto or
								of any person claiming under either of them to
								bring any action or other legal proceedings
								against the other of them in respect of such
								dispute.
							</p>
						</Box>
					</Box>
					<Box
						row={!isMobile}
						justifyContent="space-between"
						margin="28px 0 0"
					>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Seller:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Broker:
						</p>
						<p style={{ ...paragraphStyles, fontWeight: 'bold' }}>
							As Buyer:
						</p>
					</Box>
				</Box>
				<Box
					style={{
						width: isMobile ? 350 : 800,
						padding: 15,
						margin: '40px 0',
						alignItems: 'center',
						background: theme.color.white,
						justifyContent: 'space-between',
					}}
					row={!isMobile}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '60%',
							alignItems: 'end',
							marginRight: '5%',
							padding: '15px 10px',
							background: theme.color.white,
						}}
					>
						<SmallText
							style={{
								fontSize: 14,
								color: '#8D93A0',
								lineHeight: '19px',
								textAlign: 'justify',
							}}
						>
							{t('contracts.proceedToESignText')}
						</SmallText>
					</Box>

					<Button
						rounded
						label={t('contracts.proceedToESign')}
						reverse
						onClick={() => {
							if (contractAction === 'edit') {
								setShowModal(true)
							} else {
								dispatch(
									MarketPlaceDuc.creators.postContractDetails(
										contractDetails,
										documentType
									)
								)
							}
						}}
						extendStyles={{
							width: 266,
							background: '#3F56C4',
							color: theme.color.white,
						}}
					/>
				</Box>
				<DigitalContractMessage
					width={isMobile ? 350 : 800}
					margin="0 0 40px 0"
				/>
			</Box>
		</>
	)
}

export { PreviewContractNo6 }
