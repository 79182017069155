import { all, takeLatest, put, call } from 'redux-saga/effects'
import LogHelper from 'ui-marketplace-app/utils/logger'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { getCoreEndPoint } from 'ui-marketplace-app/config'
import request from 'ui-marketplace-app/utils/request'

import { UploadContentDuc } from './duc'

const logger = LogHelper('client:UploadContentSaga')

function* uploadDocumentFile(action) {
	try {
		const { uploadPayload, successMsg } = action

		const requestUrl = `${getCoreEndPoint()}fileentities`

		const options = {
			method: 'POST',
			body: JSON.stringify(uploadPayload),
		}

		yield call(request, requestUrl, options)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: successMsg,
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('upload-document-file'))
	}
}

export default function* UploadContentSaga() {
	try {
		yield all([
			takeLatest(
				UploadContentDuc.creators.uploadDocumentFile().type,
				uploadDocumentFile
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
