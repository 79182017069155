import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { AuthDuc } from 'ui-marketplace-app/modules/Auth/duc'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MarketPlaceDuc } from 'ui-marketplace-app/modules/MarketPlace/duc'
import { CertificateBlock } from 'ui-marketplace-app/modules/MarketPlace/containers/DocumentView'
import { Title } from 'ui-marketplace-app/modules/MarketPlace/components/Title'
import { KeyValueSegment } from 'ui-marketplace-app/shared/components/KeyValueSegment'
import {
	isEmptyObject,
	viewRFQTemplateKeys,
} from 'ui-marketplace-app/utils/helpers'
import { Input } from 'ui-lib/components/Input'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Button } from 'ui-lib/components/Button'
import { CheckBox } from 'ui-lib/components/Checkbox'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import { SmallText, H3, Text, P } from 'ui-lib/components/Typography'
import { Modal } from 'ui-lib/components/Modal'
import { Select } from 'ui-lib/components/Select'
import { TextArea } from 'ui-lib/components/TextArea'
import { Card } from 'ui-lib/components/Card'
import { Label } from 'ui-lib/components/Label'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import theme from 'ui-lib/utils/base-theme'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import * as yup from 'yup'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import ReportProblemIcon from 'ui-lib/icons/report_problem.svg'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'

const ProductNameWrapper = styled.div`
	font-size: 18px;
	font-weight: 500;
	overflow: hidden;
	padding-bottom: 3px;
	text-overflow: ellipsis;
	color: ${theme.color.black3};
`

const TemplateNameWrapper = styled.div`
	font-size: 16px;
	font-weight: 400;
	color: ${theme.color.grey14};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`
const SubContentWrapper = styled.div`
	// padding-right: 15px;
`
const ViewText = styled.div`
	cursor: pointer;
	color: ${theme.color.blue13};
`
const MainWrapper = styled.div`
	display: flex;
	padding: 17px 20px;
	border-radius: 5px;

	border: 1px solid
		${props => (props.selected ? theme.color.blue5 : theme.color.grey14)}};
	&:hover {
		background-color: ${theme.color.grey13};
	}
`
const ListMainWrapper = styled.div`
	overflow: scroll;
	max-height: 350px;
	padding-right: 10px;
`

const ViewMainWrapper = styled.div`
	overflow: scroll;
	max-height: 400px;
	min-height: 400px;
	border-radius: 5px;
	border: 1px solid ${theme.color.blue5};
`
const ViewContentwrapperOut = styled.div`
	padding-left: 40px;
	padding-right: 40px;
	padding-bottom: ${props =>
		props.index === props.length - 1 ? '20px' : '0px'};
	padding-top: ${props => (props.index === 0 ? '20px' : '0px')};
	background-color: ${props =>
		props.index === 0 || props.index === 1 ? theme.color.grey13 : 'px'};
`
const ViewContentwrapper = styled.div`
	display: flex;
	padding-bottom: 5px;
`
const ViewHeaderWrap = styled.div`
	display: flex;
`
const ViewHeaderIcon = styled.div``
const ViewKeyText = styled.div`
	font-size: 16px;
	min-width: 225px;
	font-weight: 500;
	color: ${theme.color.black3};
`
const ViewValueText = styled.div`
	font-size: 16px;
	min-width: 250px;
	font-weight: 300;
	color: ${theme.color.black3};
`
const ViewHeaderMainText = styled.div`
	margin-left: 20px;
`
const ViewHeaderSubText = styled.div`
	padding-left: 60px;
	margin-top: -10px;
`

const CreateRFQ = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [showTemplateListModal, setShowTemplateListModal] = useState(false)
	const [showTemplateNameInput, setShowTemplateNameInput] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [selectedTemplate, setSelectedTemplate] = useState({})
	const [showViewModal, setShowViewModal] = useState(false)
	const [templateNameExists, setTemplateNameExists] = useState(false)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(MarketPlaceDuc.selectors.location)
	const { payload } = location
	const { action, rootModule } = payload

	useEffect(() => {
		dispatch(MarketPlaceDuc.creators.rfqTemplateList())
	}, [dispatch])

	const initialValues = useSelector(
		MarketPlaceDuc.selectors.getInitialRFQFormValues
	)

	const rfqTemplateNameSearch = useSelector(
		MarketPlaceDuc.selectors.getRfqTemplateSearchResult
	)

	const rfqTemplateNameList = useSelector(
		MarketPlaceDuc.selectors.getRfqTemplateListResult
	)

	const orgProductDetails = useSelector(
		MarketPlaceDuc.selectors.getOrgProductDetails
	)

	const organization =
		action === 'create-rfq'
			? getIn(orgProductDetails, ['organization'])
			: getIn(initialValues, ['organization'])

	const currentOrgDetails = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)

	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []

	useEffect(() => {
		setTemplateNameExists(
			getIn(rfqTemplateNameSearch, ['data', 'TempNameMatch']) || false
		)
	}, [rfqTemplateNameSearch])

	const validationSchema = yup.object().shape({
		quantity: yup
			.number()
			.positive()
			.required(),
		uom: yup.string().required(),
		templateName:
			showTemplateNameInput &&
			yup
				.string()
				.required(t('validation.templateNameRequired'))
				.min(1, t('validation.templateNameRequired'))
				.matches(/\S/, t('validation.templateNameRequired'))
				.strict(),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		submitForm,
		handleBlur,
		setFieldValue,
		touched,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			dispatch(
				MarketPlaceDuc.creators.setActiveTabs('buyer', 'enquiries')
			)

			const rfqPayload = {
				..._values,
				productID: getIn(orgProductDetails, ['product', 'id']),
				productName: getIn(orgProductDetails, ['product', 'name']),
				productCode: getIn(orgProductDetails, ['product', 'code']),
				buyer: {
					id: getIn(currentOrgDetails, ['id']),
					name: getIn(currentOrgDetails, ['name']),
				},
				sellers: organization,
				saveTemplate: showTemplateNameInput,
			}

			if (action === 'create-rfq') {
				dispatch(
					MarketPlaceDuc.creators.createRFQ(
						rfqPayload,
						t('production.plotInputSuccessToast'),
						{
							setSubmitting,
							actor,
						}
					)
				)
			} else if (action === 'edit-rfq') {
				const editPayload = {
					...values,
					rfqID: initialValues.rfqID,
					productID: initialValues.productID,
					productName: initialValues.product,
					buyer: {
						id: getIn(currentOrgDetails, ['id']),
						name: getIn(currentOrgDetails, ['name']),
					},
					sellers: [
						{
							id: initialValues.sellerID,
						},
					],
				}

				dispatch(
					MarketPlaceDuc.creators.editRfq(
						editPayload,
						t('common.successUpdate'),
						{
							setSubmitting,
							actor,
						}
					)
				)
			}
		},
	})

	const disableCTA =
		!isEmptyObject(errors) ||
		!values.quantity ||
		(showTemplateNameInput &&
			(!values.templateName ||
				(values.templateName && !values.templateName.trim()))) ||
		(showTemplateNameInput && templateNameExists) ||
		!values.uom

	const getInspectorData = (item, type) => {
		const data =
			getIn(item, ['templateObj', 'inspection']) &&
			getIn(item, ['templateObj', 'inspection']).length > 0
				? getIn(item, ['templateObj', 'inspection']).find(
						ele => getIn(ele, ['type']) === type
				  )
				: ''

		return data ? getIn(data, ['officer']) : ''
	}

	const viewValues = index => {
		let value = ''
		switch (index) {
			case 0: {
				value =
					getIn(selectedItem, ['templateObj', 'product', 'name']) ||
					''
				break
			}
			case 1: {
				const data = (
					<div>
						{getIn(selectedItem, ['templateObj', 'quantity']) || ''}
						&nbsp;
						{getIn(selectedItem, [
							'templateObj',
							'product',
							'uom',
						]) || ''}
					</div>
				)
				value = data || ''
				break
			}
			case 2: {
				const data = (
					<div>
						{getIn(selectedItem, [
							'templateObj',
							'shipment',
							'month',
						]) || ''}
						{getIn(selectedItem, [
							'templateObj',
							'shipment',
							'month',
						])
							? ','
							: ''}{' '}
						&nbsp;
						{getIn(selectedItem, [
							'templateObj',
							'shipment',
							'period',
						]) || ''}
					</div>
				)
				value = data || ''
				break
			}
			case 3: {
				value = getIn(selectedItem, ['templateObj', 'ladingPort']) || ''
				break
			}
			case 4: {
				value =
					getIn(selectedItem, ['templateObj', 'destinationPort']) ||
					''
				break
			}
			case 5: {
				value =
					getIn(selectedItem, [
						'templateObj',
						'supplyChainModel',
						'id',
					]) || ''
				break
			}
			case 6: {
				value = getInspectorData(selectedItem, 'quality') || ''
				break
			}
			case 7: {
				value = getInspectorData(selectedItem, 'quantity') || ''
				break
			}
			case 8: {
				value =
					getIn(selectedItem, ['templateObj', 'contractType']) || ''
				break
			}
			case 9: {
				value = getIn(selectedItem, ['templateObj', 'payment']) || ''
				break
			}
			case 10: {
				value =
					getIn(selectedItem, [
						'templateObj',
						'requirementDetails',
					]) || ''
				break
			}
			case 11: {
				value =
					getIn(selectedItem, [
						'templateObj',
						'meta',
						'termsAndConditions',
					]) || ''
				break
			}

			default:
				break
		}

		return value
	}

	const viewSelectedItem = () => {
		const viewData = []
		viewRFQTemplateKeys.forEach((ele, index) => {
			viewData.push(
				<ViewContentwrapperOut
					index={index}
					length={viewRFQTemplateKeys.length}
				>
					<ViewContentwrapper>
						<ViewKeyText>{ele}</ViewKeyText>
						<ViewValueText>{viewValues(index)}</ViewValueText>
					</ViewContentwrapper>
				</ViewContentwrapperOut>
			)
		})

		return <ViewMainWrapper>{viewData}</ViewMainWrapper>
	}

	const viewSelectedItemHeader = () => {
		return (
			<ViewHeaderWrap>
				<ViewHeaderIcon>
					<IconWrapper
						size={38}
						style={{
							color: theme.color.blue5,
							cursor: 'pointer',
							paddingTop: '5px',
						}}
						onClick={() => {
							setShowTemplateListModal(true)
							setShowViewModal(false)
						}}
					>
						<Icon glyph={LeftArrowIcon} />
					</IconWrapper>
				</ViewHeaderIcon>
				<ViewHeaderMainText>
					{getIn(selectedItem, ['templateName'])}
				</ViewHeaderMainText>
			</ViewHeaderWrap>
		)
	}

	const radioGroupOptions = () => {
		const list = []

		const tempList =
			rfqTemplateNameList && getIn(rfqTemplateNameList, ['data', 'list'])
				? getIn(rfqTemplateNameList, ['data', 'list'])
				: ''
		if (tempList && tempList.length > 0) {
			tempList.forEach(item => {
				const options = {}

				options.name = getIn(item, ['id'])
				options.label = (
					<MainWrapper
						selected={
							getIn(item, ['id']) ===
							getIn(selectedTemplate, ['id'])
						}
					>
						<SubContentWrapper
							style={{
								maxWidth: isMobile ? '100px' : '290px',
								minWidth: isMobile ? '100px' : '290px',
							}}
						>
							<ProductNameWrapper
								title={
									getIn(item, [
										'templateObj',
										'product',
										'name',
									]) || ''
								}
							>
								{getIn(item, [
									'templateObj',
									'product',
									'name',
								]) || ''}
							</ProductNameWrapper>
							<TemplateNameWrapper
								title={getIn(item, ['templateName']) || ''}
							>
								{getIn(item, ['templateName']) || ''}
							</TemplateNameWrapper>
						</SubContentWrapper>
						<SubContentWrapper
							style={{
								maxWidth: isMobile ? '85px' : '158px',
								minWidth: isMobile ? '85px' : '158px',
								paddingLeft: '20px',
							}}
						>
							<ProductNameWrapper
								title={`${getIn(item, [
									'templateObj',
									'quantity',
								]) || ''} ${getIn(item, [
									'templateObj',
									'product',
									'uom',
								]) || ''}`}
							>
								{getIn(item, ['templateObj', 'quantity']) || ''}
								&nbsp;
								{getIn(item, [
									'templateObj',
									'product',
									'uom',
								]) || ''}
							</ProductNameWrapper>
						</SubContentWrapper>
						<SubContentWrapper
							style={{
								maxWidth: '60px',
							}}
						>
							<ViewText
								onClick={() => {
									setSelectedItem(item)
									setShowViewModal(true)
									setShowTemplateListModal(false)
								}}
							>
								VIEW
							</ViewText>
						</SubContentWrapper>
					</MainWrapper>
				)

				list.push(options)
			})
		}

		return list
	}

	const templateList = () => {
		return (
			<ListMainWrapper>
				<RadioInputGroup
					direction="column"
					selected={
						selectedTemplate && getIn(selectedTemplate, ['id'])
							? getIn(selectedTemplate, ['id'])
							: null
					}
					gap="medium"
					options={radioGroupOptions()}
					onChange={e => {
						setSelectedTemplate(
							getIn(rfqTemplateNameList, ['data', 'list']).find(
								ele => getIn(ele, ['id']) === e.target.value
							)
						)
					}}
				/>
			</ListMainWrapper>
		)
	}

	const templateHandleChange = e => {
		if (e.target.value.trim() !== '') {
			dispatch(
				MarketPlaceDuc.creators.rfqTemplateSearch(e.target.value.trim())
			)
			handleChange(e)
		}
	}

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('rfq.marketPlace'),
			name: 'marketplace',
			isActive: true,
		},
		{
			label:
				action === 'create-rfq' ? t('rfq.createRFQ') : t('rfq.editRFQ'),
			name: 'create-rfq',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'marketplace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MARKETPLACE,
					{
						rootModule: 'buyer',
					}
				)
			)
		}
	}

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box>
				<Title
					title={
						action === 'create-rfq'
							? t('rfq.sendRFQ')
							: t('rfq.editRFQ')
					}
					icon={LeftArrowIcon}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE,
								{
									rootModule,
								}
							)
						)
					}}
				/>
			</Box>
			<Box row={!isMobile} justifyContent="space-between">
				<Box
					style={{
						width: isMobile ? '100%' : '60%',
						background: '#fff',
						padding: '22px',
						marginRight: '20px',
					}}
				>
					<Box
						row={!isMobile}
						justifyContent="flex-start"
						alignItems="baseline"
					>
						<Box
							style={{
								width: isMobile ? '100%' : '25%',
								overflow: 'hidden',
							}}
						>
							<H3
								style={{
									lineHeight: '1.5',
									fontSize: theme.fontSize.m,
									width: '100%',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									color: '#8D93A0',
								}}
								title={t('rfq.rfqForProduct')}
							>
								{t('rfq.rfqForProduct')}
							</H3>
						</Box>

						<Box
							padding={4}
							style={{
								width: '60%',
							}}
						>
							<Text
								style={{
									lineHeight: '1.5',
									width: '100%',
									overflow: 'hidden',
									color: '#299F74',
									fontSize: theme.fontSize.xl,
									fontWeight: 'bold',
								}}
							>
								{getIn(orgProductDetails, ['product', 'name'])}
							</Text>
						</Box>
					</Box>
					<Spacer size={40} />
					<form onSubmit={handleSubmit}>
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('rfq.quantityTimeline')}
							</SmallText>

							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box>
									<Label
										style={{
											fontSize: '14px',
											color: '#242845',
										}}
										required
									>
										{t('rfq.enterQuantity')}
									</Label>
									<Box row margin="8px 0 0">
										<Box width={140}>
											<Input
												value={values.quantity}
												name="quantity"
												type="number"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.quantity &&
													errors.quantity
												}
											/>
										</Box>
										<Box width={144} margin="0  0 0 16px">
											<Select
												value={values.uom}
												options={[
													{
														label: t(
															'marketPlace.mt'
														),
														name: 'mt',
													},
													{
														label: t(
															'marketPlace.kg'
														),
														name: 'kg',
													},
												]}
												key={values.uom}
												labelKey="label"
												valueKey="name"
												onChange={value =>
													setFieldValue(
														'uom',
														value.name
													)
												}
												returnOnlyValue
											/>
										</Box>
									</Box>
									<Box row margin="8px 0 0">
										<Box>
											<Label
												style={{
													fontSize: '14px',
													color: '#242845',
												}}
											>
												{t('rfq.shipmentPeriod')}
											</Label>
											<Box row margin="8px 0 0">
												<Box width={140}>
													<Select
														value={values.month}
														options={[
															{
																label: t(
																	'marketPlace.jan'
																),
																name: 'january',
															},
															{
																label: t(
																	'marketPlace.feb'
																),
																name:
																	'february',
															},
															{
																label: t(
																	'marketPlace.mar'
																),
																name: 'march',
															},
															{
																label: t(
																	'marketPlace.apr'
																),
																name: 'april',
															},
															{
																label: t(
																	'marketPlace.may'
																),
																name: 'may',
															},
															{
																label: t(
																	'marketPlace.jun'
																),
																name: 'june',
															},
															{
																label: t(
																	'marketPlace.jul'
																),
																name: 'july',
															},
															{
																label: t(
																	'marketPlace.aug'
																),
																name: 'august',
															},
															{
																label: t(
																	'marketPlace.sep'
																),
																name:
																	'september',
															},
															{
																label: t(
																	'marketPlace.oct'
																),
																name: 'october',
															},
															{
																label: t(
																	'marketPlace.nov'
																),
																name:
																	'november',
															},
															{
																label: t(
																	'marketPlace.dec'
																),
																name:
																	'december',
															},
														]}
														key={values.month}
														labelKey="label"
														valueKey="name"
														onChange={value =>
															setFieldValue(
																'month',
																value.name
															)
														}
														returnOnlyValue
													/>
												</Box>

												<Box
													width={144}
													margin="0 0 0 16px"
												>
													<Select
														value={values.period}
														options={[
															{
																label: t(
																	'marketPlace.first-half'
																),

																name:
																	'first-half',
															},

															{
																label: t(
																	'marketPlace.second-half'
																),

																name:
																	'second-half',
															},

															{
																label: t(
																	'marketPlace.entire-month'
																),

																name:
																	'entire-month',
															},
														]}
														key={values.period}
														labelKey="label"
														valueKey="name"
														onChange={value =>
															setFieldValue(
																'period',
																value.name
															)
														}
														returnOnlyValue
													/>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>

								<Card
									style={{
										background: '#CBD2EF',
										maxWidth: isMobile ? 300 : 200,
										padding: '12px 4px 0 12px',
										height: 160,
										margin: isMobile
											? '26px 0px'
											: '26px 0px 0px 28px',
										borderRadius: 6,
									}}
									textAlign="left"
								>
									<Box row>
										<IconWrapper
											size={24}
											style={{
												padding: 4,
												background: '#ECEEF7',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<Icon glyph={ReportProblemIcon} />
										</IconWrapper>
										<SmallText
											style={{
												marginLeft: 12,
												fontWeight: 'bold',
											}}
										>
											{t('rfq.impStep')}
										</SmallText>
									</Box>
									<P
										style={{
											color: '#3F56C4',
											fontSize: 14,
											marginTop: 8,
										}}
									>
										{t('rfq.impStepDescription')}
									</P>
								</Card>
							</Box>
						</Card>
						<Spacer size={32} />
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('rfq.shipmentDetails')}
							</SmallText>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box style={{ width: '200px' }}>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
										>
											{t('rfq.portOfLading')}
										</Label>
										<Box style={{ width: '100%' }}>
											<Input
												value={values.ladingPort}
												name="ladingPort"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.ladingPort &&
													errors.ladingPort
												}
											/>
										</Box>
									</Box>
								</Box>

								<Box style={{ width: '200px' }}>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: '14px',
												margin: '0 0 8px',
												color: theme.color.black3,
											}}
										>
											{t('rfq.destinationPort')}
										</Label>
										<Input
											value={values.destinationPort}
											name="destinationPort"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.destinationPort &&
												errors.destinationPort
											}
										/>
									</Box>
								</Box>
							</Box>
						</Card>
						<Spacer size={32} />
						<Card
							style={{
								padding: '0px 20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<SmallText
								style={{
									position: 'absolute',
									fontSize: theme.fontSize.m,
									color: theme.color.accent2,
									top: '-10px',
									left: '20px',
									display: 'inline-block',
									backgroundColor: '#FAFBFF',
									padding: '0 8px',
								}}
							>
								{t('rfq.thirdPartyInspection')}
							</SmallText>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box style={{ width: '200px' }}>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												color: '#242845',
												margin: '0 0 8px',
											}}
										>
											{t(
												'rfq.preferredQuantityInspector'
											)}
										</Label>
										<Box style={{ width: '100%' }}>
											<Input
												value={values.quantityInspector}
												name="quantityInspector"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.quantityInspector &&
													errors.quantityInspector
												}
											/>
										</Box>
									</Box>
								</Box>

								<Box style={{ width: '200px' }}>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												color: '#242845',
												margin: '0 0 8px',
											}}
										>
											{t('rfq.preferredQualityInspector')}
										</Label>
										<Input
											value={values.qualityInspector}
											name="qualityInspector"
											type="text"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.qualityInspector &&
												errors.qualityInspector
											}
										/>
									</Box>
								</Box>
							</Box>
							<Box
								row={!isMobile}
								style={{ width: '100%', marginTop: '20px' }}
								justifyContent="space-between"
							>
								<Box style={{ width: '200px' }}>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												color: '#242845',
												margin: '0 0 8px',
											}}
										>
											{t('rfq.scModel')}
										</Label>
										<Box style={{ width: '100%' }}>
											<Select
												value={values.supplyChainModel}
												options={[
													{
														label: t(
															'createAsset.identity-preserved'
														),
														name:
															'identity-preserved',
													},
													{
														label: t(
															'createAsset.segregated'
														),
														name: 'segregated',
													},
													{
														label: t(
															'createAsset.mass-balance'
														),
														name: 'mass-balance',
													},
												]}
												key={values.supplyChainModel}
												labelKey="label"
												valueKey="name"
												onChange={value =>
													setFieldValue(
														'supplyChainModel',
														value.name
													)
												}
												returnOnlyValue
											/>
										</Box>
									</Box>
								</Box>

								<Box style={{ width: '200px' }}>
									<Box style={{ width: '100%' }}>
										<Label
											style={{
												fontSize: theme.fontSize.m,
												color: '#242845',
												margin: '0 0 8px',
											}}
										>
											{t('rfq.typeOfContract')}
										</Label>
										<Select
											value={values.contractType}
											options={[
												{
													label: t('marketPlace.fob'),

													name: 'fob',
												},

												{
													label: t('marketPlace.cif'),

													name: 'cif',
												},

												{
													label: t(
														'marketPlace.local-delivery'
													),

													name: 'local-delivery',
												},
											]}
											key={values.contractType}
											labelKey="label"
											valueKey="name"
											onChange={e =>
												setFieldValue(
													'contractType',
													e.name
												)
											}
											returnOnlyValue
										/>
									</Box>
								</Box>
							</Box>
						</Card>
						<Spacer size={32} />
						<Box>
							<Label>
								<Label
									style={{
										fontSize: '16px',
										margin: '0 0 8px',
										color: theme.color.black3,
									}}
								>
									{t('rfq.termsAndConditions')}
								</Label>
								<Label
									style={{
										fontSize: '12px',
										margin: isMobile ? '8px 0' : '0 0 8px',
										color: theme.color.white,
										alignItems: 'center',
										float: 'right',
										paddingRight: '10px',
										paddingLeft: '10px',
										backgroundColor: '#CE924B',
										borderRadius: '4px',
										opacity: 1,
									}}
								>
									{t('rfq.termsAndConditionsline2')}
								</Label>
							</Label>
							<TextArea
								name="termsAndConditions"
								value={values.termsAndConditions}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'termsAndConditions',
										e.target.value
									)
								}}
							/>
						</Box>
						<Spacer size={16} />
						<Box>
							<Label
								style={{
									fontSize: '16px',
									margin: '0 0 8px',
									color: theme.color.black3,
								}}
							>
								{t('rfq.paymentTerms')}
							</Label>
							<TextArea
								name="payment"
								value={values.payment}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue('payment', e.target.value)
								}}
							/>
						</Box>
						<Spacer size={16} />
						<Box>
							<Label
								style={{
									fontSize: '16px',
									margin: '0 0 8px',
									color: theme.color.black3,
								}}
							>
								{t('rfq.additionalDetails')}
							</Label>
							<TextArea
								name="requirementDetails"
								value={values.requirementDetails}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'requirementDetails',
										e.target.value
									)
								}}
							/>
						</Box>
						{showTemplateNameInput ? (
							<Box style={{}}>
								<Label
									style={{
										fontSize: '14px',
										color: '#242845',
										marginBottom: '8px',
									}}
									required
								>
									{t('rfq.templateName')}
								</Label>
								<Box>
									<Input
										helpText={
											templateNameExists ? (
												<div
													style={{
														color:
															theme.color.error,
														paddingTop: 8,
													}}
												>
													{t(
														'rfq.templateNameValidation'
													)}
												</div>
											) : (
												''
											)
										}
										value={values.templateName}
										name="templateName"
										type="text"
										onChange={e => templateHandleChange(e)}
										onBlur={handleBlur}
										error={errors.templateName}
									/>
								</Box>
							</Box>
						) : (
							''
						)}
					</form>
				</Box>
				<Box
					style={{
						width: isMobile ? '100%' : '37%',
					}}
				>
					{action === 'create-rfq' &&
					rfqTemplateNameList &&
					getIn(rfqTemplateNameList, ['data']) &&
					getIn(rfqTemplateNameList, ['data', 'list']) &&
					getIn(rfqTemplateNameList, ['data', 'list']).length > 0 ? (
						<Box
							style={{
								padding: '1px',
								marginBottom: 32,
								marginTop: isMobile ? '20px' : '0px',
								alignItems: isMobile ? 'center' : 'normal',
							}}
						>
							<Button
								label={t('rfq.orderUsingTemplate')}
								onClick={() => {
									setShowTemplateListModal(true)
									setSelectedTemplate({})
								}}
							/>
						</Box>
					) : (
						''
					)}
					{(organization || []).length > 1 ? (
						<CollapsableSegment
							expand
							title={t('rfq.sellerList')}
							open
							noBorderColor
						>
							<Spacer size={12} />
							{(organization || []).map(org => {
								return (
									<>
										<Box
											padding={8}
											style={{
												border: '1px solid #D9DEE5',
											}}
										>
											<Title
												title={org.name}
												note={`${getIn(org, [
													'primaryAddress',
													'state',
												])}, ${getIn(org, [
													'primaryAddress',
													'country',
												])}`}
												small
											/>
										</Box>
										<Spacer size={12} />
									</>
								)
							})}
						</CollapsableSegment>
					) : (
						<Box
							style={{
								background: '#fff',
								padding: '28px',
							}}
						>
							<Label style={{ color: theme.color.blue5 }}>
								{t('rfq.issueTo')}
							</Label>
							<Spacer size={8} />

							<Title
								title={getIn(organization, [0, 'name'])}
								note={getIn(organization, [
									0,
									'categories',
									0,
									'name',
								])}
								style={{ fontSize: '18px' }}
							/>
							<Spacer size={8} />
							<KeyValueSegment
								name={t('rfq.address')}
								description={`${getIn(organization, [
									0,
									'primaryAddress',
									'line1',
								]) || ''} ${getIn(organization, [
									0,
									'primaryAddress',
									'city',
								]) || ''}
 ${getIn(organization, [0, 'primaryAddress', 'state']) ||
		''} ${getIn(organization, [0, 'primaryAddress', 'country']) || ''}`}
							/>
							<Spacer size={8} />
							<KeyValueSegment
								name={t('rfq.stateCountry')}
								description={`
							${getIn(organization, [0, 'primaryAddress', 'state']) ||
								''} ${getIn(organization, [
									0,
									'primaryAddress',
									'country',
								]) || ''}`}
							/>
							<Spacer size={12} />
							{(
								getIn(organization, [
									0,
									'certificationStatus',
									'certificates',
								]) || []
							).map(certificate => (
								<CertificateBlock
									key={getIn(certificate, [
										'meta',
										'certificate',
										'issuingBody',
									])}
									name={getIn(certificate, [
										'meta',
										'certificate',
										'issuingBody',
									])}
									certificate={certificate}
								/>
							))}
						</Box>
					)}
					<Box
						style={{
							background: '#fff',
							padding: '28px',
						}}
					>
						<Label style={{ color: theme.color.blue5 }}>
							{t('rfq.destinationDetails')}
						</Label>
						<Spacer size={8} />

						<Title
							title={getIn(currentOrgDetails, ['name'])}
							note={getIn(currentOrgDetails, [
								'categories',
								0,
								'name',
							])}
							small
						/>
						<Spacer size={8} />
						<KeyValueSegment
							name={t('rfq.address')}
							description={`${getIn(currentOrgDetails, [
								'primaryAddress',
								'line1',
							]) || ''} ${getIn(currentOrgDetails, [
								'primaryAddress',
								'city',
							]) || ''}
 ${getIn(currentOrgDetails, ['primaryAddress', 'state']) ||
		''} ${getIn(currentOrgDetails, ['primaryAddress', 'country']) || ''}`}
						/>
						<Spacer size={8} />
						<KeyValueSegment
							name={t('rfq.stateCountry')}
							description={`
							${getIn(currentOrgDetails, ['primaryAddress', 'state']) ||
								''} , ${getIn(currentOrgDetails, [
								'primaryAddress',
								'country',
							]) || ''}`}
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width={isMobile ? '100%' : '60%'}
				row={!isMobile}
				justifyContent={
					action === 'create-rfq' ? 'space-between' : 'end'
				}
				margin="36px 0 0"
			>
				{action === 'create-rfq' && (
					<Box
						style={{
							height: '42px',
							paddingTop: '9px',
							paddingLeft: '23px',
							paddingRight: '23px',
							backgroundColor: theme.color.white,
						}}
					>
						<CheckBox
							label={t('rfq.checkBoxLabel')}
							name="saveTemplate"
							onChange={() =>
								setShowTemplateNameInput(!showTemplateNameInput)
							}
						/>
					</Box>
				)}
				<Box
					style={{
						marginTop: isMobile ? '20px' : '0px',
						alignItems: isMobile ? 'center' : 'normal',
					}}
				>
					<Button
						isLoading={isSubmitting}
						label={
							action === 'create-rfq'
								? t('rfq.sendRFQ')
								: t('rfq.updateRFQ')
						}
						primary
						disabled={disableCTA}
						onClick={() => submitForm()}
						extendStyles={{ width: 212 }}
					/>
				</Box>
			</Box>
			{action === 'create-rfq' && (
				<Box
					style={{
						fontSize: 14,
						marginTop: 12,
						color: theme.color.grey14,
					}}
				>
					{t('rfq.tip')}
				</Box>
			)}
			<Modal
				size="680px"
				show={showTemplateListModal}
				closeable
				heading={t('rfq.selectTemplate')}
				subHeading={t('rfq.selectTemplateSubheading')}
				body={templateList()}
				confirmlabel={t('rfq.selectTemplate')}
				onClose={() => setShowTemplateListModal(false)}
				extendStyles={{ borderRadius: '10px' }}
				isDisabled={
					!(selectedTemplate && getIn(selectedTemplate, ['id']))
				}
				onConfirm={() => {
					if (selectedTemplate) {
						const rfqDetails = {
							quantity:
								parseInt(
									getIn(selectedTemplate, [
										'templateObj',
										'quantity',
									]),
									10
								) || '',
							uom: getIn(selectedTemplate, [
								'templateObj',
								'product',
								'uom',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'product',
										'uom',
								  ]).toLowerCase() || ''
								: '',
							ladingPort:
								getIn(selectedTemplate, [
									'templateObj',
									'ladingPort',
								]) || '',
							month: getIn(selectedTemplate, [
								'templateObj',
								'shipment',
								'month',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'shipment',
										'month',
								  ]).toLowerCase() || ''
								: '',
							period: getIn(selectedTemplate, [
								'templateObj',
								'shipment',
								'period',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'shipment',
										'period',
								  ])
										.replace(/\s/g, '-')
										.toLowerCase() || ''
								: '',
							destinationPort:
								getIn(selectedTemplate, [
									'templateObj',
									'destinationPort',
								]) || '',
							quantityInspector:
								getInspectorData(
									selectedTemplate,
									'quantity'
								) || '',
							qualityInspector:
								getInspectorData(selectedTemplate, 'quality') ||
								'',
							supplyChainModel: getIn(selectedTemplate, [
								'templateObj',
								'supplyChainModel',
								'id',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'supplyChainModel',
										'id',
								  ])
										.replace(/\s/g, '-')
										.toLowerCase() || ''
								: '',
							contractType: getIn(selectedTemplate, [
								'templateObj',
								'contractType',
							])
								? getIn(selectedTemplate, [
										'templateObj',
										'contractType',
								  ])
										.replace(/\s/g, '-')
										.toLowerCase() || ''
								: '',
							payment:
								getIn(selectedTemplate, [
									'templateObj',
									'payment',
								]) || '',
							requirementDetails:
								getIn(selectedTemplate, [
									'templateObj',
									'requirementDetails',
								]) || '',
							termsAndConditions:
								getIn(selectedTemplate, [
									'templateObj',
									'meta',
									'termsAndConditions',
								]) || '',
						}
						dispatch(
							MarketPlaceDuc.creators.setInitialRFQDetails(
								rfqDetails
							)
						)
						setShowTemplateListModal(false)
					}
				}}
			/>
			<Modal
				size="680px"
				show={showViewModal}
				closeable
				heading={viewSelectedItemHeader()}
				subHeading={
					<ViewHeaderSubText>
						{t('rfq.showing')}{' '}
						{getIn(selectedItem, ['templateName'])}{' '}
						{t('rfq.details')}
					</ViewHeaderSubText>
				}
				hideButtons
				body={viewSelectedItem()}
				onClose={() => {
					setShowViewModal(false)
					setSelectedTemplate({})
				}}
				extendStyles={{ borderRadius: '10px' }}
			/>
		</>
	)
}

export { CreateRFQ }
