import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'ui-lib/utils/Box'
import { SmallText } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'

const DigitalContractMessage = ({ width = '100%', margin = 0 }) => {
	const { t } = useTranslation()

	return (
		<Box
			style={{
				width,
				margin,
				padding: 15,
				alignItems: 'center',
				background: theme.color.white,
				justifyContent: 'space-between',
			}}
			row
		>
			<Box
				style={{
					alignItems: 'end',
					marginRight: '5%',
					padding: '15px 10px',
					background: theme.color.white,
				}}
			>
				<SmallText
					style={{
						fontSize: 14,
						color: '#8D93A0',
						lineHeight: '19px',
						textAlign: 'justify',
					}}
				>
					"{t('contracts.digitalContractMessage')} (
					<a
						href="https://dibizglobal.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://dibizglobal.com
					</a>
					). {t('contracts.digitalContractMessageLine2')}:
					<a
						href="https://dibizglobal.com/#/digitalcontracts/countries"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://dibizglobal.com/#/digitalcontracts/countries
					</a>
					".
				</SmallText>
			</Box>
		</Box>
	)
}

export { DigitalContractMessage }
