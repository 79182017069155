import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import LogHelper from 'ui-marketplace-app/utils/logger'
import { AppDuc } from 'ui-marketplace-app/modules/App/duc'
import { MainRouteDuc } from 'ui-marketplace-app/routes/duc'
import { getCoreEndPoint } from 'ui-marketplace-app/config'
import request from 'ui-marketplace-app/utils/request'
import { Toast } from 'ui-lib/components/Toast'
import { FeedbackDuc } from 'ui-marketplace-app/modules/Feedback/duc'

const logger = LogHelper('client:feedbackSaga')

function* saveUserFeedback(action) {
	try {
		const { feedback, successToast } = action
		const { topic, message } = feedback
		const module = `smallholder/${topic.toLowerCase()}`
		const values = {
			module,
			message,
		}
		const requestUrl = `${getCoreEndPoint()}feedback`
		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}

		yield put(AppDuc.creators.showGlobalLoader('dashboard-listing'))

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			message: successToast,
			type: 'success',
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-listing'))
	}
}

export default function* FeedbackSaga() {
	try {
		yield all([
			takeLatest(
				FeedbackDuc.creators.saveUserFeedback().type,
				saveUserFeedback
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
